import React from 'react';
import { Link } from "react-router-dom"

export default function Error404() {
    return (<>
        <div className="appHeader no-border">
            <div className="left">
                <Link to="/" className="headerButton goBack">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </Link>
            </div>
            <div className="pageTitle">
                404 Page
            </div>
            <div className="right">
            </div>
        </div>

        <div id="appCapsule">

            <div className="section">
                <div className="splash-page mt-5 mb-5">
                    <h1>404</h1>
                    <h2 className="mb-2">Page not found!</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquam.
                    </p>
                </div>
            </div>

            <div className="fixed-bar">
                <div className="row">
                    <div className="col-6">
                        <Link to="/" className="btn btn-lg btn-outline-secondary btn-block goBack">Go Back</Link>
                    </div>
                    <div className="col-6">
                        <Link to="/" className="btn btn-lg btn-primary btn-block">Try Again</Link>
                    </div>
                </div>
            </div>

        </div>
    </>);
}
