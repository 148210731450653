const JewelaryIcon = ({color}) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.68694 9.25937C8.68694 9.05691 8.52298 8.89277 8.32035 8.89277C7.713 8.89277 7.22056 8.40034 7.22056 7.79299C7.22056 7.59054 7.0566 7.42639 6.85397 7.42639C6.65152 7.42639 6.48738 7.59054 6.48738 7.79299C6.48738 8.40034 5.99512 8.89277 5.38759 8.89277C5.18514 8.89277 5.021 9.05691 5.021 9.25937C5.021 9.46182 5.18514 9.62596 5.38759 9.62596C5.99512 9.62596 6.48738 10.1184 6.48738 10.7257C6.48738 10.9282 6.65152 11.0923 6.85397 11.0923C7.0566 11.0923 7.22056 10.9282 7.22056 10.7257C7.22056 10.1184 7.713 9.62596 8.32035 9.62596C8.52298 9.62596 8.68694 9.46182 8.68694 9.25937ZM6.85397 9.63724C6.74729 9.4935 6.61984 9.36623 6.4761 9.25937C6.61984 9.1525 6.74729 9.02523 6.85397 8.88149C6.96083 9.02523 7.0881 9.1525 7.23202 9.25937C7.0881 9.36623 6.96083 9.4935 6.85397 9.63724Z"
        fill={color}
      />
      <path
        d="M24.8169 5.22639C24.2096 5.22639 23.7171 4.73396 23.7171 4.1266C23.7171 3.92415 23.5532 3.76001 23.3506 3.76001C23.1481 3.76001 22.984 3.92415 22.984 4.1266C22.984 4.73396 22.4917 5.22639 21.8842 5.22639C21.6817 5.22639 21.5176 5.39053 21.5176 5.59298C21.5176 5.79543 21.6817 5.95958 21.8842 5.95958C22.4917 5.95958 22.984 6.45201 22.984 7.05936C22.984 7.26181 23.1481 7.42596 23.3506 7.42596C23.5532 7.42596 23.7171 7.26181 23.7171 7.05936C23.7171 6.45201 24.2096 5.95958 24.8169 5.95958C25.0196 5.95958 25.1835 5.79543 25.1835 5.59298C25.1835 5.39053 25.0196 5.22639 24.8169 5.22639ZM23.3506 5.97086C23.2439 5.82712 23.1164 5.69985 22.9727 5.59298C23.1164 5.48612 23.2439 5.35885 23.3506 5.21511C23.4574 5.35885 23.5847 5.48612 23.7286 5.59298C23.5847 5.69985 23.4574 5.82712 23.3506 5.97086Z"
        fill={color}
      />
      <path
        d="M17.4415 11.5417L20.6773 6.58286C20.6974 6.56335 20.7149 6.54169 20.7298 6.51824C20.7965 6.4103 20.8026 6.27552 20.7459 6.16185L19.6461 3.96228C19.584 3.83823 19.4569 3.75983 19.318 3.76001H10.5197C10.3808 3.76001 10.2537 3.83841 10.1916 3.96282L9.1373 6.07145C9.11439 6.09526 9.0947 6.12211 9.07877 6.15129C9.01433 6.26961 9.02005 6.41388 9.0938 6.52665L12.3736 11.5516C8.88938 12.8415 6.92055 16.5335 7.79121 20.1456C8.66187 23.7575 12.0965 26.1473 15.7859 25.7081C19.4752 25.2686 22.2527 22.1391 22.2508 18.4238C22.2386 15.3518 20.3222 12.6093 17.4415 11.5417ZM15.8203 12.6829L17.373 6.69277H19.7301L15.8203 12.6829ZM14.597 14.9583V14.9601L14.6008 14.9646C14.6203 14.992 14.6432 15.0167 14.6693 15.038C14.6781 15.045 14.6872 15.0507 14.6964 15.057C14.7175 15.0716 14.7402 15.0838 14.7638 15.0937C14.7741 15.0976 14.7841 15.1024 14.7946 15.1058C14.8644 15.1296 14.9403 15.1296 15.0102 15.1058C15.0209 15.1024 15.0307 15.0976 15.0409 15.0937C15.0647 15.0838 15.0873 15.0716 15.1084 15.057C15.1176 15.0507 15.1269 15.045 15.1356 15.038C15.1616 15.0167 15.1845 14.992 15.2038 14.9646L15.2078 14.9598L15.2096 14.958L15.9794 13.7805C18.3415 14.3204 19.9225 16.5466 19.654 18.9547C19.3855 21.3626 17.3527 23.1859 14.9298 23.1922C12.5068 23.1983 10.465 21.3856 10.1841 18.9789C9.90307 16.5722 11.4727 14.3379 13.832 13.7861L14.597 14.9583ZM13.1929 6.69277H16.6156L14.9044 13.2967L13.1929 6.69277ZM10.7463 4.4932H19.0916L19.8248 5.95958H10.0132L10.7463 4.4932ZM12.4356 6.69277L13.9882 12.6832L10.0799 6.69277H12.4356ZM14.9189 25.0225C11.6952 25.0182 8.94666 22.6855 8.41825 19.5055C7.88984 16.3254 9.73642 13.229 12.7853 12.1826L13.4086 13.1357C10.7719 13.8911 9.09613 16.4752 9.4817 19.1906C9.86709 21.9061 12.1959 23.9216 14.9386 23.9136C17.6812 23.9055 19.998 21.8764 20.3675 19.1586C20.7371 16.441 19.0461 13.867 16.4051 13.1268L17.0284 12.1738C20.0927 13.2045 21.9584 16.3042 21.4347 19.4946C20.9109 22.6851 18.152 25.0255 14.9189 25.0225Z"
        fill={color}
      />
    </svg>
  );
};

export default JewelaryIcon;
