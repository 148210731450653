import React from "react";
import "./index.css";

const DetailedComp = ({ detail }) => {
    // Perform the replacement of **text** with <b> tags and inline styles
    let replacedStr = detail.replace(/\*\*(.*?)\*\*/g, '<b style="font-size: 20px;color:black">$1</b>');

    return (
        <div className="detailed-component-container">
            <p style={{ fontSize: 24, fontWeight: "bold", color: "black" }}>Suggestions:</p>
            {/* Render the replaced string using dangerouslySetInnerHTML */}
            <p
                style={{
                    fontSize: 18,
                    whiteSpace: "pre-line",
                    color:"black",
                    opacity:"0.9"
                }}
                dangerouslySetInnerHTML={{ __html: replacedStr }}
            />
        </div>
    );
};

export default DetailedComp;
