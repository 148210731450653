import { createTheme } from "@mui/material/styles";
import { configMain, configBrookline, configNorthwell, configEverett } from "./config"
const themeMain = createTheme(configMain)
const themeBrookline = createTheme(configBrookline)
const themeNorthwell = createTheme(configNorthwell)
const themeEverett = createTheme(configEverett)

 export {
    themeMain,
    themeBrookline,
    themeNorthwell,
    themeEverett
 }