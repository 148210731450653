import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, Typography, Dialog, DialogTitle, IconButton } from "@mui/material";
import { makePodcastSubmission, createTranscriptionV2 } from "../_api/podcast";
import AWS from "aws-sdk";
import toast from "react-hot-toast";
import { Buffer } from "buffer";
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';

const headStyle = {
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "15px",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "0px 10px",
  gap: "10px",
  background: "#F0F1FF",
  borderRadius: "5px",
  color: "#242424",
};
const fileContainerStyle = {
  padding: "20px",
  border: "1px dashed #D8D7D7",
  margin: "10px 0px 100px 0px",
  borderRadius: "5px",
};

const inputBtnStyle = {
  fontFamily: "sans-serif",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "20px",
  color: "#242424",
  textTransform: "none",
  "&:hover": {
    background: "none",
  },
};

const inputFileStyle = {
  position: "absolute",
  left: "1px",
  backgroundColor: "white",
  padding: "10px",
  opacity: "0%",
};

const uploadBtnStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "15px",
  color: "#5048E5",
  padding: "10px 18px",
  borderRadius: "15px",
  "&:hover": {
    background: "rgba(97, 109, 237, 0.1)",
  },
};

const submitAudioStyle = {
  background: "#10B981",
  color: "#ffff",
  gap: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "15px",
  padding: "10px 15px",
  borderRadius: "10px",
  "&:hover": {
    background: "rgba(0, 159, 64, 1)",
  },
};

const downloadBtnStyle = {
  color: "#5048E5",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "15px",
  borderRadius: "10px",
  gap: "10px",
  padding: "10px 15px",
  marginRight: "10px",
  "&:hover": {
    background: "rgba(97, 109, 237, 0.1)",
    cursor: "default",
  },
};

const audioPlayerStyle = {
  position: 'relative',
  width: '100%',
  height: 'auto',
  backgroundColor: '#f8f9fa',
  display: 'flex'
}
const ccButton = {
  position: 'absolute',
  top: '0px',
  right: '5px',
  color: '#000'
}

const audioStyle = {
  width: "96%",
  background: 'transparent',
  border: 'none'
};

const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = process.env.REACT_APP_REGION;
AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});
const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const CustomAudioPlayer = ({ url, text }) => {
  const [ccModal, setCCModal] = useState(false)
  const Message = ({ index, username, text }) => {
    return (
      <Grid container>
        <Grid
          item
          xs={2.5}
          sx={{ color: index == 0 ? "#616DED" : "#FB785C", fontWeight: "bold" }}
        >
          {username}:
        </Grid>
        <Grid item xs={9.5} sx={{ color: "#000" }}>
          {text}
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Dialog
        open={ccModal}
        keepMounted
        onClose={() => setCCModal(false)}
        maxWidth='sm'
        fullWidth
        style={{
          backgroundColor: 'rgba(1000, 1000, 1000, 0.2)',
          overflow: 'visible',
        }}
      >
        <DialogTitle>Transcript</DialogTitle>

        <Grid container
          sx={{ height: '50vh' }}>
          <Grid
            container
            item
            xs={12}
            flexDirection={"column-reverse"}
            p={2}
          >
            {text?.map((item, index) => (
              <Message
                username={item?.user.name}
                index={item?.user.id}
                text={item.text}
                key={`${index}`}
              />
            ))}
          </Grid>
        </Grid>
      </Dialog>
      <div style={audioPlayerStyle}>
        <audio
          style={audioStyle}
          src={url}
          controls="true"
          controlsList="nodownload"
          className="audio-1"
        ></audio>
        <IconButton style={ccButton} onClick={() => setCCModal(true)}>
          <ClosedCaptionIcon fontSize="large" />
        </IconButton>
      </div>
    </>

  )
}

const PodcastSubmission = (props) => {
  const { podcast, setPage, messages, selectedVoices, refresh } = props;
  const [status, setStatus] = useState("Sending File...");
  const [submissionDetails, setSubmissionDetails] = useState({
    submission:
      podcast?.submission === "not submitted" ? "submitted" : "resubmitted",
    submittedFile: "",
    podcastImage: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [audioLink, setAudioLink] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [submissionDisabled, setSubmissionDisabled] = useState(true);
  const inputFileRef = useRef();

  const _createTranscription = async () => {
    try {
      setIsLoading(true);
      const _data = await createTranscriptionV2({
        voiceIds: selectedVoices,
        texts: messages.map((item, index) => {
          return {
            content: [item?.text],
            voice: item?.voiceIndex,
            title: `${podcast?.podcast?.title} message ${index}`,
          };
        }),
      });
      if (_data?.data) {
        onAudioUpload(_data?.data);
      } else {
        console.log("Unexpected problem", _data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onAudioUpload = (buffer) => {
    const file_name = `${podcast.id}--${podcast?.userId?.id}.mp3`;
    const audioBuffer = Buffer.from(buffer);
    const params = {
      ACL: "public-read",
      Body: audioBuffer,
      Bucket: S3_BUCKET,
      Key: file_name,
      ContentType: "audio/mpeg",
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (e) => {
        if (Math.round((e.loaded / e.total) * 100) === 100) {
          console.log("Uploaded!");
        }
      })
      .send((err, data) => {
        if (err) {
          console.log(err);
        } else {
          setSubmissionDetails({
            ...submissionDetails,
            submittedFile: `https://${S3_BUCKET}.s3.amazonaws.com/${file_name}`,
          });
          setAudioLink(`https://${S3_BUCKET}.s3.amazonaws.com/${file_name}`);
        }
      });
  };

  const onImageUpload = (file) => {
    const type = file.name.split(".").pop();
    const file_name = `${podcast.id}--${podcast?.userId?.id}.${type}`;
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: file_name,
      ContentType: file.type,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (e) => {
        if (Math.round((e.loaded / e.total) * 100) === 100) {
          console.log("Uploaded!");
        }
      })
      .send((err, data) => {
        if (err) {
          console.log(err);
        } else {
          setSubmissionDetails({
            ...submissionDetails,
            podcastImage: `https://${S3_BUCKET}.s3.amazonaws.com/${file_name}`,
          });
        }
      });
  };

  const _makePodcastSubmission = async () => {
    try {
      setIsLoading(true);
      setStatus("Submitting...");
      await makePodcastSubmission(podcast?.id, { ...submissionDetails, submittedChat: messages });
      setIsLoading(false);
      setPage("main");
      refresh();
    } catch (error) {
      toast.error("Unable to make submission", { duration: 3000 });
      console.log(error);
    }
  };

  const validateSubmission = () => {
    console.log(submissionDetails);
    setSubmissionDisabled(
      submissionDetails?.submission === "" ||
      submissionDetails?.submittedFile === "" ||
      submissionDetails?.podcastImage === ""
    );
  };

  const downloadAudio = async () => {
    try {


      const fileUrl = submissionDetails?.submittedFile
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = podcast?.title + '.mp3';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.log('Error:', error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    _createTranscription();
  }, []);

  useEffect(() => {
    validateSubmission();
  }, [submissionDetails]);

  if (isLoading)
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <img
          src={
            submissionDisabled
              ? "assets/img/generating.gif"
              : "assets/img/pingpong.gif"
          }
          alt="Generating..."
          style={{ height: "70%" }}
        />
        <Typography sx={headStyle}>{status}</Typography>
      </Grid>
    );
  else
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center overflow-hidden mb-1">
          <div className="d-flex align-items-center">
            <Button
              className="mb-1"
              variant="text"
              sx={{ minWidth: "unset", width: "fit-content" }}
              startIcon={
                <img
                  width="150%"
                  height="150%"
                  src="/assets/img/icon/back.svg"
                  className="logo"
                  alt=""
                />
              }
              onClick={() => {
                setPage("voice");
              }}
            />
            <h1
              style={{
                fontfamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "20px",
                color: "#9B9FAC",
              }}
            >
              Podcast Assignments
            </h1>
          </div>
        </div>
        <div style={headStyle}>Podcast Audio File</div>
        <div className="mt-3 mb-4">
          <CustomAudioPlayer url={audioLink} text={messages} />
          {/* <audio

            style={audioStyle}
            src={audioLink}
            controls="true"
            controlsList="nodownload"
            className="audio-1"
          ></audio> */}
        </div>
        <div style={headStyle}>Podcast Title Image</div>
        <div
          className="mt-1 d-flex justify-content-between align-items-center"
          style={fileContainerStyle}
        >
          <Button
            sx={inputBtnStyle}
          // disabled={disabled}
          >
            <img
              src={
                uploadedImages.length !== 0
                  ? URL.createObjectURL(uploadedImages[0])
                  : "/assets/img/icon/imgSelect.svg"
              }
              style={{ marginRight: "16px", height: 150 }}
            />
            <input
              type="file"
              name="file"
              ref={inputFileRef}
              style={inputFileStyle}
              onChange={(e) => {
                // This snippet will run if a file is selected,
                // else the previously selected file will be retained.
                if (e.target.files.length > 0) {
                  if (e.target.files[0]?.size > 7340032) {
                    console.log(
                      "File size is too big. Try to upload less than or equal to 7MBs"
                    );
                  } else {
                    setUploadedImages(e.target.files);
                    onImageUpload(e.target.files[0]);
                  }
                }
              }}
              accept="image/*"
            />
            {uploadedImages.length !== 0
              ? uploadedImages[0]?.name
              : "Select Image"}
          </Button>
          <Button
            sx={uploadBtnStyle}
            onClick={() => inputFileRef.current.click()}
          >
            {" "}
            <img
              src="/assets/img/icon/uploadIcon.svg"
              style={{ marginRight: "10px" }}
            />{" "}
            Upload
          </Button>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            sx={downloadBtnStyle}
            onClick={downloadAudio}
            disabled={submissionDetails.submittedFile == ""}
          >
            {" "}
            <img src="/assets/img/icon/downloadIcon.svg" /> Download Audio
          </Button>

          <Button
            sx={submitAudioStyle}
            onClick={_makePodcastSubmission}
            disabled={submissionDisabled}
          >
            {" "}
            <img src="/assets/img/icon/submitIcon.svg" /> Submit Audio
          </Button>
        </div>
      </div>
    );
};

export default PodcastSubmission;
