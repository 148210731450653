import React from "react";
import { Button, Divider, Typography, Grid } from "@mui/material";

const actionsBtnStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  dispaly: "flex",
  textTransform: "none",
  padding: "10px 15px",
  minWidth: "unset",
  borderRadius: "11.1486px",
  color: "#33C052",
  "&:hover": {
    background: "rgba(51, 192, 82, 0.15)",
    color: "#0A9929",
  },
};

const headStyle = {
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "15px",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "0px 10px",
  gap: "10px",
  background: "#F0F1FF",
  borderRadius: "5px",
};

const contentStyle = {
  display: "flex",
  alignItems: "flex-start",
  padding: "15px 20px",
  gap: "20px",
};

const titleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "15px",
  color: "#9B9FAC",
  alignItems: "flex-start",
  minWidth: "140px",
};

const descStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  color: "#242424",
};

const PodcastDetails = (props) => {
  const { podcast, setPage } = props;
  const isSubmissionDisabled =
    podcast.approval == "approved" ||
    (podcast.approval == "pending" && podcast.submission != "not submitted");

  const _buttonText = () => {
    if (podcast.approval == "approved") {
      return "Completed!";
    } else if (
      podcast.approval == "pending" &&
      podcast.submission != "not submitted"
    ) {
      return "Awaiting Check!";
    } else {
      return "Start Assignment";
    }
  };

  return (
    <div>
      <Grid
        container
        className="d-flex justify-content-between align-items-center overflow-hidden mb-1"
      >
        <Grid item lg={6} md={6} sm={12} className="d-flex align-items-center">
          <Button
            className="mb-1"
            variant="text"
            sx={{ minWidth: "unset", width: "fit-content" }}
            startIcon={
              <img
                width="150%"
                height="150%"
                src="assets/img/icon/back.svg"
                className="logo"
                alt=""
              />
            }
            onClick={() => {
              setPage("all");
            }}
          />
          <h1
            style={{
              fontfamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "20px",
              color: "#9B9FAC",
            }}
          >
            Podcasts Assignments
          </h1>
        </Grid>
        <Grid item lg={6} md={6} sm={12} sx={{ textAlign: "right" }}>
          <Button
            sx={actionsBtnStyle}
            disabled={isSubmissionDisabled}
            onClick={() => setPage("chat")}
          >
            {_buttonText()}
          </Button>
        </Grid>
      </Grid>
      <div style={headStyle}>Assignment Details</div>
      <div style={contentStyle}>
        <Typography style={titleStyle}>Title</Typography>
        <Typography sx={descStyle}>{podcast.title || "__Title"}</Typography>
      </div>
      <Divider sx={{ color: "#C0BEBE", borderBottomWidth: 2 }} />
      <div style={contentStyle}>
        <Typography sx={titleStyle}>Description</Typography>
        <Typography sx={descStyle}>
          <span dangerouslySetInnerHTML={{ __html: podcast.description || "__Description" }} />
        </Typography>
      </div>
    </div>
  );
};

export default PodcastDetails;
