import React, { useState } from "react"
import "./index.css"
import InputField from "../../resumeAnalyzer/inputComp/inputComp"
import { Grid, MenuItem, TextField, Typography, Box } from "@mui/material"
import VoltageBtn from "../../resumeAnalyzer/voltage-btn/voltage_Btn"
import InterviewSection from "./interview-section"
import InterviewQuestions from "../interviewQuestions/interviewQuestions"
import { ArrowBack } from "@mui/icons-material"
import SuccessScreen from "../successScr"
import swal from "sweetalert"
const InterViewAnalyst = () => {
    const [questions, setQuestions] = useState()
    const [isLoading, setIsloading] = useState({ open: false, text: '' })
    const [state, setState] = useState(0)
    const [answers, setAnswers] = useState([])
    console.log("🚀 ~ InterViewAnalyst ~ answers:", answers)
    const isIframe = sessionStorage.getItem('isIframe');

    const handleBack = () => {
        if (state == 1) {
            swal({
                title: "Are you sure that you want to leave this page?",
                text: "You will lose the progress you've made.",
                icon: "warning",
                dangerMode: true,
            })
                .then(willDelete => {
                    if (willDelete) {
                        setState(0)
                    }
                });
            console.log("answers 1", answers)
            setAnswers([])
        } else {
            setState(prev => prev - 1)
            // setAnswers([])
            console.log("answers 2", answers)
        }
    }
    console.log("answers", answers)
    return (
        <div className="interview-analytics" style={{marginTop: isIframe !== "true"  ? 200 : 0}}>
            {state !== 0 ?
                <ArrowBack
                    style={styles.ArrowBack}
                    onClick={handleBack}
                  /> : null}
            {isLoading.open ? <div
                style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center" }}
            >
                <img src='./gif/loading.gif' style={{ width: 100 }} />
                <Typography fontFamily={'Poppins'} textAlign={'center'} variant='h4'>{isLoading?.text || ""}</Typography>
            </div> :
                <>{
                    state == 0 ?
                        <InterviewSection
                            setQuestions={setQuestions}
                            setState={setState}
                            setIsloading={setIsloading}
                        /> :
                        state == 1 ?
                            <InterviewQuestions setAnswers={setAnswers} answers={answers} questions={questions} setState={setState} setIsloading={setIsloading} />
                            : state == 2 ?
                                <SuccessScreen answers={answers} setState={setState} />
                                : null
                }
                </>
            }

        </div>
    )
}
// <VoltageBtn normalBtn={true} onClick={handleNextAndSubmit} name={"Submit"} />
export default InterViewAnalyst
const styles = {

    ArrowBack: {
        position: "absolute",
        top: -40,
        left: 20,
        cursor: "pointer"
    }
}