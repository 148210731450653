function Screen3() {
    return (<>

        <div className="section">
            <div className=" mt-5 mb-5 text-center">
                <div className="mb-3 d-flex justify-content-center">
                    <img src="assets/img/img-money.png" alt="" className="imaged square w140" />
                </div>
                <h2 className="mb-2 text-primary">Track your debt progress</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur <br /> adipiscing elit. Etiam aliquam.
                </p>
            </div>
        </div>
    </>);
}

export default Screen3;