import React from 'react';

export default function Header() {
    return (<>
        <div className="appHeader">
            <div className="left">
                <a href="#" className="headerButton goBack text-primary" onClick={() => window.history.go(-1)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div className="pageTitle">
                Categories & Rules
            </div>
        </div>
    </>)
}
