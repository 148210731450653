import React, { forwardRef, useState } from 'react'
import { Box, Button, IconButton, Link, Typography, Container } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popup from '../../components/common/Popup';
import Tutorial from '../../components/common/page-tutorials';
import { useStyles } from '../../utils/styles';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Footer() {
    const classes = useStyles()
    const [openHelp, setOpenHelp] = useState(false)
    return (
        <Box className={classes.footerStyle}>
            <Container maxWidth="xl" className={classes.flexStart} sx={{ gap: "24px", py: 1 }}>
                <Tutorial />
                <Button
                    onClick={() => setOpenHelp(true)}
                    variant='outlined'
                >
                    Help
                </Button>
                <Popup />
                <Dialog
                    open={openHelp}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenHelp(false)}
                    fullWidth
                    maxWidth="md"
                    PaperProps={{
                        sx: {
                            borderRadius: "15px",
                            m: { sm: "32px", xs: "3px" },
                            width: { sm: "calc(100% - 64px)", xs: "100%" },
                        }
                    }}
                >
                    <DialogContent sx={{ position: 'relative', p: { sm: 9, xs: 2 }, display: "flex", gap: 3.5, flexDirection: 'column', overflowX: 'hidden' }}>
                        <IconButton
                            onClick={() => setOpenHelp(false)}
                            sx={{ position: 'absolute', top: 3, right: 6, color: "#000" }}>
                            <CloseRoundedIcon />
                        </IconButton>
                        <Typography sx={{ mt: 1 }} variant="h4" color="initial" fontWeight={600}>
                            Need Help?
                        </Typography>
                        <Typography variant="body1" color="initial">
                            Support mail
                            <Link href="mailto:developers@stickball.biz" > developers@stickball.biz</Link>
                        </Typography>
                        <Typography variant="body1" color="initial">Support explaination text will be here</Typography>
                        <List sx={{ ml: 2 }} >
                            {
                                ["Option 1", "Option 2", "Option 3"].map(item => {
                                    return (
                                        <ListItem disablePadding sx={{ alignItems: 'flex-start' }} key={item}>
                                            <ListItemIcon sx={{ minWidth: 25, mt: 1 }}>
                                                <FiberManualRecordIcon sx={{ width: 15, height: 15, color: "black" }} />
                                            </ListItemIcon>
                                            <ListItemText primary={item} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                        <img style={{ marginLeft: 20 }} src="assets/svg/logo.gif" width={130} height={40} alt="" />
                    </DialogContent>
                </Dialog>
            </Container>

        </Box>
    )
}

export default Footer