import React from "react";
import "./loader.css";

export default function Loading() {
  return (
    <>
      <div className="full-container">
        <div className="contain">
          <img src="assets/img/icon/loader.png" className="load" />
          <div class="bubbles">
            <div class="firstbubble"></div>
            <div class="secondbubble"></div>
            <div class="thirdbubble"></div>
            <div class="fourthbubble"></div>
            <div class="fifthbubble"></div>
          </div>
        </div>
        <br />
        <h3 className="text-center text loadingtext">Processing</h3>
      </div>
    </>
  );
}
