import { useState } from "react";
import LoaderComp from "../../common/loaderComp"

const Onboarding = () => {
    const [isLoadIframe, setIframeLoaded] = useState(false);
    const isIframe = sessionStorage.getItem('isIframe');

    return (
        <div style={{marginTop: isIframe !== "true"  ? 200 : 30}}>
        <div
            className="modal-content"
            style={{
                maxWidth: "100%",
                maxHeight: "600px",
            }}
        >
            <div className="modal-body pb-3">
                {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
                <iframe
                    onLoad={() => setIframeLoaded(false)}
                    src={"https://document-ocr.stickball.biz/"}
                    allowFullScreen="true"
                    width="100%"
                    height="490px"
                    style={{
                        border: 0,
                        zIndex: 10
                    }}
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
            {/* <SkillAssignments userID={props?.user?.id} open={showSkillmodal} setOpen={setShowSkillModal} /> */}
        </div>
        </div>
    )
}
export default Onboarding