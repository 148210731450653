import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CableIcon from '@mui/icons-material/Cable';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';

export default function Field(props) {
    const { item, key } = props
    return (<>
        <li key={key} data-bs-toggle="modal" data-bs-target="#rocketModal">
            <a href="#" className="item">
                <div className="icon-box" style={{ borderRadius: '50%', overflow: 'hidden' }}>
                    {item?.icon}
                </div>
                <div className="in">
                    <span>
                        {item?.title}
                        <footer>{item?.webLink}</footer>
                    </span>
                </div>
            </a>
        </li>

        <div style={{ zIndex: 310000 }} className="modal fade action-sheet" id="rocketModal" tabIndex="-1" role="dialog">
            <div className="modal-dialog d-flex justify-content-center align-items-center" role="document" style={{ boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.20)', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
                <div className="modal-content" style={{ height: '80%', maxWidth: '35%', borderRadius: '10px', position: 'relative' }}>
                    <span data-bs-dismiss="modal">
                        <CloseIcon
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                zIndex: '1000',
                                cursor: 'pointer'
                            }}
                        />
                    </span>
                    <div className="modal-body p-3 d-flex flex-column align-items-center">
                        <div className="d-flex flex-column align-items-center" style={{ maxWidth: '90%' }}>
                            <img width="150px" height="150px" src="assets/img/rocket-money.jpg" alt="" />
                            <p style={{ fontSize: '20px', fontWeight: 100 }} className="mt-2 text-center">Budget Calculator uses <b>Plaid</b> to <br />connect your account</p>
                            <div className="px-2 d-flex flex-column " style={{ maxWidth: '350px' }}>
                                <h4 > <CableIcon fontSize="small" /> Connect effortlessly</h4>
                                <p className="pl-2">Plaid lets you securely connect accounts in seconds</p>
                                <h4> <VisibilityOffIcon fontSize="small" /> Your data belongs to you</h4>
                                <p className="pl-2">Plaid does not sells your personal info, and will only use <br />  it with your permision.</p>
                            </div>
                            <p className="text-center" style={{ fontSize: 12 }}>
                                by selecting "Continue" you are agree to the <br />
                                <a href="#" style={{ textDecoration: 'underline', color: '#0F0F0F' }}>Plaid End User Privacy Policy</a>
                            </p>
                            <button
                                disabled
                                style={{
                                    marginTop: '5px',
                                    padding: '10px 100px',
                                    backgroundColor: '#5C6262',
                                    color: '#8C9595',
                                    cursor: 'unset'
                                }}
                            >Continue <LockIcon fontSize="small" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
