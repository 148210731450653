import { Link } from "react-router-dom";

import CommentIcon from "@mui/icons-material/Comment";

export const QUESTION_DATA = [
  {
    id: "888cc640-360f-11ed-a261-0242ac120002",
    question:
      "What’s the connection between my credit report and my credit score?",
    answer: `Creditors use your credit score to help decide whether to give you credit and what the terms will be, including what interest rate you’ll pay to borrow money. Your credit score is calculated using information in your credit report. Your credit report, which lists your payment history and information about the debts you owe, is a key part of many credit scoring systems. That’s why it’s so important to make sure your credit report is accurate. Federal law gives you the right to get a free copy of your credit report from each of the three nationwide credit bureaus once every 12 months.
        Everyone in the U.S. can get a free credit report from all three nationwide credit bureaus (Equifax, Experian, and TransUnion) at AnnualCreditReport.com.`,
  },
  {
    id: "19e6023c-3610-11ed-a261-0242ac120002",
    question: "How often should I check my credit score?",
    answer: `You can check your credit score as often as you want without impacting your score. Checking your credit score once a year may be enough to give you peace of mind that your finances are on track. However, many people prefer checking their scores monthly or weekly. While it’s up to you how often you check your score, looking at it regularly might provide insight into what’s affecting it and empower you to stay on track.`,
  },
  {
    id: "23b4df9a-3610-11ed-a261-0242ac120002",
    question: `What's considered a good credit score?`,
    answer: `Most banks and lenders use the FICO credit score to estimate credit risk. The FICO score ranges from 300-850, and what’s considered “good” will vary depending on which credit bureau is reporting your score. Generally, any score above 750 is considered “excellent,” and anything between 700-749 is considered “good.”`,
  },
  {
    id: "2fb2a0fc-3610-11ed-a261-0242ac120002",
    question: `How quickly can I raise my credit score?`,
    answer: `Since your payment history is the most important factor in determining your credit score, focus your attention on paying all your bills on time. Set up automatic bill pays or create reminders in your phone so you don’t miss payments. Next, request an increase to your credit card limits. This might sound counterintuitive, but increasing your credit limit effectively decreases your credit utilization, which can help raise your score. Lastly, request a current credit report and look for any discrepancies or errors. If you find something, dispute it with the reporting credit bureau immediately.`,
  },
];

function FAQs() {
  return (
    <>
      <div id="appCapsule" className="bg-white mx-0 mt-2 pt-0 pb-1">
        <div className="accordion border-0" id="accordionExample2">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordion001"
              >
                <div className="icon-box mr-2">
                  <CommentIcon />
                </div>
                Frequently Asked Questions
              </button>
            </h2>
            <div
              id="accordion001"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionExample2"
            >
              <div className="accordion-body">
                <ul className="listview image-listview border border-2 rounded-2">
                  {QUESTION_DATA.map((val, i) => (
                    <li>
                      <Link to={`/answer/${val.id}`} className="item">
                        <div
                          className="w-100"
                          style={{
                            borderBottom:
                              QUESTION_DATA.length - 1 !== i
                                ? "2px solid #C5C5C5"
                                : "none",
                          }}
                        >
                          <div
                            className="d-flex align-items-center"
                            style={{
                              marginBottom:
                                QUESTION_DATA.length - 1 !== i ? "5px" : "none",
                            }}
                          >
                            <div className="icon-box bg-primary">{i + 1}</div>
                            <div>
                              <span>{val.question}</span>
                              <div className="text-primary">
                                <b></b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                  {/* <li>
                                    <Link to="/answer" className="item">
                                        <div className="icon-box bg-primary">
                                            2
                                        </div>
                                        <div className="in">
                                            <span>
                                                How often should I check my credit score?
                                            </span>
                                            <div className="text-primary"><b></b></div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/answer" className="item">
                                        <div className="icon-box bg-primary">
                                            3
                                        </div>
                                        <div className="in">
                                            <span>
                                                What's considered a good credit score?
                                            </span>
                                            <div className="text-primary"><b></b></div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/answer" className="item">
                                        <div className="icon-box bg-primary">
                                            4
                                        </div>
                                        <div className="in">
                                            <span>
                                                How quickly can I raise my credit score?
                                            </span>
                                            <div className="text-primary"><b></b></div>
                                        </div>
                                    </Link>
                                </li> 
                                 <li>
                                    <Link to="/answer" className="item">
                                        <div className="icon-box bg-primary">
                                            5
                                        </div>
                                        <div className="in">
                                            <span>
                                                Question 5 ?
                                            </span>
                                            <div className="text-primary"><b></b></div>
                                        </div>
                                    </Link>
                                </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQs;
