import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const CustomButton = ({ onClick, style, children, icon, before=false, after=false }) => {
    const [isHovered, setIsHovered] = useState(false);

    const buttonStyle = {
      display: 'flex',
      alignItems: 'center',
      borderRadius: style?.borderRadius || '13px',
      backgroundColor: isHovered ? '#555fcb' : (style?.backgroundColor || '#616DED'),
      color: style.textColor || 'white',
      padding: style.padding || '10px 15px',
      border: 'none',
      cursor: 'pointer',
      ...style,
    };
  
    return (
      <button
        onClick={onClick}
        style={buttonStyle}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        {(before && icon) && <span>{icon}</span>}
        {children}
        {(after && icon) && <span>{icon}</span>}
      </button>
    );
};

export const CustomTextButton = ({ onClick, style, children, hoverTextColor }) => {
    const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    borderRadius: style.borderRadius || '13px',
    color: (style.textColor || '#616DED'),
    backgroundColor: isHovered ? (hoverTextColor || 'rgba(97, 109, 237, 0.2)') : 'transparent',
    padding: style.padding || '10px 15px',
    border: 'none',
    cursor: 'pointer',
    ...style,
  };

  return (
    <button
      onClick={onClick}
      style={buttonStyle}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {children}
    </button>
  );

};
  

CustomButton.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
};
CustomTextButton.propTypes = {
    onClick: PropTypes.func,
    style: PropTypes.object,
    children: PropTypes.node.isRequired,
    icon: PropTypes.node,
  };
