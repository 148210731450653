import React from 'react'
import { Box } from '@mui/material';
export default function BrandLogo({ user }) {

    const organizationId = user?.organization?.id || 2

    let logoConfig = {
        uri: "/assets/img/logos/brookline-logo.svg",
        w: { xs: "120px", md: "170px" },
        h: 'fit-content',
    };

    if (organizationId === 2 || organizationId === 3) {
        // Brookline Bank - Eng && Brookline Bank - Spa
        logoConfig = {
            uri: "/assets/img/logos/brookline-logo.svg",
            w: { xs: "130px", md: "170px" },
            h: 'fit-content',
        };
    } else if (organizationId === 4 || organizationId === 5) {
        // Healthy Relationship || Northwell
        logoConfig = {
            uri: "/assets/img/logos/northwell-logo.png",
            w: { xs: "140px", md: "190px" },
            h: 'fit-content',
        };
    } else if (organizationId === 1) {
        // City of Everett
        logoConfig = {
            uri: "/assets/img/logos/everett-logo.png",
            w: { xs: "100px", md: "150px" },
            h: 'fit-content',
        };
    }

    return (
        <Box
            component="img"
            sx={{
                width: logoConfig.w,
                height: logoConfig.h,
            }}
            src={logoConfig.uri}
            alt="Logo"
        />
    )
}