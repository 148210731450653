import {Link} from 'react-router-dom'
function EditBill() {
    return (
        <>
            <div className="appHeader">
                <div className="left">
                    <a href="#" className="headerButton goBack" onClick={() => window.history.go(-1)}>
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
            </div>
            <div id="appCapsule" className="bg-white" style={{ height: '100%' }}>
                <h2 className="mx-4 mt-3 text-center">Select the services you consider the bills</h2>
                <p className="text-left mx-4 pt-1">To setup your bills budget, we first need to know which of these services you consider bills(not including credit cards)</p>
                <ul className="listview image-listview inset" style={{ boxShadow: 'none' }} >
                    <li>
                        <div className="item" style={{ width: 'fit-content', alignItems: 'baseline' }}>
                            <div className="in text-left ">
                                <div className="icon-box bg-primary">s</div>
                            </div>
                            <p className="text-left"> <b>sample</b></p>
                        </div>
                    </li>
                </ul>
                <div className="space-between px-2 align-center mt-5" style={{ fontSize: '12px', lineHeight: '2' }}>
                    <p className="m-0 p-0">
                        Are you missing a bill from above ? <br /> You can add it here
                    </p>
                    <Link to="/add-bill" className="btn btn-outline-primary">+Add Bill</Link>
                </div>
                <div className="fixed-bar text-center">
                    <button className="btn btn-lg btn-center btn-block btn-primary ">Continue with 0 bills</button>
                </div>
            </div>
        </>
    );
}

export default EditBill;