import { useState } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import IconModal from './IconModal'
let bgColor = "bg-primary";
let color = "#616ded"
function AddCategory() {
    const [value, setValue] = useState(30)

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
        if (value < 10) {
            bgColor = "bg-dark";
            color = '#000';
        } else if (value > 20 && value < 40) {
            bgColor = "bg-primary"
            color = '#616ded'
        } else if (value > 40 && value < 60) {
            bgColor = "bg-warning"
            color = '#FFB400'
        } else if (value > 60 && value < 80) {
            bgColor = "bg-success"
            color = '#29bc6e'
        } else {
            bgColor = "bg-danger"
            color = '#FF396F'
        }
    };
    return (<>
        <IconModal />
        <div className={"appHeader apppHeader-history " + bgColor}>
            <div className="left">
                <a href="#" className="headerButton goBack text-white" onClick={() => window.history.go(-1)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div className="right">
                <Link to="/categories-and-rules" className="text-white"> Save</Link>
            </div>
        </div>

        <div className={"row mt-5 p-2 pl-3 " + bgColor}>
            <h3 className="text-white">Add New Category</h3>
            <p>Pic a name, color and icon for your category</p>
        </div>
        <div className="row p-2 space-between">
            <Box sx={{ minWidth: 120 }} className="space-between">
                <TextField
                    id="standard-search"
                    label="Name"
                    type="search"
                    variant="standard"
                    placeholder="(ex. Cofee)"
                />
                <a href="#" className="text-secondary" data-bs-toggle="modal" data-bs-target="#iconModal"><p className="p-0 m-0 mr-5" >Icon</p> <KeyboardArrowDownRoundedIcon className="ml-5" /></a>
            </Box>
            <div className="space-between mt-4 align-center">
                <div className="color-slider">
                    <label htmlFor="color">Color</label>
                    <div id="color" style={{ width: "30px", height: "30px", borderRadius: "6px" }} className={bgColor}></div>
                </div>
                <Box sx={{ width: 250 }} className="mt-3">
                    <Slider
                        aria-label="Temperature"
                        
                        onChange={handleSliderChange}
                        style={{ color: color }}
                    />
                </Box>
            </div>
            <div className="input-list p-2">
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="customCheckc1" />
                    <label className="form-check-label" htmlFor="customCheckc1">Include transaction in pending</label>
                </div>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="customCheckc2" />
                    <label className="form-check-label" htmlFor="customCheckc2">Include transaction in income</label>
                </div>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="customCheckc3" />
                    <label className="form-check-label" htmlFor="customCheckc3">Mark transaction as tex deductible</label>
                </div>
            </div>
            <button className={"btn btn-lg btn-block " + bgColor}>Save</button>



        </div>
    </>);
}

export default AddCategory;