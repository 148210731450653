import * as Axios from "axios";
const API_URL = process.env.REACT_APP_API_LINK;
const axios = Axios.create({
  headers: { "x-api-key": process.env.REACT_APP_X_API_KEY },
});

export const getUserPodcasts = () => {
  if (sessionStorage.getItem("token") !== null) {
    return new Promise(async (res, rej) => {
      try {
        const resp = await axios.get(`${API_URL}/podcast-submission/me`, {
          headers: {
            Authorization: `Stickball ${sessionStorage.getItem("token")}`,
          },
        });
        return res(resp.data);
      } catch (error) {
        rej(error);
      }
    });
  }
};

export const makePodcastSubmission = (podcast_id, body) => {
  if (sessionStorage.getItem("token") !== null) {
    return new Promise(async (res, rej) => {
      try {
        const resp = await axios.put(
          `${API_URL}/podcast-submission/${podcast_id}`,
          body,
          {
            headers: {
              Authorization: `Stickball ${sessionStorage.getItem("token")}`,
            },
          }
        );
        return res(resp.data);
      } catch (error) {
        rej(error);
      }
    });
  }
};

export const createTranscription = (payload) => {
  if (sessionStorage.getItem("token") !== null) {
    return new Promise(async (res, rej) => {
      try {
        const resp = await axios.post(
          `${API_URL}/podcast/transcription`,
          payload,
          {
            headers: {
              Authorization: `Stickball ${sessionStorage.getItem("token")}`,
            },
          }
        );
        res(resp.data);
      } catch (error) {
        rej(error);
      }
    });
  }
};

export const createTranscriptionV2 = (payload) => {
  if (sessionStorage.getItem("token") !== null) {
    return new Promise(async (res, rej) => {
      try {
        const resp = await axios.post(
          `${API_URL}/play-ht/transcription/v2`,
          payload,
          {
            headers: {
              Authorization: `Stickball ${sessionStorage.getItem("token")}`,
            },
          }
        );
        res(resp.data);
      } catch (error) {
        rej(error);
      }
    });
  }
};

export const getTranscriptionStatus = (transcriptionId) => {
  if (sessionStorage.getItem("token") !== null) {
    return new Promise(async (res, rej) => {
      try {
        const resp = await axios.get(
          `${API_URL}/podcast/transcription/${transcriptionId}`,
          {
            headers: {
              Authorization: `Stickball ${sessionStorage.getItem("token")}`,
            },
          }
        );
        res(resp.data);
      } catch (error) {
        rej(error);
      }
    });
  }
};

export const getTranscriptionVoices = () => {
  if (sessionStorage.getItem("token") !== null) {
    return new Promise(async (res, rej) => {
      try {
        const resp = await axios.get(
          `${API_URL}/play-ht/transcription-voices`,
          {
            headers: {
              Authorization: `Stickball ${sessionStorage.getItem("token")}`,
            },
          }
        );
        res(resp.data);
      } catch (error) {
        rej(error);
      }
    });
  }
};
