
import * as Axios from 'axios';
const API_URL = process.env.REACT_APP_API_LINK;
const axios = Axios.create({
    'headers': { 'x-api-key': process.env.REACT_APP_X_API_KEY }
})

export const getdictionaries = async (type) => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.get(`${API_URL}/words-glossary`,

            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        return res.data;
    }

}



// export const getEnrollment = async () => {
//     if (sessionStorage.getItem("token") !== null) {
//         const res = await axios.get(`${API_URL}/get-enrollment`,
//             {
//                 headers: {
//                     'Authorization': `Stickball ${sessionStorage.getItem('token')}`
//                 }
//             });
//         return res.data;
//     }

// }


// export const resetCalculator = async () => {
//     if (sessionStorage.getItem("token") !== null) {
//         const res = await axios.get(`${API_URL}/reset-budgetcalculator`,
//             {
//                 headers: {
//                     'Authorization': `Stickball ${sessionStorage.getItem('token')}`
//                 }
//             });
//         return res.data;
//     }

// }

