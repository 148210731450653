import React, { useState } from 'react';
import { Button, Box, Typography, IconButton } from '@mui/material';
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function Popup() {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false)
    };
    return (
        <>
            <Button variant='outlined' onClick={() => setOpen(true)}>Disclaimer</Button>
            {
                open &&
                <Box
                    sx={alertStyle}>
                    <Box sx={{ borderRadius: "25px", background: (theme) => theme.palette.primary.main, p: 2 }}>
                        <Box width={"100%"} display={"flex"} justifyContent="space-between">
                            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", mb: 1 }}>
                                <NewReleasesRoundedIcon color="error" />
                                <Typography variant='body1' color={"white"} fontWeight={600}>Disclaimer</Typography>
                            </Box>
                            <IconButton sx={{ color: theme => theme.palette.common.white }} onClick={handleClose}>
                                <CloseRoundedIcon />
                            </IconButton>
                        </Box>
                        <Typography sx={{ fontSize: "12px" }} color="white" textAlign="left">
                            Information on this website is made available to you only as self-help tools for your independent use
                            and is not intended to provide financial or tax advice.
                            We cannot and do not guarantee its applicability or accuracy in regard to your individual circumstances.
                            All examples are for illustrative purposes only.
                        </Typography>
                    </Box>
                </Box >
            }

        </>

    );
}

const alertStyle = {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    right: { sm: 10, xs: 3 },
    bottom: { sm: 10, xs: 3 },
    left: { sm: "auto", xs: 3 },
    maxWidth: "400px",
    zIndex: 9999,
    backgroundColor: (theme) => theme.palette.common.whtie,
    boxShadow: "0 0 5px 1px rgba(0, 0, 0, 0.1)",
    padding: '1px',
    borderRadius: "25px",
}