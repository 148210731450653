import React, { useState } from "react";

function CardRapper({ children, title }) {
    return <div className="cal-card">
        <div className="cal-card-title" >
            <span>{title || "--"}</span>
        </div>
        <div className="cal-card-body">
            {children}
        </div>
    </div>

}

export default CardRapper;
