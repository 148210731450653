export const componentsDefaults = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: "8px",
        textTransform: "capitalize",
        height: "fit-content",
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: "32px",
        padding: "24px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "32px",
        padding: "21px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      },
    },
  },
  MuiIconButton: {
    variants: [
      {
        props: { variant: "no-padding" },
        style: {
          padding: 0,
          "& svg": {
            fontSize: "40px",
          },
        },
      },
    ],
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        borderRadius: "8px",
      },
    },
  },
};
