import { Link } from 'react-router-dom';

function GetCreditScore() {
    return (<>
        <div className="appHeader">
            <div className="left">
                <a href="#" className="headerButton goBack" onClick={() => window.history.go(-1)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div className="pageTitle">
            </div>
        </div>
        <div className="card m-2 p-3">
            <div className="container pt-5 mt-5">
                <div className="row">
                    <h2>Get your credit score</h2>
                    <p>Please complete the form below to requist your score from experian</p>
                </div>
                <div className="row">
                    <div className="col">
                        <input className="form-control-input" type="text" placeholder="First Name" />
                    </div>
                    <div className="col">
                        <input className="form-control-input" type="text" placeholder="Last Name" />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <input className="form-control-input" type="text" placeholder="Address" />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <input className="form-control-input" type="text" placeholder="SSN" />
                    </div>
                    <div className="col">
                        <input className="form-control-input" type="text" placeholder="DOB" />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="radioList" id="radioList3" />
                        <label className="form-check-label" htmlFor="radioList3">
                            I accept the Truebil <a href="#">Terms of Service</a> related to Credit Scores
                        </label>
                    </div>
                </div>
                <div className="fixed-bar text-center">
                    <div>
                        <p className="text-center">Don't worry, this won't impact your score</p>
                        <Link to="/credit-mcqs"><button className="btn btn-lg btn-primary btn-block mb-2">Continue</button></Link>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default GetCreditScore;