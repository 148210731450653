import { makeStyles } from "@mui/styles";

// Create shared styles
const sharedStyles = (theme) => ({
  boxBase: {
    borderRadius: "12px",
    padding: "2px",
    overflow: "hidden",

  }
});

export const useStyles = makeStyles((theme) => {
  const shared = sharedStyles(theme); // Use shared styles

  return {
    flexColCenterVertical: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    flexColCenter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    footerStyle: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      background: theme.palette.common.white,
    },
    flexStart: {
      display: "flex",
      justifyContent: "flex-start",
    },
    incomeExpenseCard: {
      ...shared.boxBase,
      background: `linear-gradient(180deg, ${theme.palette.primary.main}, transparent)`,
    },
    surplusCard: {
      ...shared.boxBase,
      background: `linear-gradient(180deg, ${theme.palette.common.green}, transparent)`,
    },
    deficitCard: {
      ...shared.boxBase,
      background: `linear-gradient(180deg, ${theme.palette.error.main}, transparent)`,
    },
    boxWidthGradientBorder: {
      height: "100%",
      width: "100%",
      background: theme.palette.common.white,
      padding: "25px 16px",
      display: "flex",
      justifyContent: 'space-between',
      alignItems: "center",
      borderRadius: "12px",
    },
    homeCard: {
      display: "flex",
      justifyContent: "space-around",
      gap: "16px",
      flexWrap: "wrap",
      maxHeight: "50vh",
      overflowY: "auto",
      overflowX: "hidden",
    },
    skillAssignmentButton: {
      position: "fixed",
      top: "56%",
      transform: "translateY(-50%)",
      zIndex: theme.zIndex.drawer + 2,
      background: `linear-gradient(90deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
      borderTopLeftRadius: "15px",
      borderBottomLeftRadius: "15px",
      boxShadow: theme.shadows[3],
      padding: theme.spacing(1),
      cursor: "pointer",
      transition: 'all 0.15s ease-in-out',
    }
  };
});
