import React from 'react';

export default function DevelopmentModel({ modalText, modalTitle }) {

    return (<>
        <div className="modal fade action-sheet" id="development" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
            <h1 style={{textAlign:'center', marginBottom:-20}}>{modalTitle}</h1>
                        <h5 className="modal-title"></h5>
                    </div>
                    <div className="modal-body p-5 text-center">
                        <p>{modalText || 'This application is just an MVP, These features will be added soon'}</p>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
