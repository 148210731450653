import { Card, Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Category from "./Category";

const CardSection = ({
  title,
  buttonText,
  onButtonClick,
  content,
  categoryData,
  type,
  singleSubCategoryHandler
}) => {
  const theme = useTheme();
  const budgetFilterData=categoryData?.filter((item) => item?.value !== 0 && item?.type === type)
  return (
    <Card sx={{ p: { xs: "14px", md: "28px" }, flex: 1, minHeight: "15rem" }}>
      <Box display="flex" justifyContent="space-between" mb="1rem">
        <Typography variant="h3">{title}</Typography>
        <Button
          variant="outlined"
          onClick={onButtonClick}
          startIcon={<AddCircleOutlineIcon />}
          sx={{
            color: theme.palette.common.text,
            borderRadius: "22px",
            border: `1px solid ${theme.palette.common.text}`,
          }}
        >
          {buttonText}
        </Button>
      </Box>

      {(budgetFilterData && budgetFilterData?.length > 0) ? (
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          height="9rem"
          overflow="auto"
          p
        >
          {budgetFilterData?.map((data, index) => (
              <Category key={index} data={data} type="Add" singleSubCategoryHandler={singleSubCategoryHandler}/>
            ))}
        </Box>
      ) : (
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.palette.common.background,
            borderRadius: "8px",
            height: "9.3rem",
          }}
        >
          <Typography variant="body2" color="text" p={2} textAlign="center">
            {content}
          </Typography>
        </Card>
      )}
    </Card>
  );
};

export default CardSection;
