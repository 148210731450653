import React from "react"
import "./index.css"
const InputField = ({ name, onChange, textaria, placeholder,required,error, width, height }) => {
    const isIframe = sessionStorage.getItem('isIframe');

    return (
        <div className="InputField-container" style={{width:width || "unset"}}>
            <p className="InputField-name">{name}</p>
            <div style={{width:"100%"}}>
            {textaria ?
                <textarea
                    className="resume-analyzer-textaria"
                    name="Description"
                    id=""
                    cols="55"
                    rows="5"
                    required={required || false}
                    placeholder={placeholder}
                    style={{ width: "100%",height:height || "unset", border:"0.5px solid lightgrey", fontSize:isIframe == "true" && 12 }}
                    onChange={onChange} /> :
                <input onChange={onChange}
                    placeholder={placeholder}
                    style={{fontSize:isIframe == "true" && 12}}
                    className="InputField-inp-style" />}
                {error&&<p style={{color:"red",margin:0,alignSelf:"flex-start", position:"absolute"}}>{error}</p>}
                </div>
        </div>
    )
}
export default InputField