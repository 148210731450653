import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { CustomButton, CustomTextButton } from "../Button";
import { Typography } from "@mui/material";

export const WarningModal = (props) => {
  return (
    <Dialog
      fullWidth
      open={props.open}
      close={props.close}
      TransitionComponent={props.transition}
      maxWidth="sm"
      PaperProps={{
        sx: {
          height: 200,
        },
      }}
    >
      <DialogContent className="mt-3">
        <Typography>
          Are you sure you want to discard all the information and go back?
        </Typography>
      </DialogContent>
      <DialogActions className="mb-3" sx={{ px: 5 }}>
        <CustomTextButton
          style={{}}
          className="mt-2"
          data-bs-dismiss="modal"
          onClick={props.close}
        >
          Cancel
        </CustomTextButton>
        <CustomButton
          onClick={() => {
            props.handleCloseSelectedModal();
            props.close();
          }}
          className="mt-2"
          style={{
            backgroundColor: "#EB4747",
            marginLeft: "10px",
          }}
        >
          Yes, Discard
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};
