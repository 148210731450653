import { Link } from 'react-router-dom'
// import Field1 from './Field1'
function CreditScoreStarting() {
    return (<>
        <div className="appHeader bg-primary">
            <div className="left">
                <a href="#" className="headerButton text-white goBack" onClick={() => window.history.go(-1)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div className="pageTitle">
                Credit Score
            </div>
        </div>
        <div className="container mt-5 pt-5">
            <div className="row">
                <div className="card bg-primary p-3">
                    <h2 className="text-white">Activate your free credit score</h2>
                    <p>Some advantages are...</p>
                    <ul>
                        <li>See how lender view you</li>
                        <li>No cost to you</li>
                        <li>Only takes a minut</li>
                    </ul>

                    <Link to="/welcome"><button className="btn btn-lg btn-block btn-outline-primary bg-white">Get my free score</button></Link>
                </div>
            </div>
            <div id="appCapsule" className="bg-white m-0 p-0 mt-4">
                <div className="listview-title mt-2 d-flex justify-content-between">CREDIT REPORT CARD</div>
                <ul className="listview image-listview inset">
                    {/* <Field1 />
                    <Field1 />
                    <Field1 />
                    <Field1 />
                    <Field1 /> */}
                </ul>
            </div>
        </div>
    </>);
}

export default CreditScoreStarting;