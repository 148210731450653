import React, { useEffect, useState } from 'react';
import { Avatar, Box, Container, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography, } from '@mui/material';
import CareerList from './careersList';
import DreamJobInput from './dream-job-input';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DreamJobSearch = () => {
    const [randerState, setRanderState] = useState(0)
    const [recomendedJobs, setRecomendedJobs] = useState([])
    const navigate = useNavigate()
    return (
        <Container
            sx={{
                overflowX: "auto",
                alignItems: "center",
                display: "flex",
                padding: 2,
                justifyContent: "center",
                '@media (max-width: 600px)': {
                    justifyContent: "unset"
                },
            }} >
            <Paper
                sx={{
                    padding: 2,
                    marginTop: 25,
                    backgroundColor: "#616DED",
                    borderRadius: 10,
                    position: "relative",
                }}
                elevation={4}>
                <div
                    data-bs-toggle="modal"
                    data-bs-target="#careerCompass"
                    onClick={() => {
                        navigate("/", { replace: true })
                    }}

                    style={{
                        height: "40px",
                        width: "40px",
                        backgroundColor: '#ffffff',
                        position: 'absolute',
                        top: 20,
                        left: 20,
                        borderRadius: "20px",
                        transition: "background-color 0.3s ease",
                        cursor: "pointer",
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex'
                    }}
                    onMouseEnter={(e) => { e.target.style.backgroundColor = "#f0f0f0"; }}
                    onMouseLeave={(e) => { e.target.style.backgroundColor = "#ffffff"; }}
                >
                    <ArrowBackIcon
                        onMouseEnter={(e) => { e.target.style.backgroundColor = "#f0f0f0"; }}
                        onMouseLeave={(e) => { e.target.style.backgroundColor = "#ffffff"; }}
                        color="#616DED" />
                </div>
                <Box
                    width={'100%'}
                    justifyContent={'center'}
                    display={'flex'}>
                    <Typography variant="h4" color="white" textAlign={'center'} gutterBottom>
                        Discover Jobs <br />
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    width={700}
                    sx={{
                        minHeight: 394,
                        '@media (max-width: 700px)': {
                            width: "600px",
                            maxHeight: 'auto'
                        },
                    }}
                    backgroundColor={randerState == 0 || randerState == 2 ? "#fff" : "#fff3"}
                    padding={2}
                    borderRadius={10}
                    height={'50vh'}
                >
                    {
                        randerState == 0 ?
                            <DreamJobInput setRanderState={setRanderState} setRecomendedJobs={setRecomendedJobs} /> :
                            randerState == 2 ? <CareerList recomendedJobs={recomendedJobs} setRanderState={setRanderState} /> : null
                    }
                </Box>
            </Paper>
        </Container>
    );
};

export default DreamJobSearch;
