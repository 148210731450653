import React, { useState } from 'react';
import startCase from 'lodash.startcase'
import Loading from '../common/loading';
import { updateBudgetEntry } from '../_api/index'
import { useMutation, useQueryClient } from 'react-query';
import CurrencyFormat from 'react-currency-format';
import toast from 'react-hot-toast';
import FormControl from '@mui/material/FormControl';


export default function EditModel(props) {
    const queryClient = useQueryClient();
    console.log({ props });
    const { isLoading: isLoading, isSuccess: isSuccess, mutate: updateAmount } = useMutation(updateBudgetEntry, {
        onSuccess: () => {
            queryClient.invalidateQueries('budgeting-calculators');
            toast.success("Updated Successfully")
        },
    });
    const [valueInput, setValueInput] = useState(props.value)
    const [descriptionInput, setDescriptionInput] = useState(props.description)

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            id: props.id,
            amount: parseInt(valueInput),
            mainCategory: "NetWorth",
            subCategory: props.title,
            type: props.type,
            description: descriptionInput
        }
        updateAmount(data);
    }
    if (isLoading && !isSuccess) {
        return (<Loading />)
    } else
        return (
            <div key={props.title} className="modal fade action-sheet" id={"mod" + props.id} tabIndex="-1" role="dialog">
                <div className="modal-dialog   modal-dialog-centered" role="document">
                    <div className="modal-content income-inner-content m-auto">
                        <div className="modal-header">
                            <h5 className="modal-title text-dark">{startCase(props.title)}</h5>
                        </div>
                        <div className="modal-body mb-2">
                            <div className="action-sheet-content px-0">
                                <div className="form-group basic changing-modal pt-4">
                                    <span className="dialog1-btn" onClick={() => { setValueInput(valueInput > 0 ? parseInt(valueInput) - 1 : 1) }}><b>-</b></span>
                                    <div className="input-group mb-2">
                                        <CurrencyFormat
                                            value={valueInput !== 0 && valueInput !== "0" && valueInput !== "" ? valueInput : ""}
                                            thousandSeparator={true} prefix={'$'}
                                            // type="number"
                                            onValueChange={(values) => {
                                                const { value } = values;
                                                setValueInput(value)
                                            }}
                                            style={{ textAlign: 'center', fontSize: "30px" }}
                                            className="form-control text-primary"
                                            inputmode="numeric" pattern="[0-9]*" type="text" name="creditcard"
                                            placeholder="$0"
                                        />
                                        {/* <input type="text" className="form-control text-primary" onChange={(e) => setValueInput(inputCurrencyFormat(e.target.value))} placeholder="0"
                                                value={valueInput !== 0 && valueInput !== "0" && valueInput !== "" ? inputCurrencyFormat(valueInput) : ""}
                                                style={{ textAlign: 'center', fontSize: "30px" }} /> */}
                                    </div>
                                    <span className="dialog1-btn" onClick={() => { setValueInput(valueInput > 0 ? parseInt(valueInput) + 1 : 1) }}><b>+</b></span>
                                </div>
                                <div className="form-group pe-3 mb-1">
                                    <FormControl fullWidth sx={{ m: 1 }}>
                                        <label className="label mb-2">Description</label>
                                        <textarea class="form-control text_area_bg" id="exampleFormControlTextarea1" rows="6" defaultValue={props?.description}
                                            onChange={(e) => {
                                                setDescriptionInput(e.target.value)
                                            }}
                                        ></textarea>
                                    </FormControl>
                                </div>
                                <div data-bs-dismiss="modal" className="form-group basic">
                                    <button onClick={handleSubmit} className="btn btn-primary btn-block btn-lg"
                                    >Set Amount</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
}
