import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
export default function Header() {
  const navigate = useNavigate();
  var url = new URL(window.location.href);
  var tabRef = url?.searchParams.get("tab") || "debt";
  console.log("window",window.location.href.split("?")[1])
  return (
    <>
      <div className="appHeader py-5" style={{border: 'none'}}>
        <div className="left ml-3 top_back_icon">
          <Link
            to="/net-worth"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="headerButton goBack"
          >
            <ion-icon name="chevron-back-outline"></ion-icon>
            <h4>Go Back</h4>
          </Link>
        </div>
        <h1 className="modal-title text-primary"><AddIcon className="add-icon-asset"/> {tabRef === "debt" ? "Add Debt" : "Add Asset" }</h1>
      </div>

      {/* <div className="appHeader">
            <div className="left">
                <Link to="/net-worth" onClick={(e) => {
                    e.preventDefault()
                    navigate(-1)
                }} className="headerButton goBack">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                    
                </Link>
            </div>
            <div className="pageTitle">
                + Add
            </div>
        </div> */}
    </>
  );
}
