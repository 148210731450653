import React, { useState } from "react";
import "./index.css"
import recordIcon from "../../../assets/images/record.png"
import {
  Container,
  Box,
  Typography,
  Grid,
  Button
} from "@mui/material";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import RecordVideo from "../record-video/record-video";


// const allSteps = [
//     // {
//     //     steps: [
//     //         {
//     //             label: "Problem",
//     //             description:
//     //                 "This section tells us the most important problem we are trying to solve",
//     //             value: "problem" // Change value to a string
//     //         },
//     //         {
//     //             label: "Environment",
//     //             description:
//     //                 "This section tells us what different people think about the problem",
//     //             value: "environment" // Change value to a string
//     //         },
//     //     ]
//     // },
//     // {
//     //     steps: [
//     //         {
//     //             label: "People",
//     //             description:
//     //                 "Do we need more or less people?",
//     //             value: "people" // Change value to a string
//     //         },
//     //         {
//     //             label: "Process",
//     //             description:
//     //                 "Do we need to change the current processes, system workflows, and policies?",
//     //             value: "process" // Change value to a string
//     //         },
//     //         {
//     //             label: "Technology",
//     //             description:
//     //                 "Do we need to change current features and capabilities?",
//     //             value: "technology" // Change value to a string
//     //         },
//     //         {
//     //             label: "Cost",
//     //             description:
//     //                 "What is the cost to implement?",
//     //             value: "cost" // Change value to a string
//     //         },
//     //         {
//     //             label: "Budget Type (Ops or Capital)",
//     //             description:
//     //                 "Financial plan for day-to-day expenses or long-term investments",
//     //             value: "capitalBudget" // Change value to a string
//     //         },
//     //         {
//     //             label: "Considerations",
//     //             description:
//     //                 "What other projects are going on?",
//     //             value: "consideration" // Change value to a string
//     //         },
//     //     ]
//     // },
//     // {
//     //     steps: [
//     //         {
//     //             label: "ROI (Return on Investment)",
//     //             description:
//     //                 "It is a measure used to calculate how much profit or benefit you gain from an investment compared to its cost.",
//     //             value: "roi" // Change value to a string
//     //         },
//     //         {
//     //             label: "ROI Realization",
//     //             description:
//     //                 "ROI Realization is when you get the profit you expected from your investment.",
//     //             value: "roi2" // Change value to a string
//     //         },
//     //     ]
//     // },
//     {
//         steps: [
//             {
//                 label: "Prioritize",
//                 description: "",
//                 value: "prioritize" // Change value to a string
//             },
//             {
//                 label: "Plan",
//                 description: "",
//                 value: "plan" // Change value to a string
//             },
//             {
//                 label: "Resources",
//                 description: "",
//                 value: "resources" // Change value to a string
//             },
//             {
//                 label: "Timeline",
//                 description: "",
//                 value: "timeline" // Change value to a string
//             },
//             {
//                 label: "KPIs",
//                 description: "",
//                 value: "kpis" // Change value to a string
//             },
//         ]
//     }
// ];

const InterviewQuestions = ({ setIsloading, setState, questions, setAnswers, answers }) => {
  console.log("🚀 ~ InterviewQuestions ~ answers:", answers)
  const [questionInd, setQuestionInd] = useState(0)
  let lenghtOfQuestions = questions?.length

  return (
    <div className="interview-questions-container">
      <ConditionalRendering setAnswers={setAnswers} answers={answers} questions={questions} lenghtOfQuestions={lenghtOfQuestions} setQuestionInd={setQuestionInd} questionInd={questionInd} setIsloading={setIsloading} setState={setState} />
    </div>
  )
}


const ConditionalRendering = ({ setAnswers, answers, setState, setQuestionInd, questionInd, lenghtOfQuestions, questions }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleBack = () => {
    if (!questionInd == 0) {
      setQuestionInd(prev => prev - 1)
    } else {
      setQuestionInd(0)
    }
  }
  const handleNext = () => {
    console.log(questionInd)
    if (questionInd + 1 == questions.length) {
      // if(questionInd+1 == 2){
      setState(2)
    }
    if (questionInd == lenghtOfQuestions - 1) {
      setQuestionInd(0)
    } else {
      setQuestionInd(prev => prev + 1)
    }
  }
  let grade = answers[questionInd]?.grade
  return (
    <Grid container spacing={1.3} sx={{ width: "100%" }}
      // style={{ justifyContent: 'space-evenly' }}
      className="questions-container">
      <div style={{ display: "flex", justifyContent: "space-around", width:"100%" }}>
        <Grid
          className="questions-container-1"
          sx={{
            padding:"6px",
            // maxHeight :{xs:"320px", md:"400px"},
            height:"80vh",
            overflowY: "auto",
            WebkitOverflowScrolling: "touch", // Enable momentum scrolling for iOS
            "&::-webkit-scrollbar": {
              width: "5px", // Adjust the width of the scrollbar
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.3)", // Adjust the color of the scrollbar thumb
              borderRadius: "5px", // Adjust the border radius of the scrollbar thumb
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the color of the scrollbar thumb on hover
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent", // Set the background color of the scrollbar track to transparent
            },
          }}
          item
          sm={6}
          width={"100%"}
          alignContent={"start"}
          display={"grid"}
        >
          <Box className="question-box">
            <Typography variant="h6">Question</Typography>
            <Typography sx={{ mt: 1, mb: 2, fontSize: { xs: '12px', md: '16px' } }}>
              {questions[questionInd]}
            </Typography>
          </Box>
          {isLoading ? <></>
            : answers[questionInd]?.feedback ?
              <Box className="question-box" sx={{ mt: 2, position: "relative" }}>
                <Typography sx={{ mt: 1, mb: 2, position: "absolute", right: 14, top: 12, fontSize: { xs: '12px', md: '16px' }, display: { xs: "block", md: 'none' } }}>
                  Grade : {grade >= 6 && grade < 8 ? "Good" : grade >= 8 ? "Best" : grade < 6 ? "Bad" : null}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: { xs: '16px', md: '18px' } }}>Your Answer : </Typography>
                <Typography sx={{ mt: 1, mb: 2, fontSize: { xs: '12px', md: '16px' } }}>
                  {answers[questionInd]?.ans_content}
                </Typography>

                <Typography sx={{ fontSize: { xs: '16px', md: '18px' } }} variant="h6">Feedback : </Typography>
                <Typography sx={{ mt: 1, mb: 2, fontSize: { xs: '12px', md: '16px' } }}>
                  {answers[questionInd]?.feedback}
                </Typography>
                <Typography sx={{ mt: 1, mb: 2, position: { sx: 'relative', md: 'absolute' }, right: 14, top: 12, fontSize: { xs: '12px', md: '16px' }, display: { xs: "block", md: 'none' } }}>
                  Grade : {grade >= 6 && grade < 8 ? "Good" : grade >= 8 ? "Best" : grade < 6 ? "Bad" : null}
                </Typography>
              </Box>
              : null}
        </Grid>
        <RecordVideo isLoading={isLoading} setState={setState} questionInd={questionInd} setAnswers={setAnswers} answers={answers} setIsLoading={setIsLoading} questions={questions} question={questions[questionInd]} />
      </div>
      <Box sx={{ mt: 1.3 }} className="slide-box">
        <Box className="row-between" sx={{ p: '8px 4px' }}>
          <Button onClick={handleBack} className="btn-back" startIcon={<ArrowBackRoundedIcon />} >Previous</Button>
          <Typography sx={{ fontSize: { xs: '10px', md: '16px' } }} className="question-index-text">Question {questionInd + 1} of {questions?.length}</Typography>
          <Button onClick={handleNext} disabled={!answers[questionInd]?.ans_content} className="btn-back" endIcon={<ArrowForwardRoundedIcon />} >{questionInd + 1 == questions?.length ? "Submit" : "Next"}</Button>
        </Box>
      </Box>
    </Grid>
    // <Container maxWidth="lg" >
    // </Container>
  )
}
const styles = {
  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}
export default InterviewQuestions
