import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "./Header";
import Screen1 from './Screen1'
import Screen2 from './Screen2'
import Screen3 from './Screen3'

function StartingScreen() {
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);

    const colors = [
        <Screen1 />,
        <Screen2 />,
        <Screen3 />,
    ]

    const nextIndex = () => {
        setIndex((prevIndex) =>
            prevIndex === colors.length - 1 ?
                navigate("/get-credit-score")
                : prevIndex + 1
        )
    }

    return (<>
        <Header />
        <div id="appCapsule" className="mt-5 pt-5">

            <div className="slideshow mt-5 pt-5">
                <div
                    className="slideshowSlider mt-5"
                    style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                >
                    {colors.map((backgroundColor, index) => (
                        <div
                            className="slide"
                            key={index}
                        // style={{ backgroundColor }}
                        >{backgroundColor}</div>
                    ))}
                </div>

                <div className="slideshowDots">
                    {colors.map((_, idx) => (
                        <div
                            key={idx}
                            className={`slideshowDot${index === idx ? " active" : ""}`}
                            onClick={() => {
                                setIndex(idx);
                            }}
                        ></div>
                    ))}
                </div>
            </div>

            <div className="fixed-bar">
                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-lg text-white bg-primary btn-block" onClick={() => nextIndex()}>
                        {
                            index === 2 ? <>Active my score</> : <>Next</>
                        }
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </>);
}

export default StartingScreen;