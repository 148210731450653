import { useState } from 'react'
import { Link } from 'react-router-dom'
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

function SeacrchTransaction() {
    const [value, setValue] = useState("")
    return (<>
        <div className="appHeader">
            <div className="left">
                <a href="#" className="headerButton goBack" onClick={() => window.history.go(-1)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div className="pageTitle">

            </div>
            <div className="right" data-bs-toggle="modal" data-bs-target="#exportModel">
                <Link to="#" onClick={() => window.history.go(-1)} className="mr-5 text-dark">Skip</Link>
                <Link to="#" onClick={() => window.history.go(-1)}>Continue(0)</Link>
            </div>
        </div>
        <div id="appCapsule" className="bg-white" style={{ height: '100%' }}>
            <h2 className="mx-3 mt-3">Find Transactions</h2>
            <p className="text-left mx-3">Search below and select any transactions that are associated with this bill</p>
            <form action="/" className="p-4">
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Input
                        fullWidth
                        id="standard-search"
                        type="search"
                        variant="standard"
                        placeholder="Search my transactions"
                        style={{ outline: 'none', border: 'none' }}
                        onChange={(e) => { setValue(e.target.value) }}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                    />
                </Box>
            </form>
            {
                value.length > 3
                &&
                <ul className="listview image-listview inset" style={{ boxShadow: 'none' }} >
                    <li>
                        <Link to="/search-transaction" className="item" style={{ width: 'fit-content', alignItems: 'baseline' }}>
                            <div className="in text-left ">
                                <div className="icon-box text-primary">+</div>
                            </div>
                            <p className="text-left text-primary"> <b>Add"{value}"</b></p>
                        </Link>
                    </li>
                </ul>
            }
        </div>
    </>);
}

export default SeacrchTransaction;