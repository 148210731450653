import { Box, Button, Typography } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const InterestsInfo = ({ setRanderState }) => {
    return (
        <Box
        style={{maxHeight:"20%", flex:1}}
         width={"90%"} display={"flex"} flexDirection={'column'} alignItems={'center'}>
            {/* <Box
                display="flex"
                alignItems="center"
                padding={0}
                gap={0.3}
            > */}
                <div style={{ textAlign: 'center', padding: '20px' }}>

                    <Typography variant="body1" paragraph>
                        Welcome to our career exploration tool designed to help you uncover your interests
                        and align them with potential career opportunities. By answering a series of questions,
                        you'll gain insights into the types of activities and roles that resonate with you the most.
                    </Typography>

                    <Typography variant="h6" fontWeight={'bold'} color={'black'} gutterBottom>
                        Let's Get Started:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        In Next page their are 60 diverse questions covering a wide range of
                        activities and interests. Take your time to respond to each one, and let us
                        guide you towards potential career paths that align with your passions.
                    </Typography>
                </div>
            {/* </Box> */}
            <Box width={'90%'} padding={1} position={'relative'} display={'flex'} justifyContent={"flex-end"} >
                <Button
                    onClick={() => { setRanderState(1) }}
                    // disabled={length_of_questions / pagination?.pageNum == 8 ||
                    //  length_of_questions / pagination?.pageNum < 8 || !onePageData}
                    endIcon={<ArrowForwardIcon />} variant="contained" color="primary">
                    <Typography variant="button">Next</Typography>
                </Button>
            </Box>
        </Box>
    )
}
const styles = {
    textWhite: { fontWeight: "bold", color: "black" }
}
export default InterestsInfo