import * as Axios from 'axios';

// const API_URL = 'http://localhost:4000/client';
const API_URL = process.env.REACT_APP_API_LINK;
const axios = Axios.create({
    'headers': { 'x-api-key': process.env.REACT_APP_X_API_KEY }
})
export const GetInterestBasedjobsBykeyword = async (keyword) => {
    // ${API_URL.replace("client", '')}
    const endpoint = `${API_URL}/prompt/get-profession-by-keyword`;
    try {
        const response = await axios.post(endpoint, {queryString:keyword}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`,
            },
        });
        console.log("🚀 ~ GetInterestBasedjobsBykeyword ~ result:", response.data)
        const result = response.data;
        return result
    } catch (err) {
        console.log("🚀 ~ GetInterestBasedjobsBykeyword ~ err:", err)
    }
};