import Header from './Header'
import { Link } from 'react-router-dom'
function CategoriesAndRules() {
    return (<>
        <Header />
        <div className="extraHeader pe-0 ps-0">
            <ul className="nav nav-tabs lined" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" data-bs-toggle="tab" href="#categories" role="tab">
                        Categories
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#rules" role="tab">
                        Rules
                    </a>
                </li>
            </ul>
        </div>

        <div id="appCapsule" className="extra-header-active full-height">
            <div className="section tab-content mt-2 mb-1">
                <div className="tab-pane fade show active" id="categories" role="tabpanel">

                    <p>Create and manage your custom categories below</p>

                    <div id="appCapsule">
                        <ul className="listview image-listview inset" >
                            <li>
                                <Link to="/add-category" className="item" style={{ width: 'fit-content', alignItems: 'center' }}>
                                    <div className="in">
                                        <div className="icon-box bg-primary">+</div>
                                        <p className="text-primary mt-2"> <b>New Category</b></p>
                                    </div>
                                </Link>
                            </li>
                        </ul>

                    </div>

                </div>
                <div className="tab-pane fade" id="rules" role="tabpanel">
                    <p>Automatically Customize your transactions with Rules. (ex. Change Categories, names etc.</p>

                    <div id="appCapsule">
                        <ul className="listview image-listview inset" >
                            <li>
                                <Link to="/add-rule" className="item" style={{ width: 'fit-content', alignItems: 'center' }}>
                                    <div className="in">
                                        <div className="icon-box bg-primary">+</div>
                                        <p className="text-primary mt-2"> <b>New Rule</b></p>
                                    </div>
                                </Link>
                            </li>
                        </ul>

                    </div>
                </div>

            </div>

        </div>
    </>);
}

export default CategoriesAndRules;