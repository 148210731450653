import React from "react";
import { Link } from "react-router-dom";

export default function Field1(props) {
  return (
    <>
      <li
        onClick={props.onClick}
        key={props.keyId}
        data-bs-toggle="modal"
        data-bs-target="#development"
        style={{ fontFamily: "poppins" }}
      >
        <a className="item pl-1">
          <div className={"bg-squar " + props.bg}>{props.icon}</div>
          <div className="in">
            <span style={{ fontSize: "15px", fontWeight: 500 }}>
              {props.title}
              <footer style={{ fontSize: "11px", fontWeight: 400 }}>
                {props.sub}
              </footer>
            </span>
            <div className="text-dark" style={{ fontWeight: 400 }}>
              <b>{props.rightText}</b>
            </div>
          </div>
        </a>
      </li>
    </>
  );
}
