import React, { useEffect, useState } from 'react';
import { Avatar, Box, Container, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography, } from '@mui/material';
import Interests from './Interests';
import InterestsInfo from './interestsIntro';
import CareerList from './careersList';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import "./index.css"
const AssessmentsAndCounseling = () => {
    const [randerState, setRanderState] = useState(0)
    const [recomendedJobs, setRecomendedJobs] = useState([])
    const navigate = useNavigate();
    const isIframe = sessionStorage.getItem('isIframe');

    return (
        <Container
            sx={{
                overflowX: "auto",
                alignItems: "center",
                display: "flex",
                padding: 2,
                // height:"100vh",
                // flex:1,
                marginTop: isIframe !== "true"  ? "200px" : "24px",
                flexDirection:'column',
                justifyContent: "center",
                '@media (max-width: 600px)': {
                    justifyContent: "unset"
                },
            }} >
                {/* <div style={{height:"120px"}}></div> */}
            <Container
                maxWidth="md"
                sx={{
                    // marginTop: 15,
                    backgroundColor: "#616DED",
                    borderRadius: 10,
                    '@media (max-width: 600px)': {
                        width:"auto",
                        paddingTop:"40px"
                    },
                }}
                // padding={1}
                elevation={4}>
                <Box
                    width={'100%'}
                    justifyContent={'center'}
                    display={'flex'}
                    sx={{ padding: "10px" }}
                    style={{position:'relative'}}
                >
                    <div 
                        onClick={() => {
                            navigate("/",{replace:true})
                        }}
                        data-bs-toggle={"modal"}
                        data-bs-target={"#careerCompass"} 
                        className='assessment-back-btn'
                        onMouseEnter={(e) => { e.target.style.backgroundColor = "#f0f0f0"; }}
                        onMouseLeave={(e) => { e.target.style.backgroundColor = "#ffffff"; }}
                    >
                        <ArrowBackIcon
                        sx={{borderRadius:20}}
                        onMouseEnter={(e) => { e.target.style.backgroundColor = "#f0f0f0"; }}
                        onMouseLeave={(e) => { e.target.style.backgroundColor = "#ffffff"; }}
                         color="#616DED" />
                    </div>


                    <Typography variant="h4" color="white" textAlign={'center'} gutterBottom style={{ fontSize: '24px' }}>
                        Discover Your Career Path <br />
                        <Typography variant="h5" color="white" textAlign={'center'} gutterBottom style={{ fontSize: '20px' }}>
                            A Personalized Journey
                        </Typography>
                    </Typography>

                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    sx={{
                        '@media (max-width: 600px)': {
                            width: randerState ==1? "700px": "700px",
                            maxHeight: 'auto'
                        },
                    }}
                    backgroundColor={randerState == 0 || randerState == 2 ? "#fff" : "#fff3"}
                    // padding={2}
                    borderRadius={10}
                    // height={'50vh'}
                    minHeight={"300px"}
                    marginBottom={2}
                >
                    {
                        randerState == 0 ?
                            <InterestsInfo setRanderState={setRanderState} /> :
                            randerState == 1 ? <Interests setRanderState={setRanderState} setRecomendedJobs={setRecomendedJobs} /> :
                                randerState == 2 ? <CareerList recomendedJobs={recomendedJobs} setRanderState={setRanderState} /> : null
                    }
                </Box>
            </Container>
        </Container>
    );
};

export default AssessmentsAndCounseling;
