import "./index.css"
import success from "../../assets/images/success.gif"
const SuccessScreen = () => {
    return (
        <div className="success-scr-container">
            <div className="success-box">
            <img src={success} width={200}/>
            <p className="success-heading">Thank You</p>
            {/* <p className="success-text">Response recorded Successfully ! </p> */}
            </div>
        </div>
    )
}


// import "./index.css"
// import Rating from '@mui/material/Rating';

// // import success from "../../assets/images/success.gif"
// const SuccessScreen = ({answers}) => {
//     return (
//         <div className="success-scr-container">
//             <div className="success-box">
//             {
//                 answers.map((val,index)=>(
//                   <EachAnswerResult data={val} index={index}/>
//                 ))
//             }
//             </div>
//         </div>
//     )
// }
// const grade = () => {
       
//     if (grade >= 7) {
//         return 'A';
//     } else if (grade >= 4) {
//         return 'B';
//     } else if (grade >= 1) {
//         return 'C';
//     }
// }
// const EachAnswerResult = ({data,index}) => {
//     return (
//         <div key={index} className="question-answer">
//         <p className="question">Question :{data.question}</p>
//         <p className="answer">Answer :{data.answer}</p>
//         <p className="feedback">Feedback :{data.feedback}</p>
//         {grade === 'A' ? (
//             <Rating name="half-rating-read" defaultValue={1.5} precision={0.5} readOnly  style={{ fontSize: 20,  marginLeft: "510px" }}/>
//         ) : grade === 'B' ? (
//             <Rating name="half-rating-read" defaultValue={3.5} precision={0.5} readOnly  style={{ fontSize: 20,  marginLeft: "510px" }} />
//         ) : (
//             <Rating name="half-rating-read" defaultValue={4.5} precision={0.5} readOnly   style={{ fontSize: 20,  marginLeft: "510px" }} />
//         )}
//     </div>
//     )
// }
// const styles = {
//     sectionbox:{
//         display:"flex",
//         width:"80%",
//         flexDirection:'column'
//     }
// }
export default SuccessScreen