import React, { useState, useEffect, useRef, useCallback } from 'react';
import Webcam from "react-webcam";
import { Button, Box, Alert, Typography, CircularProgress } from '@mui/material';
import "./index.css"
import { interviewSubmitAnswers } from '../../../_api/interview-simulator';
// import LoadingDialog from '.././common/LoadingDialog';

export default function RecordVideo({ question, isLoading, setState, setIsLoading, setAnswers, answers, questionInd, questions }) {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [timer, setTimer] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [message, setMessage] = useState("");
    const [loadingMessage, setLoadingMessage] = useState("");
    const [permissionsGranted, setPermissionsGranted] = useState(false);

    const handleStartCaptureClick = useCallback(() => {
        askPermission()
        setCapturing(true);
        setTimer(0);
        setIsRunning(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
    }, [webcamRef, setCapturing, mediaRecorderRef]);

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
        setIsRunning(false);
    }, [mediaRecorderRef, webcamRef, setCapturing]);

    const handleStateUpdate = async () => {
        setMessage("");
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm",
            });
            setTimer(0);
            setRecordedChunks([]);
            setIsLoading(true)
            try {
                const response = await interviewSubmitAnswers(question, blob);
                let answers_copy = answers
                if (answers.find(val => val.question == question)) {
                    answers_copy[questionInd] = response;
                    setAnswers(answers_copy)
                    console.log("🚀 ~ handleQuestionGenerate ~ response: if", response)
                } else {
                    setAnswers([...answers, response])
                    console.log("🚀 ~ handleQuestionGenerate ~ response: else", response)
                }
                setIsLoading(false)
            } catch (error) {
                setIsLoading(false)
                // Handle errors
                console.error("Error:", error);
            }
            // Do something with recordedChunks (Blob object)
        }
    };

    useEffect(() => {
        if (timer >= 30000) {
            handleStopCaptureClick();
        }
    }, [timer]);

    useEffect(() => {
        let intervalId;

        if (isRunning) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1000); // Increase the timer by 1 second
            }, 1000);
        }

        return () => {
            clearInterval(intervalId); // Clean up the interval on component unmount
        };
    }, [isRunning]);

    const videoConstraints = {
        width: "380",
        height: "350",
        facingMode: "user",
    };

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600000);
        const minutes = Math.floor((time % 3600000) / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };
    const askPermission = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then(function (stream) {
                    setPermissionsGranted(true);
                })
                .catch(function (error) {
                    console.error('getUserMedia error:', error);
                    // Permission denied for camera or microphone
                    setPermissionsGranted(false);
                });
        } else {
            console.error('getUserMedia not supported on your browser');
        }
    }
    const checkPermissions = () => {
        navigator.permissions.query({ name: 'camera' }).then(function (cameraPermission) {
            navigator.permissions.query({ name: 'microphone' }).then(function (microphonePermission) {
                if (cameraPermission.state === 'granted' && microphonePermission.state === 'granted') {
                    setPermissionsGranted(true);
                } else {
                    setPermissionsGranted(false);
                }
            });
        });
    };

    useEffect(() => {
        // Check permissions when component mounts
        askPermission()
        checkPermissions();
    }, []);

    useEffect(() => {
        askPermission()
        setRecordedChunks([])
        setTimer(0)
    }, [questionInd])

    console.log("timer", timer)

    return (
        <>
            {/* <LoadingDialog open={isLoading} text={loadingMessage} /> */}
            {/* !answers[questionInd] */}
            <div style={{ display: "flex", flexDirection: "column" }}>
                {permissionsGranted ? <div className="web-cam">
                    {!recordedChunks.length && (
                        <Webcam
                            className='recorded-video-style'
                            style={{ borderRadius: '15px', transition: 'opacity 0.5s' }}
                            ref={webcamRef}
                            audio={true}
                            muted
                            mirrored
                            width="100%"
                            videoConstraints={videoConstraints}
                        />
                    )}
                    {!!recordedChunks.length && (
                        <video
                            controls
                            className='recorded-video-style'
                            src={recordedChunks.length > 0 ? URL.createObjectURL(new Blob(recordedChunks, { type: "video/webm" })) : null}
                            style={{

                                borderRadius: '15px',
                                marginTop: '20px',
                                opacity: recordedChunks.length > 0 ? 1 : 0,
                                transition: 'opacity 0.5s'
                            }}
                        />
                    )}
                    {!recordedChunks.length && <div
                        className="cam-stream-time"
                        style={{ backgroundColor: capturing ? "#ff2525" : "#ccc" }}
                    >
                        {formatTime(timer)}
                    </div>}
                    {!recordedChunks.length && <div className="cam-buttons">
                        {capturing ? (
                            <>
                                <button
                                    disabled={timer < 4000}
                                    style={{ cursor: "pointer", opacity: timer < 4000 ? "0.5" : 1 }}
                                    className="cam-stop"
                                    onClick={handleStopCaptureClick}
                                >
                                    <div className="stop-icon"></div>
                                </button>
                            </>
                        ) : (
                            <button
                                style={{ cursor: "pointer" }}
                                className="cam-start"
                                onClick={handleStartCaptureClick}
                            ></button>
                        )}
                    </div>}
                </div> :
                    <Typography
                        sx={{ borderRadius: '10px', textTransform: 'capitalize', fontWeight: "bold" }}
                        variant='contained' onClick={askPermission}>
                        Grant camera and microphone permissions !
                    </Typography>}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: { xs: '10px', md: '20px' }, mt: { xs: 1, md: 2 } }}>
                    <Typography sx={{ opacity: 0.7, fontSize: { xs: '12px', md: '16px' } }}>
                        Record a video max 30 seconds
                    </Typography>
                    <Box sx={{ display: 'flex', gap: { xs: '10px', md: '20px' }, justifyContent: 'center' }}>
                        <Button
                            disabled={(!recordedChunks.length && capturing) || timer === 0}
                            sx={{ borderRadius: '10px', textTransform: 'capitalize', fontSize: { xs: '10px', md: '14px' }, minWidth: '80px', padding: '8px 16px' }}
                            variant='outlined'
                            onClick={() => {
                                setTimer(0);
                                setRecordedChunks([]);
                            }}
                        >
                            Retake
                        </Button>
                        <Button
                            disabled={(!recordedChunks.length && capturing) || timer === 0}
                            sx={{
                                borderRadius: '10px',
                                textTransform: 'capitalize',
                                backgroundColor: '#616ded',
                                fontSize: { xs: '10px', md: '14px' },
                                minWidth: { xs: "80px", md: "120px" },
                                padding: { xs: "unset", md: '8px 16px' },
                            }}
                            variant='contained'
                            onClick={handleStateUpdate}
                        >
                            {isLoading ? <CircularProgress color="secondary" size={20} /> : "Submit Answer"} 
                        </Button>
                    </Box>
                </Box>

            </div>
            {/* {
                message &&
                <Alert sx={{ m: 2 }} severity="error">{message}</Alert>
            } */}
        </>
    );
}
