import React, { useState } from "react";
import { Button, Grid, TextField, Box } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const nextBtnStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  padding: "8px 20px",
  color: "#33C052",
  border: "1.5px solid #33C052",
  borderRadius: "10px",
  textTransform: "none",
  "&:hover": {
    background: "none",
  },
};

const sendBtnStyle = {
  color: "#33C052",
  borderRadius: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  padding: "8px 20px",
  "&:hover": {
    background: "rgba(51, 192, 82, 0.15)",
    color: "#0A9929",
  },
};

const headStyle = {
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "15px",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "0px 10px",
  gap: "10px",
  background: "#F0F1FF",
  borderRadius: "5px",
  color: "#242424",
};

const contentStyle = {
  display: "flex",
  padding: "15px 20px",
  flex: 1,
  gap: "20px",
  backgroundColor: "red",
};

const titleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "15px",
  color: "#9B9FAC",
  alignItems: "flex-start",
  minWidth: "140px",
};

const descStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
};

const personNameStyle = (index) => {
  return {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    color: index == 0 ? "#616DED" : "#FB785C",
  };
};

const Message = ({ index, username, text }) => {
  return (
    <Grid container>
      <Grid
        item
        xs={2.5}
        sx={{ color: index == 0 ? "#616DED" : "#FB785C", fontWeight: "bold" }}
      >
        {username}:
      </Grid>
      <Grid item xs={9.5} sx={{ color: "#000" }}>
        {text}
      </Grid>
    </Grid>
  );
};

const PodcastChat = (props) => {
  const { podcast, setPage, messages, setMessages } = props;
  const users = [
    {
      id: 0,
      name: "Person 1",
    },
    {
      id: 1,
      name: "Person 2",
    },
  ];

  const [user1Message, setUser1Message] = useState("");
  const [user2Message, setUser2Message] = useState("");
  const [isSubmitted1, setSubmited1] = useState(false);
  const [isSubmitted2, setSubmited2] = useState(false);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Grid
        container
        className="d-flex justify-content-between align-items-center overflow-hidden mb-1"
      >
        <Grid item lg={6} md={6} sm={12} className="d-flex align-items-center">
          <Button
            className="mb-1"
            variant="text"
            sx={{ minWidth: "unset", width: "fit-content" }}
            startIcon={
              <img
                width="150%"
                height="150%"
                src="assets/img/icon/back.svg"
                className="logo"
                alt=""
              />
            }
            onClick={() => {
              setPage("all");
            }}
          />
          <h1
            style={{
              fontfamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "20px",
              color: "#9B9FAC",
            }}
          >
            Podcast Assignments
          </h1>
        </Grid>
        <Grid item lg={6} md={6} sm={12} sx={{ textAlign: "right" }}>
          <Button
            sx={nextBtnStyle}
            onClick={() => {
              setPage("voice");
            }}
            disabled={messages.length == 0}
          >
            Next{" "}
            <img
              src="/assets/img/icon/rightArrow.svg"
              style={{ marginLeft: "13px" }}
            />
          </Button>
        </Grid>
      </Grid>
      <div style={headStyle}>Enter dialogue for both persons</div>
      <div style={{ display: "flex", height: "55vh" }}>
        <Grid container>
          <Grid container item lg={6} md={12} sm={12} p={1}>
            <Grid
              item
              sx={{
                width: "100%",
                height: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Grid
                container
                item
                justifyContent={"space-between"}
                sx={{ alignItems: "center" }}
              >
                <div
                  style={personNameStyle(0)}
                >{`Person 1`}</div>
                <Box>
                  <Button
                    endIcon={<SendIcon />}
                    variant="text"
                    color="success"
                    sx={sendBtnStyle}
                    onClick={() => {
                      setSubmited1(true)
                      if(user1Message.length > 2000) return

                      if (user1Message.trim() != "") {
                        setMessages([
                          ...messages,
                          {
                            user: users[0],
                            text: user1Message.replace(/\n/g, ". "),
                            voiceIndex: 0,
                          },
                        ]);
                        setUser1Message("");
                        setSubmited1(false)
                      }
                    }}
                  >
                    Send
                  </Button>
                </Box>
              </Grid>
              <Grid container item sx={{ height: "75%" }}>
                <TextField
                  value={user1Message}
                  fullWidth
                  multiline
                  rows={7}
                  error={(isSubmitted1 && !user1Message) || user1Message.length > 2000}
                  helperText={
                    (isSubmitted1 && !user1Message && "Please Enter Text") ||
                    (user1Message.length > 2000 && `Text must not exceed 2000 characters. Remove ${user1Message.length - 2000} characters.`)
                  }
                  onChange={(e) => {
                    setSubmited1(false)
                    setUser1Message(e.target.value);
                  }}
                  sx={{
                    height: "100%",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  InputProps={{ style: { height: "100%" } }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                width: "100%",
                height: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingTop: "10px",
              }}
            >
              <Grid
                container
                item
                justifyContent={"space-between"}
                sx={{ alignItems: "center" }}
              >
                <div
                  style={personNameStyle(1)}
                >{`Person 2`}</div>
                <Box>
                  <Button
                    endIcon={<SendIcon />}
                    variant="text"
                    color="success"
                    sx={sendBtnStyle}
                    onClick={() => {
                      setSubmited2(true)
                      if(user2Message.length > 2000) return
                      
                      if (user2Message.trim() != "") {
                        setMessages([
                          ...messages,
                          {
                            user: users[1],
                            text: user2Message.replace(/\n/g, ". "),
                            voiceIndex: 1,
                          },
                        ]);
                        setUser2Message("");
                        setSubmited2(false)
                      }
                    }}
                  >
                    Send
                  </Button>
                </Box>
              </Grid>
              <Grid container item sx={{ height: "75%" }}>
                <TextField
                  value={user2Message}
                  fullWidth
                  multiline
                  rows={7}
                  error={(isSubmitted2 && !user2Message) || user2Message.length > 2000}
                  helperText={
                    (isSubmitted2 && !user2Message && "Please Enter Text") ||
                    (user2Message.length > 2000 && `Text must not exceed 2000 characters. Remove ${user2Message.length - 2000} characters.`)
                  }
                  onChange={(e) => {
                    setSubmited2(false)
                    setUser2Message(e.target.value);
                  }}
                  sx={{
                    height: "100%",
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  InputProps={{ style: { height: "100%" } }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            lg={6}
            md={12}
            sm={12}
            sx={{ height: "55vh", overflowY: "auto" }}
            p={1}
          >
            <Grid
              container
              item
              flexDirection={"column-reverse"}
              p={1}
              sx={{ border: "1px solid grey" }}
            >
              {messages.map((item, index) => (
                <Message
                  username={item?.user.name}
                  index={item?.user.id}
                  text={item.text}
                  key={`${index}`}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PodcastChat;
