import React, { useState, useEffect } from 'react'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
    IconButton,
    useMediaQuery,
    Box
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';



export default function Tutorial() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    let tutData = localStorage.getItem("lang") === "sp" ? [
        "assets/img/tutorial/spa/m1.png",
        "assets/img/tutorial/spa/m2.png",
        "assets/img/tutorial/spa/m3.png",
        "assets/img/tutorial/spa/m4.png",
        "assets/img/tutorial/spa/m5.png",
        "assets/img/tutorial/spa/m6.png",
        "assets/img/tutorial/spa/m7.png",
        "assets/img/tutorial/spa/m8.png",
    ] : [
        "assets/img/tutorial/eng/m1.png",
        "assets/img/tutorial/eng/m2.png",
        "assets/img/tutorial/eng/m3.png",
        "assets/img/tutorial/eng/m4.png",
        "assets/img/tutorial/eng/m5.png",
        "assets/img/tutorial/eng/m6.png",
        "assets/img/tutorial/eng/m7.png",
        "assets/img/tutorial/eng/m8.png",
    ]

    const [open, setOpen] = useState(false)

    // useEffect(() => {
    //     let firstVisit = localStorage.getItem('firstVisit')
    //     if (!firstVisit) {
    //         setOpen(true)
    //     }
    // }, [])

    return (
        <>
            <IconButton variant="no-padding" onClick={() => setOpen(true)}>
                <HelpRoundedIcon />
            </IconButton>
            <Dialog
                fullWidth
                open={open}
                onClose={() => {
                    setOpen(false)
                    localStorage.setItem('firstVisit', "visited")
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                PaperProps={{
                    sx: {
                        margin: { sm: 5, xs: .5 },
                        width: '100%'
                    }
                }}
            >
                <Box id="alert-dialog-title" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                        {/* Tutorial */}
                    </span>
                    <IconButton onClick={() => {
                        setOpen(false)
                        localStorage.setItem('firstVisit', "visited")
                    }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{ display: 'flex', justifyContent: 'center', p: { sm: "32px", xs: "10px" } }}>
                    <CarouselProvider
                        style={{ width: "100%" }}
                        naturalSlideWidth={"100%"}
                        naturalSlideHeight={isMobile ? "200px" : "500px"}
                        totalSlides={tutData?.length}
                        visibleSlides={1}
                    >
                        <Slider>
                            {
                                tutData?.map((item, index) => {
                                    return (
                                        <Slide
                                            style={{
                                                marginLeft: '0px',
                                                borderRadius: '15px',
                                                height: isMobile ? "200px" : "500px",
                                            }}
                                            index={index}
                                            key={index}
                                        >
                                            <img width="100%" src={item} alt="" />

                                        </Slide>
                                    )
                                })
                            }
                        </Slider>
                        {tutData.length > 1 && <div style={{ width: '100%', marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                            <ButtonBack style={{ border: 'none', backgroundColor: 'transparent' }}>
                                <IconButton>
                                    <ArrowBackIosRoundedIcon />
                                </IconButton>
                            </ButtonBack>
                            <ButtonNext style={{ border: 'none', backgroundColor: 'transparent' }}>
                                <IconButton>
                                    <ArrowForwardIosRoundedIcon />
                                </IconButton>
                            </ButtonNext>
                        </div>}
                    </CarouselProvider>
                </DialogContent>
            </Dialog>
        </>
    )
}
