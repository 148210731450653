import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const CustomDialog = ({
  openDialog,
  dialogTitle,
  dialogContent,
  dialogAction,
}) => {
  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          p: { xs: "1rem", md: "2.5rem 4rem" },
        },
      }}
      sx={{
        "& .MuiPaper-root": {
          "@media (max-width:900px)": {
            bottom: 0,
            position: "absolute",
            borderRadius: "0px",
            margin: 0,
            width: "100%",
          },
        },
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>

      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions
        sx={{
          width: "100%",
        }}
      >
        {dialogAction}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
