import React, { useState, useEffect } from 'react';
import { BsBank2 } from 'react-icons/bs';
import { GrTest } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom'
import Header from './Header'
import AccountBox from './AccountBox'
import { FcMoneyTransfer } from "react-icons/fc"
import { FcCurrencyExchange } from "react-icons/fc"
import { FaRegMoneyBillAlt } from "react-icons/fa"
import { FcAutomotive } from "react-icons/fc"
import { FcHome } from "react-icons/fc"
import { FcComboChart } from "react-icons/fc"
import { FcGraduationCap } from "react-icons/fc"
import { FcAlarmClock } from "react-icons/fc"
import { FaMoneyCheckAlt } from "react-icons/fa"
import { BsCurrencyBitcoin } from "react-icons/bs"
import { BsCreditCard2Front } from "react-icons/bs"
import { GiPalmTree } from "react-icons/gi"
import { GiGoldBar } from "react-icons/gi"
import { GiEarrings } from "react-icons/gi"
import { GiSonicShoes } from "react-icons/gi"
import { GiFishingBoat } from "react-icons/gi"
import { AiTwotoneGift } from "react-icons/ai"
import HomeWorkIcon from '@mui/icons-material/HomeWork';

import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import LinkModal from './LinkModal'
import LoanModal from './LoanModal'

import { useQuery } from 'react-query';
import Loading from '../common/loading';

import { getAllData } from '../_api';
import { netWorthTotalAssets } from '../_budget-functionality/index'

export default function AddAccounts() {
    const navigate = useNavigate()
    var url = new URL(window.location.href);
    var tabRef = url?.searchParams.get("tab") || "assets";
    const { isLoading, data: allData } = useQuery("budget", getAllData, {
        enabled: Boolean(sessionStorage.getItem('token'))
    });

    const [cash, setCash] = useState(0);
    const [bank, setBank] = useState(0);
    const [test, setTest] = useState(0);
    const [checking, setChecking] = useState(0);
    const [saving, setSaving] = useState(0);
    const [vehicle, setVehicle] = useState(0)
    const [realEstate, setRealEstate] = useState(0)
    const [crypto, setCrypto] = useState(0)
    const [investments, setInvestments] = useState(0)
    const [ira, setIra] = useState(0)
    const [plan, setPlan] = useState(0)
    const [goldSilver, setGoldSilver] = useState(0)
    const [jewelry, setJewelry] = useState(0)
    const [collectibles, setCollectibles] = useState(0)
    const [instruments, setInstruments] = useState(0)
    const [boat, setBoat] = useState(0)
    const [otherAsset, setOtherAsset] = useState(0)
    const [creditCard, setCreditCard] = useState(0)
    const [mortgage, setMortgage] = useState(0)
    const [autoLoan, setAutoLoan] = useState(0)
    const [personalLoan, setPersonalLoan] = useState(0)
    const [studentLoan, setStudentLoan] = useState(0)
    const [otherDebt, setOtherDebt] = useState(0)

    useEffect(() => {
        if (!isLoading) {
            assetData.map((item) => {
                netWorthTotalAssets("NetWorth", allData, item.subcategory, item.setValue, item.type)
            })
            debtData.map(item => {
                netWorthTotalAssets("NetWorth", allData, item.subcategory, item.setValue, item.type)
            })
        }
    }, [allData])

    const assetData = [
        {
            title: "Cash",
            type: "Add",
            value: cash,
            setValue: setCash,
            maincategory: "NetWorth",
            subcategory: "Cash",
            icon: <FcMoneyTransfer style={{ fontSize: "35px" }} />,

        },
        {
            title: "Bank",
            type: "Add",
            value: bank,
            setValue: setBank,
            maincategory: "NetWorth",
            subcategory: "Bank",
            icon: <BsBank2 style={{ fontSize: "35px" }} />,

        },
        {
            title: "Savings",
            type: "Add",
            value: saving,
            setValue: setSaving,
            maincategory: "NetWorth",
            subcategory: "Savings",
            icon: <FcCurrencyExchange style={{ fontSize: "35px" }} />,

        },
        {
            title: "Checking",
            type: "Add",
            value: checking,
            setValue: setChecking,
            maincategory: "NetWorth",
            subcategory: "Checking",
            icon: <FaMoneyCheckAlt style={{ fontSize: "35px" }} />,

        },
        {
            title: "Vehicle",
            type: "Add",
            value: vehicle,
            setValue: setVehicle,
            maincategory: "NetWorth",
            subcategory: "Vehicles",
            icon: <DeliveryDiningIcon style={{ fontSize: "35px" }} />,

        },


        {
            title: "Real Estate",
            type: "Add",
            value: realEstate,
            setValue: setRealEstate,
            maincategory: "NetWorth",
            subcategory: "RealEstate",
            icon: <FcHome style={{ fontSize: "35px" }} />,

        },
        {
            title: "Crypto",
            type: "Add",
            value: crypto,
            setValue: setCrypto,
            maincategory: "NetWorth",
            subcategory: "Crypto",
            icon: <BsCurrencyBitcoin style={{ fontSize: "35px" }} />,

        },
        {
            title: "Investments",
            type: "Add",
            value: investments,
            setValue: setInvestments,
            maincategory: "NetWorth",
            subcategory: "Investments",
            icon: <FcComboChart style={{ fontSize: "35px" }} />,

        },
        {
            title: "401k/IRA",
            type: "Add",
            value: ira,
            setValue: setIra,
            maincategory: "NetWorth",
            subcategory: "IRA",
            icon: <GiPalmTree style={{ fontSize: "35px" }} />,

        },
        {
            title: "529 Plan",
            type: "Add",
            value: plan,
            setValue: setPlan,
            maincategory: "NetWorth",
            subcategory: "Plan",
            icon: <FcGraduationCap style={{ fontSize: "35px" }} />,

        },
        {
            title: "Gold/Silver",
            type: "Add",
            value: goldSilver,
            setValue: setGoldSilver,
            maincategory: "NetWorth",
            subcategory: "GoldSilver",
            icon: <GiGoldBar style={{ fontSize: "35px" }} />,

        },
        {
            title: "Jewelry",
            type: "Add",
            value: jewelry,
            setValue: setJewelry,
            maincategory: "NetWorth",
            subcategory: "Jewelry",
            icon: <GiEarrings style={{ fontSize: "35px" }} />,

        },
        {
            title: "Collectibles",
            type: "Add",
            value: collectibles,
            setValue: setCollectibles,
            maincategory: "NetWorth",
            subcategory: "Collectibles",
            icon: <GiSonicShoes style={{ fontSize: "35px" }} />,

        },
        {
            title: "Instruments",
            type: "Add",
            value: instruments,
            setValue: setInstruments,
            maincategory: "NetWorth",
            subcategory: "Instruments",
            icon: <FcAlarmClock style={{ fontSize: "35px" }} />,

        },
        {
            title: "Boat",
            type: "Add",
            value: boat,
            setValue: setBoat,
            maincategory: "NetWorth",
            subcategory: "Boat",
            icon: <GiFishingBoat style={{ fontSize: "35px" }} />,

        },
        {
            title: "Other",
            type: "Add",
            value: otherAsset,
            setValue: setOtherAsset,
            maincategory: "NetWorth",
            subcategory: "Other",
            icon: <AiTwotoneGift style={{ fontSize: "35px" }} />,

        },
    ]
    const debtData = [
        {
            title: "Credit Card",
            type: "Delete",
            value: creditCard,
            setValue: setCreditCard,
            maincategory: "NetWorth",
            subcategory: "CreditCard",
            icon: <BsCreditCard2Front style={{ fontSize: "35px" }} />,

        },
        {
            title: "Mortgage",
            type: "Delete",
            value: mortgage,
            setValue: setMortgage,
            maincategory: "NetWorth",
            subcategory: "Mortgage",
            icon: <HomeWorkIcon style={{ fontSize: "35px" }} />,

        },
        {
            title: "Auto Loan",
            type: "Delete",
            value: autoLoan,
            setValue: setAutoLoan,
            maincategory: "NetWorth",
            subcategory: "AutoLoan",
            icon: <FcAutomotive style={{ fontSize: "35px" }} />,

        },
        {
            title: "Personal Loan",
            type: "Delete",
            value: personalLoan,
            setValue: setPersonalLoan,
            maincategory: "NetWorth",
            subcategory: "PersonalLoan",
            icon: <FaRegMoneyBillAlt style={{ fontSize: "35px" }} />,

        },
        {
            title: "Student Loan",
            type: "Delete",
            value: studentLoan,
            setValue: setStudentLoan,
            maincategory: "NetWorth",
            subcategory: "StudentLoan",
            icon: <FcGraduationCap style={{ fontSize: "35px" }} />,

        },
        {
            title: "Other",
            type: "Delete",
            value: otherDebt,
            setValue: setOtherDebt,
            maincategory: "NetWorth",
            subcategory: "Other",
            icon: <AiTwotoneGift style={{ fontSize: "35px" }} />,

        },
    ]
    if (isLoading)
        return (<Loading />)
    else
        return (<>
            <LoanModal />
            <Header />
            {/* <div className="extraHeader pe-0 ps-0">
                <ul className="nav nav-tabs lined" role="tablist">
                    <li className="nav-item" onClick={() => navigate("/add-accounts/?tab=assets")}>
                        <a className={tabRef === "assets" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#waiting" role="tab">
                            Asset
                        </a>
                    </li>
                    <li className="nav-item" onClick={() => navigate("/add-accounts/?tab=debt")}>
                        <a className={tabRef === "debt" ? "nav-link active" : "nav-link"} data-bs-toggle="tab" href="#paid" role="tab">
                            Debt
                        </a>
                    </li>
                </ul>
            </div> */}

            <div id="appCapsule" className="extra-header-active full-height">
                <div className="section tab-content mt-2 mb-1 mx-5">
                    <div className={tabRef === "assets" ? "tab-pane fade show active" : "tab-pane fad"} id="waiting" role="tabpanel">

                        <h4>Accounts</h4>
                        <p>Please select a type of asset to add:</p>
                        <div className="row">
                            {
                                assetData.map((item, index) => {
                                    return (
                                        <>
                                            {/* <LinkModal id={item.subcategory} key={index} /> */}
                                            <AccountBox icon={item.icon} modal={item.subcategory} id={item.subcategory} title={item.title} type={item.type} keyId={index + item.subcategory} />
                                        </>
                                    )
                                })
                            }

                        </div>
                    </div>
                    <div className={tabRef === "debt" ? "tab-pane fade show active" : "tab-pane fad"} id="paid" role="tabpanel">

                        <h4>Accounts</h4>
                        <p>Please select a type of debt to add:</p>
                        <div className="row">
                            {
                                debtData.map((item, index) => {
                                    return (
                                        <>
                                            <LinkModal id={item.subcategory} />
                                            <AccountBox icon={item.icon} modal={item.subcategory} id={item.subcategory} title={item.title} type={item.type} />
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>

            </div>
        </>)
}
