// import ExportModel from './exportModel'
import { Link } from 'react-router-dom'
function Header() {
    return (<>
        {/* <ExportModel /> */}
        <div className="appHeader appHeader-history pb-5 bg-primary">
            <div className="left">
                <Link to="/manage-budget" className="headerButton text-white goBack">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </Link>
            </div>
            <div className="pageTitle">

            </div>
            {/* <div className="right" data-bs-toggle="modal" data-bs-target="#exportModel">
                Export
            </div> */}
        </div>
    </>);
}

export default Header;