import Header from "./header";
import SpeedDial from "@mui/material/SpeedDial";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useParams } from "react-router-dom";
import { QUESTION_DATA } from "../faqs";

function Answer() {
  let { question_id } = useParams();
  const questionData = QUESTION_DATA.find((el) => el.id == question_id);
  return (
    <>
      <Header />
      <div
        className="row mt-5 pt-5 px-3"
        style={{ width: "95%", margin: "0 auto" }}
      >
        <h3 style={{ fontWeight: 600 }}>{questionData.question}</h3>
        <p style={{ fontWeight: 400 }}>{questionData.answer}</p>
      </div>

      {/* <Link to="/help">
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "absolute", bottom: 16, right: 16 }}
          icon={<SearchIcon />}
        ></SpeedDial>
      </Link> */}
    </>
  );
}

export default Answer;
