import React from 'react';
import { Link } from 'react-router-dom'
export default function AccountBox(props) {
    return (<>
        <Link to={"/add-plan/" + props.id + "/?type=" + props.type} key={props.keyId} className="col-4 mb-3"
        // data-bs-toggle="modal" data-bs-target={"#" + props.modal}
        >
            <div className="bill-box account-box">
                <div className="iconbox text-success">
                    {props.icon}
                    {/* <ion-icon name={props.icon}></ion-icon> */}
                </div>
                <p className="text-dark mt-1">{props.title}</p>
            </div>
        </Link>
    </>)
}
