import React, { useEffect, useState } from 'react';
import { AppBar, Tabs, Tab, Button, Box, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, linearGradient } from 'recharts';
import { barChartData } from '../../components/_budget-functionality';


const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#ffffff', padding: '5px', border: '1px solid #ccc' }}>
        <Typography>{`${payload[0].payload.tooltipValue || ''}`}</Typography>
      </div>
    );
  }
  return null;
};



const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  overflow: 'hidden',
  borderRadius: "32px",
  height: "fit-content"
}));

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: 'none',
  textTransform: "none",
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const LegendContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: "32px",
  padding: "10px",
  backgroundColor: theme.palette.grey[200],
  height: "fit-content"
}));

const LegendItem = styled(Box)(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: "7px 10px",
  backgroundColor: theme.palette.background.paper,
  borderRadius: "32px",
  color: color,
  marginLeft: theme.spacing(1),
  cursor: "pointer"
}));

const Dot = styled(Box)(({ theme, color }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: theme.spacing(0.5),
}));


const CustomButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  // color: theme.palette.text.primary,
  // backgroundColor: theme.palette.background.paper,
  borderColor: theme.palette.divider,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function thousandSeparator(num) {
  if (num > 0) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "0";
  }
}

const MyChartComponent = ({ data }) => {
  const theme = useTheme();
  const [budgetType, setBudgetType] = useState('Income-Expense');
  const [categoryType, setCategoryType] = useState('Income');
  const [duration, setDuration] = useState("max")
  const [chartData, setChartData] = useState([])
  const [chartFlag, setChartFlag] = useState(false)

  const handleAlignment = (event, newVal) => {
    if (newVal !== null) {
      setBudgetType(newVal);
      if (newVal == "Income-Expense") {
        setCategoryType("Income")
      } else {
        setCategoryType("Assets")
      }
    }
  };

  const handleDataWithTab = () => {

    if (duration === "1w" || duration === "5d") return "day";
    if (duration === "6m") return "month";
    return "max";
  };


  const splitData = () => {
    const tempChartData = barChartData(
      data,
      0,
      handleDataWithTab(),
      budgetType == "Income-Expense" ? "ManageBudget" : "NetWorth"
    ).reverse();
    if (duration === "6m")
      return tempChartData.splice(tempChartData.length - 6);
    if (duration === "1w")
      return tempChartData.splice(tempChartData.length - 12);
    if (duration === "5d")
      return tempChartData.splice(tempChartData.length - 10);
    return tempChartData;
  };

  useEffect(() => {
    setChartFlag(false)
    console.log("Split Data: ", splitData())
    if (budgetType == "Income-Expense") {
      setChartData(splitData()?.map(item => ({ name: item.Month, value: categoryType == "Income" ? item.Income : item.Expenses, tooltipValue: `$${thousandSeparator(categoryType == "Income" ? item.Income : item.Expenses)}` })))
    } else {
      setChartData(splitData()?.map(item => ({ name: item.Month, value: categoryType == "Assets" ? item.Income : item.Expenses, tooltipValue: `$${thousandSeparator(categoryType == "Assets" ? item.Income : item.Expenses)}` })))
    }
    setTimeout(() => setChartFlag(true), 50)
  }, [data, duration, categoryType])

  return (
    <Box sx={{ width: '100%', p: 0 }}>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <CustomToggleButtonGroup
          value={budgetType}
          exclusive
          onChange={handleAlignment}
          sx={{ marginBottom: 2 }}
        >
          <CustomToggleButton value="Income-Expense">
            Income-Expense
          </CustomToggleButton>
          <CustomToggleButton value="Asset-Debt">
            Asset-Debt
          </CustomToggleButton>
        </CustomToggleButtonGroup>
        {
          budgetType === "Income-Expense" ?
            <LegendContainer>
              <LegendItem
                onClick={() => setCategoryType("Income")}
                sx={{ bgcolor: categoryType == "Income" ? theme.palette.common.white : "transparent" }}
                color={categoryType == "Income" ? theme.palette.primary.main : theme.palette.success.main}>
                <Dot color={categoryType == "Income" ? theme.palette.primary.main : theme.palette.success.main} />
                <Typography variant="body2">Income</Typography>
              </LegendItem>
              <LegendItem
                onClick={() => setCategoryType("Expense")}
                sx={{ bgcolor: categoryType == "Expense" ? theme.palette.common.white : "transparent" }}
                color={categoryType == "Expense" ? theme.palette.primary.main : theme.palette.success.main}>
                <Dot color={categoryType == "Expense" ? theme.palette.primary.main : theme.palette.success.main} />
                <Typography variant="body2">Expense</Typography>
              </LegendItem>
            </LegendContainer>
            :
            <LegendContainer>
              <LegendItem
                onClick={() => setCategoryType("Assets")}
                sx={{ bgcolor: categoryType == "Assets" ? theme.palette.common.white : "transparent" }}
                color={categoryType == "Assets" ? theme.palette.primary.main : theme.palette.success.main}>
                <Dot color={categoryType == "Assets" ? theme.palette.primary.main : theme.palette.success.main} />
                <Typography variant="body2">Assets</Typography>
              </LegendItem>
              <LegendItem
                onClick={() => setCategoryType("Debts")}
                sx={{ bgcolor: categoryType == "Debts" ? theme.palette.common.white : "transparent" }}
                color={categoryType == "Debts" ? theme.palette.primary.main : theme.palette.success.main}>
                <Dot color={categoryType == "Debts" ? theme.palette.primary.main : theme.palette.success.main} />
                <Typography variant="body2">Debts</Typography>
              </LegendItem>
            </LegendContainer>
        }

      </Box>
      {
        chartFlag &&
        <Box>
          <ResponsiveContainer width="100%" height={330}>
            <AreaChart data={chartData}>
              <defs>
                <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={theme.palette.primary.main} stopOpacity={0.8} />
                  <stop offset="100%" stopColor={theme.palette.primary.main} stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              {/* <Legend /> */}
              <Area
                type="monotone"
                dataKey="value"
                stroke={theme.palette.primary.main}
                strokeWidth={4}
                fill="url(#colorGradient)"
                fillOpacity={1}
                dot={{ r: 6, fill: theme.palette.primary.main, stroke: '#ffffff', strokeWidth: 2 }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      }

      <Box display="flex" justifyContent="center" mt={2}>
        <CustomButton onClick={() => setDuration("5d")} variant={duration == "5d" ? "contained" : "outlined"}>5 D</CustomButton>
        <CustomButton onClick={() => setDuration("1w")} variant={duration == "1w" ? "contained" : "outlined"}>1 W</CustomButton>
        <CustomButton onClick={() => setDuration("6m")} variant={duration == "6m" ? "contained" : "outlined"}>6 M</CustomButton>
        <CustomButton onClick={() => setDuration("max")} variant={duration == "max" ? "contained" : "outlined"}>MAX</CustomButton>
      </Box>
    </Box>
  );
};

export default MyChartComponent;
