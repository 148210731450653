import React, { useState } from "react";
import CardRapper from "../tabs/cardRapper";
import Footer from "../footer";
import Parser from 'html-react-parser'
import ContentTypes from './ContentTypes'


export default function SkillSectionDetails({ content }) {
  return (
    <CardRapper title={Parser(content?.name)}>
      <div className="row p-3">
        <ContentTypes content={content} />
      </div>
      <Footer />
    </CardRapper>

  );
}
