import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Loading from "../loader/Loading";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { Typography, Divider, Button } from "@mui/material";
import "./index.css"
export const CustomBudgetModal = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const {
    formLoader = false,
    budgetModal = false,
  } = props;

  const dialogStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginTop: "50px",
  };

  useEffect(() => {
    if (formLoader) {
      const timerId = setTimeout(() => {
        props.setFormLoader(false);
        setIsHovered(false);
      }, 7000);

      return () => clearTimeout(timerId);
    }
  }, [formLoader]);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const DialogTitleContent = ({ handleClick }) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1 className="modal-title pb-0">
            <AddIcon className="plus_ic" />
            Add Budget
          </h1>
          {!formLoader && (
            <Typography
              variant="body1"
              style={{
                textTransform: "capitalize",
                textAlign: "center",
                color: "#958d9e",
                padding: "0px",
                fontFamily: "Poppins, sans-serif",
              }}
              
            >
              Select a spending category below to create a budget for it.
            </Typography>
          )}
        </div>
        <div className="d-flex justify-content-between">
          <div className="back_btn">
            <div
              style={{
                cursor: formLoader ? "" : "pointer",
              }}
              className={`headerBtn goBack ${
                formLoader ? "disabled" : "enabled"
              }`}
              onMouseEnter={formLoader && handleHover}
              onMouseLeave={formLoader && handleLeave}
              onClick={!formLoader && handleClick}
            >
              <ion-icon
                style={{
                  color: formLoader ? (isHovered ? "#EB4747" : "gray") : "#616DED",
                }}
                name="chevron-back-outline"
                role="img"
                className="md hydrated"
                aria-label="chevron back outline"
              ></ion-icon>
              <h3
                style={{
                  color: formLoader ? (isHovered ? "#EB4747" : "gray") : "#616DED",
                }}
              >
                {" "}
                Back{" "}
              </h3>
            </div>
            <CloseIcon
              onClick={handleClick}
              style={{
                cursor: "pointer",
                visibility: "hidden",
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Dialog
        open={props.openModal}
        close={props.closeModal}
        TransitionComponent={props.transition}
        fullScreen
      >
        <DialogTitle>
          <DialogTitleContent
            handleClick={props.closeModal}
          />
        </DialogTitle>
        <DialogContent
          sx={
            budgetModal
              ? dialogStyle
              : {
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }
          }
        >
          {formLoader ? <Loading /> : props.content}
        </DialogContent>
        {budgetModal ? (
          <>
            <Divider />
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                py: "10px",
              }}
            >
              <Button
                data-bs-dismiss="modal"
                onClick={props.handleReset}
                variant="contained"
                className="reset-cal"
                startIcon={<SettingsBackupRestoreIcon className="plus_ic" />}
              >
                Reset Calculator
              </Button>
            </DialogActions>
          </>
        ) : null}
      </Dialog>
    </>
  );
};
