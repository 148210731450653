import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import { Chart, ArgumentAxis, ValueAxis, AreaSeries, } from '@devexpress/dx-react-chart-material-ui';
import { ArgumentScale, Animation } from '@devexpress/dx-react-chart';
import { styled } from '@mui/material/styles';
import { scalePoint } from 'd3-scale';
import { Link } from 'react-router-dom';

const data = [
    { year: '2010', android: 0, },
    { year: '2011', android: 500, },
    { year: '2012', android: 1000, },
];

const PREFIX = 'Demo';

const classes = {
    chart: `${PREFIX}-chart`,
};

const StyledChartRoot = styled(Chart.Root)(() => ({
    [`&.${classes.chart}`]: {
        paddingRight: '20px',
    },
}));

const ChartRoot = props => (
    <StyledChartRoot {...props} className={classes.chart} />
);

function CarPage() {
    return (<>
        <div className="appHeader">
            <div className="left">
                <a href="#" className="headerButton goBack" onClick={() => window.history.go(-1)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div className="right">
                Edit
            </div>
        </div>
        <div className="container mt-5 pt-5">
            <div style={{ position: 'fixed', top: '50px', backgroundColor: '#FFF', width: '100%', zIndex: '100' }} className="row d-flex justify-content-center">
                <div className="col-5"></div>
                <div className="col-2">
                    <div className="text-success text-center mt-3">
                        <DirectionsCarFilledIcon style={{ fontSize: '35px' }} />
                    </div>
                    <h3 className="mt-3 text-center">Car</h3>
                </div>
                <div className="col-5"></div>
            </div>
            <div style={{ top: '160px' }} className="extraHeader pe-0 ps-0">
                <ul className="nav nav-tabs lined" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" data-bs-toggle="tab" href="#equity" role="tab">
                            Equity
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="tab" href="#value" role="tab">
                            Value
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-bs-toggle="tab" href="#loan" role="tab">
                            Loan
                        </a>
                    </li>
                </ul>
            </div>
            <div id="appCapsule" className="extra-header-active full-height mt-5">
                <div className="section tab-content mt-2 mb-1">
                    <div className="tab-pane fade show active" id="equity" role="tabpanel">
                        <h4>Equity</h4>
                        <div className="row d-flex justify-content-center text-center mt-5 pt-5">

                            <div className="chart mt-2">
                                <Chart data={data} rootComponent={ChartRoot} >
                                    <ArgumentScale factory={scalePoint} />
                                    <ArgumentAxis />
                                    <ValueAxis />
                                    <AreaSeries name="Android" valueField="android" argumentField="year" />
                                    <Animation />
                                </Chart>
                            </div>
                            <div id="appCapsule" className="bg-white mx-3 mt-2 pt-0">
                                <div className="listview-title mt-2">VALUE HISTORY</div>
                                <div data-bs-toggle="modal" data-bs-target="#valueChange" style={{ cursor: 'pointer' }} className="space-between align-center text-primary px-4" >
                                    <p className="m-0 px-3">
                                        Update Balance
                                    </p>
                                    <div className="btn text-primary">+</div>
                                </div>
                                <ul className="listview image-listview inset">
                                    <li>
                                        <div href="#" className="item">
                                            <div className="in text-left">
                                                <span>
                                                    January 26, 2022
                                                    <footer>Initial value</footer>
                                                </span>
                                                <div className="text-primary"><b>$500</b></div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="value" role="tabpanel">

                        <h4>Value</h4>
                        <div className="row d-flex justify-content-center text-center mt-5 pt-5">

                            <div className="chart mt-2">
                                <Chart data={data} rootComponent={ChartRoot} >
                                    <ArgumentScale factory={scalePoint} />
                                    <ArgumentAxis />
                                    <ValueAxis />
                                    <AreaSeries name="Android" valueField="android" argumentField="year" />
                                    <Animation />
                                </Chart>
                            </div>
                            <div id="appCapsule" className="bg-white mx-3 mt-2 pt-0">
                                <div className="listview-title mt-2">VALUE HISTORY</div>
                                <div data-bs-toggle="modal" data-bs-target="#valueChange" style={{ cursor: 'pointer' }} className="space-between align-center text-primary px-4" >
                                    <p className="m-0 px-3">
                                        Update Balance
                                    </p>
                                    <div className="btn text-primary">+</div>
                                </div>
                                <ul className="listview image-listview inset">
                                    <li>
                                        <div href="#" className="item">
                                            <div className="in text-left">
                                                <span>
                                                    January 26, 2022
                                                    <footer>Initial value</footer>
                                                </span>
                                                <div className="text-primary"><b>$500</b></div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="loan" role="tabpanel">

                        <h4>Loan</h4>
                        <div className="row d-flex justify-content-center text-center mt-5 pt-5">

                            <div className="chart mt-2">
                                <Chart data={data} rootComponent={ChartRoot} >
                                    <ArgumentScale factory={scalePoint} />
                                    <ArgumentAxis />
                                    <ValueAxis />
                                    <AreaSeries name="Android" valueField="android" argumentField="year" />
                                    <Animation />
                                </Chart>
                            </div>
                            <div id="appCapsule" className="bg-white mx-3 mt-2 pt-0">
                                <div className="listview-title mt-2">VALUE HISTORY</div>
                                <div data-bs-toggle="modal" data-bs-target="#valueChange" style={{ cursor: 'pointer' }} className="space-between align-center text-primary px-4" >
                                    <p className="m-0 px-3">
                                        Update Balance
                                    </p>
                                    <div className="btn text-primary">+</div>
                                </div>
                                <ul className="listview image-listview inset">
                                    <li>
                                        <div href="#" className="item">
                                            <div className="in text-left">
                                                <span>
                                                    January 26, 2022
                                                    <footer>Initial value</footer>
                                                </span>
                                                <div className="text-primary"><b>$500</b></div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </>);
}

export default CarPage;