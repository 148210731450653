import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
export default function Header() {
    const navigate = useNavigate()
    return (<>
        <div className="appHeader">
            <div className="left">
                <div onClick={() => navigate(-1)} className="headerButton goBack">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </div>
            </div>
            <div className="right">
            </div>
        </div>
    </>)
}
