import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Link } from 'react-router-dom';


function CardUsage() {
    return (<>
        <div className="appHeader">
            <div className="left">
                <a href="#" className="headerButton goBack" onClick={() => window.history.go(-1)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div className="pageTitle d-flex align-center">
                <div className="square-icon px-3 mr-2 py-1 bg-primary">
                    A
                </div>
                Card Usage
            </div>
        </div>
        <div className="container mt-5 pt-2 bg-white">
            <div className="row mt-5 d-flex align-center">
                <div className="col-2">
                    <InfoOutlinedIcon style={{ fontSize: "50px" }} />
                </div>
                <div className="col-10">
                    <p className="p-0 m-0">You have <b><span className="text-dark">1 open credit card account.</span></b> Here is your total utilization accross them</p>
                </div>
            </div>
            <div className="row text-center mt-3">
                <div className="col">
                    <p className="m-0 p-0 text-left">Total Balance</p>
                    <h4 className="text-left">$18</h4>
                </div>
                <div className="col">
                    <p className="m-0 p-0 text-left">Total Limit</p>
                    <h4 className="text-left">$36,800</h4>
                </div>
                <div className="col">
                    <p className="m-0 p-0 text-left">Usage</p>
                    <h4 className="text-left text-primary"> 1%</h4>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col text-primary">Great</div>
                <div className="col">Good</div>
                <div className="col">Fair</div>
                <div className="col">Poor</div>
            </div>
            <ul className="listview image-listview mt-5" style={{ border: 'none' }}>
                <li className="no-border">
                    <Link to="/all-credits" className="item p-0 m-0">
                        <div className="icon-box bg-primary">
                            <AcUnitRoundedIcon />
                        </div>
                        <div className="in">
                            <span>
                                View Credit Events
                            </span>
                            <div className="text-primary"><b></b></div>
                        </div>
                    </Link>
                </li>
                <p className="text-secondary p-0 m-0">
                    <sapn className="text-success">
                        <FiberManualRecordIcon style={{ fontSize: '12px', marginTop: '-3px', marginRight: '20px', marginLeft: '8px' }} />
                    </sapn>CURRENT STATUS
                </p>
                <p style={{ marginLeft: '40px', marginTop: '3px' }}>Your card utilizstion looks good. Keep it low for an optimal score.</p>
            </ul>
            <ul className="listview image-listview mt-5" style={{ border: 'none' }}>
                <li className="no-border">
                    <Link to="/all-credits" className="item p-0 m-0">
                        <div className="icon-box bg-primary">
                            <AcUnitRoundedIcon />
                        </div>
                        <div className="in">
                            <span>
                                Jpmcb Card
                                <footer>Balance: $18</footer>
                            </span>
                            <div className="mr-5 text-secondary"><b><span className="text-dark">1%</span></b> <br />Limit: $36,800</div>
                        </div>
                    </Link>
                </li>
            </ul>
            <p className="text-center mt-5">Learn more about Card Usage and how it impacts your credit score</p>
            <Link to="/answer"><h5 className="text-center">Learn More</h5></Link>
        </div>
    </>);
}

export default CardUsage;