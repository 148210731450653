import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Header from "./Header";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  getFinancialGoals,
  setFinancialGoals,
  putFinancialGoals,
  deleteFinancialGoals,
  putFinancialGoalsFull,
  getFinancialGoalsTrash,
} from "../_api/index";
import Loading from "../common/loading";
import { useQuery, useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { saveSvgAsPng } from "save-svg-as-png";
import WordCloud from "./WordCloud";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {
  Button,
  InputLabel,
  MenuItem,
  Grid,
  Select,
  IconButton,
  Card,
  Box,
  Typography,
  LinearProgress,
  Tab,
  Tabs,
  InputAdornment,
  TextField,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { styled } from "@mui/material/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GoalScale from "./Scale";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTheme } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import WidgetsIcon from "@mui/icons-material/Widgets";
import Marquee from "react-fast-marquee";
import AddGoalDialog from "./AddGoalDialog";
import CertificateIcon from "../svgIcons/CertificateIcon";
import { CustomDropDown, CustomTextField } from "./CustomField";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const getItems = (
  id,
  name,
  content,
  type,
  status,
  created,
  completed,
  startDate,
  endDate,
  personName,
  email,
  frequency
) =>
  Array.from({ length: 1 }, (v, k) => k).map((k) => ({
    id: id,
    name: name,
    content: content,
    type: type,
    status: status,
    createdID: created,
    completedID: completed,
    endDate: endDate,
    startDate: startDate,
    personName: personName,
    email: email,
    frequency: frequency,
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 10;

const getItemStyle = (isDragging, draggableStyle, item) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? theme.palette.common.white: theme.palette.common.white,
  boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#f4f5f7",
  // padding: grid,
  width: "100%",
  margin: " 0px 10px 0px 0px",
  minHeight: 300,
});

let cardType = [
  {
    name: "Short Term",
    value: "shortterm",
  },
  {
    name: "Mid Term",
    value: "midterm",
  },
  {
    name: "Long Term",
    value: "longterm",
  },
];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    borderRadius: "1.25rem",
    background: theme.palette.common.disabled,
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: ".9rem",
    "&:hover": {
      background: theme.palette.common.text,
      color: theme.palette.common.white,
    },
    "&.Mui-selected": {
      background: theme.palette.common.text,
      color: theme.palette.common.white,

      "&:hover": {
        background: theme.palette.common.text,
        color: theme.palette.common.white,
      },
    },
    "&:first-of-type": {
      marginRight: "8px",
    },
  },
}));

export default function FinancialGoals(props) {
  const images = [
    "./assets/img/money.png",
    "./assets/img/building.png",
    "./assets/img/cloud.png",
    "./assets/img/desk.png",
    "./assets/img/bulb.png",
  ];
  const queryClient = useQueryClient();
  const [state, setState] = useState([[], [], []]);
  const [description, setDescription] = useState("");
  const [currentCardID, setCurrentCardID] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [type, setType] = useState("midterm");
  const [openModal, setOpenModal] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [trashOpened, setTrashOpened] = useState(false);
  const [createdID, setCreatedID] = useState("");
  const [completedID, setCompletedID] = useState("");
  const [lineData, setLineData] = useState([]);
  const [name, setName] = useState("");
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [wordString, setWordString] = useState(
    "Start building your word financial goal cloud"
  );
  const [person, setPerson] = useState("");
  const [email, setEmail] = useState("");
  const [frequency, setFrequency] = useState(7);

  const [view, setView] = useState("timeline");
  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();

  const handleChange = (event, newView) => {
    setView(newView);
  };

  const { isLoading, data: financialGoals } = useQuery(
    "financial-goals",
    getFinancialGoals,
    {
      enabled: Boolean(sessionStorage.getItem("token")),
    }
  );

  const { data: trashData } = useQuery(
    "financial-goal-trash",
    getFinancialGoalsTrash,
    {
      enabled: Boolean(sessionStorage.getItem("token")),
    }
  );
  const { isLoading: isSetLoading, mutate: postFinancialData } = useMutation(
    setFinancialGoals,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("financial-goals");
        toast.success("Goal Added");
      },
      onError: (err) => {
        toast.dismiss();
        toast.error((err?.response?.data?.message).toUpperCase());
      },
    }
  );
  const { isLoading: isPutLoading, mutate: putFinancialData } = useMutation(
    putFinancialGoals,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("financial-goals");
        queryClient.invalidateQueries("financial-goal-trash");
        toast.dismiss();
        toast.success("Goal Updated");
      },
      onError: (err) => {
        toast.dismiss();
        toast.error((err?.response?.data?.message).toUpperCase());
      },
    }
  );
  const { isLoading: isPutLoadingFull, mutate: putFinancialDataFull } =
    useMutation(putFinancialGoalsFull, {
      onSuccess: (res) => {
        queryClient.invalidateQueries("financial-goals");
        toast.dismiss();
        toast.success("Goal Updated");
      },
      onError: (err) => {
        toast.dismiss();
        toast.error((err?.response?.data?.message).toUpperCase());
      },
    });
  const { isLoading: isDeleting, mutate: deleteFinancialData } = useMutation(
    deleteFinancialGoals,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("financial-goals");
        queryClient.invalidateQueries("financial-goal-trash");
        toast.dismiss();
        toast.success("Goal Deleted");
      },
      onError: (err) => {
        toast.dismiss();
        toast.error((err?.response?.data?.message).toUpperCase());
      },
    }
  );

  useEffect(() => {
    if (!isLoading) {
      getTimeLine("all");
    }
  }, [isLoading]);

  useEffect(() => {
    if (isPutLoading || isPutLoadingFull) {
      toast.loading("Updating...");
    }
  }, [isPutLoading, isPutLoadingFull]);
  useEffect(() => {
    if (isDeleting) {
      toast.loading("Deleting...");
    }
  }, [isDeleting]);
  useEffect(() => {
    if (!isLoading) {
      console.log("Financial Goals: ", financialGoals);
      let temp_main = [[], [], []];
      if (financialGoals?.data?.length === 0) {
        setState(temp_main);
      }
      financialGoals?.data?.map((goal) => {
        if (goal?.status === "todo") {
          let temp = temp_main[0];
          temp.push(
            getItems(
              goal?.id,
              goal?.name,
              goal?.description,
              goal?.type,
              goal?.status,
              goal?.createdid,
              goal?.completedid,
              goal?.startDate,
              goal?.endDate,
              goal?.personName,
              goal?.email,
              goal?.frequency
            )[0]
          );
          let temp2 = temp_main[1];
          let temp3 = temp_main[2];
          setState([temp, temp2, temp3]);
        }
        if (goal?.status === "progress") {
          let temp = temp_main[0];
          let temp2 = temp_main[1];
          temp2.push(
            getItems(
              goal?.id,
              goal?.name,
              goal?.description,
              goal?.type,
              goal?.status,
              goal?.createdid,
              goal?.completedid,
              goal?.startDate,
              goal?.endDate,
              goal?.personName,
              goal?.email,
              goal?.frequency
            )[0]
          );
          let temp3 = temp_main[2];
          setState([temp, temp2, temp3]);
        }
        if (goal?.status === "done") {
          let temp = temp_main[0];
          let temp2 = temp_main[1];
          let temp3 = temp_main[2];
          temp3.push(
            getItems(
              goal?.id,
              goal?.name,
              goal?.description,
              goal?.type,
              goal?.status,
              goal?.createdid,
              goal?.completedid,
              goal?.startDate,
              goal?.endDate,
              goal?.personName,
              goal?.email,
              goal?.frequency
            )[0]
          );
          setState([temp, temp2, temp3]);
        }
      });
    }
  }, [isLoading, financialGoals]);
  useEffect(() => {
    if (!isLoading) {
      let temp_string = "";
      financialGoals?.data?.map((goal) => {
        temp_string += goal?.description + " ";
      });
      if (temp_string.length !== 0) {
        setWordString(temp_string);
      }
    }
  }, [isLoading]);
  const setWords = (type) => {
    let temp_string = "";
    financialGoals?.data?.map((goal) => {
      if (goal?.status === type) {
        temp_string += goal?.description + " ";
      }
    });
    if (type === "all") {
      financialGoals?.data?.map((goal) => {
        temp_string += goal?.description + " ";
      });
    }
    if (temp_string.length !== 0) {
      setWordString(temp_string);
    } else {
      setWordString("Start building your word financial goal cloud");
    }
  };
  const getTimeLine = (status) => {
    if (status === "todo") {
      setLineData(
        financialGoals?.data?.filter((goal) => {
          return goal.status === "todo";
        })
      );
    } else if (status === "progress") {
      setLineData(
        financialGoals?.data?.filter((goal) => {
          return goal.status === "progress";
        })
      );
    } else if (status === "done") {
      setLineData(
        financialGoals?.data?.filter((goal) => {
          return goal.status === "done";
        })
      );
    } else {
      setLineData(financialGoals?.data);
    }
  };
  function handleSave() {
    const svgElement = document.querySelector("#nft-svg");
    saveSvgAsPng(svgElement, "nft.png");
    setOpenModalUpdate(false);
  }
  function onDragEnd(result) {
    const { source, destination } = result;
    console.log("Result: ", result);

    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    if (parseInt(sInd) == 2) {
      return;
    }
    const dInd = +destination.droppableId;
    const drageID = result.draggableId;
    if (sInd == dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];
      setState(newState);
      if (parseInt(destination.droppableId) == 0) {
        let header = {
          id: drageID,
          status: "todo",
        };
        putFinancialData(header);
      }
      if (parseInt(destination.droppableId) == 1) {
        let header = {
          id: drageID,
          status: "progress",
        };
        putFinancialData(header);
      }
      if (parseInt(destination.droppableId) == 2) {
        let header = {
          id: drageID,
          status: "done",
        };
        putFinancialData(header);
      }
    }
  }

  let isIframe = sessionStorage.getItem("isIframe");

  let url = new URL(window.location.href);
  let pageView = url?.searchParams.get("view");

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const totalDuration = moment(endDate).diff(moment(startDate), "milliseconds");
  const elapsedTime = moment().diff(moment(startDate), "milliseconds");

  const percentage = Math.min(
    Math.max((elapsedTime / totalDuration) * 100, 0),
    100
  );

  const moveItem = (item, fromIndex, toIndex) => {
    const updatedState = [...state];
    const [movedItem] = updatedState[fromIndex].splice(
      updatedState[fromIndex].indexOf(item),
      1
    );
    updatedState[toIndex].push(movedItem);
    setState(updatedState);
  };
  
  const openCardModal = (item) => {
    setCurrentCardID(item?.id);
    setName(item?.name);
    setPerson(item?.personName);
    setEmail(item?.email);
    setFrequency(item?.frequency);
    setStartDate(item?.startDate);
    setEndDate(item?.endDate);
    setType(item?.type);
    setDescription(item?.content);
    setOpenModalUpdate(true);
    setCurrentStatus(item?.status);
    setCreatedID(item?.createdID);
    setCompletedID(item?.createdID);
  };

  if (isLoading || isSetLoading) {
    return <Loading />;
  } else
    return (
      <>
        <Box>
          <Box display="flex" justifyContent="center">
            <Box
              sx={{
                border: `4px solid ${theme.palette.common.disabled}`,
                backgroundColor: theme.palette.common.lightGray,
                borderRadius: "1.25rem",
                p: 1,
                width: "fit-content",
              }}
            >
              <StyledToggleButtonGroup
                color="primary"
                value={view}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="timeline">Timeline</ToggleButton>
                <ToggleButton value="word-cloud">Word Cloud</ToggleButton>
              </StyledToggleButtonGroup>
            </Box>
          </Box>

          {view === "timeline" ? (
            <Box display="flex" gap={2} my="2rem">
              {(lineData?.length === 0 || !lineData) && (
                <Typography variant="h4">GOALS NOT ADDED YET!</Typography>
              )}
              <Marquee speed={50} pauseOnHover={true}>
                {lineData?.map((card, i) => {
                  const statusColor =
                    card?.status === "todo"
                      ? theme.palette.error.main
                      : card?.status === "progress"
                      ? theme.palette.primary.main
                      : theme.palette.success.main;
                  const statusLightColor =
                    card?.status === "todo"
                      ? theme.palette.error.extraLight
                      : card?.status === "progress"
                      ? theme.palette.primary.extraLight
                      : theme.palette.success.extraLight;

                  return (
                    <Box key={i} width="250px" position="relative" mx={2}>
                      <Card>
                        <Box display="flex" alignItems="center" gap="5px">
                          <Box
                            sx={{
                              bgcolor: statusLightColor,
                              p: "2px 4px",
                              borderRadius: "8px",
                            }}
                          >
                            <SupervisorAccountIcon
                              fontSize="small"
                              sx={{ color: statusColor }}
                            />
                          </Box>
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontSize: "1rem",
                              fontWeight: 700,
                            }}
                          >
                            {card?.name}
                          </Typography>
                        </Box>

                        <Box sx={{ mt: 2 }}>
                          <LinearProgress
                            variant="determinate"
                            value={percentage}
                            sx={{
                              height: "6px",
                              borderRadius: "5px",
                              bgcolor: (theme) => theme.palette.common.disabled,
                              "& .MuiLinearProgress-bar": {
                                bgcolor: statusColor,
                              },
                            }}
                          />
                        </Box>

                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt={2}
                        >
                          <Box display="flex" gap="2px" alignItems="center">
                            <AccessTimeIcon />
                            <Typography fontSize="10px" fontWeight="500">
                              {moment(card.endDate).format("ll")}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography color={statusColor}>
                              {percentage}%
                            </Typography>
                          </Box>
                        </Box>
                      </Card>

                      <Box width="100%" display="flex" justifyContent="center">
                        <Box
                          sx={{
                            bgcolor: statusColor,
                            width: "2px",
                            height: "15px",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          bgcolor: statusColor,
                          borderRadius: "8px",
                          px: "8px",
                          py: "5px",
                          mb: "2rem",
                        }}
                      >
                        <Typography
                          sx={{
                            color: theme.palette.common.white,
                            fontSize: "6px",
                          }}
                        >
                          Start on <br />
                          {moment(card.startDate).format("ll")}
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "right",
                            color: theme.palette.common.white,
                            fontSize: "6px",
                          }}
                        >
                          Deadline <br />
                          {moment(card.endDate).format("ll")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ position: "absolute", bottom: -5, left: "42%" }}
                      >
                        <Box
                          sx={{
                            borderRadius: "50%",
                            border: `2px solid ${statusColor}`,
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: theme.palette.common.white,
                          }}
                        >
                          <Typography>{i + 1}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Marquee>
            </Box>
          ) : (
            <Box my="2rem">
              <WordCloud
                setWords={setWords}
                getTimeLine={getTimeLine}
                string={wordString}
              />
            </Box>
          )}
          {/* Desktop Card */}
          <Card sx={{ display: { xs: "none", md: "block" } }}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              mb="2rem"
            >
              {view === "timeline" && (
                <Box>
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                    disableRipple
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: "1.5rem",
                      color: theme.palette.common.white,
                      p: "6px 10px",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        opacity: 0.8,
                      },
                    }}
                  >
                    Add New Goal
                  </Button>
                </Box>
              )}
            </Box>

            <Box>
              <Box display="flex" justifyContent="space-between" gap={2}>
                <Box
                  onClick={() => {
                    setWords("todo");
                    getTimeLine("todo");
                  }}
                  sx={{
                    backgroundColor: theme.palette.error.main,
                    borderRadius: "32px 32px 0px 0px",
                    padding: "13px 18px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.common.white,
                      fontSize: "1rem",
                      fontWeight: 700,
                    }}
                  >
                    TO DO
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setWords("progress");
                    getTimeLine("progress");
                  }}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "32px 32px 0px 0px",
                    padding: "13px 18px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.common.white,
                      fontSize: "1rem",
                      fontWeight: 700,
                    }}
                  >
                    IN PROGRESS
                  </Typography>
                </Box>

                <Box
                  onClick={() => {
                    setWords("done");
                    getTimeLine("done");
                  }}
                  sx={{
                    backgroundColor: theme.palette.success.main,
                    borderRadius: "32px 32px 0px 0px",
                    padding: "13px 18px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.common.white,
                      fontSize: "1rem",
                      fontWeight: 700,
                    }}
                  >
                    Done
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" gap={2}>
                <DragDropContext onDragEnd={onDragEnd}>
                  {state.map((el, ind) => (
                    <Droppable key={ind} droppableId={`${ind}`}>
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            backgroundColor: snapshot.isDraggingOver
                              ? theme.palette.primary.extraLight
                              : theme.palette.common.background,
                            width: "100%",
                            minHeight: "300px",
                            p: ".5rem .7rem",
                            borderRadius: "0rem 0rem 2rem 2rem",
                          }}
                        >
                          {el.map((item, index) => {
                            const iconColor =
                              item?.status === "todo"
                                ? theme.palette.error.main
                                : item?.status === "progress"
                                ? theme.palette.primary.main
                                : theme.palette.success.main;
                            const iconBgColor =
                              item?.status === "todo"
                                ? theme.palette.error.extraLight
                                : item?.status === "progress"
                                ? theme.palette.primary.extraLight
                                : theme.palette.success.extraLight;

                            return (
                              <Draggable
                                key={item?.id}
                                draggableId={item?.id.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      backgroundColor: snapshot.isDragging
                                        ? theme.palette.common.disabled
                                        : theme.palette.common.white,
                                      borderRadius: "8px",
                                      padding: "6px",
                                      boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
                                      mb: ".3rem",
                                    }}
                                    onClick={() => {
                                      setCurrentCardID(item?.id);
                                      setName(item?.name);
                                      setPerson(item?.personName);
                                      setEmail(item?.email);
                                      setFrequency(item?.frequency);
                                      setStartDate(item?.startDate);
                                      setEndDate(item?.endDate);
                                      setType(item?.type);
                                      setDescription(item?.content);
                                      setOpenModalUpdate(true);
                                      setCurrentStatus(item?.status);
                                      setCreatedID(item?.createdID);
                                      setCompletedID(item?.createdID);
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 0.5,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          backgroundColor: iconBgColor,
                                          p: 0.5,
                                          borderRadius: "5px",
                                          height: "fit-content",
                                        }}
                                      >
                                        <WidgetsIcon
                                          sx={{ color: iconColor }}
                                        />
                                      </Box>
                                      <Box>
                                        <Typography
                                          variant="h6"
                                          sx={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {item?.name}
                                        </Typography>
                                        <Typography sx={{ fontSize: ".6rem" }}>
                                          {" "}
                                          {item?.content?.slice(0, 100)}
                                          {item?.content?.length > 100
                                            ? "..."
                                            : ""}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  ))}
                </DragDropContext>
              </Box>

              {!!props?.user?.dob && (
                <GoalScale data={lineData} user={props.user} />
              )}
            </Box>
          </Card>
          {/* Mobile Card */}

          <Box sx={{ display: { xs: "block", md: "none" } }}>
    {/* Mobile Card */}
    {view === "timeline" && (
      <Box my={2}>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            setOpenModal(true);
          }}
          disableRipple
          sx={{
            backgroundColor: theme.palette.primary.main,
            borderRadius: ".5rem",
            color: theme.palette.common.white,
            width: "100%",
            p: "6px 10px",
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
              opacity: 0.8,
            },
          }}
        >
          Add New Goal
        </Button>
      </Box>
    )}
    <Box
      sx={{
        background: theme.palette.common.disabled,
        width: "100%",
        margin: " 0px 10px 0px 0px",
        minHeight: 300,
        borderRadius: "16px",
      }}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          textColor="inherit"
          indicatorColor="primary"
          variant="fullWidth"
        >
          {["TO DO", "IN PROGRESS", "DONE"]?.map((item, index) => {
            return (
              <Tab
                key={index}
                label={item}
                disableRipple
                sx={{
                  backgroundColor:
                    index === 0
                      ? theme.palette.error.main
                      : index === 1
                      ? theme.palette.primary.main
                      : theme.palette.success.main,
                  color: theme.palette.common.white,
                  borderRadius:
                    index === 0
                      ? "16px 0px 0px 0px"
                      : index === 1
                      ? "0px"
                      : "0px 16px 0px 0px",
                  fontSize: ".7rem",
                  padding: "0px",
                  minWidth: 0,
                  flexGrow: 1,
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box sx={{ padding: "4px 8px" }}>
        {value === 0 &&
          state[0]?.map((item, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: theme.palette.common.white,
                borderRadius: "8px",
                padding: "6px",
                boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
                marginBottom: "8px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={() => openCardModal(item)}
            >
              <Box sx={{ display: "flex", gap: 0.5 }}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.error.extraLight,
                    p: 0.5,
                    borderRadius: "5px",
                    height: "fit-content",
                  }}
                >
                  <WidgetsIcon sx={{ color: theme.palette.error.main }} />
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography sx={{ fontSize: ".6rem" }}>
                    {item?.content?.slice(0, 100)}
                    {item?.content?.length > 100 ? "..." : ""}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation(); 
                    moveItem(item, 0, 1);
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            </Box>
          ))}

        {value === 1 &&
          state[1]?.map((item, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: theme.palette.common.white,
                borderRadius: "8px",
                padding: "6px",
                boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
                marginBottom: "8px",
              }}
              onClick={() => openCardModal(item)}
            >
              <Box display="flex" justifyContent="space-between">
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      moveItem(item, 1, 0);
                    }}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  <Box
                    sx={{
                      backgroundColor: theme.palette.primary.extraLight,
                      p: 0.5,
                      borderRadius: "5px",
                      height: "fit-content",
                    }}
                  >
                    <WidgetsIcon sx={{ color: theme.palette.primary.main }} />
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Typography sx={{ fontSize: ".6rem" }}>
                      {item?.content?.slice(0, 100)}
                      {item?.content?.length > 100 ? "..." : ""}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      moveItem(item, 1, 2); 
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          ))}

        {value === 2 &&
          state[2]?.map((item, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: theme.palette.common.white,
                borderRadius: "8px",
                padding: "6px",
                boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
                marginBottom: "8px",
              }}
              onClick={() => openCardModal(item)}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.success.extraLight,
                    p: 0.5,
                    borderRadius: "5px",
                    height: "fit-content",
                  }}
                >
                  <WidgetsIcon sx={{ color: theme.palette.success.main }} />
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography sx={{ fontSize: ".6rem" }}>
                    {item?.content?.slice(0, 100)}
                    {item?.content?.length > 100 ? "..." : ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  </Box>
        </Box>

        <form>
          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              style={{ textAlign: "center", fontWeight: 600 }}
              id="alert-dialog-title"
            >
              Add Goal
            </DialogTitle>
            <DialogContent sx={{ maxWidth: "lg" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    width="100%"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 0.5,
                      mb: "1rem",
                    }}
                  >
                    <img src="assets/img/iconsBg.png" alt="iconsBg" />
                    <Typography variant="body1" textAlign="center">
                      Add Icon For Your Goal
                    </Typography>
                    <Typography
                      variant="h4"
                      color={theme.palette.success.main}
                      textAlign="center"
                    >
                      Explore{" "}
                      <span style={{ color: theme.palette.primary.main }}>
                        Icons
                      </span>
                    </Typography>
                    <TextField
                      variant="filled"
                      placeholder="Type any word or term here"
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ pb: ".1rem" }} />
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiFilledInput-root": {
                          backgroundColor: "transparent",
                          border: `1px solid ${theme.palette.common.lightGray}`,
                          padding: "5px 5px",
                          borderRadius: "8px",
                          "&:hover": {
                            backgroundColor: "transparent",
                            borderColor: theme.palette.common.black,
                          },
                          "&.Mui-focused": {
                            backgroundColor: "transparent",
                            borderColor: theme.palette.common.black,
                          },
                        },
                        "& .MuiFilledInput-input": {
                          paddingTop: "8px",
                          paddingBottom: "2px",
                        },
                      }}
                    />
                  </Card>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    height="100%"
                    justifyContent="space-between"
                  >
                    <CustomTextField
                      label="Enter Name"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <CustomDropDown
                      label="Select Type"
                      options={cardType}
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextField
                    label="Description"
                    placeholder="Description"
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <CustomTextField
                    label="Goal Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <CustomTextField
                    label="Goal End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <CustomTextField
                    label="Person Name Will Check Your Progress"
                    placeholder="Person Name Will Check Your Progress"
                    value={person}
                    onChange={(e) => setPerson(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <CustomTextField
                    label="Email Address of Person Who Will Check In"
                    placeholder="Email Address of Person Who Will Check In"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <CustomDropDown
                    label="Frequency of Check-ins"
                    options={[
                      { value: 7, name: "Weekly" },
                      { value: 14, name: "Bi-weekly" },
                      { value: 30, name: "Monthly" },
                    ]}
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                width="50%"
                onClick={() => {
                  if (!name) {
                    toast.error("Please Enter Person Name");
                    return false;
                  }
                  if (name.length < 3) {
                    toast.error("Name must be at least 3 characters");
                    return false;
                  }
                  if (!isValidEmail(email) || !email) {
                    toast.error("Please Enter a valid email.");
                    return false;
                  }
                  if (!frequency || frequency < 0 || frequency > 365) {
                    toast.error("Frequency must be between 0 and 365");
                    return false;
                  }
                  let header = {
                    name: name,
                    // startDate: startDate,
                    endDate: endDate,
                    description: description,
                    type: type,
                    status: "todo",
                    personName: person,
                    email: email,
                    frequency: +frequency,
                  };
                  postFinancialData(header);
                  setDescription("");
                  setOpenModal(false);
                }}
                type="submit"
              >
                Add Goal
              </Button>
            </DialogActions>
          </Dialog>
        </form>

        <form>
          <Dialog
            open={openModalUpdate}
            onClose={() => setOpenModalUpdate(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle
              style={{ textAlign: "center", fontWeight: 600 }}
              id="alert-dialog-title"
            >
              {currentStatus === "done" ? "Goal Completed" : "Update Goal"}
            </DialogTitle>
            <DialogContent>
              {currentStatus === "done" ? (
                
                  <CertificateIcon
                    name={name}
                    createdID={createdID}
                    completedID={completedID}
                    props={props}
                  />
                
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card
                      width="100%"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 0.5,
                        mb: "1rem",
                      }}
                    >
                      <img src="assets/img/iconsBg.png" alt="iconsBg" />
                      <Typography variant="body1" textAlign="center">
                        Add Icon For Your Goal
                      </Typography>
                      <Typography
                        variant="h4"
                        color="success.main"
                        textAlign="center"
                      >
                        Explore{" "}
                        <span style={{ color: "primary.main" }}>Icons</span>
                      </Typography>
                      <TextField
                        variant="filled"
                        placeholder="Type any word or term here"
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon sx={{ pb: ".1rem" }} />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          "& .MuiFilledInput-root": {
                            backgroundColor: "transparent",
                            border: `1px solid lightGray`,
                            padding: "5px 5px",
                            borderRadius: "8px",
                            "&:hover": {
                              backgroundColor: "transparent",
                              borderColor: "black",
                            },
                            "&.Mui-focused": {
                              backgroundColor: "transparent",
                              borderColor: "black",
                            },
                          },
                          "& .MuiFilledInput-input": {
                            paddingTop: "8px",
                            paddingBottom: "2px",
                          },
                        }}
                      />
                    </Card>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={1}
                      height="100%"
                      justifyContent="space-between"
                    >
                      <CustomTextField
                        label="Name"
                        placeholder="Type..."
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                      />
                      <CustomDropDown
                        label="Select Type"
                        options={cardType}
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      label="Description"
                      placeholder="Type..."
                      multiline
                      rows={3}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      label="Goal Start Date"
                      type="date"
                      value={moment(startDate).format("YYYY-MM-DD")}
                      onChange={(e) => setStartDate(e.target.value)}
                      disabled={currentStatus === "done"}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      label="Goal End Date"
                      type="date"
                      value={moment(endDate).format("YYYY-MM-DD")}
                      onChange={(e) => setEndDate(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      label="Name of Person who Will Check In On Your Progress"
                      placeholder="Type..."
                      value={person}
                      onChange={(e) => setPerson(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTextField
                      label="Email address of person who will check in with you"
                      placeholder="Type..."
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomDropDown
                      label="Frequency of Check-ins"
                      options={[
                        { value: 7, name: "Weekly" },
                        { value: 14, name: "Bi-weekly" },
                        { value: 30, name: "Monthly" },
                      ]}
                      value={frequency}
                      onChange={(e) => setFrequency(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions sx={{ mr: "30px" }}>
              {currentStatus !== "done" ? (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setOpenModalUpdate(false);
                      setEndDate(moment().format("YYYY-MM-DD"));
                      setStartDate(moment().format("YYYY-MM-DD"));
                      setName("");
                      setDescription("");
                      setFrequency(null);
                      setPerson("");
                      setEmail("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.error.main,
                      "&:hover": {
                        backgroundColor: theme.palette.error.dark,
                      },
                    }}
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      let header = {
                        id: currentCardID,
                      };

                      deleteFinancialData(header);
                      setName("");
                      setEndDate(moment().format("YYYY-MM-DD"));
                      setStartDate(moment().format("YYYY-MM-DD"));
                      setDescription("");
                      setFrequency(null);
                      setPerson("");
                      setEmail("");
                      setTimeout(() => {
                        setOpenModalUpdate(false);
                      }, 1000);
                    }}
                  >
                    Delete Goal
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (!name) {
                        toast.error("Please Enter Person Name");
                        return false;
                      }
                      if (name.length < 3) {
                        toast.error("Name must be at least 3 characters");
                        return false;
                      }
                      if (!isValidEmail(email) || !email) {
                        toast.error("Please Enter a valid email.");
                        return false;
                      }
                      if (!frequency || frequency < 0 || frequency > 365) {
                        toast.error("Frequency must be between 0 and 365");
                        return false;
                      }
                      let header = {
                        id: currentCardID,
                        name: name,
                        // startDate: startDate,
                        endDate: moment(endDate).format("YYYY-MM-DD"),
                        description: description,
                        type: type,
                        personName: person,
                        email: email,
                        frequency: parseInt(frequency),
                      };
                      putFinancialDataFull(header);
                      setDescription("");
                      setName("");
                      setFrequency(null);
                      setPerson("");
                      setEmail("");
                      setEndDate(moment().format("YYYY-MM-DD"));
                      setStartDate(moment().format("YYYY-MM-DD"));
                      setTimeout(() => {
                        setOpenModalUpdate(false);
                      }, 1000);
                    }}
                    autoFocus
                  >
                    Update Goal
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSave();
                      setDescription("");
                      setEndDate(moment().format("YYYY-MM-DD"));
                      setStartDate(moment().format("YYYY-MM-DD"));
                    }}
                  >
                    Save
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        </form>
      </>
    );
}

// var financialGoalsData = {
//   status: true,
//   data: [
//     {
//       id: 2,
//       description: "goal 2",
//       name: "goal2 ",
//       userId: 2,
//       status: "todo",
//       type: "shortterm",
//       createdId: "169ffcf1-dd4f-442e-89e7-d385b6ac331f",
//       completedId: "",
//       isDeleted: false,
//       startDate: "2024-08-23T11:27:40.283Z",
//       endDate: "2024-08-23T23:59:59.000Z",
//       personName: "qwer",
//       email: "qwer@gmail.com",
//       frequency: 14,
//       createdAt: "2024-08-23T11:27:40.283Z",
//       updatedAt: "2024-08-23T11:30:28.276Z",
//     },

//     {
//       id: 1,
//       description: "goal 1",
//       name: "goal",
//       userId: 2,
//       status: "done",
//       type: "midterm",
//       createdId: "8df06299-b897-4a75-8f45-6db1f561548d",
//       completedId: "202f566c-7954-4956-b2a3-1160f15e885c",
//       isDeleted: false,
//       startDate: "2024-08-23T11:21:11.483Z",
//       endDate: "2024-08-25T23:59:59.000Z",
//       personName: "abc",
//       email: "abc@gmail.com",
//       frequency: 7,
//       createdAt: "2024-08-23T11:21:11.483Z",
//       updatedAt: "2024-08-23T11:26:35.589Z",
//     },
//   ],
//   message: "Financial Goals Were Fetched Successfully !",
// };
