import React, { useState } from "react";
import { Box, Button, Card, MenuItem, Typography } from "@mui/material";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "./tabs/cardRapper";
import CalTab from "./tabs";
import {
  ListL_LG,
  renderFormatData,
  NumericFormat,
  RecalculateButton,
  TextField,
  PieChart,
} from "./tabs/list";
import { useTheme } from "@mui/material/styles";

import Footer from "../footer";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";

export default function SavingForGoal() {
  const { t } = useTranslation();
  const [duration, setDuration] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [itemsCost, setItemsCost] = useState(null);
  const [currentSaved, setCurrentSaved] = useState(null);
  const [otherIncome, setOtherIncome] = useState(null);
  const [contributing, setContributing] = useState(null);
  const theme = useTheme()

  const trials = [
    {
      label: t("weekly"),
      value: 52,
    },
    {
      label: t("biWeekly"),
      value: 26,
    },
    {
      label: t("monthly"),
      value: 12,
    },
    {
      label: t("yearly"),
      value: 1,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const totalCost = Number(itemsCost?.replace(/[^0-9.-]+/g, ""));
    const currentSavings = Number(currentSaved?.replace(/[^0-9.-]+/g, ""));
    const otherIncom = Number(otherIncome?.replace(/[^0-9.-]+/g, ""));
    const regularContribution = Number(contributing?.replace(/[^0-9.-]+/g, ""));
    const frequency = duration;

    const totalAmountNeeded = totalCost - currentSavings - otherIncom;

    let timeNeeded = totalAmountNeeded / regularContribution;

    setShowResults(true);
    let temp = [
      {
        color: "#5C34D5",
        data: totalCost,
        label: t("costOfItems"),
      },
      {
        color: "#4898FF",
        data: totalAmountNeeded,
        label: t("reduceItTo"),
      },
      {
        color: "#51F074",
        data: timeNeeded,
        label: t("youCanAfford"),
      },
    ];
    setChartData(temp);
  };

  const renderResultTabs = () => {
    return (
      <>
   <Box>
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 1,
          }}
        >
           <PieChart data={chartData} />
        </Card>

        <Box>
          {chartData?.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: theme.palette.common.background,
                p: 1,
                mt: 1,
                borderRadius: 1,
              }}
            >
              <Typography>{item?.label}</Typography>
              <Typography>
                {renderFormatData(item.data, item)}
              </Typography>
            </Box>
          ))}
          <Box mt={2} textAlign="center">
          <RecalculateButton
            onClick={() => {
              setShowResults(false);
            }}
          />
          </Box>
        </Box>
      </Box>

        {/* <CalTab setTabValue={setTabValue} tabValue={tabValue}>
          {tabValue === "Results" && (
            <Grid item xs={12} md={12} sx={{ mt: 3 }}>
              <List>
                {chartData?.map((item, i) => (
                  <ListL_LG
                    key={i}
                    left={item?.label}
                    right={renderFormatData(item.data, item)}
                  />
                ))}
                <Divider />
              </List>
            </Grid>
          )}
          {tabValue === "Graph" && <PieChart data={chartData} />}
          <RecalculateButton
            onClick={() => {
              setShowResults(false);
            }}
          />
        </CalTab> */}
      </>
    );
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Total desired items cost:"
        required
        name="purchasePrice"
        variant="standard"
        value={itemsCost}
        onChange={(e) => {
          setItemsCost(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="How much do you currently have saved?"
        required
        variant="standard"
        value={currentSaved}
        onChange={(e) => {
          setCurrentSaved(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Other Income (monetary gifts)"
        required
        variant="standard"
        value={otherIncome}
        onChange={(e) => {
          setOtherIncome(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <TextField
        className="card-input"
        select
        label="Savings"
        required
        variant="standard"
        value={duration}
        onChange={(e) => {
          setDuration(e.target.value);
        }}
      >
        {trials.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <NumericFormat
        className="card-input"
        label="How much are you contributing to your savings?"
        required
        variant="standard"
        value={contributing}
        onChange={(e) => {
          setContributing(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />

      <Box textAlign="center" mt={1}>
        <Button
          variant="contained"
          sx={{ width: "70%" }}
          type="submit"
          className="btn btn-calculate"
        >
          Calculate
        </Button>
      </Box>
    </form>
  );
  return showResults ? renderResultTabs() : renderForm();
}
