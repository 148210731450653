import React, { useState, useEffect } from "react";
import SkillSectionDetails from "./SkillSectionDetails";
import Header from "../header";
import "../style/auto.css";
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query'
import { getSkillSectionsBySubSkillID, getSkillSection } from '../../_api/boost'
import Parser from 'html-react-parser'
import { CircularProgress } from '@mui/material';

export default function SkillSections() {
  const navigation = useNavigate();
  const [activeID, setActiveID] = useState(null);
  const [skillSections, setSkillSections] = useState([])
  var url = new URL(window.location.href);
  var subSkillID = url?.searchParams.get("subSkillID");
  var SkillSectionID = url?.searchParams.get("skillSectionID");

  const { isLoading: skillSectionLoading, data: skillSection } = useQuery(
    ["get-single-skillsection", activeID],
    () => getSkillSection(activeID),
    { enabled: !!activeID }
  );
  const { isLoading, data: allSkillSections } = useQuery(
    ["get-skillsections-by-subkillId", subSkillID],
    () => getSkillSectionsBySubSkillID(subSkillID)
  );
  const navigate = (param) => {
    navigation(param, { replace: true });
  };
  useEffect(() => {
    if (!isLoading) {
      setSkillSections(allSkillSections?.data)
      setActiveID(SkillSectionID ? SkillSectionID : allSkillSections?.data[0]?.id)
    }
  }, [isLoading, allSkillSections])
  useEffect(() => {
    setActiveID(SkillSectionID)
  }, [SkillSectionID])

  if (isLoading) return <div className="container d-flex justify-content-center mt-5 pt-5">
    <CircularProgress />
  </div>
  return (
    <>
      <Header />
      <div className="container-fluid mt-5" style={{ paddingTop: '40px' }}>
        <div className="row" style={{ height: 'fit-content' }}>
          <div className="col-md-2">
            <h2>Skill Sections</h2>
            <div className="list-auto">
              {
                !skillSections?.length &&
                <p>No Skill Section Found!</p>
              }
              <ul>
                {
                  skillSections?.map(ss => {
                    return (
                      <li
                        className={activeID === ss?.id && "auto-active"}
                        onClick={() => {
                          navigate(`/boost/skill-sections?subSkillID=${subSkillID}&skillSectionID=${ss?.id}`);
                          setActiveID(ss?.id);
                          if (ss?.contentType === "Metaverse") {
                            window.location.reload()
                          }
                        }}
                      >
                        {Parser(ss?.name)}
                      </li>
                    )
                  })
                }

              </ul>
            </div>
          </div>
          <div className="col-md-10">
            {
              skillSectionLoading ?
                <div className="d-flex justify-content-center">
                  <CircularProgress />
                </div>
                :
                skillSection?.data ?
                  <SkillSectionDetails content={skillSection?.data} />
                  : <></>
            }

          </div>
        </div>
      </div>
    </>
  );
}
