const GoldSilverIcon = ({color}) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_71_5656)">
        <path
          d="M26.2115 19.2267H22.1961L19.6934 12.5529H10.5063L8.00364 19.2267H3.98822L0.859863 27.569H29.3399L26.2115 19.2267ZM11.6626 14.2213H18.5372L20.4142 19.2267H9.78555L11.6626 14.2213ZM3.26745 25.9005L5.14446 20.8951H12.0191L13.8961 25.9005H3.26745ZM15.0999 24.3589L13.801 20.8951H16.3988L15.0999 24.3589ZM18.1807 20.8951H25.0553L26.9323 25.9005H16.3037L18.1807 20.8951Z"
          fill={color}
        />
        <path
          d="M14.2656 2.43091H15.9341V5.76782H14.2656V2.43091Z"
          fill={color}
        />
        <path
          d="M7.2583 4.75537L8.70318 3.92114L10.3716 6.81091L8.92676 7.64514L7.2583 4.75537Z"
          fill={color}
        />
        <path
          d="M2.35254 10.2722L3.18677 8.82733L6.07653 10.4958L5.24231 11.9407L2.35254 10.2722Z"
          fill={color}
        />
        <path
          d="M0.862305 15.8342H4.19922V17.5027H0.862305V15.8342Z"
          fill={color}
        />
        <path
          d="M26.0005 15.8342H29.3374V17.5027H26.0005V15.8342Z"
          fill={color}
        />
        <path
          d="M24.1226 10.4955L27.0123 8.82703L27.8466 10.2719L24.9568 11.9404L24.1226 10.4955Z"
          fill={color}
        />
        <path
          d="M19.8276 6.81152L21.4961 3.92176L22.941 4.75598L21.2725 7.64575L19.8276 6.81152Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_71_5656">
          <rect
            width="28.48"
            height="28.48"
            fill="white"
            transform="translate(0.859863 0.76001)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoldSilverIcon;
