import { currencyFormat } from "../_budget-functionality";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useMediaQuery } from "@mui/material";

export default function Asset(props) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const handleViewDetailsClick = () => {
    props.onViewDetails(props?.data);
  };

  return (
    <Box
      key={props?.keyId}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={theme.palette.common.background}
      padding="11px"
      borderRadius="8px"
    >
      <Box display="flex" gap={{ xs: 0.2, md: 1 }} alignItems="center">
        <Box
          sx={{
            backgroundColor: theme.palette.primary.extraLight,
            borderRadius: "50%",
            width: { xs: "1.5rem", md: "2.5rem" },
            height: { xs: "1.5rem", md: "2.5rem" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: 0.5, md: 1 },
          }}
        >
          {<props.data.icon color={theme.palette.primary.main} />}
        </Box>
        <Typography fontSize={{ xs: ".7rem", md: ".9rem" }} fontWeight="600">
          {props?.data?.title}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={{ xs: 1, md: 3 }}>
        <Box display="flex" alignItems="center">
          <Typography variant="body1" display={{ xs: "none", md: "block" }}>
            Amount :{" "}
          </Typography>
          <Typography fontSize={{ xs: "1rem", md: "1.3rem" }} fontWeight="700">
            {" "}
            {currencyFormat(parseInt(props?.data?.value))}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            startIcon={<VisibilityIcon />}
            onClick={handleViewDetailsClick}
            sx={{
              bgcolor: theme.palette.common.text,
              color: theme.palette.common.white,
              fontSize: { xs: "10px", md: "20px" },
              "&:hover": {
                bgcolor: theme.palette.common.text,
                opacity: 0.9,
              },
            }}
          >
            {isSmallScreen ? "View" : "View Detail"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
