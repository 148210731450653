
function EditAccount() {
    return (<>
        <div className="appHeader mb-5">
            <div className="left">
                <a href="#" className="headerButton goBack" 
                // onClick={() => window.history.go(-1)}
                >
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div className="right">
                <a href="#" className="headerButton">
                    Remove
                </a>
            </div>
        </div>
        <div className="row d-flex align-items-center mt-5 px-3 pt-5">
            <h2 className="text-primary">Edit Account</h2>
            <p>Account Name <br /><span className="text-dark">Cash</span></p>
            <div className="d-flex">
                <div className="form-check form-switch mt-1 mr-2">
                    <input className="form-check-input" type="checkbox" />
                    <label className="form-check-label" htmlFor="darkmodeSwitch"></label>
                </div>
                <div>
                    <h4 className="p-0 m-0">Include in Net Worth</h4>
                    <p className="p-0 m-0" style={{ fontSize: '12px', lineHeight: '1.5' }}>Toggle off to hide from net worth charts and calculations</p>
                </div>
            </div>
            <div className="fixed-bar">
                <button className="btn btn-lg btn-block btn-primary">Save Changes</button>
            </div>
        </div>
    </>);
}

export default EditAccount;