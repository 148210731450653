import { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { currencyFormat } from '../_budget-functionality/index'


import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import IconButton from '@mui/material/IconButton';


import { GrTest } from 'react-icons/gr';
import { FcHome } from "react-icons/fc"
import { FcGraduationCap } from "react-icons/fc"
import { FcAlarmClock } from "react-icons/fc"
import { FaMoneyCheckAlt } from "react-icons/fa"
import { BsCurrencyBitcoin } from "react-icons/bs"
import { GiPalmTree } from "react-icons/gi"
import { GiGoldBar } from "react-icons/gi"
import { GiEarrings } from "react-icons/gi"
import { GiSonicShoes } from "react-icons/gi"
import { GiFishingBoat } from "react-icons/gi"
import { AiTwotoneGift } from "react-icons/ai"
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

// import {
//     Chart, ArgumentAxis, ValueAxis, AreaSeries,
// } from '@devexpress/dx-react-chart-material-ui';
// import { ArgumentScale, Animation } from '@devexpress/dx-react-chart';
// import { styled } from '@mui/material/styles';
// import { scalePoint } from 'd3-scale';
// import ValueChangeModal from './ValueChangeModal';
import Loading from '../common/loading';
import { useQuery } from 'react-query';
import { getAllData } from '../_api/index';
import { GetNetWorthItemData } from '../_budget-functionality/index';
import EditModel from './EditModel'
import DeleteModel from './DeleteModel'

// const data = [
//     { year: '2010', android: 0, },
//     { year: '2011', android: 500, },
//     { year: '2012', android: 1000, },
// ];

// const PREFIX = 'Demo';

// const classes = {
//     chart: `${PREFIX}-chart`,
//     color: '#fff',
// };

// const StyledChartRoot = styled(Chart.Root)(() => ({
//     [`&.${classes.chart}`]: {
//         paddingRight: '20px',
//         color: '#fff',
//     },
// }));

// const ChartRoot = props => (
//     <StyledChartRoot {...props} className={classes.chart} style={{ color: '#fff' }} />
// );

function ValueCahange() {
    const navigate = useNavigate()
    const { isLoading, data: allData } = useQuery("budgeting-calculators", getAllData, {
        enabled: Boolean(sessionStorage.getItem('token'))
    });

    var url = new URL(window.location.href);
    var type = url?.searchParams?.get("type");


    const assetData = [
        {
            title: "Cash",
            type: "Add",
            subcategory: "Cash",
            icon: <img src="/assets/img/icon/cash.svg" width="50px" height="50px" />,

        },
        {
            title: "Bank",
            type: "Add",
            subcategory: "Bank",
            icon: <img src="/assets/img/icon/bank.svg" width="50px" height="50px" />,

        },
        {
            title: "Test",
            type: "Add",
            subcategory: "Test",
            icon: <GrTest style={{ fontSize: "50px" }} />,

        },
        {
            title: "Savings",
            type: "Add",
            subcategory: "Savings",
            icon: <img src="/assets/img/icon/saving.svg" width="50px" height="50px" />,

        },
        {
            title: "Checking",
            type: "Add",
            subcategory: "Checking",
            icon: <FaMoneyCheckAlt style={{ fontSize: "50px", color: "black" }} />,

        },
        {
            title: "Vehicle",
            type: "Add",
            subcategory: "Vehicles",
            icon: <DeliveryDiningIcon style={{ fontSize: "50px", color: "black" }} />,

        },


        {
            title: "Real Estate",
            type: "Add",
            subcategory: "RealEstate",
            icon: <FcHome style={{ fontSize: "50px" }} />,

        },
        {
            title: "Crypto",
            type: "Add",
            subcategory: "Crypto",
            icon: <BsCurrencyBitcoin style={{ fontSize: "50px", color: "black" }} />,

        },
        {
            title: "Investments",
            type: "Add",
            subcategory: "Investments",
            icon: <img src="/assets/img/icon/investments.svg" width="50px" height="50px" />,

        },
        {
            title: "401k/IRA",
            type: "Add",
            subcategory: "IRA",
            icon: <GiPalmTree style={{ fontSize: "50px", color: "black" }} />,

        },
        {
            title: "529 Plan",
            type: "Add",
            subcategory: "Plan",
            icon: <FcGraduationCap style={{ fontSize: "50px" }} />,

        },
        {
            title: "Gold/Silver",
            type: "Add",
            subcategory: "GoldSilver",
            icon: <GiGoldBar style={{ fontSize: "50px", color: "black" }} />,

        },
        {
            title: "Jewelry",
            type: "Add",
            subcategory: "Jewelry",
            icon: <GiEarrings style={{ fontSize: "50px", color: "black" }} />,

        },
        {
            title: "Collectibles",
            type: "Add",
            subcategory: "Collectibles",
            icon: <GiSonicShoes style={{ fontSize: "50px", color: "black" }} />,

        },
        {
            title: "Instruments",
            type: "Add",
            subcategory: "Instruments",
            icon: <FcAlarmClock style={{ fontSize: "50px" }} />,

        },
        {
            title: "Boat",
            type: "Add",
            subcategory: "Boat",
            icon: <GiFishingBoat style={{ fontSize: "50px", color: "black" }} />,

        },
        {
            title: "Other",
            type: "Add",
            subcategory: "Other",
            icon: <AiTwotoneGift style={{ fontSize: "50px", color: "black" }} />,

        },
        {
            title: "Credit Card",
            type: "Delete",
            subcategory: "CreditCard",
            icon: <img src="/assets/img/icon/creditCard.svg" width="50px" height="50px" />,

        },
        {
            title: "Mortgage",
            type: "Delete",
            subcategory: "Mortgage",
            icon: <img src="/assets/img/icon/mortgage.svg" width="50px" height="50px" />,

        },
        {
            title: "Auto Loan",
            type: "Delete",
            subcategory: "AutoLoan",
            icon: <img src="/assets/img/icon/autoloan.svg" width="50px" height="50px" />,

        },
        {
            title: "Personal Loan",
            type: "Delete",
            subcategory: "PersonalLoan",
            icon: <img src="/assets/img/icon/cash.svg" width="50px" height="50px" />,


        },
        {
            title: "Student Loan",
            type: "Delete",
            subcategory: "StudentLoan",
            icon: <FcGraduationCap style={{ fontSize: "50px" }} />,

        },
        {
            title: "Other",
            type: "Delete",
            subcategory: "OtherDebt",
            icon: <AiTwotoneGift style={{ fontSize: "50px" }} />,

        },
    ]

    const { id } = useParams();
    const [itemDetails, setItemDetails] = useState([])
    const [value, setValue] = useState(0)
    useEffect(() => {
        if (!isLoading) {
            setItemDetails(GetNetWorthItemData("NetWorth", allData, id, type))
        }
    }, [allData, value])
    function getTotal() {
        let total = 0
        itemDetails.map((item) => {
            total = total + parseInt(item.amount)
        })
        return (total)
    }
    if (isLoading)
        return (<Loading />)
    else
        return (<>
            {/* <ValueChangeModal setValue={setValue} /> */}
            <Link to="/net-worth" onClick={(e) => {
                e.preventDefault()
                navigate(-1)
            }} className="appHeader customHeader mb-5 goBack">
                <div className="customLeft left">
                    <a href="#" className="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                        <h4>Go Back</h4>
                    </a>
                </div>
                <div className="right">
                    {/* <Link to="/edit-account" className="headerButton">
                        Edit
                    </Link> */}
                </div>
            </Link>
            <div className="row d-flex justify-content-center text-center mt-5 pt-5">
                <div className="d-flex justify-content-center mt-5 pt-3">
                    <ul className='adit_asset_ul'>
                        <li>
                            {
                                assetData.map((item, index) => {
                                    return (item.subcategory === id && item.icon)
                                })
                            }
                        </li>
                        <li>
                            <h2 className="mt-2 ml-2">{
                                assetData.map(item => {
                                    return (item.subcategory === id && item.title)
                                })
                            }</h2>
                        </li>
                    </ul>
                    <div className="dollor_current ml-5">
                        <p className='th_asset_debt mb-0'>current balance</p>
                        <h1 className="text-primary">{currencyFormat(parseInt(getTotal()))}</h1>
                    </div>
                </div>
                <div id="appCapsule" className="bg-white mx-5 mt-2 pt-0 p-3">
                    <h5 className="text-start fw-bold mt-2 pl-4 mob_res_table">VALUE HISTORY</h5>
                    <div className="table-responsive px-4 mob_res_table">
                        <table className="table">
                            <thead>
                                <tr>
                                    {
                                     itemDetails.length === 0 ?
                                            <th scope="col">No Data Found</th>
                                            :
                                            <>
                                                <th className="text-start th_asset_debt" scope="col">Date</th>
                                                <th className="text-start th_asset_debt" scope="col">Description</th>
                                                <th scope="col" className="text-end th_asset_debt">Amount</th>
                                                <th scope="col" className="text-end th_asset_debt">Actions</th>
                                    </>
                                    }
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    itemDetails.length !== 0 ?
                                        itemDetails.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="text-start" scope="row">
                                                        {item.name !== null ? item.name : "Contains No Title"} <br />
                                                        {moment(item.createdAt).format("MMMM Do YYYY")}
                                                    </td>
                                                    <td className="text-start" scope="row">
                                                        {item.description !== null ? item.description : "Contains No Title"} <br />
                                                    </td>
                                                    <td className="text-end text-primary" style={{ paddingTop: '25px' }}>{currencyFormat(parseInt(item.amount))}</td>
                                                    <td className="text-end text-primary" >
                                                        <EditModel id={item?.id} title={item?.subCategory} value={item?.amount} type={item?.type} description={item?.description} />
                                                        <DeleteModel id={item.id} title={item.subCategory} />
                                                        <IconButton data-bs-toggle="modal" data-bs-target={"#mod" + item.id}>
                                                            <EditRoundedIcon />
                                                        </IconButton>
                                                        <IconButton data-bs-toggle="modal" data-bs-target={"#delMod" + item.id}>
                                                            <DeleteRoundedIcon />
                                                        </IconButton>
                                                    </td>
                                                </tr>)
                                        })
                                        :
                                        <tr scope="row">
                                            <td>No history for this time priod</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <ul className="listview image-listview inset">
                        {
                            itemDetails.map((item, key) => {
                                return (<>
                                    <li key={key}>
                                        <div href="#" className="item">
                                            <div className="in text-left">
                                                <span>
                                                    {item.name !== null ? item.name : "Contains No Title"}
                                                    <footer>{moment(item.createdAt).format("MMMM Do YYYY")}</footer>
                                                </span>
                                                <div className="text-primary"><b>{currencyFormat(parseInt(item.amount))}</b></div>
                                            </div>
                                        </div>
                                    </li>
                                </>)
                            })
                        }
                    </ul> */}
                </div>
            </div>
        </>);
}

export default ValueCahange;