import React, { useState, useEffect } from 'react';
import Header from './Header'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import CurrencyFormat from 'react-currency-format';

import HomeWorkIcon from '@mui/icons-material/HomeWork';

import { BsImage } from "react-icons/bs"
import { FcMoneyTransfer } from "react-icons/fc"
import { FcCurrencyExchange } from "react-icons/fc"
import { FaRegMoneyBillAlt } from "react-icons/fa"
import { FcAutomotive } from "react-icons/fc"
import { FcHome } from "react-icons/fc"
import { FcComboChart } from "react-icons/fc"
import { FcGraduationCap } from "react-icons/fc"
import { FcAlarmClock } from "react-icons/fc"
import { FaMoneyCheckAlt } from "react-icons/fa"
import { BsCurrencyBitcoin } from "react-icons/bs"
import { BsCreditCard2Front } from "react-icons/bs"
import { BsCashCoin } from "react-icons/bs"
import { GiPalmTree } from "react-icons/gi"
import { GiGoldBar } from "react-icons/gi"
import { GiEarrings } from "react-icons/gi"
import { GiSonicShoes } from "react-icons/gi"
import { GiFishingBoat } from "react-icons/gi"
import { AiTwotoneGift } from "react-icons/ai"
import { BsBank2 } from 'react-icons/bs';
import { GrTest } from 'react-icons/gr';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { addNewBudgetEntry } from '../_api/index'
import { useMutation, useQueryClient } from 'react-query';
import Loading from '../common/loading';
import toast from 'react-hot-toast';


export default function Add529Plan() {
    const queryClient = useQueryClient();
    const { isLoading: isLoading, isSuccess: isSuccess, mutate: addAmount } = useMutation(addNewBudgetEntry, {
        onSuccess: () => {
            queryClient.invalidateQueries('budgeting-calculators');
            toast.success("Added Successfully");
            navigate(-1)
        },
    });
    const navigate = useNavigate();
    const assetData = [
        {
            title: "Cash",
            type: "Add",
            subcategory: "Cash",
            icon: <FcMoneyTransfer />,

        },
        {
            title: "Bank",
            type: "Add",
            subcategory: "Bank",
            icon: <BsBank2 />,

        },
        {
            title: "Savings",
            type: "Add",
            subcategory: "Savings",
            icon: <FcCurrencyExchange />,

        },
        {
            title: "Checking",
            type: "Add",
            subcategory: "Checking",
            icon: <FaMoneyCheckAlt />,

        },
        {
            title: "Vehicle",
            type: "Add",
            subcategory: "Vehicles",
            icon: <DeliveryDiningIcon />,

        },


        {
            title: "Real Estate",
            type: "Add",
            subcategory: "RealEstate",
            icon: <FcHome />,

        },
        {
            title: "Crypto",
            type: "Add",
            subcategory: "Crypto",
            icon: <BsCurrencyBitcoin />,

        },
        {
            title: "Investments",
            type: "Add",
            subcategory: "Investments",
            icon: <FcComboChart />,

        },
        {
            title: "401k/IRA",
            type: "Add",
            subcategory: "IRA",
            icon: <GiPalmTree />,

        },
        {
            title: "529 Plan",
            type: "Add",
            subcategory: "Plan",
            icon: <FcGraduationCap />,

        },
        {
            title: "Gold/Silver",
            type: "Add",
            subcategory: "GoldSilver",
            icon: <GiGoldBar />,

        },
        {
            title: "Jewelry",
            type: "Add",
            subcategory: "Jewelry",
            icon: <GiEarrings />,

        },
        {
            title: "Collectibles",
            type: "Add",
            subcategory: "Collectibles",
            icon: <GiSonicShoes />,

        },
        {
            title: "Instruments",
            type: "Add",
            subcategory: "Instruments",
            icon: <FcAlarmClock />,

        },
        {
            title: "Boat",
            type: "Add",
            subcategory: "Boat",
            icon: <GiFishingBoat />,

        },
        {
            title: "Other",
            type: "Add",
            subcategory: "Other",
            icon: <AiTwotoneGift />,

        },
        {
            title: "Credit Card",
            type: "Delete",
            subcategory: "CreditCard",
            icon: <BsCreditCard2Front />,

        },
        {
            title: "Mortgage",
            type: "Delete",
            subcategory: "Mortgage",
            icon: <HomeWorkIcon />,

        },
        {
            title: "Auto Loan",
            type: "Delete",
            subcategory: "AutoLoan",
            icon: <FcAutomotive />,

        },
        {
            title: "Personal Loan",
            type: "Delete",
            subcategory: "PersonalLoan",
            icon: <FaRegMoneyBillAlt />,

        },
        {
            title: "Student Loan",
            type: "Delete",
            subcategory: "StudentLoan",
            icon: <FcGraduationCap />,

        },
        {
            title: "Other",
            type: "Delete",
            subcategory: "Other",
            icon: <AiTwotoneGift />,

        },
    ]
    const [itemDetails, setItemDetails] = useState({ title: "Item", icon: <BsImage /> })
    const [amount, setAmount] = useState(0)
    const [nameError, setNameError] = useState(false)
    const [amountError, setAmountError] = useState(false)
    const [dateError, setDateError] = useState(false)
    const [name, setName] = useState(itemDetails.title || "")
    const [date, setDate] = useState(null)
    const [description, setDescription] = useState(null)
    const [descriptionError, setDescriptionError] = useState(false)
    const { id } = useParams();

    var url = new URL(window.location.href);
    var type = url?.searchParams?.get("type");
    useEffect(() => {
        assetData.map((item => {
            if (item.subcategory === id) {
                setItemDetails(item)
                setName(item?.title)
            }
        }))
    }, [id])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!/^[a-zA-Z ]*$/.test(name)) {
            setNameError(true)
            setAmountError(false)
            setDateError(false)
            setDescriptionError(false)
            return
        }
        if (name === "" || name === null || name === undefined) {
            setNameError(true)
            setAmountError(false)
            setDateError(false)
            setDescriptionError(false)
        } else if (amount === "" || amount === null || amount === undefined || amount === 0 || amount === "0") {
            setNameError(false)
            setAmountError(true)
            setDateError(false)
            setDescriptionError(false)

            // } 
            // else if (date === "" || date === null || date === undefined || date === 0 || date === "0") {
            //     setNameError(false)
            //     setAmountError(false)
            //     setDateError(true)
            //     setDescriptionError(false)
        } else if (description === "" || description === null || description === undefined) {
            setNameError(false)
            setAmountError(false)
            setDateError(false)
            setDescriptionError(true)
        } else {
            const data = {
                name: name,
                amount: parseInt(amount),
                mainCategory: "NetWorth",
                subCategory: id,
                type: type,
                description: description,
            }
            addAmount(data);
            // toast.success(`${itemDetails.title} added successfully`)

        }
    }

    if (isLoading)
        return (<Loading />)
    else
        return <>
            <Header />
            <div id="appCapsule" className="px-5 bg-white">
                <h1 className="text-primary mt-4">Add {itemDetails.title}</h1>
                <p>Tell us about your {itemDetails.title}. You can update this information at any time.</p>
                <div className="form-group inputgroup mb-1">
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <label className="label"> Name</label>
                        <Input
                            type="text"
                            // required
                            className='name_before mb-0'
                            id="standard-adornment-amount"
                            // onChange={(e) => {
                            //     name !== "" ? setNameError(false) : setNameError(true)
                            //     setName(e.target.value)
                            // }}
                            value={name}
                            startAdornment={<InputAdornment position="start">{itemDetails.icon}</InputAdornment>}

                        />
                        {
                            nameError && <p className="text-danger mb-0">Please Enter Correct Name</p>
                        }

                    </FormControl>
                </div>

                <div className="form-group basic row">
                    <div className="col-12 pl-0">
                        <div className="input-group inputgroup mb-1">
                            <FormControl fullWidth sx={{ m: 1 }}>
                                {/* <InputLabel htmlFor="outlined-adornment-amount">Enter Amount</InputLabel> */}
                                <label className="label"> Amount</label>
                                <CurrencyFormat
                                    thousandSeparator={true} prefix={'$'}
                                    // type="number"
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        amount !== "" ? setAmountError(false) : setAmountError(true)
                                        setAmount(value)
                                    }}

                                    className="form-control"
                                    inputmode="numeric" pattern="[0-9]*" type="text" name="creditcard"
                                    // required="text"
                                    placeholder="$0"
                                />
                                {
                                    amountError && <p className="text-danger">Please Enter Amount</p>
                                }
                                {/* <Input
                                    required
                                    type="number"
                                    id="standard-adornment-amount"
                                    onChange={(e) => { setAmount(e.target.value) }}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    placeholder="0"
                                /> */}
                            </FormControl>
                            {/* <span className="input-group-text" id="basic-addonb1">$</span>
                    <input type="text" name="name" className="form-control" placeholder="0" /> */}
                        </div>
                    </div>
                    {/* <div className="col-6 pe-0">
                        <div className="form-group col inputgroup">
                            <FormControl fullWidth sx={{ m: 1 }}>
                                <label className="label"> Date</label>
                                <input required type="date" onChange={(e) => {
                                    date !== "" ? setDateError(false) : setDateError(true)
                                    setDate(e.target.value)
                                }} className="form-control width-date" placeholder="" />
                                {
                                    dateError && <p className="text-danger">Please Enter Date</p>
                                }
                            </FormControl>
                        </div>
                    </div> */}
                </div>
                <div className="inputgroup form-group pe-3 mb-1">
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <label className="label mb-2"> Description</label>
                        {/* <input type='text'  onChange={(e) => {
                                description !== "" ? setDescriptionError(false) : setDescriptionError(true)
                                setDescription(e.target.value)
                            }}></input> */}
                        <textarea class="form-control text_area_bg" id="exampleFormControlTextarea1" rows="6"
                            onChange={(e) => {
                                description !== "" ? setDescriptionError(false) : setDescriptionError(true)
                                setDescription(e.target.value)
                            }}
                        ></textarea>
                        {descriptionError && <p className="text-danger">Please Enter Description</p>}
                    </FormControl>
                </div>

                <div className="form-group basic" data-bs-dismiss="modal">
                    <button type="submit" className="btn btn-primary btn-block btn-lg" onClick={(e) => handleSubmit(e)} >Add {itemDetails.title}</button>
                </div>
            </div>
        </>
}
