import { useState } from "react";
import Header from "./header";
import { useQuery } from 'react-query'
import { useNavigate } from "react-router-dom";
import NavigationIcon from "@mui/icons-material/Navigation";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getSkills, getSubSkills } from '../_api/boost'
import Parser from 'html-react-parser'
import "./index.css";
import SkillAssignments from "../common/SkillAssignments";
import { CircularProgress } from '@mui/material';

function Boost(props) {
  const navigate = useNavigate();
  const [selected, setSelected] = useState({});
  const [showSkillmodal, setShowSkillModal] = useState(false);
  const [partName, setPartName] = useState();
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const { isLoading, data: skills } = useQuery("get-skills", getSkills, {
    enabled: Boolean(sessionStorage.getItem('token'))
  })
  const { isLoading: subLoading, data: subSkills } = useQuery("get-sub-skills", getSubSkills, {
    enabled: Boolean(sessionStorage.getItem('token'))
  })

  const handleClick = (data) => {
    setSelected(data);
  };

  const handleBGC = (value) => {
    if (selected.id == value.id) {
      return "#89b5f5";
    }
  };

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    let mybutton = document.getElementById("myBtn1");
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  };

  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  if (isLoading || subLoading) return <div className="container d-flex justify-content-center mt-5 pt-5">
    <CircularProgress />
  </div>
  return (
    <>
      <div className="container" >
        <div className="row mt-5 pt-5 px-3">
          <Grid container spacing={0}>
            <Grid item md={10} sm={10} gap={2} >
              <Button onClick={() => navigate(-1)} className="button" style={{ width: "150px", height: '50px', borderRadius: '15px', color: '#606EED' }} >
                <ArrowBackIosNewIcon/>Go back </Button>
            </Grid>
            <Grid item md={2} sm={2}>
              <Button sx={{
                textTransform: 'capitalize',
                fontWeight: 'bold',
                mt: 1,
                color: '#616DED',
                border: '1px solid #616DED',
                borderRadius: "10px",
              }} onClick={() => { setShowSkillModal(true); setPartName("boost") }}>Skill Assignment</Button>
            </Grid>
          </Grid>
          <div className="FC_filter">
            {
              !skills?.data?.length &&
              <p className="text-center w-100">No SKills Found!</p>
            }
            {skills?.data?.map((val) => (
              <a href={"#" + val.id}>
                <div
                  onClick={() => handleClick(val)}
                  key={val.id}
                  className="FC_filterBtn"
                  style={{ backgroundColor: handleBGC(val) }}
                >
                  <label className="lable">{val.name}</label>
                </div>
              </a>
            ))}
          </div>

          {skills?.data?.map((val) => (
            <div key={val.id} className="container row cardBox">
              <h1 style={{ marginTop: 20, marginBottom: 10 }} id={val.id}>
                {val.name}
              </h1>
              {
                !subSkills?.data?.filter(sub => sub?.moneySkillId === val.id)?.length &&
                <p>No Sub Skills Found!</p>
              }
              {subSkills?.data?.map((el) => (
                el?.moneySkillId === val?.id &&
                <div
                  onClick={() => {
                    navigate(`/boost/skill-sections?subSkillID=${el?.id}`)
                  }}
                  className="card"
                  style={{ width: "18rem", margin: 20, cursor: "pointer" }}
                >
                  <img
                    onLoad={() => setIsImageLoaded(true)}
                    src={(el.image && isImageLoaded) ? process.env.REACT_APP_S3_BUCKET_LINK + el?.image : "/assets/img/dummy.jpeg"} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <h5 className="card-title">{el.name}</h5>
                    <p className="card-text">{Parser(el.description)}</p>
                  </div>
                </div>
              ))}
            </div>
          ))}
          <div className="GOTOTOP">
            <button onClick={topFunction} id="myBtn1" title="Go to top">
              <NavigationIcon />
            </button>
          </div>
        </div>
      </div>
      <SkillAssignments open={showSkillmodal} setOpen={setShowSkillModal} partName={partName} userID={props?.user?.id} />

    </>
  );
}

export default Boost;
