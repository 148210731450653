import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import swal from "sweetalert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SkillAssignments from "../common/SkillAssignments";
let theme = createTheme();

theme.typography.btn1 = {
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Poppins",
  fontStyle: "normal",
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
  "@media (min-width:600px)": {
    fontSize: "15px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "15px",
    fontWeight: 600,
  },
};

theme.typography.h2 = {
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Poppins",
  fontStyle: "normal",
  display: "flex",
  padding: "10ppx",
  alignItems: "center",
  borderRadius: "15px",
  color: "#616DED",
  "@media (min-width:600px)": {
    fontSize: "15px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "15px",
    fontWeight: 600,
  },
};

function Header({ user }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false)
  return (
    <>
      <div style={{ width: "90%", margin: "100px auto 0px auto" }}>
        <div className="d-flex flex-wrap align-items-center justify-content-between border-0">
          <Grid item sm={12} md={6} xs={12}>
            <Button
              variant="text"
              startIcon={
                <img
                  width="150%"
                  height="150%"
                  src="assets/img/icon/arrow.svg"
                  className="logo"
                  alt=""
                />
              }
              onClick={() => navigate(-1)}
              sx={{
                "&:hover": {
                  backgroundColor: "#EAECFF",
                  borderColor: "#0062cc",
                  boxShadow: "none",
                  borderRadius: "10px",
                  // height: 50,
                },

                ml: 2,
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 15,
                px: 3,
                py: 1.5,

                color: "#616DED",
              }}
            >
              <ThemeProvider theme={theme}>
                <Typography variant="h2">Go Back</Typography>
              </ThemeProvider>
            </Button>
          </Grid>
          <div className="d-flex flex-wrap align-items-center jutify-content-between d-grid gap-3">
            <Grid item sm={12} md={6} xs={12}>
              <Button
                sx={{
                  "&:hover": {
                    backgroundColor: "#3D49C9",
                    borderColor: "#0062cc",
                    boxShadow: "none",
                  },
                  textTransform: "none",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "0.938rem",
                  px: 3,
                  py: 1.2,
                  borderRadius: "15px",
                  bgcolor: "#616DED",
                }}
                onClick={() => swal("This feature will be available soon!")}
              >
                <ThemeProvider theme={theme}>
                  <Typography variant="btn1">Retrieve Credit Report</Typography>
                </ThemeProvider>
              </Button>
            </Grid>
            <a href={`${process.env.REACT_APP_EXPERION_LINK}?token=${sessionStorage.getItem('token')}`}
              target="blank"
              style={{
                color: "#FFFFFF",
                textTransform: "none",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "0.938rem",
                padding: '8px 20px',
                borderRadius: "15px",
                backgroundColor: "#616ded",
                "&:hover": {
                  backgroundColor: "#3D49C9",
                  borderColor: "#0062cc",
                  boxShadow: "none",
                },
              }}
            > Experion</a>
            <Button
              sx={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "15px",
                lineHeight: "22px",
                textTransform: 'capitalize',
                border: '1px solid #616DED',
                borderRadius: "10px",
                borderRadius: "10px",
                background: "none",
                color: "#616DED",
                "&:hover": {
                  background: "#E3EEFA",
                },
              }}
              onClick={() => setOpen(true)}
            >
              Skill Assignment
            </Button>
          </div>
        </div>
        <SkillAssignments open={open} setOpen={setOpen} partName="credit-score" userID={user?.id} />
      </div>
    </>
  );
}

export default Header;
