const OthersIcon = ({ color }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.4028 8.13892H22.4223C22.8832 7.52642 23.1567 6.76543 23.1567 5.94165C23.1567 3.92236 21.5139 2.27954 19.4946 2.27954C18.2783 2.27954 17.3813 2.71533 16.6719 3.65098C16.0784 4.43379 15.6444 5.52676 15.1001 6.90771C14.5558 5.52671 14.1218 4.43379 13.5283 3.65098C12.8188 2.71533 11.9219 2.27954 10.7056 2.27954C8.68628 2.27954 7.04346 3.92236 7.04346 5.94165C7.04346 6.76543 7.31704 7.52642 7.77788 8.13892H4.79736C3.58579 8.13892 2.6001 9.12461 2.6001 10.3362V11.801C2.6001 12.7559 3.21245 13.57 4.06494 13.8723V25.0823C4.06494 26.2938 5.05063 27.2795 6.26221 27.2795H23.938C25.1496 27.2795 26.1353 26.2938 26.1353 25.0823V13.8723C26.9877 13.57 27.6001 12.7559 27.6001 11.801V10.3362C27.6001 9.12461 26.6144 8.13892 25.4028 8.13892ZM16.4445 7.49146C17.5666 4.64487 17.9931 3.74438 19.4946 3.74438C20.7062 3.74438 21.6919 4.73008 21.6919 5.94165C21.6919 7.15322 20.7062 8.13892 19.4946 8.13892H16.1883C16.2781 7.91357 16.3637 7.69663 16.4445 7.49146ZM10.7056 3.74438C12.2071 3.74438 12.6336 4.64487 13.7557 7.49146C13.8365 7.69663 13.9221 7.91357 14.0119 8.13892H10.7056C9.49399 8.13892 8.5083 7.15322 8.5083 5.94165C8.5083 4.73008 9.49399 3.74438 10.7056 3.74438ZM12.1704 25.8147H6.26221C5.85835 25.8147 5.52979 25.4861 5.52979 25.0823V13.9983H12.1704V25.8147ZM12.1704 12.5334H4.79736C4.39351 12.5334 4.06494 12.2049 4.06494 11.801V10.3362C4.06494 9.93232 4.39351 9.60376 4.79736 9.60376H12.1704V12.5334ZM16.5649 25.8147H13.6353V9.60376C13.7887 9.60376 15.7231 9.60376 16.5649 9.60376V25.8147ZM24.6704 25.0823C24.6704 25.4861 24.3418 25.8147 23.938 25.8147H18.0298V13.9983H24.6704V25.0823ZM26.1353 11.801C26.1353 12.2049 25.8067 12.5334 25.4028 12.5334H18.0298V9.60376H25.4028C25.8067 9.60376 26.1353 9.93232 26.1353 10.3362V11.801Z"
        fill={color}
      />
    </svg>
  );
};

export default OthersIcon;
