import React, { useState } from "react";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useFormik } from "formik";
import * as Yup from "yup";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CustomTextButton } from "../Button";
import Category from "./Category";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ValueChangeModal from "./ValueChangeModal";
import PdfViewer from "../PdfViewer";
import { CustomButton } from "../Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CustomBudgetModal } from "./CutomBudgetModal";
import { WarningModal } from "./WarningModal";
import { InitialContractModal } from "./InitialContractModal";
import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  Button,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  Box,
  Alert
} from "@mui/material";
// import { textAlign } from "@mui/system";
import { resetCalculator } from "../_api/index";
import TrackTime from "./track-time/index";
import toast from "react-hot-toast";
import "./track-time/track-time.css";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RightTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const fieldGroup = {
  display: "flex",
  flexDirection: "column",
  gap: 10,
};
const labelStyle = {
  fontSize: "16px",
  fontWeight: 500,
  color: "#000",
  marginLeft: 2,
  marginBottom: 14,
  height: 24,
};
const inputStyle = {
  padding: 10,
  border: "1px solid",
  borderRadius: "10px",
  minWidth: "185px",
};
const dollarSignStyle = {
  position: "absolute",
  left: "8px",
  top: "50%",
  transform: "translateY(-50%)",
};

export default function AddBudgetModel(props) {
  const [page, setPage] = useState("income");
  const [recurring, setRecurring] = useState(false);
  const [openIncomeModal, setOpenIncomeModal] = useState(false);
  const [openExpenseModal, setOpenExpenseModal] = useState(false);
  const [openBudgetContractModal, setOpenBudgetContractModal] = useState(false);
  const [openContractPdfModal, setOpenContractPdfModal] = useState(false);
  const [openContractFormModal, setOpenContractFormModal] = useState(false);
  const [openContractWarningModal, setOpenContractWarningModal] =
    useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [newTransition, setNewTransition] = useState(false);

  const handleReset = async () => {
    toast.loading("Reseting Calculator, Please Wait...");
    await resetCalculator();
    toast.dismiss();
    toast.success("Calculator Restored");
    window.location.reload();
  };

  const handleIncomeModal = () => {
    setOpenIncomeModal(true);
  };

  const handleCloseIncomeModal = () => {
    setOpenIncomeModal(false);
  };

  const handleBudgetContractModal = () => {
    setOpenBudgetContractModal(true);
  };

  const handleCloseBudgetContractModal = () => {
    setOpenBudgetContractModal(false);
  };

  const handleExpenseModal = () => {
    setOpenExpenseModal(true);
  };

  const handleCloseExpenseModal = () => {
    setOpenExpenseModal(false);
  };

  const handleContractPdfModal = () => {
    setOpenContractPdfModal(true);
    handleCloseBudgetContractModal();
  };

  const handleCloseContractPdfModal = () => {
    setOpenContractPdfModal(false);
  };

  const handleContractFormModal = () => {
    setOpenContractFormModal(true);
    setFormLoader(true);
    setOpenContractPdfModal(false);
  };

  const handleCloseContractFormModal = () => {
    setOpenContractWarningModal(false);
    setOpenContractPdfModal(true);
    setNewTransition(true);
    setOpenContractFormModal(false);
  };

  const handleContractWarningModal = () => {
    setOpenContractWarningModal(true);
  };

  const handleCloseContractWarningModal = () => {
    setOpenContractWarningModal(false);
  };

  const handleCloseTrackTimePage = () => {
    setPage("income");
  }

  const CustomInput = (props) => {
    const {
      name,
      error,
      label,
      type,
      helperText,
      onChange,
      value,
      onBlur,
      placeholder,
      fullWidth,
      showIcon,
      subLabel,
      others,
    } = props;
    return (
      <>
        <div style={fieldGroup}>
          <label style={labelStyle}>{label}</label>
          {subLabel && (
            <p
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                fontSize: "12px",
                margin: "0px",
              }}
            >
              {subLabel}
            </p>
          )}
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {showIcon && (
              <span style={dollarSignStyle}>
                <MonetizationOnIcon style={{ color: "#000" }} />
              </span>
            )}
            <input
              {...others}
              name={name}
              placeholder={placeholder}
              style={{
                ...inputStyle,
                paddingLeft: showIcon ? "35px" : "10px",
                borderColor: error ? "red" : "rgba(0, 0, 0, 0.23)",
                width: fullWidth ? "100%" : "fit-content",
              }}
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              type={type}
            />
          </div>
          <p
            style={{
              color: error ? "red" : "rgba(0, 0, 0, 0.6)",
              fontSize: "12px",
              margin: "0px",
            }}
          >
            {helperText}
          </p>
        </div>
      </>
    );
  };

  const formik = useFormik({
    initialValues: {
      hourlyRate: "",
      grossMonthlyIncome: "",
      stateOfTax: "",
      estNetMonthlyIncome: "",
      frequency: "",
    },
    validationSchema: Yup.object({
      hourlyRate: Yup.string().required(["This field is required"]),
      grossMonthlyIncome: Yup.string().required(["This field is required"]),
      stateOfTax: Yup.string().required(["This field is required"]),
      estNetMonthlyIncome: Yup.string()
        .trim()
        .required(["This field is required"])
        .min(5, "Note must be at least 5 characters long"),
      frequency: Yup.string().trim().required(["This field is required"]),
    }),
    onSubmit: async (values) => {
      console.log("form submitted");
    },
  });

  const contractModalContent = (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ py: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomInput
              name="hourlyRate"
              // helperText={formik.touched.hourlyRate && formik.errors.hourlyRate}
              // error={Boolean(
              //   formik.touched.hourlyRate && formik.errors.hourlyRate
              // )}
              // onChange={formik.handleChange}
              // value={formik.values.hourlyRate}
              // onBlur={formik.handleBlur}
              fullWidth
              label="HourlyRate"
              showIcon={true}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomInput
              name="grossMonthlyIncome"
              // helperText={
              //   formik.touched.grossMonthlyIncome &&
              //   formik.errors.grossMonthlyIncome
              // }
              // error={Boolean(
              //   formik.touched.grossMonthlyIncome &&
              //     formik.errors.grossMonthlyIncome
              // )}
              // onChange={formik.handleChange}
              // value={formik.values.grossMonthlyIncome}
              // onBlur={formik.handleBlur}
              fullWidth
              label="Gross Monthly Income"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={6}>
            <label style={labelStyle}>W2 Tax</label>
            <RadioGroup sx={{ ml: 1 }} name="column-radio-buttons-group">
              <FormControlLabel
                value="standard"
                control={<Radio />}
                label="Standard"
              />
              <FormControlLabel
                value="non-standard"
                control={<Radio />}
                label="Non-Standard"
              />
            </RadioGroup>
            <p style={{ color: "red", fontSize: "12px", margin: "0px" }}></p>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <TextField
                select={true}
                name="stateOfTax"
                // helperText={formik.touched.stateOfTax && formik.errors.stateOfTax}
                // error={Boolean(formik.touched.stateOfTax && formik.errors.stateOfTax)}
                // onChange={formik.handleChange}
                // value={formik.values.stateOfTax}
                // onBlur={formik.handleBlur}
                fullWidth
                label="State of Tax"
                SelectProps={{
                  sx: { borderRadius: "10px" },
                  native: true,
                }}
                InputLabelProps={{ shrink: true }}
              >
                <option value="m-1">Menu Item</option>
                <option value="m-2">Menu Item</option>
                <option value="m-3">Menu Item</option>
                <option value="m-4">Menu Item</option>
              </TextField>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={12}>
            <label style={labelStyle}>Tax Breakdown</label>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mt-2">
          <Box
            sx={{
              backgroundColor: "rgba(169, 169, 169, 0.5)",
              display: "flex",
              flexDirection: "row",
              padding: "15px 10px 0px 10px",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p style={{ color: "black", fontWeight: 500 }}>TAX TYPE</p>
            <p style={{ color: "black", fontWeight: 500 }}>MONTHLY AMOUNT $</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "15px 10px 0px 10px",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p style={{ color: "black" }}>Faderal Tax</p>
            <p style={{ color: "black" }}>$519.60</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              padding: "15px 10px 0px 10px",
            }}
          >
            <p style={{ color: "black" }}>State Tax</p>
            <p style={{ color: "black" }}>$216.5</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              padding: "15px 10px 0px 10px",
              borderBottom: "1px solid rgba(169, 169, 169, 0.5)",
            }}
          >
            <p style={{ color: "black" }}>Social Security Tax</p>
            <p style={{ color: "black" }}>$268.46</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              padding: "15px 10px 0px 10px",
              borderBottom: "1px solid rgba(169, 169, 169, 0.5)",
            }}
          >
            <p style={{ color: "black" }}>Medicare Tax</p>
            <p style={{ color: "black" }}>$62.78</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              padding: "15px 10px 0px 10px",
            }}
          >
            <Typography style={{ color: "#616DED" }}>Total Tax</Typography>
            <Typography style={{ color: "#616DED" }}>$1067.34</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }} className="mt-2">
          <CustomInput
            name="estNetMonthlyIncome"
            // helperText={(formik.touched.estNetMonthlyIncome && formik.errors.estNetMonthlyIncome) ? formik.errors.estNetMonthlyIncome : ''}
            // error={Boolean(formik.touched.estNetMonthlyIncome && formik.errors.estNetMonthlyIncome)}
            // onChange={formik.handleChange}
            // value={formik.values.estNetMonthlyIncome}
            // onBlur={formik.handleBlur}
            fullWidth
            label="Estimated Net Monthly Income"
            type="text"
          />
        </Grid>
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                required
                control={<Checkbox />}
                label="Recurring Income"
                checked={recurring}
                onChange={() => setRecurring(!recurring)}
              />
            </FormGroup>
            <p style={{ color: "red", fontSize: "12px", margin: "0px" }}></p>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <TextField
                select={true}
                name="frequency"
                // name="taxr"
                // helperText={formik.touched.frequency && formik.errors.frequency}
                // error={Boolean(formik.touched.frequency && formik.errors.frequency)}
                // onChange={formik.handleChange}
                // value={formik.values.frequency}
                // onBlur={formik.handleBlur}
                fullWidth
                label="Frequency"
                SelectProps={{
                  sx: { borderRadius: "10px" },
                  native: true,
                }}
                InputLabelProps={{ shrink: true }}
                disabled={!recurring}
              >
                <option value="">Select a frequency</option>
                <option value="m-1">Menu Item</option>
                <option value="m-2">Menu Item</option>
                <option value="m-3">Menu Item</option>
                <option value="m-4">Menu Item</option>
              </TextField>
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className="mt-2"
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <CustomButton
            onClick={(e) => {
              console.log("do something");
            }}
            style={{
              textColor: "white",
              padding: "15px 20px",
            }}
          >
            Add Contract
          </CustomButton>
        </Grid>
      </Box>
    </form>
  );

  const budgetModalContent = (
    <>
      <div className="box" onClick={handleIncomeModal}>
        <h1>Income</h1>
        <div className="income_vector i-vec-laptop">
          <img
            src="./assets//img/vector-down.png"
            alt=""
            className="vector-down"
          />
          <img src="./assets//img/Vector.png" alt="" />
        </div>
      </div>
      <div className="box1" onClick={handleExpenseModal}>
        <h1>Expenses</h1>
        <div className="expenses_vector i-vec-laptop">
          <img src="./assets//img/vector-up.png" alt="" className="vector-up" />
          <img src="./assets//img/Vector-expen.png" alt="" />
        </div>
      </div>
      <div className="box1 box2" onClick={handleBudgetContractModal}>
        <h1>Contract</h1>
        <div className="expenses_vector i-vec-laptop">
          <img src="./assets//img/vector-up.png" alt="" className="vector-up" />
          <img src="./assets//img/Vector-expen.png" alt="" />
        </div>
      </div>
    </>
  );

  const incomeContent = (
    <>
      {page === "trackTime" ? (
        <TrackTime page={page} setPage={setPage} />
      ) : (
        <div className="col-md-8 col-lg-6 col-12 mx-auto">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2 className="heading-in-exepen">Income</h2>
            <CustomButton
              onClick={(e) => {
                console.log("do something");
              }}
              icon={<AddIcon style={{ fontSize: "18px", marginRight: "8px",}} />}
              before={true}
              style={{
                textColor: "white",
                padding: "15px 20px",
              }}
            >
              Add Income
            </CustomButton>
          </div>
          <ul className="listview image-listview mb-2">
            <Category
              title="Monthly Income"
              modalId="changeIncome"
              money={null}
              icon={<CreditCardIcon />}
              bg="text-success"
              key="icomefieldKey"
              avg={true}
              add="+"
            />
            <ValueChangeModal
              id="changeIncome"
              title="Monthly Income"
              value={0}
              key={"inconmeKey"}
              category="MonthlyIncome"
              type="Add"
            />
            {props.data.map((item, index) => {
              return (
                item.type === "Add" && (
                  <>
                    <ValueChangeModal
                      id={item.modalId}
                      title={item.title}
                      value={item.value}
                      setValue={item.setValue}
                      category={item.category}
                      type={item.type}
                    />
                    <Category
                      money={null}
                      title={item.title}
                      modalId={item.modalId}
                      icon={item.icon}
                      bg={item.color}
                      avg={true}
                      add="+"
                      key={index}
                    />
                  </>
                )
              );
            })}
          </ul>
        </div>
      )}
      {page === "income" && (
        <div
          className="col-md-6 d-flex justify-content-start my-2"
        >
          {/* <CustomTextButton
            variant="standard"
            style={{marginLeft: "12px"}}
            onClick={() => setPage("trackTime")}
          >
            <span><AccessTimeRoundedIcon style={{ fontSize: "23px", marginRight: "7px" }} />Track Time</span>
          </CustomTextButton> */}
        </div>
      )}
    </>
  );

  const contractPdfContent = (
    <div className="col-md-6 col-lg-6 col-12 mx-auto">
      <Alert sx={{ my: 2 }} severity="warning">This feature is currently under development. Stay tuned for updates and improvements!</Alert>
      <h2 className="heading-in-exepen">Contract</h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <a href="#">File name.pdf</a>
        <Typography>32 Mb</Typography>
      </div>
      <Card>
        <CardContent>
          <div style={{ position: "relative" }}>
            <PdfViewer
              pdfUrl="assets/img/icon/academicResume.pdf"
              height={"50vh"}
            />
            <div
              style={{
                position: "absolute",
                top: "90%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomButton
                onClick={handleContractFormModal}
                after={true}
                icon={<ArrowForwardIosIcon style={{ fontSize: "18px", marginLeft: "8px" }} />}
                style={{
                  textColor: "white",
                  padding: "15px 20px",
                }}
              >
                Continue
              </CustomButton>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const expenseContent = (
    <div className="col-md-6 col-lg-6 col-12 mx-auto">
      <h2 className="heading-in-exepen">Expense</h2>
      <ul className="listview image-listview mb-2">
        <Category
          title="Bills & Utilities"
          modalId="changeBills"
          money={null}
          icon={<FileCopyIcon />}
          bg="text-primary-dark"
          key="billfieldKey"
          avg={true}
          add="+"
        />
        <ValueChangeModal
          id="changeBills"
          title="Bills & Utilities"
          value={0}
          editBill={true}
          key="billKey"
          category="BillsAndUtilities"
          type="Delete"
        />

        {props.data.map((item, index) => {
          return (
            item.type === "Delete" && (
              <>
                <ValueChangeModal
                  id={item.modalId}
                  title={item.title}
                  value={item.value}
                  setValue={item.setValue}
                  category={item.category}
                  type="Delete"
                />
                <Category
                  money={null}
                  title={item.title}
                  modalId={item.modalId}
                  icon={item.icon}
                  bg={item.color}
                  avg={true}
                  add="+"
                  key={index}
                />
              </>
            )
          );
        })}
      </ul>
    </div>
  );

  const contractFormContent = (
    <div className="col-md-8 col-lg-8 col-12 mx-auto">
      <Alert sx={{ my: 2 }} severity="warning">This feature is currently under development. Stay tuned for updates and improvements!</Alert>
      <h2 className="heading-in-exepen">Contract</h2>
      {contractModalContent}
    </div>
  );
  return (
    <>
      <CustomBudgetModal
        openModal={props.open}
        closeModal={props.closeModal}
        transition={Transition}
        content={budgetModalContent}
        budgetModal={true}
        handleReset={handleReset}
      />
      <CustomBudgetModal
        openModal={openIncomeModal}
        closeModal={page === "trackTime" ? handleContractWarningModal : handleCloseIncomeModal}
        transition={Transition}
        content={incomeContent}
      />
      <CustomBudgetModal
        openModal={openContractPdfModal}
        closeModal={handleCloseContractPdfModal}
        transition={newTransition ? RightTransition : Transition}
        content={contractPdfContent}
      />
      <CustomBudgetModal
        openModal={openExpenseModal}
        closeModal={handleCloseExpenseModal}
        transition={Transition}
        content={expenseContent}
      />
      <CustomBudgetModal
        openModal={openContractFormModal}
        closeModal={handleContractWarningModal}
        transition={RightTransition}
        content={contractFormContent}
        formLoader={formLoader}
        setFormLoader={setFormLoader}
      />
      <InitialContractModal
        open={openBudgetContractModal}
        transition={Transition}
        close={handleCloseBudgetContractModal}
        handleContractPdfModal={handleContractPdfModal}
      />
      <WarningModal
        open={openContractWarningModal}
        transition={Transition}
        close={handleCloseContractWarningModal}
        handleCloseSelectedModal={page === "trackTime" ? handleCloseTrackTimePage : handleCloseContractFormModal}
      />
    </>
  );
}
