
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { themeMain, themeBrookline, themeNorthwell, themeEverett } from './theme/index';


const ThemeProviderCustom = ({ children, user }) => {
    const organizationId = user?.organization?.id
    const [theme, setTheme] = useState(themeMain)
    useEffect(() => {
        if (user) {
            if (organizationId === 2 || organizationId === 3) { //Brookline
                setTheme(themeBrookline)
            } else if (organizationId === 4) {// Healthy Relationship
                setTheme(themeNorthwell)
            } else if (organizationId === 1) {//City of Everett
                setTheme(themeEverett)
            } else if (organizationId === 5) {// Northwell
                setTheme(themeNorthwell)
            }
        }
    }, [user])

    return (
        <ThemeProvider theme={theme} >
            {children}
        </ThemeProvider>
    )
}

export default ThemeProviderCustom;