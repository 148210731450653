import TakeoutDiningOutlinedIcon from '@mui/icons-material/TakeoutDiningOutlined';
function AllCredits() {
    return (<>
        <div className="appHeader">
            <div className="left">
                <a href="#" className="headerButton goBack" onClick={() => window.history.go(-1)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div className="pageTitle">
            All Credits
            </div>
        </div>
        <div className="text-center" style={{marginTop:'200px'}}>
            <TakeoutDiningOutlinedIcon style={{fontSize: '60px'}} />
            <p className="text-center px-5 mt-3">You have no recent events to Report. Check back at a later date.</p>
        </div>
    </>);
}

export default AllCredits;