import React, { useState, useEffect, useRef } from "react";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import SkillAssignments from '../common/SkillAssignments'
export default function Header(props) {
  const videoRef = useRef();
  const [open, setOpen] = useState(false)
  return (
    <>
      <button className="skillContainer" style={{
        border: "1px solid #616DED",
        color: '#616DED',
      }}>
        <div
          className="skillItem"
          onClick={() => setOpen(true)}
        >
          Skill Assignment
        </div>
      </button>
      <SkillAssignments userID={props?.user?.id} open={open} setOpen={setOpen} partName="financial-goals" />
      <div
        className="modal fade dialogbox dialogbox1"
        id="videoModal"
        data-bs-backdrop="static"
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "100%", padding: "0px 200px 0px 200px" }}
        >
          <div className="modal-content" style={{ maxWidth: "100%" }}>
            <div className="modal-header">
              <div className="btn-inline">
                <div
                  className="text-primary bg-white"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    videoRef.current.pause();
                  }}
                >
                  <ClearRoundedIcon />
                </div>
              </div>
            </div>
            <div className="modal-body pb-3">
              <video
                ref={videoRef}
                style={{ maxWidth: "100%", maxHeight: 300 }}
                className="rounded center fit"
                controls
                src="https://stickball.s3.amazonaws.com/stickball-unity.mp4"
              ></video>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
