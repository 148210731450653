import React, { useState, useEffect, useRef } from "react";
import { getUserPodcasts, getTranscriptionVoices } from "../_api/podcast";
import PodcastTable from "./PodcastTable";
import AllPodcasts from "./AllPodcasts";
import PodcastDetails from "./PodcastDetails";
import PodcastChat from "./PodcastChat";
import PodcastChatVoice from "./PodcastChatVoice";
import PodcastSubmission from "./PodcastSubmission";

export default function PodcastingTabs() {
  const [page, setPage] = useState("main");
  const [podcasts, setPodcasts] = useState([]);
  const [voices, setVoices] = useState([]);
  const [selectedPodcast, setSelectedPodcast] = useState({});
  const [messages, setMessages] = useState([]);
  const [selectedVoices, setSelectedVoices] = useState("");
  const [transcripted, setTranscripted] = useState({
    isConverted: false,
    conversionResponse: {},
  });

  const _getUserPodcasts = async () => {
    try {
      const _data = await getUserPodcasts();
      const __data = await getTranscriptionVoices();
      const _podcasts = _data;
      const _voices = __data.voices;
      setPodcasts(_podcasts);
      setVoices(_voices);
      setSelectedVoices([_voices[0].id, _voices[1].id]);
    } catch (error) {
      console.log(error);
    }
  };

  const getPage = () => {
    switch (page) {
      case "main":
        return (
          <AllPodcasts
            podcasts={podcasts}
            submittedPodcasts={podcasts.filter(
              (item) => item?.submission != "not submitted"
            )}
            setPage={setPage}
            refresh={refresh}
          />
        );
      case "all":
        return (
          <PodcastTable
            podcasts={podcasts}
            setPage={setPage}
            setSelectedPodcast={setSelectedPodcast}
          />
        );
      case "details":
        return <PodcastDetails podcast={selectedPodcast} setPage={setPage} />;
      case "chat":
        return (
          <PodcastChat
            podcast={selectedPodcast}
            setPage={setPage}
            messages={messages}
            setMessages={setMessages}
          />
        );
      case "voice":
        return (
          <PodcastChatVoice
            podcast={selectedPodcast}
            setPage={setPage}
            voices={voices}
            messages={messages}
            selectedVoices={selectedVoices}
            setSelectedVoices={setSelectedVoices}
          />
        );
      case "submission":
        return (
          <PodcastSubmission
            podcast={selectedPodcast}
            setPage={setPage}
            messages={messages}
            selectedVoices={selectedVoices}
            transcripted={transcripted}
            setTranscripted={setTranscripted}
            refresh={refresh}
          />
        );
      default:
        return (
          <>
            <div>Something went wrong!</div>
          </>
        );
    }
  };

  const refresh = () => {
    _getUserPodcasts();
  };

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    setMessages([]);
  }, [selectedPodcast]);

  return (
    <>
      <div style={{ textAlign: "none", padding: "0px 20px 20px 10px" }}>
        {getPage()}
      </div>
    </>
  );
}
