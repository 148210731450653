import { CircularProgress } from "@mui/material";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { NumericFormat } from "./list";
import { useTranslation } from "react-i18next";
const ListTable = (props) => {
  const { t } = useTranslation()
  const {
    data,
    count,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    ...other
  } = props;

  return (
    <div {...other} style={{ overflow: "auto" }}>
      <Table sx={{ minWidth: "100%" }}>
        <TableHead sx={{ visibility: "visible" }}>
          <TableRow>
            <TableCell>{t('month')}</TableCell>
            <TableCell>{t("startBalance")}</TableCell>
            <TableCell>{t("payment")}</TableCell>
            <TableCell>{t("principalPaid")}</TableCell>
            <TableCell>{t("simpleInterestPaid")}</TableCell>
            <TableCell>{t("interestPaid")}</TableCell>
            <TableCell>{t("endBalance")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((val, index) => {
            index = index + (page * rowsPerPage)
            return (
              <TableRow hover key={index}>
                <TableCell> {index + 1} </TableCell>
                <TableCell>
                  <NumericFormat
                    displayType="text"
                    value={val.startBalance}
                    thousandSeparator=","
                    decimalScale={2}
                    prefix="$"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    displayType="text"
                    value={val.payment}
                    thousandSeparator=","
                    decimalScale={2}
                    prefix="$"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    displayType="text"
                    value={val.principalPaid}
                    thousandSeparator=","
                    decimalScale={2}
                    prefix="$"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    displayType="text"
                    value={val.interestPaid}
                    thousandSeparator=","
                    decimalScale={2}
                    prefix="$"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    displayType="text"
                    value={val.totalInterestPaid}
                    thousandSeparator=","
                    decimalScale={2}
                    prefix="$"
                  />
                </TableCell>
                <TableCell>
                  <NumericFormat
                    displayType="text"
                    value={val.endBalance}
                    thousandSeparator=","
                    decimalScale={2}
                    prefix="$"
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        className="pagination-details"
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </div>
  );
};
export default ListTable;
