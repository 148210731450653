import React, { useRef, useState, useEffect } from "react";
import { Button, Grid, Box, Typography, Card, Switch } from "@mui/material";
import { Pause, PlayArrow } from "@mui/icons-material";

const cardStyle = {
  borderRadius: "12px",
  boxShadow: "none",
  border: "2px solid transparent",
  padding: "5px",
  // border: "2px solid transparent",
  cursor: "pointer",
};

const headStyle = {
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "15px",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "0px 10px",
  gap: "10px",
  background: "#F0F1FF",
  borderRadius: "5px",
  color: "#242424",
};

const generateBtnStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  padding: "8px 20px",
  color: "#33C052",
  border: "1.5px solid #33C052",
  borderRadius: "10px",
  textTransform: "none",
  "&:hover": {
    background: "rgba(51, 192, 82, 0.15)",
  },
};

const labelStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
};

const playButtonStyle = {
  padding: "20px",
  borderRadius: "15px",
  "&:hover": {
    background: "rgba(97, 109, 237, 0.2)",
  },
};

const VoiceButton = ({
  id,
  label,
  sample,
  country,
  selectedVoices,
  setSelectedVoices,
  voiceSelection,
}) => {
  const selectionColorSchemes = [
    {
      border: "2px solid rgba(97, 109, 237, 1)",
      background: "rgba(97, 109, 237, 0.4)",
    }, // FOR USER 1
    {
      border: "2px solid rgba(251, 120, 92, 1)",
      background: "rgba(251, 120, 92, 0.4)",
    }, // FOR USER 2
  ];
  const highlightColorSchemes = [
    {
      border: "2px solid rgba(97, 109, 237, 0.4)",
      background: "rgba(97, 109, 237, 0.1)",
    }, // FOR USER 1
    {
      border: "2px solid rgba(251, 120, 92, 0.4)",
      background: "rgba(251, 120, 92, 0.1)",
    }, // FOR USER 2
  ];

  const [selected, setSelected] = useState(selectedVoices.includes(id));
  const voiceIndex = selectedVoices.indexOf(id);

  const [playing, setPlaying] = useState(false);
  const audioRef = useRef();
  const [buttonStyle, setButtonStyle] = useState({
    ...cardStyle,
    background:
      selected && voiceIndex !== -1
        ? selectionColorSchemes[voiceIndex].background
        : "#fff",
    border:
      selected && voiceIndex !== -1
        ? selectionColorSchemes[voiceIndex].border
        : "2px solid transparent",
  });

  const _getFlag = () => {
    switch (country) {
      case "US":
        return "/assets/img/icon/usFlag.svg";
      case "UK":
        return "/assets/img/icon/ukFlag.svg";
      case "Canada":
        return "/assets/img/icon/canadaFlag.svg";
      default:
        return "";
    }
  };

  useEffect(() => {
    const _selected = selectedVoices.includes(id);
    const _selectedVoiceId = selectedVoices[voiceSelection];
    setSelected(_selected);

    if (id !== _selectedVoiceId) {
      audioRef.current.pause();
      setPlaying(false);
    }

    if (!_selected) {
      setButtonStyle({
        ...cardStyle,
        background: "#fff",
        border: "2px solid transparent",
      });
    }
  }, [selectedVoices, playing]);

  useEffect(() => {
    setPlaying(false);
    audioRef.current.pause();
  }, [voiceSelection]);

  return (
    <Card
      className="d-flex align-items-center justify-content-between"
      sx={buttonStyle}
      onMouseEnter={() => {
        if (!selected) {
          setButtonStyle({
            ...cardStyle,
            background: highlightColorSchemes[voiceSelection].background,
            border: highlightColorSchemes[voiceSelection].border,
          });
        }
      }}
      onMouseLeave={() => {
        if (!selected) {
          setButtonStyle({ ...cardStyle });
        }
      }}
      onClick={() => {
        if (!selectedVoices.includes(id)) {
          const temp = [...selectedVoices];
          temp[voiceSelection] = id;
          setSelected(true);
          setSelectedVoices(temp);
          setButtonStyle({
            ...cardStyle,
            background: selectionColorSchemes[voiceSelection].background,
            border: selectionColorSchemes[voiceSelection].border,
          });
        }
      }}
    >
      <audio ref={audioRef} src={sample} />
      <Box style={{ borderRadius: "8px", textAlign: "center" }}>
        <Button
          sx={playButtonStyle}
          onClick={() => {
            if (playing) {
              audioRef.current.pause();
              setPlaying(false);
            } else {
              setPlaying(true);
              audioRef.current.play();
            }
          }}
        >
          {playing ? <Pause /> : <PlayArrow />}
        </Button>
      </Box>
      <Box>
        <Typography sx={labelStyle}>{label}</Typography>
      </Box>
      <Box>
        <img style={{ borderRadius: "8px" }} src={_getFlag()} alt="Flag" />
      </Box>
    </Card>
  );
};

const PodcastChatVoice = (props) => {
  const { setPage, voices, selectedVoices, setSelectedVoices } = props;

  const [voiceSelection, setVoiceSelection] = useState(0);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Grid
        container
        className="d-flex justify-content-between align-items-center overflow-hidden mb-1"
      >
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className="d-flex align-items-center"
        >
          <Button
            className="mb-1"
            variant="text"
            sx={{ minWidth: "unset", width: "fit-content" }}
            startIcon={
              <img
                width="120%"
                height="150%"
                src="assets/img/icon/back.svg"
                className="logo"
                alt=""
              />
            }
            onClick={() => {
              setPage("chat");
            }}
          />
          <h1
            style={{
              fontfamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "20px",
              color: "#9B9FAC",
            }}
          >
            Podcast Assignments
          </h1>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ textAlign: "right" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              gap: 10,
            }}
          >
            <Typography
              style={{ color: voiceSelection == 0 ? "#616DED" : "#000" }}
            >
              Person 1
            </Typography>
            <Switch
              color="info"
              value={voiceSelection == 0 ? "on" : "off"}
              onChange={(e) => {
                if (e.target.value == "on") {
                  setVoiceSelection(1);
                } else {
                  setVoiceSelection(0);
                }
              }}
            />
            <Typography
              style={{ color: voiceSelection == 1 ? "#FB785C" : "#000" }}
            >
              Person 2
            </Typography>
            <Button
              className="header_btn"
              sx={generateBtnStyle}
              onClick={() => setPage("submission")}
              disabled={selectedVoices[0] == selectedVoices[1]}
            >
              <img
                src="/assets/img/icon/generateIcon.svg"
                style={{ marginRight: "13px" }}
                alt="Generating..."
              />{" "}
              Generate Podcast
            </Button>
          </div>
        </Grid>
      </Grid>
      <div style={headStyle}>Enter dialogue for both persons</div>
      <div
        style={{
          display: "flex",
          height: "55vh",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ overflowY: "scroll", margin: "2px 0px" }}
        >
          {voices.map((item) => (
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <VoiceButton
                id={item.id}
                label={item.label}
                sample={item.sample}
                country={item.country}
                selectedVoices={selectedVoices}
                setSelectedVoices={setSelectedVoices}
                voiceSelection={voiceSelection}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default PodcastChatVoice;
