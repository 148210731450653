import PanToolIcon from "@mui/icons-material/PanTool";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";

function Help() {
  return (
    <>
      <div className="appHeader">
        <div className="left">
          <Link to="/answer" className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </Link>
        </div>
        <div className="right"></div>
      </div>
      <div>
        <div style={{ width: "90%", margin: "0 auto" }}>
          <div className="row mt-5 p-5">
            <h1 className="text-white pt-5" style={{ fontWeight: 600 }}>
              Hi There{" "}
              {/* <span className="text-warning">
                <PanToolIcon />
              </span> */}
            </h1>
            <p className="text-white pb-5 pt-2" style={{ fontWeight: 400 }}>
              Let us knoe below how we can help you.
            </p>
          </div>
          <div className="wallet-card2">
            <h5 className="ml-2" style={{ fontWeight: 400, fontSize: "16px" }}>
              Search for help
            </h5>
            <OutlinedInput
              sx={{
                height: "40px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              id="outlined-adornment-search"
              varient="search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              aria-describedby=""
              style={{
                borderRadius: "30px",
                border: "2px solid #4e1dff",
                width: "100%",
              }}
              placeholder="Search articles"
            />
          </div>
        </div>
        <div
          className="bg-primary w-100"
          style={{
            height: "280px",
            position: "absolute",
            top: 0,
            zIndex: -1,
          }}
        ></div>
      </div>
    </>
  );
}

export default Help;
