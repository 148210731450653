import * as labFor from "react-number-format";
import { Divider, ListItem, ListItemText, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import * as mui from "@mui/material";
import { useTranslation } from "react-i18next";
// import * as TextField from '@mui/material/TextField';
import PieChart from "./PieChart";
import { useTheme } from "@mui/material/styles";

function ListL_LG({ left, right }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Divider />
      <ListItem
        secondaryAction={
          <span
            style={{
              color: "#ffc324",
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: isMobile ? "12px" : "25px",
              lineHeight: "28px",
            }}
            edge="end"
          >
            {right}
          </span>
        }
      >
        <ListItemText>
          <span
            style={{
              color: "#B6B6B6",
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: isMobile ? "12px" : "25px",
              lineHeight: "28px",
            }}
            edge="end"
          >
            {left}
          </span>
        </ListItemText>
      </ListItem>
    </>
  );
}

const NumericFormat = (props) => {
  const [text, setText] = useState(props.value);
  const theme = useTheme();
  useEffect(() => {
    if (typeof props.value !== "string") return;
    setText(props.value.replace("-", ""));
    setTimeout(() => {
      setText(props.value.replace("-", ""));
    }, 50);
  }, [props.value]);

  return (
    <>
      {props.label && (
        <mui.Typography variant="body1" color="text" sx={{ fontWeight: "500" }}>
          {props.label}
        </mui.Typography>
      )}
      <labFor.NumericFormat
        {...props}
        label={undefined}
        fullWidth
        size='small'
        sx={{
         
          marginBottom:'1.5rem',
          "& legend": { display: "none" },
          "& fieldset": { top: 0 },
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            backgroundColor: theme.palette.success.light,
            border: `1px solid ${theme.palette.success.main}`,
            color: theme.palette.common.text,
            "& input": {
              padding: "15px",
              color: theme.palette.common.text,
            },
            "&:hover fieldset": {
              borderColor: theme.palette.success.dark,
            },
          },
        }}
        variant="outlined"
        value={text}
        customInput={mui.TextField}
      />
    </>
  );
};

const TextField = (props) =>{ 
  const theme = useTheme();
  return(
  <>
    {props.label && (
      <mui.Typography variant="body1" color="text" sx={{ fontWeight: "500" }}>
        {props.label}
      </mui.Typography>
    )}
    <mui.TextField
      {...props}
      label={undefined}
      fullWidth
      
      sx={{
        marginBottom:'1.5rem',
        "& legend": { display: "none" },
        "& fieldset": { top: 0 },
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          backgroundColor: theme.palette.success.light,
          border: `1px solid ${theme.palette.success.main}`,
          color: theme.palette.common.text,
          "& input": {
            padding: "15px", 
            color: theme.palette.common.text,
          },
          "& select": {
            padding: "10px 15px", 
            color: theme.palette.common.text,
          },
          "&:hover fieldset": {
            borderColor: theme.palette.success.dark,
          },
          "&.Mui-focused fieldset": {
            borderColor: theme.palette.success.dark,
          },
        },
        "& .MuiSelect-icon": {
          color: theme.palette.success.dark,
        },
      }}
      variant="outlined"
    >
      {props.children}
    </mui.TextField>
  </>
)}

const renderFormatData = (value, item) => (
  <NumericFormat
    displayType="text"
    value={value}
    thousandSeparator=","
    decimalScale={2}
    prefix={
      item?.label?.includes("in years") ||
      item?.label?.includes("in months") ||
      item?.label?.includes("Total months") ||
      item?.label?.includes("Children") ||
      item?.label?.includes("rate years")
        ? ""
        : "$"
    }
  />
);
const RecalculateButton = ({ onClick, btnText, type, className }) => {
  return (
    
      <mui.Button
        type={type}
        variant="contained"
        className={className || "btn-recalculate"}
        onClick={onClick}
        sx={{width:'60%'}}
      >
        {btnText || "Recalculate"}
      </mui.Button>
    
  );
};


export {
  ListL_LG,
  renderFormatData,
  NumericFormat,
  RecalculateButton,
  TextField,
  PieChart,
};
