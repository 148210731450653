// import "../assets/css/style.css" ;
import ManageBudget from "../components/manage-budget";
import Add529Plan from "../components/add-529-plan";
import AddAccounts from "../components/add-accounts";
import SelectAccount from "../components/select-account";
import NetWorth from "../components/net-worth";
import StartingScreen from "../components/starting-screens/";
import BudgetHistory from "../components/budget-history";
import BillHistory from "../components/bill-history";
import EditBill from "../components/bill-history/EditBill";
import AddBill from "../components/bill-history/AddBill";
import SearchTransaction from "../components/bill-history/SearchTransaction";
import CategoriesAndRules from "../components/categories-and-rules";
import AddCategory from "../components/categories-and-rules/AddCategory";
import AddRule from "../components/categories-and-rules/AddRule";
import LinkedAccounts from "../components/linked-accounts/";
import ValueChange from "../components/net-worth/ValueChange";
import EditAccount from "../components/net-worth/EditAccount";
import Answer from "../components/faqs/answer";
import SpendingHistory from "../components/home";
import CreditScore from "../components/credit-score";
import GetCreditScore from "../components/credit-score/getCreditScore";
import MCQs from "../components/credit-score/mcqs";
import CreditScoreStarting from "../components/credit-score/creditScoreStarting";
import AllCredits from "../components/credit-score/allCredits";
import CardUsage from "../components/usage-details/cardUsage";
import Help from "../components/faqs/help";
import CreditActivated from "../components/credit-score/creditActivated";
import CarPage from "../components/car-page/";
import SideBar from "../components/common/sideBar";
import FinancialGoals from "../components/financial-goals";
import Error404 from "../components/404";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import FinanceCalculator from "../components/finance-calculator";
import Boost from "../components/boost";
// import Auto from "../components/finance-calculator/forms/auto";
// import CollegeAndEducation from "../components/finance-calculator/forms/college-and-education";
// import CreditAndDebt from "../components/finance-calculator/forms/credit-and-debt";
// import BudgetGoals from "../components/finance-calculator/forms/budget-and-goals";
// import SaveAndInvest from "../components/finance-calculator/forms/saving-and-investment";
// import CareerAndRetirement from "../components/finance-calculator/forms/career-and-retirement";
// import FamilyAndLife from "../components/finance-calculator/forms/family-and-life";
// import HomeAndMortgage from "../components/finance-calculator/forms/home-and-mortgage";
// import WorkAndTaxes from "../components/finance-calculator/forms/work-&-taxes";
// import Loans from "../components/finance-calculator/forms/loans";
import SkillSections from '../components/boost/skill-sections/'
import Dictionary from '../components/Dictionary';
import { Fab } from '@mui/material';
import ChatBot from '../components/chatBot/chat-bot';
import AssessmentsAndCounseling from "../components/assessment-and-councelling/assesmentsandcounselling";
import CareerSammary from "../components/assessment-and-councelling/career-sammary";
import DreamJobSearch from "../components/jobs-search/dream-jobs-search";
import ResumeAnalyzer from "../components/home/resumeAnalyzer/resumeAnalyzer";
import InterViewAnalyst from "../components/home/interviewSimulator/interviewAnalyst/interviewAnalyst";
import Onboarding from "../components/home/onboarding/onboarding";
import Shadowing from "../components/home/shadowing/shadowing";
import Taxes from "../components/taxes";


const calculatorRoutes = [
  {
    path: "/finance-calculator",
    component: FinanceCalculator,
  },
  // {
  //   path: "/finance-calculator-college-and-education",
  //   component: CollegeAndEducation,
  // },
  // {
  //   path: "/finance-calculator-credit-and-debt",
  //   component: CreditAndDebt,
  // },
  // {
  //   path: "/finance-calculator-buget-and-goals",
  //   component: BudgetGoals,
  // },
  // {
  //   path: "/finance-calculator-save=and-investment",
  //   component: SaveAndInvest,
  // },
  // {
  //   path: "/finance-calculator-career=and-retirement",
  //   component: CareerAndRetirement,
  // },
  // {
  //   path: "/finance-calculator-family-and-life",
  //   component: FamilyAndLife,
  // },
  // {
  //   path: "/finance-calculator-home-and-mortgage",
  //   component: HomeAndMortgage,
  // },
  // {
  //   path: "/finance-calculator-work-and-taxes",
  //   component: WorkAndTaxes,
  // },
  // {
  //   path: "/finance-calculator-loans",
  //   component: Loans,
  // }
]

const mainRoutes = [
  {
    path: "/welcome",
    component: StartingScreen,
  },
  {
    path: "/manage-budget",
    component: ManageBudget,
  },
  {
    path: "/add-plan/:id",
    component: Add529Plan,
  },
  {
    path: "/add-accounts",
    component: AddAccounts,
  },
  {
    path: "/select-account",
    component: SelectAccount,
  },
  {
    path: "/net-worth",
    component: NetWorth,
  },
  {
    path: "/dictionary",
    component: Dictionary,
  },
  {
    path: "/budget-history/:id",
    component: BudgetHistory,
  },
  {
    path: "/bill-history/:id",
    component: BillHistory,
  },
  {
    path: "/add-bill",
    component: AddBill,
  },
  {
    path: "/edit-bill",
    component: EditBill,
  },
  {
    path: "/search-transaction",
    component: SearchTransaction,
  },
  {
    path: "/categories-and-rules",
    component: CategoriesAndRules,
  },
  {
    path: "/add-category",
    component: AddCategory,
  },
  {
    path: "/add-rule",
    component: AddRule,
  },
  {
    path: "/linked-accounts",
    component: LinkedAccounts,
  },
  {
    path: "/value-change/:id",
    component: ValueChange,
  },
  {
    path: "/edit-account",
    component: EditAccount,
  },
  {
    path: "/answer/:question_id",
    component: Answer,
  },
  {
    path: "/",
    component: SpendingHistory,
  },
  {
    path: "/assessments-and-counseling",
    component: AssessmentsAndCounseling,
  },
  {
    path: "/dream-job-search",
    component: DreamJobSearch,
  },
  {
    path: "/career-sammary",
    component: CareerSammary,
  },
  {
    path: "/resume-analyzer",
    component: ResumeAnalyzer,
  },
  {
    path: "/interview-simulator",
    component: InterViewAnalyst,
  },
  {
    path: "/onboarding",
    component: Onboarding,
  },
  {
    path: "/shadowing",
    component: Shadowing,
  },
  {
    path: "/credit-score",
    component: CreditScore,
  },
  {
    path: "/finance-calculator",
    component: FinanceCalculator,
  },
  {
    path: "/credit-score-starting",
    component: CreditScoreStarting,
  },
  {
    path: "/get-credit-score",
    component: GetCreditScore,
  },
  {
    path: "/credit-mcqs",
    component: MCQs,
  },
  {
    path: "/credit-activated",
    component: CreditActivated,
  },
  {
    path: "/all-credits",
    component: AllCredits,
  },
  {
    path: "/card-usage",
    component: CardUsage,
  },
  {
    path: "/help",
    component: Help,
  },
  {
    path: "/car-page",
    component: CarPage,
  },
  {
    path: "/financial-goals",
    component: FinancialGoals,
  },
  // {
  //   path: "/finance-calculator-auto",
  //   component: Auto,
  // },
  // {
  //   path: "/finance-calculator-college-and-education",
  //   component: CollegeAndEducation,
  // },
  // {
  //   path: "/finance-calculator-credit-and-debt",
  //   component: CreditAndDebt,
  // },
  // {
  //   path: "/finance-calculator-buget-and-goals",
  //   component: BudgetGoals,
  // },
  // {
  //   path: "/finance-calculator-save=and-investment",
  //   component: SaveAndInvest,
  // },
  // {
  //   path: "/finance-calculator-career=and-retirement",
  //   component: CareerAndRetirement,
  // },
  // {
  //   path: "/finance-calculator-family-and-life",
  //   component: FamilyAndLife,
  // },
  // {
  //   path: "/finance-calculator-home-and-mortgage",
  //   component: HomeAndMortgage,
  // },
  // {
  //   path: "/finance-calculator-work-and-taxes",
  //   component: WorkAndTaxes,
  // },
  // {
  //   path: "/finance-calculator-loans",
  //   component: Loans,
  // },
  {
    path: "/boost",
    component: Boost,
  },
  {
    path: "/boost/skill-sections",
    component: SkillSections,
  },
  {
    path: "taxes",
    component: Taxes,
  }
]

export const FinanceCalculatorAccess = () => (
  <Routes>
    {
      calculatorRoutes.map((route) => {
        return <Route exact path={route.path} element={<route.component />} />
      })
    }
    <Route exact path="*" element={<Error404 />}></Route>
  </Routes>
);

function MobileView() {
  const [user, setUser] = useState();
  const [openChatBot, setOpenChatBot] = useState(false);

  const navigate = useNavigate()
  let url = new URL(window.location.href)
  useEffect(() => {
    if (url?.pathname.includes("/bill-history")) {
      navigate('/manage-budget', { replace: true })
      window.location.reload()
    } else if (url?.pathname.includes("/value-change")) {
      navigate('/net-worth', { replace: true })
      window.location.reload()
    } else if (url?.pathname.includes("/net-worth/")) {
      navigate('/', { replace: true })
      window.location.reload()
    }
  }, [url])
  let isIframe = sessionStorage.getItem('isIframe')
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>

        {
          mainRoutes.map((route) => {
            return <Route exact path={route.path} element={<route.component user={user} />} />
          })
        }
        <Route exact path="*" element={<Error404 />}></Route>
      </Routes>
      {/* {
          isIframe !== "true" &&
          <div style={{
            position: 'fixed',
            bottom: 30,
            right: 30,
          }}>
            <Fab aria-label="help" onClick={() => setOpenChatBot(true)}>
              <img width="100%" style={{ borderRadius: '50%' }} src="assets/img/chatbot-img.svg" alt="icon" />
            </Fab>
            {openChatBot &&
              <ChatBot open={openChatBot} setOpen={setOpenChatBot} />
            }
          </div>
        } */}

    </>
  );
}

export default MobileView;
