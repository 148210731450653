import React from "react";
import { Grid, Container, } from '@mui/material'
// import Header from "../header";
import "./style/auto.css";

export default function Layout({ children }) {
    return (
        <>
            {/* <Header /> */}
            <Container maxWidth="lg" sx={{ pt: 20 }}>
                <Grid container>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
