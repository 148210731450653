import React from 'react'

const PersonalLoanIcon = ({color}) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_71_5330)">
<path d="M25.2446 15.3037H21.906V5.37524C21.906 5.03547 21.6305 4.76001 21.2907 4.76001H5.4751C5.13532 4.76001 4.85986 5.03547 4.85986 5.37524V21.1909C4.85986 21.5306 5.13532 21.8061 5.4751 21.8061H10.1317V25.1448C10.1317 25.4845 10.4072 25.76 10.747 25.76H25.2446C25.5844 25.76 25.8599 25.4845 25.8599 25.1448V15.919C25.8599 15.5792 25.5844 15.3037 25.2446 15.3037ZM17.2931 5.99048H20.6755V7.39596H17.2931V5.99048ZM12.6802 5.99048H16.0626V7.39596H12.6802V5.99048ZM8.11105 15.3037C7.77128 15.3037 7.49581 15.5792 7.49581 15.919C7.49581 16.2587 7.77128 16.5342 8.11105 16.5342H10.1318V17.9397H8.11105C7.77128 17.9397 7.49581 18.2151 7.49581 18.5549C7.49581 18.8947 7.77128 19.1702 8.11105 19.1702H10.1318V20.5756H6.09033V5.99048H11.4497V8.0112C11.4497 8.35097 11.7252 8.62643 12.065 8.62643H20.6755V15.3038L8.11105 15.3037ZM13.3554 16.5342C13.1232 17.5186 12.3466 18.2953 11.3622 18.5274V16.5342H13.3554ZM11.3622 24.5295V22.5363C12.3466 22.7685 13.1232 23.5452 13.3554 24.5295H11.3622ZM24.6294 24.5295H22.6362C22.8683 23.5452 23.645 22.7685 24.6294 22.5363V24.5295ZM24.6294 21.2832C22.9651 21.5474 21.6472 22.8652 21.383 24.5295H14.6085C14.3444 22.8652 13.0265 21.5474 11.3622 21.2832V19.7806C13.0265 19.5164 14.3444 18.1986 14.6085 16.5342H21.383C21.6472 18.1986 22.9651 19.5164 24.6294 19.7806V21.2832H24.6294ZM24.6294 18.5274C23.645 18.2953 22.8683 17.5186 22.6362 16.5342H24.6294V18.5274Z" fill={color}/>
<path d="M18.6546 10.0315H8.11084C7.77107 10.0315 7.49561 10.307 7.49561 10.6467V13.2827C7.49561 13.6225 7.77107 13.8979 8.11084 13.8979H18.6546C18.9943 13.8979 19.2698 13.6225 19.2698 13.2827V10.6467C19.2698 10.307 18.9943 10.0315 18.6546 10.0315ZM18.0393 12.6674H8.72607V11.262H18.0393V12.6674Z" fill={color}/>
<path d="M17.9958 17.9397C16.5665 17.9397 15.4036 19.1026 15.4036 20.5319C15.4036 21.9612 16.5665 23.1241 17.9958 23.1241C19.4251 23.1241 20.588 21.9612 20.588 20.5319C20.588 19.1026 19.4251 17.9397 17.9958 17.9397ZM17.9958 21.8936C17.2449 21.8936 16.6341 21.2827 16.6341 20.5319C16.6341 19.7811 17.245 19.1702 17.9958 19.1702C18.7466 19.1702 19.3575 19.7811 19.3575 20.5319C19.3575 21.2827 18.7466 21.8936 17.9958 21.8936Z" fill={color}/>
</g>
<defs>
<clipPath id="clip0_71_5330">
<rect width="21" height="21" fill="white" transform="translate(4.85986 4.76001)"/>
</clipPath>
</defs>
</svg>

  )
}

export default PersonalLoanIcon