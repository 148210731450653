
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SecurityIcon from "@mui/icons-material/Security";
import RadialSeparators from "./separator";
import Header from "./header";
import Field from "./field";
import Field1 from "./field1";
import FAQs from "../faqs";
import DevelopmentModel from "./developmentModal";
import FeedBackModal from "./FeedBackModal";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useEffect, useState } from "react";
import {
  CardHeader,
  Grid,
  Card,
  CardContent,
  Box,
  Divider,
} from "@mui/material";
import ListDialog from "./dialog";

const data = [
  { argument: 1, value: 10 },
  { argument: 2, value: 20 },
  { argument: 3, value: 30 },
];
const fieldData = [
  {
    title: "Card Usage",
    sub: "High Impact",
    modalText: `Your credit usage, particularly as represented by your credit utilization ratio, is the next most important factor in your credit scores. Your credit utilization ratio is calculated by dividing the total revolving credit you are currently using by the total of all your revolving credit limits. This ratio looks at how much of your available credit you're utilizing and can give a snapshot of how reliant you are on non-cash funds. Using more than 30% of your available credit is a negative to creditors. Credit utilization accounts for 30% of your FICO® Score.`,
    rightText: "<1%",
    bg: "bg-success",
    icon: "A",
  },
  {
    title: "Payment History",
    sub: "High Impact",
    rightText: "Great",
    modalText:
      "Payment history is the most important ingredient in credit scoring, and even one missed payment can have a negative impact on your score. Lenders want to be sure that you will pay back your debt, and on time, when they are considering you for new credit. Payment history accounts for 35% of your FICO® Score.",
    bg: "bg-success",
    icon: "A",
  },
  {
    title: "Account Age",
    sub: "Medium Impact",
    modalText: `How long you've held credit accounts makes up 15% of your FICO® Score. This includes the age of your oldest credit account, the age of your newest credit account and the average age of all your accounts. Generally, the longer your credit history, the higher your credit scores.`,
    rightText: "16yrs, 9mon",
    bg: "bg-success",
    icon: "A",
  },
  {
    title: "Account Mix",
    sub: "Low Impact",
    modalText: `People with top credit scores often carry a diverse portfolio of credit accounts, which might include a car loan, credit card, student loan, mortgage or other credit products. Credit scoring models consider the types of accounts and how many of each you have as an indication of how well you manage a wide range of credit products. Credit mix accounts for 10% of your FICO® Score.`,
    rightText: "1 Open",
    bg: "bg-warning",
    icon: "C",
  },
  {
    title: "Hard Inquiries",
    sub: "Low Impact",
    rightText: "3",
    modalText: `The number of credit accounts you've recently opened, as well as the number of hard inquiries lenders make when you apply for credit, accounts for 10% of your FICO® Score. Too many accounts or inquiries can indicate increased risk, and as such can hurt your credit score.`,
    bg: "bg-warning",
    icon: "C",
  },
  {
    title: "Derogatory Marks",
    sub: "High Impact",
    rightText: "0",
    modalText: `Derogatory marks are negative, long-lasting indications on your credit reports that generally mean you didn’t pay back a loan as agreed. For example, a late payment or bankruptcy appears on your reports as a derogatory mark. These derogatory marks generally stay on your credit reports for up to 7 or 10 years (sometimes even longer) and damage your scores.
  \n
          If you have a lower score coupled with a derogatory mark, you may have a hard time getting approved for credit or may get less-than-ideal credit terms. But the good news is that the impact to your credit of all derogatory marks decreases over time.
          `,
    bg: "bg-success",
    icon: "A",
  },
];

const data1 = [
  {
    Month: "Jan",
    "Credit Score": 300,
  },
  {
    Month: "Feb",
    "Credit Score": 350,
  },
  {
    Month: "Mar",
    "Credit Score": 400,
  },
  {
    Month: "Apr",
    "Credit Score": 450,
  },
  {
    Month: "May",
    "Credit Score": 500,
  },
  {
    Month: "Jun",
    "Credit Score": 500,
  },
  {
    Month: "Jul",
    "Credit Score": 600,
  },
  {
    Month: "Aug",
    "Credit Score": 700,
  },
  {
    Month: "Sep",
    "Credit Score": 750,
  },
  {
    Month: "Oct",
    "Credit Score": 800,
  },
];

export const CustomTooltip = ({ active, payload, label, isHideDollarSign }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#ffffff",
          borderRadius: 2,
          borderWidth: 1,
          borderColor: "gray",
          borderStyle: "solid",
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <p
          style={{ marginBottom: 5, fontWeight: "bold", color: "#000000" }}
        >{`${label} `}</p>
        {payload.map((val) => {
          let prize = `${val?.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return (
            <p style={{ color: val.fill, marginBottom: 2 }}>{`${val.name} : ${isHideDollarSign ? "" : "$"
              }${prize}`}</p>
          );
        })}
        {!!payload[0].payload?.finance_goal?.length && (
          <p style={{ marginBottom: 5, fontWeight: "bold", color: "#000000" }}>
            Financial Goals
          </p>
        )}
        {payload[0].payload?.finance_goal?.map((val) => {
          return (
            <li style={{ fontSize: 10, color: val.fill }}>{`${val.name || val.description
              }`}</li>
          );
        })}
      </div>
    );
  }
  return <></>;
};

function CreditScore(props) {
  const [modalText, setModalText] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    setTimeout(() => {
      let tagVal = document.getElementsByClassName(
        "recharts-text recharts-cartesian-axis-tick-value"
      );
      tagVal.forEach((nodes) => {
        nodes.childNodes.forEach((el) => {
          let val = el.innerHTML;
          if (Number(val))
            el.innerHTML = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          if (val == 0)
            el.innerHTML = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        });
      });
    }, 100);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Header user={props?.user} />
      <Box
        sx={{
          width: "90%",
          margin: "50px auto 30px auto",
          fontFamily: "poppins",
        }}
      >
        <ListDialog
          modalText={modalText}
          modalTitle={modalTitle}
          open={open}
          setOpen={setOpen}
        />
        <Grid container spacing={5} className="d-flex align-items-center">
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card
              sx={{ width: "100%", minWidth: "350px", borderRadius: "15px" }}
            >
              <CardHeader className="headerStyle_creditScore"
                sx={{
                  background: "#939CF8",
                  // "& .css-nrdprl-MuiTypography-root": {
                  //   color: "rgba(255, 255, 255, 0.8)",
                  //   fontSize: "12px",
                  //   textAlign: "right",
                  //   fontFamily: "poppins",
                  //   fontWeight: 500,
                  // },
                }}
                subheader="NEXT UPDATE IN 19 DAYS"
              />
              <CardContent className="d-flex" sx={{ background: "#616DED" }}>
                <Box className="d-flex align-items-center d-grid gap-5">
                  <Box
                    sx={{
                      width: 120,
                      height: 120,
                    }}
                  >
                    <CircularProgressbarWithChildren
                      value={850}
                      text="630"
                      strokeWidth={7}
                      styles={buildStyles({
                        strokeLinecap: "butt",
                        pathColor: "#FFFFFF",
                        textColor: "rgba(255, 255, 255, 0.6)",
                        fontSize: "25px",
                      })}
                    >
                      <RadialSeparators
                        count={8}
                        style={{
                          background: "#616DED",
                          width: "10px",
                          height: '10%',
                        }}
                      />
                    </CircularProgressbarWithChildren>
                  </Box>
                  <Box>
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "18px",
                      }}
                    >
                      No Change
                    </span>
                    <p
                      style={{
                        color: "#A5ADFF",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "14px",
                      }}
                    >
                      13 Days Ago
                    </p>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box
              sx={{
                width: "100%",
                minWidth: "350px",
                height: "220px",
                boxSizing: "border-box",
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={data1}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Month" />
                  <YAxis />
                  <Tooltip
                    content={<CustomTooltip isHideDollarSign={true} />}
                  />
                  {/* <Legend /> */}
                  <Bar dataKey="Credit Score" fill="#616DED" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Grid>
        </Grid>
        <div
          className=" mt-5"
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "13px",
            color: "#958D9E",
          }}
        >
          <span>CREDIT CARD REPORT</span>
        </div>
        <Divider />
        <Box>
          <ul className="listview image-listview">
            {fieldData?.map((item, key) => {
              return (
                <Field1
                  key={key}
                  onClick={() => {
                    setModalText(item?.modalText);
                    setModalTitle(item?.title);
                    handleClickOpen();
                  }}
                  icon={item.icon}
                  bg={item.bg}
                  title={item.title}
                  sub={item.sub}
                  rightText={item.rightText}
                  keyId="key"
                />
              );
            })}
          </ul>
        </Box>
        <Box>
          <div
            className=" mt-2"
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "13px",
            }}
          >
            IDENTITY MONITORING
          </div>
          <Divider />
          <ul className="listview image-listview mb-2">
            <li className="border-bottom">
              <div className="item px-1">
                <div className="icon-box">
                  <SecurityIcon />
                </div>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div>
                    ID Theft Protection
                    <footer style={{ fontSize: "11px", fontWeight: 400 }}>
                      Currently Inactive
                    </footer>
                  </div>

                  <div className="form-check form-switch  ms-2">
                    <input
                      className="form-check-input dark-mode-switch"
                      type="checkbox"
                      id="darkmodeSwitch"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="darkmodeSwitch"
                    ></label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </Box>
        <FAQs />
      </Box>
    </>
  );
}

export default CreditScore;

// import {
//   ArgumentAxis,
//   ValueAxis,
//   Chart,
//   LineSeries,
// } from "@devexpress/dx-react-chart-material-ui";
// import {
//   CircularProgressbarWithChildren,
//   buildStyles,
// } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
// import SecurityIcon from "@mui/icons-material/Security";
// import RadialSeparators from "./separator";
// import Header from "./header";
// import Field from "./field";
// import Field1 from "./field1";
// import FAQs from "../faqs";
// import DevelopmentModel from "./developmentModal";
// import FeedBackModal from "./FeedBackModal";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import { useEffect, useState } from "react";

// const data = [
//   { argument: 1, value: 10 },
//   { argument: 2, value: 20 },
//   { argument: 3, value: 30 },
// ];
// const fieldData = [
//   {
//     title: "Card Usage",
//     sub: "High Impact",
//     modalText: `Your credit usage, particularly as represented by your credit utilization ratio, is the next most important factor in your credit scores. Your credit utilization ratio is calculated by dividing the total revolving credit you are currently using by the total of all your revolving credit limits. This ratio looks at how much of your available credit you're utilizing and can give a snapshot of how reliant you are on non-cash funds. Using more than 30% of your available credit is a negative to creditors. Credit utilization accounts for 30% of your FICO® Score.`,
//     rightText: "<1%",
//     bg: "bg-success",
//     icon: "A",
//   },
//   {
//     title: "Payment History",
//     sub: "High Impact",
//     rightText: "Great",
//     modalText:
//       "Payment history is the most important ingredient in credit scoring, and even one missed payment can have a negative impact on your score. Lenders want to be sure that you will pay back your debt, and on time, when they are considering you for new credit. Payment history accounts for 35% of your FICO® Score.",
//     bg: "bg-success",
//     icon: "A",
//   },
//   {
//     title: "Account Age",
//     sub: "Medium Impact",
//     modalText: `How long you've held credit accounts makes up 15% of your FICO® Score. This includes the age of your oldest credit account, the age of your newest credit account and the average age of all your accounts. Generally, the longer your credit history, the higher your credit scores.`,
//     rightText: "16yrs, 9mon",
//     bg: "bg-success",
//     icon: "A",
//   },
//   {
//     title: "Account Mix",
//     sub: "Low Impact",
//     modalText: `People with top credit scores often carry a diverse portfolio of credit accounts, which might include a car loan, credit card, student loan, mortgage or other credit products. Credit scoring models consider the types of accounts and how many of each you have as an indication of how well you manage a wide range of credit products. Credit mix accounts for 10% of your FICO® Score.`,
//     rightText: "1 Open",
//     bg: "bg-warning",
//     icon: "C",
//   },
//   {
//     title: "Hard Inquiries",
//     sub: "Low Impact",
//     rightText: "3",
//     modalText: `The number of credit accounts you've recently opened, as well as the number of hard inquiries lenders make when you apply for credit, accounts for 10% of your FICO® Score. Too many accounts or inquiries can indicate increased risk, and as such can hurt your credit score.`,
//     bg: "bg-warning",
//     icon: "C",
//   },
//   {
//     title: "Derogatory Marks",
//     sub: "High Impact",
//     rightText: "0",
//     modalText: `Derogatory marks are negative, long-lasting indications on your credit reports that generally mean you didn’t pay back a loan as agreed. For example, a late payment or bankruptcy appears on your reports as a derogatory mark. These derogatory marks generally stay on your credit reports for up to 7 or 10 years (sometimes even longer) and damage your scores.
// \n
//         If you have a lower score coupled with a derogatory mark, you may have a hard time getting approved for credit or may get less-than-ideal credit terms. But the good news is that the impact to your credit of all derogatory marks decreases over time.
//         `,
//     bg: "bg-success",
//     icon: "A",
//   },
// ];

// const data1 = [
//   {
//     Month: "January",
//     "Credit Score": 0,
//   },
//   {
//     Month: "February",
//     "Credit Score": 500,
//   },
//   {
//     Month: "March",
//     "Credit Score": 550,
//   },
//   {
//     Month: "April",
//     "Credit Score": 600,
//   },
//   {
//     Month: "May",
//     "Credit Score": 650,
//   },
//   {
//     Month: "June",
//     "Credit Score": 700,
//   },
//   {
//     Month: "July",
//     "Credit Score": 750,
//   },
//   {
//     Month: "August",
//     "Credit Score": 800,
//   },
//   {
//     Month: "September",
//     "Credit Score": 850,
//   },
// ];

// export const CustomTooltip = ({ active, payload, label, isHideDollarSign }) => {
//   if (active && payload && payload.length) {
//     return (
//       <div
//         style={{
//           backgroundColor: "#ffffff",
//           borderRadius: 2,
//           borderWidth: 1,
//           borderColor: "gray",
//           borderStyle: "solid",
//           paddingLeft: 5,
//           paddingRight: 5,
//         }}
//       >
//         <p
//           style={{ marginBottom: 5, fontWeight: "bold", color: "#000000" }}
//         >{`${label} `}</p>
//         {payload.map((val) => {
//           let prize = `${val?.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//           return (
//             <p style={{ color: val.stroke, marginBottom: 2 }}>{`${val.name} : ${
//               isHideDollarSign ? "" : "$"
//             }${prize}`}</p>
//           );
//         })}
//         {!!payload[0].payload?.finance_goal?.length && (
//           <p style={{ marginBottom: 5, fontWeight: "bold", color: "#000000" }}>
//             Financial Goals
//           </p>
//         )}
//         {payload[0].payload?.finance_goal?.map((val) => {
//           return (
//             <li style={{ fontSize: 10, color: val.stroke }}>{`${
//               val.name || val.description
//             }`}</li>
//           );
//         })}
//       </div>
//     );
//   }
//   return <></>;
// };

// function CreditScore(props) {
//   const [modalText, setModalText] = useState("");
//   const [modalTitle, setModalTitle] = useState("");

//   useEffect(() => {
//     handleData();
//   }, []);

//   const handleData = async () => {
//     setTimeout(() => {
//       let tagVal = document.getElementsByClassName(
//         "recharts-text recharts-cartesian-axis-tick-value"
//       );
//       tagVal.forEach((nodes) => {
//         nodes.childNodes.forEach((el) => {
//           let val = el.innerHTML;
//           if (Number(val))
//             el.innerHTML = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//           if (val == 0)
//             el.innerHTML = val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//         });
//       });
//     }, 100);
//   };

//   return (
//     <>
//       <Header user={props.user} />
//       <DevelopmentModel modalText={modalText} modalTitle={modalTitle} />
//       <FeedBackModal
//         id="veryHappy"
//         text="Thank You, Your appreciation means a lot to us"
//       />
//       <FeedBackModal
//         id="happy"
//         text="Thank You, We are gonna improve it in near future."
//       />
//       <FeedBackModal
//         id="sad"
//         text="No need to worry. We're working on the improvements"
//       />
//       <FeedBackModal
//         id="angry"
//         text="Sorry, for the bad experience. Check it out again, tomorrow"
//       />
//       <div
//         className="section wallet-card-section mt-5 pt-5"
//         style={{ marginTop: "50px" }}
//       >
//         <div className="wallet-card pt-5">
//           <div
//             style={{ border: "none", padding: "0px" }}
//             className="card-header text-end"
//           >
//             <p style={{ fontSize: "12px" }} className="p-0 m-0">
//               Next update in 19 days
//             </p>
//           </div>
//           <div className="d-flex align-center justify-content-center">
//             <div style={{ width: "100px", height: "100px" }}>
//               <CircularProgressbarWithChildren
//                 value={850}
//                 // text={`${parseInt(700 + Math.random() * 50)}`}
//                 text="630"
//                 strokeWidth={7}
//                 styles={buildStyles({
//                   strokeLinecap: "butt",
//                   pathColor: "#616ded",
//                   textColor: "#616ded",
//                 })}
//               >
//                 <RadialSeparators
//                   count={5}
//                   style={{
//                     background: "#fff",
//                     width: "2px",
//                     height: `${7}%`,
//                   }}
//                 />
//               </CircularProgressbarWithChildren>
//             </div>
//             <div className="ml-4">
//               <span className="text-primary">
//                 <b>-</b>
//               </span>
//               <span className="text-dark">No Change</span>
//               <p style={{ fontSize: "12px" }}>13 Days Ago</p>
//             </div>
//           </div>
//           <div className="mt-2">
//             <div
//               style={{
//                 width: "100%",
//                 backgroundColor: "#ffffff",
//                 borderRadius: 15,
//               }}
//             >
//               <div style={{ height: 200, width: "100%" }}>
//                 <ResponsiveContainer width="100%" height="100%">
//                   <LineChart
//                     width={500}
//                     height={300}
//                     data={data1}
//                     margin={{
//                       top: 5,
//                       right: 30,
//                       left: 20,
//                       bottom: 5,
//                     }}
//                   >
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis dataKey="Month" />
//                     <YAxis />
//                     <Tooltip
//                       content={<CustomTooltip isHideDollarSign={true} />}
//                     />
//                     {/* <Legend /> */}
//                     <Line
//                       type="monotone"
//                       dataKey="Credit Score"
//                       stroke="#616DED"
//                       activeDot={{ r: 8 }}
//                     />
//                   </LineChart>
//                 </ResponsiveContainer>
//               </div>
//             </div>
//           </div>
//           {/* <div id="appCapsule" className="bg-white m-0 p-0">
//                     <ul className="listview image-listview mt-3">
//                         <Field />
//                     </ul>
//                 </div> */}
//         </div>
//       </div>
//       <div id="appCapsule" className="bg-white m-0 px-2 py-0 mt-4">
//         <div className="listview-title mt-2 d-flex justify-content-between">
//           <span>CREDIT REPORT CARD</span>
//           <span>as of today</span>
//         </div>
//         <ul className="listview image-listview">
//           {fieldData.map((item, key) => {
//             return (
//               <Field1
//                 key={key}
//                 onClick={() => {
//                   setModalText(item.modalText);
//                   setModalTitle(item.title);
//                 }}
//                 icon={item.icon}
//                 bg={item.bg}
//                 title={item.title}
//                 sub={item.sub}
//                 rightText={item.rightText}
//                 keyId="key"
//               />
//             );
//           })}
//         </ul>
//         <div className="listview-title mt-2">IDENTITY MONITORING</div>
//         <ul className="listview image-listview mb-2">
//           <li>
//             <div className="item px-1">
//               <div className="icon-box">
//                 <SecurityIcon />
//               </div>
//               <div className="in">
//                 <div>
//                   ID Theft Protection
//                   <footer>Currently Inactive</footer>
//                 </div>

//                 <div className="form-check form-switch  ms-2">
//                   <input
//                     className="form-check-input dark-mode-switch"
//                     type="checkbox"
//                     id="darkmodeSwitch"
//                   />
//                   <label
//                     className="form-check-label"
//                     htmlFor="darkmodeSwitch"
//                   ></label>
//                 </div>
//               </div>
//             </div>
//           </li>
//         </ul>
//         {/* <div className="section full">
//                 <div className="listview-title mt-2">RECOMMENDATIONS</div>
//                 <div className="carousel-single splide">
//                     <div className="splide__track">
//                         <ul className="splide__list">
//                             <li className="splide__slide">
//                                 <div className="bg-white card-block1">
//                                     <div className="card">
//                                         <div className="p-3 text-dark">
//                                             <div className="text-success">
//                                                 <img src="assets/img/go-green.png" style={{ width: '80px', height: '100px' }} alt="" />
//                                             </div>
//                                             <h3>Go green and save money</h3>
//                                             <p>Switching to clean energy in Massachasetts is easier than ever</p>
//                                             <button className=" btn btn-lg btn-outline-primary btn-block">Get Started</button>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </li>
//                         </ul>
//                     </div>
//                 </div>
//             </div> */}
//         <FAQs />
//         <div className="row px-4 pb-3 text-center">
//           <p className="text-center">HOW ARE YOU LINKING CREDIT SCORES</p>
//           <div
//             data-bs-toggle="modal"
//             data-bs-target="#veryHappy"
//             className="col imogi"
//           >
//             😍
//           </div>
//           <div
//             data-bs-toggle="modal"
//             data-bs-target="#happy"
//             className="col imogi"
//           >
//             😀
//           </div>
//           <div
//             data-bs-toggle="modal"
//             data-bs-target="#sad"
//             className="col imogi"
//           >
//             😔
//           </div>
//           <div
//             data-bs-toggle="modal"
//             data-bs-target="#angry"
//             className="col imogi"
//           >
//             🥺
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default CreditScore;
