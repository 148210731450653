import * as Axios from 'axios';

const API_URL = process.env.REACT_APP_API_LINK;
const axios = Axios.create({
    'headers': { 'x-api-key': process.env.REACT_APP_X_API_KEY }
})
export const GetInterestBasedjobs = async (filteredData) => {
    // ${API_URL.replace("client", '')}
    const endpoint = `${API_URL}prompt/get-suggested-profession`;
    try {
        const response = await axios.post(endpoint, filteredData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`,
            },
        });
        console.log("🚀 ~ GetInterestBasedjobs ~ result:", response.data)
        const result = response.data;
        return result
    } catch (err) {
        console.log("🚀 ~ GetInterestBasedjobs ~ err:", err)
    }
};