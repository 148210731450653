import React, { useState } from "react";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "./tabs/cardRapper";
import CalTab from "./tabs";
import {
  ListL_LG,
  renderFormatData,
  NumericFormat,
  RecalculateButton,
  TextField,
  PieChart,
} from "./tabs/list";

import Footer from "../footer";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import { Box, Button, Card, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function CCPayoff() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [debtAmount, setDebtAmount] = useState();
  const [interestRate, setInterestRate] = useState();
  const [months, setMonths] = useState();
  const [monthAmount, setMonthAmount] = useState();
  const theme = useTheme();

  const handleSubmit = (e) => {
    e.preventDefault();
    var creditCardDebt = Number(debtAmount?.replace(/[^0-9.-]+/g, ""));
    var creditCardInterestRate = Number(
      interestRate?.replace(/[^0-9.-]+/g, "")
    );
    var monthlyPayment = Number(monthAmount?.replace(/[^0-9.-]+/g, "")) || 0;
    var monthsToPayoff = Number(months?.replace(/[^0-9.-]+/g, "")) || 0;

    var resultDesc;
    var monthlyInterestRate = creditCardInterestRate / 1200;
    var totalPrinciple = creditCardDebt;
    var totalPayments;
    var totalInterest;
    let temp = [];
    if (monthlyPayment > 0) {
      // Calculate the number of months to payoff
      monthsToPayoff =
        -Math.log(1 - (creditCardDebt * monthlyInterestRate) / monthlyPayment) /
        Math.log(1 + monthlyInterestRate);
      totalPayments = monthlyPayment * monthsToPayoff;
      totalInterest = totalPayments - creditCardDebt;
      temp = [
        {
          color: "#5C34D5",
          data: Math.ceil(monthsToPayoff).toString(),
          label: t("totalMonth"),
        },
        {
          color: "#5C34D5",
          data: monthlyPayment,
          label: t("payingPerMonth"),
        },
        {
          color: "#4898FF",
          data: totalPrinciple,
          label: t("totalPrincipalPaid"),
        },
        {
          color: "#51F074",
          data: totalInterest,
          label: t("totalInterestPaid"),
        },
      ];
    } else {
      // Calculate the monthly payment to payoff in specified months
      monthlyPayment =
        (creditCardDebt * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -monthsToPayoff));
      totalPayments = monthlyPayment * monthsToPayoff;
      totalInterest = totalPayments - creditCardDebt;
      temp = [
        {
          color: "#5C34D5",
          data: Math.ceil(monthsToPayoff).toString(),
          label: t("totalMonth"),
        },
        {
          color: "#5C34D5",
          data: monthlyPayment,
          label: t("payingPerMonth"),
        },
        // {
        //   color: "#4898FF",
        //   data: totalPrinciple,
        //   label: t("totalPrincipalPaid"),
        // },
        {
          color: "#51F074",
          data: totalInterest,
          label: t("totalInterestPaid"),
        },
      ];
    } // if

    setShowResults(true);
    setChartData(temp);
  };

  const renderResultTabs = () => {
    return (
      <Box>
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 1,
          }}
        >
          <PieChart data={chartData} />
        </Card>

        <Box>
          {chartData?.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: theme.palette.common.background,
                p: 1,
                mt: 1,
                borderRadius: 1,
              }}
            >
              <Typography>{item?.label}</Typography>
              <Typography>
                {i == 0 ? item.data : renderFormatData(item.data, item)}
              </Typography>
            </Box>
          ))}
          <Box mt={2} textAlign="center">
            <RecalculateButton
              onClick={() => {
                setShowResults(false);
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  // <CalTab setTabValue={setTabValue} tabValue={tabValue}>
  //   {tabValue === "Results" && (
  //     <Grid item xs={12} md={12} sx={{ mt: 3 }}>
  //       <List>
  //         {chartData?.map((item, i) => (
  //           <ListL_LG
  //             key={i}
  //             left={item?.label}
  //             right={i == 0 ? item.data : renderFormatData(item.data, item)}
  //           />
  //         ))}
  //         <Divider />
  //       </List>
  //     </Grid>
  //   )}
  //   {tabValue === "Graph" && <PieChart data={chartData} />}
  //   <RecalculateButton
  //     onClick={() => {
  //       setShowResults(false);
  //     }}
  //   />
  // </CalTab>

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Total amount of debt on your card"
        required
        variant="standard"
        value={debtAmount}
        onChange={(e) => {
          setDebtAmount(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Interest you pay on this card"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      {!months && (
        <NumericFormat
          className="card-input"
          label="Monthly payment amount"
          required
          variant="standard"
          value={monthAmount}
          onChange={(e) => {
            setMonthAmount(e.target.value);
          }}
          prefix="$"
          thousandSeparator=","
          customInput={TextField}
        />
      )}
      <div>{!monthAmount && !months && <label className="mt-5">OR</label>}</div>
      {!monthAmount && (
        <NumericFormat
          className="card-input"
          label="Desired number of months to pay off your card"
          required
          variant="standard"
          value={months}
          onChange={(e) => {
            setMonths(e.target.value);
          }}
          customInput={TextField}
        />
      )}
      <Box textAlign="center" mt={1}>
        <Button
          variant="contained"
          sx={{ width: "70%" }}
          type="submit"
          className="btn btn-calculate"
        >
         Calculate
        </Button>
      </Box>
    </form>
  );
  return showResults ? renderResultTabs() : renderForm();
}
