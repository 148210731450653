import { lancasterStemmer } from 'lancaster-stemmer';
import stemmer from 'stemmer';

const defaultMaxWords = 250;
const defaultStopwords = []
// ["a", "a's", "ain't", "all", "am", "an", "and", "any", "are", "aren't", "as", "ask", "at", "b", "be", "but", "by", "c", "c'mon", "c's", "can", "can't", "cant", "co", "com", "d", "did", "didn't", "do", "e", "eg", "et", "etc", "ex", "f", "far", "for", "g", "h", "he", "he", "i", "i'd", "i'll", "i'm", "i've", "ie", "if", "in", "inc", "is", "isn't", "it", "it'd", "it'll", "it's", "its", "j", "k", "let's", "ltd", "m", "may", "me", "my", "n", "nd", "no", "not", "of", "off", "oh", "ok", "on", "onto", "or", "p", "q", "qv", "r", "rd", "re", "so", "t", "t's", "th", "the", "then", "thru", "thus", "to", "truly", "u", "un", "up", "us", "use", "uucp", "v", "via", "viz", "vs", "w", "want", "wants", "was", "wasn't", "way", "we", "we'd", "we'll", "we're", "we've", "welcome", "well", "went", "were", "weren't", "what", "what's", "who", "who's", "x", "y", "yes", "yet", "you", "you'd", "you're", "you've", "z",];

export function tokenizeWords(content, settings = {}) {
    const { allowNumbers, maxWords, stemmer, stopwordsInput } = settings;

    const stopwords = stopwordsInput
        .split(',')
        .map((stopword) => stopword.toLowerCase().trim());
    const stopwordsSet = new Set(
        [...defaultStopwords, ...stopwords].map((stopword) =>
            stopword.toLowerCase(),
        ),
    );
    const matched = content.match(/\w+/g);
    if (!matched) {
        return [];
    }
    const tokens = matched
        .map((token) => token.toLowerCase())
        .filter((token) => {
            const filterByStopwords = !stopwordsSet.has(token);
            return allowNumbers
                ? filterByStopwords
                : filterByStopwords && Number.isNaN(Number(token));
        })
        .map((token) => {
            switch (stemmer) {
                case 'porter':
                    return stemmer(token);
                case 'lancaster':
                    return lancasterStemmer(token);
                default:
                    return token;
            }
        });
    const frequency = {};
    tokens.forEach((token) => {
        if (frequency[token]) {
            frequency[token].value += 1;
        } else {
            frequency[token] = {
                text: token,
                value: 1,
            };
        }
    });

    return Object.values(frequency)
        .sort((a, b) => b.value - a.value)
        .slice(0, maxWords || defaultMaxWords);
}