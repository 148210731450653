import React, { useState } from 'react'
import HomeIcon from "@mui/icons-material/Home";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import BudgetIcon from "../../components/svgIcons/BudgetIcon";
import CalculatorIcon from "../../components/svgIcons/CalculatorIcon";
import NetWorthIcon from "../../components/svgIcons/NetWorthIcon";
import ShopIcon from "../../components/svgIcons/ShopIcon";
import DictionaryIcon from "../../components/svgIcons/DictionaryIcon";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import { useLocation, Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import PaymentsIcon from '@mui/icons-material/Payments';
import CalculateIcon from '@mui/icons-material/Calculate';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import {
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";

function RouteButtons({ handleClose, page }) {
    const [openRentModal, setOpenRentModal] = useState(false);
    const [openShopModal, setOpenShopModal] = useState(false);
    const [openShoppingModal, setOpenShoppingModal] = useState(false);
    const [openDocumentModal, setOpenDocumentModal] = useState(false);
    const [openHealthModal, setOpenHealthModal] = useState(false);
    const [openTaxModal, setOpenTaxModal] = useState(false);
    const [openWorkForceModal, setOpenWorkForceModal] = useState(false);
    const [openTradingModal, setOpenTradingModal] = useState(false);

    const theme = useTheme();
    const location = useLocation();

    const pagesData = [
        {
            title: "Home",
            link: "/",
            icon: HomeIcon,
            open: null,
            setOpen: null,
        },
        {
            title: "Budget",
            link: "/manage-budget",
            icon: PaymentsIcon,
            open: null,
            setOpen: null,
        },
        {
            title: "Goals",
            link: "/financial-goals",
            icon: GpsFixedIcon,
            open: null,
            setOpen: null,
        },
        {
            title: "Finance Calculators",
            link: "/finance-calculator",
            icon: CalculateIcon,
            open: null,
            setOpen: null,
        },
        {
            title: "Net Worth",
            link: "/net-worth",
            icon: AccountBalanceWalletIcon,
            open: null,
            setOpen: null,
        },
        {
            title: "Credit Score",
            link: "/credit-score",
            icon: CreditScoreIcon,
            open: null,
            setOpen: null,
        },
        {
            title: "Rent & Home",
            link: "#",
            icon: HomeIcon,
            open: openRentModal,
            setOpen: setOpenRentModal,
            content: <ifrmae></ifrmae>
        },
        {
            title: "Shop & Compare",
            link: "#",
            icon: ShoppingCartOutlinedIcon,
            open: openShopModal,
            setOpen: setOpenShopModal,
        },
        {
            title: "Practice Shopping",
            link: "#",
            icon: ShoppingCartOutlinedIcon,
            open: openShoppingModal,
            setOpen: setOpenShoppingModal,
        },
        {
            title: "Document AI",
            link: "#",
            icon: LibraryBooksIcon,
            open: openDocumentModal,
            setOpen: setOpenDocumentModal,
        },
        {
            title: "Health Care",
            link: "#",
            icon: HealthAndSafetyIcon,
            open: openHealthModal,
            setOpen: setOpenHealthModal,
        },
        {
            title: "Dictionary",
            link: "/Dictionary",
            icon: LibraryBooksIcon,
            open: null,
            setOpen: null,
        },
        {
            title: "Boost",
            link: "/boost",
            icon: GpsFixedIcon,
            open: null,
            setOpen: null,
        },
        {
            title: "Taxes",
            link: "/taxes",
            icon: AssuredWorkloadOutlinedIcon,
            open: null,
            setOpen: null,
        },
        {
            title: "Workforce Readiness",
            link: "#",
            icon: ExploreOutlinedIcon,
            open: openWorkForceModal,
            setOpen: setOpenWorkForceModal,
        },

        {
            title: "Trading Flow",
            link: "#",
            icon: AutoGraphOutlinedIcon,
            open: openTradingModal,
            setOpen: setOpenTradingModal,
        },
    ];
    return (
        <>
            {
                pagesData.map((data, index) => {
                    const isActive = data.open !== null ? data.open : location.pathname === data.link;
                    return (
                        page == "sidebar" ?
                            <Box
                                key={index}
                                component={data.link ? Link : "div"}
                                to={data.link ? data.link : undefined}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    textDecoration: "none",
                                    gap: 1,
                                    py: 1,
                                    color: isActive
                                        ? theme.palette.success.main
                                        : theme.palette.primary.main,
                                    "&:hover": {
                                        color: theme.palette.success.main,
                                    },
                                }}
                                onClick={() => {
                                    handleClose()
                                    if (data.setOpen) {
                                        data.setOpen(true);
                                    }
                                }}
                            >
                                <data.icon
                                    color={
                                        isActive
                                            ? theme.palette.success.main
                                            : theme.palette.primary.main
                                    }
                                    sx={{
                                        color: isActive
                                            ? theme.palette.success.main
                                            : theme.palette.primary.main,
                                        width: "30px",
                                        height: "29px",
                                    }}
                                />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: isActive
                                            ? theme.palette.success.main
                                            : theme.palette.primary.main,
                                    }}
                                >
                                    {data.title}
                                </Typography>
                            </Box>
                            : index !== 0 ?
                                <Box
                                    key={index}
                                    component={data.link ? Link : "div"}
                                    to={data.link ? data.link : undefined}
                                    sx={{
                                        borderRadius: "14px",
                                        width: { sm: "45%", xs: "100%" },
                                        display: "flex",
                                        alignItems: "center",
                                        textDecoration: "none",
                                        gap: "16px",
                                        p: "12px",
                                        background: theme.palette.secondary.light,
                                        color: theme.palette.secondary.main,
                                        "&:hover": {
                                            color: theme.palette.success.main,
                                            background: theme.palette.success.light,
                                        },
                                    }}
                                    onClick={() => {
                                        if (data.setOpen) {
                                            data.setOpen(true);
                                        }
                                    }}
                                >
                                    <data.icon
                                        color={"inherit"}
                                        sx={{
                                            color: "inherit",
                                            width: "30px",
                                            height: "29px",
                                        }}
                                    />
                                    <Typography variant="h6" color={"inherit"} > {data.title} </Typography>
                                </Box>
                                : <></>
                    );
                })
            }

            {pagesData.map(
                (data, index) =>
                    data.open !== null && (
                        <Dialog
                            fullWidth
                            maxWidth="md"
                            key={index}
                            open={data.open}>
                            <DialogTitle>{data.title}</DialogTitle>
                            <DialogContent>
                                <Typography>{data.title} </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => data.setOpen(false)}>Close</Button>
                            </DialogActions>
                        </Dialog>
                    )
            )}
        </>
    )
}

export default RouteButtons