
const StudentLoanIcon = ({color}) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.0968 7.91156C26.0763 7.66388 25.9005 7.44075 25.6652 7.36151L15.3097 3.7951C15.1738 3.74831 15.026 3.74831 14.89 3.7951L4.53451 7.36151C4.27442 7.4511 4.09984 7.69585 4.09984 7.97094C4.09984 8.24602 4.27442 8.49077 4.53451 8.58036L8.03148 9.78469V15.9202C8.03148 16.1821 8.18999 16.418 8.43251 16.5169C8.94341 16.7254 9.4672 16.9103 9.99747 17.0705C9.52563 17.9131 9.25609 18.8837 9.25609 19.9163C9.25609 23.1385 11.8776 25.76 15.0998 25.76C18.3221 25.76 20.9436 23.1385 20.9436 19.9163C20.9436 18.8837 20.6741 17.9131 20.2022 17.0705C20.7325 16.9103 21.2563 16.7254 21.7672 16.5169C22.0097 16.418 22.1682 16.1821 22.1682 15.9202V9.78469L24.8108 8.87457V12.1819C24.8108 12.5378 25.0994 12.8264 25.4553 12.8264C25.8113 12.8264 26.0998 12.5378 26.0998 12.1819V7.97094C26.0998 7.95096 26.0986 7.93119 26.0968 7.91156ZM15.0998 24.4709C12.5884 24.4709 10.5452 22.4277 10.5452 19.9163C10.5452 17.4048 12.5884 15.3616 15.0998 15.3616C17.6113 15.3616 19.6545 17.4048 19.6545 19.9163C19.6545 22.4277 17.6113 24.4709 15.0998 24.4709ZM20.8791 15.4808C20.3937 15.6641 19.8977 15.8243 19.3972 15.9606C18.3285 14.8006 16.7977 14.0725 15.0998 14.0725C13.402 14.0725 11.8712 14.8006 10.8025 15.9606C10.302 15.8243 9.80601 15.6641 9.32054 15.4808V10.2286L14.89 12.1467C15.026 12.1936 15.1738 12.1936 15.3097 12.1467L20.8791 10.2286V15.4808ZM15.0998 10.8556L6.72373 7.97094L15.0998 5.08623L23.476 7.97094L15.0998 10.8556Z" fill={color}/>
    <path d="M15.9121 20.0942C15.3203 19.876 14.7679 19.6387 14.576 19.5549C14.5042 19.4889 14.5032 19.4035 14.5102 19.3549C14.5194 19.2906 14.5681 19.1326 14.8196 19.0569C15.4638 18.8628 16.0735 19.3474 16.0797 19.3523C16.4362 19.6158 16.9333 19.5296 17.1661 19.1966C17.3739 18.8992 17.3282 18.4664 17.0394 18.2C16.9385 18.1209 16.8235 18.0394 16.6928 17.9622C16.4858 17.8399 16.196 17.6979 15.8497 17.6063V17.4591C15.8497 17.045 15.514 16.7092 15.0999 16.7092C14.6858 16.7092 14.35 17.045 14.35 17.4591V17.6334C13.6329 17.8637 13.1267 18.438 13.0257 19.1419C12.9288 19.8168 13.2215 20.4662 13.7896 20.8366C13.8228 20.8582 13.8575 20.8771 13.8936 20.8932C13.9227 20.9062 14.6144 21.2141 15.3931 21.5013C15.6977 21.6136 16.0086 21.8146 15.9681 22.0418C15.9346 22.2292 15.6734 22.5065 15.2188 22.5065C14.757 22.5065 14.3139 22.3217 14.0334 22.0122C13.7554 21.7054 13.2812 21.6821 12.9742 21.9602C12.6673 22.2383 12.644 22.7125 12.9221 23.0193C13.3002 23.4365 13.7975 23.7344 14.35 23.8878V24.0577C14.35 24.4718 14.6858 24.8076 15.0999 24.8076C15.514 24.8076 15.8497 24.4718 15.8497 24.0577V23.9265C16.6706 23.7145 17.303 23.0974 17.4444 22.3054C17.5777 21.5589 17.2452 20.586 15.9121 20.0942Z" fill={color}/>
    </svg>
    
  )
}

export default StudentLoanIcon