import * as Axios from 'axios';

const API_URL = process.env.REACT_APP_API_LINK + "/client-application";
const axios = Axios.create({
    'headers': { 'x-api-key': process.env.REACT_APP_X_API_KEY }
})

// getting skills of money
export const getUserByToken = async () => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.get(`${API_URL}/me`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        console.log("User in reponse: ", res)
        return res.data?.user;
    }

}
