import { useState, useEffect } from "react";
import Asset from "./Assets";
import Button from "@mui/material/Button";
import { useQuery } from "react-query";
import Loading from "../common/loading";
import {
  addNewBudgetEntry,
  deleteBudgetEntry,
  getAllData,
  updateBudgetEntry,
} from "../_api";
import {
  currencyFormat,
  GetNetWorthItemData,
  netWorthTotalAssets,
} from "../_budget-functionality/index";
import SkillAssignments from "../common/SkillAssignments";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { formatPrefix } from "d3-format";
import moment from "moment";
import BarChart from "./BarChart";
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CustomTextField } from "../financial-goals/CustomField";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import OthersIcon from "../svgIcons/OthersIcon";
import SavingIcon from "../svgIcons/SavingIcon";
import CollectionIcon from "../svgIcons/CollectionIcon";
import InstrumentsIcon from "../svgIcons/InstrumentsIcon";
import GoldSilverIcon from "../svgIcons/GoldSilverIcon";
import JewelaryIcon from "../svgIcons/JewelaryIcon";
import PlamTreeIcon from "../svgIcons/PlamTreeIcon";
import DegreeIcon from "../svgIcons/DegreeIcon";
import InvestmentIcon from "../svgIcons/InvestmentIcon";
import CryptoIcon from "../svgIcons/CryptoIcon";
import CashIcon from "../svgIcons/CashIcon";
import BankIcon from "../svgIcons/BankIcon.";
import CheckingIcon from "../svgIcons/CheckingIcon";
import VehicleIcon from "../svgIcons/VehicleIcon";
import EstateIcon from "../svgIcons/EstateIcon";
import CreditCardIcon from "../svgIcons/CreditCardIcon";
import MortgageIcon from "../svgIcons/MortgageIcon";
import AutoLoanIcon from "../svgIcons/AutoLoanIcon";
import PersonalLoanIcon from "../svgIcons/PersonalLoanIcon";
import StudentLoanIcon from "../svgIcons/StudentLoanIcon";
import OtherDebtIcon from "../svgIcons/OtherDebtIcon";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import SingleAsset from "./SingleAsset";
import MyChartComponent from "../../layouts/common/MyChartComponent";
import CustomDialog from "../manage-budget/CustomDialog";

let filteredData = [];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    borderRadius: "1.25rem",
    background: theme.palette.common.disabled,
    color: theme.palette.common.white,
    fontWeight: 600,
    padding: "0.1rem 0.5rem", // Adjust padding here
    [theme.breakpoints.up("md")]: {
      padding: ".2rem 1.5rem", // Adjust padding for larger screens
    },
    "&:hover": {
      background: theme.palette.common.text,
      color: theme.palette.common.white,
    },
    "&.Mui-selected": {
      background: theme.palette.common.text,
      color: theme.palette.common.white,
      "&:hover": {
        background: theme.palette.common.text,
        color: theme.palette.common.white,
      },
    },
    "&:first-of-type": {
      marginRight: { xs: "4px", md: "8px" },
    },
  },
}));

export default function Banner(props) {
  const queryClient = useQueryClient();
  const { isLoading, data: allData } = useQuery(
    "budgeting-calculators",
    getAllData,
    {
      enabled: Boolean(sessionStorage.getItem("token")),
      onError: () => toast.error("Failed to fetch data."),
    }
  );

  const {
    mutate: addAmount,
  }  = useMutation(addNewBudgetEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries("budgeting-calculators");
      toast.dismiss();
      toast.success("Added Successfully")
    },
    onError: () => {
      toast.error("Something went wrong!");
      toast.dismiss();
    },
  });

  const {
    mutate: delTransaction,
  } = useMutation(deleteBudgetEntry, {
    onMutate: () => toast.loading("Deleting..."),
    onSuccess: () => {
      queryClient.invalidateQueries("budgeting-calculators");
      setOpenDialog(false)
      toast.dismiss();
      toast.success("Transaction deleted successfully");
    },
    onError: () => {toast.error("Failed to delete entry.")
      toast.dismiss();
    },
  });

  const {
    mutate: updateAmount,
  }  = useMutation(updateBudgetEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries("budgeting-calculators");
      toast.dismiss();
      toast.success("Edited Successfully")
    },
    onError: () => {
      toast.error("Something went wrong!");
      toast.dismiss();
    },
  });
  const [view, setView] = useState("assets");
  const [cash, setCash] = useState(0);
  const [bank, setBank] = useState(0);
  const [checking, setChecking] = useState(0);
  const [saving, setSaving] = useState(0);
  const [vehicle, setVehicle] = useState(0);
  const [realEstate, setRealEstate] = useState(0);
  const [crypto, setCrypto] = useState(0);
  const [investments, setInvestments] = useState(0);
  const [ira, setIra] = useState(0);
  const [plan, setPlan] = useState(0);
  const [goldSilver, setGoldSilver] = useState(0);
  const [jewelry, setJewelry] = useState(0);
  const [collectibles, setCollectibles] = useState(0);
  const [instruments, setInstruments] = useState(0);
  const [boat, setBoat] = useState(0);
  const [otherAsset, setOtherAsset] = useState(0);
  const [creditCard, setCreditCard] = useState(0);
  const [mortgage, setMortgage] = useState(0);
  const [autoLoan, setAutoLoan] = useState(0);
  const [personalLoan, setPersonalLoan] = useState(0);
  const [studentLoan, setStudentLoan] = useState(0);
  const [otherDebt, setOtherDebt] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [singleAssetDetail, setSingleAssetDetail] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const [index, setIndex] = useState(0);

  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      amount: singleAssetDetail?.amount || null,
      description: singleAssetDetail?.description || "",
    },
    validationSchema: Yup.object({
      amount: Yup.number().required("Amount is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      
      const data = {
        name: singleAssetDetail?.name,
        amount: parseInt(values.amount),
        mainCategory: "NetWorth",
        subCategory: singleAssetDetail?.subCategory,
        type: singleAssetDetail?.type,
        description: values.description,
      };
      try {
        toast.loading(isEdit ? "Updating..." : "Adding...");
        isEdit ? updateAmount(data) : addAmount(data);
        resetForm();
        setOpenDialog(false);
        setOpenDetailsDialog(false);
      } catch (error) {
        console.error(error);
      } finally {
        setIsEdit(false);
      }
    },
  });

  const handleAssetClick = (asset) => {
    setSelectedAsset(asset);
    setOpenDetailsDialog(true);
  };

  useEffect(() => {
    if (!isLoading) {
      assetData?.map((item) => {
        netWorthTotalAssets(
          "NetWorth",
          allData,
          item?.subcategory,
          item?.setValue,
          item?.type
        );
      });
      debtData?.map((item) => {
        netWorthTotalAssets(
          "NetWorth",
          allData,
          item?.subcategory,
          item?.setValue,
          item?.type
        );
      });
    }
  }, [allData]);

  useEffect(() => {
    if (isEdit) {
      formik.setFieldValue("amount", singleAssetDetail?.amount);
      formik.setFieldValue("description", singleAssetDetail?.description);
    } else {
      formik.setFieldValue("amount", null);
      formik.setFieldValue("description", "");
    }
  }, [isEdit]);

  const assetData = [
    {
      title: "Cash",
      type: "Add",
      value: cash,
      setValue: setCash,
      maincategory: "NetWorth",
      subcategory: "Cash",
      icon: CashIcon,
    },
    {
      title: "Bank",
      type: "Add",
      value: bank,
      setValue: setBank,
      maincategory: "NetWorth",
      subcategory: "Bank",
      icon: BankIcon,
    },
    // {
    //     title: "Test",
    //     type: "Add",
    //     value: test,
    //     setValue: setTest,
    //     maincategory: "NetWorth",
    //     subcategory: "Test",
    //     icon: <GrTest style={{ fontSize: "25px" }} />,

    // },
    {
      title: "Savings",
      type: "Add",
      value: saving,
      setValue: setSaving,
      maincategory: "NetWorth",
      subcategory: "Savings",
      icon: SavingIcon,
    },
    {
      title: "Checking",
      type: "Add",
      value: checking,
      setValue: setChecking,
      maincategory: "NetWorth",
      subcategory: "Checking",
      icon: CheckingIcon,
    },
    {
      title: "Vehicle",
      type: "Add",
      value: vehicle,
      setValue: setVehicle,
      maincategory: "NetWorth",
      subcategory: "Vehicles",
      icon: VehicleIcon,
    },

    {
      title: "Real Estate",
      type: "Add",
      value: realEstate,
      setValue: setRealEstate,
      maincategory: "NetWorth",
      subcategory: "RealEstate",
      icon: EstateIcon,
    },
    {
      title: "Crypto",
      type: "Add",
      value: crypto,
      setValue: setCrypto,
      maincategory: "NetWorth",
      subcategory: "Crypto",
      icon: CryptoIcon,
    },
    {
      title: "Investments",
      type: "Add",
      value: investments,
      setValue: setInvestments,
      maincategory: "NetWorth",
      subcategory: "Investments",
      icon: InvestmentIcon,
    },
    {
      title: "401k/IRA",
      type: "Add",
      value: ira,
      setValue: setIra,
      maincategory: "NetWorth",
      subcategory: "IRA",
      icon: PlamTreeIcon,
    },
    {
      title: "529 Plan",
      type: "Add",
      value: plan,
      setValue: setPlan,
      maincategory: "NetWorth",
      subcategory: "Plan",
      icon: DegreeIcon,
    },
    {
      title: "Gold/Silver",
      type: "Add",
      value: goldSilver,
      setValue: setGoldSilver,
      maincategory: "NetWorth",
      subcategory: "GoldSilver",
      icon: GoldSilverIcon,
    },
    {
      title: "Jewelry",
      type: "Add",
      value: jewelry,
      setValue: setJewelry,
      maincategory: "NetWorth",
      subcategory: "Jewelry",
      icon: JewelaryIcon,
    },
    {
      title: "Collectibles",
      type: "Add",
      value: collectibles,
      setValue: setCollectibles,
      maincategory: "NetWorth",
      subcategory: "Collectibles",
      icon: CollectionIcon,
    },
    {
      title: "Instruments",
      type: "Add",
      value: instruments,
      setValue: setInstruments,
      maincategory: "NetWorth",
      subcategory: "Instruments",
      icon: InstrumentsIcon,
    },
    // {
    //   title: "Boat",
    //   type: "Add",
    //   value: boat,
    //   setValue: setBoat,
    //   maincategory: "NetWorth",
    //   subcategory: "Boat",
    //   icon: <GiFishingBoat style={{ fontSize: "25px" }} />,
    // },
    {
      title: "Other",
      type: "Add",
      value: otherAsset,
      setValue: setOtherAsset,
      maincategory: "NetWorth",
      subcategory: "Other",
      icon: OthersIcon,
    },
  ];
  const debtData = [
    {
      title: "Credit Card",
      type: "Delete",
      value: creditCard,
      setValue: setCreditCard,
      maincategory: "NetWorth",
      subcategory: "CreditCard",
      icon: CreditCardIcon,
    },
    {
      title: "Mortgage",
      type: "Delete",
      value: mortgage,
      setValue: setMortgage,
      maincategory: "NetWorth",
      subcategory: "Mortgage",
      icon: MortgageIcon,
    },
    {
      title: "Auto Loan",
      type: "Delete",
      value: autoLoan,
      setValue: setAutoLoan,
      maincategory: "NetWorth",
      subcategory: "AutoLoan",
      icon: AutoLoanIcon,
    },
    {
      title: "Personal Loan",
      type: "Delete",
      value: personalLoan,
      setValue: setPersonalLoan,
      maincategory: "NetWorth",
      subcategory: "PersonalLoan",
      icon: PersonalLoanIcon,
    },
    {
      title: "Student Loan",
      type: "Delete",
      value: studentLoan,
      setValue: setStudentLoan,
      maincategory: "NetWorth",
      subcategory: "StudentLoan",
      icon: StudentLoanIcon,
    },
    {
      title: "Other",
      type: "Delete",
      value: otherDebt,
      setValue: setOtherDebt,
      maincategory: "NetWorth",
      subcategory: "Other",
      icon: OtherDebtIcon,
    },
  ];
  if (!isLoading) {
    filteredData = allData?.filter((entry) => {
      return entry.amount !== 0 && entry.mainCategory === "NetWorth";
    });
  }

  const handleViewDetails = (data) => {
    setSelectedDetails(
      GetNetWorthItemData("NetWorth", allData, data?.subcategory, data?.type)
    );
    setOpenDialog(true);
    setIndex(1);
  };

  const handleViewSingleAssetDetails = (data) => {
    setSingleAssetDetail(data);
    setIndex(2);
  };

  const dialogTitle =
    index === 0 ? (
      <>
        <Typography variant="h2" textAlign="center" color="text">
          Add {view === "assets" ? "Asset" : "Debt"}
        </Typography>
        <Typography textAlign="center">
          Please select a type of {view === "assets" ? "asset" : "debt"} to add
        </Typography>
        <Box position="absolute" right={10} top={10}>
          <IconButton aria-label="close" onClick={() => setOpenDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </>
    ) : index === 1 ? (
      <>
        <Box position="absolute" right={10} top={10}>
          <IconButton aria-label="close" onClick={() => setOpenDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </>
    ) : index === 2 ? (
      <></>
    ) : null;

  const dialogContent =
    index === 0 ? (
      <>
        <Box height="18rem" overflowY="auto">
          <Grid container spacing={{ xs: 0.5, md: 1 }}>
            {(view === "assets" ? assetData : debtData)?.map((asset, index) => (
              <Grid item key={index} xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: { xs: 0.3, md: 1 },
                    backgroundColor: theme.palette.common.background,
                    padding: "12px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAssetClick(asset)}
                >
                  <Box>{<asset.icon color={theme.palette.common.text} />}</Box>
                  <Typography variant={{ xs: "body2", md: "body1" }}>
                    {asset.title}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    ) : index === 1 ? (
      <>
        <Box display="flex" flexDirection="column" gap={1}>
          {selectedDetails?.map((asset, index) => {
            return (
              <SingleAsset
                key={index}
                keyId={index}
                data={asset}
                onViewDetails={handleViewSingleAssetDetails}
              />
            );
          })}
        </Box>
      </>
    ) : index === 2 ? (
      <>
        <Box
          sx={{
            border: `1px solid ${theme.palette.common.disabled}`,
            borderRadius: "2rem",
            p: "1.75rem",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">{singleAssetDetail?.name}</Typography>
            <Typography variant="h1">$ {singleAssetDetail?.amount}</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor={theme.palette.common.background}
            p={1}
            my={1}
            borderRadius={1}
          >
            <Typography variant="body1">Date:</Typography>
            <Typography variant="body1" fontWeight="700">
              {moment(singleAssetDetail?.createdAt).format("ll")}
            </Typography>
          </Box>
          <Typography variant="body1" fontWeight="600">
            Description:
          </Typography>
          <Box
            sx={{
              bgcolor: theme.palette.common.background,
              p: 1,
              borderRadius: 1,
              height: "6rem",
              overflowY: "auto",
            }}
          >
            <Typography variant="body2">
              {singleAssetDetail?.description}
            </Typography>
          </Box>
        </Box>
      </>
    ) : null;

  const dialogAction =
    index === 0 ? (
      <></>
    ) : index === 1 ? (
      <></>
    ) : index === 2 ? (
      <>
        <Box display="flex" justifyContent="space-between" p={2} width="100%">
          <Box>
            <Button
              variant="text"
              startIcon={<ArrowBackIosIcon />}
              onClick={() => {
                setIndex(1);
                setIsEdit(false);
              }}
            >
              Back
            </Button>
          </Box>

          <Box display="flex" gap={2}>
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => {
                setIsEdit(true);
                setOpenDetailsDialog(true);
              }}
              disableRipple
              sx={{ minWidth: "130px" }}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              startIcon={<DeleteIcon />}
              disableRipple
              onClick={() => {
                const data = {
                  id: singleAssetDetail.id,
                };
                delTransaction(data);
              }}
              sx={{
                color: theme.palette.common.white,
                bgcolor: theme.palette.error.main,
                "&:hover": {
                  bgcolor: theme.palette.error.dark,
                },
                minWidth: "130px",
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </>
    ) : null;

  if (isLoading) {
    return <Loading />;
  } else
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Card sx={{ height: "100%", p: { xs: "14px", md: "28px" } }}>
              {/* <BarChart data={{ netWorth: allData }} /> */}
              <MyChartComponent data={allData} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card sx={{ height: "100%", p: { xs: "14px", md: "28px" } }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    border: `2px solid ${theme.palette.common.disabled}`,
                    backgroundColor: theme.palette.common.background,
                    borderRadius: "1.5rem",
                    p: { xs: ".1rem", md: ".3rem .5rem" },
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  <StyledToggleButtonGroup
                    color="primary"
                    value={view}
                    exclusive
                    aria-label="Platform"
                  >
                    <ToggleButton
                      value="assets"
                      sx={{ fontSize: { xs: ".7rem", md: ".9rem" },mr:'.3rem' }}
                      onClick={() => setView("assets")}
                    >
                      Assets
                    </ToggleButton>
                    <ToggleButton
                      value="debts"
                      sx={{ fontSize: { xs: ".7rem", md: ".9rem" } }}
                      onClick={() => setView("debts")}
                    >
                      Debts
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                </Box>

                <Box
                  display="flex"
                  gap={{ xs: 0.1, md: 0.3 }}
                  alignItems="center"
                >
                  <Typography
                    fontSize={{ xs: ".6rem", md: "1rem" }}
                    color="text"
                  >
                    {view === "assets" ? "Total Assets:" : "Total Debts:"}
                  </Typography>
                  <Typography
                    fontSize={{ xs: "1rem", md: "2rem" }}
                    color="text"
                    fontWeight="bold"
                  >
                    ${" "}
                    {view === "assets"
                      ? assetData
                          ?.reduce(
                            (total, item) =>
                              item.value !== 0 && item.type === "Add"
                                ? total + item.value
                                : total,
                            0
                          )
                          .toLocaleString()
                      : debtData
                          ?.reduce(
                            (total, item) =>
                              item.value !== 0 && item.type === "Delete"
                                ? total + item.value
                                : total,
                            0
                          )
                          .toLocaleString()}
                  </Typography>
                </Box>
              </Box>

              {(view === "assets"
                ? assetData?.filter(
                    (item) => item.value !== 0 && item.type === "Add"
                  )
                : debtData?.filter(
                    (item) => item.value !== 0 && item.type === "Delete"
                  )
              ).length === 0 ? (
                <Card
                  sx={{
                    backgroundColor: theme.palette.common.background,
                    borderRadius: ".5rem",
                    height: "15rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    my: "1.5rem",
                  }}
                >
                  <Typography variant="body2">
                    {view === "assets"
                      ? "You have not added any assets. Please add assets by clicking 'Add Asset' button below."
                      : "You have not added any debt. Please add debt by clicking 'Add Debt' button below."}
                  </Typography>
                </Card>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    height: "15rem",
                    overflowY: "auto",
                    my: "1.75rem",
                  }}
                >
                  {(view === "assets"
                    ? assetData?.filter(
                        (item) => item.value !== 0 && item.type === "Add"
                      )
                    : debtData?.filter(
                        (item) => item.value !== 0 && item.type === "Delete"
                      )
                  ).map((item, index) => (
                    <Asset
                      key={index}
                      keyId={index}
                      data={item}
                      name={"Asset"}
                      onViewDetails={handleViewDetails}
                    />
                  ))}
                </Box>
              )}

              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  disableRipple
                  sx={{ width: "50%" }}
                  onClick={() => {
                    setOpenDialog(true);
                    setIndex(0)
                    setIsEdit(false);
                  }}
                >
                  Add {view === "assets" ? "Asset" : "Debt"}
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>

        {/* <SkillAssignments
          userID={props?.user?.id}
          open={open}
          setOpen={setOpen}
          partName="net-worth"
        /> */}

        <CustomDialog
          openDialog={openDialog}
          dialogTitle={dialogTitle}
          dialogContent={dialogContent}
          dialogAction={dialogAction}
        />

        <Dialog
          open={openDetailsDialog}
          fullWidth
          maxWidth="md"
          sx={{
            "& .MuiPaper-root": {
              "@media (max-width:900px)": {
                bottom: 0,
                position: "absolute",
                borderRadius: "0px",
                margin: 0,
                width: "100%",
              },
            },
          }}
        >
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              {!isEdit && selectedAsset?.icon && (
                <selectedAsset.icon color={theme.palette.primary.main} />
              )}

              <Typography variant="h3" color="text">
                {isEdit
                  ? `Edit ${singleAssetDetail?.name}`
                  : `Add ${selectedAsset?.title}`}
              </Typography>
            </Box>
            {!isEdit && (
              <Typography textAlign="center">
                Please Enter The{" "}
                {isEdit ? singleAssetDetail?.name : selectedAsset?.title} Amount
                You Want To Add.
              </Typography>
            )}

            <Box position="absolute" right={10} top={10}>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setOpenDetailsDialog(false);
                  setIsEdit(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box>
                  <CustomTextField
                    name="amount"
                    label={isEdit ? "Edit Amount :" : "Enter Amount"}
                    type="number"
                    placeholder={isEdit ? "Edit Amount :" : "Enter Amount"}
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ paddingLeft: "5px", paddingBottom: "10px" }}
                        >
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box>
                  <CustomTextField
                    name="description"
                    label={isEdit ? "Edit Description" : "Description"}
                    placeholder={isEdit ? "Edit Description" : "Description"}
                    multiline
                    value={formik.values.description}
                    rows={4}
                    onChange={formik.handleChange}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box
                // display="flex"
                // justifyContent="space-between"
                // p={2}
                // width="100%"
              >
                {/* <Button
                  variant="text"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => {
                    setOpenDetailsDialog(false);
                    setIsEdit(false);
                  }}
                >
                  Back
                </Button> */}
                <Button variant="contained" type="submit">
                  {isEdit
                    ? `Set ${singleAssetDetail?.name}`
                    : `Add ${selectedAsset?.title}`}
                </Button>
              </Box>
            </DialogActions>
          </form>
        </Dialog>
      </>
    );
}

// const allData = [
//   {
//     id: 111,
//     name: null,
//     amount: 2000,
//     mainCategory: "NetWorth",
//     subCategory: "MonthlyIncome",
//     type: "Add",
//     description: "Income",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:23:46.983Z",
//     updatedAt: "2024-08-23T12:23:46.983Z",
//   },
//   {
//     id: 12,
//     name: null,
//     amount: 2100,
//     mainCategory: "NetWorth",
//     subCategory: "MonthlyIncome",
//     type: "Add",
//     description: "Income",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-24T12:23:46.983Z",
//     updatedAt: "2024-08-24T12:23:46.983Z",
//   },
//   {
//     id: 13,
//     name: null,
//     amount: 800,
//     mainCategory: "NetWorth",
//     subCategory: "MonthlyIncome",
//     type: "Add",
//     description: "Income",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-22T12:23:46.983Z",
//     updatedAt: "2024-08-22T12:23:46.983Z",
//   },
//   {
//     id: 15,
//     name: null,
//     amount: 8000,
//     mainCategory: "NetWorth",
//     subCategory: "MonthlyIncome",
//     type: "Add",
//     description: "Income",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-25T12:23:46.983Z",
//     updatedAt: "2024-08-25T12:23:46.983Z",
//   },
//   {
//     id: 2,
//     name: null,
//     amount: 3000,
//     mainCategory: "NetWorth",
//     subCategory: "CashAndCheck",
//     type: "Add",
//     description: "adsfads ",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:23:55.254Z",
//     updatedAt: "2024-08-23T12:23:55.254Z",
//   },
//   {
//     id: 3,
//     name: null,
//     amount: 3000,
//     mainCategory: "NetWorth",
//     subCategory: "Checking",
//     type: "Add",
//     description: "vdsfg",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:24:02.351Z",
//     updatedAt: "2024-08-23T12:24:02.351Z",
//   },
//   {
//     id: 4,
//     name: null,
//     amount: 4000,
//     mainCategory: "NetWorth",
//     subCategory: "AutoAndTransport",
//     type: "Delete",
//     description: "sdfadsf",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:24:12.802Z",
//     updatedAt: "2024-08-23T12:24:12.802Z",
//   },
//   {
//     id: 5,
//     name: null,
//     amount: 1888,
//     mainCategory: "NetWorth",
//     subCategory: "Education",
//     type: "Delete",
//     description: "cvbxfb",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:24:24.847Z",
//     updatedAt: "2024-08-23T12:24:24.847Z",
//   },
//   {
//     id: 6,
//     name: null,
//     amount: 5098,
//     mainCategory: "NetWorth",
//     subCategory: "Fees",
//     type: "Delete",
//     description: "dfgdfg",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:24:47.871Z",
//     updatedAt: "2024-08-23T12:24:47.871Z",
//   },
//   {
//     id: 7,
//     name: "Cash",
//     amount: 399,
//     mainCategory: "NetWorth",
//     subCategory: "Cash",
//     type: "Add",
//     description: "hjfgkghjk",
//     frequency: null,
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:25:12.427Z",
//     updatedAt: "2024-08-23T12:25:12.427Z",
//   },
//   {
//     id: 8,
//     name: "Bank",
//     amount: 48887,
//     mainCategory: "NetWorth",
//     subCategory: "Bank",
//     type: "Add",
//     description: "hjfghj",
//     frequency: null,
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:25:19.502Z",
//     updatedAt: "2024-08-23T12:25:19.502Z",
//   },
//   {
//     id: 8,
//     name: "Bank",
//     amount: 48887,
//     mainCategory: "NetWorth",
//     subCategory: "Bank",
//     type: "Add",
//     description: "hjfghj",
//     frequency: null,
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:25:19.502Z",
//     updatedAt: "2024-08-23T12:25:19.502Z",
//   },
//   {
//     id: 9,
//     name: "Savings",
//     amount: 565,
//     mainCategory: "NetWorth",
//     subCategory: "Savings",
//     type: "Add",
//     description: "6tyurty",
//     frequency: null,
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:25:25.702Z",
//     updatedAt: "2024-08-23T12:25:25.702Z",
//   },
//   {
//     id: 10,
//     name: "Credit Card",
//     amount: 56564,
//     mainCategory: "NetWorth",
//     subCategory: "CreditCard",
//     type: "Delete",
//     description: "dhfghd",
//     frequency: null,
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:25:35.859Z",
//     updatedAt: "2024-08-23T12:25:35.859Z",
//   },
//   {
//     id: 11,
//     name: "Student Loan",
//     amount: 455,
//     mainCategory: "NetWorth",
//     subCategory: "StudentLoan",
//     type: "Delete",
//     description: "ghdfg",
//     frequency: null,
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:25:45.269Z",
//     updatedAt: "2024-08-23T12:25:45.269Z",
//   },
// ];
