import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import CurrencyFormat from 'react-currency-format';
import moment from "moment";
import Header from "./Header";
import {
  currencyFormat,
  toLatestArray,
  toOldArray,
  lowToHigh,
  highToLow,
  spendingTotal,
  recuringCase,
} from "../_budget-functionality/index";
import CircularProgress from "@mui/material/CircularProgress";

import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import IconButton from "@mui/material/IconButton";

import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FlatwareIcon from "@mui/icons-material/Flatware";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import SchoolIcon from "@mui/icons-material/School";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DeckIcon from "@mui/icons-material/Deck";
import MoneyIcon from "@mui/icons-material/Money";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import PetsIcon from "@mui/icons-material/Pets";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import BiotechIcon from "@mui/icons-material/Biotech";
import PercentIcon from "@mui/icons-material/Percent";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import GavelIcon from "@mui/icons-material/Gavel";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import Loading from "../common/loading";
import EditModel, { recuringList } from "./EditModel";
import DeleteModel from "./DeleteModel";
import { useQuery } from "react-query";
import { getAllData } from "../_api/index";
import {
  budgetHistory,
  selectDate,
  selectCompareDate,
  transactionTotal,
  MonthlyAmount,
} from "../_budget-functionality/index";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from 'react-router-dom'

let today = new Date().toISOString();
function BillHistory() {
  const { isLoading, data: allData } = useQuery(
    "budgeting-calculators",
    getAllData, {
    enabled: Boolean(sessionStorage.getItem('token'))
  }
  );
  const [income, setIncome] = useState(0);
  const [bills, setBills] = useState(0);
  const [duration, setDuration] = useState("This Month");
  const [data, setData] = useState([]);
  const [sort, setSort] = useState("Recent First");
  const [sortedData, setSortedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [cashAndChecks, setCashAndChecks] = useState(0);
  const [hustle, setHustle] = useState(0);
  const [everythingElse, setEverthingElse] = useState(0);

  var url = new URL(window.location.href);
  var type = url?.searchParams?.get("type");
  const { id } = useParams();
  useEffect(() => {
    if (!isLoading) {
      setData(
        budgetHistory(
          id,
          allData,
          "ManageBudget",
          selectDate(duration),
          selectCompareDate(duration),
          type
        )
      );
      MonthlyAmount(
        "ManageBudget",
        allData,
        setIncome,
        "MonthlyIncome",
        null,
        "Add"
      );
      MonthlyAmount(
        "ManageBudget",
        allData,
        setBills,
        "BillsAndUtilities",
        null,
        "Delete"
      );
      MonthlyAmount(
        "ManageBudget",
        allData,
        setCashAndChecks,
        "CashAndCheck",
        null,
        "Add"
      );
      MonthlyAmount(
        "ManageBudget",
        allData,
        setHustle,
        "SideHustle",
        null,
        "Add"
      );
      MonthlyAmount(
        "ManageBudget",
        allData,
        setEverthingElse,
        "EverythingElse",
        null,
        "Add"
      );
    }
  }, [allData, duration]);

  useEffect(() => {
    setLoading(true);
    if (!isLoading) {
      if (sort === "Recent First") {
        setSortedData(toLatestArray(data));
        setLoading(false);
      }
      if (sort === "Old First") {
        setSortedData(toOldArray(data));
        setLoading(false);
      }
      if (sort === "Low to High") {
        setSortedData(lowToHigh(data));
        setLoading(false);
      }
      if (sort === "High to Low") {
        setSortedData(highToLow(data));
        setLoading(false);
      }
    }
    if (!isLoading) {
      setFilteredData(
        categoryData.filter((item) => {
          return item.category === id;
        })
      );
    }
  }, [data, sort, id]);

  const categoryData = [
    {
      title: "Monthly Income",
      icon: <CreditCardIcon />,
      category: "MonthlyIncome",
      type: "Add",
    },
    {
      title: "Bills & Utilities",
      icon: <FileCopyIcon />,
      category: "BillsAndUtilities",
      type: "Delete",
    },
    {
      title: "Auto & Transport",
      icon: <DirectionsCarIcon />,
      category: "AutoAndTransport",
      type: "Delete",
    },
    {
      title: "Cash & Checks",
      icon: <CreditCardIcon />,
      category: "CashAndCheck",
      type: "Add",
    },
    {
      title: "Dinning & Drinks",
      icon: <FlatwareIcon />,
      category: "DinningAndDrinks",
      type: "Delete",
    },
    {
      title: "Credit Card Payment",
      icon: <CreditScoreIcon />,
      category: "CreditCardPayment",
      type: "Delete",
    },
    {
      title: "Education",
      icon: <SchoolIcon />,
      category: "Education",
      type: "Delete",
    },
    {
      title: "Charitable Donations",
      icon: <VolunteerActivismIcon />,
      category: "CharitableDonations",
      type: "Delete",
    },
    {
      title: "Entertainmetn and Rec.",
      icon: <SportsFootballIcon />,
      category: "Entertainment",
      type: "Delete",
    },
    {
      title: "Family Care",
      icon: <FavoriteBorderIcon />,
      category: "FamilyCare",
      type: "Delete",
    },
    {
      title: "Fees",
      icon: <MonetizationOnIcon />,
      category: "Fees",
      type: "Delete",
    },
    {
      title: "Gifts",
      icon: <CardGiftcardIcon />,
      category: "Gifts",
      type: "Delete",
    },
    {
      title: "Groceries",
      icon: <ShoppingCartCheckoutIcon />,
      category: "Groceries",
      type: "Delete",
    },
    {
      title: "Health & Wellness",
      icon: <DirectionsRunIcon />,
      category: "HealthAndWellness",
      type: "Delete",
    },
    {
      title: "Home & Garden",
      icon: <DeckIcon />,
      category: "HomeAndGarden",
      type: "Delete",
    },
    {
      title: "Loan Payment",
      icon: <MoneyIcon />,
      category: "LoanPayment",
      type: "Delete",
    },
    {
      title: "Medical",
      icon: <MedicalServicesIcon />,
      category: "Medical",
      type: "Delete",
    },
    {
      title: "Personal Care",
      icon: <SelfImprovementIcon />,
      category: "PersonalCare",
      type: "Delete",
    },
    {
      title: "Pets",
      icon: <PetsIcon />,
      category: "Pets",
      type: "Delete",
    },
    {
      title: "Saving Transfers",
      icon: <TransferWithinAStationIcon />,
      category: "SavingTransfers",
      type: "Delete",
    },
    {
      title: "Shopping",
      icon: <ShoppingBagIcon />,
      category: "Shopping",
      type: "Delete",
    },
    {
      title: "Software & Tech",
      icon: <BiotechIcon />,
      category: "SoftwareAndTech",
      type: "Delete",
    },
    {
      title: "Taxes",
      modalId: "changeTex",
      icon: <PercentIcon />,
      category: "Taxes",
      type: "Delete",
    },
    {
      title: "Travel & Vacation",
      icon: <TravelExploreIcon />,
      category: "TravelAndVacation",
      type: "Delete",
    },
    {
      title: "Business",
      icon: <LocationCityIcon />,
      category: "Business",
      type: "Delete",
    },
    {
      title: "Legal",
      icon: <GavelIcon />,
      category: "Legal",
      type: "Delete",
    },
    {
      title: "Side Hustle",
      icon: <AddTaskIcon />,
      category: "SideHustle",
      type: "Add",
    },
    {
      title: "Everything Else",
      icon: <AllInclusiveIcon />,
      category: "EverythingElse",
      type: "Delete",
    },
    {
      title: "Checking",
      icon: <AccountBalanceIcon />,
      category: "Checking",
      type: "Add",
    },
    {
      title: "Credit Cards",
      icon: <CreditCardOutlinedIcon />,
      category: "CreditCards",
      type: "Add",
    },
    {
      title: "Net Cash",
      icon: <LocalAtmOutlinedIcon />,
      category: "NetCash",
      type: "Add",
    },
    {
      title: "Savings",
      icon: <SavingsOutlinedIcon />,
      category: "Savings",
      type: "Add",
    },
    {
      title: "Investments",
      icon: <SignalCellularAltOutlinedIcon />,
      category: "Investments",
      type: "Add",
    },

    {
      title: "Everything Else",
      icon: <AllInclusiveIcon />,
      category: "EverythingElse",
      type: "Add",
    },
  ];

  if (isLoading) {
    return <Loading />;
  } else
    return (
      <>
        <div className="px-5 mob-scr-padd">
          <h1 className="modal-title text-center text-primary mt-4 mob-h1" style={{ textTransform: 'uppercase' }}>view budget</h1>
          <div style={{ width: '127px' }}>
            <div class="back_btn mob-back-btn">
              <Link to={-1} class="headerBtn" style={{ marginTop: '-47px' }}>
                <ion-icon
                  name="chevron-back-outline"
                  role="img"
                  class="md hydrated"
                  aria-label="chevron back outline"
                ></ion-icon>
                <h3> Go Back </h3>
              </Link>
            </div>

          </div>
          <div id="appCapsule" className="mt-5  p-2 pt-5 mob-appCapsule">
            <div className="row">
              <h1 className="my-4 pt-4 mob-h1">{filteredData[0]?.title}</h1>
              <div className="col-md-4 col-lg-3 col-12">
                <div className="card icon-box align-center left-box">
                  <div className="card w-100 p-2 small_box_left">
                    <span style={{ color: '#bdc2fe' }}>
                      Left to Spend  </span>
                    <h1 className="pt-4 mt-4 mb-0 text-white">
                      {currencyFormat(
                        income +
                        hustle +
                        cashAndChecks +
                        everythingElse -
                        parseInt(
                          spendingTotal(allData, today)
                        )
                      )}
                    </h1>

                  </div>
                  <div className="circle_meter">
                    <CircularProgressbar
                      value={
                        (transactionTotal(data) /
                          (income + hustle + cashAndChecks + everythingElse)) *
                        100
                      }
                      text={"$" + transactionTotal(data)}
                    />
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="col-md-8 col-lg-9 col-12">
                <div className="space-between">
                  <h3 className="text-primary">
                    <select
                      className="text-primary"
                      style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        fontWeight: "500",
                        backgroundColor: "transparent",
                      }}
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                    >
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="This Week">This Week</option>
                      <option value="Last Week">Last Week</option>
                      <option value="Past Three Months">
                        Past Three Months
                      </option>
                      <option value="This Year">This Year</option>
                      <option value="Last Year">Last Year</option>
                      <option value="Month Name">Month Name</option>
                    </select>
                  </h3>
                  <h3 className="text-primary">
                    <select
                      className="text-primary"
                      style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        fontWeight: "500",
                        backgroundColor: "transparent",
                      }}
                      value={sort}
                      onChange={(e) => setSort(e.target.value)}
                    >
                      <option value="Low to High">Low to High</option>
                      <option value="High to Low">High to Low</option>
                      <option value="Recent First">Recent First</option>
                      <option value="Old First">Old First</option>
                    </select>
                  </h3>
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        {data.length === 0 ? (
                          <th scope="col">No Data Found</th>
                        ) : (
                          <>
                            <th scope="col">Date</th>
                            <th scope="col">Description</th>
                            <th scope="col" style={{ textAlign: "center" }}>Frequency</th>
                            <th scope="col" className="text-end">
                              Amount
                            </th>
                            <th scope="col" className="text-end">
                              Actions
                            </th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {data.length != 0 ? (
                        loading ? (
                          <span className="text-center">
                            <CircularProgress />{" "}
                          </span>
                        ) : (
                          sortedData.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td scope="row">
                                  {moment(item.createdAt).format(
                                    "MMMM Do YYYY"
                                  )}{" "}
                                  <br />
                                  {moment(item.createdAt).format("h:mm:ss A")}
                                </td>
                                <td
                                  scope="row"
                                >
                                  {item.description}
                                </td>
                                <td align="center"
                                  scope="row"
                                >
                                  {item.isRecurring ? recuringList[item.frequency] : "-"}
                                </td>
                                <td
                                  scope="row"
                                  className="text-end text-primary"
                                  style={{ paddingTop: "25px", fontSize: '16px', fontWeight: '500' }}
                                >
                                  {currencyFormat(parseInt(item.amount))}
                                </td>
                                <td className="text-end text-primary">
                                  <EditModel
                                    id={item.id}
                                    title={item.subCategory}
                                    value={item.amount}
                                    type={item.type}
                                    description={item?.description}
                                    frequency={item?.frequency}
                                    isRecurring={item?.isRecurring}
                                  />
                                  <DeleteModel
                                    id={item.id}
                                    title={item.subCategory}
                                  />
                                  <IconButton
                                    data-bs-toggle="modal"
                                    data-bs-target={"#mod" + item.id}
                                  >
                                    <EditRoundedIcon />
                                  </IconButton>
                                  <IconButton
                                    data-bs-toggle="modal"
                                    data-bs-target={"#delMod" + item.id}
                                  >
                                    <DeleteRoundedIcon />
                                  </IconButton>
                                </td>
                              </tr>
                            );
                          })
                        )
                      ) : (
                        <tr scope="row">
                          <td>No history for this time priod</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Header />
            <div className="icon-box history-icon bg-primary-dark">
                {filteredData[0]?.icon}
            </div>
            <div id="appCapsule" className="mt-5  p-2 pt-5">
                <h1 className="mt-5 pt-5 ml-3">{filteredData[0]?.title}</h1>
                <div className="card space-between p-2 align-center">
                    <div style={{ width: 80, height: 80 }}>
                        <CircularProgressbar value={transactionTotal(data) / (income + hustle + cashAndChecks + everythingElse) * 100} text={"$" + transactionTotal(data)} />
                    </div>
                    <span>Left to Spend <br /><h1 className="p-0 m-0">{currencyFormat(income + hustle + cashAndChecks + everythingElse - parseInt(spendingTotal(allData, today)))}</h1></span>
                    <div></div>
                </div>
                <div className="mt-4">
                    <div className="space-between">
                        <h3 className="text-primary">
                            <select className="text-primary" style={{ width: '100%', border: 'none', outline: 'none', fontWeight: '500', backgroundColor: 'transparent' }} value={duration} onChange={(e) => setDuration(e.target.value)}>
                                <option value="This Month">This Month</option>
                                <option value="Last Month">Last Month</option>
                                <option value="This Week">This Week</option>
                                <option value="Last Week">Last Week</option>
                                <option value="Past Three Months">Past Three Months</option>
                                <option value="This Year">This Year</option>
                                <option value="Last Year">Last Year</option>
                                <option value="Month Name">Month Name</option>
                            </select>
                        </h3>
                        <h3 className="text-primary">
                            <select className="text-primary" style={{ width: '100%', border: 'none', outline: 'none', fontWeight: '500', backgroundColor: 'transparent' }} value={sort} onChange={(e) => setSort(e.target.value)}>
                                <option value="Low to High">Low to High</option>
                                <option value="High to Low">High to Low</option>
                                <option value="Recent First">Recent First</option>
                                <option value="Old First">Old First</option>
                            </select>
                        </h3>
                    </div>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    {
                                        data.length === 0 ?
                                            <th scope="col">No Data Found</th>
                                            :
                                            <>
                                                <th scope="col">Date</th>
                                                <th scope="col" className="text-end">Amount</th>
                                                <th scope="col" className="text-end">Actions</th>
                                            </>
                                    }
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    data.length != 0 ?
                                        loading ? <span className="text-center"><CircularProgress /> </span> :
                                            sortedData.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td scope="row">
                                                            {moment(item.createdAt).format("MMMM Do YYYY")} <br />
                                                            {moment(item.createdAt).format("h:mm:ss A")}
                                                        </td>
                                                        <td scope="row" className="text-end text-primary" style={{ paddingTop: '25px' }}>{currencyFormat(parseInt(item.amount))}</td>
                                                        <td className="text-end text-primary">
                                                            <EditModel id={item.id} title={item.subCategory} value={item.amount} type={item.type} />
                                                            <DeleteModel id={item.id} title={item.subCategory} />
                                                            <IconButton data-bs-toggle="modal" data-bs-target={"#mod" + item.id}>
                                                                <EditRoundedIcon />
                                                            </IconButton>
                                                            <IconButton data-bs-toggle="modal" data-bs-target={"#delMod" + item.id}>
                                                                <DeleteRoundedIcon />
                                                            </IconButton>
                                                        </td>
                                                    </tr>)
                                            })
                                        :
                                        <tr scope="row">
                                            <td>No history for this time priod</td>
                                        </tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
      </>
    );
}

export default BillHistory;
