import React, { useState } from "react";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getAssignments } from "../_api/skill-assignments";
import { useQuery } from 'react-query'
import { convertToEmbedUrl } from "../embedUtils";
import FileViewer from "../FileViewer";
import PdfViewer from "../PdfViewer";

export default function SkillAssignments({ userID, partName, open, setOpen }) {
  const [showSkill, setShowSkill] = useState(false)
  const [framLink, setFramLink] = useState("")
  const [isLoaded, setIsLoaded] = useState(false)
  const [content, setContent] = useState({})
  const { isLoading, data } = useQuery(["get-skill-assignments", partName], () => getAssignments(partName))


  return (
    <>
      <Dialog
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
        open={open}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {showSkill ? (
            <Button
              sx={{ textTransform: "none", color: "#616ded" }}
              startIcon={<ArrowBackIosIcon />}
              onClick={() => setShowSkill(false)}
            >
              Back
            </Button>
          ) : (
            <Typography variant="h6">Skill Assignments</Typography>
          )}
          <IconButton onClick={() => setOpen(false)}>
            <ClearRoundedIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {!showSkill ? (
            <TableContainer
              sx={{ overflowX: "hidden", overflowY: "auto", height: "65vh" }}
            >
              <Table stickyHeader aria-label="sticky table">
                {!data?.length && !isLoading ? (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      No Assignment Found!
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                )}

                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  data?.map((assignment, ind) => {
                    return (
                      <TableBody>
                        <TableRow hover>
                          <TableCell>{assignment?.assignmentName}</TableCell>
                          <TableCell align="right">
                            <IconButton
                              onClick={() => {
                                setShowSkill(true);
                                setFramLink(
                                  assignment.iframeLink + "?userID=" + userID
                                );
                                setIsLoaded(false);
                                setContent(assignment);
                              }}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })
                )}
              </Table>
            </TableContainer>
          ) : content?.type === "customForm" ? (
            <div className="px-5" style={{ minHeight: "450px" }}>
              <h1 className="text-center my-4">{content?.customFormName}</h1>
              <div className="text-center my-4">
                {isImage(content?.customFormImage) ? (
                  <div
                    style={{
                      width: "80%",
                      height: "475px",
                      margin: "0 auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={content?.customFormImage}
                      alt="skill-assignment-img"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ) : getExtension(content?.customFormImage) === "mp4" ? (
                  <video
                    src={content?.customFormImage}
                    controls
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      maxWidth: "80%",
                      height: "475px",
                    }}
                  />
                ) : getExtension(content?.customFormImage) === "pdf" ? (
                  <PdfViewer
                    pdfUrl={content?.customFormImage}
                    height="475px"
                  />
                ) : (
                  <FileViewer
                    fileUrl={convertToEmbedUrl(content?.customFormImage)}
                    height="475px"
                  />
                )}
              </div>
              <span
                dangerouslySetInnerHTML={{
                  __html: content?.customFormDescription,
                }}
              />
            </div>
          ) : (
            <>
              <iframe
                onLoad={() => setIsLoaded(true)}
                allowFullScreen="true"
                src={framLink}
                width="100%"
                height={"65vh"}
                style={{ height: '65vh' }}
              ></iframe>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

const getExtension = (file) => {
  let splitedEl = file.split(".");
  return splitedEl[splitedEl.length - 1];
};
const isImage = (file) => {
  let extension = getExtension(file);
  return extension === "jpg" || extension === "jpeg" || extension === "png" || extension === "gif";
};
