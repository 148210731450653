import React, { useState, useEffect } from "react";
import Header from "./Header";
import ValueChangeModal from "./ValueChangeModal";
import AddBudgetModel from "./AddBudgetModel";
import Category from "./Category";
import BarChart from "../net-worth/BarChart";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import FlatwareIcon from "@mui/icons-material/Flatware";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import SchoolIcon from "@mui/icons-material/School";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DeckIcon from "@mui/icons-material/Deck";
import MoneyIcon from "@mui/icons-material/Money";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import PetsIcon from "@mui/icons-material/Pets";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import BiotechIcon from "@mui/icons-material/Biotech";
import PercentIcon from "@mui/icons-material/Percent";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import GavelIcon from "@mui/icons-material/Gavel";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import Checkbox from "@mui/material/Checkbox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addNewBudgetEntry,
  deleteBudgetEntry,
  getAllData,
  getFinancialGoals,
  updateBudgetEntry,
} from "../_api/index";
import { budgetHistory, incomeTotal } from "../_budget-functionality/index";
import SelectAccountModal from "./SelectAccountModal";
import {
  MonthlyAmount,
  currencyFormat,
  spendingTotal,
} from "../_budget-functionality/index";
import Loading from "../common/loading";
import moment from "moment";
import AddBudgetModal from "./AddBudgetModel";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import CardSection from "./CardSection";
import {
  CustomDropDown,
  CustomTextField,
} from "../financial-goals/CustomField";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import SubCategory from "./SubCategory";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomDialog from "./CustomDialog";
import MyChartComponent from "../../layouts/common/MyChartComponent";

let today = new Date().toISOString();

export default function ManageBudget(props) {
  const [openBudgetModal, setOpenBudgetModal] = useState(false);
  const [totalIncome, setTotalIncome] = useState(0);
  const queryClient = useQueryClient();

  const handleBudgetModal = () => {
    setOpenBudgetModal(true);
  };

  const handleCloseBudgetModal = () => {
    setOpenBudgetModal(false);
  };
  const { isLoadingFinancialGoals, data: financialGoals } = useQuery(
    "financial-goals",
    getFinancialGoals,
    {
      enabled: Boolean(sessionStorage.getItem("token")),
    }
  );

  const { isLoading, data: allData } = useQuery(
    "budgeting-calculators",
    getAllData,
    {
      enabled: Boolean(sessionStorage.getItem("token")),
    }
  );

  const {
    // isLoading: isLoading,
    // isSuccess: isSuccess,
    mutate: addAmount,
  } = useMutation(addNewBudgetEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries("budgeting-calculators");
      toast.dismiss();
      toast.success("Added Successfully")
      if (props?.handleClose) props?.handleClose();
    },
    onError: () => {
      toast.error("Something went wrong!");
      toast.dismiss();
    },
  });

  const {
    // isLoading: isLoading,
    // isSuccess: isSuccess,
    mutate: updateAmount,
  } = useMutation(updateBudgetEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries("budgeting-calculators");
      toast.dismiss();
      toast.success("Edited Successfully")
    },
    onError: () => {
      toast.error("Something went wrong!");
      toast.dismiss();
    },
  });

  const {
    // isLoading: isLoading,
    // isSuccess: isSuccess,
    mutate: delTransaction,
  } = useMutation(deleteBudgetEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries("budgeting-calculators");
      // TODO:We have to refetch Data later after delete 
      // singleSubCategoryHandler(singleTypeData)
      toast.dismiss();
      toast.success("Transaction deleted successfully");
      setIndex(1)

    },
  });

  useEffect(() => {
    setChart(false);
    if (!isLoading) {
      setTotalIncome(incomeTotal(allData, today));
      MonthlyAmount(
        "ManageBudget",
        allData,
        setIncome,
        "MonthlyIncome",
        setLMIncome,
        "Add"
      );
      MonthlyAmount(
        "ManageBudget",
        allData,
        setBill,
        "BillsAndUtilities",
        setLMBill,
        "Delete"
      );
      // MonthlyAmount("ManageBudget", allData, setHustle, "SideHustle", setLMHustle)
      categoryData.map((item) => {
        MonthlyAmount(
          "ManageBudget",
          allData,
          item.setValue,
          item.category,
          item.setLMValue,
          item.type
        );
      });
      setChart(true);
    }
    handleData();
  }, [allData]);

  let cardType = [
    {
      name: "Daily",
      value: "daily",
    },
    {
      name: "Weekly",
      value: "weekly",
    },
    {
      name: "Monthly",
      value: "monthly",
    },
    {
      name: "Yearly",
      value: "yearly",
    },
  ];

  const handleData = async () => {
    setTimeout(() => {
      let tagVal = document.getElementsByClassName(
        "recharts-text recharts-cartesian-axis-tick-value"
      );
      tagVal.forEach((nodes) => {
        nodes.childNodes.forEach((el) => {
          let val = el.innerHTML;
          if (Number(val))
            el.innerHTML = "$" + val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          if (val == 0)
            el.innerHTML = "$" + val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        });
      });
    }, 100);
  };

  // const [dismissModal, setDismissModal] = useState(false)
  const [income, setIncome] = useState(0);
  const [lmIncome, setLMIncome] = useState(0);
  const [chart, setChart] = useState(true);
  const [bill, setBill] = useState(0);
  const [lmBill, setLMBill] = useState(0);
  const [autoAndTransport, setAutoAndTransport] = useState(0);
  const [lmAutoAndTransport, setLMAutoAndTransport] = useState(0);
  const [cashAndChecks, setCashAndChecks] = useState(0);
  const [lmCashAndChecks, setLMCashAndChecks] = useState(0);
  const [diningAndDrinks, setDiningAndDrinks] = useState(0);
  const [lmDiningAndDrinks, setLMDiningAndDrinks] = useState(0);
  const [creditCardPayment, setCreditCardPayment] = useState(0);
  const [lmCreditCardPayment, setLMCreditCardPayment] = useState(0);
  const [education, setEducation] = useState(0);
  const [lmEducation, setLMEducation] = useState(0);
  const [charitableDonations, setCharitableDonations] = useState(0);
  const [lmCharitableDonations, setLMCharitableDonations] = useState(0);
  const [entertainmentAndRec, setEntertainmentAndRec] = useState(0);
  const [lmEntertainmentAndRec, setLMEntertainmentAndRec] = useState(0);
  const [familyCare, setFamilyCare] = useState(0);
  const [lmFamilyCare, setLMFamilyCare] = useState(0);
  const [fees, setFees] = useState(0);
  const [lmFees, setLMFees] = useState(0);
  const [gifts, setGifts] = useState(0);
  const [lmGifts, setLMGifts] = useState(0);
  const [groceries, setGroceries] = useState(0);
  const [lmGroceries, setLMGroceries] = useState(0);
  const [healthAdnWellness, setHealthAndWellnes] = useState(0);
  const [lmHealthAdnWellness, setLMHealthAndWellnes] = useState(0);
  const [homeAndGarden, setHomeAndGarden] = useState(0);
  const [lmHomeAndGarden, setLMHomeAndGarden] = useState(0);
  const [loanPayment, setLoanPayment] = useState(0);
  const [lmLoanPayment, setLMLoanPayment] = useState(0);
  const [medical, setMedical] = useState(0);
  const [lmMedical, setLMMedical] = useState(0);
  const [personalCare, setPersonalCare] = useState(0);
  const [lmPersonalCare, setLMPersonalCare] = useState(0);
  const [pets, setPets] = useState(0);
  const [lmPets, setLMPets] = useState(0);
  const [savingTransfer, setSavingTransfer] = useState(0);
  const [lmSavingTransfer, setLMSavingTransfer] = useState(0);
  const [shoping, setShoping] = useState(0);
  const [lmShoping, setLMShoping] = useState(0);
  const [softwareAndTech, setSoftwareAndTech] = useState(0);
  const [lmSoftwareAndTech, setLMSoftwareAndTech] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [lmTaxes, setLMTaxes] = useState(0);
  const [travelAndVacation, setTrevelAndVacaion] = useState(0);
  const [lmTravelAndVacation, setLMTrevelAndVacaion] = useState(0);
  const [business, setBusiness] = useState(0);
  const [lmBusiness, setLMBusiness] = useState(0);
  const [legal, setLegal] = useState(0);
  const [lmLegal, setLMLegal] = useState(0);
  const [everythingElse, setEverythingElse] = useState(0);
  const [everythingElseIncome, setEverythingElseIncome] = useState(0);
  const [temp, setTemp] = useState(0);
  const [lmEverythingElse, setLMEverythingElse] = useState(0);
  const [lmEverythingElseIncome, setLMEverythingElseIncome] = useState(0);
  const [checking, setChecking] = useState(0);
  const [lmChecking, setLMChecking] = useState(0);
  const [creditCards, setCreditCards] = useState(0);
  const [lmCreditCards, setLMCreditCards] = useState(0);
  const [netCash, setNetCash] = useState(0);
  const [lmNetCash, setLMNetCash] = useState(0);
  const [savings, setSavings] = useState(0);
  const [lmSavings, setLMSavings] = useState(0);
  const [investments, setInvestments] = useState(0);
  const [lmInvestments, setLMInvestments] = useState(0);
  const [hustle, setHustle] = useState(0);
  const [lmHustle, setLMHustle] = useState(0);
  const [toggle, setToggle] = useState(true);
  const [debunce, setDebunce] = useState(true);

  const [dialogType, setDialogType] = useState("Add");
  const [budgetTypeData, setBudgetTypeData] = useState(null);
  const [openBudgetAddModal, setOpenBudgetAddModal] = useState(false);
  const [singleSubCategoryData, setSingleSubCategoryData] = useState([]);
  const [singleSubCategoryDetailsData, setSingleSubCategoryDetailsData] =
    useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [index, setIndex] = useState(0);
  const [singleTypeData, setSingleTypeData]= useState([])

  useEffect(() => {
    setToggle(false);
    setTimeout(() => {
      setToggle(true);
    }, 50);
  }, [allData]);

  const formik = useFormik({
    initialValues: {
      amount: null,
      description: "",
      cardType: "monthly",
      recurring: false,
    },
    validationSchema: Yup.object({
      amount: Yup.number().required("Amount is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        id:isEdit ?singleSubCategoryDetailsData?.id :null,
        amount: parseInt(values.amount),
        mainCategory: "ManageBudget",
        subCategory: isEdit
          ? singleSubCategoryDetailsData?.subCategory
          : budgetTypeData?.category,
        type: isEdit
          ? singleSubCategoryDetailsData?.type
          : budgetTypeData?.type,
        description: values.description,
        isRecurring: !!values?.recurring,
        frequency: values.recurring ? values.cardType : "",
      };
      try {
        console.log(data, "data");
        console.log(
          singleSubCategoryDetailsData,
          "  singleSubCategoryDetailsData"
        );

        toast.loading(isEdit ? "Updating..." : "Adding...");
        if (isEdit) {
          updateAmount(data);
          // toast.dismiss();
          // toast.success("Added Successfully");
        } else {
          addAmount(data);
          // toast.dismiss();
          // toast.success("Amount Edited Successfully");
        }
        setOpenBudgetAddModal(false);
        resetForm();
      } catch (error) {
        console.log(error);
      } finally {
        setIsEdit(false);
      }
    },
  });

  useEffect(() => {
    isEdit
      ? formik.setFieldValue("amount", singleSubCategoryDetailsData?.amount)
      : formik.setFieldValue("amount", 0);
    isEdit
      ? formik.setFieldValue(
          "description",
          singleSubCategoryDetailsData?.description
        )
      : formik.setFieldValue("description", "");
    isEdit
      ? formik.setFieldValue(
          "recurring",
          singleSubCategoryDetailsData?.recurring
        )
      : formik.setFieldValue("recurring", false);
    isEdit
      ? formik.setFieldValue("cardType", singleSubCategoryDetailsData?.cardType)
      : formik.setFieldValue("cardType", "monthly");
  }, [isEdit]);

  const handleIncrement = () => {
    const newValue = formik.values.amount
      ? parseInt(formik.values.amount) + 1
      : 1;
    formik.setFieldValue("amount", newValue);
  };

  const handleDecrement = () => {
    const newValue =
      formik.values.amount > 1 ? parseInt(formik.values.amount) - 1 : 0;
    formik.setFieldValue("amount", newValue);
  };

  const singleSubCategoryHandler = (data) => {
    setSingleTypeData(data)
    setOpenDialog(true);
    setIndex(1);
    setSingleSubCategoryData(
      budgetHistory(
        data?.category,
        allData,
        "ManageBudget",
        // selectDate(duration),
        // selectCompareDate(duration),
        data?.type
      )
    );
  };

  const singleSubCategoryDetailsHandler = (data) => {
    setOpenDialog(true);
    setIndex(2);
    setSingleSubCategoryDetailsData(data);
  };

  const categoryData = [
    {
      title: "Monthly Income",
      modalId: "monthlyIncome",
      icon: CreditCardIcon,
      color: "text-primary-lite",
      value: income,
      setValue: setIncome,
      lmValue: lmIncome,
      setLMValue: setLMIncome,
      // historyLink: "/bill-history",
      // linkText: "View Transactions",
      category: "MonthlyIncome",
      type: "Add",
    },

    {
      title: "Bills & Utilities",
      modalId: "BillsAndUtilities",
      icon: FileCopyIcon,
      color: "text-primary-lite",
      value: bill,
      setValue: setBill,
      lmValue: lmBill,
      setLMValue: setLMBill,
      // historyLink: "/bill-history",
      // linkText: "View Transactions",
      category: "BillsAndUtilities",
      type: "Delete",
    },
    {
      title: "Auto & Transport",
      modalId: "changeAutoAndTransport",
      icon: DirectionsCarIcon,
      color: "text-primary-lite",
      value: autoAndTransport,
      setValue: setAutoAndTransport,
      lmValue: lmAutoAndTransport,
      setLMValue: setLMAutoAndTransport,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "AutoAndTransport",
      type: "Delete",
    },
    {
      title: "Cash & Checks",
      modalId: "changeCashAndChecks",
      icon: CreditCardIcon,
      color: "text-success",
      value: cashAndChecks,
      lmValue: lmCashAndChecks,
      setValue: setCashAndChecks,
      setLMValue: setLMCashAndChecks,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "CashAndCheck",
      type: "Add",
    },
    {
      title: "Dinning & Drinks",
      modalId: "changeDiningAndDrinks",
      icon: FlatwareIcon,
      color: "text-primary-dark",
      value: diningAndDrinks,
      lmValue: lmDiningAndDrinks,
      setValue: setDiningAndDrinks,
      setLMValue: setLMDiningAndDrinks,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "DinningAndDrinks",
      type: "Delete",
    },
    {
      title: "Credit Card Payment",
      modalId: "changeCreditCardPayment",
      icon: CreditScoreIcon,
      color: "text-primary-lite",
      value: creditCardPayment,
      lmValue: lmCreditCardPayment,
      setValue: setCreditCardPayment,
      setLMValue: setLMCreditCardPayment,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "CreditCardPayment",
      type: "Delete",
    },
    {
      title: "Education",
      modalId: "changeEducation",
      icon: SchoolIcon,
      color: "text-danger",
      value: education,
      lmValue: lmEducation,
      setValue: setEducation,
      setLMValue: setLMEducation,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Education",
      type: "Delete",
    },
    {
      title: "Charitable Donations",
      modalId: "changeCharitableDonations",
      icon: VolunteerActivismIcon,
      color: "text-success-lite",
      value: charitableDonations,
      lmValue: lmCharitableDonations,
      setValue: setCharitableDonations,
      setLMValue: setLMCharitableDonations,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "CharitableDonations",
      type: "Delete",
    },
    {
      title: "Entertainment and Rec.",
      modalId: "changeEntertainment",
      icon: SportsFootballIcon,
      color: "text-warning-dark",
      value: entertainmentAndRec,
      lmValue: lmEntertainmentAndRec,
      setValue: setEntertainmentAndRec,
      setLMValue: setLMEntertainmentAndRec,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Entertainment",
      type: "Delete",
    },
    {
      title: "Family Care",
      modalId: "changeFamilyCare",
      icon: FavoriteBorderIcon,
      color: "text-danger",
      value: familyCare,
      lmValue: lmFamilyCare,
      setValue: setFamilyCare,
      setLMValue: setLMFamilyCare,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "FamilyCare",
      type: "Delete",
    },
    {
      title: "Fees",
      modalId: "changeFees",
      icon: MonetizationOnIcon,
      color: "text-danger-dark",
      value: fees,
      lmValue: lmFees,
      setValue: setFees,
      setLMValue: setLMFees,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Fees",
      type: "Delete",
    },
    {
      title: "Gifts",
      modalId: "changeGifts",
      icon: CardGiftcardIcon,
      color: "text-primary-dark",
      value: gifts,
      lmValue: lmGifts,
      setValue: setGifts,
      setLMValue: setLMGifts,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Gifts",
      type: "Delete",
    },
    {
      title: "Groceries",
      modalId: "changeGroceries",
      icon: ShoppingCartCheckoutIcon,
      color: "text-danger",
      value: groceries,
      lmValue: lmGroceries,
      setValue: setGroceries,
      setLMValue: setLMGroceries,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Groceries",
      type: "Delete",
    },
    {
      title: "Health & Wellness",
      modalId: "changeHealthWellness",
      icon: DirectionsRunIcon,
      color: "text-primary-lite",
      value: healthAdnWellness,
      lmValue: lmHealthAdnWellness,
      setValue: setHealthAndWellnes,
      setLMValue: setLMHealthAndWellnes,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "HealthAndWellness",
      type: "Delete",
    },
    {
      title: "Home & Garden",
      modalId: "changeHomeGarden",
      icon: DeckIcon,
      color: "text-success-lite",
      value: homeAndGarden,
      lmValue: lmHomeAndGarden,
      setValue: setHomeAndGarden,
      setLMValue: setLMHomeAndGarden,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "HomeAndGarden",
      type: "Delete",
    },
    {
      title: "Loan Payment",
      modalId: "changeLoanPayment",
      icon: MoneyIcon,
      color: "text-primary",
      value: loanPayment,
      lmValue: lmLoanPayment,
      setValue: setLoanPayment,
      setLMValue: setLMLoanPayment,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "LoanPayment",
      type: "Delete",
    },
    {
      title: "Medical",
      modalId: "changeMedical",
      icon: MedicalServicesIcon,
      color: "text-primary-dark",
      value: medical,
      lmValue: lmMedical,
      setValue: setMedical,
      setLMValue: setLMMedical,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Medical",
      type: "Delete",
    },
    {
      title: "Personal Care",
      modalId: "changePersonalCare",
      icon: SelfImprovementIcon,
      color: "text-danger-lite",
      value: personalCare,
      lmValue: lmPersonalCare,
      setValue: setPersonalCare,
      setLMValue: setLMPersonalCare,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "PersonalCare",
      type: "Delete",
    },
    {
      title: "Pets",
      modalId: "changePets",
      icon: PetsIcon,
      color: "text-primary-lite",
      value: pets,
      lmValue: lmPets,
      setValue: setPets,
      setLMValue: setLMPets,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Pets",
      type: "Delete",
    },
    {
      title: "Saving Transfers",
      modalId: "changeSavingTransfer",
      icon: TransferWithinAStationIcon,
      color: "text-success-lite",
      value: savingTransfer,
      lmValue: lmSavingTransfer,
      setValue: setSavingTransfer,
      setLMValue: setLMSavingTransfer,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "SavingTransfers",
      type: "Delete",
    },
    {
      title: "Shopping",
      modalId: "changeShoping",
      icon: ShoppingBagIcon,
      color: "text-warning",
      value: shoping,
      lmValue: lmShoping,
      setValue: setShoping,
      setLMValue: setLMShoping,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Shopping",
      type: "Delete",
    },
    {
      title: "Software & Tech",
      modalId: "changeTech",
      icon: BiotechIcon,
      color: "text-primary-dark",
      value: softwareAndTech,
      lmValue: lmSoftwareAndTech,
      setValue: setSoftwareAndTech,
      setLMValue: setLMSoftwareAndTech,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "SoftwareAndTech",
      type: "Delete",
    },
    {
      title: "Taxes",
      modalId: "changeTex",
      icon: PercentIcon,
      color: "text-dark",
      value: taxes,
      lmValue: lmTaxes,
      setValue: setTaxes,
      setLMValue: setLMTaxes,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Taxes",
      type: "Delete",
    },
    {
      title: "Travel & Vacation",
      modalId: "changeTravel",
      icon: TravelExploreIcon,
      color: "text-warning-dark",
      value: travelAndVacation,
      lmValue: lmTravelAndVacation,
      setValue: setTrevelAndVacaion,
      setLMValue: setLMTrevelAndVacaion,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "TravelAndVacation",
      type: "Delete",
    },
    {
      title: "Business",
      modalId: "changeBusiness",
      icon: LocationCityIcon,
      color: "text-success-dark",
      value: business,
      lmValue: lmBusiness,
      setValue: setBusiness,
      setLMValue: setLMBusiness,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Business",
      type: "Delete",
    },
    {
      title: "Legal",
      modalId: "changeLegal",
      icon: GavelIcon,
      color: "text-danger-dark",
      value: legal,
      lmValue: lmLegal,
      setValue: setLegal,
      setLMValue: setLMLegal,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Legal",
      type: "Delete",
    },
    {
      title: "Everything Else",
      modalId: "changeEverythingElse",
      icon: AllInclusiveIcon,
      color: "text-dark",
      value: everythingElse,
      lmValue: lmEverythingElse,
      setValue: setEverythingElse,
      setLMValue: setLMEverythingElse,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "EverythingElse",
      type: "Delete",
    },
    {
      title: "Side Hustle",
      modalId: "sideHustle",
      icon: AddTaskIcon,
      color: "text-success",
      value: hustle,
      lmValue: lmHustle,
      setValue: setHustle,
      setLMValue: setLMHustle,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "SideHustle",
      type: "Add",
    },
    {
      title: "Checking",
      modalId: "changeChecking",
      icon: AccountBalanceIcon,
      color: "text-success",
      value: checking,
      lmValue: lmChecking,
      setValue: setChecking,
      setLMValue: setLMChecking,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Checking",
      type: "Add",
    },
    {
      title: "Credit Cards",
      modalId: "changeCreditCards",
      icon: CreditCardOutlinedIcon,
      color: "text-success",
      value: creditCards,
      lmValue: lmCreditCards,
      setValue: setCreditCards,
      setLMValue: setLMCreditCards,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "CreditCards",
      type: "Add",
    },
    {
      title: "Net Cash",
      modalId: "changeNetCash",
      icon: LocalAtmOutlinedIcon,
      color: "text-success",
      value: netCash,
      lmValue: lmNetCash,
      setValue: setNetCash,
      setLMValue: setLMNetCash,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "NetCash",
      type: "Add",
    },
    {
      title: "Savings",
      modalId: "changeSavings",
      icon: SavingsOutlinedIcon,
      color: "text-success",
      value: savings,
      lmValue: lmSavings,
      setValue: setSavings,
      setLMValue: setLMSavings,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Savings",
      type: "Add",
    },
    {
      title: "Investments",
      modalId: "changeInvestments",
      icon: SignalCellularAltOutlinedIcon,
      color: "text-success",
      value: investments,
      lmValue: lmInvestments,
      setValue: setInvestments,
      setLMValue: setLMInvestments,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "Investments",
      type: "Add",
    },
    {
      title: "Everything Else",
      modalId: "changeEverythingElseIncome",
      icon: AllInclusiveIcon,
      color: "text-dark",
      value: everythingElseIncome,
      lmValue: lmEverythingElseIncome,
      setValue: setEverythingElseIncome,
      setLMValue: setLMEverythingElseIncome,
      historyLink: "/bill-history",
      linkText: "View Transactions",
      category: "EverythingElse",
      type: "Add",
    },
  ];

  const theme = useTheme();

  const handleClose = () => {
    try {
      setDebunce(false);
      setTimeout(() => {
        setDebunce(true);
        window.document
          .getElementsByClassName("modal-backdrop fade show")
          .forEach((el) => {
            el.remove();
          });
        document.getElementsByTagName("body")[0].style = "";
      }, 500);
    } catch (error) {}
  };

  try {
    window.document
      .getElementsByClassName("modal-backdrop fade show")
      .forEach((el) => el.remove());
  } catch (error) {}

  const handleBudgetClick = (data) => {
    setBudgetTypeData(data);
    setOpenBudgetAddModal(true);
  };

  const incomeData = [];

  const dialogTitle =
    index === 0 ? (
      <>
        <Typography
          variant="h3"
          fontWeight="700"
          textAlign="center"
          color="text"
        >
          {dialogType === "Add" ? "Add Income" : "Add Expense"}
        </Typography>
        <Typography textAlign="center">
          Select A Spending Category Below To Create A Budget For It.
        </Typography>
        <Box position="absolute" right={10} top={10}>
          <IconButton aria-label="close" onClick={() => setOpenDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </>
    ) : index === 1 ? (
      <>
       <Typography
          variant="h3"
          fontWeight="700"
          textAlign="center"
          color="text"
        >
          {singleTypeData?.title}
        </Typography>
        <Box position="absolute" right={10} top={10}>
          <IconButton aria-label="close" onClick={() => setOpenDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </>
    ) : index === 2 ? (
      <></>
    ) : null;

  const dialogContent =
    index === 0 ? (
      <Box height="18rem" overflowY="auto">
        <Grid container spacing={{ xs: 0.5, md: 1 }}>
          {categoryData
            ?.filter((filteredData) => filteredData?.type === dialogType)
            .map((data, index) => (
              <Grid item key={index} xs={6}>
                <Box
                  onClick={() => handleBudgetClick(data)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: { xs: 0.3, md: 1 },
                    backgroundColor: theme.palette.common.background,
                    padding: "12px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  {data?.icon && (
                    <Box>
                      {<data.icon sx={{ color: theme.palette.common.text }} />}
                    </Box>
                  )}

                  <Typography variant={{ xs: "body2", md: "body1" }}>
                    {data.title}
                  </Typography>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
    ) : index === 1 ? (
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        height="18rem"
        overflow="auto"
        p
      >
        {console.log(singleSubCategoryData,'2222222222222222')}
        
        {singleSubCategoryData?.map((data, index) => (
          <SubCategory
            key={index}
            data={data}
            singleSubCategoryDetailsHandler={singleSubCategoryDetailsHandler}
          />
        ))}
      </Box>
    ) : index === 2 ? (
      <Box
        sx={{
          border: `1px solid ${theme.palette.common.disabled}`,
          borderRadius: "2rem",
          p: "1.75rem",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">
            {moment(singleSubCategoryDetailsData?.createdAt).format("LT")}
          </Typography>
          <Typography variant="h1">
            $ {singleSubCategoryDetailsData?.amount}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor={theme.palette.common.background}
          p={1}
          my={1}
          borderRadius={1}
        >
          <Typography variant="body1">Date:</Typography>
          <Typography variant="body1" fontWeight="700">
            {moment(singleSubCategoryDetailsData?.createdAt).format("ll")}
          </Typography>
        </Box>
        <Typography variant="body1" fontWeight="600">
          Description:
        </Typography>
        <Box
          sx={{
            bgcolor: theme.palette.common.background,
            p: 1,
            borderRadius: 1,
            height: "6rem",
            overflowY: "auto",
          }}
        >
          <Typography variant="body2">
            {singleSubCategoryDetailsData?.description}
          </Typography>
        </Box>
      </Box>
    ) : null;

  const dialogAction =
    index === 0 ? (
      <></>
    ) : index === 1 ? (
      <></>
    ) : index === 2 ? (
      <Box display="flex" justifyContent="space-between" p={2} width="100%">
        <Box>
          <Button
            variant="text"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => {
              setIndex(1);
              setIsEdit(false);
            }}
          >
            Back
          </Button>
        </Box>
        <Box display="flex" gap={1}>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => {
              setIsEdit(true);
              setOpenBudgetAddModal(true);
            }}
            disableRipple
            sx={{ minWidth: "130px" }}
          >
            Edit
          </Button>
          {console.log(singleSubCategoryDetailsData,'1111111111111')}
          
          <Button
            variant="contained"
            startIcon={<DeleteIcon />}
            disableRipple
            onClick={() => {
              toast.loading('Deleting...')
              const data = {
                id: singleSubCategoryDetailsData?.id,
              };
              delTransaction(data);
              setOpenDialog(false)
            }}
            sx={{
              color: theme.palette.common.white,
              bgcolor: theme.palette.error.main,
              "&:hover": {
                bgcolor: theme.palette.error.dark,
              },
              minWidth: "130px",
            }}
          >
            Delete
          </Button>
        </Box>
      </Box>
    ) : null;

  if (isLoading) {
    return <Loading />;
  } else
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7}>
            <Card sx={{ height: "100%", p: { xs: "14px", md: "28px" } }}>
              {/* {toggle && (
                <BarChart
                  isBudget={true}
                  height={350}
                  assetDebt={true}
                  top={10}
                  data={{
                    allData: allData,
                    goals: financialGoals?.data,
                  }}
                />
              )} */}
              <MyChartComponent data={allData} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={5} gap={2}>
            <Box display="flex" flexDirection="column" gap={1} height="100%">
              <CardSection
                title="Income"
                buttonText="Add Income"
                onButtonClick={() => {
                  setOpenDialog(true);
                  setIndex(0);
                  setDialogType("Add");
                }}
                content="You have $ 0 remaining after expenses"
                categoryData={categoryData}
                type="Add"
                singleSubCategoryHandler={singleSubCategoryHandler}
              />
              <CardSection
                title="Expense"
                buttonText="Add Expense"
                onButtonClick={() => {
                  setOpenDialog(true);
                  setIndex(0);
                  setDialogType("Delete");
                }}
                content="You have not entered any Expense. Please enter an Expense by clicking 'Add Expense' button"
                categoryData={categoryData}
                type="Delete"
                singleSubCategoryHandler={singleSubCategoryHandler}
              />
            </Box>
          </Grid>
        </Grid>

        <CustomDialog
          openDialog={openDialog}
          dialogTitle={dialogTitle}
          dialogContent={dialogContent}
          dialogAction={dialogAction}
        />

        <Dialog
          open={openBudgetAddModal}
          fullWidth
          maxWidth="md"
          PaperProps={{
            sx: {
              p: { xs: "1rem", md: "2.5rem 4rem" },
            },
          }}
          sx={{
            "& .MuiPaper-root": {
              "@media (max-width:900px)": {
                bottom: 0,
                position: "absolute",
                borderRadius: "0px",
                margin: 0,
                width: "100%",
              },
            },
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>
              <Typography
                variant="h3"
                fontWeight="700"
                textAlign="center"
                color="text"
              >
                {budgetTypeData?.title}
              </Typography>

              <Box position="absolute" right={10} top={10}>
                <IconButton
                  aria-label="close"
                  onClick={() => setOpenBudgetAddModal(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </DialogTitle>

            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box>
                  <CustomTextField
                    name="description"
                    label={isEdit ? "Edit Description" : "Description"}
                    placeholder={isEdit ? "Edit Description" : "Description"}
                    multiline
                    value={formik.values.description}
                    rows={1}
                    onChange={formik.handleChange}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
                <Box display="flex" gap={0.5}>
                  <Box width={"100%"}>
                    <CustomTextField
                      name="amount"
                      label={isEdit ? "Edit Amount :" : "Amount"}
                      placeholder={isEdit ? "Edit Amount :" : "Amount"}
                      type="number"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ paddingLeft: "5px", paddingBottom: "10px" }}
                          >
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      gap: 0.2,
                      alignItems: "flex-end",
                    }}
                  >
                    <IconButton
                      disableRipple
                      sx={{
                        bgcolor: theme.palette.success.main,
                        color: theme.palette.common.white,
                        borderRadius: "8px",
                        width: "39px",
                        height: "39px",
                        "&:hover": {
                          bgcolor: theme.palette.success.dark,
                        },
                      }}
                      onClick={handleDecrement}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton
                      disableRipple
                      sx={{
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        borderRadius: "8px",
                        width: "39px",
                        height: "39px",
                        "&:hover": {
                          bgcolor: theme.palette.primary.dark,
                        },
                      }}
                      onClick={handleIncrement}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box>
                  <CustomDropDown
                    label="Select Type"
                    options={cardType}
                    disabled={!formik.values.recurring}
                    name="cardType"
                    value={formik.values.cardType}
                    onChange={formik.handleChange}
                  />
                </Box>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="recurring"
                        checked={formik.values.recurring}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Is It Recurring?"
                  />
                </FormGroup>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box p={2}>
                <Button variant="contained" type="submit">
                  Set Amount
                </Button>
              </Box>
            </DialogActions>
          </form>
        </Dialog>

        {/* old code */}
        {debunce && (
          <AddBudgetModel
            handleClose={handleClose}
            data={categoryData}
            income={income}
            bill={bill}
            open={!isLoading && openBudgetModal && openBudgetModal}
            closeModal={handleCloseBudgetModal}
          />
        )}
        <SelectAccountModal
          id="selectAccount"
          // title={item.title}
          // value={item.value}
          // setValue={item.setValue}
          // category={item.category}
          // type={item.type}
        />

        {/* <div id="appCapsule" className="mb-5 pt-7"> */}
        {/* <div className="container pt-5">
            {toggle && (
              <BarChart
                isBudget={true}
                height={350}
                assetDebt={true}
                top={10}
                data={{
                  allData: allData,
                  goals: financialGoals?.data,
                }}
              />
            )}
          </div>
          <div className="container mt-3">
            <div className="row">
              <div className="col-md-6">
                <div className="listview-title mt-1">INCOME</div>
                <div
                  className="card card-income"
                  style={{ overflowY: "hidden" }}
                >
                  <ul
                    className="listview image-listview inset"
                    style={{ boxShadow: "none", overflowY: "scroll" }}
                    id="style-3"
                  >
                    {income !== 0 && (
                      <>
                        <Category
                          category="MonthlyIncome"
                          title="Monthly Income"
                          money={income}
                          lmMoney={lmIncome}
                          icon={<CreditCardIcon />}
                          bg="text-success"
                          key="icomefieldKey"
                          type="Add"
                        />
                      </>
                    )}
                    {categoryData.map((item, index) => {
                      return (
                        item.value !== 0 &&
                        item.type === "Add" && (
                          <>
                            <Category
                              category={item.category}
                              title={item.title}
                              money={item.value}
                              lmMoney={item.lmValue}
                              icon={item.icon}
                              bg={item.color}
                              key={index}
                              type="Add"
                            />
                          </>
                        )
                      );
                    })}
                    {totalIncome !== 0 ? (
                      <div
                        style={{ backgroundColor: "#EDEDF5" }}
                        className="card-footer text-secondary"
                      >
                        You have{" "}
                        <b>
                          <span className="text-dark">
                            {currencyFormat(
                              totalIncome - spendingTotal(allData, today)
                            )}
                          </span>
                        </b>{" "}
                        remaining after expenses
                      </div>
                    ) : (
                      <div className="card-footer">
                        You have not entered any Income. Please enter an Income
                        by clicking "Add Budget" button below
                      </div>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="listview-title mt-1">EXPENSES</div>
                <div
                  className="card card-income"
                  style={{ overflowY: "hidden" }}
                >
                  <ul
                    className="listview image-listview inset"
                    style={{ boxShadow: "none", overflowY: "scroll" }}
                    id="style-3"
                  >
                    {bill !== 0 && (
                      <>
                        <Category
                          category="BillsAndUtilities"
                          title="Bills & Utilities"
                          money={bill}
                          lmMoney={lmBill}
                          icon={<FileCopyIcon />}
                          bg="text-primary-dark"
                          key="billfieldKey"
                          type="Delete"
                        />
                      </>
                    )}
                    {categoryData.map((item, index) => {
                      return (
                        item.value !== 0 &&
                        item.type === "Delete" && (
                          <>
                            <Category
                              category={item.category}
                              title={item.title}
                              money={item.value}
                              lmMoney={item.lmValue}
                              icon={item.icon}
                              bg={item.color}
                              key={index}
                              type="Delete"
                            />
                          </>
                        )
                      );
                    })}
                    {categoryData.filter((item) => {
                      return item.value !== 0;
                    }).length === 0 && (
                      <div className="card-footer">
                        You have not entered any Expense. Please enter an
                        Expense by clicking "Add Budget" button below
                      </div>
                    )}
                  </ul>
                </div>
              </div>
              <div className="row pe-0">
                <div className="col-12 p-0">
                  <button
                    className="btn btn_budget text-white bg-primary btn-block"
                    onClick={handleBudgetModal}
                  >
                    <AddIcon className="me-2" />
                    Add Budget
                  </button>
                </div>
              </div>
              <div className="row pe-0">
                <div className="col-12 p-0">
                  <button
                    className="btn btn_budget  text-primary btn-block"
                    data-bs-toggle="modal"
                    data-bs-target="#selectAccount"
                    style={{ border: "2px solid" }}
                    // disabled
                  >
                    <InsertLinkIcon className="me-2" />
                    Connect Financial Accounts
                  </button>
                  <div></div>
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className="row">
                    <div className="col-md-6">
                    <div className="listview-title mt-1">INCOME</div>
                <ul className="listview image-listview inset mb-2" >
                    {
                        income !== 0 && <>
                            <Category category="MonthlyIncome" title="Monthly Income (Income)"
                               
                                money={income} lmMoney={lmIncome} icon={<CreditCardIcon />} bg="text-success" key="icomefieldKey" type="Add" />

                            
                        </>
                    }
                    {
                        categoryData.map((item, index) => {
                            return (item.value !== 0 && item.type === "Add" && <>
                               
                                <Category category={item.category} title={item.title}
                                   
                                    money={item.value} lmMoney={item.lmValue} icon={item.icon} bg={item.color} key={index} type="Add" />
                            </>)
                        })
                    }
                    {
                        income !== 0 || cashAndChecks !== 0 || hustle !== 0 && everythingElseIncome !== 0 ?
                            <div style={{ backgroundColor: "#EDEDF5" }} className="card-footer text-secondary">You have <b><span className="text-dark">{currencyFormat(income + hustle + cashAndChecks + everythingElseIncome - spendingTotal(allData, today))}</span></b> remaining after expenses</div>
                            : <div className="card-footer">You have not entered any Income. Please enter an Income by clicking "Add Budget" button below</div>
                    }
                </ul>
                    </div>
                    <div className="col-md-6">
                    <div className="listview-title mt-1">EXPENSES</div>
                <ul className="listview image-listview inset" >
                    {
                        bill !== 0 && <>
                            <Category category="BillsAndUtilities" title="Bills & Utilities"
                              
                                money={bill} lmMoney={lmBill} icon={<FileCopyIcon />} bg="text-primary-dark" key="billfieldKey" type="Delete" />

                            
                        </>
                    }
                    {
                        categoryData.map((item, index) => {
                            return (item.value !== 0 && item.type === "Delete" && <>
                               
                                <Category category={item.category} title={item.title}
                               
                                    money={item.value} lmMoney={item.lmValue} icon={item.icon} bg={item.color} key={index} type="Delete" />
                            </>)
                        })
                    }
                    {
                        categoryData.filter(item => { return (item.value !== 0) }).length === 0 && <div className="card-footer">You have not entered any Expense.
                            Please enter an Expense by clicking "Add Budget" button below</div>
                    }
                    
                </ul>
                    </div>
                </div> */}

        {/* <div className="row m-3">
            <div className="col-12">
              <button
                className="btn  text-white bg-primary btn-block"
                data-bs-toggle="modal"
                data-bs-target="#addBudget"
              >
                Add Budget
              </button>
            </div>
          </div> */}
        {/* <Footer /> */}
        {/* </div> */}
      </>
    );
}

// const allData = [
//   {
//     id: 111,
//     name: null,
//     amount: 2000,
//     mainCategory: "ManageBudget",
//     subCategory: "MonthlyIncome",
//     type: "Add",
//     description: "Income",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:23:46.983Z",
//     updatedAt: "2024-08-23T12:23:46.983Z",
//   },
//   {
//     id: 12,
//     name: null,
//     amount: 2100,
//     mainCategory: "ManageBudget",
//     subCategory: "MonthlyIncome",
//     type: "Add",
//     description: "Income",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-24T12:23:46.983Z",
//     updatedAt: "2024-08-24T12:23:46.983Z",
//   },
//   {
//     id: 13,
//     name: null,
//     amount: 800,
//     mainCategory: "ManageBudget",
//     subCategory: "MonthlyIncome",
//     type: "Add",
//     description: "Income",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-22T12:23:46.983Z",
//     updatedAt: "2024-08-22T12:23:46.983Z",
//   },
//   {
//     id: 15,
//     name: null,
//     amount: 8000,
//     mainCategory: "ManageBudget",
//     subCategory: "MonthlyIncome",
//     type: "Add",
//     description: "Income",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-25T12:23:46.983Z",
//     updatedAt: "2024-08-25T12:23:46.983Z",
//   },
//   {
//     id: 2,
//     name: null,
//     amount: 3000,
//     mainCategory: "ManageBudget",
//     subCategory: "CashAndCheck",
//     type: "Add",
//     description: "adsfads ",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:23:55.254Z",
//     updatedAt: "2024-08-23T12:23:55.254Z",
//   },
//   {
//     id: 26,
//     name: null,
//     amount: 300,
//     mainCategory: "ManageBudget",
//     subCategory: "CashAndCheck",
//     type: "Add",
//     description: "adsfadsvcbxcbx ",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:23:55.254Z",
//     updatedAt: "2024-08-23T12:23:55.254Z",
//   },
//   {
//     id: 3,
//     name: null,
//     amount: 3000,
//     mainCategory: "ManageBudget",
//     subCategory: "Checking",
//     type: "Add",
//     description: "vdsfg",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:24:02.351Z",
//     updatedAt: "2024-08-23T12:24:02.351Z",
//   },
//   {
//     id: 4,
//     name: null,
//     amount: 4000,
//     mainCategory: "ManageBudget",
//     subCategory: "AutoAndTransport",
//     type: "Delete",
//     description: "sdfadsf",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:24:12.802Z",
//     updatedAt: "2024-08-23T12:24:12.802Z",
//   },
//   {
//     id: 5,
//     name: null,
//     amount: 1888,
//     mainCategory: "ManageBudget",
//     subCategory: "Education",
//     type: "Delete",
//     description: "cvbxfb",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:24:24.847Z",
//     updatedAt: "2024-08-23T12:24:24.847Z",
//   },
//   {
//     id: 6,
//     name: null,
//     amount: 5098,
//     mainCategory: "ManageBudget",
//     subCategory: "Fees",
//     type: "Delete",
//     description: "dfgdfg",
//     frequency: "",
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:24:47.871Z",
//     updatedAt: "2024-08-23T12:24:47.871Z",
//   },
//   {
//     id: 7,
//     name: "Cash",
//     amount: 399,
//     mainCategory: "NetWorth",
//     subCategory: "Cash",
//     type: "Add",
//     description: "hjfgkghjk",
//     frequency: null,
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:25:12.427Z",
//     updatedAt: "2024-08-23T12:25:12.427Z",
//   },
//   {
//     id: 8,
//     name: "Bank",
//     amount: 48887,
//     mainCategory: "NetWorth",
//     subCategory: "Bank",
//     type: "Add",
//     description: "hjfghj",
//     frequency: null,
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:25:19.502Z",
//     updatedAt: "2024-08-23T12:25:19.502Z",
//   },
//   {
//     id: 9,
//     name: "Savings",
//     amount: 565,
//     mainCategory: "NetWorth",
//     subCategory: "Savings",
//     type: "Add",
//     description: "6tyurty",
//     frequency: null,
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:25:25.702Z",
//     updatedAt: "2024-08-23T12:25:25.702Z",
//   },
//   {
//     id: 10,
//     name: "Credit Card",
//     amount: 56564,
//     mainCategory: "NetWorth",
//     subCategory: "CreditCard",
//     type: "Delete",
//     description: "dhfghd",
//     frequency: null,
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:25:35.859Z",
//     updatedAt: "2024-08-23T12:25:35.859Z",
//   },
//   {
//     id: 11,
//     name: "Student Loan",
//     amount: 455,
//     mainCategory: "NetWorth",
//     subCategory: "StudentLoan",
//     type: "Delete",
//     description: "ghdfg",
//     frequency: null,
//     isRecurring: false,
//     userId: 2,
//     createdAt: "2024-08-23T12:25:45.269Z",
//     updatedAt: "2024-08-23T12:25:45.269Z",
//   },
// ];
