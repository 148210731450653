import { Box, useTheme, Container } from '@mui/material';
import Header from './common/Header';
import Footer from './common/Footer';
import { useState } from 'react';
import SkillAssignment from './common/SkillAssignment';



const Layout = ({ children, user }) => {
    const theme = useTheme();
    const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(true);
    return (
        <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: theme.palette.common.background }}>
            <Header user={user} isDisclaimerVisible={isDisclaimerVisible} setIsDisclaimerVisible={setIsDisclaimerVisible} />
            <Container maxWidth="xl">
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        padding: 0,
                        marginTop: isDisclaimerVisible ? "180px" : '130px', // Height of the AppBar
                        marginBottom: '64px', // Height of the Footer
                    }}
                >
                    {children}
                </Box>
            </Container>
            <Footer />
            <SkillAssignment isDisclaimerVisible={isDisclaimerVisible} />
        </Box>
    );
};

export default Layout;
