
import Car_Loan_Interest from "./images/updated_images/car_loan_interest-min.jpg"
import Auto_Loan_Payment from "./images/updated_images/auto_loan_payment-min.jpg"
import Car_Loan_Amortization from "./images/updated_images/car_loan_amortization-min.PNG"
import How_Much_Car_Can_You_Afford from "./images/updated_images/how_much_car_can_you_afford-min.png"
import Cost_Of_Credit from "./images/updated_images/cost_of_credit-min.jpg"
import Do_I_Have_Too_Much_Debt from "./images/updated_images/do_i_have_too_much_debt-min.png"
import How_Long_Before_My_Credit_Card_PaidOff from "./images/updated_images/how_long_till_my_credit_card_paidoff-min.webp"
import How_Much_House_Can_You_Afford from "./images/updated_images/how_much_house_can_you_afford-min.jpg"
import How_Much_Will_My_Loan_Cost from "./images/updated_images/how_much_will_my_loan_cost-min.jpg"
import Paying_Off_A_Loan_Early from "./images/updated_images/paying_off_a_loan_early-min.jpg"
import Mortage_Payment from "./images/updated_images/mortage_payment-min.jpg"
import Back_To_School_Budget from "./images/updated_images/back_to_school_budget-min.jpg"
import Building_Education_Fund from "./images/updated_images/building_education_fund-min.jpg"
import Emergency_Fund from "./images/updated_images/emergency_fund-min.png"
import Lunch_Tracker from "./images/updated_images/lunch_tracker-min.jpg"
import Rework_Your_Budget from "./images/updated_images/rework_your_budget-min.jpg"
import Do_I_Have_Enough_Retirement_Money from "./images/updated_images/do_i_have_enough_money_for_retirement-min.jpg"
import Save_A_Million from "./images/updated_images/save_a_million-min.jpg"
import Saving_For_A_Goal from "./images/updated_images/saving_for_a_goal-min.jpg"
import Inflation_Impact_On_Retirement from "./images/updated_images/inflation_impact_on_retirement-min.webp"
import Entertainment_Planner from "./images/updated_images/entertainment_planner-min.png"
import Travel_Budgetting from "./images/updated_images/travel_budgeting-min.jpg"
import Tooth_Fairy from "./images/updated_images/tooth_fairy-min.jpg"
import My_Bonus_After_Taxes from "./images/updated_images/my_bonus_after_taxes-min.webp"
import Mortage_Amortization from "./images/updated_images/mortage_payments-min.png"
import Mortage_Interest from "./images/updated_images/extramortage-min.jpg"



import Auto from "./images/Auto01.jpg";
import Auto02 from "./images/Auto02.jpg";
import Auto03 from "./images/Auto03.jpg";
import Auto04 from "./images/Auto04.jpg";
import BudgetGoals01 from "./images/BudgetGoals01.jpg";
import BudgetGoals02 from "./images/Budget & Goals02.jpg";
import BudgetGoals03 from "./images/Budget & Goals03.jpg";
import BudgetGoals04 from "./images/Budget & Goals04.jpg";
import BudgetGoals05 from "./images/BudgetGoals05.jpg";
import BudgetGoals06 from "./images/Budget & Goals06.jpg";
import BudgetGoals07 from "./images/Budget & Goals07.jpg";
import BudgetGoals08 from "./images/Budget & Goals08.jpg";
import BudgetGoals09 from "./images/Budget & Goals09.jpg";
import BudgetGoals10 from "./images/Budget & Goals10.jpg";
import DebitCredit01 from "./images/Credit & Debt 01.jpg";
import DebitCredit02 from "./images/Credit & Debt 02.jpg";
import DebitCredit03 from "./images/Credit & Debt 03.jpg";
import DebitCredit04 from "./images/Credit & Debt 04.jpg";
import EducationCollege01 from "./images/Education & College 01.jpg";
import EducationCollege02 from "./images/Education & College 02.jpg";
import EducationCollege03 from "./images/Education & College 03.jpg";
import Loan01 from "./images/Loan 01.jpg";
import Loan02 from "./images/Loan 02.jpg";
import Loan03 from "./images/Loan 03.jpg";
import Loan04 from "./images/Loan 04.jpg";
import Loan05 from "./images/Loan05.jpg";
import Loan06 from "./images/Loan06.jpg";
import SavingsInvestment01 from "./images/Savings & Investment 01.jpg";
import SavingsInvestment02 from "./images/Savings & Investment 02.jpg";
import SavingsInvestment03 from "./images/Savings & Investment 03.jpg";
import SavingsInvestment04 from "./images/Savings & Investment 04.jpg";
import SavingsInvestment05 from "./images/Savings & Investment 05.jpg";
import SavingsInvestment06 from "./images/Savings & Investment 06.jpg";
import SavingsInvestment07 from "./images/Savings & Investment 07.jpg";
import Family02 from "./images/Family02.jpg";
import Family03 from "./images/Family03.jpg";
import Family04 from "./images/Family04.png";
import Family05 from "./images/Family05.jpg";
import Family06 from "./images/Family06.jpg";
import Family07 from "./images/Family07.jpg";
import Family08 from "./images/Family08.jpg";
import Career01 from "./images/career01.jpg";
import Career02 from "./images/career02.jpg";
import Career03 from "./images/career03.jpeg";
import Career04 from "./images/career04.jpg";
import Career05 from "./images/career05.jpg";
import Career06 from "./images/career06.jpg";
import Career07 from "./images/career07.jpg";
import Career08 from "./images/career08.jpg";
import Career09 from "./images/career09.jpg";
import Home01 from "./images/home01.jpg";
import Home02 from "./images/home02.jpg";
import Home03 from "./images/home03.jpg";
import Home04 from "./images/home04.jpg";
import Home05 from "./images/home05.jpg";
import Work01 from "./images/work01.jpeg";
import Work02 from "./images/work02.jpg";
import loading_iframe from "./images/loading_iframe.gif";
import DocumentAI from './images/Document AI.svg'
import HealthCare from './images/Health Care.svg'
import PracticeShopping from './images/Practice Shopping.svg'
import docAi from './images/docAi.svg'
import Dictionary from './images/Dictionary.svg'
import healthCare from './images/healthCare.svg'
import practiseShoping from './images/practiseShoping.svg'
import PayCheck from './images/paycheck.jpeg'

export {
  docAi,
  Dictionary,
  healthCare,
  practiseShoping,
  DocumentAI,
  HealthCare,
  PracticeShopping,
  loading_iframe,
  Car_Loan_Interest,
  Auto_Loan_Payment,
  Car_Loan_Amortization,
  How_Much_Car_Can_You_Afford,
  Cost_Of_Credit,
  Do_I_Have_Too_Much_Debt,
  How_Long_Before_My_Credit_Card_PaidOff,
  How_Much_Will_My_Loan_Cost,
  Paying_Off_A_Loan_Early,
  How_Much_House_Can_You_Afford,
  Mortage_Payment,
  Back_To_School_Budget,
  Building_Education_Fund,
  Emergency_Fund,
  Lunch_Tracker,
  Rework_Your_Budget,
  Do_I_Have_Enough_Retirement_Money,
  Save_A_Million,
  Saving_For_A_Goal,
  Inflation_Impact_On_Retirement,
  Entertainment_Planner,
  Travel_Budgetting,
  Tooth_Fairy,
  My_Bonus_After_Taxes,
  Mortage_Amortization,
  Mortage_Interest,
  Auto,
  Auto02,
  Auto03,
  Auto04,
  BudgetGoals01,
  BudgetGoals02,
  BudgetGoals03,
  BudgetGoals04,
  BudgetGoals05,
  BudgetGoals06,
  BudgetGoals07,
  BudgetGoals08,
  BudgetGoals09,
  BudgetGoals10,
  DebitCredit01,
  DebitCredit02,
  DebitCredit03,
  DebitCredit04,
  EducationCollege01,
  EducationCollege02,
  EducationCollege03,
  Loan01,
  Loan02,
  Loan03,
  Loan04,
  Loan05,
  Loan06,
  SavingsInvestment01,
  SavingsInvestment02,
  SavingsInvestment03,
  SavingsInvestment04,
  SavingsInvestment05,
  SavingsInvestment06,
  SavingsInvestment07,
  Family02,
  Family03,
  Family04,
  Family05,
  Family06,
  Family07,
  Family08,
  Career01,
  Career02,
  Career03,
  Career04,
  Career05,
  Career06,
  Career07,
  Career08,
  Career09,
  Home01,
  Home02,
  Home03,
  Home04,
  Home05,
  Work01,
  Work02,
  PayCheck,
};





