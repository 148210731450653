import React from 'react';
import ReactDOM from 'react-dom';
// import './assets/css/style.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './layout/App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    }
  }
});
ReactDOM.render(
  <BrowserRouter >
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
// serviceWorkerRegistration.register();
serviceWorkerRegistration.unregister();

