import React from 'react'
import { AppBar, Toolbar, IconButton, Box, styled, Container, Button, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import PodcastsRoundedIcon from '@mui/icons-material/PodcastsRounded';
import Sidebar from './Sidebar';
import Logo from '../../components/common/Logo';
import BrandLogo from '../../components/common/BrandLogo';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import CloseIcon from '@mui/icons-material/Close';
import Marquee from "react-fast-marquee";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingLeft: 0,
    paddingRight: 0,
}));

function Header({ user, isDisclaimerVisible, setIsDisclaimerVisible }) {
    return (
        <StyledAppBar position="fixed">
            <Container maxWidth="xl" sx={{ p: 0 }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Sidebar isDisclaimerVisible={isDisclaimerVisible} />
                        <BrandLogo user={user} />
                    </Box>
                    <Box sx={{ ml: { sm: "-100px" }, display: { md: "block", xs: "none" } }}>
                        <Logo />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton color="inherit">
                            <PodcastsRoundedIcon />
                        </IconButton>
                        <IconButton color="inherit" sx={{ display: { sm: "block", xs: "none" } }}>
                            <SearchIcon />
                        </IconButton>
                        <IconButton color="inherit">
                            <LanguageRoundedIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </Container>
            <Box sx={{ width: "100%", backgroundColor: (theme) => theme.palette.primary.main, color: "white", p: .5, }}>
                <Container maxWidth="xl" sx={{ p: 0 }}>
                    <Button onClick={() => window.history.back()} startIcon={<ArrowBackIosNewRoundedIcon />} variant="standard">Back</Button>
                </Container>
            </Box>
            {isDisclaimerVisible && (
                <Box sx={{ width: "100%", backgroundColor: (theme) => theme.palette.success.main, color: "white", p: 1, }}>
                    <Container maxWidth="xl" sx={{ p: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography sx={{ textWrap: "nowrap" }} variant="h6" color="white"> <WarningAmberRoundedIcon sx={{ mb: .5 }} /> Data Alert</Typography>
                        <Marquee
                            pauseOnHover={true}
                            speed={60}
                            gradient={false}
                            style={{ width: "80%", }}
                        >
                            If you clear your browser cache, you will lose all the progress you've made and information you have entered
                            in the Financial Education Center. To avoid this, please refrain from clearing your cache. For guidance in the
                            Financial Education Center, click the “?” button in the bottom left to view a demonstration. This will show
                            you how to navigate and make the most of each section.
                        </Marquee>
                        <IconButton aria-label="close" color="inherit" onClick={() => setIsDisclaimerVisible(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Container>
                </Box>
            )}
        </StyledAppBar>
    )
}

export default Header