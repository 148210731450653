import {
  Box,
  Drawer,
  IconButton,
  styled,
} from "@mui/material";
import WidgetsRoundedIcon from "@mui/icons-material/WidgetsRounded";
import React, { useState } from "react";

import RouteButtons from "./RouteButtons";

const drawerWidth = 280;
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },

}));
function Sidebar({ isDisclaimerVisible }) {
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleDrawerToggle}
        sx={{ mr: { sm: 2, xs: 0.5 } }}
      >
        <WidgetsRoundedIcon size="large" />
      </IconButton>
      <StyledDrawer
        variant="temporary"
        open={open}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          sx={{
            padding: 1,
            px: 3,
            marginTop: isDisclaimerVisible ? "180px" : '130px',
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
          }}
        >
          <RouteButtons handleClose={handleDrawerToggle} page="sidebar" />
        </Box>
      </StyledDrawer>
    </>
  );
}

export default Sidebar;
