import { Container, Box, Typography } from "@mui/material"
import FieldSetComp from "./fieldSetComponent"
import { useLocation } from 'react-router-dom'
import { useState } from "react"

const fieldSetData = [
    {
        name: "KNOWLEDGE",
        content: [
            {
                heading: 'Arts and Humanities',
                list: ['Engish language',]
            },
            {
                heading: 'Engineering and Technology',
                list: ['mechanical',]
            }
        ]
    },
    {
        name: "SKILLS",
        content: [
            {
                heading: 'Basic Skills',
                list: ['listening to others, not interrupting, and asking good questions',]
            },
            {
                heading: 'Problem Solving',
                list: ['noticing a problem and figuring out the best way to solve it',]
            }
        ]
    },
    {
        name: "ABILITIES",
        content: [
            {
                heading: 'Controlled Movement',
                list: ['quickly change the controls of a machine, car, truck or boat',
                    'use your arms and/or legs together while sitting, standing, or lying down']
            },
            {
                heading: 'Hand and Finger Use',
                list: ['keep your arm or hand steady', 'hold or move items with your hands']
            }
        ]
    },

]


const CareerSammary = (props) => {

    const location = useLocation()
    const item = location.state
    const metadata = item.metaData
    const metadataKeys = Object.keys(metadata).map(val => val)
    const metadataValues = Object.values(metadata).map(val => val)


    console.log("metadataKeys.map(val=>val)",)


    return (
        <Container
            sx={{
                overflowX: "auto",
                display: "flex",
                padding: 5,
                flexDirection: "column",
                gap: 5
            }}>

            <Box sx={{ marginTop: 23 }} height={0} width={'100%'}
                display={'flex'} backgroundColor={"#390D50"}
                justifyContent={'center'} alignItems={'center'}>
            </Box>

            {/* Devider */}
            <Typography
                fontFamily={'Poppins'}
                fontSize={30}
                fontWeight={'bold'}
                color={'#004488'}>
                {item?.profession?.replace("_", " ")}
            </Typography>

            {/* Devider */}

            <Box display={'flex'} flexWrap={'wrap'} alignItems={"center"} justifyContent={'space-between'} gap={5} paddingY={5}>
                {metadataValues?.slice(0, -1)?.map((item, index) => {
                    if (item.includes("Salary")) {
                        let temporaryArray = metadataValues
                        const regex = /\$\d{1,3}(?:,\d{3})*(?:\.\d+)?/g;
                        const salaries = item.match(regex);
                        if (salaries && salaries.length >= 2) {
                        const minSalary = Math.min(...salaries.map(s => parseInt(s.replace(/\$|,/g, ''))));
                        const maxSalary = Math.max(...salaries.map(s => parseInt(s.replace(/\$|,/g, ''))));

                        // Format the result
                        const formattedResult = `$${minSalary.toLocaleString()} - $${maxSalary.toLocaleString()}`;

                        console.log("formattedResult", formattedResult); // Output: $61,250 to $129,400
                        temporaryArray[index] = formattedResult
                    }else{
                        temporaryArray[index] = item
                    }   return (
                            <FieldSetComp heading={metadataKeys[index]}>
                                <DescriptionComp metadataValues={metadataValues} index={index} />
                            </FieldSetComp>
                        )
                    }
                    return (
                        <FieldSetComp heading={metadataKeys[index]}>
                            <DescriptionComp metadataValues={metadataValues} index={index} />
                        </FieldSetComp>
                    )
                })}
            </Box>
        </Container>
    )
}

const description = (e) => (e.slice(0, 180))
const DescriptionComp = ({ metadataValues, index }) => {
    const [showMore, setShowmore] = useState(false)

    return (
        <p
            style={{
                color: "black",
                fontFamily: "Poppins",
                fontSize: 15
            }}>{!showMore ? description(metadataValues[index]) : metadataValues[index]}
            {metadataValues[index]?.length > 180 ? <b style={{ color: "#616ded", cursor: "pointer" }} onClick={() => { setShowmore(prev => !prev) }}>{!showMore ? " See more..." : " Show Less"}</b> : null}
        </p>
    )
}


export default CareerSammary