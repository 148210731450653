import { TextField, MenuItem, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const CustomTextField = ({ label = "", ...props }) => {
  const theme = useTheme();

  return (
    <Box sx={{ borderRadius: "8px", overflow: "hidden" }}>
      <Typography variant="body2" fontWeight="bold" mb={0.2}>
        {label}
      </Typography>

      <TextField
        variant="filled"
        {...props}
        fullWidth
        // value={props.value}
        // onChange={props.onChange}
        // InputProps={{
        //   disableUnderline: true,
        //   ...props.InputProps,
        // }}
        sx={{
          "& .MuiFilledInput-root": {
            backgroundColor: theme.palette.success.light,
            border: `1px solid ${theme.palette.success.main}`,
            padding: "5px 5px",
            borderRadius: "8px",
            overflow: "hidden",
            "&:hover": {
              backgroundColor: theme.palette.success.light,
              borderColor: theme.palette.success.main,
            },
            "&.Mui-focused": {
              backgroundColor: theme.palette.success.light,
              borderColor: theme.palette.success.main,
            },
            "& input[type=number]": {
              "-moz-appearance": "textfield",
              "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
              },
            },
          },
          "& .MuiFilledInput-input": {
            paddingTop: "8px",
            paddingBottom: "2px",
          },
        }}
      />
    </Box>
  );
};

export const CustomDropDown = ({
  options = [],
  label,
 
  ...props
}) => {
  return (
    <TextField
      select
      variant="filled"
      fullWidth
      InputProps={{
        disableUnderline: true,
      }}
      sx={{
        "& .MuiFilledInput-root": {
          padding: "5px 5px",
          borderRadius: "8px",
        },
        "& .MuiFilledInput-input": {
          paddingTop: "8px",
          paddingBottom: "2px",
        },
      }}
      {...props}
    >
      {options.length > 0 ? (
        options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>No options available</MenuItem>
      )}
    </TextField>
  );
};
