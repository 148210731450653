import { Link } from 'react-router-dom'
function Header() {
    return (<>
        <div className="appHeader">
            <div className="left">
                <Link to="/" className="headerButton goBack">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </Link>
            </div>
            <div className="pageTitle">

            </div>
        </div>
    </>);
}

export default Header;