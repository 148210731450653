import React from 'react'

const DictionaryIcon = ({color}) => {
  return (
    <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.2584 3.84277H7.89662C6.21947 3.84277 4.85986 5.20238 4.85986 6.87953V23.8854C4.85986 25.5625 6.21947 26.9222 7.89662 26.9222H21.2584C22.9355 26.9222 24.2951 25.5625 24.2951 23.8854V6.87953C24.2951 5.20238 22.9355 3.84277 21.2584 3.84277Z" stroke={color} stroke-width="1.30297"/>
    <path d="M19.4359 11.7383H9.71819C9.38276 11.7383 9.11084 12.0102 9.11084 12.3456V14.775C9.11084 15.1105 9.38276 15.3824 9.71819 15.3824H19.4359C19.7713 15.3824 20.0432 15.1105 20.0432 14.775V12.3456C20.0432 12.0102 19.7713 11.7383 19.4359 11.7383Z" stroke={color} stroke-width="1.30297"/>
    <path d="M17.614 17.8115H11.5405C11.205 17.8115 10.9331 18.0834 10.9331 18.4189V19.0262C10.9331 19.3617 11.205 19.6336 11.5405 19.6336H17.614C17.9494 19.6336 18.2213 19.3617 18.2213 19.0262V18.4189C18.2213 18.0834 17.9494 17.8115 17.614 17.8115Z" stroke={color} stroke-width="1.30297"/>
    <path d="M16.7036 8.14612V2.62785C16.7036 2.29243 16.9756 2.02051 17.311 2.02051H20.3477C20.6831 2.02051 20.9551 2.29243 20.9551 2.62785V8.14612C20.9551 8.68722 20.3008 8.9582 19.9182 8.57559L19.2588 7.91614C19.0216 7.67895 18.637 7.67895 18.3999 7.91614L17.7404 8.57559C17.3578 8.9582 16.7036 8.68722 16.7036 8.14612Z" fill="white" stroke={color} stroke-width="1.30297"/>
    </svg>
    
  )
}

export default DictionaryIcon