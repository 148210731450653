import React, { useState } from "react"
import "./index.css"
import InputField from "../../resumeAnalyzer/inputComp/inputComp"
import { Grid, MenuItem, TextField, Typography, Box } from "@mui/material"
import VoltageBtn from "../../resumeAnalyzer/voltage-btn/voltage_Btn"
import { interviewQuestions } from "../../../_api/interview-simulator"
const InterviewSection = ({ setIsloading, setState, setQuestions }) => {
    const jobRoles = [{ name: "Executive" }, { name: "Manager" }, { name: "Analyst" }]
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [jobRole, setJobRole] = useState("")
    const [interViewLevel, setInterViewLevel] = useState("Executive")
    const [titleError, setTitleError] = useState("")
    const [descriptionError, setDescriptionError] = useState("")
    const [jobRoleError, setJobRoleError] = useState("")
    const handleQuestionGenerate = async () => {
        if (!title) {
            setTitleError("Interview Topic is required")
            return
        }
        if (!description) {
            setDescriptionError("Interview Description is required")
            return
        }
        if (!jobRole) {
            setJobRoleError("Job Role is required")
            return
        }
        try {
            setIsloading({ open: true, text: "Generating Questions" })
            const response = await interviewQuestions(title, jobRole, description, interViewLevel);
            console.log("🚀 ~ handleQuestionGenerate ~ response:", response)
            setQuestions(response)
            setState(1)
            setIsloading({ false: true, text: "" })
        } catch (error) {
            setIsloading(false)
            // Handle errors
            console.error("Error:", error);
        }
    }
    return (
        <Box
            sx={{
                WebkitOverflowScrolling: "touch", // Enable momentum scrolling for iOS
                "&::-webkit-scrollbar": {
                    width: "5px", // Adjust the width of the scrollbar
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)", // Adjust the color of the scrollbar thumb
                    borderRadius: "5px", // Adjust the border radius of the scrollbar thumb
                },
                "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the color of the scrollbar thumb on hover
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent", // Set the background color of the scrollbar track to transparent
                },
            }}
            className="interview-section-container" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 50 }}>
            <p className="main-title-2">Interview&nbsp;<b style={{ color: "#6e7bee" }}>Simulator</b></p>
            <div className="interview-analyst-input-div">
                <div className="interview-analyst-inputdivider">
                    <InputField width={"70%"} error={titleError} onChange={(e) => { setTitle(e.target.value); if (e.target.value) { setTitleError("") } }}
                        name={"Interview Topic"} placeholder="e.g., Web Developer, Data Scientist" />
                    <InputField height={50} textaria={true} width={"70%"} error={descriptionError} onChange={(e) => { setDescription(e.target.value); if (e.target.value) { setDescriptionError("") } }}
                        name={"Interview Description"} placeholder="e.g., Web Developer, Data Scientist" />
                </div>
                <div className="interview-analyst-inputdivider">
                    <Grid item md={12} xs={12} lg={12} className="interview-select-options">
                        <p style={{ marginBottom: "18px" }} className="InputField-name">Interview Level</p>
                        <TextField
                            className="fieldInput"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            select
                            fullWidth
                            onChange={(e) => {
                                setInterViewLevel(e.target.value);
                            }}
                            value={interViewLevel}
                            label="Select Type"
                            required
                        >
                            {jobRoles.map((ct) => {
                                return (
                                    <MenuItem key={ct.value} value={ct.name}>
                                        {ct.name}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Grid>
                    <InputField width={"70%"} error={jobRoleError} onChange={(e) => { setJobRole(e.target.value); if (e.target.value) { setJobRoleError("") } }} name={"Job Role"} placeholder="e.g., Web Developer, Data Scientist" />
                </div>
                <VoltageBtn
                    width={"40%"}
                    normalBtn={true}
                    onClick={handleQuestionGenerate}
                    name={"Generate"} />
            </div></Box>
    )
}
export default InterviewSection