export const scrapeData = `Title: Accountants & Auditors 
Information:
Knowledge Business accounting and economics customer service Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Math add, subtract, multiply, or divide choose the right type of math to solve a problem Verbal communicate by speaking read and understand what is written Ideas and Logic use rules to solve problems notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Analytical Thinking Cooperation Stress Tolerance 
Technology You might use software like this on the job: Accounting software Intuit QuickBooks Sage 50 Accounting Word processing software Google Docs Microsoft Word Financial analysis software Oracle E-Business Suite Financials Tropics workers' compensation software 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $78,000 $48,560 $132,690 Check out my state Local Salary Info Find Jobs 
Explore More Bookkeeping, Accounting, & Auditing Clerks Financial Examiners Financial Managers Tax Examiners & Collectors, & Revenue Agents Treasurers & Controllers You might like a career in one of these industries: Professional, Science, & Technical 
##############################################
Title: Actors 
Information:
Knowledge Arts and Humanities music, dance, visual arts, drama, or sculpture English language Communications multimedia Math and Science sociology and anthropology psychology Business customer service 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Memory remember words, numbers, pictures, or steps Verbal communicate by speaking listen and understand what people say Ideas and Logic create new and original ideas notice when problems happen 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Cooperation Persistence Adaptability/Flexibility Dependability Initiative Social Orientation 
Technology You might use software like this on the job: Web page creation and editing software Facebook LinkedIn Video creation and editing software Apple Final Cut Pro YouTube Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 2 of 5) no high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $17.94 $13.20 $109.46 Check out my state Local Salary Info Find Jobs 
Explore More Choreographers Music Directors & Composers Musicians & Singers Producers & Directors Talent Directors You might like a career in one of these industries: Media & Communication Arts & Entertainment Education 
##############################################
Title: Actuaries 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics Business accounting and economics Arts and Humanities English language 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information group things in different ways 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Analytical Thinking Attention to Detail Integrity Dependability Achievement/Effort Initiative 
Technology You might use software like this on the job: Data base user interface and query software Microsoft SQL Server Oracle Database Presentation software Microsoft PowerPoint Financial analysis software Oak Mountain Software AnnuityValue Pricing software 
Education Education: (rated 4 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $113,990 $71,760 $199,300 Check out my state Local Salary Info Find Jobs 
Explore More Accountants & Auditors Compensation, Benefits, & Job Analysis Specialists Financial & Investment Analysts Financial Risk Specialists Personal Financial Advisors You might like a career in one of these industries: Finance & Insurance Professional, Science, & Technical 
##############################################
Title: Acupuncturists 
Information:
Knowledge Health medicine and dentistry therapy and counseling Business customer service sales and marketing Math and Science psychology Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Hand and Finger Use keep your arm or hand steady put together small parts with your fingers 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Dependability Self Control Attention to Detail Adaptability/Flexibility Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software Electronic health record EHR software QPuncture II Electronic mail software Microsoft Outlook 
Education Education: (rated 5 of 5) master's degree or professional degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $72,220 $31,200 $156,000 Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Chiropractors Emergency Medicine Physicians Massage Therapists Naturopathic Physicians You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Acute Care Nurses 
Information:
Knowledge Health medicine and dentistry therapy and counseling Business customer service Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people understanding people's reactions 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted do two or more things at the same time 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Stress Tolerance Attention to Detail Dependability Adaptability/Flexibility Concern for Others 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software GE Healthcare Centricity EMR Presentation software Microsoft PowerPoint Electronic mail software IBM Lotus Notes Microsoft Outlook 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $81,220 $61,250 $129,400 Check out my state Local Salary Info Find Jobs 
Explore More Advanced Practice Psychiatric Nurses Clinical Nurse Specialists Critical Care Nurses Licensed Practical & Licensed Vocational Nurses Registered Nurses You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Adapted Physical Education Specialists 
Information:
Knowledge Education and Training teaching and course design Math and Science psychology Arts and Humanities English language Health therapy and counseling 
Skills Basic Skills figuring out how to use new ideas or things listening to others, not interrupting, and asking good questions Social teaching people how to do something understanding people's reactions People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Adaptability/Flexibility Cooperation Concern for Others Dependability Integrity Initiative 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Data base user interface and query software Database software Student record software 
Education Education: (rated 5 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $63,950 $38,680 $121,850 Check out my state Local Salary Info Find Jobs 
Explore More Special Education Teachers, Elementary School Special Education Teachers, Kindergarten Special Education Teachers, Middle School Special Education Teachers, Preschool Special Education Teachers, Secondary School You might like a career in one of these industries: Education 
##############################################
Title: Adhesive Bonding Machine Operators & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat change when and how fast you move based on how something else is moving Endurance exercise for a long time without getting out of breath Verbal listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Self Control Integrity Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $38,780 $27,700 $51,990 Check out my state Local Salary Info Find Jobs 
Explore More Cutting & Slicing Machine Setters, Operators, & Tenders Extruding, Forming, Pressing, & Compacting Machine Setters, Operators, & Tenders Machine Feeders & Offbearers Paper Goods Machine Setters, Operators, & Tenders Rolling Machine Setters, Operators, & Tenders, Metal & Plastic You might like a career in one of these industries: Manufacturing 
##############################################
Title: Administrative Law Judges, Adjudicators, & Hearing Officers 
Information:
Knowledge Safety and Government law and government Arts and Humanities English language Business customer service administrative services Health medicine and dentistry 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Attention to Detail Self Control Independence Stress Tolerance Analytical Thinking 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access Online databases 
Education Education: (rated 5 of 5) doctoral degree or bachelor's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $94,990 $46,070 $151,470 Check out my state Local Salary Info Find Jobs 
Explore More Arbitrators, Mediators, & Conciliators Claims Adjusters, Examiners, & Investigators Judges, Magistrate Judges, & Magistrates Judicial Law Clerks Lawyers You might like a career in one of these industries: Government 
##############################################
Title: Administrative Services Managers 
Information:
Knowledge Business customer service management Arts and Humanities English language Engineering and Technology computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Social changing what is done based on other people's actions bringing people together to solve differences Resource Management managing your time and the time of other people selecting and managing the best workers for a job 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Attention do two or more things at the same time pay attention to something without being distracted 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Self Control Stress Tolerance Attention to Detail Cooperation 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Word processing software Google Docs Microsoft Word Data base user interface and query software Microsoft Access Yardi software 
Education Education: (rated 3 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $103,330 $59,070 $178,870 Check out my state Local Salary Info Find Jobs 
Explore More Executive Secretaries & Executive Administrative Assistants Facilities Managers First-Line Supervisors of Office & Administrative Support Workers General & Operations Managers Project Management Specialists You might like a career in one of these industries: Government Education Professional, Science, & Technical Health & Counseling 
##############################################
Title: Adult Basic Education, Adult Secondary Education, & English as a Second Language Instructors 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Business customer service administrative services 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Social teaching people how to do something understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Integrity Self Control Adaptability/Flexibility Concern for Others Independence 
Technology You might use software like this on the job: Office suite software Google Workspace software Microsoft Office software Computer based training software Blackboard software Quizlet Multi-media educational software Edpuzzle Kahoot! 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $58,590 $36,970 $97,010 Check out my state Local Salary Info Find Jobs 
Explore More Elementary School Teachers Middle School Teachers Special Education Teachers, Elementary School Special Education Teachers, Kindergarten Tutors You might like a career in one of these industries: Education 
##############################################
Title: Advanced Practice Psychiatric Nurses 
Information:
Knowledge Math and Science psychology biology Health therapy and counseling medicine and dentistry Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Concern for Others Dependability Stress Tolerance Attention to Detail Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Invivo Data EPX ePRO Management System Microsoft Access Medical software Medical condition coding software Zung Depression Rating Scale Spreadsheet software Microsoft Excel 
Education Education: (rated 5 of 5) master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $81,220 $61,250 $129,400 Check out my state Local Salary Info Find Jobs 
Explore More Clinical Nurse Specialists Family Medicine Physicians Nurse Practitioners Psychiatric Technicians Psychiatrists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Advertising & Promotions Managers 
Information:
Knowledge Business sales and marketing customer service Arts and Humanities English language Communications multimedia Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems come up with lots of ideas 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Initiative Cooperation Dependability Attention to Detail Adaptability/Flexibility Stress Tolerance 
Technology You might use software like this on the job: Video creation and editing software Adobe Systems Adobe After Effects YouTube Web page creation and editing software LinkedIn WordPress Analytical or scientific software Data visualization software WebTrends Analytics 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $127,830 $60,380 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Advertising Sales Agents Market Research Analysts & Marketing Specialists Marketing Managers Sales Managers Search Marketing Strategists You might like a career in one of these industries: Professional, Science, & Technical Media & Communication 
##############################################
Title: Advertising Sales Agents 
Information:
Knowledge Business sales and marketing customer service Arts and Humanities English language Communications multimedia Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social talking people into changing their minds or their behavior looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic come up with lots of ideas create new and original ideas 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Initiative Persistence Integrity Achievement/Effort Dependability 
Technology You might use software like this on the job: Desktop publishing software Adobe Systems Adobe InDesign Microsoft Publisher Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $58,450 $30,510 $132,640 Check out my state Local Salary Info Find Jobs 
Explore More Advertising & Promotions Managers Market Research Analysts & Marketing Specialists Marketing Managers Search Marketing Strategists Telemarketers You might like a career in one of these industries: Media & Communication Professional, Science, & Technical 
##############################################
Title: Aerospace Engineering & Operations Technologists & Technicians 
Information:
Knowledge Engineering and Technology mechanical product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Manufactured or Agricultural Goods manufacture and distribution of products Business customer service 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Analytical Thinking Integrity Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Program testing software JUnit Selenium Presentation software Microsoft PowerPoint Operating system software Linux UNIX 
Education Education: (rated 3 of 5) certificate after high school or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $74,410 $47,950 $109,610 Check out my state Local Salary Info Find Jobs 
Explore More Automotive Engineering Technicians Avionics Technicians Calibration Technologists & Technicians Electro-Mechanical & Mechatronics Technologists & Technicians Mechanical Engineering Technologists & Technicians You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Aerospace Engineers 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Analytical Thinking Dependability Adaptability/Flexibility Initiative Stress Tolerance 
Technology You might use software like this on the job: Analytical or scientific software The MathWorks MATLAB Wolfram Research Mathematica Presentation software Microsoft PowerPoint Development environment software C Microsoft Visual Studio 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $126,880 $78,170 $176,280 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Avionics Technicians Electro-Mechanical & Mechatronics Technologists & Technicians Mechanical Engineering Technologists & Technicians Mechanical Engineers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical Government 
##############################################
Title: Agents & Business Managers of Artists, Performers, & Athletes 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language music, dance, visual arts, drama, or sculpture Communications multimedia 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Social bringing people together to solve differences talking people into changing their minds or their behavior Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Persistence Initiative Dependability Integrity Attention to Detail Cooperation 
Technology You might use software like this on the job: Web page creation and editing software Facebook LinkedIn Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $82,530 $45,180 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Advertising & Promotions Managers Advertising Sales Agents Marketing Managers Public Relations Specialists Talent Directors You might like a career in one of these industries: Arts & Entertainment 
##############################################
Title: Agricultural Engineers 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Manufactured or Agricultural Goods food production 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math add, subtract, multiply, or divide choose the right type of math to solve a problem Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Analytical Thinking Integrity Dependability Initiative Cooperation 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access Oracle Database 
Education Education: (rated 4 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $83,260 $50,480 $146,350 Check out my state Local Salary Info Find Jobs 
Explore More Conservation Scientists Environmental Engineers Industrial Ecologists Precision Agriculture Technicians Soil & Plant Scientists You might like a career in one of these industries: Government Professional, Science, & Technical Manufacturing Education 
##############################################
Title: Agricultural Equipment Operators 
Information:
Knowledge Arts and Humanities English language Engineering and Technology mechanical 
Skills Basic Skills listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use keep your arm or hand steady hold or move items with your hands 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Persistence Cooperation Attention to Detail Independence Initiative 
Technology You might use software like this on the job: Data base user interface and query software Martens Farms Farm Trac Microsoft Access Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $37,780 $28,370 $51,700 Check out my state Local Salary Info Find Jobs 
Explore More Crushing, Grinding, & Polishing Machine Setters, Operators, & Tenders Extruding, Forming, Pressing, & Compacting Machine Setters, Operators, & Tenders Farmworkers & Laborers, Crop, Nursery, & Greenhouse Machine Feeders & Offbearers Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting Wholesale/Commercial Sales 
##############################################
Title: Agricultural Inspectors 
Information:
Knowledge Business customer service management Safety and Government law and government public safety and security Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Attention to Detail Cooperation Self Control Concern for Others 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $44,720 $32,610 $72,220 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Technicians Construction & Building Inspectors First-Line Supervisors of Farming, Fishing, & Forestry Workers Food Science Technicians Graders & Sorters, Agricultural Products You might like a career in one of these industries: Government Manufacturing 
##############################################
Title: Agricultural Sciences Teachers, Postsecondary 
Information:
Knowledge Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Education and Training teaching and course design Arts and Humanities English language Business management 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math add, subtract, multiply, or divide choose the right type of math to solve a problem Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Analytical Thinking Integrity Leadership Adaptability/Flexibility Achievement/Effort 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Sakai CLE Data base user interface and query software Data management software Database software 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $85,860 $48,130 $150,550 Check out my state Local Salary Info Find Jobs 
Explore More Atmospheric, Earth, Marine, & Space Sciences Teachers, Postsecondary Biological Science Teachers, Postsecondary Environmental Science Teachers, Postsecondary Family & Consumer Sciences Teachers, Postsecondary Forestry & Conservation Science Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Agricultural Technicians 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics biology Business management administrative services Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic notice when problems happen order or arrange things Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Analytical Thinking Achievement/Effort Concern for Others 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $41,760 $29,900 $63,720 Check out my state Local Salary Info Find Jobs 
Explore More Biological Technicians Chemical Technicians Farmers, Ranchers, & Other Agricultural Managers Food Science Technicians Precision Agriculture Technicians You might like a career in one of these industries: Professional, Science, & Technical Education Farming, Forestry, Fishing, & Hunting 
##############################################
Title: Air Traffic Controllers 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language Education and Training teaching and course design Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Attention pay attention to something without being distracted do two or more things at the same time Visual Understanding quickly know what you are looking at see hidden patterns Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Stress Tolerance Dependability Adaptability/Flexibility Persistence Achievement/Effort 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Flight control software Direct-to-tool software Traffic management advisor TMA software 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $132,250 $73,590 $189,800 Check out my state Local Salary Info Find Jobs 
Explore More Airfield Operations Specialists Airline Pilots, Copilots, & Flight Engineers Commercial Pilots Railroad Conductors & Yardmasters Traffic Technicians You might like a career in one of these industries: Government 
##############################################
Title: Aircraft Cargo Handling Supervisors 
Information:
Knowledge Business customer service management Safety and Government public safety and security Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted do two or more things at the same time Ideas and Logic notice when problems happen use rules to solve problems Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Self Control Attention to Detail Dependability Cooperation Leadership 
Technology You might use software like this on the job: Office suite software Corel WordPerfect Office Suite Microsoft Office software Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $53,490 $41,030 $87,170 Check out my state Local Salary Info Find Jobs 
Explore More Airfield Operations Specialists First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Material-Moving Machine & Vehicle Operators First-Line Supervisors of Passenger Attendants Transportation Inspectors You might like a career in one of these industries: Transportation & Storage 
##############################################
Title: Aircraft Mechanics & Service Technicians 
Information:
Knowledge Engineering and Technology mechanical product and service development Arts and Humanities English language Business customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Technical planning and doing the basic maintenance on equipment repairing machines or systems using the right tools 
Abilities Hand and Finger Use put together small parts with your fingers hold or move items with your hands Verbal read and understand what is written communicate by speaking Ideas and Logic notice when problems happen order or arrange things Controlled Movement quickly change the controls of a machine, car, truck or boat 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Persistence Self Control Adaptability/Flexibility 
Technology You might use software like this on the job: Enterprise resource planning ERP software SAP software Tracware AeroTrac Presentation software Microsoft PowerPoint Facilities management software Maintenance planning software Maintenance record software 
Education Education: (rated 3 of 5) certificate after high school or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $70,010 $41,020 $108,200 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Aircraft Service Attendants Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Avionics Technicians Engine & Other Machine Assemblers You might like a career in one of these industries: Transportation & Storage Manufacturing Government 
##############################################
Title: Aircraft Service Attendants 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Facilities management software Maintenance planning software Maintenance record software Information retrieval or search software Computerized aircraft log manager CALM Technical manual database software 
Education Education: (rated 2 of 5) Get started on your career: Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $37,050 $29,400 $51,880 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Mechanics & Service Technicians Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Avionics Technicians Commercial Pilots Rail Car Repairers You might like a career in one of these industries: Transportation & Storage 
##############################################
Title: Aircraft Structure, Surfaces, Rigging, & Systems Assemblers 
Information:
Knowledge Education and Training teaching and course design Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Engineering and Technology mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things Verbal listen and understand what people say read and understand what is written 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $58,430 $37,650 $85,370 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Mechanics & Service Technicians Electrical & Electronic Equipment Assemblers Engine & Other Machine Assemblers Layout Workers, Metal & Plastic Structural Metal Fabricators & Fitters You might like a career in one of these industries: Manufacturing 
##############################################
Title: Airfield Operations Specialists 
Information:
Knowledge Safety and Government public safety and security law and government Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language Business customer service 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted do two or more things at the same time Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Attention to Detail Integrity Stress Tolerance Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Oracle Database Presentation software Microsoft PowerPoint Operating system software Linux Microsoft Windows 
Education Education: (rated 3 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $49,600 $30,460 $110,830 Check out my state Local Salary Info Find Jobs 
Explore More Air Traffic Controllers Aircraft Cargo Handling Supervisors Airline Pilots, Copilots, & Flight Engineers Aviation Inspectors Commercial Pilots You might like a career in one of these industries: Transportation & Storage Government 
##############################################
Title: Airline Pilots, Copilots, & Flight Engineers 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language Engineering and Technology mechanical computers and electronics Math and Science geography 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Controlled Movement quickly decide if you should move your hand, foot, or other body part quickly change the controls of a machine, car, truck or boat Attention do two or more things at the same time pay attention to something without being distracted Vision see details that are far away see details up close Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Cooperation Stress Tolerance Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software Airline Pilots Daily Aviation Log PPC CoPilot Flight Planning & E6B 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $211,790 $98,680 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Air Traffic Controllers Aircraft Service Attendants Airfield Operations Specialists Captains, Mates, & Pilots of Water Vessels Commercial Pilots You might like a career in one of these industries: Transportation & Storage 
##############################################
Title: Allergists & Immunologists 
Information:
Knowledge Health medicine and dentistry Arts and Humanities English language Business customer service management Math and Science biology 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted do two or more things at the same time Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Attention to Detail Cooperation Dependability Self Control Stress Tolerance 
Technology You might use software like this on the job: Medical software Bizmatics PrognoCIS EMR eClinicalWorks EHR software Presentation software Microsoft PowerPoint Analytical or scientific software GraphPad Software GraphPad Prism Molecular Devices Softmax Pro 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $223,410 $63,970 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Dermatologists Emergency Medicine Physicians General Internal Medicine Physicians Pediatric Surgeons You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Ambulance Drivers & Attendants 
Information:
Knowledge Business customer service management Arts and Humanities English language Safety and Government public safety and security law and government Education and Training teaching and course design 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Social looking for ways to help people understanding people's reactions 
Abilities Verbal listen and understand what people say communicate by speaking Attention do two or more things at the same time pay attention to something without being distracted Controlled Movement quickly decide if you should move your hand, foot, or other body part use your arms and/or legs together while sitting, standing, or lying down Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Concern for Others Self Control Attention to Detail Integrity Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Helpdesk or call center software Computer aided dispatch software Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $30,380 $22,410 $47,120 Check out my state Local Salary Info Find Jobs 
Explore More Emergency Medical Technicians Nursing Assistants Orderlies Paramedics Public Safety Telecommunicators You might like a career in one of these industries: Health & Counseling Government Transportation & Storage 
##############################################
Title: Amusement & Recreation Attendants 
Information:
Knowledge Business customer service management Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Concern for Others Cooperation Dependability Adaptability/Flexibility Self Control Attention to Detail 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Desktop publishing software Adobe Systems Adobe PageMaker 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $27,780 $20,170 $36,920 Check out my state Local Salary Info Find Jobs 
Explore More Baggage Porters & Bellhops Counter & Rental Clerks Laborers & Freight, Stock, & Material Movers, Hand Locker Room, Coatroom, & Dressing Room Attendants Parking Attendants You might like a career in one of these industries: Arts & Entertainment Government 
##############################################
Title: Anesthesiologist Assistants 
Information:
Knowledge Health medicine and dentistry Arts and Humanities English language Math and Science chemistry arithmetic, algebra, geometry, calculus, or statistics Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one 
Abilities Verbal listen and understand what people say read and understand what is written Attention pay attention to something without being distracted do two or more things at the same time Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Stress Tolerance Dependability Integrity Self Control Concern for Others 
Technology You might use software like this on the job: Medical software Bizmatics PrognoCIS EMR eClinicalWorks EHR software Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) master's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $126,010 $83,820 $168,120 Check out my state Local Salary Info Find Jobs 
Explore More Anesthesiologists Emergency Medicine Physicians Nurse Anesthetists Orthopedic Surgeons Pediatric Surgeons You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Anesthesiologists 
Information:
Knowledge Health medicine and dentistry Math and Science biology chemistry Arts and Humanities English language Business customer service 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen use rules to solve problems Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Dependability Integrity Achievement/Effort Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software Medical calculator software MEDITECH software 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $239,200+ $74,860 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians Nurse Anesthetists Orthopedic Surgeons Pediatric Surgeons You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Animal Breeders 
Information:
Knowledge Business customer service sales and marketing Math and Science biology arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem figuring out how to use new ideas or things Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Verbal listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Attention to Detail Independence Self Control Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Breedtrak Microsoft Access Electronic mail software Email software Microsoft Outlook Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $45,320 $29,640 $73,580 Check out my state Local Salary Info Find Jobs 
Explore More Animal Caretakers Animal Trainers Farmers, Ranchers, & Other Agricultural Managers Farmworkers, Farm, Ranch, & Aquacultural Animals Veterinary Assistants & Laboratory Animal Caretakers You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting 
##############################################
Title: Animal Caretakers 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Dependability Self Control Attention to Detail Cooperation Stress Tolerance 
Technology You might use software like this on the job: Data base user interface and query software CEEJS The Pet Groomer's Secretary Microsoft Access Presentation software Microsoft PowerPoint Calendar and scheduling software DaySmart Software Appointment-Plus Petschedule 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $29,530 $22,250 $44,280 Check out my state Local Salary Info Find Jobs 
Explore More Animal Breeders Animal Control Workers Animal Trainers Veterinary Assistants & Laboratory Animal Caretakers Veterinary Technologists & Technicians You might like a career in one of these industries: Service Retail Professional, Science, & Technical 
##############################################
Title: Animal Control Workers 
Information:
Knowledge Business customer service administrative services Safety and Government law and government public safety and security Arts and Humanities English language Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Self Control Stress Tolerance Independence Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Animal Shelter Manager Microsoft Access Presentation software Microsoft PowerPoint Geographic information system Esri ArcGIS Geographic information system GIS software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $39,320 $28,430 $62,050 Check out my state Local Salary Info Find Jobs 
Explore More Animal Breeders Animal Caretakers Animal Trainers Fish & Game Wardens Veterinary Assistants & Laboratory Animal Caretakers You might like a career in one of these industries: Government 
##############################################
Title: Animal Scientists 
Information:
Knowledge Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Manufactured or Agricultural Goods food production Education and Training teaching and course design 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Integrity Dependability Attention to Detail Initiative Achievement/Effort 
Technology You might use software like this on the job: Analytical or scientific software Deoxyribonucleic acid DNA sequence analysis software SAS Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access Structured query language SQL 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $69,390 $45,910 $145,780 Check out my state Local Salary Info Find Jobs 
Explore More Biochemists & Biophysicists Food Scientists & Technologists Microbiologists Soil & Plant Scientists Zoologists & Wildlife Biologists You might like a career in one of these industries: Education Professional, Science, & Technical Farming, Forestry, Fishing, & Hunting Manufacturing Wholesale/Commercial Sales 
##############################################
Title: Animal Trainers 
Information:
Knowledge Business customer service management Education and Training teaching and course design Math and Science psychology Arts and Humanities English language 
Skills Basic Skills talking to others using the best training or teaching strategies for learning new things Social teaching people how to do something changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Attention to Detail Self Control Independence Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Database software Oracle Database Presentation software Microsoft PowerPoint Enterprise resource planning ERP software Oracle PeopleSoft SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $35,620 $27,050 $66,850 Check out my state Local Salary Info Find Jobs 
Explore More Animal Breeders Animal Caretakers Animal Control Workers Exercise Trainers & Group Fitness Instructors Veterinary Assistants & Laboratory Animal Caretakers You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting Service 
##############################################
Title: Anthropologists & Archeologists 
Information:
Knowledge Math and Science sociology and anthropology geography Arts and Humanities history and archeology English language Education and Training teaching and course design Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information writing things for co-workers or customers Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Visual Understanding see hidden patterns quickly know what you are looking at Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Integrity Persistence Dependability Initiative 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software Microsoft PowerPoint Geographic information system ESRI ArcGIS software Geographic information system GIS software 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $63,940 $40,260 $100,560 Check out my state Local Salary Info Find Jobs 
Explore More Anthropology & Archeology Teachers, Postsecondary Area, Ethnic, & Cultural Studies Teachers, Postsecondary Geographers Historians Sociologists You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Anthropology & Archeology Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities English language history and archeology Math and Science sociology and anthropology geography Education and Training teaching and course design Communications multimedia 
Skills Basic Skills talking to others reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Analytical Thinking Integrity Initiative Persistence Dependability Self Control 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software Geographic information system GIS software Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Sakai CLE 
Education Education: (rated 5 of 5) doctoral degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $85,000 $50,920 $167,180 Check out my state Local Salary Info Find Jobs 
Explore More Area, Ethnic, & Cultural Studies Teachers, Postsecondary Geography Teachers, Postsecondary History Teachers, Postsecondary Philosophy & Religion Teachers, Postsecondary Sociology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Appraisers & Assessors of Real Estate 
Information:
Knowledge Arts and Humanities English language Business customer service administrative services Engineering and Technology computers and electronics building and construction Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Attention to Detail Dependability Independence Analytical Thinking Stress Tolerance 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Yardi software Video conferencing software Google Meet Analytical or scientific software Construction Management Software ProEst MicroSolve CAMA 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $61,560 $35,700 $117,600 Check out my state Local Salary Info Find Jobs 
Explore More Appraisers of Personal & Business Property Insurance Appraisers, Auto Damage Property, Real Estate, & Community Association Managers Real Estate Brokers Real Estate Sales Agents You might like a career in one of these industries: Government Real Estate & Rentals 
##############################################
Title: Appraisers of Personal & Business Property 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Yardi software Presentation software Microsoft PowerPoint Analytical or scientific software WinEstimator WinEst WinGap 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $61,560 $35,700 $117,600 Check out my state Local Salary Info Find Jobs 
Explore More Appraisers & Assessors of Real Estate Insurance Appraisers, Auto Damage Property, Real Estate, & Community Association Managers Real Estate Brokers Real Estate Sales Agents You might like a career in one of these industries: Government Real Estate & Rentals 
##############################################
Title: Arbitrators, Mediators, & Conciliators 
Information:
Knowledge Arts and Humanities English language Safety and Government law and government Business human resources (HR) Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social bringing people together to solve differences talking people into changing their minds or their behavior 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Analytical Thinking Self Control Integrity Independence Stress Tolerance Attention to Detail 
Technology You might use software like this on the job: Enterprise resource planning ERP software Oracle PeopleSoft SAP software Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 5 of 5) professional degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $64,030 $33,980 $194,630 Check out my state Local Salary Info Find Jobs 
Explore More Administrative Law Judges, Adjudicators, & Hearing Officers Judges, Magistrate Judges, & Magistrates Judicial Law Clerks Labor Relations Specialists Lawyers You might like a career in one of these industries: Professional, Science, & Technical Government Finance & Insurance 
##############################################
Title: Architects 
Information:
Knowledge Engineering and Technology design building and construction Safety and Government public safety and security law and government Arts and Humanities English language music, dance, visual arts, drama, or sculpture Business management 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Ideas and Logic come up with lots of ideas create new and original ideas Verbal communicate by speaking communicate by writing Math add, subtract, multiply, or divide choose the right type of math to solve a problem Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Analytical Thinking Cooperation Dependability Initiative Integrity 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Civil 3D Trimble SketchUp Pro Presentation software Microsoft PowerPoint Data base user interface and query software Amazon Elastic Compute Cloud EC2 Amazon Redshift 
Education Education: (rated 5 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $82,840 $51,310 $136,400 Check out my state Local Salary Info Find Jobs 
Explore More Civil Engineers Construction & Building Inspectors Construction Managers Interior Designers Landscape Architects You might like a career in one of these industries: Professional, Science, & Technical 
##############################################
Title: Architectural & Civil Drafters 
Information:
Knowledge Engineering and Technology design building and construction Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen order or arrange things Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Analytical Thinking Adaptability/Flexibility Independence 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Civil 3D Trimble SketchUp Pro Presentation software Microsoft PowerPoint Graphics or photo imaging software Adobe Systems Adobe After Effects Adobe Systems Adobe Illustrator 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $59,820 $37,770 $86,180 Check out my state Local Salary Info Find Jobs 
Explore More Civil Engineering Technologists & Technicians Civil Engineers Electrical & Electronics Drafters Mechanical Drafters Mechanical Engineering Technologists & Technicians You might like a career in one of these industries: Professional, Science, & Technical Construction 
##############################################
Title: Architectural & Engineering Managers 
Information:
Knowledge Engineering and Technology design product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Business management 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Analytical Thinking Integrity Dependability Attention to Detail Stress Tolerance Cooperation 
Technology You might use software like this on the job: Data base management system software Amazon DynamoDB Apache Hive Presentation software Microsoft PowerPoint Computer aided design CAD software Autodesk AutoCAD Civil 3D Autodesk Revit 
Education Education: (rated 5 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $159,920 $102,450 $221,550 Check out my state Local Salary Info Find Jobs 
Explore More Electrical Engineers Industrial Engineers Logistics Engineers Mechatronics Engineers Project Management Specialists You might like a career in one of these industries: Professional, Science, & Technical Manufacturing 
##############################################
Title: Architecture Teachers, Postsecondary 
Information:
Knowledge Engineering and Technology design building and construction Arts and Humanities English language music, dance, visual arts, drama, or sculpture Education and Training teaching and course design Communications multimedia 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Analytical Thinking Independence Initiative Self Control Persistence 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk Revit Trimble SketchUp Pro Presentation software Microsoft PowerPoint Computer based training software Learning management system LMS Sakai CLE 
Education Education: (rated 5 of 5) doctoral degree or professional degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $93,220 $48,900 $171,740 Check out my state Local Salary Info Find Jobs 
Explore More Art, Drama, & Music Teachers, Postsecondary Career/Technical Education Teachers, Middle School Career/Technical Education Teachers, Postsecondary Engineering Teachers, Postsecondary Landscape Architects You might like a career in one of these industries: Education 
##############################################
Title: Archivists 
Information:
Knowledge Arts and Humanities history and archeology English language Business customer service administrative services Engineering and Technology computers and electronics Education and Training teaching and course design 
Skills Basic Skills reading work related information writing things for co-workers or customers Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic group things in different ways order or arrange things 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Cooperation Achievement/Effort Analytical Thinking 
Technology You might use software like this on the job: Data base user interface and query software FileMaker Pro Microsoft Access Presentation software Microsoft PowerPoint Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $58,640 $35,310 $101,870 Check out my state Local Salary Info Find Jobs 
Explore More Curators Document Management Specialists Historians Librarians & Media Collections Specialists Library Science Teachers, Postsecondary You might like a career in one of these industries: Education Government Service Arts & Entertainment Media & Communication 
##############################################
Title: Area, Ethnic, & Cultural Studies Teachers, Postsecondary 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language history and archeology Math and Science sociology and anthropology Communications multimedia 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Dependability Analytical Thinking Achievement/Effort Initiative Attention to Detail 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Sakai CLE Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $80,910 $48,280 $162,440 Check out my state Local Salary Info Find Jobs 
Explore More Anthropology & Archeology Teachers, Postsecondary English Language & Literature Teachers, Postsecondary History Teachers, Postsecondary Philosophy & Religion Teachers, Postsecondary Sociology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Art Directors 
Information:
Knowledge Engineering and Technology design computers and electronics Arts and Humanities English language music, dance, visual arts, drama, or sculpture Communications multimedia Business sales and marketing 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic come up with lots of ideas create new and original ideas 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Dependability Attention to Detail Persistence Cooperation Innovation Achievement/Effort 
Technology You might use software like this on the job: Web platform development software AJAX Cascading style sheets CSS Video creation and editing software Adobe Systems Adobe After Effects YouTube Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Illustrator 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $105,180 $57,820 $207,060 Check out my state Local Salary Info Find Jobs 
Explore More Graphic Designers Producers & Directors Project Management Specialists Public Relations Specialists Special Effects Artists & Animators You might like a career in one of these industries: Professional, Science, & Technical 
##############################################
Title: Art Therapists 
Information:
Knowledge Health therapy and counseling Math and Science psychology sociology and anthropology Arts and Humanities music, dance, visual arts, drama, or sculpture English language Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Integrity Dependability Self Control Stress Tolerance Social Orientation 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 5 of 5) master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $60,800 $37,990 $111,800 Check out my state Local Salary Info Find Jobs 
Explore More Clinical & Counseling Psychologists Marriage & Family Therapists Music Therapists Occupational Therapists Recreational Therapists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Art, Drama, & Music Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities music, dance, visual arts, drama, or sculpture English language Education and Training teaching and course design Communications multimedia Math and Science psychology 
Skills Basic Skills talking to others using the best training or teaching strategies for learning new things Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic create new and original ideas make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Attention to Detail Cooperation Self Control Integrity Achievement/Effort 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Illustrator Next Limit Maxwell Render Computer based training software Blackboard software Moodle Video creation and editing software Adobe Systems Adobe After Effects Apple Final Cut Pro 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $77,280 $40,960 $167,610 Check out my state Local Salary Info Find Jobs 
Explore More Architecture Teachers, Postsecondary Area, Ethnic, & Cultural Studies Teachers, Postsecondary Communications Teachers, Postsecondary English Language & Literature Teachers, Postsecondary Self-Enrichment Teachers You might like a career in one of these industries: Education 
##############################################
Title: Astronomers 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Engineering and Technology computers and electronics product and service development Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills reading work related information using scientific rules and strategies to solve problems People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Initiative Achievement/Effort Integrity Attention to Detail Persistence 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software Microsoft PowerPoint Development environment software Abstraction plus reference plus synthesis A++ National Instruments LabVIEW 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $128,330 $63,390 $181,510 Check out my state Local Salary Info Find Jobs 
Explore More Data Scientists Geoscientists Mathematicians Physicists Physics Teachers, Postsecondary You might like a career in one of these industries: Professional, Science, & Technical Education Government 
##############################################
Title: Athletes & Sports Competitors 
Information:
Knowledge Business management customer service Arts and Humanities English language Communications multimedia Education and Training teaching and course design 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social changing what is done based on other people's actions understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Endurance exercise for a long time without getting out of breath Physical Strength lift, push, pull, or carry exercise for a long time without your muscles getting tired Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Stress Tolerance Achievement/Effort Self Control Persistence Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Video creation and editing software YouTube Electronic mail software Email software 
Education Education: (rated 2 of 5) no high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $94,270 $28,510 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Amusement & Recreation Attendants Athletic Trainers Coaches & Scouts Exercise Trainers & Group Fitness Instructors Umpires, Referees, & Other Sports Officials You might like a career in one of these industries: Arts & Entertainment 
##############################################
Title: Athletic Trainers 
Information:
Knowledge Health medicine and dentistry therapy and counseling Business customer service administrative services Math and Science psychology biology Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Concern for Others Dependability Attention to Detail Self Control Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software Database software Premier Software Simtrak Mobility 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $53,840 $39,670 $78,000 Check out my state Local Salary Info Find Jobs 
Explore More Exercise Physiologists Exercise Trainers & Group Fitness Instructors Occupational Therapists Physical Therapist Assistants Sports Medicine Physicians You might like a career in one of these industries: Health & Counseling Education Arts & Entertainment 
##############################################
Title: Atmospheric & Space Scientists 
Information:
Knowledge Math and Science physics arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Engineering and Technology computers and electronics product and service development Communications multimedia 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Dependability Attention to Detail Stress Tolerance Adaptability/Flexibility Achievement/Effort 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics SAS Presentation software Microsoft PowerPoint Graphics or photo imaging software Adobe Systems Adobe Photoshop Microsoft Paint 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $83,780 $50,490 $141,520 Check out my state Local Salary Info Find Jobs 
Explore More Climate Change Policy Analysts Data Scientists Geoscientists Hydrologists Remote Sensing Scientists & Technologists You might like a career in one of these industries: Government Professional, Science, & Technical Media & Communication Education 
##############################################
Title: Atmospheric, Earth, Marine, & Space Sciences Teachers, Postsecondary 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Engineering and Technology computers and electronics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Analytical Thinking Independence Dependability Initiative Achievement/Effort 
Technology You might use software like this on the job: Analytical or scientific software The MathWorks MATLAB Wolfram Research Mathematica Computer based training software Blackboard software Learning management system LMS Graphics or photo imaging software Adobe Systems Adobe After Effects Adobe Systems Adobe Photoshop 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $97,770 $50,590 $178,740 Check out my state Local Salary Info Find Jobs 
Explore More Biological Science Teachers, Postsecondary Chemistry Teachers, Postsecondary Environmental Science Teachers, Postsecondary Geography Teachers, Postsecondary Physics Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Audio & Video Technicians 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Communications multimedia telecommunications Arts and Humanities English language music, dance, visual arts, drama, or sculpture Business customer service 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Attention pay attention to something without being distracted do two or more things at the same time Hand and Finger Use keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Adaptability/Flexibility Dependability Cooperation Integrity Stress Tolerance 
Technology You might use software like this on the job: Video creation and editing software Adobe Systems Adobe After Effects YouTube Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop Operating system software Linux Microsoft Windows 
Education Education: (rated 3 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $50,660 $31,310 $89,990 Check out my state Local Salary Info Find Jobs 
Explore More Broadcast Technicians Calibration Technologists & Technicians Camera Operators, Television, Video, & Film Lighting Technicians Sound Engineering Technicians You might like a career in one of these industries: Media & Communication Education Real Estate & Rentals Service Professional, Science, & Technical 
##############################################
Title: Audiologists 
Information:
Knowledge Business customer service sales and marketing Health therapy and counseling medicine and dentistry Math and Science psychology biology Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Hearing and Speech speak clearly recognize spoken words Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Concern for Others Integrity Dependability Attention to Detail Cooperation Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software eClinicalWorks EHR software Vestibular Technologies ScreenTRAK 
Education Education: (rated 5 of 5) doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $82,680 $56,990 $120,380 Check out my state Local Salary Info Find Jobs 
Explore More Emergency Medicine Physicians Hearing Aid Specialists Occupational Therapists Optometrists Pediatric Surgeons You might like a career in one of these industries: Health & Counseling Retail Education 
##############################################
Title: Audiovisual Equipment Installers & Repairers 
Information:
Knowledge Engineering and Technology computers and electronics mechanical Business customer service management Communications telecommunications Arts and Humanities English language 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Initiative Analytical Thinking Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Mobile location based services software Global positioning system GPS software Office suite software Microsoft Office software 
Education Education: (rated 3 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $44,960 $30,620 $69,060 Check out my state Local Salary Info Find Jobs 
Explore More Electrical & Electronics Repairers, Commercial & Industrial Equipment Electronic Equipment Installers & Repairers, Motor Vehicles Radio, Cellular, & Tower Equipment Installers & Repairers Telecommunications Equipment Installers & Repairers Telecommunications Line Installers & Repairers You might like a career in one of these industries: Construction Retail 
##############################################
Title: Automotive & Watercraft Service Attendants 
Information:
Knowledge Business customer service sales and marketing Engineering and Technology mechanical product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Endurance exercise for a long time without getting out of breath Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Self Control Dependability Analytical Thinking Integrity Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Development environment software Software libraries 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $30,850 $24,660 $40,290 Check out my state Local Salary Info Find Jobs 
Explore More Automotive Service Technicians & Mechanics Bus & Truck Mechanics & Diesel Engine Specialists Cleaners of Vehicles & Equipment Maintenance Workers, Machinery Pump Operators You might like a career in one of these industries: Retail Service 
##############################################
Title: Automotive Body & Related Repairers 
Information:
Knowledge Engineering and Technology mechanical Business customer service Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Achievement/Effort Innovation Analytical Thinking 
Technology You might use software like this on the job: Operating system software Microsoft Windows Word processing software Microsoft OneNote Microsoft Word Analytical or scientific software Collision damage estimation software Swan River Estimiser Pro 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,670 $32,370 $78,460 Check out my state Local Salary Info Find Jobs 
Explore More Automotive Service Technicians & Mechanics Electric Motor, Power Tool, & Related Repairers Grinding & Polishing Workers, Hand Rail Car Repairers Tire Builders You might like a career in one of these industries: Service Retail 
##############################################
Title: Automotive Engineering Technicians 
Information:
Knowledge Engineering and Technology product and service development mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Hand and Finger Use put together small parts with your fingers keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Analytical Thinking Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Computer aided design CAD software Autodesk AutoCAD Mechanical PTC Creo Parametric Analytical or scientific software A&D Technology iTest Data acquisition software 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $61,990 $39,890 $94,720 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Calibration Technologists & Technicians Electrical & Electronic Engineering Technologists & Technicians Mechanical Engineering Technologists & Technicians Robotics Technicians You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Automotive Engineers 
Information:
Knowledge Engineering and Technology product and service development mechanical Math and Science physics arithmetic, algebra, geometry, calculus, or statistics Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Analytical Thinking Dependability Attention to Detail Initiative Persistence 
Technology You might use software like this on the job: Analytical or scientific software Minitab The MathWorks MATLAB Presentation software Microsoft PowerPoint Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $96,310 $61,990 $151,260 Check out my state Local Salary Info Find Jobs 
Explore More Electrical Engineers Electronics Engineers Industrial Engineers Mechanical Engineers Mechatronics Engineers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Automotive Glass Installers & Repairers 
Information:
Knowledge Business customer service management Engineering and Technology mechanical Arts and Humanities English language 
Skills Basic Skills talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Integrity Self Control Initiative 
Technology You might use software like this on the job: Operating system software Microsoft Windows Enterprise resource planning ERP software Workday software Data base user interface and query software Recordkeeping software 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $43,700 $29,650 $59,900 Check out my state Local Salary Info Find Jobs 
Explore More Automotive Body & Related Repairers Automotive Service Technicians & Mechanics Cleaners of Vehicles & Equipment Glaziers Molders, Shapers, & Casters You might like a career in one of these industries: Service Retail 
##############################################
Title: Automotive Service Technicians & Mechanics 
Information:
Knowledge Engineering and Technology mechanical computers and electronics Business customer service 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted do two or more things at the same time Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Analytical Thinking Independence Persistence 
Technology You might use software like this on the job: Electronic mail software IBM Notes Microsoft Outlook Video creation and editing software YouTube Facilities management software Alliance Automotive Shop Controller Snap-On ShopKey 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,970 $29,270 $75,360 Check out my state Local Salary Info Find Jobs 
Explore More Bus & Truck Mechanics & Diesel Engine Specialists Electric Motor, Power Tool, & Related Repairers Engine & Other Machine Assemblers Motorboat Mechanics & Service Technicians Rail Car Repairers You might like a career in one of these industries: Retail Service 
##############################################
Title: Aviation Inspectors 
Information:
Knowledge Arts and Humanities English language Engineering and Technology mechanical product and service development Safety and Government public safety and security law and government Business customer service 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Attention pay attention to something without being distracted do two or more things at the same time Hearing and Speech speak clearly 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Dependability Attention to Detail Self Control Stress Tolerance Achievement/Effort 
Technology You might use software like this on the job: Data base user interface and query software Aircraft regulation databases Microsoft Access Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) associate's degree or high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $79,570 $36,770 $126,150 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Avionics Technicians Construction & Building Inspectors Transportation Inspectors Transportation Vehicle, Equipment & Systems Inspectors You might like a career in one of these industries: Government Transportation & Storage 
##############################################
Title: Avionics Technicians 
Information:
Knowledge Engineering and Technology computers and electronics mechanical Arts and Humanities English language Business customer service Communications telecommunications 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written listen and understand what people say Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Analytical Thinking Achievement/Effort Independence 
Technology You might use software like this on the job: Enterprise resource planning ERP software SAP software Workday software Presentation software Microsoft PowerPoint Operating system software Linux UNIX 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $75,450 $40,980 $109,160 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Aircraft Mechanics & Service Technicians Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Electrical & Electronics Repairers, Commercial & Industrial Equipment Electro-Mechanical & Mechatronics Technologists & Technicians You might like a career in one of these industries: Transportation & Storage Manufacturing 
##############################################
Title: Baggage Porters & Bellhops 
Information:
Knowledge Business customer service Arts and Humanities English language Safety and Government public safety and security Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Attention do two or more things at the same time pay attention to something without being distracted Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Integrity Self Control Concern for Others Cooperation 
Technology You might use software like this on the job: Office suite software Corel WordPerfect Office Suite Microsoft Office software Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $31,510 $22,210 $45,560 Check out my state Local Salary Info Find Jobs 
Explore More Concierges Flight Attendants Parking Attendants Passenger Attendants Reservation & Transportation Ticket Agents & Travel Clerks You might like a career in one of these industries: Hotel & Food Transportation & Storage 
##############################################
Title: Bailiffs 
Information:
Knowledge Safety and Government public safety and security law and government Arts and Humanities English language Business customer service Math and Science psychology 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Social understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Self Control Dependability Stress Tolerance Cooperation Attention to Detail 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access National Crime Information Center (NCIC) database Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 3 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $49,100 $28,290 $82,530 Check out my state Local Salary Info Find Jobs 
Explore More Correctional Officers & Jailers Detectives & Criminal Investigators First-Line Supervisors of Police & Detectives Police & Sheriff's Patrol Officers Probation Officers & Correctional Treatment Specialists You might like a career in one of these industries: Government 
##############################################
Title: Bakers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products food production Business customer service management Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements figuring out how to use new ideas or things Social changing what is done based on other people's actions 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Cooperation Dependability Integrity Achievement/Effort Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Enterprise resource planning ERP software Sage 100 ERP TwinPeaks Software Visual Z-Bake 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $32,780 $24,060 $45,650 Check out my state Local Salary Info Find Jobs 
Explore More Chefs & Head Cooks Cooks, Restaurant Food & Tobacco Roasting, Baking, & Drying Machine Operators & Tenders Food Batchmakers Food Cooking Machine Operators & Tenders You might like a career in one of these industries: Manufacturing Retail Hotel & Food 
##############################################
Title: Barbers 
Information:
Knowledge Business customer service management Arts and Humanities English language Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Cooperation Self Control Dependability Concern for Others Social Orientation 
Technology You might use software like this on the job: Operating system software Linux Microsoft Windows Video creation and editing software YouTube Calendar and scheduling software Appointment scheduling software 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $34,990 $22,880 $60,910 Check out my state Local Salary Info Find Jobs 
Explore More Hairdressers, Hairstylists, & Cosmetologists Manicurists & Pedicurists Shampooers Skincare Specialists Tailors, Dressmakers, & Custom Sewers You might like a career in one of these industries: Service 
##############################################
Title: Baristas 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Attention do two or more things at the same time 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Cooperation Dependability Self Control Stress Tolerance Integrity Concern for Others 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Office suite software Microsoft Office software Word processing software Microsoft Word 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $27,930 $21,150 $35,350 Check out my state Local Salary Info Find Jobs 
Explore More Cooks, Short Order Dining Room & Cafeteria Attendants & Bartender Helpers Fast Food & Counter Workers Food Preparation Workers Waiters & Waitresses You might like a career in one of these industries: Hotel & Food 
##############################################
Title: Bartenders 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language Education and Training teaching and course design Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use hold or move items with your hands keep your arm or hand steady Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Self Control Integrity Attention to Detail Cooperation Social Orientation 
Technology You might use software like this on the job: Web page creation and editing software Facebook Point of sale POS software Focus point of sale POS software Intuit QuickBooks Point of Sale Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $29,380 $19,100 $57,220 Check out my state Local Salary Info Find Jobs 
Explore More Baristas Dining Room & Cafeteria Attendants & Bartender Helpers Fast Food & Counter Workers Food Preparation Workers Waiters & Waitresses You might like a career in one of these industries: Hotel & Food 
##############################################
Title: Bicycle Repairers 
Information:
Knowledge Engineering and Technology mechanical product and service development Business customer service sales and marketing Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people understanding people's reactions 
Abilities Hand and Finger Use put together small parts with your fingers hold or move items with your hands Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Analytical Thinking Cooperation Dependability Self Control Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software RepairTRAX Upland Consulting Group Repair Traq Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $36,250 $27,040 $47,690 Check out my state Local Salary Info Find Jobs 
Explore More Electric Motor, Power Tool, & Related Repairers Motorcycle Mechanics Outdoor Power Equipment & Other Small Engine Mechanics Rail Car Repairers Tire Repairers & Changers You might like a career in one of these industries: Retail 
##############################################
Title: Bill & Account Collectors 
Information:
Knowledge Arts and Humanities English language Business customer service accounting and economics Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social talking people into changing their minds or their behavior understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic group things in different ways notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Dependability Independence Cooperation Analytical Thinking 
Technology You might use software like this on the job: Customer relationship management CRM software Austin Logistics CallSelect Microsoft Dynamics Presentation software Microsoft PowerPoint Data base user interface and query software Relational database software 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $39,470 $29,510 $59,610 Check out my state Local Salary Info Find Jobs 
Explore More Billing & Posting Clerks Credit Authorizers, Checkers, & Clerks Customer Service Representatives Tax Examiners & Collectors, & Revenue Agents Tellers You might like a career in one of these industries: Administration & Support Services Finance & Insurance Health & Counseling 
##############################################
Title: Billing & Posting Clerks 
Information:
Knowledge Business administrative services customer service Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information using math to solve problems Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Dependability Attention to Detail Independence Concern for Others Cooperation 
Technology You might use software like this on the job: Accounting software Intuit QuickBooks Sage 50 Accounting Presentation software Microsoft PowerPoint Medical software Epic Systems MEDITECH software 
Education Education: (rated 3 of 5) some college or high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $42,810 $31,580 $60,480 Check out my state Local Salary Info Find Jobs 
Explore More Bookkeeping, Accounting, & Auditing Clerks Brokerage Clerks Customer Service Representatives Insurance Claims & Policy Processing Clerks Payroll & Timekeeping Clerks You might like a career in one of these industries: Health & Counseling Professional, Science, & Technical 
##############################################
Title: Biochemists & Biophysicists 
Information:
Knowledge Math and Science biology chemistry Arts and Humanities English language Education and Training teaching and course design Engineering and Technology computers and electronics 
Skills Basic Skills using scientific rules and strategies to solve problems reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information group things in different ways Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Analytical Thinking Persistence Attention to Detail Achievement/Effort Dependability 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics Minitab Presentation software Microsoft PowerPoint Graphics or photo imaging software Adobe Systems Adobe Photoshop Graphics software 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $103,810 $61,540 $171,010 Check out my state Local Salary Info Find Jobs 
Explore More Chemists Geneticists Medical Scientists Microbiologists Molecular & Cellular Biologists You might like a career in one of these industries: Professional, Science, & Technical Manufacturing 
##############################################
Title: Bioengineers & Biomedical Engineers 
Information:
Knowledge Engineering and Technology product and service development computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics biology Arts and Humanities English language Health medicine and dentistry 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Cooperation Attention to Detail Analytical Thinking Persistence Achievement/Effort 
Technology You might use software like this on the job: Analytical or scientific software SAS The MathWorks MATLAB Enterprise application integration software Extensible markup language XML Microsoft Teams Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $99,550 $63,420 $159,130 Check out my state Local Salary Info Find Jobs 
Explore More Biochemists & Biophysicists Bioinformatics Scientists Chemical Engineers Nanosystems Engineers Nanotechnology Engineering Technologists & Technicians You might like a career in one of these industries: Professional, Science, & Technical Manufacturing 
##############################################
Title: Biofuels Processing Technicians 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical computers and electronics Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills reading work related information keeping track of how well people and/or groups are doing in order to make improvements Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use hold or move items with your hands keep your arm or hand steady Attention pay attention to something without being distracted do two or more things at the same time Controlled Movement quickly change the controls of a machine, car, truck or boat 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Adaptability/Flexibility Concern for Others 
Technology You might use software like this on the job: Object or component oriented development software Python R Analytical or scientific software Data visualization software SAS Industrial control software Digital control systems DCS Human machine interface HMI software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $57,470 $37,100 $85,760 Check out my state Local Salary Info Find Jobs 
Explore More Biomass Plant Technicians Chemical Plant & System Operators Petroleum Pump System Operators, Refinery Operators, & Gaugers Power Plant Operators Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing Government 
##############################################
Title: Biofuels Production Managers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Business management human resources (HR) Engineering and Technology mechanical product and service development Math and Science chemistry 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Resource Management selecting and managing the best workers for a job managing your time and the time of other people 
Abilities Verbal listen and understand what people say communicate by speaking Attention pay attention to something without being distracted do two or more things at the same time Ideas and Logic notice when problems happen use rules to solve problems Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Attention to Detail Leadership Initiative Integrity Analytical Thinking 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Industrial control software Distributed control system DCS Human machine interface HMI software 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $107,560 $67,720 $178,470 Check out my state Local Salary Info Find Jobs 
Explore More Biofuels Processing Technicians Biofuels/Biodiesel Technology & Product Development Managers Biomass Plant Technicians Biomass Power Plant Managers Geothermal Production Managers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Biofuels/Biodiesel Technology & Product Development Managers 
Information:
Knowledge Arts and Humanities English language Math and Science chemistry arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology product and service development computers and electronics Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Adaptability/Flexibility Analytical Thinking Dependability Integrity Attention to Detail Stress Tolerance 
Technology You might use software like this on the job: Object or component oriented development software Microsoft SQL Server Reporting Services SSRS Perl Presentation software Microsoft PowerPoint Enterprise resource planning ERP software Microsoft Dynamics SAP software 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $159,920 $102,450 $221,550 Check out my state Local Salary Info Find Jobs 
Explore More Biofuels Production Managers Biomass Power Plant Managers Chemical Engineers Geothermal Production Managers Manufacturing Engineers You might like a career in one of these industries: Professional, Science, & Technical Manufacturing 
##############################################
Title: Bioinformatics Scientists 
Information:
Knowledge Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Persistence Dependability Integrity Independence 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software Microsoft PowerPoint Object or component oriented development software Microsoft SQL Server Reporting Services SSRS Scala 
Education Education: (rated 5 of 5) bachelor's degree or post-doctoral training usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $87,300 $50,730 $155,570 Check out my state Local Salary Info Find Jobs 
Explore More Biochemists & Biophysicists Bioinformatics Technicians Data Scientists Geneticists Molecular & Cellular Biologists You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Bioinformatics Technicians 
Information:
Knowledge Engineering and Technology computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics biology Arts and Humanities English language 
Skills Basic Skills reading work related information figuring out how to use new ideas or things Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Cooperation Analytical Thinking Achievement/Effort Dependability Initiative 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software Microsoft PowerPoint Object or component oriented development software Perl R 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $71,700 $38,500 $145,280 Check out my state Local Salary Info Find Jobs 
Explore More Biological Technicians Data Scientists Database Architects Health Information Technologists & Medical Registrars Statistical Assistants You might like a career in one of these industries: Professional, Science, & Technical Government Education 
##############################################
Title: Biological Science Teachers, Postsecondary 
Information:
Knowledge Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Education and Training teaching and course design Engineering and Technology computers and electronics 
Skills Basic Skills talking to others using the best training or teaching strategies for learning new things Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Achievement/Effort Integrity Attention to Detail Persistence Independence Initiative 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Computer based training software Learning management system LMS Moodle Word processing software Google Docs Microsoft Word 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $81,650 $49,800 $166,750 Check out my state Local Salary Info Find Jobs 
Explore More Atmospheric, Earth, Marine, & Space Sciences Teachers, Postsecondary Chemistry Teachers, Postsecondary Environmental Science Teachers, Postsecondary Health Specialties Teachers, Postsecondary Molecular & Cellular Biologists You might like a career in one of these industries: Education 
##############################################
Title: Biological Technicians 
Information:
Knowledge Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information order or arrange things Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Independence Analytical Thinking Cooperation 
Technology You might use software like this on the job: Analytical or scientific software Laboratory information management system LIMS SAS Presentation software Microsoft PowerPoint Graphics or photo imaging software Adobe Systems Adobe Photoshop Graphics software 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $49,650 $33,480 $79,110 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Technicians Cytogenetic Technologists Histotechnologists Medical & Clinical Laboratory Technicians Medical & Clinical Laboratory Technologists You might like a career in one of these industries: Professional, Science, & Technical Education Government Health & Counseling 
##############################################
Title: Biologists 
Information:
Knowledge Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Safety and Government law and government Business management 
Skills Basic Skills using scientific rules and strategies to solve problems listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information group things in different ways Math choose the right type of math to solve a problem add, subtract, multiply, or divide Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Cooperation Integrity Analytical Thinking Initiative Dependability 
Technology You might use software like this on the job: Analytical or scientific software Minitab Visual Molecular Dynamics VMD Presentation software Microsoft PowerPoint Object or component oriented development software Perl R 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $87,300 $50,730 $155,570 Check out my state Local Salary Info Find Jobs 
Explore More Environmental Scientists & Specialists, Including Health Geneticists Molecular & Cellular Biologists Soil & Plant Scientists Zoologists & Wildlife Biologists You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Biomass Plant Technicians 
Information:
Knowledge Engineering and Technology mechanical product and service development Math and Science chemistry Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Ideas and Logic notice when problems happen order or arrange things Attention pay attention to something without being distracted do two or more things at the same time Verbal read and understand what is written 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Cooperation Stress Tolerance Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Development environment software National Instruments LabVIEW 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $93,060 $52,000 $119,880 Check out my state Local Salary Info Find Jobs 
Explore More Biofuels Processing Technicians Hydroelectric Plant Technicians Power Plant Operators Stationary Engineers & Boiler Operators Water & Wastewater Treatment Plant & System Operators You might like a career in one of these industries: Utilities Government 
##############################################
Title: Biomass Power Plant Managers 
Information:
Knowledge Engineering and Technology mechanical product and service development Manufactured or Agricultural Goods manufacture and distribution of products Business management human resources (HR) Safety and Government public safety and security 
Skills Basic Skills talking to others thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Resource Management selecting and managing the best workers for a job managing your time and the time of other people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Stress Tolerance Integrity Dependability Cooperation Initiative Leadership 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $107,560 $67,720 $178,470 Check out my state Local Salary Info Find Jobs 
Explore More Biofuels Production Managers Biomass Plant Technicians Geothermal Production Managers Hydroelectric Plant Technicians Hydroelectric Production Managers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Biostatisticians 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Engineering and Technology computers and electronics Health medicine and dentistry 
Skills Basic Skills using math to solve problems figuring out how to use new ideas or things Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking read and understand what is written Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Integrity Attention to Detail Cooperation Independence Dependability 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft SQL Server Oracle Database 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,920 $58,090 $161,300 Check out my state Local Salary Info Find Jobs 
Explore More Bioinformatics Scientists Bioinformatics Technicians Clinical Data Managers Data Scientists Statisticians You might like a career in one of these industries: Professional, Science, & Technical Government Education 
##############################################
Title: Blockchain Engineers 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Development environment software Apache Kafka Go Object or component oriented development software C# TypeScript Application server software Docker Kubernetes 
Education Education: (rated 4 of 5) Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,740 $46,540 $166,780 Check out my state Local Salary Info Find Jobs 
Explore More Computer Systems Analysts Computer Systems Engineers/Architects Database Architects Information Security Engineers Software Developers You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Boilermakers 
Information:
Knowledge Engineering and Technology design building and construction Math and Science arithmetic, algebra, geometry, calculus, or statistics Manufactured or Agricultural Goods manufacture and distribution of products Business customer service 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Ideas and Logic notice when problems happen use rules to solve problems Verbal listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Initiative Persistence Achievement/Effort Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Computer based training software Health and safety training software 
Education Education: (rated 3 of 5) no high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $66,920 $46,560 $95,700 Check out my state Local Salary Info Find Jobs 
Explore More Helpers--Pipelayers, Plumbers, Pipefitters, & Steamfitters Millwrights Plumbers, Pipefitters, & Steamfitters Structural Metal Fabricators & Fitters Welders, Cutters, Solderers, & Brazers You might like a career in one of these industries: Construction Manufacturing 
##############################################
Title: Bookkeeping, Accounting, & Auditing Clerks 
Information:
Knowledge Business customer service administrative services Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills using math to solve problems listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Dependability Attention to Detail Integrity Cooperation Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Accounting software Intuit QuickBooks Sage 50 Accounting Cloud-based data access and sharing software Dropbox Microsoft SharePoint Enterprise resource planning ERP software Microsoft Dynamics SAP software 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $45,860 $30,460 $65,540 Check out my state Local Salary Info Find Jobs 
Explore More Billing & Posting Clerks Brokerage Clerks Credit Authorizers, Checkers, & Clerks Office Clerks, General Payroll & Timekeeping Clerks You might like a career in one of these industries: Professional, Science, & Technical 
##############################################
Title: Brickmasons & Blockmasons 
Information:
Knowledge Engineering and Technology building and construction design Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Physical Strength use your lower back and stomach lift, push, pull, or carry Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Independence Achievement/Effort 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Project management software Daystar iStructural.com Estimating software Accounting software Intuit QuickBooks 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $59,000 $37,490 $93,290 Check out my state Local Salary Info Find Jobs 
Explore More Carpenters Floor Layers Helpers--Brickmasons, Blockmasons, Stonemasons, & Tile & Marble Setters Refractory Materials Repairers Tile & Stone Setters You might like a career in one of these industries: Construction 
##############################################
Title: Bridge & Lock Tenders 
Information:
Knowledge Safety and Government public safety and security Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hearing and Speech pay attention to one sound while there are other distracting sounds recognize spoken words Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Self Control Integrity Cooperation Concern for Others 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Enterprise resource planning ERP software Oracle PeopleSoft SAP software Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,280 $26,630 $67,330 Check out my state Local Salary Info Find Jobs 
Explore More Captains, Mates, & Pilots of Water Vessels Hoist & Winch Operators Locomotive Engineers Rail Yard Engineers, Dinkey Operators, & Hostlers Railroad Brake, Signal, & Switch Operators & Locomotive Firers You might like a career in one of these industries: Government Construction 
##############################################
Title: Broadcast Announcers & Radio Disc Jockeys 
Information:
Knowledge Communications multimedia telecommunications Arts and Humanities English language Engineering and Technology computers and electronics Business customer service 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic create new and original ideas come up with lots of ideas Attention pay attention to something without being distracted do two or more things at the same time Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Dependability Integrity Attention to Detail Stress Tolerance Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Word processing software Burli Software Burli Newsroom System Microsoft Word Presentation software Microsoft PowerPoint Music or sound editing software Adobe Systems Adobe Audition Audion Laboratories VoxPro 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $41,750 $23,920 $171,650 Check out my state Local Salary Info Find Jobs 
Explore More Media Programming Directors Media Technical Directors/Managers News Analysts, Reporters, & Journalists Producers & Directors Public Relations Specialists You might like a career in one of these industries: Media & Communication 
##############################################
Title: Broadcast Technicians 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Communications telecommunications multimedia Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen order or arrange things Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Adaptability/Flexibility Stress Tolerance Integrity 
Technology You might use software like this on the job: Video creation and editing software Adobe Systems Adobe After Effects Apple Final Cut Pro Presentation software Microsoft PowerPoint Operating system software Linux UNIX 
Education Education: (rated 3 of 5) associate's degree or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $60,700 $29,450 $128,960 Check out my state Local Salary Info Find Jobs 
Explore More Audio & Video Technicians Calibration Technologists & Technicians Electrical & Electronic Engineering Technologists & Technicians Electrical & Electronics Repairers, Commercial & Industrial Equipment Sound Engineering Technicians You might like a career in one of these industries: Media & Communication 
##############################################
Title: Brokerage Clerks 
Information:
Knowledge Arts and Humanities English language Business customer service accounting and economics Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Math add, subtract, multiply, or divide choose the right type of math to solve a problem Ideas and Logic order or arrange things notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Dependability Integrity Attention to Detail Analytical Thinking Stress Tolerance Persistence 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Structured query language SQL Presentation software Microsoft PowerPoint Customer relationship management CRM software Royal Alliance VISION2020 Core Salesforce software 
Education Education: (rated 3 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $54,680 $40,090 $79,860 Check out my state Local Salary Info Find Jobs 
Explore More Billing & Posting Clerks Bookkeeping, Accounting, & Auditing Clerks Customer Service Representatives New Accounts Clerks Securities, Commodities, & Financial Services Sales Agents You might like a career in one of these industries: Finance & Insurance 
##############################################
Title: Brownfield Redevelopment Specialists & Site Managers 
Information:
Knowledge Arts and Humanities English language Business customer service management Safety and Government law and government Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Attention to Detail Analytical Thinking Dependability Cooperation Persistence 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software ESRI ArcView Presentation software Microsoft PowerPoint Enterprise resource planning ERP software Oracle Hyperion SAP software 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $128,620 $63,380 $218,550 Check out my state Local Salary Info Find Jobs 
Explore More Conservation Scientists Environmental Engineers Environmental Restoration Planners Environmental Science & Protection Technicians, Including Health Environmental Scientists & Specialists, Including Health You might like a career in one of these industries: Government 
##############################################
Title: Budget Analysts 
Information:
Knowledge Business accounting and economics management Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Math add, subtract, multiply, or divide choose the right type of math to solve a problem Verbal listen and understand what people say read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Analytical Thinking Dependability Cooperation Stress Tolerance 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Structured query language SQL Presentation software Microsoft PowerPoint SAP Crystal Xcelsius Enterprise resource planning ERP software Microsoft Dynamics GP Sage Active Planner 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $82,260 $53,000 $128,170 Check out my state Local Salary Info Find Jobs 
Explore More Accountants & Auditors Financial Examiners Financial Managers Personal Financial Advisors Treasurers & Controllers You might like a career in one of these industries: Government Education Professional, Science, & Technical 
##############################################
Title: Bus & Truck Mechanics & Diesel Engine Specialists 
Information:
Knowledge Engineering and Technology mechanical computers and electronics Transportation movement of people or goods by air, rail, sea, or road Education and Training teaching and course design Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Ideas and Logic notice when problems happen use rules to solve problems Hearing and Speech tell the difference between sounds 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Analytical Thinking Initiative Integrity Self Control 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks Facilities management software Computerized maintenance management system software CMMS Shop management software Analytical or scientific software Cummins INSITE Engine diagnostic software 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $54,360 $37,030 $78,340 Check out my state Local Salary Info Find Jobs 
Explore More Automotive Service Technicians & Mechanics Engine & Other Machine Assemblers Mobile Heavy Equipment Mechanics Motorboat Mechanics & Service Technicians Rail Car Repairers You might like a career in one of these industries: Transportation & Storage Wholesale/Commercial Sales Government Service 
##############################################
Title: Bus Drivers, School 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Operating system software Microsoft Windows Internet browser software Web browser software Map creation software AOL MapQuest 
Education Education: (rated 2 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $41,270 $25,390 $58,390 Check out my state Local Salary Info Find Jobs 
Explore More Bus Drivers, Transit & Intercity Railroad Conductors & Yardmasters School Bus Monitors Shuttle Drivers & Chauffeurs Taxi Drivers You might like a career in one of these industries: Education Transportation & Storage Government 
##############################################
Title: Bus Drivers, Transit & Intercity 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Business customer service Safety and Government public safety and security law and government Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Social looking for ways to help people understanding people's reactions 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Vision see details that are far away decide which thing is closer or farther away from you or decide how far away it is from you Attention pay attention to something without being distracted do two or more things at the same time Spatial know where things are around you 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Self Control Cooperation Concern for Others Attention to Detail Independence 
Technology You might use software like this on the job: Operating system software Microsoft Windows Map creation software AOL MapQuest Microsoft MapPoint Internet browser software Web browser software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $50,890 $33,610 $80,420 Check out my state Local Salary Info Find Jobs 
Explore More Bus Drivers, School Railroad Conductors & Yardmasters Shuttle Drivers & Chauffeurs Subway & Streetcar Operators Taxi Drivers You might like a career in one of these industries: Government Transportation & Storage 
##############################################
Title: Business Continuity Planners 
Information:
Knowledge Business management customer service Arts and Humanities English language Engineering and Technology computers and electronics Safety and Government public safety and security 
Skills Basic Skills talking to others reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Cooperation Dependability Attention to Detail Persistence Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Microsoft SQL Server Structured query language SQL Presentation software Mentimeter Microsoft PowerPoint Enterprise resource planning ERP software Oracle JD Edwards EnterpriseOne Virtual Corporation Sustainable Planner 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $75,990 $40,310 $131,260 Check out my state Local Salary Info Find Jobs 
Explore More Compliance Managers Emergency Management Directors Information Security Engineers Information Technology Project Managers Project Management Specialists You might like a career in one of these industries: Government Professional, Science, & Technical Education 
##############################################
Title: Business Intelligence Analysts 
Information:
Knowledge Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Business management Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Dependability Initiative Integrity Persistence 
Technology You might use software like this on the job: Development environment software Apache Kafka Go Data base user interface and query software Airtable IBM DB2 Web platform development software Django Microsoft ASP.NET 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $103,500 $58,510 $174,790 Check out my state Local Salary Info Find Jobs 
Explore More Computer Systems Analysts Data Scientists Market Research Analysts & Marketing Specialists Marketing Managers Statistical Assistants You might like a career in one of these industries: Professional, Science, & Technical Finance & Insurance Media & Communication Management 
##############################################
Title: Business Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Business management accounting and economics Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Analytical Thinking Independence Achievement/Effort Attention to Detail Adaptability/Flexibility 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Computer based training software Blackboard software Schoology Word processing software Google Docs Microsoft Word 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $88,790 $48,100 $206,630 Check out my state Local Salary Info Find Jobs 
Explore More Career/Technical Education Teachers, Secondary School Economics Teachers, Postsecondary Instructional Coordinators Law Teachers, Postsecondary Management Analysts You might like a career in one of these industries: Education 
##############################################
Title: Butchers & Meat Cutters 
Information:
Knowledge Business customer service sales and marketing Manufactured or Agricultural Goods food production manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Cooperation Integrity Attention to Detail Self Control Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Accounting software Financial accounting software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $36,930 $26,030 $51,180 Check out my state Local Salary Info Find Jobs 
Explore More Cooks, Restaurant Cooks, Short Order Food Preparation Workers Meat, Poultry, & Fish Cutters & Trimmers Slaughterers & Meat Packers You might like a career in one of these industries: Retail 
##############################################
Title: Buyers & Purchasing Agents, Farm Products 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Business customer service accounting and economics Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language 
Skills Basic Skills talking to others thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Social bringing people together to solve differences talking people into changing their minds or their behavior 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Stress Tolerance Attention to Detail Dependability Integrity Analytical Thinking Initiative 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics GP SAP software Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access Oracle Database 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $67,620 $41,060 $116,370 Check out my state Local Salary Info Find Jobs 
Explore More Farmers, Ranchers, & Other Agricultural Managers Purchasing Agents Purchasing Managers Sales Representatives, Wholesale & Manufacturing Wholesale & Retail Buyers You might like a career in one of these industries: Manufacturing Wholesale/Commercial Sales Government 
##############################################
Title: Cabinetmakers & Bench Carpenters 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology building and construction design Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Cooperation Stress Tolerance Adaptability/Flexibility Concern for Others 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Facilities management software Computerized maintenance management system CMMS 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $38,810 $29,120 $58,570 Check out my state Local Salary Info Find Jobs 
Explore More Carpenters Furniture Finishers Model Makers, Wood Structural Metal Fabricators & Fitters Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Calibration Technologists & Technicians 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Civil 3D Bentley MicroStation Operating system software Apple macOS Linux Development environment software National Instruments LabVIEW 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $62,050 $39,760 $99,300 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Electrical & Electronic Engineering Technologists & Technicians Electrical & Electronics Repairers, Commercial & Industrial Equipment Mechanical Engineering Technologists & Technicians Robotics Technicians You might like a career in one of these industries: Professional, Science, & Technical Manufacturing Service 
##############################################
Title: Camera & Photographic Equipment Repairers 
Information:
Knowledge Engineering and Technology mechanical computers and electronics Business customer service Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Persistence Achievement/Effort Stress Tolerance Analytical Thinking 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software RepairTRAX Electronic mail software Email software 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $44,060 $30,010 $71,750 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Electrical & Electronics Repairers, Commercial & Industrial Equipment Lighting Technicians Motion Picture Projectionists Photographic Process Workers & Processing Machine Operators You might like a career in one of these industries: Education Service 
##############################################
Title: Camera Operators, Television, Video, & Film 
Information:
Knowledge Arts and Humanities English language Engineering and Technology computers and electronics Communications multimedia telecommunications 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Ideas and Logic notice when problems happen order or arrange things Verbal communicate by speaking listen and understand what people say Hand and Finger Use keep your arm or hand steady hold or move items with your hands Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Achievement/Effort Persistence Adaptability/Flexibility 
Technology You might use software like this on the job: Video creation and editing software Adobe Systems Adobe After Effects YouTube Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Illustrator Analytical or scientific software Litchi Pix4D Pix4Dcapture 
Education Education: (rated 3 of 5) associate's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $58,230 $33,190 $116,770 Check out my state Local Salary Info Find Jobs 
Explore More Audio & Video Technicians Avionics Technicians Broadcast Technicians Electro-Mechanical & Mechatronics Technologists & Technicians Media Technical Directors/Managers You might like a career in one of these industries: Media & Communication Professional, Science, & Technical 
##############################################
Title: Captains, Mates, & Pilots of Water Vessels 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Safety and Government public safety and security law and government Engineering and Technology mechanical Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions teaching people how to do something 
Abilities Verbal communicate by speaking listen and understand what people say Spatial know where things are around you imagine how something will look after it is moved around or changed Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Self Control Leadership Stress Tolerance Attention to Detail Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Route navigation software FURUNO navigational chart software Navigational chart software 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $95,210 $42,090 $160,140 Check out my state Local Salary Info Find Jobs 
Explore More Airline Pilots, Copilots, & Flight Engineers Bridge & Lock Tenders Motorboat Operators Sailors & Marine Oilers Ship Engineers You might like a career in one of these industries: Transportation & Storage 
##############################################
Title: Cardiologists 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Medical software Epic Systems MEDITECH software Transaction security and virus protection software Watchman Monitoring 
Education Education: (rated 5 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $239,200+ $132,630 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More General Internal Medicine Physicians Neurologists Pediatric Surgeons Radiologists Urologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Cardiovascular Technologists & Technicians 
Information:
Knowledge Business customer service Arts and Humanities English language Health medicine and dentistry Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns Hand and Finger Use keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Concern for Others Attention to Detail Cooperation Dependability Stress Tolerance Integrity 
Technology You might use software like this on the job: Web platform development software Hypertext preprocessor PHP JavaScript Medical software MEDITECH software Smart Digital Holter Monitor Data base user interface and query software Database software Structured data entry software 
Education Education: (rated 3 of 5) associate's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $63,020 $33,950 $102,000 Check out my state Local Salary Info Find Jobs 
Explore More Diagnostic Medical Sonographers Magnetic Resonance Imaging Technologists Neurodiagnostic Technologists Radiation Therapists Radiologic Technologists & Technicians You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Career/Technical Education Teachers, Middle School 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Math and Science psychology arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social teaching people how to do something understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted do two or more things at the same time 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Concern for Others Dependability Self Control Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Object or component oriented development software Objective C Scala Word processing software Google Docs Microsoft Word Development environment software Apache Kafka Go 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $62,630 $46,790 $99,250 Check out my state Local Salary Info Find Jobs 
Explore More Career/Technical Education Teachers, Postsecondary Career/Technical Education Teachers, Secondary School Middle School Teachers Secondary School Teachers Special Education Teachers, Elementary School You might like a career in one of these industries: Education 
##############################################
Title: Career/Technical Education Teachers, Postsecondary 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language Engineering and Technology mechanical product and service development Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions using the best training or teaching strategies for learning new things Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Attention to Detail Dependability Self Control Cooperation Leadership 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Common Curriculum Moodle Medical software Medical condition coding software Medical procedure coding software 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $59,840 $37,680 $102,330 Check out my state Local Salary Info Find Jobs 
Explore More Career/Technical Education Teachers, Middle School Career/Technical Education Teachers, Secondary School Engineering Teachers, Postsecondary Special Education Teachers, Elementary School Teaching Assistants, Special Education You might like a career in one of these industries: Education 
##############################################
Title: Career/Technical Education Teachers, Secondary School 
Information:
Knowledge Business customer service administrative services Education and Training teaching and course design Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen group things in different ways Attention pay attention to something without being distracted do two or more things at the same time Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Integrity Attention to Detail Adaptability/Flexibility Concern for Others Leadership 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Padlet Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 4 of 5) bachelor's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $62,500 $47,250 $98,090 Check out my state Local Salary Info Find Jobs 
Explore More Career/Technical Education Teachers, Middle School Career/Technical Education Teachers, Postsecondary Instructional Coordinators Secondary School Teachers Special Education Teachers, Elementary School You might like a career in one of these industries: Education 
##############################################
Title: Cargo & Freight Agents 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Math and Science geography Safety and Government public safety and security law and government Arts and Humanities English language 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Stress Tolerance Adaptability/Flexibility Dependability Independence Achievement/Effort 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Word processing software Microsoft OneNote Microsoft Word Mobile location based services software Transportation management system TMS software Web-based dispatch software 
Education Education: (rated 2 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $46,860 $32,720 $70,280 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Cargo Handling Supervisors Customs Brokers Freight Forwarders Postal Service Clerks Shipping, Receiving, & Inventory Clerks You might like a career in one of these industries: Transportation & Storage 
##############################################
Title: Carpenters 
Information:
Knowledge Engineering and Technology building and construction design Business management customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Ideas and Logic notice when problems happen order or arrange things Spatial imagine how something will look after it is moved around or changed Physical Strength use your lower back and stomach 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Initiative Achievement/Effort Adaptability/Flexibility 
Technology You might use software like this on the job: Accounting software Intuit QuickBooks Quicken Project management software Turtle Creek Software Goldenseal VirtualBoss Word processing software Microsoft Word Wilhelm Publishing Threshold 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $51,390 $36,160 $89,950 Check out my state Local Salary Info Find Jobs 
Explore More Brickmasons & Blockmasons Drywall & Ceiling Tile Installers Sheet Metal Workers Structural Iron & Steel Workers Structural Metal Fabricators & Fitters You might like a career in one of these industries: Construction 
##############################################
Title: Carpet Installers 
Information:
Knowledge Business customer service management Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Engineering and Technology building and construction 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath Physical Strength use your lower back and stomach lift, push, pull, or carry 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Independence Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software Aya Associates Comp-U-Floor Carpet Dealer Management System CDMS Project management software FIRST Flooring Measure Square FloorEstimate Pro 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $45,240 $29,120 $78,600 Check out my state Local Salary Info Find Jobs 
Explore More Drywall & Ceiling Tile Installers Floor Layers Floor Sanders & Finishers Insulation Workers, Floor, Ceiling, & Wall Tile & Stone Setters You might like a career in one of these industries: Construction Retail 
##############################################
Title: Cartographers & Photogrammetrists 
Information:
Knowledge Math and Science geography arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics design Arts and Humanities English language 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Adaptability/Flexibility Innovation Initiative 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Fireworks Adobe Systems Adobe Illustrator Presentation software Microsoft PowerPoint Map creation software Mapping software Precision analytical aerotriangulation pugging software 
Education Education: (rated 4 of 5) certificate after high school or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $71,890 $47,550 $107,600 Check out my state Local Salary Info Find Jobs 
Explore More Geodetic Surveyors Geographic Information Systems Technologists & Technicians Remote Sensing Technicians Surveying & Mapping Technicians Surveyors You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Cashiers 
Information:
Knowledge Business customer service management Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Cooperation Dependability Integrity Self Control Stress Tolerance Attention to Detail 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software Database software ReliaSoft Prism Operating system software Handheld computer device software Microsoft Windows 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $28,240 $21,440 $35,900 Check out my state Local Salary Info Find Jobs 
Explore More Counter & Rental Clerks First-Line Supervisors of Retail Sales Workers Order Clerks Retail Salespersons Tellers You might like a career in one of these industries: Retail Hotel & Food 
##############################################
Title: Cement Masons & Concrete Finishers 
Information:
Knowledge Arts and Humanities English language Engineering and Technology building and construction Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Physical Strength use your lower back and stomach 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Persistence Self Control Stress Tolerance Cooperation 
Technology You might use software like this on the job: Project management software National Concrete & Masonry Estimator Tradesman's Software Master Estimator Analytical or scientific software ADAPT-Modeler Shilstone seeMIX Accounting software Sirus GT Construction Accounting 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $48,300 $35,940 $80,680 Check out my state Local Salary Info Find Jobs 
Explore More Brickmasons & Blockmasons Construction Laborers Segmental Pavers Terrazzo Workers & Finishers Tile & Stone Setters You might like a career in one of these industries: Construction 
##############################################
Title: Chefs & Head Cooks 
Information:
Knowledge Manufactured or Agricultural Goods food production manufacture and distribution of products Business customer service management Education and Training teaching and course design Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Social changing what is done based on other people's actions understanding people's reactions Resource Management managing your time and the time of other people selecting and managing the best workers for a job 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted do two or more things at the same time Hand and Finger Use hold or move items with your hands 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Initiative Adaptability/Flexibility Leadership Stress Tolerance Attention to Detail 
Technology You might use software like this on the job: Spreadsheet software Google Sheets Microsoft Excel Instant messaging software GroupMe Data base user interface and query software Culinary Software Services ChefTec ReServe Interactive 
Education Education: (rated 3 of 5) associate's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $56,520 $33,750 $91,520 Check out my state Local Salary Info Find Jobs 
Explore More Bakers Cooks, Institution & Cafeteria Cooks, Private Household Cooks, Restaurant Cooks, Short Order You might like a career in one of these industries: Hotel & Food 
##############################################
Title: Chemical Engineers 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics chemistry Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills using scientific rules and strategies to solve problems thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Ideas and Logic group things in different ways notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal read and understand what is written listen and understand what people say Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Integrity Attention to Detail Dependability Achievement/Effort Initiative 
Technology You might use software like this on the job: Analytical or scientific software Minitab The MathWorks MATLAB Presentation software Microsoft PowerPoint Computer aided design CAD software Autodesk AutoCAD Dassault Systemes CATIA 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $106,260 $72,490 $171,400 Check out my state Local Salary Info Find Jobs 
Explore More Chemists Manufacturing Engineers Materials Engineers Mechanical Engineers Nuclear Engineers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Chemical Equipment Operators & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Math and Science chemistry arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology mechanical computers and electronics Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use hold or move items with your hands keep your arm or hand steady Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Adaptability/Flexibility Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software IBM Notes Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $49,330 $34,360 $80,480 Check out my state Local Salary Info Find Jobs 
Explore More Chemical Plant & System Operators Cleaning, Washing, & Metal Pickling Equipment Operators & Tenders Heat Treating Equipment Setters, Operators, & Tenders, Metal & Plastic Mixing & Blending Machine Setters, Operators, & Tenders Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Chemical Plant & System Operators 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Math and Science chemistry Engineering and Technology mechanical Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted Hand and Finger Use keep your arm or hand steady hold or move items with your hands Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Integrity Stress Tolerance Analytical Thinking Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Industrial control software Alarm management system software Distributed control system DCS Network security or virtual private network VPN management software Coordinated incident management system CIMS software 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $82,670 $45,930 $107,680 Check out my state Local Salary Info Find Jobs 
Explore More Chemical Equipment Operators & Tenders Petroleum Pump System Operators, Refinery Operators, & Gaugers Power Plant Operators Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders Water & Wastewater Treatment Plant & System Operators You might like a career in one of these industries: Manufacturing 
##############################################
Title: Chemical Technicians 
Information:
Knowledge Math and Science chemistry arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills using scientific rules and strategies to solve problems reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems measuring how well a system is working and how to improve it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal read and understand what is written communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Hand and Finger Use hold or move items with your hands keep your arm or hand steady Math choose the right type of math to solve a problem 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Integrity Dependability Analytical Thinking Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Oracle Database Structured query language SQL Presentation software Microsoft PowerPoint Object or component oriented development software C++ Oracle Java 
Education Education: (rated 3 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $50,840 $35,770 $81,610 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Food Science Technicians Histotechnologists Nanotechnology Engineering Technologists & Technicians Quality Control Analysts You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Chemistry Teachers, Postsecondary 
Information:
Knowledge Math and Science chemistry arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Education and Training teaching and course design Engineering and Technology product and service development 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Analytical Thinking Integrity Attention to Detail Dependability Adaptability/Flexibility Independence 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Analytical or scientific software OriginLab Origin PerkinElmer ChemOffice Suite Computer based training software Learning management system LMS Moodle 
Education Education: (rated 5 of 5) master's degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $80,720 $50,130 $163,180 Check out my state Local Salary Info Find Jobs 
Explore More Atmospheric, Earth, Marine, & Space Sciences Teachers, Postsecondary Biological Science Teachers, Postsecondary Environmental Science Teachers, Postsecondary Mathematical Science Teachers, Postsecondary Physics Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Chemists 
Information:
Knowledge Math and Science chemistry arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products Business management 
Skills Basic Skills using scientific rules and strategies to solve problems reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Independence Integrity Dependability Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Structured query language SQL Presentation software Apple iWork Keynote Microsoft PowerPoint Analytical or scientific software Minitab Waters Empower Chromatography Data Software 
Education Education: (rated 4 of 5) bachelor's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $80,670 $49,050 $139,200 Check out my state Local Salary Info Find Jobs 
Explore More Biochemists & Biophysicists Bioengineers & Biomedical Engineers Chemical Engineers Materials Engineers Medical & Clinical Laboratory Technologists You might like a career in one of these industries: Manufacturing Professional, Science, & Technical Government 
##############################################
Title: Chief Executives 
Information:
Knowledge Business management human resources (HR) Arts and Humanities English language Safety and Government public safety and security law and government Engineering and Technology computers and electronics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Leadership Initiative Persistence Analytical Thinking Achievement/Effort Integrity 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Presentation software Mentimeter Microsoft PowerPoint Accounting software Intuit QuickBooks Sage 50 Accounting 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $189,520 $74,920 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Financial Managers General & Operations Managers Human Resources Managers Social & Community Service Managers Treasurers & Controllers You might like a career in one of these industries: Professional, Science, & Technical 
##############################################
Title: Chief Sustainability Officers 
Information:
Knowledge Arts and Humanities English language Business management customer service Safety and Government law and government Communications multimedia 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem writing things for co-workers or customers Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it 
Abilities Verbal communicate by writing communicate by speaking Ideas and Logic come up with lots of ideas create new and original ideas 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Leadership Initiative Cooperation Persistence Integrity Analytical Thinking 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics GP SAP software Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $189,520 $74,920 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Climate Change Policy Analysts Environmental Scientists & Specialists, Including Health Industrial Ecologists Sustainability Specialists Wind Energy Development Managers You might like a career in one of these industries: Professional, Science, & Technical 
##############################################
Title: Child, Family, & School Social Workers 
Information:
Knowledge Business customer service administrative services Health therapy and counseling Math and Science psychology sociology and anthropology Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Dependability Stress Tolerance Integrity Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Student information systems SIS Computer based training software EasyCBM Spreadsheet software Microsoft Excel 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $50,820 $36,380 $81,920 Check out my state Local Salary Info Find Jobs 
Explore More Healthcare Social Workers Marriage & Family Therapists Mental Health & Substance Abuse Social Workers Rehabilitation Counselors Social & Human Service Assistants You might like a career in one of these industries: Government Health & Counseling Education 
##############################################
Title: Childcare Workers 
Information:
Knowledge Business customer service Arts and Humanities English language Education and Training teaching and course design Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems Attention do two or more things at the same time pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Self Control Concern for Others Integrity Stress Tolerance Cooperation Attention to Detail 
Technology You might use software like this on the job: Word processing software Microsoft Word Computer based training software Educational software Schoology Multi-media educational software Nearpod Seesaw 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $28,520 $21,250 $39,090 Check out my state Local Salary Info Find Jobs 
Explore More Home Health Aides Kindergarten Teachers Nannies Personal Care Aides Preschool Teachers You might like a career in one of these industries: Health & Counseling Service Education 
##############################################
Title: Chiropractors 
Information:
Knowledge Health medicine and dentistry therapy and counseling Arts and Humanities English language Business customer service management Math and Science biology 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Hand and Finger Use keep your arm or hand steady put together small parts with your fingers 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Integrity Self Control Dependability Leadership Attention to Detail 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software Advantage Software Chiropractic Advantage Versatile Software Systems VersaSoft Chiro Accounting software Billing software EZClaim medical billing software 
Education Education: (rated 5 of 5) doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $75,380 $38,170 $132,630 Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians Neurologists Orthopedic Surgeons Pediatric Surgeons You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Choreographers 
Information:
Knowledge Arts and Humanities music, dance, visual arts, drama, or sculpture English language Business management Education and Training teaching and course design Communications multimedia 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social teaching people how to do something changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Endurance exercise for a long time without getting out of breath Flexibility, Balance, and Coordination move your arms, legs, and mid-section together while your whole body is moving keep your balance or stay upright Ideas and Logic come up with lots of ideas 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Persistence Initiative Achievement/Effort Dependability Innovation Adaptability/Flexibility 
Technology You might use software like this on the job: Web page creation and editing software Facebook Social media sites Video creation and editing software YouTube Graphics or photo imaging software Chorel Technology Dance Designer Credo Interactive DanceForms 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $50,990 $29,020 $100,420 Check out my state Local Salary Info Find Jobs 
Explore More Actors Art, Drama, & Music Teachers, Postsecondary Dancers Music Directors & Composers Musicians & Singers You might like a career in one of these industries: Education Arts & Entertainment 
##############################################
Title: Civil Engineering Technologists & Technicians 
Information:
Knowledge Engineering and Technology product and service development building and construction Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Business administrative services management Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic use rules to solve problems notice when problems happen Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Attention to Detail Dependability Cooperation Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Civil 3D Autodesk Revit Presentation software Microsoft PowerPoint Geographic information system ESRI ArcGIS software Geographic information system GIS software 
Education Education: (rated 3 of 5) certificate after high school or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $59,630 $37,430 $85,740 Check out my state Local Salary Info Find Jobs 
Explore More Architectural & Civil Drafters Construction & Building Inspectors Construction Managers Electrical & Electronics Drafters Mechanical Engineering Technologists & Technicians You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Civil Engineers 
Information:
Knowledge Engineering and Technology design product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Business management 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Dependability Analytical Thinking Attention to Detail Initiative Self Control 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Civil 3D Autodesk Revit Presentation software Microsoft PowerPoint Analytical or scientific software Minitab The MathWorks MATLAB 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $89,940 $61,040 $138,690 Check out my state Local Salary Info Find Jobs 
Explore More Civil Engineering Technologists & Technicians Construction & Building Inspectors Construction Managers Transportation Engineers Water/Wastewater Engineers You might like a career in one of these industries: Professional, Science, & Technical Government Construction 
##############################################
Title: Claims Adjusters, Examiners, & Investigators 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Stress Tolerance Dependability Attention to Detail Initiative Achievement/Effort 
Technology You might use software like this on the job: Office suite software Business software applications Microsoft Office software Video conferencing software Zoom Document management software Document management system software InSystems Calligo Document Management System 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $72,230 $46,040 $102,630 Check out my state Local Salary Info Find Jobs 
Explore More Compensation, Benefits, & Job Analysis Specialists Credit Authorizers, Checkers, & Clerks Eligibility Interviewers, Government Programs Fraud Examiners, Investigators & Analysts Insurance Claims & Policy Processing Clerks You might like a career in one of these industries: Finance & Insurance Government 
##############################################
Title: Cleaners of Vehicles & Equipment 
Information:
Knowledge Business customer service Arts and Humanities English language Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Independence Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Operating system software Microsoft Windows Data base user interface and query software Bella FSM Auto Detailing Service Software Green Cloud KleanTRAC Calendar and scheduling software BookFresh Thoughtful Systems Scheduling Manager for Auto Detailing 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $31,000 $22,500 $43,520 Check out my state Local Salary Info Find Jobs 
Explore More Cleaning, Washing, & Metal Pickling Equipment Operators & Tenders Grinding & Polishing Workers, Hand Janitors & Cleaners Laundry & Dry-Cleaning Workers Maintenance Workers, Machinery You might like a career in one of these industries: Service Retail 
##############################################
Title: Cleaning, Washing, & Metal Pickling Equipment Operators & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Persistence Achievement/Effort Initiative Stress Tolerance Attention to Detail Dependability 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Word processing software Microsoft Word 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $37,190 $28,460 $52,460 Check out my state Local Salary Info Find Jobs 
Explore More Chemical Plant & System Operators Furnace, Kiln, Oven, Drier, & Kettle Operators & Tenders Laundry & Dry-Cleaning Workers Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders Textile Bleaching & Dyeing Machine Operators & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Clergy 
Information:
Knowledge Arts and Humanities philosophy and religion English language Business management customer service Education and Training teaching and course design Health therapy and counseling 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Attention pay attention to something without being distracted Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Leadership Initiative Integrity Dependability Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Instant messaging software GroupMe Twitter Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $55,550 $31,170 $93,530 Check out my state Local Salary Info Find Jobs 
Explore More Child, Family, & School Social Workers Directors, Religious Activities & Education Educational, Guidance, & Career Counselors & Advisors Marriage & Family Therapists Philosophy & Religion Teachers, Postsecondary You might like a career in one of these industries: Service 
##############################################
Title: Climate Change Policy Analysts 
Information:
Knowledge Safety and Government law and government Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems measuring how well a system is working and how to improve it figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Integrity Persistence Attention to Detail Initiative Cooperation 
Technology You might use software like this on the job: Analytical or scientific software SAS The MathWorks MATLAB Presentation software Microsoft PowerPoint Object or component oriented development software Perl R 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $76,480 $46,920 $130,770 Check out my state Local Salary Info Find Jobs 
Explore More Chief Sustainability Officers Environmental Economists Environmental Scientists & Specialists, Including Health Industrial Ecologists Sustainability Specialists You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Clinical & Counseling Psychologists 
Information:
Knowledge Health therapy and counseling Math and Science psychology sociology and anthropology Business customer service administrative services Arts and Humanities English language 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Concern for Others Self Control Stress Tolerance Cooperation Integrity Dependability 
Technology You might use software like this on the job: Spreadsheet software Google Sheets Microsoft Excel Word processing software Google Docs Microsoft Word Medical software eClinicalWorks EHR software UNI/CARE Pro-Filer 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $90,130 $42,760 $168,790 Check out my state Local Salary Info Find Jobs 
Explore More Clinical Neuropsychologists Marriage & Family Therapists Mental Health & Substance Abuse Social Workers Mental Health Counselors Psychiatrists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Clinical Data Managers 
Information:
Knowledge Arts and Humanities English language Engineering and Technology computers and electronics Business customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Cooperation Analytical Thinking Persistence 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Structured query language SQL Presentation software Microsoft PowerPoint Analytical or scientific software IBM SPSS Statistics SAS 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $103,500 $58,510 $174,790 Check out my state Local Salary Info Find Jobs 
Explore More Bioinformatics Technicians Clinical Research Coordinators Data Scientists Health Informatics Specialists Social Science Research Assistants You might like a career in one of these industries: Professional, Science, & Technical Finance & Insurance Media & Communication Management 
##############################################
Title: Clinical Neuropsychologists 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics Statistical software Presentation software Microsoft PowerPoint Medical software Patient electronic medical record EMR software The Tova Company Test of Variables of Attention 
Education Education: (rated 5 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $106,420 $40,000 $138,860 Check out my state Local Salary Info Find Jobs 
Explore More Advanced Practice Psychiatric Nurses Clinical Nurse Specialists Neurologists Neuropsychologists Psychiatrists You might like a career in one of these industries: Government 
##############################################
Title: Clinical Nurse Specialists 
Information:
Knowledge Health medicine and dentistry therapy and counseling Education and Training teaching and course design Arts and Humanities English language Math and Science biology 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Attention do two or more things at the same time pay attention to something without being distracted Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Adaptability/Flexibility Dependability Leadership Attention to Detail Analytical Thinking 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software GE Healthcare Centricity EMR Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 5 of 5) master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $81,220 $61,250 $129,400 Check out my state Local Salary Info Find Jobs 
Explore More Acute Care Nurses Advanced Practice Psychiatric Nurses Critical Care Nurses Nurse Practitioners Registered Nurses You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Clinical Research Coordinators 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Health medicine and dentistry 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen use rules to solve problems Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Cooperation Integrity Dependability Self Control Attention to Detail Initiative 
Technology You might use software like this on the job: Data base user interface and query software Invivo Data EPX ePRO Management System PPD Patient Profiles Video conferencing software Google Meet Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB 
Education Education: (rated 4 of 5) bachelor's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $144,440 $73,730 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Clinical Data Managers Clinical Nurse Specialists Health Informatics Specialists Health Specialties Teachers, Postsecondary Medical & Health Services Managers You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Coaches & Scouts 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language Business management customer service Math and Science psychology 
Skills Basic Skills talking to others keeping track of how well people and/or groups are doing in order to make improvements Social teaching people how to do something understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic create new and original ideas order or arrange things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Leadership Integrity Cooperation Self Control Concern for Others 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Video creation and editing software Flipgrid YouTube Desktop communications software Edmodo ParentSquare 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $44,890 $24,910 $93,590 Check out my state Local Salary Info Find Jobs 
Explore More Athletes & Sports Competitors Athletic Trainers Exercise Trainers & Group Fitness Instructors Self-Enrichment Teachers Umpires, Referees, & Other Sports Officials You might like a career in one of these industries: Education Arts & Entertainment 
##############################################
Title: Coating, Painting, & Spraying Machine Setters, Operators, & Tenders 
Information:
Knowledge Engineering and Technology mechanical Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Adaptability/Flexibility Persistence Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Inventory management software Inventory control software Inventory management systems Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $43,960 $31,200 $63,430 Check out my state Local Salary Info Find Jobs 
Explore More Furniture Finishers Grinding & Polishing Workers, Hand Molders, Shapers, & Casters Painting, Coating, & Decorating Workers Plating Machine Setters, Operators, & Tenders, Metal & Plastic You might like a career in one of these industries: Manufacturing Service 
##############################################
Title: Coil Winders, Tapers, & Finishers 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Business management 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Achievement/Effort Leadership Initiative Integrity 
Technology You might use software like this on the job: Graphics or photo imaging software Blueprint display software Analytical or scientific software Electronic Systems of Wisconsin Motor Test System software Industrial control software Machine Control Specialists CoilPro 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $43,160 $31,620 $59,660 Check out my state Local Salary Info Find Jobs 
Explore More Electrical & Electronic Equipment Assemblers Extruding & Forming Machine Setters, Operators, & Tenders, Synthetic & Glass Fibers Grinding & Polishing Workers, Hand Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Welding, Soldering, & Brazing Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Coin, Vending, & Amusement Machine Servicers & Repairers 
Information:
Knowledge Engineering and Technology computers and electronics mechanical Business customer service Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Stress Tolerance Self Control Dependability Initiative Attention to Detail Adaptability/Flexibility 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $39,690 $26,630 $59,930 Check out my state Local Salary Info Find Jobs 
Explore More Computer, Automated Teller, & Office Machine Repairers Industrial Machinery Mechanics Maintenance Workers, Machinery Packaging & Filling Machine Operators & Tenders Paper Goods Machine Setters, Operators, & Tenders You might like a career in one of these industries: Retail Arts & Entertainment Government Wholesale/Commercial Sales Manufacturing 
##############################################
Title: Commercial & Industrial Designers 
Information:
Knowledge Engineering and Technology design product and service development Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Ideas and Logic come up with lots of ideas create new and original ideas Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Initiative Dependability Analytical Thinking Cooperation Persistence 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk Revit Dassault Systemes SolidWorks Video creation and editing software Adobe Systems Adobe After Effects Kapwing Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Trimble SketchUp Pro 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $75,910 $43,680 $125,780 Check out my state Local Salary Info Find Jobs 
Explore More Craft Artists Fabric & Apparel Patternmakers Fashion Designers Mechanical Drafters Model Makers, Metal & Plastic You might like a career in one of these industries: Manufacturing Professional, Science, & Technical Wholesale/Commercial Sales 
##############################################
Title: Commercial Divers 
Information:
Knowledge Engineering and Technology mechanical building and construction Math and Science physics arithmetic, algebra, geometry, calculus, or statistics Business customer service Safety and Government public safety and security 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions teaching people how to do something 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say communicate by speaking Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Stress Tolerance Persistence Adaptability/Flexibility Integrity 
Technology You might use software like this on the job: Data base user interface and query software Diving logbook software Remote operated vehicle ROV dive log software Analytical or scientific software Dynamic positioning DP software Internet browser software Web browser software 
Education Education: (rated 3 of 5) certificate after high school or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $68,300 $38,390 $171,470 Check out my state Local Salary Info Find Jobs 
Explore More Earth Drillers Plumbers, Pipefitters, & Steamfitters Riggers Sailors & Marine Oilers Ship Engineers You might like a career in one of these industries: Administration & Support Services Construction 
##############################################
Title: Commercial Pilots 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Business customer service management Math and Science geography arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat quickly decide if you should move your hand, foot, or other body part Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted do two or more things at the same time Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Cooperation Self Control Stress Tolerance Initiative 
Technology You might use software like this on the job: Office suite software Microsoft Office software Analytical or scientific software Calibration software Pilot Navigator Software Load Balance Data base user interface and query software Airline Pilots Daily Aviation Log PPC Skylog Services Skylog Pro 
Education Education: (rated 3 of 5) bachelor's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $103,910 $54,100 $217,530 Check out my state Local Salary Info Find Jobs 
Explore More Air Traffic Controllers Airfield Operations Specialists Airline Pilots, Copilots, & Flight Engineers Aviation Inspectors Locomotive Engineers You might like a career in one of these industries: Transportation & Storage Health & Counseling 
##############################################
Title: Communications Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities English language philosophy and religion Education and Training teaching and course design Communications multimedia Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Independence Concern for Others Integrity Self Control Innovation 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Moodle Video creation and editing software Apple Final Cut Pro Video production software 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $76,250 $46,930 $133,720 Check out my state Local Salary Info Find Jobs 
Explore More Education Teachers, Postsecondary English Language & Literature Teachers, Postsecondary Library Science Teachers, Postsecondary Political Science Teachers, Postsecondary Sociology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Community Health Workers 
Information:
Knowledge Business customer service management Arts and Humanities English language Education and Training teaching and course design Health medicine and dentistry 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Integrity Cooperation Adaptability/Flexibility Independence Initiative 
Technology You might use software like this on the job: Office suite software Google Workspace software Microsoft Office software Video conferencing software Zoom Data base user interface and query software Client databases Microsoft Access 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $46,190 $32,180 $73,730 Check out my state Local Salary Info Find Jobs 
Explore More Child, Family, & School Social Workers Health Education Specialists Mental Health & Substance Abuse Social Workers Mental Health Counselors Rehabilitation Counselors You might like a career in one of these industries: Health & Counseling Government Service 
##############################################
Title: Compensation & Benefits Managers 
Information:
Knowledge Business human resources (HR) management Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Attention to Detail Dependability Initiative Cooperation Leadership 
Technology You might use software like this on the job: Enterprise resource planning ERP software SAP software Workday software Presentation software Microsoft PowerPoint Human resources software Human resource management software HRMS Vantage Point Software HRA 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $131,280 $77,230 $217,650 Check out my state Local Salary Info Find Jobs 
Explore More Compensation, Benefits, & Job Analysis Specialists Financial Managers Human Resources Managers Human Resources Specialists Labor Relations Specialists You might like a career in one of these industries: Management Finance & Insurance Professional, Science, & Technical 
##############################################
Title: Compensation, Benefits, & Job Analysis Specialists 
Information:
Knowledge Business human resources (HR) management Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government law and government 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Analytical Thinking Dependability Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics Workday software Presentation software Microsoft PowerPoint Human resources software Human resource management software HRMS Oracle E-Business Suite Human Resources Management System 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $67,780 $42,250 $117,060 Check out my state Local Salary Info Find Jobs 
Explore More Compensation & Benefits Managers Eligibility Interviewers, Government Programs Human Resources Assistants Human Resources Managers Human Resources Specialists You might like a career in one of these industries: Finance & Insurance Government Professional, Science, & Technical Service Management 
##############################################
Title: Compliance Managers 
Information:
Knowledge Safety and Government law and government public safety and security Arts and Humanities English language Business management customer service Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Social changing what is done based on other people's actions talking people into changing their minds or their behavior People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Stress Tolerance Dependability Persistence Self Control 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Compliance software Actimize Brokerage Compliance Solutions Thomson Reuters Paisley Enterprise GRC 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $128,620 $63,380 $218,550 Check out my state Local Salary Info Find Jobs 
Explore More Compliance Officers Environmental Compliance Inspectors Management Analysts Regulatory Affairs Managers Regulatory Affairs Specialists You might like a career in one of these industries: Government 
##############################################
Title: Compliance Officers 
Information:
Knowledge Safety and Government law and government public safety and security Arts and Humanities English language Business customer service management Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Hearing and Speech speak clearly recognize spoken words 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Independence Attention to Detail Analytical Thinking Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $71,690 $41,050 $118,060 Check out my state Local Salary Info Find Jobs 
Explore More Claims Adjusters, Examiners, & Investigators Compliance Managers Eligibility Interviewers, Government Programs Environmental Compliance Inspectors Government Property Inspectors & Investigators You might like a career in one of these industries: Government Finance & Insurance 
##############################################
Title: Computer & Information Research Scientists 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Business management 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Cooperation Attention to Detail Independence Achievement/Effort Initiative 
Technology You might use software like this on the job: Development environment software Apache Kafka Oracle Java 2 Platform Enterprise Edition J2EE Presentation software Microsoft PowerPoint Analytical or scientific software SAS The MathWorks MATLAB 
Education Education: (rated 5 of 5) bachelor's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $136,620 $78,190 $232,010 Check out my state Local Salary Info Find Jobs 
Explore More Computer Programmers Computer Systems Analysts Data Scientists Mathematicians Software Developers You might like a career in one of these industries: Professional, Science, & Technical Government Media & Communication 
##############################################
Title: Computer & Information Systems Managers 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Business customer service management Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Integrity Dependability Initiative Cooperation Analytical Thinking 
Technology You might use software like this on the job: Web platform development software Apache Tomcat Spring Framework Data base user interface and query software Amazon Web Services AWS software Blackboard software Enterprise resource planning ERP software Microsoft Dynamics SAP software 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $164,070 $97,430 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Computer Systems Analysts Computer Systems Engineers/Architects Information Technology Project Managers Project Management Specialists Software Developers You might like a career in one of these industries: Professional, Science, & Technical Media & Communication Finance & Insurance 
##############################################
Title: Computer Hardware Engineers 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Communications telecommunications 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information order or arrange things Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Analytical Thinking Innovation Initiative Cooperation Achievement/Effort 
Technology You might use software like this on the job: Analytical or scientific software SAS The MathWorks MATLAB Desktop communications software Eko Computer aided design CAD software Autodesk AutoCAD Dassault Systemes CATIA 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $132,360 $78,380 $208,200 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Computer Systems Engineers/Architects Electrical & Electronic Engineering Technologists & Technicians Electronics Engineers Software Developers You might like a career in one of these industries: Professional, Science, & Technical Manufacturing Media & Communication 
##############################################
Title: Computer Network Architects 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Communications telecommunications Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic order or arrange things use rules to solve problems Math choose the right type of math to solve a problem 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Analytical Thinking Dependability Persistence Integrity Achievement/Effort 
Technology You might use software like this on the job: Web platform development software Google Angular Spring Framework Instant messaging software Blink Network monitoring software Symantec Intruder Alert Wireshark 
Education Education: (rated 4 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $126,900 $73,490 $185,170 Check out my state Local Salary Info Find Jobs 
Explore More Computer Network Support Specialists Computer Systems Engineers/Architects Information Security Engineers Network & Computer Systems Administrators Software Developers You might like a career in one of these industries: Professional, Science, & Technical Media & Communication 
##############################################
Title: Computer Network Support Specialists 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Communications telecommunications multimedia Business customer service management Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Cooperation Analytical Thinking Attention to Detail Dependability Integrity Adaptability/Flexibility 
Technology You might use software like this on the job: Operating system software Microsoft Windows Server UNIX Shell Presentation software Microsoft PowerPoint Network monitoring software WildPackets OmniPeek Network Analyzer Wireshark 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $68,050 $42,440 $115,220 Check out my state Local Salary Info Find Jobs 
Explore More Computer Network Architects Computer User Support Specialists Information Security Engineers Network & Computer Systems Administrators Software Developers You might like a career in one of these industries: Professional, Science, & Technical Media & Communication Education 
##############################################
Title: Computer Numerically Controlled Tool Operators 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical product and service development Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat change when and how fast you move based on how something else is moving Ideas and Logic notice when problems happen order or arrange things Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Initiative Analytical Thinking Integrity 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks Video conferencing software FaceTime Computer aided manufacturing CAM software 1CadCam Unigraphics Vero International VISI-Series 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,760 $33,360 $63,600 Check out my state Local Salary Info Find Jobs 
Explore More Computer Numerically Controlled Tool Programmers Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Machinists Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Multiple Machine Tool Setters, Operators, & Tenders, Metal & Plastic You might like a career in one of these industries: Manufacturing 
##############################################
Title: Computer Numerically Controlled Tool Programmers 
Information:
Knowledge Engineering and Technology computers and electronics mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements figuring out how to use new ideas or things Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic order or arrange things notice when problems happen Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns Verbal listen and understand what people say 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Independence Analytical Thinking Dependability Integrity Achievement/Effort 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks Video conferencing software FaceTime Computer aided manufacturing CAM software 1CadCam Unigraphics Vero Software WorkNC 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $60,800 $42,990 $90,090 Check out my state Local Salary Info Find Jobs 
Explore More Computer Numerically Controlled Tool Operators Electromechanical Equipment Assemblers Machinists Mechanical Drafters Robotics Technicians You might like a career in one of these industries: Manufacturing 
##############################################
Title: Computer Programmers 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Analytical Thinking Integrity Cooperation Independence Achievement/Effort 
Technology You might use software like this on the job: Development environment software Apache Kafka Apache Maven Presentation software Microsoft PowerPoint Object or component oriented development software Apache Spark Objective C 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $97,800 $54,310 $157,690 Check out my state Local Salary Info Find Jobs 
Explore More Computer Systems Analysts Computer Systems Engineers/Architects Database Architects Software Developers Web & Digital Interface Designers You might like a career in one of these industries: Professional, Science, & Technical Media & Communication 
##############################################
Title: Computer Science Teachers, Postsecondary 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Education and Training teaching and course design Arts and Humanities English language Business customer service 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Dependability Persistence Independence Achievement/Effort Analytical Thinking 
Technology You might use software like this on the job: Object or component oriented development software C# Oracle Java Computer based training software Learning management system LMS Moodle Development environment software C Microsoft Visual Basic 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $84,760 $48,620 $165,880 Check out my state Local Salary Info Find Jobs 
Explore More Business Teachers, Postsecondary Computer & Information Systems Managers Library Science Teachers, Postsecondary Mathematical Science Teachers, Postsecondary Software Developers You might like a career in one of these industries: Education 
##############################################
Title: Computer Systems Analysts 
Information:
Knowledge Engineering and Technology computers and electronics Business customer service management Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills talking to others reading work related information People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems order or arrange things Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Dependability Cooperation Integrity Initiative 
Technology You might use software like this on the job: Development environment software Oracle Java 2 Platform Enterprise Edition J2EE Oracle SQL Developer Data base user interface and query software Blackboard software ServiceNow Web platform development software React Spring Framework 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $102,240 $61,390 $161,980 Check out my state Local Salary Info Find Jobs 
Explore More Computer Systems Engineers/Architects Database Administrators Database Architects Information Security Engineers Software Developers You might like a career in one of these industries: Professional, Science, & Technical Finance & Insurance Management 
##############################################
Title: Computer Systems Engineers/Architects 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Communications telecommunications 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems measuring how well a system is working and how to improve it figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by writing listen and understand what people say Ideas and Logic notice when problems happen create new and original ideas Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Innovation Persistence Adaptability/Flexibility Dependability 
Technology You might use software like this on the job: Web platform development software React Spring Framework Project management software Atlassian Confluence Microsoft Teams Development environment software Apache Maven Oracle Java 2 Platform Enterprise Edition J2EE 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,740 $46,540 $166,780 Check out my state Local Salary Info Find Jobs 
Explore More Blockchain Engineers Computer Systems Analysts Database Architects Information Security Engineers Software Developers You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Computer User Support Specialists 
Information:
Knowledge Engineering and Technology computers and electronics mechanical Business customer service management Communications telecommunications multimedia Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Cooperation Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Web platform development software Google Angular Spring Framework Data base user interface and query software Blackboard software ServiceNow Operating system software Apple iOS Microsoft Windows Server 
Education Education: (rated 3 of 5) bachelor's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,890 $36,580 $94,920 Check out my state Local Salary Info Find Jobs 
Explore More Computer Network Support Specialists Computer Systems Analysts Information Security Engineers Network & Computer Systems Administrators Software Developers You might like a career in one of these industries: Professional, Science, & Technical Education Media & Communication Administration & Support Services 
##############################################
Title: Computer, Automated Teller, & Office Machine Repairers 
Information:
Knowledge Engineering and Technology computers and electronics mechanical Business customer service Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use put together small parts with your fingers hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Analytical Thinking Independence Dependability Persistence Initiative 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Operating system software Linux UNIX 
Education Education: (rated 3 of 5) associate's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $44,910 $31,560 $64,090 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Coin, Vending, & Amusement Machine Servicers & Repairers Electrical & Electronic Engineering Technologists & Technicians Electrical & Electronics Repairers, Commercial & Industrial Equipment Telecommunications Equipment Installers & Repairers You might like a career in one of these industries: Wholesale/Commercial Sales Retail Service 
##############################################
Title: Concierges 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Engineering and Technology computers and electronics Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Attention to Detail Integrity Cooperation Dependability Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Electronic mail software Microsoft Outlook Presentation software Microsoft PowerPoint Accounting software Billing software Budgeting software 
Education Education: (rated 3 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $35,560 $26,880 $56,550 Check out my state Local Salary Info Find Jobs 
Explore More Baggage Porters & Bellhops Counter & Rental Clerks Hotel, Motel, & Resort Desk Clerks Passenger Attendants Reservation & Transportation Ticket Agents & Travel Clerks You might like a career in one of these industries: Real Estate & Rentals Health & Counseling Hotel & Food Service 
##############################################
Title: Conservation Scientists 
Information:
Knowledge Arts and Humanities English language Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Business customer service management Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen order or arrange things Math add, subtract, multiply, or divide choose the right type of math to solve a problem Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Dependability Attention to Detail Cooperation Initiative Independence 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software Geographic information system GIS software Presentation software Microsoft PowerPoint Analytical or scientific software Clover Technology GALENA WinEPIC 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $64,460 $41,360 $102,670 Check out my state Local Salary Info Find Jobs 
Explore More Environmental Restoration Planners Environmental Scientists & Specialists, Including Health Industrial Ecologists Range Managers Soil & Plant Scientists You might like a career in one of these industries: Government Service 
##############################################
Title: Construction & Building Inspectors 
Information:
Knowledge Engineering and Technology building and construction product and service development Business customer service management Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Self Control Cooperation Analytical Thinking 
Technology You might use software like this on the job: Word processing software Inspection Depot Home Guide System Microsoft Word Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $64,480 $40,370 $104,110 Check out my state Local Salary Info Find Jobs 
Explore More Aviation Inspectors Civil Engineering Technologists & Technicians First-Line Supervisors of Construction Trades & Extraction Workers Government Property Inspectors & Investigators Transportation Vehicle, Equipment & Systems Inspectors You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Construction Laborers 
Information:
Knowledge Engineering and Technology building and construction mechanical Safety and Government public safety and security 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Endurance exercise for a long time without getting out of breath Physical Strength lift, push, pull, or carry 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Stress Tolerance Concern for Others 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $40,750 $29,700 $72,430 Check out my state Local Salary Info Find Jobs 
Explore More Brickmasons & Blockmasons Cement Masons & Concrete Finishers Excavating & Loading Machine & Dragline Operators, Surface Mining Paving, Surfacing, & Tamping Equipment Operators Pipelayers You might like a career in one of these industries: Construction 
##############################################
Title: Construction Managers 
Information:
Knowledge Engineering and Technology building and construction product and service development Business management customer service Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Resource Management selecting and managing the best workers for a job managing your time and the time of other people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things notice when problems happen Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Attention to Detail Leadership Initiative Stress Tolerance 
Technology You might use software like this on the job: Data base user interface and query software Database software Yardi software Cloud-based data access and sharing software Dropbox Microsoft SharePoint Project management software HCSS HeavyJob Oracle Primavera Enterprise Project Portfolio Management 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $101,480 $62,210 $168,390 Check out my state Local Salary Info Find Jobs 
Explore More Civil Engineering Technologists & Technicians Civil Engineers Construction & Building Inspectors First-Line Supervisors of Construction Trades & Extraction Workers Solar Energy Installation Managers You might like a career in one of these industries: Construction 
##############################################
Title: Continuous Mining Machine Operators 
Information:
Knowledge Engineering and Technology mechanical Manufactured or Agricultural Goods manufacture and distribution of products Safety and Government law and government Education and Training teaching and course design 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use keep your arm or hand steady hold or move items with your hands Attention pay attention to something without being distracted do two or more things at the same time Hearing and Speech tell the difference between sounds 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Initiative Independence Self Control Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Mobile location based services software Fleet monitoring system software Leica Geosystems FMS 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $57,360 $38,020 $76,870 Check out my state Local Salary Info Find Jobs 
Explore More Earth Drillers Excavating & Loading Machine & Dragline Operators, Surface Mining Helpers--Extraction Workers Loading & Moving Machine Operators, Underground Mining Operating Engineers & Other Construction Equipment Operators You might like a career in one of these industries: Mining, Oil, & Gas 
##############################################
Title: Control & Valve Installers & Repairers 
Information:
Knowledge Engineering and Technology mechanical product and service development Safety and Government public safety and security Business customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Integrity Initiative Cooperation 
Technology You might use software like this on the job: Enterprise resource planning ERP software IBM Maximo Asset Management SAP software Presentation software Microsoft PowerPoint Industrial control software Supervisory control and data acquisition SCADA software Wonderware software 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $64,810 $37,980 $102,570 Check out my state Local Salary Info Find Jobs 
Explore More Electric Motor, Power Tool, & Related Repairers Engine & Other Machine Assemblers Industrial Machinery Mechanics Plumbers, Pipefitters, & Steamfitters Stationary Engineers & Boiler Operators You might like a career in one of these industries: Utilities Government 
##############################################
Title: Conveyor Operators & Tenders 
Information:
Knowledge Arts and Humanities English language Engineering and Technology mechanical Safety and Government public safety and security Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Attention pay attention to something without being distracted Hand and Finger Use hold or move items with your hands 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Self Control Concern for Others Cooperation Attention to Detail Independence 
Technology You might use software like this on the job: Operating system software Microsoft Windows Industrial control software Control system software Sortation software Spreadsheet software Microsoft Excel 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $36,890 $30,770 $53,190 Check out my state Local Salary Info Find Jobs 
Explore More Hoist & Winch Operators Industrial Truck & Tractor Operators Laborers & Freight, Stock, & Material Movers, Hand Machine Feeders & Offbearers Tank Car, Truck, & Ship Loaders You might like a career in one of these industries: Transportation & Storage Manufacturing Wholesale/Commercial Sales 
##############################################
Title: Cooks, Fast Food 
Information:
Knowledge Business management customer service Transportation movement of people or goods by air, rail, sea, or road Communications multimedia Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions Social looking for ways to help people 
Abilities Verbal listen and understand what people say Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Cooperation Dependability Social Orientation Self Control Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Point of sale POS software Foodman Home-Delivery Plexis Software Plexis POS Electronic mail software Microsoft Outlook 
Education Education: (rated 1 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $27,640 $20,680 $35,800 Check out my state Local Salary Info Find Jobs 
Explore More Bakers Cooks, Institution & Cafeteria Cooks, Restaurant Cooks, Short Order Food Preparation Workers You might like a career in one of these industries: Hotel & Food 
##############################################
Title: Cooks, Institution & Cafeteria 
Information:
Knowledge Arts and Humanities English language Manufactured or Agricultural Goods food production manufacture and distribution of products Business customer service management Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Attention do two or more things at the same time pay attention to something without being distracted Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Self Control Integrity Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software IBM Lotus 1-2-3 Microsoft Excel Data base user interface and query software Meals Plus Point of sale POS software PCS Revenue Control Systems FASTRAK School Meal Software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $32,510 $23,250 $45,230 Check out my state Local Salary Info Find Jobs 
Explore More Chefs & Head Cooks Cooks, Private Household Cooks, Restaurant Cooks, Short Order Food Preparation Workers You might like a career in one of these industries: Health & Counseling Education Hotel & Food 
##############################################
Title: Cooks, Private Household 
Information:
Knowledge Business customer service sales and marketing Manufactured or Agricultural Goods food production manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Ideas and Logic come up with lots of ideas notice when problems happen Hand and Finger Use hold or move items with your hands put together small parts with your fingers Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Dependability Attention to Detail Integrity Independence Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Accounting software Cost tracking software Intuit QuickBooks Video creation and editing software YouTube Data base user interface and query software APPCA Personal Chef Office Cooking e-books 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $38,570 $24,950 $92,030 Check out my state Local Salary Info Find Jobs 
Explore More Chefs & Head Cooks Cooks, Fast Food Cooks, Institution & Cafeteria Cooks, Restaurant Cooks, Short Order You might like a career in one of these industries: Service 
##############################################
Title: Cooks, Restaurant 
Information:
Knowledge Manufactured or Agricultural Goods food production manufacture and distribution of products Business customer service Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Resource Management managing your time and the time of other people 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Cooperation Dependability Attention to Detail Stress Tolerance Self Control Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Point of sale POS software Point of sale POS restaurant software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $34,110 $23,610 $44,570 Check out my state Local Salary Info Find Jobs 
Explore More Bakers Cooks, Fast Food Cooks, Institution & Cafeteria Cooks, Short Order Food Preparation Workers You might like a career in one of these industries: Hotel & Food 
##############################################
Title: Cooks, Short Order 
Information:
Knowledge Business customer service Manufactured or Agricultural Goods food production manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Attention do two or more things at the same time pay attention to something without being distracted Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Self Control Cooperation Stress Tolerance Attention to Detail Adaptability/Flexibility 
Technology You might use software like this on the job: Point of sale POS software Foodman Home-Delivery Plexis Software Plexis POS Inventory management software Inventory control software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $30,360 $21,510 $40,110 Check out my state Local Salary Info Find Jobs 
Explore More Cooks, Fast Food Cooks, Institution & Cafeteria Cooks, Restaurant Fast Food & Counter Workers Food Preparation Workers You might like a career in one of these industries: Hotel & Food Retail 
##############################################
Title: Cooling & Freezing Equipment Operators & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products food production Arts and Humanities English language Engineering and Technology mechanical Business management 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic notice when problems happen use rules to solve problems Verbal communicate by speaking communicate by writing 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Integrity Leadership Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Google Gmail 
Education Education: (rated 2 of 5) no high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $42,890 $29,910 $61,560 Check out my state Local Salary Info Find Jobs 
Explore More Cleaning, Washing, & Metal Pickling Equipment Operators & Tenders Food & Tobacco Roasting, Baking, & Drying Machine Operators & Tenders Furnace, Kiln, Oven, Drier, & Kettle Operators & Tenders Heat Treating Equipment Setters, Operators, & Tenders, Metal & Plastic Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Coroners 
Information:
Knowledge Arts and Humanities English language Health medicine and dentistry Business customer service management Safety and Government law and government 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Hand and Finger Use hold or move items with your hands 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Attention to Detail Dependability Self Control Cooperation Stress Tolerance 
Technology You might use software like this on the job: Data base user interface and query software Structured query language SQL Transact-SQL Video conferencing software Zoom Graphics or photo imaging software Graphics software Mideo Systems EZDoc Plus 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $71,690 $41,050 $118,060 Check out my state Local Salary Info Find Jobs 
Explore More Detectives & Criminal Investigators Forensic Science Technicians Medical Records Specialists Police & Sheriff's Patrol Officers Police Identification & Records Officers You might like a career in one of these industries: Government Finance & Insurance 
##############################################
Title: Correctional Officers & Jailers 
Information:
Knowledge Safety and Government public safety and security law and government Arts and Humanities English language Business customer service management Engineering and Technology computers and electronics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social understanding people's reactions changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted do two or more things at the same time Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Self Control Integrity Stress Tolerance Attention to Detail Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Data base user interface and query software 3M Electronic Monitoring Microsoft Access Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $49,610 $35,510 $82,600 Check out my state Local Salary Info Find Jobs 
Explore More Bailiffs First-Line Supervisors of Correctional Officers First-Line Supervisors of Police & Detectives Police & Sheriff's Patrol Officers Probation Officers & Correctional Treatment Specialists You might like a career in one of these industries: Government 
##############################################
Title: Correspondence Clerks 
Information:
Knowledge Business administrative services customer service Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics 
Skills Basic Skills writing things for co-workers or customers listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Initiative Cooperation Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software Electronic health record EHR software Healthcare common procedure coding system HCPCS 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $38,700 $29,930 $59,840 Check out my state Local Salary Info Find Jobs 
Explore More Executive Secretaries & Executive Administrative Assistants File Clerks Legal Secretaries & Administrative Assistants Office Clerks, General Secretaries & Administrative Assistants You might like a career in one of these industries: Finance & Insurance Administration & Support Services Professional, Science, & Technical Health & Counseling 
##############################################
Title: Cost Estimators 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Business accounting and economics management Engineering and Technology computers and electronics product and service development Arts and Humanities English language 
Skills Basic Skills reading work related information using math to solve problems Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Analytical Thinking Dependability Cooperation Persistence 
Technology You might use software like this on the job: Project management software Microsoft Project Oracle Primavera Enterprise Project Portfolio Management Word processing software Microsoft OneNote Microsoft Word Financial analysis software CPR Visual Estimator Primavera Cost Management 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $71,200 $42,110 $120,600 Check out my state Local Salary Info Find Jobs 
Explore More Civil Engineering Technologists & Technicians Construction Managers Industrial Engineers Logistics Engineers Production, Planning, & Expediting Clerks You might like a career in one of these industries: Construction Manufacturing 
##############################################
Title: Costume Attendants 
Information:
Knowledge Arts and Humanities music, dance, visual arts, drama, or sculpture English language Engineering and Technology design Manufactured or Agricultural Goods manufacture and distribution of products Business customer service 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen come up with lots of ideas 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Dependability Cooperation Concern for Others Stress Tolerance Innovation Attention to Detail 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $48,470 $31,310 $85,820 Check out my state Local Salary Info Find Jobs 
Explore More Fabric & Apparel Patternmakers Fashion Designers Makeup Artists, Theatrical & Performance Set & Exhibit Designers Tailors, Dressmakers, & Custom Sewers You might like a career in one of these industries: Media & Communication Arts & Entertainment Education 
##############################################
Title: Counter & Rental Clerks 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Self Control Cooperation Integrity Stress Tolerance 
Technology You might use software like this on the job: Data base user interface and query software Database software Oracle Database Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $35,830 $24,620 $57,490 Check out my state Local Salary Info Find Jobs 
Explore More Cashiers Door-to-Door Sales Workers, News & Street Vendors, & Related Workers Retail Salespersons Sales Representatives, Wholesale & Manufacturing Stockers & Order Fillers You might like a career in one of these industries: Real Estate & Rentals Retail Service 
##############################################
Title: Couriers & Messengers 
Information:
Knowledge Business customer service Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use hold or move items with your hands put together small parts with your fingers Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Integrity Independence Self Control Concern for Others 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $35,280 $26,350 $46,800 Check out my state Local Salary Info Find Jobs 
Explore More Light Truck Drivers Mail Clerks & Mail Machine Operators Postal Service Clerks Postal Service Mail Carriers Shipping, Receiving, & Inventory Clerks You might like a career in one of these industries: Transportation & Storage Health & Counseling 
##############################################
Title: Court Reporters & Simultaneous Captioners 
Information:
Knowledge Arts and Humanities English language Business administrative services customer service Engineering and Technology computers and electronics Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions writing things for co-workers or customers Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by writing Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Independence Integrity Self Control Stress Tolerance 
Technology You might use software like this on the job: Word processing software Advantage Software Total Eclipse Microsoft Word Data base user interface and query software Acclaim Legal Acclaim DepoManage OMTI ReporterBase Enterprise resource planning ERP software Acculaw Court Reporters Billing Scheduling Job Management System ABSMS ReporterWorks 
Education Education: (rated 3 of 5) certificate after high school or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $63,560 $33,030 $116,380 Check out my state Local Salary Info Find Jobs 
Explore More Correspondence Clerks Court, Municipal, & License Clerks Legal Secretaries & Administrative Assistants Paralegals & Legal Assistants Word Processors & Typists You might like a career in one of these industries: Government Administration & Support Services 
##############################################
Title: Court, Municipal, & License Clerks 
Information:
Knowledge Business customer service administrative services Safety and Government law and government Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Dependability Cooperation Self Control Stress Tolerance 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Video conferencing software Zoom Data base user interface and query software Abilis CORIS Offender Management System Microsoft Access 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $44,140 $30,310 $63,640 Check out my state Local Salary Info Find Jobs 
Explore More Compliance Officers Legal Secretaries & Administrative Assistants Office Clerks, General Paralegals & Legal Assistants Secretaries & Administrative Assistants You might like a career in one of these industries: Government 
##############################################
Title: Craft Artists 
Information:
Knowledge Arts and Humanities music, dance, visual arts, drama, or sculpture Business sales and marketing customer service Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology design 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers hold or move items with your hands Ideas and Logic create new and original ideas come up with lots of ideas 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Independence Achievement/Effort Innovation Persistence Initiative 
Technology You might use software like this on the job: Web page creation and editing software Facebook Computer aided design CAD software Embroidery design software Pattern design software Electronic mail software Email software 
Education Education: (rated 3 of 5) Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $38,150 $23,220 $88,380 Check out my state Local Salary Info Find Jobs 
Explore More Etchers & Engravers Fine Artists, Including Painters, Sculptors, & Illustrators Jewelers & Precious Stone & Metal Workers Model Makers, Wood Sewers, Hand You might like a career in one of these industries: Arts & Entertainment 
##############################################
Title: Crane & Tower Operators 
Information:
Knowledge Engineering and Technology mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use keep your arm or hand steady hold or move items with your hands Attention pay attention to something without being distracted do two or more things at the same time Vision decide which thing is closer or farther away from you or decide how far away it is from you 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Self Control Cooperation Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Office suite software Microsoft Office software 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $61,340 $37,680 $93,410 Check out my state Local Salary Info Find Jobs 
Explore More Excavating & Loading Machine & Dragline Operators, Surface Mining Hoist & Winch Operators Industrial Truck & Tractor Operators Mobile Heavy Equipment Mechanics Riggers You might like a career in one of these industries: Construction Manufacturing Transportation & Storage Wholesale/Commercial Sales 
##############################################
Title: Credit Analysts 
Information:
Knowledge Business accounting and economics administrative services Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government law and government 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem figuring out how to use new ideas or things Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Math add, subtract, multiply, or divide choose the right type of math to solve a problem Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Analytical Thinking Attention to Detail Integrity Dependability Initiative Stress Tolerance 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Presentation software Microsoft PowerPoint Financial analysis software CGI-AMS Strata Moody's KMV Risk Advisor 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $78,850 $47,960 $161,800 Check out my state Local Salary Info Find Jobs 
Explore More Credit Authorizers, Checkers, & Clerks Credit Counselors Financial & Investment Analysts Loan Interviewers & Clerks Loan Officers You might like a career in one of these industries: Finance & Insurance Management 
##############################################
Title: Credit Authorizers, Checkers, & Clerks 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Cooperation Dependability Attention to Detail Self Control Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Spreadsheet programs Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 2 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $44,830 $30,830 $64,080 Check out my state Local Salary Info Find Jobs 
Explore More Bill & Account Collectors Credit Counselors Insurance Claims & Policy Processing Clerks Loan Interviewers & Clerks Loan Officers You might like a career in one of these industries: Finance & Insurance Administration & Support Services Wholesale/Commercial Sales Retail 
##############################################
Title: Credit Counselors 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics psychology Education and Training teaching and course design 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people talking people into changing their minds or their behavior 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Concern for Others Attention to Detail Self Control Cooperation 
Technology You might use software like this on the job: Data base user interface and query software CoreLogic DebtorTrace Microsoft Access Presentation software Microsoft PowerPoint Financial analysis software Freddie Mac Loan Prospector Prime Debt Software Credit Repair 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $47,320 $33,670 $74,310 Check out my state Local Salary Info Find Jobs 
Explore More Credit Analysts Credit Authorizers, Checkers, & Clerks Loan Interviewers & Clerks Loan Officers Personal Financial Advisors You might like a career in one of these industries: Education Finance & Insurance Professional, Science, & Technical Health & Counseling 
##############################################
Title: Crematory Operators 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software Belmar & Associates Mortware HMIS Advantage Office suite software Microsoft Office 
Education Education: (rated 2 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $40,360 $30,630 $57,910 Check out my state Local Salary Info Find Jobs 
Explore More Embalmers Funeral Attendants Funeral Home Managers Morticians, Undertakers, & Funeral Arrangers Recycling & Reclamation Workers You might like a career in one of these industries: Service 
##############################################
Title: Criminal Justice & Law Enforcement Teachers, Postsecondary 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language Safety and Government law and government public safety and security Business customer service 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Integrity Self Control Independence Attention to Detail Stress Tolerance 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Distance learning software Learning management system LMS Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $64,990 $40,720 $129,640 Check out my state Local Salary Info Find Jobs 
Explore More Education Teachers, Postsecondary Law Teachers, Postsecondary Political Science Teachers, Postsecondary Social Work Teachers, Postsecondary Sociology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Critical Care Nurses 
Information:
Knowledge Health medicine and dentistry therapy and counseling Business customer service Arts and Humanities English language Math and Science psychology 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people understanding people's reactions 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Attention to Detail Integrity Dependability Stress Tolerance Concern for Others Cooperation 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software Epic Systems Cloud-based data access and sharing software Google Drive Microsoft SharePoint Information retrieval or search software American Association of Critical Care Nurses AACN Medicopeia PEPID RN Critical Care RNCC 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $81,220 $61,250 $129,400 Check out my state Local Salary Info Find Jobs 
Explore More Acute Care Nurses Clinical Nurse Specialists Licensed Practical & Licensed Vocational Nurses Nurse Practitioners Registered Nurses You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Crossing Guards & Flaggers 
Information:
Knowledge Safety and Government public safety and security Business customer service Arts and Humanities English language 
Skills Basic Skills talking to others Social understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Integrity Self Control Cooperation Independence Concern for Others 
Technology You might use software like this on the job: Word processing software Microsoft Word Human resources software Payroll software Calendar and scheduling software Visual Computer Solutions Crossing Guard Scheduling 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $33,380 $26,000 $57,060 Check out my state Local Salary Info Find Jobs 
Explore More Parking Enforcement Workers Railroad Conductors & Yardmasters Security Guards Subway & Streetcar Operators Transit & Railroad Police You might like a career in one of these industries: Government Administration & Support Services 
##############################################
Title: Crushing, Grinding, & Polishing Machine Setters, Operators, & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement use your arms and/or legs together while sitting, standing, or lying down change when and how fast you move based on how something else is moving Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal communicate by speaking listen and understand what people say Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Cooperation Concern for Others Initiative Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Email software Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $43,290 $30,220 $63,490 Check out my state Local Salary Info Find Jobs 
Explore More Cutting & Slicing Machine Setters, Operators, & Tenders Grinding & Polishing Workers, Hand Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Machine Feeders & Offbearers Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing Mining, Oil, & Gas 
##############################################
Title: Curators 
Information:
Knowledge Arts and Humanities English language history and archeology Business management administrative services Math and Science sociology and anthropology geography Communications multimedia 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems group things in different ways 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Initiative Integrity Dependability Cooperation Leadership 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Presentation software Microsoft PowerPoint Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $60,380 $36,320 $102,980 Check out my state Local Salary Info Find Jobs 
Explore More Anthropology & Archeology Teachers, Postsecondary Archivists Historians Librarians & Media Collections Specialists Museum Technicians & Conservators You might like a career in one of these industries: Arts & Entertainment Government Education 
##############################################
Title: Customer Service Representatives 
Information:
Knowledge Business customer service management Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people bringing people together to solve differences 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Attention to Detail Self Control Stress Tolerance Integrity Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Airtable Yardi software Customer relationship management CRM software Salesforce software Telemation e-CRM Enterprise resource planning ERP software Microsoft Dynamics SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $37,780 $27,990 $59,630 Check out my state Local Salary Info Find Jobs 
Explore More Billing & Posting Clerks Insurance Claims & Policy Processing Clerks Receptionists & Information Clerks Sales Representatives of Services Telemarketers You might like a career in one of these industries: Finance & Insurance Administration & Support Services Retail 
##############################################
Title: Customs & Border Protection Officers 
Information:
Knowledge Safety and Government law and government public safety and security Arts and Humanities English language foreign language Business customer service administrative services Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Attention pay attention to something without being distracted do two or more things at the same time Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Self Control Dependability Stress Tolerance Adaptability/Flexibility Attention to Detail 
Technology You might use software like this on the job: Office suite software Corel WordPerfect Office Suite Microsoft Office software Data base user interface and query software Automated Manifest System AMS National Crime Information Center (NCIC) database Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $65,790 $40,560 $104,100 Check out my state Local Salary Info Find Jobs 
Explore More Compliance Officers Customs Brokers Detectives & Criminal Investigators Police & Sheriff's Patrol Officers Transit & Railroad Police You might like a career in one of these industries: Government 
##############################################
Title: Customs Brokers 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language Business administrative services customer service Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic use rules to solve problems order or arrange things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Integrity Dependability Stress Tolerance Persistence Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Automated commercial environment software ACE Microsoft Access Presentation software Microsoft PowerPoint Electronic mail software Electronic data interchange EDI software Microsoft Outlook 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $71,690 $41,050 $118,060 Check out my state Local Salary Info Find Jobs 
Explore More Brokerage Clerks Cargo & Freight Agents Customs & Border Protection Officers Freight Forwarders Transportation, Storage, & Distribution Managers You might like a career in one of these industries: Government Finance & Insurance 
##############################################
Title: Cutters & Trimmers, Hand 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Achievement/Effort Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Word processing software Microsoft Word 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $36,130 $27,040 $51,970 Check out my state Local Salary Info Find Jobs 
Explore More Cutting & Slicing Machine Setters, Operators, & Tenders Grinding & Polishing Workers, Hand Sawing Machine Setters, Operators, & Tenders, Wood Sewing Machine Operators Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Cutting & Slicing Machine Setters, Operators, & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Self Control Adaptability/Flexibility Cooperation Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $39,880 $30,220 $55,760 Check out my state Local Salary Info Find Jobs 
Explore More Cutting, Punching, & Press Machine Setters, Operators, & Tenders, Metal & Plastic Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Textile Cutting Machine Setters, Operators, & Tenders Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Cutting, Punching, & Press Machine Setters, Operators, & Tenders, Metal & Plastic 
Information:
Knowledge Engineering and Technology mechanical Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Initiative Integrity Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $39,340 $30,280 $59,090 Check out my state Local Salary Info Find Jobs 
Explore More Cutting & Slicing Machine Setters, Operators, & Tenders Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Multiple Machine Tool Setters, Operators, & Tenders, Metal & Plastic Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Cytogenetic Technologists 
Information:
Knowledge Math and Science biology chemistry Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic order or arrange things make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Dependability Integrity Analytical Thinking Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Object or component oriented development software C++ Python Presentation software Microsoft PowerPoint Analytical or scientific software Cell Bioscience Automated Image Capture MetaSystems Isis Color Karyotyping 
Education Education: (rated 4 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,380 $35,220 $84,670 Check out my state Local Salary Info Find Jobs 
Explore More Cytotechnologists Histology Technicians Histotechnologists Medical & Clinical Laboratory Technicians Medical & Clinical Laboratory Technologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Cytotechnologists 
Information:
Knowledge Math and Science biology Health medicine and dentistry Arts and Humanities English language Business administrative services 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Dependability Integrity Independence Analytical Thinking Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software Fortius Lab Systems Clinical LIS MEDITECH software Expert system software Ansible software 
Education Education: (rated 5 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,380 $35,220 $84,670 Check out my state Local Salary Info Find Jobs 
Explore More Cytogenetic Technologists Histology Technicians Histotechnologists Medical & Clinical Laboratory Technologists Physicians, Pathologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Dancers 
Information:
Knowledge Arts and Humanities music, dance, visual arts, drama, or sculpture English language Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Social changing what is done based on other people's actions 
Abilities Endurance exercise for a long time without getting out of breath Flexibility, Balance, and Coordination move your arms, legs, and mid-section together while your whole body is moving bend, stretch, twist, or reach with your body, arms, and/or legs Physical Strength exercise for a long time without your muscles getting tired use your lower back and stomach Verbal listen and understand what people say 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Achievement/Effort Persistence Cooperation Attention to Detail Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Web page creation and editing software Facebook LinkedIn Video creation and editing software Apple Final Cut Pro YouTube Graphics or photo imaging software Adobe Systems Adobe Photoshop Choreography software 
Education Education: (rated 3 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $21.64 $13.20 $39.03 Check out my state Local Salary Info Find Jobs 
Explore More Actors Art, Drama, & Music Teachers, Postsecondary Choreographers Music Directors & Composers Musicians & Singers You might like a career in one of these industries: Arts & Entertainment Education 
##############################################
Title: Data Entry Keyers 
Information:
Knowledge Arts and Humanities English language Business administrative services customer service Safety and Government law and government 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written listen and understand what people say Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Dependability Achievement/Effort Independence Stress Tolerance 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Word processing software Google Docs Microsoft Word Medical software Electronic medical record EMR software Medical procedure coding software 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $36,190 $26,850 $51,510 Check out my state Local Salary Info Find Jobs 
Explore More File Clerks Mail Clerks & Mail Machine Operators Office Clerks, General Office Machine Operators Word Processors & Typists You might like a career in one of these industries: Administration & Support Services Professional, Science, & Technical 
##############################################
Title: Data Scientists 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Data base user interface and query software Amazon Web Services AWS software Structured query language SQL Presentation software Microsoft PowerPoint Object or component oriented development software R Scala 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $103,500 $58,510 $174,790 Check out my state Local Salary Info Find Jobs 
Explore More Bioinformatics Scientists Financial Quantitative Analysts Operations Research Analysts Statistical Assistants Statisticians You might like a career in one of these industries: Professional, Science, & Technical Finance & Insurance Media & Communication Management 
##############################################
Title: Data Warehousing Specialists 
Information:
Knowledge Engineering and Technology computers and electronics design Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic order or arrange things make general rules or come up with answers from lots of detailed information Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Initiative Persistence Cooperation Dependability 
Technology You might use software like this on the job: Data base management system software Amazon DynamoDB Oracle PL/SQL Presentation software Microsoft PowerPoint Metadata management software CA Erwin Data Modeler Informatica software 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $134,870 $74,980 $197,350 Check out my state Local Salary Info Find Jobs 
Explore More Computer Systems Analysts Computer Systems Engineers/Architects Database Administrators Database Architects Software Developers You might like a career in one of these industries: Professional, Science, & Technical Media & Communication Finance & Insurance 
##############################################
Title: Database Administrators 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Arts and Humanities English language Business customer service management Communications telecommunications 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Persistence Cooperation Dependability Analytical Thinking 
Technology You might use software like this on the job: Data base management system software Amazon DynamoDB Elasticsearch Data base user interface and query software Apache Hive Blackboard software Web platform development software Google Angular Spring Framework 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $99,890 $53,010 $153,870 Check out my state Local Salary Info Find Jobs 
Explore More Computer Systems Analysts Database Architects Information Security Engineers Network & Computer Systems Administrators Software Developers You might like a career in one of these industries: Professional, Science, & Technical Finance & Insurance Media & Communication 
##############################################
Title: Database Architects 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Math add, subtract, multiply, or divide choose the right type of math to solve a problem Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Achievement/Effort Initiative Cooperation Integrity 
Technology You might use software like this on the job: Data base management system software Amazon DynamoDB Elasticsearch Data base user interface and query software Apache Hive Blackboard software Web platform development software React Spring Framework 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $134,870 $74,980 $197,350 Check out my state Local Salary Info Find Jobs 
Explore More Computer Systems Analysts Computer Systems Engineers/Architects Data Warehousing Specialists Database Administrators Software Developers You might like a career in one of these industries: Professional, Science, & Technical Media & Communication Finance & Insurance 
##############################################
Title: Demonstrators & Product Promoters 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language Manufactured or Agricultural Goods food production Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems group things in different ways 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Integrity Cooperation Social Orientation Concern for Others Independence 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Video conferencing software Zoom Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $34,770 $26,200 $58,220 Check out my state Local Salary Info Find Jobs 
Explore More Counter & Rental Clerks Door-to-Door Sales Workers, News & Street Vendors, & Related Workers Retail Salespersons Sales Representatives of Services Telemarketers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical Retail 
##############################################
Title: Dental Assistants 
Information:
Knowledge Business customer service management Health medicine and dentistry Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Ideas and Logic use rules to solve problems order or arrange things Attention do two or more things at the same time 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Self Control Stress Tolerance Integrity Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software Henry Schein Dentrix The Systems Workplace TDOCS Electronic mail software Email software 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $44,820 $31,450 $59,200 Check out my state Local Salary Info Find Jobs 
Explore More Dental Hygienists Endoscopy Technicians Medical Assistants Ophthalmic Medical Technicians Surgical Assistants You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Dental Hygienists 
Information:
Knowledge Health medicine and dentistry Business customer service Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention do two or more things at the same time 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Integrity Dependability Cooperation Self Control Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software Dental office management software Henry Schein Dentrix Electronic mail software Email software 
Education Education: (rated 3 of 5) associate's degree or professional degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $81,400 $61,510 $107,640 Check out my state Local Salary Info Find Jobs 
Explore More Dental Assistants Medical Assistants Ophthalmic Medical Technicians Surgical Assistants Surgical Technologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Dental Laboratory Technicians 
Information:
Knowledge Business management customer service Engineering and Technology design mechanical Arts and Humanities English language Health medicine and dentistry 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Ideas and Logic notice when problems happen order or arrange things Verbal read and understand what is written listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Initiative Integrity Achievement/Effort Persistence 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Data base user interface and query software Easy Solutions Easy Lab Mainstreet Systems & Software DentaLab/PC II 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,050 $30,770 $74,800 Check out my state Local Salary Info Find Jobs 
Explore More Dental Assistants Medical Appliance Technicians Medical Equipment Repairers Molders, Shapers, & Casters Ophthalmic Laboratory Technicians You might like a career in one of these industries: Manufacturing Health & Counseling 
##############################################
Title: Dentists, General 
Information:
Knowledge Health medicine and dentistry Business customer service management Arts and Humanities English language Math and Science biology 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people understanding people's reactions 
Abilities Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Integrity Dependability Concern for Others Self Control Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software eClinicalWorks EHR software Windent SQL Office suite software Microsoft Office software 
Education Education: (rated 5 of 5) doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $155,040 $68,160 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Oral & Maxillofacial Surgeons Orthodontists Orthopedic Surgeons Pediatric Surgeons Prosthodontists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Dermatologists 
Information:
Knowledge Health medicine and dentistry therapy and counseling Business customer service management Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Concern for Others Dependability Integrity Self Control Initiative 
Technology You might use software like this on the job: Medical software Bizmatics PrognoCIS EMR eClinicalWorks EHR software Video conferencing software Cisco Webex Zoom Spreadsheet software Microsoft Excel 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $239,200+ $102,010 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians Ophthalmologists Orthopedic Surgeons Pediatric Surgeons You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Derrick Operators, Oil & Gas 
Information:
Knowledge Engineering and Technology mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Endurance exercise for a long time without getting out of breath Hearing and Speech pay attention to one sound while there are other distracting sounds 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Cooperation Stress Tolerance Concern for Others Self Control Attention to Detail 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Word processing software Microsoft Word 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $51,220 $37,610 $70,820 Check out my state Local Salary Info Find Jobs 
Explore More Earth Drillers Helpers--Extraction Workers Rotary Drill Operators, Oil & Gas Roustabouts, Oil & Gas Wellhead Pumpers You might like a career in one of these industries: Mining, Oil, & Gas 
##############################################
Title: Desktop Publishers 
Information:
Knowledge Engineering and Technology computers and electronics Communications multimedia Arts and Humanities English language 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one 
Abilities Ideas and Logic order or arrange things create new and original ideas Verbal read and understand what is written communicate by writing 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Dependability Cooperation Adaptability/Flexibility Integrity Stress Tolerance 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Illustrator Presentation software Microsoft PowerPoint Desktop publishing software Adobe Systems Adobe InDesign Microsoft Publisher 
Education Education: (rated 3 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,910 $29,370 $86,820 Check out my state Local Salary Info Find Jobs 
Explore More Film & Video Editors Graphic Designers Prepress Technicians & Workers Proofreaders & Copy Markers Web & Digital Interface Designers You might like a career in one of these industries: Media & Communication Professional, Science, & Technical 
##############################################
Title: Detectives & Criminal Investigators 
Information:
Knowledge Safety and Government law and government public safety and security Arts and Humanities English language Business customer service administrative services Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly know what you are looking at Attention do two or more things at the same time 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Self Control Dependability Stress Tolerance Attention to Detail Adaptability/Flexibility 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Photoshop Graphics software Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access Structured query language SQL 
Education Education: (rated 3 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $86,280 $47,990 $150,570 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Police & Detectives Intelligence Analysts Police & Sheriff's Patrol Officers Police Identification & Records Officers Private Detectives & Investigators You might like a career in one of these industries: Government 
##############################################
Title: Diagnostic Medical Sonographers 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Math and Science physics psychology Health medicine and dentistry 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Hand and Finger Use keep your arm or hand steady hold or move items with your hands Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Dependability Independence Integrity Concern for Others Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software MEDITECH software Patient medical record software Electronic mail software Email software 
Education Education: (rated 3 of 5) associate's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $81,350 $61,430 $107,730 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Magnetic Resonance Imaging Technologists Neurodiagnostic Technologists Radiation Therapists Radiologic Technologists & Technicians You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Dietetic Technicians 
Information:
Knowledge Business customer service management Arts and Humanities English language Education and Training teaching and course design Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people teaching people how to do something 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Attention to Detail Adaptability/Flexibility Concern for Others Dependability Stress Tolerance Independence 
Technology You might use software like this on the job: Data base user interface and query software USDA Child Nutrition Database ValuSoft MasterCook Presentation software Microsoft PowerPoint Analytical or scientific software Axxya Systems Nutritionist Pro NutriGenie Optimal Nutrition 
Education Education: (rated 3 of 5) associate's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $33,960 $25,040 $49,920 Check out my state Local Salary Info Find Jobs 
Explore More Dietitians & Nutritionists Exercise Physiologists Health Education Specialists Nursing Assistants Psychiatric Technicians You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Dietitians & Nutritionists 
Information:
Knowledge Math and Science biology psychology Health medicine and dentistry therapy and counseling Arts and Humanities English language Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Concern for Others Attention to Detail Dependability Cooperation Analytical Thinking 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Desktop communications software Skype Analytical or scientific software Axxya Systems Nutritionist Pro Monash University Low FODMAP Diet App 
Education Education: (rated 5 of 5) certificate after college or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $66,450 $44,140 $95,130 Check out my state Local Salary Info Find Jobs 
Explore More Clinical Nurse Specialists Dietetic Technicians Family Medicine Physicians Nurse Practitioners Pediatricians, General You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Digital Forensics Analysts 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Operating system software Apple iOS Bash Cloud-based data access and sharing software Platform as a service PaaS Slack Object or component oriented development software Perl Python 
Education Education: (rated 4 of 5) Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,740 $46,540 $166,780 Check out my state Local Salary Info Find Jobs 
Explore More Computer Systems Analysts Desktop Publishers Document Management Specialists Information Security Analysts Information Security Engineers You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Dining Room & Cafeteria Attendants & Bartender Helpers 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language Manufactured or Agricultural Goods food production 
Skills Basic Skills listening to others, not interrupting, and asking good questions Social changing what is done based on other people's actions looking for ways to help people 
Abilities Endurance exercise for a long time without getting out of breath Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Self Control Cooperation Dependability Social Orientation Integrity Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Point of sale POS software Cafe Cartel Systems Plexis Software Plexis POS Operating system software Microsoft Windows 
Education Education: (rated 1 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $29,120 $20,680 $39,760 Check out my state Local Salary Info Find Jobs 
Explore More Baristas Fast Food & Counter Workers Food Preparation Workers Food Servers, Nonrestaurant Waiters & Waitresses You might like a career in one of these industries: Hotel & Food Education 
##############################################
Title: Directors, Religious Activities & Education 
Information:
Knowledge Arts and Humanities philosophy and religion English language Education and Training teaching and course design Business customer service management Math and Science psychology 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic use rules to solve problems notice when problems happen 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Leadership Self Control Dependability Cooperation Concern for Others 
Technology You might use software like this on the job: Web page creation and editing software Facebook Social media software Video conferencing software Zoom Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 4 of 5) bachelor's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $49,380 $30,150 $101,650 Check out my state Local Salary Info Find Jobs 
Explore More Clergy Education Teachers, Postsecondary Educational, Guidance, & Career Counselors & Advisors Instructional Coordinators Social & Community Service Managers You might like a career in one of these industries: Service 
##############################################
Title: Disc Jockeys 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Music or sound editing software Adobe Systems Adobe Audition Audion Laboratories VoxPro Word processing software Microsoft Word 
Education Education: (rated 2 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $21.34 $9.87 $46.84 Check out my state Local Salary Info Find Jobs 
Explore More Agents & Business Managers of Artists, Performers, & Athletes Broadcast Announcers & Radio Disc Jockeys Meeting, Convention, & Event Planners Music Directors & Composers Sound Engineering Technicians You might like a career in one of these industries: Arts & Entertainment Hotel & Food 
##############################################
Title: Dishwashers 
Information:
Knowledge Business management customer service Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions Social changing what is done based on other people's actions 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Cooperation Integrity Dependability Attention to Detail Independence Self Control 
Technology You might use software like this on the job: Operating system software Microsoft Windows Web page creation and editing software Facebook 
Education Education: (rated 1 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $29,080 $21,670 $36,350 Check out my state Local Salary Info Find Jobs 
Explore More Cleaners of Vehicles & Equipment Dining Room & Cafeteria Attendants & Bartender Helpers Food Preparation Workers Janitors & Cleaners Laundry & Dry-Cleaning Workers You might like a career in one of these industries: Hotel & Food 
##############################################
Title: Dispatchers 
Information:
Knowledge Business customer service management Safety and Government public safety and security Arts and Humanities English language Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems notice when problems happen Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Stress Tolerance Attention to Detail Dependability Leadership Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Presentation software Microsoft PowerPoint Mobile location based services software Global positioning system GPS software Situation resource tracking software 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $44,830 $30,160 $71,930 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Material-Moving Machine & Vehicle Operators First-Line Supervisors of Passenger Attendants Public Safety Telecommunicators Railroad Conductors & Yardmasters You might like a career in one of these industries: Transportation & Storage Administration & Support Services 
##############################################
Title: Document Management Specialists 
Information:
Knowledge Arts and Humanities English language Business management customer service Engineering and Technology computers and electronics Safety and Government law and government 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Cooperation Analytical Thinking Adaptability/Flexibility 
Technology You might use software like this on the job: Document management software Adobe Systems Adobe Acrobat Records management software Video creation and editing software Apple Final Cut Pro WeVideo Enterprise resource planning ERP software Microsoft Dynamics Oracle PeopleSoft Financials 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,740 $46,540 $166,780 Check out my state Local Salary Info Find Jobs 
Explore More Computer Systems Analysts Database Administrators Health Information Technologists & Medical Registrars Management Analysts Software Developers You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Door-to-Door Sales Workers, News & Street Vendors, & Related Workers 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social talking people into changing their minds or their behavior understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Initiative Dependability Independence Persistence Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Office suite software Microsoft Office software Route navigation software Route mapping software 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $31,100 $20,540 $51,860 Check out my state Local Salary Info Find Jobs 
Explore More Cashiers Counter & Rental Clerks Demonstrators & Product Promoters Retail Salespersons Telemarketers 
##############################################
Title: Dredge Operators 
Information:
Knowledge Engineering and Technology mechanical Arts and Humanities English language Safety and Government public safety and security Business management 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Concern for Others Leadership Cooperation Dependability Stress Tolerance Self Control 
Technology You might use software like this on the job: Data base user interface and query software Teledyne Odom Hydrographic ODOM eChart Industrial control software HYPACK DREDGEPACK Programmable logic controller PLC software Mobile location based services software Global positioning system GPS software 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,090 $34,110 $77,080 Check out my state Local Salary Info Find Jobs 
Explore More Continuous Mining Machine Operators Excavating & Loading Machine & Dragline Operators, Surface Mining Hoist & Winch Operators Operating Engineers & Other Construction Equipment Operators Rotary Drill Operators, Oil & Gas You might like a career in one of these industries: Construction Mining, Oil, & Gas 
##############################################
Title: Drilling & Boring Machine Tool Setters, Operators, & Tenders, Metal & Plastic 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology mechanical product and service development Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say read and understand what is written 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Analytical Thinking Cooperation Dependability Independence Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Enterprise resource planning ERP software SAP software Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $42,450 $30,720 $62,400 Check out my state Local Salary Info Find Jobs 
Explore More Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Tool Grinders, Filers, & Sharpeners Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing Construction 
##############################################
Title: Driver/Sales Workers 
Information:
Knowledge Business customer service management Manufactured or Agricultural Goods food production Arts and Humanities English language Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use keep your arm or hand steady put together small parts with your fingers 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Cooperation Stress Tolerance Self Control Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software MobiTech Systems Route Sales Trakker Soft Essentials Vending Essentials Map creation software GEOCOMtms A.Maze Planning Route planning software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $32,690 $19,730 $55,200 Check out my state Local Salary Info Find Jobs 
Explore More Counter & Rental Clerks Couriers & Messengers Door-to-Door Sales Workers, News & Street Vendors, & Related Workers Light Truck Drivers Shipping, Receiving, & Inventory Clerks You might like a career in one of these industries: Hotel & Food Wholesale/Commercial Sales Retail 
##############################################
Title: Drywall & Ceiling Tile Installers 
Information:
Knowledge Engineering and Technology building and construction mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government public safety and security Business management 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath Physical Strength use your lower back and stomach lift, push, pull, or carry 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Achievement/Effort Self Control 
Technology You might use software like this on the job: Word processing software Microsoft Word Wilhelm Publishing Threshold Project management software DevWave Estimate Works Turtle Creek Software Goldenseal Accounting software Job costing software 
Education Education: (rated 2 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $50,440 $35,250 $96,160 Check out my state Local Salary Info Find Jobs 
Explore More Carpenters Floor Layers Insulation Workers, Floor, Ceiling, & Wall Sheet Metal Workers Tapers You might like a career in one of these industries: Construction 
##############################################
Title: Earth Drillers 
Information:
Knowledge Engineering and Technology mechanical design Business management Safety and Government public safety and security Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Verbal listen and understand what people say communicate by speaking Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Adaptability/Flexibility Stress Tolerance Initiative Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Mobile location based services software Global positioning system GPS software Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $51,740 $38,490 $80,450 Check out my state Local Salary Info Find Jobs 
Explore More Continuous Mining Machine Operators Drilling & Boring Machine Tool Setters, Operators, & Tenders, Metal & Plastic Explosives Workers, Ordnance Handling Experts, & Blasters Helpers--Extraction Workers Rotary Drill Operators, Oil & Gas You might like a career in one of these industries: Construction Mining, Oil, & Gas 
##############################################
Title: Economics Teachers, Postsecondary 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Education and Training teaching and course design Business accounting and economics Arts and Humanities English language 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Math choose the right type of math to solve a problem add, subtract, multiply, or divide Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Analytical Thinking Achievement/Effort Integrity Dependability Independence Attention to Detail 
Technology You might use software like this on the job: Analytical or scientific software Minitab The MathWorks MATLAB Computer based training software Learning management system LMS Moodle Word processing software Google Docs Microsoft Word 
Education Education: (rated 5 of 5) doctoral degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $103,930 $51,480 $213,680 Check out my state Local Salary Info Find Jobs 
Explore More Business Teachers, Postsecondary Economists Mathematical Science Teachers, Postsecondary Political Science Teachers, Postsecondary Sociology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Economists 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Business accounting and economics Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Achievement/Effort Persistence Initiative Integrity Independence 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software Microsoft PowerPoint Object or component oriented development software Oracle Java R 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $113,940 $62,480 $207,230 Check out my state Local Salary Info Find Jobs 
Explore More Economics Teachers, Postsecondary Environmental Economists Financial & Investment Analysts Financial Risk Specialists Political Scientists You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Editors 
Information:
Knowledge Arts and Humanities English language Communications multimedia Business management administrative services Education and Training teaching and course design 
Skills Basic Skills reading work related information writing things for co-workers or customers Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by writing Ideas and Logic come up with lots of ideas group things in different ways 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Integrity Adaptability/Flexibility Cooperation Dependability Stress Tolerance 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Web page creation and editing software LinkedIn WordPress Desktop publishing software Adobe Systems Adobe InDesign Microsoft Publisher 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $73,080 $38,400 $145,240 Check out my state Local Salary Info Find Jobs 
Explore More Film & Video Editors News Analysts, Reporters, & Journalists Poets, Lyricists & Creative Writers Proofreaders & Copy Markers Writers & Authors You might like a career in one of these industries: Media & Communication Professional, Science, & Technical 
##############################################
Title: Education & Childcare Administrators, Preschool & Daycare 
Information:
Knowledge Business customer service management Education and Training teaching and course design Arts and Humanities English language Math and Science psychology 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions looking for ways to help people 
Abilities Verbal communicate by writing communicate by speaking Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Stress Tolerance Integrity Self Control Adaptability/Flexibility Independence 
Technology You might use software like this on the job: Accounting software Intuit QuickBooks Quicken Desktop communications software ParentSquare Tadpoles Enterprise resource planning ERP software ACS Technologies HeadMaster The Gallagher Group DataCare 
Education Education: (rated 4 of 5) associate's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $49,690 $35,190 $85,390 Check out my state Local Salary Info Find Jobs 
Explore More Education Administrators, Kindergarten through Secondary Preschool Teachers Social & Community Service Managers Special Education Teachers, Elementary School Special Education Teachers, Kindergarten You might like a career in one of these industries: Health & Counseling Service 
##############################################
Title: Education Administrators, Kindergarten through Secondary 
Information:
Knowledge Arts and Humanities English language Business management human resources (HR) Education and Training teaching and course design Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Attention do two or more things at the same time pay attention to something without being distracted Math choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Leadership Initiative Self Control Concern for Others 
Technology You might use software like this on the job: Analytical or scientific software Desmos IBM SPSS Statistics Enterprise resource planning ERP software Microsoft Dynamics GP Wilcomp Software RenWeb Data base user interface and query software Blackboard software Microsoft Access 
Education Education: (rated 5 of 5) certificate after master's or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $101,320 $64,690 $158,770 Check out my state Local Salary Info Find Jobs 
Explore More Education & Childcare Administrators, Preschool & Daycare Education Administrators, Postsecondary Elementary School Teachers Instructional Coordinators Secondary School Teachers You might like a career in one of these industries: Education 
##############################################
Title: Education Administrators, Postsecondary 
Information:
Knowledge Arts and Humanities English language Business management customer service Education and Training teaching and course design Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Initiative Integrity Leadership Dependability Cooperation Achievement/Effort 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Data base user interface and query software Blackboard software Microsoft Access Web page creation and editing software Google Sites LinkedIn 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $99,940 $61,000 $196,420 Check out my state Local Salary Info Find Jobs 
Explore More Education Administrators, Kindergarten through Secondary Education Teachers, Postsecondary Instructional Coordinators Teaching Assistants, Postsecondary Training & Development Managers You might like a career in one of these industries: Education 
##############################################
Title: Education Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities English language philosophy and religion Education and Training teaching and course design Math and Science psychology sociology and anthropology Business management 
Skills Basic Skills talking to others reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Analytical Thinking Achievement/Effort Initiative Integrity Dependability Persistence 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Padlet Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) doctoral degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $66,930 $38,190 $129,570 Check out my state Local Salary Info Find Jobs 
Explore More Adult Basic Education, Adult Secondary Education, & English as a Second Language Instructors Instructional Coordinators Library Science Teachers, Postsecondary Psychology Teachers, Postsecondary Sociology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Educational, Guidance, & Career Counselors & Advisors 
Information:
Knowledge Health therapy and counseling Math and Science psychology sociology and anthropology Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Integrity Cooperation Dependability Self Control Social Orientation 
Technology You might use software like this on the job: Data base user interface and query software Blackboard software Microsoft Access Analytical or scientific software ACT WorkKeys Counseling software Computer based training software Moodle Schoology 
Education Education: (rated 5 of 5) master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $60,140 $38,280 $98,530 Check out my state Local Salary Info Find Jobs 
Explore More Child, Family, & School Social Workers Rehabilitation Counselors School Psychologists Special Education Teachers, Elementary School Special Education Teachers, Kindergarten You might like a career in one of these industries: Education 
##############################################
Title: Electric Motor, Power Tool, & Related Repairers 
Information:
Knowledge Engineering and Technology mechanical Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products Business management 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Technical repairing machines or systems using the right tools figuring out what is causing equipment, machines, wiring, or computer programs to not work 
Abilities Hand and Finger Use put together small parts with your fingers hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things Visual Understanding quickly compare groups of letters, numbers, pictures, or other things quickly know what you are looking at Verbal listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Stress Tolerance Independence Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Analytical or scientific software Commutator profiling software Motor testing software 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $48,260 $32,790 $73,850 Check out my state Local Salary Info Find Jobs 
Explore More Control & Valve Installers & Repairers Electrical & Electronic Equipment Assemblers Electromechanical Equipment Assemblers Engine & Other Machine Assemblers Industrial Machinery Mechanics You might like a career in one of these industries: Wholesale/Commercial Sales Service 
##############################################
Title: Electrical & Electronic Engineering Technologists & Technicians 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Business customer service 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Analytical Thinking Integrity Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Analytical or scientific software The MathWorks MATLAB Wolfram Research Mathematica Presentation software Microsoft PowerPoint Computer aided design CAD software Autodesk Revit Bentley MicroStation 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $66,390 $43,930 $101,480 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Electrical & Electronics Repairers, Commercial & Industrial Equipment Electronics Engineers Mechanical Engineering Technologists & Technicians Robotics Technicians You might like a career in one of these industries: Manufacturing Professional, Science, & Technical Government 
##############################################
Title: Electrical & Electronic Equipment Assemblers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical 
Skills Basic Skills reading work related information keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers hold or move items with your hands Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Achievement/Effort Cooperation Integrity Adaptability/Flexibility 
Technology You might use software like this on the job: Enterprise resource planning ERP software Sage 100 ERP SAP software Network connectivity terminal emulation software Rasmussen Software Anzio Terminal emulation software Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $38,580 $29,650 $58,620 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Electric Motor, Power Tool, & Related Repairers Electromechanical Equipment Assemblers Engine & Other Machine Assemblers Industrial Machinery Mechanics You might like a career in one of these industries: Manufacturing 
##############################################
Title: Electrical & Electronics Drafters 
Information:
Knowledge Engineering and Technology design product and service development Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics geography Business administrative services 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Initiative Cooperation Analytical Thinking Adaptability/Flexibility 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk Revit Bentley MicroStation Presentation software Microsoft PowerPoint Enterprise resource planning ERP software SAP software SoftBrands Fourth Shift Edition 
Education Education: (rated 3 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $64,240 $43,270 $99,420 Check out my state Local Salary Info Find Jobs 
Explore More Architectural & Civil Drafters Electrical & Electronic Engineering Technologists & Technicians Electronics Engineers Mechanical Drafters Mechanical Engineering Technologists & Technicians You might like a career in one of these industries: Professional, Science, & Technical Manufacturing Construction Utilities 
##############################################
Title: Electrical & Electronics Installers & Repairers, Transportation Equipment 
Information:
Knowledge Engineering and Technology product and service development computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Safety and Government public safety and security Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Analytical Thinking Persistence Initiative 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk Revit Trimble SketchUp Pro Presentation software Microsoft PowerPoint Spreadsheet software IBM Lotus 1-2-3 Microsoft Excel 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $73,630 $45,970 $94,250 Check out my state Local Salary Info Find Jobs 
Explore More Avionics Technicians Calibration Technologists & Technicians Electrical & Electronic Engineering Technologists & Technicians Electrical & Electronics Repairers, Commercial & Industrial Equipment Lighting Technicians You might like a career in one of these industries: Transportation & Storage Government Manufacturing 
##############################################
Title: Electrical & Electronics Repairers, Commercial & Industrial Equipment 
Information:
Knowledge Engineering and Technology computers and electronics mechanical Manufactured or Agricultural Goods manufacture and distribution of products Business customer service management Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it Technical repairing machines or systems using the right tools watching gauges, dials, or display screens to make sure a machine is working 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things Verbal listen and understand what people say communicate by speaking Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Analytical Thinking Independence Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Facilities management software Computerized maintenance management system CMMS Maintenance management software Electronic mail software Email software 
Education Education: (rated 3 of 5) associate's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $64,030 $39,550 $95,940 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Calibration Technologists & Technicians Electrical & Electronic Engineering Technologists & Technicians Electrical & Electronics Installers & Repairers, Transportation Equipment Robotics Technicians You might like a career in one of these industries: Manufacturing Government 
##############################################
Title: Electrical & Electronics Repairers, Powerhouse, Substation, & Relay 
Information:
Knowledge Engineering and Technology mechanical product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Safety and Government public safety and security Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Analytical Thinking Cooperation Dependability Integrity Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Analytical or scientific software Fluke Corporation FlukeView Forms OMICRON Test Universe Electronic mail software Microsoft Outlook 
Education Education: (rated 3 of 5) certificate after high school or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $93,720 $55,170 $117,220 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Control & Valve Installers & Repairers Electrical & Electronics Installers & Repairers, Transportation Equipment Electrical & Electronics Repairers, Commercial & Industrial Equipment Power Distributors & Dispatchers You might like a career in one of these industries: Utilities Government 
##############################################
Title: Electrical Engineers 
Information:
Knowledge Engineering and Technology product and service development computers and electronics Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills writing things for co-workers or customers reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Persistence Analytical Thinking Initiative Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Analytical or scientific software Minitab The MathWorks MATLAB Word processing software Microsoft OneNote Microsoft Word Computer aided design CAD software Autodesk AutoCAD Civil 3D Autodesk Revit 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $103,320 $65,480 $166,970 Check out my state Local Salary Info Find Jobs 
Explore More Electrical & Electronic Engineering Technologists & Technicians Electrical & Electronics Repairers, Commercial & Industrial Equipment Electronics Engineers Mechanical Engineers Mechatronics Engineers You might like a career in one of these industries: Professional, Science, & Technical Manufacturing Utilities 
##############################################
Title: Electrical Power-Line Installers & Repairers 
Information:
Knowledge Engineering and Technology building and construction mechanical Arts and Humanities English language Education and Training teaching and course design Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Ideas and Logic notice when problems happen use rules to solve problems Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Self Control Cooperation Persistence 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Video conferencing software Zoom Computer aided design CAD software Bentley MicroStation Computer aided design and drafting CADD software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $82,340 $47,070 $114,590 Check out my state Local Salary Info Find Jobs 
Explore More Electrical & Electronics Repairers, Powerhouse, Substation, & Relay Electricians Helpers--Electricians Hoist & Winch Operators Telecommunications Line Installers & Repairers You might like a career in one of these industries: Utilities Construction Government 
##############################################
Title: Electricians 
Information:
Knowledge Engineering and Technology building and construction mechanical Business management customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Verbal listen and understand what people say communicate by speaking Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Self Control Dependability Adaptability/Flexibility Integrity Initiative 
Technology You might use software like this on the job: Word processing software Microsoft Word Socrates Contractor's Library Analytical or scientific software Construction Master Pro SoftEmpire Electrical Calculations Data base user interface and query software Database software Shafer Service Systems 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $60,240 $37,440 $102,300 Check out my state Local Salary Info Find Jobs 
Explore More Electrical & Electronics Repairers, Powerhouse, Substation, & Relay Electrical Power-Line Installers & Repairers Helpers--Electricians Lighting Technicians Plumbers, Pipefitters, & Steamfitters You might like a career in one of these industries: Construction 
##############################################
Title: Electro-Mechanical & Mechatronics Technologists & Technicians 
Information:
Knowledge Engineering and Technology computers and electronics mechanical Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Verbal listen and understand what people say communicate by writing Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Initiative Analytical Thinking Persistence Integrity 
Technology You might use software like this on the job: Analytical or scientific software ESRI ArcGIS software The MathWorks MATLAB Presentation software Microsoft PowerPoint Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks 
Education Education: (rated 3 of 5) certificate after high school or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $60,570 $40,300 $99,390 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Avionics Technicians Calibration Technologists & Technicians Electrical & Electronic Engineering Technologists & Technicians Robotics Technicians You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Electromechanical Equipment Assemblers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical computers and electronics Arts and Humanities English language 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Adaptability/Flexibility Analytical Thinking 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Graphics or photo imaging software Blueprint display software Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $38,580 $29,650 $58,620 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Calibration Technologists & Technicians Electrical & Electronic Equipment Assemblers Engine & Other Machine Assemblers Timing Device Assemblers & Adjusters You might like a career in one of these industries: Manufacturing 
##############################################
Title: Electronic Equipment Installers & Repairers, Motor Vehicles 
Information:
Knowledge Engineering and Technology mechanical computers and electronics Business customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted do two or more things at the same time Verbal listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Initiative Self Control Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Analytical or scientific software Harris Tech X.over Pro True Audio WinSpeakerz 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $41,600 $30,560 $65,380 Check out my state Local Salary Info Find Jobs 
Explore More Automotive Service Technicians & Mechanics Avionics Technicians Electric Motor, Power Tool, & Related Repairers Electrical & Electronics Installers & Repairers, Transportation Equipment Electrical & Electronics Repairers, Commercial & Industrial Equipment You might like a career in one of these industries: Retail Service Manufacturing Wholesale/Commercial Sales 
##############################################
Title: Electronics Engineers 
Information:
Knowledge Engineering and Technology product and service development computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information order or arrange things Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Dependability Analytical Thinking Cooperation Integrity Initiative 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks Presentation software Microsoft PowerPoint Development environment software C National Instruments LabVIEW 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $108,170 $74,880 $171,430 Check out my state Local Salary Info Find Jobs 
Explore More Computer Hardware Engineers Electrical & Electronic Engineering Technologists & Technicians Electrical Engineers Mechatronics Engineers Microsystems Engineers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical Media & Communication Government 
##############################################
Title: Elementary School Teachers 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language history and archeology Math and Science arithmetic, algebra, geometry, calculus, or statistics psychology Engineering and Technology computers and electronics 
Skills Basic Skills talking to others using the best training or teaching strategies for learning new things Social teaching people how to do something changing what is done based on other people's actions People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen use rules to solve problems Attention do two or more things at the same time pay attention to something without being distracted Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Adaptability/Flexibility Concern for Others Integrity Self Control Initiative 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Computer based training software Padlet Schoology Multi-media educational software Nearpod Seesaw 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $61,690 $45,470 $101,310 Check out my state Local Salary Info Find Jobs 
Explore More Kindergarten Teachers Middle School Teachers Secondary School Teachers Special Education Teachers, Elementary School Tutors You might like a career in one of these industries: Education 
##############################################
Title: Elevator & Escalator Installers & Repairers 
Information:
Knowledge Engineering and Technology mechanical building and construction Business customer service management Safety and Government public safety and security Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Ideas and Logic notice when problems happen use rules to solve problems Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Persistence Integrity Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Analytical or scientific software Elevator Controls INTERACT WORLD Electronics Freedomware Electronic mail software Microsoft Outlook 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $99,000 $47,850 $135,130 Check out my state Local Salary Info Find Jobs 
Explore More Maintenance & Repair Workers, General Mechanical Door Repairers Millwrights Mobile Heavy Equipment Mechanics Rail Car Repairers You might like a career in one of these industries: Construction 
##############################################
Title: Eligibility Interviewers, Government Programs 
Information:
Knowledge Business customer service management Arts and Humanities English language Education and Training teaching and course design Engineering and Technology computers and electronics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Attention to Detail Integrity Concern for Others Stress Tolerance Dependability Adaptability/Flexibility 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Video conferencing software Google Meet Zoom Medical software GE Healthcare Centricity EMR Medicaid management information system MMIS 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $49,230 $33,230 $66,060 Check out my state Local Salary Info Find Jobs 
Explore More Compensation, Benefits, & Job Analysis Specialists Human Resources Assistants Human Resources Specialists Insurance Claims & Policy Processing Clerks Interviewers You might like a career in one of these industries: Government 
##############################################
Title: Embalmers 
Information:
Knowledge Business customer service management Math and Science chemistry psychology Arts and Humanities English language philosophy and religion Safety and Government law and government 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Attention to Detail Self Control Concern for Others Stress Tolerance 
Technology You might use software like this on the job: Word processing software Microsoft Word Data base user interface and query software HMIS Advantage Twin Tier Technologies MIMS Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $49,910 $31,600 $79,830 Check out my state Local Salary Info Find Jobs 
Explore More Crematory Operators Funeral Attendants Funeral Home Managers Licensed Practical & Licensed Vocational Nurses Morticians, Undertakers, & Funeral Arrangers You might like a career in one of these industries: Service 
##############################################
Title: Emergency Management Directors 
Information:
Knowledge Safety and Government public safety and security law and government Business management customer service Communications multimedia telecommunications Arts and Humanities English language 
Skills Basic Skills talking to others keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Attention do two or more things at the same time pay attention to something without being distracted Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Stress Tolerance Leadership Dependability Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software Geographic information system GIS software Presentation software Microsoft PowerPoint Data base user interface and query software Relational database software SoftRisk Technologies SoftRisk SQL 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $79,180 $46,920 $147,870 Check out my state Local Salary Info Find Jobs 
Explore More Business Continuity Planners Compliance Managers Information Security Engineers Security Management Specialists Security Managers You might like a career in one of these industries: Government 
##############################################
Title: Emergency Medical Technicians 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Information retrieval or search software Epocrates TechOnSoftware HazMatCE Pro 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $36,680 $28,270 $56,890 Check out my state Local Salary Info Find Jobs 
Explore More Ambulance Drivers & Attendants Licensed Practical & Licensed Vocational Nurses Paramedics Registered Nurses Surgical Assistants You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Emergency Medicine Physicians 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Medical software Epic Systems MEDITECH software 
Education Education: (rated 5 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $239,200+ $85,880 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Family Medicine Physicians General Internal Medicine Physicians Pediatricians, General Physical Medicine & Rehabilitation Physicians Physician Assistants You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Endoscopy Technicians 
Information:
Knowledge Arts and Humanities English language Business customer service Health medicine and dentistry Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Self Control Stress Tolerance Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software MEDITECH software Patient electronic medical record EMR software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $40,420 $28,750 $61,520 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Medical Equipment Preparers Radiologic Technologists & Technicians Surgical Assistants Surgical Technologists You might like a career in one of these industries: Health & Counseling Government Education 
##############################################
Title: Energy Auditors 
Information:
Knowledge Business customer service sales and marketing Engineering and Technology building and construction product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language 
Skills Basic Skills reading work related information talking to others People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Integrity Analytical Thinking Independence Initiative 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics SAS Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access Structured query language SQL 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $64,480 $40,370 $104,110 Check out my state Local Salary Info Find Jobs 
Explore More Energy Engineers Solar Energy Installation Managers Solar Energy Systems Engineers Solar Sales Representatives & Assessors Wind Energy Development Managers You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Energy Engineers 
Information:
Knowledge Engineering and Technology product and service development building and construction Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Business customer service management Arts and Humanities English language 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Math choose the right type of math to solve a problem add, subtract, multiply, or divide Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Integrity Dependability Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Analytical or scientific software Architectural Energy Corporation ENFORMA Building Diagnostics The MathWorks MATLAB Presentation software Microsoft PowerPoint Computer aided design CAD software Autodesk AutoCAD Home Energy Efficient Design HEED 
Education Education: (rated 4 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $104,600 $59,380 $168,320 Check out my state Local Salary Info Find Jobs 
Explore More Electrical Engineers Energy Auditors Solar Energy Systems Engineers Wind Energy Development Managers Wind Energy Engineers You might like a career in one of these industries: Professional, Science, & Technical Government Manufacturing 
##############################################
Title: Engine & Other Machine Assemblers 
Information:
Knowledge Engineering and Technology mechanical product and service development Manufactured or Agricultural Goods manufacture and distribution of products Business customer service 
Skills Basic Skills reading work related information keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Cooperation Integrity Dependability Independence Initiative 
Technology You might use software like this on the job: Computer aided design CAD software Computer aided design and drafting CADD software Dassault Systemes SolidWorks Spreadsheet software Microsoft Excel Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $50,850 $34,700 $68,360 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Electric Motor, Power Tool, & Related Repairers Electrical & Electronic Equipment Assemblers Electromechanical Equipment Assemblers Industrial Machinery Mechanics You might like a career in one of these industries: Manufacturing 
##############################################
Title: Engineering Teachers, Postsecondary 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills talking to others using the best training or teaching strategies for learning new things People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Analytical Thinking Integrity Dependability Initiative Adaptability/Flexibility Attention to Detail 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk Revit Dassault Systemes SolidWorks Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Sakai CLE 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $103,550 $56,860 $178,740 Check out my state Local Salary Info Find Jobs 
Explore More Architectural & Engineering Managers Career/Technical Education Teachers, Postsecondary Computer Science Teachers, Postsecondary Environmental Science Teachers, Postsecondary Physics Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: English Language & Literature Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities English language history and archeology Education and Training teaching and course design Communications multimedia Math and Science sociology and anthropology 
Skills Basic Skills reading work related information writing things for co-workers or customers Social teaching people how to do something looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Cooperation Dependability Adaptability/Flexibility Self Control Concern for Others 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop Computer based training software Learning management system LMS Moodle Word processing software Google Docs Microsoft Word 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $74,280 $45,980 $133,220 Check out my state Local Salary Info Find Jobs 
Explore More Area, Ethnic, & Cultural Studies Teachers, Postsecondary Foreign Language & Literature Teachers, Postsecondary History Teachers, Postsecondary Philosophy & Religion Teachers, Postsecondary Sociology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Entertainment & Recreation Managers 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Cloud-based data access and sharing software Microsoft SharePoint Slack Operating system software Apple macOS Linux 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $67,220 $38,350 $130,040 Check out my state Local Salary Info Find Jobs 
Explore More General & Operations Managers Park Naturalists Recreation Workers Recreational Therapists Social & Community Service Managers You might like a career in one of these industries: Arts & Entertainment Government 
##############################################
Title: Environmental Compliance Inspectors 
Information:
Knowledge Arts and Humanities English language Safety and Government law and government public safety and security Engineering and Technology computers and electronics Math and Science chemistry 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems measuring how well a system is working and how to improve it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Dependability Persistence Self Control Initiative 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Presentation software Microsoft PowerPoint Analytical or scientific software DQO-PRO Tibco Scribe Software 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $71,690 $41,050 $118,060 Check out my state Local Salary Info Find Jobs 
Explore More Environmental Engineers Environmental Science & Protection Technicians, Including Health Environmental Scientists & Specialists, Including Health Government Property Inspectors & Investigators Occupational Health & Safety Specialists You might like a career in one of these industries: Government Finance & Insurance 
##############################################
Title: Environmental Economists 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Business accounting and economics Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills writing things for co-workers or customers listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Achievement/Effort Attention to Detail Independence Integrity Persistence 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software Microsoft PowerPoint Development environment software Microsoft Visual Basic for Applications VBA Microsoft Visual Studio 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $113,940 $62,480 $207,230 Check out my state Local Salary Info Find Jobs 
Explore More Climate Change Policy Analysts Data Scientists Economists Environmental Scientists & Specialists, Including Health Industrial Ecologists You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Environmental Engineering Technologists & Technicians 
Information:
Knowledge Engineering and Technology product and service development mechanical Business customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics chemistry Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Attention pay attention to something without being distracted Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Cooperation Attention to Detail Integrity Dependability Initiative Stress Tolerance 
Technology You might use software like this on the job: Analytical or scientific software Statistical software The MathWorks MATLAB Presentation software Microsoft PowerPoint Compliance software Material safety data sheet MSDS software Waste management software 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $50,980 $35,810 $84,430 Check out my state Local Salary Info Find Jobs 
Explore More Civil Engineering Technologists & Technicians Environmental Compliance Inspectors Environmental Engineers Environmental Science & Protection Technicians, Including Health Environmental Scientists & Specialists, Including Health You might like a career in one of these industries: Professional, Science, & Technical Government Administration & Support Services 
##############################################
Title: Environmental Engineers 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics chemistry Arts and Humanities English language Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math add, subtract, multiply, or divide choose the right type of math to solve a problem Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Attention to Detail Analytical Thinking Cooperation Dependability Achievement/Effort 
Technology You might use software like this on the job: Analytical or scientific software Insightful S-PLUS The MathWorks MATLAB Desktop communications software Eko Compliance software Material safety data sheet MSDS software Regulatory compliance management software 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $96,530 $60,020 $150,840 Check out my state Local Salary Info Find Jobs 
Explore More Brownfield Redevelopment Specialists & Site Managers Environmental Engineering Technologists & Technicians Environmental Science & Protection Technicians, Including Health Environmental Scientists & Specialists, Including Health Water/Wastewater Engineers You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Environmental Restoration Planners 
Information:
Knowledge Math and Science biology geography Arts and Humanities English language Engineering and Technology design computers and electronics Business management 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Cooperation Integrity Initiative Adaptability/Flexibility Attention to Detail Dependability 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software Geographic information system GIS software Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $76,480 $46,920 $130,770 Check out my state Local Salary Info Find Jobs 
Explore More Brownfield Redevelopment Specialists & Site Managers Conservation Scientists Environmental Scientists & Specialists, Including Health Industrial Ecologists Range Managers You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Environmental Science & Protection Technicians, Including Health 
Information:
Knowledge Business customer service Arts and Humanities English language Math and Science chemistry biology Safety and Government law and government 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Math add, subtract, multiply, or divide choose the right type of math to solve a problem Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Attention to Detail Dependability Adaptability/Flexibility Cooperation Persistence 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software ESRI ArcView Video creation and editing software YouTube Analytical or scientific software HEC-RAS Visual OTTHYMO 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $48,380 $32,180 $82,690 Check out my state Local Salary Info Find Jobs 
Explore More Brownfield Redevelopment Specialists & Site Managers Environmental Compliance Inspectors Environmental Engineering Technologists & Technicians Environmental Engineers Environmental Scientists & Specialists, Including Health You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Environmental Science Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Math and Science biology chemistry Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Analytical Thinking Initiative Integrity Persistence Achievement/Effort Independence 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Sakai CLE Geographic information system ESRI ArcGIS software Geographic information system GIS systems 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $83,040 $47,330 $141,190 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Sciences Teachers, Postsecondary Atmospheric, Earth, Marine, & Space Sciences Teachers, Postsecondary Biological Science Teachers, Postsecondary Chemistry Teachers, Postsecondary Forestry & Conservation Science Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Environmental Scientists & Specialists, Including Health 
Information:
Knowledge Arts and Humanities English language Math and Science biology chemistry Business customer service administrative services Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions teaching people how to do something 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Analytical Thinking Attention to Detail Cooperation Dependability Adaptability/Flexibility 
Technology You might use software like this on the job: Analytical or scientific software Laboratory information management system LIMS Wolfel IMMI Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access Structured query language SQL 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $76,480 $46,920 $130,770 Check out my state Local Salary Info Find Jobs 
Explore More Conservation Scientists Environmental Compliance Inspectors Environmental Engineers Environmental Science & Protection Technicians, Including Health Industrial Ecologists You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Epidemiologists 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics biology Arts and Humanities English language Health medicine and dentistry Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Integrity Attention to Detail Initiative Dependability Cooperation 
Technology You might use software like this on the job: Analytical or scientific software StataCorp Stata World Health Organization HealthMapper Presentation software Microsoft PowerPoint Geographic information system Esri ArcGIS Geographic information system GIS software 
Education Education: (rated 5 of 5) master's degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $78,520 $51,170 $123,430 Check out my state Local Salary Info Find Jobs 
Explore More Clinical Neuropsychologists Clinical Nurse Specialists Emergency Medicine Physicians Medical Scientists Preventive Medicine Physicians You might like a career in one of these industries: Government Health & Counseling 
##############################################
Title: Equal Opportunity Representatives & Officers 
Information:
Knowledge Safety and Government law and government Arts and Humanities English language Business human resources (HR) customer service Math and Science sociology and anthropology 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly know what you are looking at 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Attention to Detail Analytical Thinking Dependability Self Control Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software IBM Lotus 1-2-3 Microsoft Excel Presentation software Microsoft PowerPoint Human resources software Bashen LinkLine Yocum & McKee The Complete AAP 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $71,690 $41,050 $118,060 Check out my state Local Salary Info Find Jobs 
Explore More Compensation, Benefits, & Job Analysis Specialists Eligibility Interviewers, Government Programs Human Resources Managers Human Resources Specialists Labor Relations Specialists You might like a career in one of these industries: Government Finance & Insurance 
##############################################
Title: Etchers & Engravers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Business customer service Engineering and Technology design mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Independence Dependability Achievement/Effort Persistence Cooperation 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Illustrator Corel CorelDraw Graphics Suite Computer aided manufacturing CAM software Delcam ArtCAM Express Western Engravers Supply Vision EXPERT Operating system software Microsoft Windows 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $37,980 $27,460 $59,530 Check out my state Local Salary Info Find Jobs 
Explore More Grinding & Polishing Workers, Hand Jewelers & Precious Stone & Metal Workers Molders, Shapers, & Casters Painting, Coating, & Decorating Workers Patternmakers, Metal & Plastic You might like a career in one of these industries: Manufacturing Retail 
##############################################
Title: Excavating & Loading Machine & Dragline Operators, Surface Mining 
Information:
Knowledge Engineering and Technology mechanical building and construction Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements figuring out how to use new ideas or things Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use hold or move items with your hands keep your arm or hand steady Spatial know where things are around you imagine how something will look after it is moved around or changed Vision decide which thing is closer or farther away from you or decide how far away it is from you 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Stress Tolerance Adaptability/Flexibility Cooperation Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Google Gmail Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $48,350 $36,590 $76,900 Check out my state Local Salary Info Find Jobs 
Explore More Construction Laborers Crane & Tower Operators Hoist & Winch Operators Mobile Heavy Equipment Mechanics Operating Engineers & Other Construction Equipment Operators You might like a career in one of these industries: Mining, Oil, & Gas Construction 
##############################################
Title: Executive Secretaries & Executive Administrative Assistants 
Information:
Knowledge Business administrative services customer service Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Social looking for ways to help people changing what is done based on other people's actions 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic order or arrange things notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Cooperation Adaptability/Flexibility Concern for Others 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Web page creation and editing software Google Sites LinkedIn Graphics or photo imaging software Adobe Systems Adobe Illustrator JamBoard 
Education Education: (rated 3 of 5) associate's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $65,980 $43,520 $101,600 Check out my state Local Salary Info Find Jobs 
Explore More Administrative Services Managers First-Line Supervisors of Office & Administrative Support Workers Legal Secretaries & Administrative Assistants Office Clerks, General Secretaries & Administrative Assistants You might like a career in one of these industries: Education Government Professional, Science, & Technical Finance & Insurance 
##############################################
Title: Exercise Physiologists 
Information:
Knowledge Business customer service administrative services Health medicine and dentistry therapy and counseling Education and Training teaching and course design Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Integrity Concern for Others Cooperation Self Control Attention to Detail 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $51,350 $39,250 $77,980 Check out my state Local Salary Info Find Jobs 
Explore More Athletic Trainers Dietitians & Nutritionists Exercise Trainers & Group Fitness Instructors Physical Medicine & Rehabilitation Physicians Physical Therapists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Exercise Trainers & Group Fitness Instructors 
Information:
Knowledge Business customer service Arts and Humanities English language Education and Training teaching and course design Math and Science psychology 
Skills Basic Skills talking to others keeping track of how well people and/or groups are doing in order to make improvements Social teaching people how to do something looking for ways to help people 
Abilities Endurance exercise for a long time without getting out of breath Flexibility, Balance, and Coordination bend, stretch, twist, or reach with your body, arms, and/or legs move your arms, legs, and mid-section together while your whole body is moving Physical Strength use your lower back and stomach exercise for a long time without your muscles getting tired Verbal communicate by speaking 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Concern for Others Integrity Social Orientation Self Control Independence 
Technology You might use software like this on the job: Accounting software Intuit QuickBooks Sage 50 Accounting Instant messaging software Blink Data base user interface and query software DietMaster Systems DietMaster ICTraining 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $45,380 $23,920 $80,330 Check out my state Local Salary Info Find Jobs 
Explore More Coaches & Scouts Exercise Physiologists Fitness & Wellness Coordinators Recreational Therapists Self-Enrichment Teachers You might like a career in one of these industries: Arts & Entertainment 
##############################################
Title: Explosives Workers, Ordnance Handling Experts, & Blasters 
Information:
Knowledge Safety and Government public safety and security law and government Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology product and service development mechanical Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Verbal listen and understand what people say communicate by speaking Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Self Control Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Analytical or scientific software Blaster's Tool and Supply Company Blaster's Calculator DetNet ViewShot 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $56,670 $40,510 $88,520 Check out my state Local Salary Info Find Jobs 
Explore More Continuous Mining Machine Operators Earth Drillers Helpers--Extraction Workers Pile Driver Operators Rotary Drill Operators, Oil & Gas You might like a career in one of these industries: Government Mining, Oil, & Gas Construction Wholesale/Commercial Sales 
##############################################
Title: Extruding & Drawing Machine Setters, Operators, & Tenders, Metal & Plastic 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Self Control Integrity Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $39,970 $30,200 $57,690 Check out my state Local Salary Info Find Jobs 
Explore More Cutting, Punching, & Press Machine Setters, Operators, & Tenders, Metal & Plastic Extruding & Forming Machine Setters, Operators, & Tenders, Synthetic & Glass Fibers Molding, Coremaking, & Casting Machine Setters, Operators, & Tenders, Metal & Plastic Multiple Machine Tool Setters, Operators, & Tenders, Metal & Plastic Paper Goods Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Extruding & Forming Machine Setters, Operators, & Tenders, Synthetic & Glass Fibers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Education and Training teaching and course design Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Verbal listen and understand what people say communicate by speaking Attention do two or more things at the same time 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Self Control Integrity Dependability Cooperation Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Industrial control software Camstar Manufacturing Execution System MES Statistical process control SPC software Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $39,860 $30,780 $59,090 Check out my state Local Salary Info Find Jobs 
Explore More Extruding & Drawing Machine Setters, Operators, & Tenders, Metal & Plastic Extruding, Forming, Pressing, & Compacting Machine Setters, Operators, & Tenders Molding, Coremaking, & Casting Machine Setters, Operators, & Tenders, Metal & Plastic Multiple Machine Tool Setters, Operators, & Tenders, Metal & Plastic Paper Goods Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Extruding, Forming, Pressing, & Compacting Machine Setters, Operators, & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical computers and electronics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement change when and how fast you move based on how something else is moving quickly change the controls of a machine, car, truck or boat Endurance exercise for a long time without getting out of breath Verbal communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Initiative Self Control Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $39,480 $30,290 $57,120 Check out my state Local Salary Info Find Jobs 
Explore More Adhesive Bonding Machine Operators & Tenders Cutting & Slicing Machine Setters, Operators, & Tenders Extruding & Forming Machine Setters, Operators, & Tenders, Synthetic & Glass Fibers Machine Feeders & Offbearers Paper Goods Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Fabric & Apparel Patternmakers 
Information:
Knowledge Engineering and Technology design Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic create new and original ideas order or arrange things Verbal communicate by speaking read and understand what is written Hand and Finger Use put together small parts with your fingers 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Adaptability/Flexibility Integrity Initiative Cooperation 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop Computer aided design CAD software Gerber Technology AccuMark PatternMaker Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $60,320 $31,190 $109,550 Check out my state Local Salary Info Find Jobs 
Explore More Fashion Designers Patternmakers, Metal & Plastic Patternmakers, Wood Sewers, Hand Tailors, Dressmakers, & Custom Sewers You might like a career in one of these industries: Manufacturing Management 
##############################################
Title: Facilities Managers 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Word processing software Google Docs Microsoft Word Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $99,030 $59,280 $164,080 Check out my state Local Salary Info Find Jobs 
Explore More Administrative Services Managers First-Line Supervisors of Housekeeping & Janitorial Workers General & Operations Managers Maintenance & Repair Workers, General Property, Real Estate, & Community Association Managers You might like a career in one of these industries: Education Government Health & Counseling Manufacturing 
##############################################
Title: Fallers 
Information:
Knowledge Engineering and Technology mechanical Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Reaction Time and Speed quickly move your hand, finger, or foot based on a sound, light, picture or other command quickly move your arms and legs Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Endurance exercise for a long time without getting out of breath Hand and Finger Use hold or move items with your hands 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Adaptability/Flexibility Integrity Self Control Concern for Others 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Geographic information system ESRI ArcView Geographic information system GIS systems 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $49,160 $31,660 $91,380 Check out my state Local Salary Info Find Jobs 
Explore More Farmworkers & Laborers, Crop, Nursery, & Greenhouse Log Graders & Scalers Logging Equipment Operators Sawing Machine Setters, Operators, & Tenders, Wood Tree Trimmers & Pruners You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting Administration & Support Services 
##############################################
Title: Family & Consumer Sciences Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Business customer service management Math and Science psychology 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Dependability Independence Cooperation Concern for Others Self Control 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Sakai CLE Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $76,440 $47,770 $149,650 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Sciences Teachers, Postsecondary Career/Technical Education Teachers, Middle School Education Teachers, Postsecondary Instructional Coordinators Recreation & Fitness Studies Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Family Medicine Physicians 
Information:
Knowledge Health medicine and dentistry therapy and counseling Arts and Humanities English language Math and Science biology psychology Business customer service 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted do two or more things at the same time Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Dependability Achievement/Effort Analytical Thinking Concern for Others Integrity 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software Epic Systems Spreadsheet software Microsoft Excel Electronic mail software Email software 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $211,300 $65,090 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians General Internal Medicine Physicians Pediatric Surgeons Pediatricians, General You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Farm & Home Management Educators 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language Manufactured or Agricultural Goods food production Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information order or arrange things Memory remember words, numbers, pictures, or steps Attention do two or more things at the same time 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Cooperation Initiative Adaptability/Flexibility Integrity Attention to Detail 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software Geographic information system GIS software Presentation software Microsoft PowerPoint Enterprise resource planning ERP software Microsoft Dynamics SAP software 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $53,450 $31,080 $87,540 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Sciences Teachers, Postsecondary Business Teachers, Postsecondary Career/Technical Education Teachers, Postsecondary Management Analysts Range Managers You might like a career in one of these industries: Education Government 
##############################################
Title: Farm Equipment Mechanics & Service Technicians 
Information:
Knowledge Engineering and Technology mechanical computers and electronics Business customer service management Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Technical planning and doing the basic maintenance on equipment repairing machines or systems using the right tools 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Self Control Integrity Cooperation Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software FarmLogic FarmPAD ServiceMax Electronic mail software Microsoft Outlook 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $48,010 $31,680 $66,620 Check out my state Local Salary Info Find Jobs 
Explore More Industrial Machinery Mechanics Maintenance & Repair Workers, General Millwrights Mobile Heavy Equipment Mechanics Outdoor Power Equipment & Other Small Engine Mechanics You might like a career in one of these industries: Wholesale/Commercial Sales Farming, Forestry, Fishing, & Hunting 
##############################################
Title: Farm Labor Contractors 
Information:
Knowledge Arts and Humanities foreign language Manufactured or Agricultural Goods food production manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics Business human resources (HR) 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Integrity Achievement/Effort Cooperation Concern for Others Initiative 
Technology You might use software like this on the job: Accounting software Bookkeeping software Intuit QuickBooks Data base user interface and query software E-Verify Microsoft Access Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $49,330 $29,880 $85,190 Check out my state Local Salary Info Find Jobs 
Explore More Farmers, Ranchers, & Other Agricultural Managers First-Line Supervisors of Farming, Fishing, & Forestry Workers First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Landscaping, Lawn Service, & Groundskeeping Workers General & Operations Managers You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting 
##############################################
Title: Farmers, Ranchers, & Other Agricultural Managers 
Information:
Knowledge Business management human resources (HR) Manufactured or Agricultural Goods manufacture and distribution of products food production Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills talking to others thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Integrity Attention to Detail Initiative Leadership Persistence 
Technology You might use software like this on the job: Data base user interface and query software Ag Leader Technology SMS Advanced Microsoft Access Presentation software Microsoft PowerPoint Enterprise resource planning ERP software Midwest MicroSystems Cow Sense SAP software 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $75,760 $37,440 $139,040 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Engineers Agricultural Sciences Teachers, Postsecondary Agricultural Technicians First-Line Supervisors of Farming, Fishing, & Forestry Workers Soil & Plant Scientists You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting 
##############################################
Title: Farmworkers & Laborers, Crop, Nursery, & Greenhouse 
Information:
Knowledge Manufactured or Agricultural Goods food production Math and Science biology 
Skills Basic Skills talking to others People and Technology Systems figuring out how a system should work and how changes in the future will affect it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Physical Strength use your lower back and stomach lift, push, pull, or carry Endurance exercise for a long time without getting out of breath Controlled Movement quickly change the controls of a machine, car, truck or boat 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software IBM Lotus Notes Microsoft Outlook 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $33,000 $29,120 $41,540 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Equipment Operators Farmworkers, Farm, Ranch, & Aquacultural Animals Graders & Sorters, Agricultural Products Landscaping & Groundskeeping Workers Pesticide Handlers, Sprayers, & Applicators, Vegetation You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting 
##############################################
Title: Farmworkers, Farm, Ranch, & Aquacultural Animals 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products food production Arts and Humanities English language Business management Math and Science biology 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic notice when problems happen use rules to solve problems Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Verbal listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Independence Adaptability/Flexibility Attention to Detail Integrity Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software BCL Landview Systems WinCrop Farm Works Software Trac Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) some college or high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $34,150 $23,200 $51,550 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Equipment Operators Animal Breeders Farmworkers & Laborers, Crop, Nursery, & Greenhouse Meat, Poultry, & Fish Cutters & Trimmers Slaughterers & Meat Packers You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting 
##############################################
Title: Fashion Designers 
Information:
Knowledge Engineering and Technology design Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic create new and original ideas come up with lots of ideas Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Innovation Dependability Initiative Adaptability/Flexibility Achievement/Effort 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk Revit Trimble SketchUp Pro Presentation software Microsoft PowerPoint Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Illustrator 
Education Education: (rated 3 of 5) associate's degree or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $76,700 $34,660 $139,920 Check out my state Local Salary Info Find Jobs 
Explore More Commercial & Industrial Designers Costume Attendants Fabric & Apparel Patternmakers Sewers, Hand Tailors, Dressmakers, & Custom Sewers You might like a career in one of these industries: Wholesale/Commercial Sales Management Retail Manufacturing 
##############################################
Title: Fast Food & Counter Workers 
Information:
Knowledge Business customer service sales and marketing Manufactured or Agricultural Goods food production manufacture and distribution of products Safety and Government public safety and security Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions Social looking for ways to help people understanding people's reactions 
Abilities Verbal listen and understand what people say communicate by speaking Attention do two or more things at the same time 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Self Control Dependability Adaptability/Flexibility Cooperation Attention to Detail Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Computer based training software Quizlet Point of sale POS software Foodman Home-Delivery Plexis Software Plexis POS 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $27,930 $21,150 $35,350 Check out my state Local Salary Info Find Jobs 
Explore More Baristas Cooks, Short Order Dining Room & Cafeteria Attendants & Bartender Helpers Food Preparation Workers Food Servers, Nonrestaurant You might like a career in one of these industries: Hotel & Food 
##############################################
Title: Fence Erectors 
Information:
Knowledge Business customer service management Engineering and Technology building and construction design Transportation movement of people or goods by air, rail, sea, or road Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath Physical Strength use your lower back and stomach lift, push, pull, or carry Controlled Movement use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Initiative Achievement/Effort Leadership Persistence 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Cutlist Plus fx Project management software Maxwell Systems American Contractor Software Design Associates Computer Fencing System CFS Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $39,840 $30,190 $61,710 Check out my state Local Salary Info Find Jobs 
Explore More Carpenters Drywall & Ceiling Tile Installers Electrical Power-Line Installers & Repairers Reinforcing Iron & Rebar Workers Structural Iron & Steel Workers You might like a career in one of these industries: Construction 
##############################################
Title: Fiberglass Laminators & Fabricators 
Information:
Knowledge Business management administrative services Manufactured or Agricultural Goods manufacture and distribution of products Education and Training teaching and course design Math and Science chemistry 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Ideas and Logic notice when problems happen order or arrange things Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Self Control Initiative Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Word processing software Microsoft Word Enterprise resource planning ERP software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $38,110 $29,490 $52,760 Check out my state Local Salary Info Find Jobs 
Explore More Coating, Painting, & Spraying Machine Setters, Operators, & Tenders Foundry Mold & Coremakers Furniture Finishers Insulation Workers, Floor, Ceiling, & Wall Molders, Shapers, & Casters You might like a career in one of these industries: Manufacturing 
##############################################
Title: File Clerks 
Information:
Knowledge Business administrative services customer service Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic order or arrange things group things in different ways 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Independence Adaptability/Flexibility 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or master's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $37,290 $26,190 $57,280 Check out my state Local Salary Info Find Jobs 
Explore More Bookkeeping, Accounting, & Auditing Clerks Correspondence Clerks Data Entry Keyers Medical Records Specialists Office Clerks, General You might like a career in one of these industries: Health & Counseling Government Professional, Science, & Technical 
##############################################
Title: Film & Video Editors 
Information:
Knowledge Communications multimedia telecommunications Arts and Humanities English language music, dance, visual arts, drama, or sculpture Engineering and Technology computers and electronics product and service development Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic order or arrange things come up with lots of ideas 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Stress Tolerance Attention to Detail Dependability Integrity Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Web platform development software AJAX Cascading style sheets CSS Video creation and editing software Screencastify YouTube Graphics or photo imaging software Adobe Systems Adobe After Effects Adobe Systems Adobe Creative Cloud software 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $63,520 $36,930 $166,730 Check out my state Local Salary Info Find Jobs 
Explore More Desktop Publishers Editors Media Technical Directors/Managers Producers & Directors Special Effects Artists & Animators You might like a career in one of these industries: Media & Communication 
##############################################
Title: Financial & Investment Analysts 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Word processing software Google Docs Microsoft Word Financial analysis software Oracle E-Business Suite Financials Oracle Hyperion Financial Management 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $95,080 $58,950 $169,940 Check out my state Local Salary Info Find Jobs 
Explore More Credit Analysts Financial Risk Specialists Investment Fund Managers Personal Financial Advisors Securities, Commodities, & Financial Services Sales Agents You might like a career in one of these industries: Finance & Insurance Professional, Science, & Technical Management 
##############################################
Title: Financial Examiners 
Information:
Knowledge Arts and Humanities English language Business accounting and economics management Safety and Government law and government Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Analytical Thinking Integrity Attention to Detail Cooperation Dependability Self Control 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Structured query language SQL Presentation software Microsoft PowerPoint Financial analysis software General Examination System GENESYS PricewaterhouseCoopers TeamMate 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $82,210 $48,490 $162,760 Check out my state Local Salary Info Find Jobs 
Explore More Accountants & Auditors Compliance Managers Credit Analysts Financial Managers Treasurers & Controllers You might like a career in one of these industries: Finance & Insurance Government 
##############################################
Title: Financial Managers 
Information:
Knowledge Business customer service management Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government law and government Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Math add, subtract, multiply, or divide choose the right type of math to solve a problem Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Integrity Cooperation Leadership Concern for Others Dependability 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft SQL Server Yardi software 
Education Education: (rated 4 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $139,790 $79,050 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Accountants & Auditors Credit Analysts Financial & Investment Analysts Financial Examiners Personal Financial Advisors You might like a career in one of these industries: Finance & Insurance Professional, Science, & Technical Management 
##############################################
Title: Financial Quantitative Analysts 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Business accounting and economics Engineering and Technology computers and electronics Arts and Humanities English language 
Skills Basic Skills using math to solve problems thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal read and understand what is written communicate by writing Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Integrity Attention to Detail Persistence Initiative Achievement/Effort 
Technology You might use software like this on the job: Object or component oriented development software C# Perl Presentation software Microsoft PowerPoint Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $73,810 $38,330 $133,080 Check out my state Local Salary Info Find Jobs 
Explore More Business Intelligence Analysts Data Scientists Financial & Investment Analysts Financial Risk Specialists Operations Research Analysts You might like a career in one of these industries: Finance & Insurance Government 
##############################################
Title: Financial Risk Specialists 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Word processing software Google Docs Microsoft Word Financial analysis software Oracle E-Business Suite Financials Wolfram Research Mathematica UnRisk Pricing Engine 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $102,120 $59,510 $175,720 Check out my state Local Salary Info Find Jobs 
Explore More Financial & Investment Analysts Financial Quantitative Analysts Investment Fund Managers Personal Financial Advisors Securities, Commodities, & Financial Services Sales Agents You might like a career in one of these industries: Finance & Insurance Management Professional, Science, & Technical 
##############################################
Title: Fine Artists, Including Painters, Sculptors, & Illustrators 
Information:
Knowledge Engineering and Technology design computers and electronics Arts and Humanities English language music, dance, visual arts, drama, or sculpture Manufactured or Agricultural Goods manufacture and distribution of products Education and Training teaching and course design 
Skills Basic Skills figuring out how to use new ideas or things thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic create new and original ideas come up with lots of ideas Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Dependability Integrity Initiative Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Photoshop Instant messaging software GroupMe Twitter Computer aided design CAD software Autodesk AutoCAD Trimble SketchUp Pro 
Education Education: (rated 3 of 5) some college or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,560 $28,440 $124,670 Check out my state Local Salary Info Find Jobs 
Explore More Craft Artists Etchers & Engravers Graphic Designers Set & Exhibit Designers Special Effects Artists & Animators You might like a career in one of these industries: Arts & Entertainment 
##############################################
Title: Fire Inspectors & Investigators 
Information:
Knowledge Safety and Government public safety and security law and government Business customer service management Engineering and Technology building and construction Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by writing listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Cooperation Attention to Detail Dependability Initiative Concern for Others 
Technology You might use software like this on the job: Data base user interface and query software Code database software Microsoft Access Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 3 of 5) certificate after high school or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $69,450 $42,930 $125,610 Check out my state Local Salary Info Find Jobs 
Explore More Construction & Building Inspectors Fire-Prevention & Protection Engineers Firefighters First-Line Supervisors of Firefighting & Prevention Workers Forest Fire Inspectors & Prevention Specialists You might like a career in one of these industries: Government 
##############################################
Title: Fire-Prevention & Protection Engineers 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic notice when problems happen use rules to solve problems Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Dependability Analytical Thinking Attention to Detail Cooperation Initiative 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk Revit Bentley MicroStation Presentation software Microsoft PowerPoint Analytical or scientific software ANSYS simulation software Simulation of fires in enclosures SOFIE software 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $100,660 $59,090 $152,550 Check out my state Local Salary Info Find Jobs 
Explore More Environmental Engineers Fire Inspectors & Investigators First-Line Supervisors of Firefighting & Prevention Workers Forest Fire Inspectors & Prevention Specialists Health & Safety Engineers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical Government Construction 
##############################################
Title: Firefighters 
Information:
Knowledge Safety and Government public safety and security law and government Business customer service management Education and Training teaching and course design Engineering and Technology building and construction 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem figuring out how to use new ideas or things Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions looking for ways to help people 
Abilities Endurance exercise for a long time without getting out of breath Verbal listen and understand what people say communicate by speaking Physical Strength lift, push, pull, or carry exercise for a long time without your muscles getting tired Hand and Finger Use keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Stress Tolerance Dependability Self Control Attention to Detail Integrity Concern for Others 
Technology You might use software like this on the job: Data base user interface and query software Fire incident reporting systems Microsoft Access Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $51,680 $29,150 $84,750 Check out my state Local Salary Info Find Jobs 
Explore More Fire Inspectors & Investigators Fire-Prevention & Protection Engineers First-Line Supervisors of Firefighting & Prevention Workers Forest Fire Inspectors & Prevention Specialists Lifeguards, Ski Patrol, & Other Recreational Protective Service Workers You might like a career in one of these industries: Government 
##############################################
Title: First-Line Supervisors of Construction Trades & Extraction Workers 
Information:
Knowledge Business management customer service Engineering and Technology building and construction mechanical Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social changing what is done based on other people's actions understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen order or arrange things Attention pay attention to something without being distracted do two or more things at the same time Hand and Finger Use hold or move items with your hands 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Dependability Leadership Integrity Self Control Initiative 
Technology You might use software like this on the job: Project management software Microsoft Project Oracle Primavera Enterprise Project Portfolio Management Presentation software Microsoft PowerPoint Data base user interface and query software Mi-Co Mi-Forms Sage 300 Construction and Real Estate 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $74,080 $47,600 $116,940 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Landscaping, Lawn Service, & Groundskeeping Workers First-Line Supervisors of Mechanics, Installers, & Repairers First-Line Supervisors of Production & Operating Workers Solar Energy Installation Managers You might like a career in one of these industries: Construction 
##############################################
Title: First-Line Supervisors of Correctional Officers 
Information:
Knowledge Safety and Government public safety and security law and government Business management administrative services Math and Science psychology sociology and anthropology Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social changing what is done based on other people's actions understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted do two or more things at the same time Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Self Control Dependability Stress Tolerance Attention to Detail Cooperation 
Technology You might use software like this on the job: Data base user interface and query software 3M Electronic Monitoring Microsoft Access Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 3 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $63,310 $42,100 $110,240 Check out my state Local Salary Info Find Jobs 
Explore More Correctional Officers & Jailers First-Line Supervisors of Police & Detectives First-Line Supervisors of Security Workers Police & Sheriff's Patrol Officers Probation Officers & Correctional Treatment Specialists You might like a career in one of these industries: Government 
##############################################
Title: First-Line Supervisors of Entertainment & Recreation Workers 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $44,660 $29,270 $73,010 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Housekeeping & Janitorial Workers First-Line Supervisors of Mechanics, Installers, & Repairers First-Line Supervisors of Non-Retail Sales Workers First-Line Supervisors of Office & Administrative Support Workers First-Line Supervisors of Personal Service Workers You might like a career in one of these industries: Arts & Entertainment Government 
##############################################
Title: First-Line Supervisors of Farming, Fishing, & Forestry Workers 
Information:
Knowledge Business management customer service Manufactured or Agricultural Goods manufacture and distribution of products food production Arts and Humanities English language Engineering and Technology mechanical 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions teaching people how to do something 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen group things in different ways 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Attention to Detail Integrity Leadership Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software Database software Valley Agricultural Software DairyCOMP 305 
Education Education: (rated 3 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $54,490 $36,020 $85,400 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Construction Trades & Extraction Workers First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Landscaping, Lawn Service, & Groundskeeping Workers First-Line Supervisors of Mechanics, Installers, & Repairers First-Line Supervisors of Production & Operating Workers You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting 
##############################################
Title: First-Line Supervisors of Firefighting & Prevention Workers 
Information:
Knowledge Safety and Government public safety and security law and government Business customer service management Education and Training teaching and course design Engineering and Technology building and construction 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted do two or more things at the same time Hand and Finger Use hold or move items with your hands 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Leadership Stress Tolerance Dependability Concern for Others Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Fire incident reporting systems Microsoft Access Presentation software Microsoft PowerPoint Analytical or scientific software BehavePlus Plume modeling software 
Education Education: (rated 3 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $80,150 $46,170 $128,810 Check out my state Local Salary Info Find Jobs 
Explore More Fire Inspectors & Investigators Fire-Prevention & Protection Engineers Firefighters First-Line Supervisors of Security Workers Forest Fire Inspectors & Prevention Specialists You might like a career in one of these industries: Government 
##############################################
Title: First-Line Supervisors of Food Preparation & Serving Workers 
Information:
Knowledge Business customer service management Manufactured or Agricultural Goods food production manufacture and distribution of products Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Social changing what is done based on other people's actions looking for ways to help people Resource Management selecting and managing the best workers for a job making spending decisions and keeping track of what is spent 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention do two or more things at the same time pay attention to something without being distracted Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Stress Tolerance Dependability Cooperation Leadership Self Control Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Restaurant Operations & Management Spreadsheet Library Word processing software Evernote Microsoft Word Point of sale POS software Intuit QuickBooks Point of Sale ParTech PixelPoint POS 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $37,050 $27,700 $59,650 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Entertainment & Recreation Workers First-Line Supervisors of Farming, Fishing, & Forestry Workers First-Line Supervisors of Housekeeping & Janitorial Workers First-Line Supervisors of Non-Retail Sales Workers First-Line Supervisors of Production & Operating Workers You might like a career in one of these industries: Hotel & Food 
##############################################
Title: First-Line Supervisors of Gambling Services Workers 
Information:
Knowledge Business customer service management Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics psychology Engineering and Technology computers and electronics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Attention pay attention to something without being distracted Ideas and Logic notice when problems happen use rules to solve problems Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Self Control Stress Tolerance Dependability Attention to Detail Cooperation 
Technology You might use software like this on the job: Office suite software Corel WordPerfect Office Suite Microsoft Office software Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $56,290 $35,200 $77,590 Check out my state Local Salary Info Find Jobs 
Explore More Gambling & Sports Book Writers & Runners Gambling Cage Workers Gambling Change Persons & Booth Cashiers Gambling Dealers Gambling Managers You might like a career in one of these industries: Government Hotel & Food Arts & Entertainment 
##############################################
Title: First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand 
Information:
Knowledge Business management customer service Transportation movement of people or goods by air, rail, sea, or road Safety and Government public safety and security Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions bringing people together to solve differences 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention do two or more things at the same time pay attention to something without being distracted 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Integrity Leadership Attention to Detail Cooperation Independence 
Technology You might use software like this on the job: Office suite software Corel WordPerfect Office Suite Microsoft Office software Presentation software Microsoft PowerPoint Enterprise resource planning ERP software Sage ERP Accpac SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,860 $37,310 $87,900 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Material-Moving Machine & Vehicle Operators First-Line Supervisors of Mechanics, Installers, & Repairers First-Line Supervisors of Office & Administrative Support Workers First-Line Supervisors of Passenger Attendants First-Line Supervisors of Production & Operating Workers You might like a career in one of these industries: Transportation & Storage Retail Wholesale/Commercial Sales 
##############################################
Title: First-Line Supervisors of Housekeeping & Janitorial Workers 
Information:
Knowledge Business customer service management Arts and Humanities English language Education and Training teaching and course design Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Leadership Self Control Attention to Detail Dependability Integrity Cooperation 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Data base user interface and query software Facility use software Microsoft Access 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $44,440 $29,890 $68,540 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Mechanics, Installers, & Repairers First-Line Supervisors of Office & Administrative Support Workers First-Line Supervisors of Passenger Attendants First-Line Supervisors of Production & Operating Workers You might like a career in one of these industries: Administration & Support Services Hotel & Food 
##############################################
Title: First-Line Supervisors of Landscaping, Lawn Service, & Groundskeeping Workers 
Information:
Knowledge Business customer service management Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics chemistry Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Resource Management managing your time and the time of other people selecting and managing the best workers for a job Social changing what is done based on other people's actions teaching people how to do something 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention do two or more things at the same time pay attention to something without being distracted Hand and Finger Use keep your arm or hand steady 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Leadership Adaptability/Flexibility Cooperation Self Control Attention to Detail 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 3 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $50,810 $36,520 $80,600 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Construction Trades & Extraction Workers First-Line Supervisors of Entertainment & Recreation Workers First-Line Supervisors of Farming, Fishing, & Forestry Workers First-Line Supervisors of Housekeeping & Janitorial Workers First-Line Supervisors of Mechanics, Installers, & Repairers You might like a career in one of these industries: Administration & Support Services 
##############################################
Title: First-Line Supervisors of Material-Moving Machine & Vehicle Operators 
Information:
Knowledge Business management customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics psychology Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted do two or more things at the same time 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Cooperation Integrity Leadership Stress Tolerance Attention to Detail Concern for Others 
Technology You might use software like this on the job: Enterprise resource planning ERP software SAP software Presentation software Microsoft PowerPoint Materials requirements planning logistics and supply chain software Warehouse management system WMS XATA XATANET 
Education Education: (rated 3 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,860 $37,310 $87,900 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Mechanics, Installers, & Repairers First-Line Supervisors of Office & Administrative Support Workers First-Line Supervisors of Passenger Attendants First-Line Supervisors of Production & Operating Workers You might like a career in one of these industries: Transportation & Storage Retail Wholesale/Commercial Sales 
##############################################
Title: First-Line Supervisors of Mechanics, Installers, & Repairers 
Information:
Knowledge Business management customer service Engineering and Technology mechanical Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Resource Management selecting and managing the best workers for a job managing your time and the time of other people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Hearing and Speech recognize spoken words speak clearly Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Attention to Detail Stress Tolerance Integrity Leadership Self Control 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access Yardi software 
Education Education: (rated 3 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $73,140 $44,900 $112,020 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Construction Trades & Extraction Workers First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Material-Moving Machine & Vehicle Operators First-Line Supervisors of Passenger Attendants First-Line Supervisors of Production & Operating Workers You might like a career in one of these industries: Service Construction Retail Government Manufacturing Real Estate & Rentals 
##############################################
Title: First-Line Supervisors of Non-Retail Sales Workers 
Information:
Knowledge Business customer service management Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Education and Training teaching and course design 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Integrity Dependability Leadership Initiative Self Control 
Technology You might use software like this on the job: Video conferencing software Cisco Webex Fuze cloud communications and collaboration software Video creation and editing software YouTube Customer relationship management CRM software Salesforce software SugarCRM Sugar UX 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $82,850 $47,570 $161,690 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Entertainment & Recreation Workers First-Line Supervisors of Mechanics, Installers, & Repairers First-Line Supervisors of Office & Administrative Support Workers First-Line Supervisors of Passenger Attendants First-Line Supervisors of Retail Sales Workers You might like a career in one of these industries: Wholesale/Commercial Sales Finance & Insurance 
##############################################
Title: First-Line Supervisors of Office & Administrative Support Workers 
Information:
Knowledge Business management customer service Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social changing what is done based on other people's actions understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Leadership Self Control Attention to Detail Concern for Others 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Data base user interface and query software Blackboard software Yardi software Accounting software Intuit QuickBooks Sage 50 Accounting 
Education Education: (rated 3 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $61,370 $38,420 $97,490 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Mechanics, Installers, & Repairers First-Line Supervisors of Non-Retail Sales Workers First-Line Supervisors of Passenger Attendants First-Line Supervisors of Security Workers You might like a career in one of these industries: Health & Counseling Finance & Insurance 
##############################################
Title: First-Line Supervisors of Passenger Attendants 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Mobile location based services software Accellos Real Dispatch Commercial vehicle operations CVO software 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,860 $37,310 $87,900 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Material-Moving Machine & Vehicle Operators First-Line Supervisors of Mechanics, Installers, & Repairers First-Line Supervisors of Office & Administrative Support Workers First-Line Supervisors of Production & Operating Workers You might like a career in one of these industries: Transportation & Storage Retail Wholesale/Commercial Sales 
##############################################
Title: First-Line Supervisors of Personal Service Workers 
Information:
Knowledge Business customer service management Arts and Humanities English language Education and Training teaching and course design Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Social understanding people's reactions changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention do two or more things at the same time pay attention to something without being distracted 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Attention to Detail Concern for Others Integrity Self Control Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 3 of 5) bachelor's degree or certificate after high school usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $43,680 $29,070 $69,690 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Entertainment & Recreation Workers First-Line Supervisors of Housekeeping & Janitorial Workers First-Line Supervisors of Mechanics, Installers, & Repairers First-Line Supervisors of Office & Administrative Support Workers First-Line Supervisors of Passenger Attendants You might like a career in one of these industries: Service Health & Counseling 
##############################################
Title: First-Line Supervisors of Police & Detectives 
Information:
Knowledge Safety and Government law and government public safety and security Arts and Humanities English language Business management customer service Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted do two or more things at the same time Hearing and Speech speak clearly 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Self Control Stress Tolerance Dependability Leadership Attention to Detail 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access National Crime Information Center (NCIC) database Presentation software Microsoft PowerPoint Graphics or photo imaging software Computer aided composite drawing software DesignWare 3D EyeWitness 
Education Education: (rated 3 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $96,290 $54,070 $155,760 Check out my state Local Salary Info Find Jobs 
Explore More Detectives & Criminal Investigators First-Line Supervisors of Correctional Officers First-Line Supervisors of Firefighting & Prevention Workers First-Line Supervisors of Security Workers Police & Sheriff's Patrol Officers You might like a career in one of these industries: Government 
##############################################
Title: First-Line Supervisors of Production & Operating Workers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Business management human resources (HR) Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Dependability Leadership Cooperation Self Control Initiative 
Technology You might use software like this on the job: Enterprise resource planning ERP software Oracle JD Edwards EnterpriseOne SAP software Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $63,510 $40,180 $102,070 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Construction Trades & Extraction Workers First-Line Supervisors of Farming, Fishing, & Forestry Workers First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Mechanics, Installers, & Repairers First-Line Supervisors of Passenger Attendants You might like a career in one of these industries: Manufacturing 
##############################################
Title: First-Line Supervisors of Retail Sales Workers 
Information:
Knowledge Business customer service management Arts and Humanities English language Education and Training teaching and course design Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people changing what is done based on other people's actions People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Stress Tolerance Cooperation Leadership Dependability Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Oracle Database Yardi software Video creation and editing software Apple Final Cut Pro YouTube Point of sale POS software CyberMatrix POS Plexis Software Plexis POS 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $45,250 $29,310 $74,580 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Entertainment & Recreation Workers First-Line Supervisors of Food Preparation & Serving Workers First-Line Supervisors of Non-Retail Sales Workers First-Line Supervisors of Office & Administrative Support Workers Sales Representatives of Services You might like a career in one of these industries: Retail 
##############################################
Title: First-Line Supervisors of Security Workers 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Oracle software Presentation software Microsoft PowerPoint Office suite software Microsoft Office Microsoft Office software 
Education Education: (rated 2 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $48,810 $31,890 $81,000 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Construction Trades & Extraction Workers First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Mechanics, Installers, & Repairers First-Line Supervisors of Office & Administrative Support Workers Security Managers You might like a career in one of these industries: Administration & Support Services 
##############################################
Title: Fish & Game Wardens 
Information:
Knowledge Safety and Government law and government public safety and security Arts and Humanities English language Math and Science biology psychology Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly know what you are looking at Attention do two or more things at the same time 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Dependability Stress Tolerance Adaptability/Flexibility Attention to Detail Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Configuration management software Puppet 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $59,500 $31,200 $85,300 Check out my state Local Salary Info Find Jobs 
Explore More Conservation Scientists Forest & Conservation Technicians Forest Fire Inspectors & Prevention Specialists Park Naturalists Range Managers You might like a career in one of these industries: Government 
##############################################
Title: Fishing & Hunting Workers 
Information:
Knowledge Safety and Government law and government Engineering and Technology mechanical 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Initiative Attention to Detail Independence Integrity Persistence 
Technology You might use software like this on the job: Map creation software MaxSea TIMEZERO Trimble MyTopo Terrain Navigator Pro Analytical or scientific software DeerDays Winchester Ammunition Ballistics Calculator Data base user interface and query software Catchlog Trading Catchlog OLRAC Electronic Logbook Software Solution 
Education Education: (rated 1 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $33,970 $28,660 $52,780 Local Salary Info Find Jobs 
Explore More Captains, Mates, & Pilots of Water Vessels Farmworkers, Farm, Ranch, & Aquacultural Animals Meat, Poultry, & Fish Cutters & Trimmers Motorboat Operators Sailors & Marine Oilers You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting 
##############################################
Title: Fitness & Wellness Coordinators 
Information:
Knowledge Business customer service management Education and Training teaching and course design Arts and Humanities English language Math and Science psychology 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social changing what is done based on other people's actions looking for ways to help people People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems notice when problems happen Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Initiative Dependability Leadership Adaptability/Flexibility Integrity Concern for Others 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Data base user interface and query software Airtable MicroFit HealthWizard Office suite software Google Workspace software Microsoft Office software 
Education Education: (rated 4 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $56,090 $35,010 $96,930 Check out my state Local Salary Info Find Jobs 
Explore More Community Health Workers Dietitians & Nutritionists Exercise Trainers & Group Fitness Instructors Health Education Specialists Training & Development Managers You might like a career in one of these industries: Service 
##############################################
Title: Flight Attendants 
Information:
Knowledge Business customer service human resources (HR) Safety and Government public safety and security Arts and Humanities English language Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills talking to others keeping track of how well people and/or groups are doing in order to make improvements Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Hearing and Speech speak clearly recognize spoken words Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Concern for Others Dependability Adaptability/Flexibility Cooperation Social Orientation Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Calendar and scheduling software SBS International Maestro Suite ValtamTech Flight Crew Log 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $63,760 $37,690 $97,170 Check out my state Local Salary Info Find Jobs 
Explore More Airfield Operations Specialists Baggage Porters & Bellhops First-Line Supervisors of Passenger Attendants Passenger Attendants Reservation & Transportation Ticket Agents & Travel Clerks You might like a career in one of these industries: Transportation & Storage 
##############################################
Title: Floor Layers 
Information:
Knowledge Engineering and Technology building and construction mechanical Business customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Cooperation Independence Integrity Achievement/Effort 
Technology You might use software like this on the job: Office suite software Microsoft Office software Data base user interface and query software Aya Associates Comp-U-Floor Textile Management Systems RollMaster Project management software CPR Software FloorCOST Estimator for Excel Measure Square FloorEstimate Pro 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $48,870 $32,800 $83,320 Check out my state Local Salary Info Find Jobs 
Explore More Carpet Installers Floor Sanders & Finishers Insulation Workers, Floor, Ceiling, & Wall Terrazzo Workers & Finishers Tile & Stone Setters You might like a career in one of these industries: Construction Retail 
##############################################
Title: Floor Sanders & Finishers 
Information:
Knowledge Engineering and Technology building and construction mechanical Business customer service management Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Endurance exercise for a long time without getting out of breath Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Independence Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Project management software Measure Square Pacific Solutions FloorRight Video creation and editing software Vimeo 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,060 $29,640 $75,360 Check out my state Local Salary Info Find Jobs 
Explore More Carpet Installers Floor Layers Furniture Finishers Terrazzo Workers & Finishers Tile & Stone Setters You might like a career in one of these industries: Construction 
##############################################
Title: Floral Designers 
Information:
Knowledge Business customer service sales and marketing Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Engineering and Technology design 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic create new and original ideas group things in different ways Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Dependability Attention to Detail Integrity Cooperation Concern for Others Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $33,160 $23,540 $46,560 Check out my state Local Salary Info Find Jobs 
Explore More Craft Artists Fashion Designers Painting, Coating, & Decorating Workers Sewers, Hand Tailors, Dressmakers, & Custom Sewers You might like a career in one of these industries: Retail 
##############################################
Title: Food & Tobacco Roasting, Baking, & Drying Machine Operators & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products food production Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Independence Cooperation Self Control Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Email software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $37,790 $27,040 $55,110 Check out my state Local Salary Info Find Jobs 
Explore More Cleaning, Washing, & Metal Pickling Equipment Operators & Tenders Food Batchmakers Food Cooking Machine Operators & Tenders Furnace, Kiln, Oven, Drier, & Kettle Operators & Tenders Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Food Batchmakers 
Information:
Knowledge Manufactured or Agricultural Goods food production manufacture and distribution of products Safety and Government public safety and security Education and Training teaching and course design Arts and Humanities English language 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Hand and Finger Use hold or move items with your hands 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Achievement/Effort Self Control Concern for Others Cooperation 
Technology You might use software like this on the job: Office suite software Microsoft Office software Enterprise resource planning ERP software Plex Systems Plex Manufacturing Cloud Inventory management software Edible Software 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $36,580 $27,290 $52,580 Check out my state Local Salary Info Find Jobs 
Explore More Bakers Food & Tobacco Roasting, Baking, & Drying Machine Operators & Tenders Food Cooking Machine Operators & Tenders Mixing & Blending Machine Setters, Operators, & Tenders Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Food Cooking Machine Operators & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products food production Business management Education and Training teaching and course design Engineering and Technology mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Self Control Integrity Attention to Detail Stress Tolerance Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Database software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $36,700 $27,040 $50,240 Check out my state Local Salary Info Find Jobs 
Explore More Bakers Cooling & Freezing Equipment Operators & Tenders Food & Tobacco Roasting, Baking, & Drying Machine Operators & Tenders Food Batchmakers Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing Hotel & Food 
##############################################
Title: Food Preparation Workers 
Information:
Knowledge Business customer service management Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social changing what is done based on other people's actions looking for ways to help people 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Self Control Cooperation Integrity Social Orientation Attention to Detail Dependability 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Computer based training software Quizlet Data base user interface and query software MicroBlast Recipe Wizard for Windows ValuSoft MasterCook 
Education Education: (rated 1 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $29,790 $21,770 $38,780 Check out my state Local Salary Info Find Jobs 
Explore More Cooks, Institution & Cafeteria Cooks, Restaurant Cooks, Short Order Dining Room & Cafeteria Attendants & Bartender Helpers Fast Food & Counter Workers You might like a career in one of these industries: Hotel & Food Retail 
##############################################
Title: Food Science Technicians 
Information:
Knowledge Manufactured or Agricultural Goods food production manufacture and distribution of products Arts and Humanities English language Math and Science chemistry biology Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Integrity Adaptability/Flexibility Analytical Thinking 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Microsoft SQL Server Presentation software Microsoft PowerPoint Electronic mail software IBM Lotus Notes Microsoft Outlook 
Education Education: (rated 3 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $47,860 $36,050 $71,370 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Inspectors Agricultural Technicians Chemical Technicians Food Scientists & Technologists Quality Control Analysts You might like a career in one of these industries: Manufacturing Farming, Forestry, Fishing, & Hunting 
##############################################
Title: Food Scientists & Technologists 
Information:
Knowledge Manufactured or Agricultural Goods food production manufacture and distribution of products Math and Science biology chemistry Arts and Humanities English language Engineering and Technology product and service development 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Math add, subtract, multiply, or divide choose the right type of math to solve a problem Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Integrity Attention to Detail Cooperation Achievement/Effort Dependability 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Analytical or scientific software Insightful S-PLUS MDS Analytical Technologies GenePix Pro 
Education Education: (rated 4 of 5) bachelor's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $79,860 $47,900 $132,230 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Technicians Animal Scientists Chemists Food Science Technicians Soil & Plant Scientists You might like a career in one of these industries: Manufacturing Professional, Science, & Technical Management 
##############################################
Title: Food Servers, Nonrestaurant 
Information:
Knowledge Arts and Humanities English language Business customer service Manufactured or Agricultural Goods food production 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use hold or move items with your hands 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Attention to Detail Self Control Stress Tolerance Dependability Integrity Adaptability/Flexibility 
Technology You might use software like this on the job: Office suite software Microsoft Office software Data base user interface and query software CBORD Nutrition Service Suite Picis CareSuite Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $30,300 $22,680 $41,920 Check out my state Local Salary Info Find Jobs 
Explore More Cooks, Short Order Dining Room & Cafeteria Attendants & Bartender Helpers Fast Food & Counter Workers Food Preparation Workers Waiters & Waitresses You might like a career in one of these industries: Health & Counseling Hotel & Food 
##############################################
Title: Food Service Managers 
Information:
Knowledge Business customer service management Arts and Humanities English language Education and Training teaching and course design Manufactured or Agricultural Goods food production 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social looking for ways to help people changing what is done based on other people's actions Resource Management selecting and managing the best workers for a job managing your time and the time of other people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Leadership Self Control Cooperation Stress Tolerance Attention to Detail 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Point of sale POS software ClubSoft Food & Beverage Point of Sale Restaurant Manager Analytical or scientific software Aurora FoodPro SweetWARE nutraCoster 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $61,310 $38,740 $100,520 Check out my state Local Salary Info Find Jobs 
Explore More Chefs & Head Cooks Cooks, Institution & Cafeteria Cooks, Restaurant First-Line Supervisors of Food Preparation & Serving Workers Food Servers, Nonrestaurant You might like a career in one of these industries: Hotel & Food 
##############################################
Title: Foreign Language & Literature Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities foreign language English language Education and Training teaching and course design Math and Science sociology and anthropology geography Business administrative services 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Self Control Persistence Dependability Stress Tolerance Achievement/Effort Concern for Others 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Moodle Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $76,030 $46,420 $130,250 Check out my state Local Salary Info Find Jobs 
Explore More Adult Basic Education, Adult Secondary Education, & English as a Second Language Instructors Area, Ethnic, & Cultural Studies Teachers, Postsecondary English Language & Literature Teachers, Postsecondary History Teachers, Postsecondary Secondary School Teachers You might like a career in one of these industries: Education 
##############################################
Title: Forensic Science Technicians 
Information:
Knowledge Safety and Government law and government public safety and security Education and Training teaching and course design Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Attention to Detail Dependability Adaptability/Flexibility Cooperation Self Control 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Photoshop Graphics software Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access National Crime Information Center (NCIC) database 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $63,740 $39,710 $104,330 Check out my state Local Salary Info Find Jobs 
Explore More Coroners Detectives & Criminal Investigators Medical & Clinical Laboratory Technicians Medical & Clinical Laboratory Technologists Police Identification & Records Officers You might like a career in one of these industries: Government 
##############################################
Title: Forest & Conservation Technicians 
Information:
Knowledge Safety and Government public safety and security law and government Arts and Humanities English language Business customer service management Math and Science geography 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Hand and Finger Use hold or move items with your hands keep your arm or hand steady Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Adaptability/Flexibility Dependability Cooperation Attention to Detail Independence 
Technology You might use software like this on the job: Data base user interface and query software Assisi Compiler Microsoft Access Presentation software Corel Presentation Microsoft PowerPoint Map creation software Ben Meadows Yeoman Expedition Leica Geosystems ERDAS IMAGINE 
Education Education: (rated 3 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $41,520 $32,550 $62,400 Check out my state Local Salary Info Find Jobs 
Explore More Conservation Scientists First-Line Supervisors of Farming, Fishing, & Forestry Workers Forest Fire Inspectors & Prevention Specialists Foresters Range Managers You might like a career in one of these industries: Government 
##############################################
Title: Forest & Conservation Workers 
Information:
Knowledge Arts and Humanities English language Safety and Government public safety and security Business customer service management Math and Science biology 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Endurance exercise for a long time without getting out of breath Verbal listen and understand what people say communicate by speaking Hand and Finger Use hold or move items with your hands keep your arm or hand steady Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Cooperation Dependability Adaptability/Flexibility Integrity Stress Tolerance Initiative 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software Geographic information system GIS software Presentation software Microsoft PowerPoint Spreadsheet software IBM Lotus 1-2-3 Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $32,270 $26,690 $48,270 Check out my state Local Salary Info Find Jobs 
Explore More Conservation Scientists Farmworkers & Laborers, Crop, Nursery, & Greenhouse Forest & Conservation Technicians Foresters Range Managers You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting Government 
##############################################
Title: Forest Fire Inspectors & Prevention Specialists 
Information:
Knowledge Business management customer service Education and Training teaching and course design Safety and Government public safety and security law and government Engineering and Technology computers and electronics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions teaching people how to do something 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Stress Tolerance Leadership Integrity Self Control Independence 
Technology You might use software like this on the job: Application server software Docker Microsoft Windows Server Presentation software Microsoft PowerPoint Data base user interface and query software Amazon Web Services AWS software Relational database software 
Education Education: (rated 3 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $48,110 $30,000 $90,120 Check out my state Local Salary Info Find Jobs 
Explore More Fire Inspectors & Investigators Fire-Prevention & Protection Engineers Firefighters First-Line Supervisors of Firefighting & Prevention Workers Forest & Conservation Technicians You might like a career in one of these industries: Government 
##############################################
Title: Foresters 
Information:
Knowledge Business customer service management Arts and Humanities English language Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic group things in different ways make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Dependability Cooperation Adaptability/Flexibility Attention to Detail Concern for Others 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software Geographic information system GIS software Presentation software Microsoft PowerPoint Electronic mail software IBM Notes Microsoft Outlook 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $64,220 $45,260 $97,460 Check out my state Local Salary Info Find Jobs 
Explore More Conservation Scientists Environmental Restoration Planners Environmental Scientists & Specialists, Including Health Forest & Conservation Technicians Range Managers You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting Government 
##############################################
Title: Forestry & Conservation Science Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Math and Science arithmetic, algebra, geometry, calculus, or statistics biology Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information using the best training or teaching strategies for learning new things Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Achievement/Effort Initiative Integrity Independence Self Control Dependability 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Sakai CLE Data base user interface and query software Oracle Database Structure query language SQL 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $96,500 $51,840 $138,310 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Sciences Teachers, Postsecondary Atmospheric, Earth, Marine, & Space Sciences Teachers, Postsecondary Biological Science Teachers, Postsecondary Environmental Science Teachers, Postsecondary Geography Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Forging Machine Setters, Operators, & Tenders, Metal & Plastic 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics Education and Training teaching and course design Engineering and Technology mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use keep your arm or hand steady hold or move items with your hands 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Self Control Adaptability/Flexibility 
Technology You might use software like this on the job: Electronic mail software Email software Inventory management software Inventory tracking software Industrial control software Machine control software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,540 $32,410 $62,900 Check out my state Local Salary Info Find Jobs 
Explore More Cutting, Punching, & Press Machine Setters, Operators, & Tenders, Metal & Plastic Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Multiple Machine Tool Setters, Operators, & Tenders, Metal & Plastic Tool & Die Makers Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Foundry Mold & Coremakers 
Information:
Knowledge Arts and Humanities English language Engineering and Technology mechanical design Education and Training teaching and course design Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Physical Strength use your lower back and stomach lift, push, pull, or carry 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Initiative Achievement/Effort Concern for Others 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks Computer aided manufacturing CAM software CNC Software Mastercam Inventory management software Inventory tracking software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $40,120 $29,620 $57,870 Check out my state Local Salary Info Find Jobs 
Explore More Fiberglass Laminators & Fabricators Grinding & Polishing Workers, Hand Machine Feeders & Offbearers Molders, Shapers, & Casters Molding, Coremaking, & Casting Machine Setters, Operators, & Tenders, Metal & Plastic You might like a career in one of these industries: Manufacturing 
##############################################
Title: Fraud Examiners, Investigators & Analysts 
Information:
Knowledge Arts and Humanities English language Business accounting and economics management Safety and Government law and government public safety and security Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions writing things for co-workers or customers Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal communicate by writing listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Attention to Detail Analytical Thinking Dependability Cooperation Persistence 
Technology You might use software like this on the job: Business intelligence and data analysis software Tableau TIBCO Spotfire Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft SQL Server Structured query language SQL 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $73,810 $38,330 $133,080 Check out my state Local Salary Info Find Jobs 
Explore More Detectives & Criminal Investigators Financial Examiners Intelligence Analysts Lawyers Private Detectives & Investigators You might like a career in one of these industries: Finance & Insurance Government 
##############################################
Title: Freight Forwarders 
Information:
Knowledge Business customer service administrative services Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Social changing what is done based on other people's actions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Stress Tolerance Dependability Integrity Cooperation Achievement/Effort 
Technology You might use software like this on the job: Enterprise resource planning ERP software Oracle JD Edwards EnterpriseOne SAP software Presentation software Microsoft PowerPoint Materials requirements planning logistics and supply chain software Arcline ArcFreight TMW Enterprise Transportation Management Systems 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $46,860 $32,720 $70,280 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Cargo Handling Supervisors Cargo & Freight Agents Customs Brokers Postal Service Clerks Shipping, Receiving, & Inventory Clerks You might like a career in one of these industries: Transportation & Storage 
##############################################
Title: Fuel Cell Engineers 
Information:
Knowledge Engineering and Technology product and service development design Math and Science chemistry arithmetic, algebra, geometry, calculus, or statistics Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Analytical Thinking Attention to Detail Cooperation Integrity Initiative Dependability 
Technology You might use software like this on the job: Development environment software C National Instruments LabVIEW Presentation software Microsoft PowerPoint Analytical or scientific software Gaussian GaussView Minitab 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $96,310 $61,990 $151,260 Check out my state Local Salary Info Find Jobs 
Explore More Automotive Engineers Chemical Engineers Electrical Engineers Mechanical Engineers Microsystems Engineers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Fundraisers 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language Communications multimedia Engineering and Technology computers and electronics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social talking people into changing their minds or their behavior bringing people together to solve differences Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information come up with lots of ideas Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Persistence Initiative Achievement/Effort Attention to Detail 
Technology You might use software like this on the job: Customer relationship management CRM software Blackbaud The Raiser's Edge Microsoft Dynamics Presentation software Microsoft PowerPoint Data base user interface and query software Database software Structured query language SQL 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $61,190 $37,700 $102,740 Check out my state Local Salary Info Find Jobs 
Explore More Advertising & Promotions Managers Fundraising Managers Meeting, Convention, & Event Planners Public Relations Specialists Social & Community Service Managers You might like a career in one of these industries: Service Education Health & Counseling 
##############################################
Title: Fundraising Managers 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Video creation and editing software Adobe Systems Adobe After Effects YouTube Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Illustrator Desktop publishing software Adobe Systems Adobe InDesign Microsoft Publisher 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $107,390 $63,320 $205,230 Check out my state Local Salary Info Find Jobs 
Explore More Advertising & Promotions Managers Fundraisers Public Relations Managers Public Relations Specialists Social & Community Service Managers You might like a career in one of these industries: Service Education Health & Counseling 
##############################################
Title: Funeral Attendants 
Information:
Knowledge Business customer service Arts and Humanities English language 
Skills Basic Skills talking to others keeping track of how well people and/or groups are doing in order to make improvements Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Dependability Concern for Others Self Control Cooperation Attention to Detail 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Accounting software Bookkeeping software Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $31,160 $23,210 $46,420 Check out my state Local Salary Info Find Jobs 
Explore More Crematory Operators Embalmers Funeral Home Managers Morticians, Undertakers, & Funeral Arrangers Personal Care Aides You might like a career in one of these industries: Service 
##############################################
Title: Funeral Home Managers 
Information:
Knowledge Business customer service management Arts and Humanities English language philosophy and religion Engineering and Technology computers and electronics Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Integrity Dependability Self Control Stress Tolerance Concern for Others 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software funeralOne Life Tributes Microsoft PowerPoint Data base user interface and query software HMIS Advantage Twin Tiers Technologies CIMS 
Education Education: (rated 3 of 5) associate's degree or professional degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $72,110 $43,910 $133,010 Check out my state Local Salary Info Find Jobs 
Explore More Crematory Operators First-Line Supervisors of Personal Service Workers Funeral Attendants Morticians, Undertakers, & Funeral Arrangers Social & Community Service Managers You might like a career in one of these industries: Service 
##############################################
Title: Furnace, Kiln, Oven, Drier, & Kettle Operators & Tenders 
Information:
Knowledge Engineering and Technology mechanical Manufactured or Agricultural Goods manufacture and distribution of products Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Hand and Finger Use keep your arm or hand steady hold or move items with your hands 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Initiative Stress Tolerance Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Word processing software Microsoft Word Inventory management software Inventory tracking software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $44,530 $31,450 $62,410 Check out my state Local Salary Info Find Jobs 
Explore More Extruding, Forming, Pressing, & Compacting Machine Setters, Operators, & Tenders Heat Treating Equipment Setters, Operators, & Tenders, Metal & Plastic Machine Feeders & Offbearers Metal-Refining Furnace Operators & Tenders Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Furniture Finishers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic group things in different ways make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Persistence Independence Initiative 
Technology You might use software like this on the job: Accounting software Intuit QuickBooks Data base user interface and query software DuPont ColorNet DuPont Spies Hecker Wizard Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $37,960 $28,370 $52,920 Check out my state Local Salary Info Find Jobs 
Explore More Cabinetmakers & Bench Carpenters Grinding & Polishing Workers, Hand Molders, Shapers, & Casters Painting, Coating, & Decorating Workers Upholsterers You might like a career in one of these industries: Manufacturing Service 
##############################################
Title: Gambling & Sports Book Writers & Runners 
Information:
Knowledge Business customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal listen and understand what people say communicate by speaking Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Cooperation Integrity Self Control Attention to Detail Stress Tolerance Dependability 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Point of sale POS software Credit card processing software Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $28,940 $20,890 $43,480 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Gambling Services Workers Gambling Cage Workers Gambling Change Persons & Booth Cashiers Gambling Dealers Gambling Managers You might like a career in one of these industries: Arts & Entertainment Service Government 
##############################################
Title: Gambling Cage Workers 
Information:
Knowledge Business customer service management Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social looking for ways to help people understanding people's reactions 
Abilities Math add, subtract, multiply, or divide choose the right type of math to solve a problem Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Self Control Stress Tolerance Cooperation 
Technology You might use software like this on the job: Office suite software Corel WordPerfect Office Suite Microsoft Office software Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $31,720 $23,480 $44,460 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Gambling Services Workers Gambling & Sports Book Writers & Runners Gambling Change Persons & Booth Cashiers Gambling Managers Tellers You might like a career in one of these industries: Government Arts & Entertainment Hotel & Food 
##############################################
Title: Gambling Change Persons & Booth Cashiers 
Information:
Knowledge Business customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Integrity Self Control Cooperation Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Word processing software Microsoft Word 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $30,010 $21,130 $46,840 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Gambling Services Workers Gambling & Sports Book Writers & Runners Gambling Cage Workers Gambling Managers Tellers You might like a career in one of these industries: Government Arts & Entertainment Hotel & Food 
##############################################
Title: Gambling Dealers 
Information:
Knowledge Business customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use put together small parts with your fingers hold or move items with your hands 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Self Control Stress Tolerance Cooperation Attention to Detail Social Orientation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Cloud-based data access and sharing software Slack Electronic mail software Email software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $29,120 $19,590 $61,060 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Gambling Services Workers Gambling & Sports Book Writers & Runners Gambling Cage Workers Gambling Change Persons & Booth Cashiers Gambling Managers You might like a career in one of these industries: Hotel & Food Arts & Entertainment Government 
##############################################
Title: Gambling Managers 
Information:
Knowledge Business customer service management Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics psychology Engineering and Technology computers and electronics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Attention to Detail Leadership Self Control Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $80,710 $49,710 $163,190 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Gambling Services Workers First-Line Supervisors of Office & Administrative Support Workers Gambling & Sports Book Writers & Runners Gambling Surveillance Officers & Gambling Investigators Lodging Managers You might like a career in one of these industries: Government Arts & Entertainment Hotel & Food 
##############################################
Title: Gambling Surveillance Officers & Gambling Investigators 
Information:
Knowledge Arts and Humanities English language Safety and Government public safety and security Engineering and Technology computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by writing Attention pay attention to something without being distracted do two or more things at the same time Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Cooperation Attention to Detail Stress Tolerance Dependability Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software FileMaker Pro iView Systems 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $35,970 $27,400 $57,190 Check out my state Local Salary Info Find Jobs 
Explore More Digital Forensics Analysts First-Line Supervisors of Gambling Services Workers First-Line Supervisors of Security Workers Security Management Specialists Security Managers You might like a career in one of these industries: Government Arts & Entertainment Hotel & Food 
##############################################
Title: Gas Compressor & Gas Pumping Station Operators 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical computers and electronics Business management customer service Safety and Government public safety and security 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Stress Tolerance Attention to Detail Persistence Achievement/Effort 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Facilities management software Computerized maintenance management system CMMS 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $59,990 $33,680 $90,720 Check out my state Local Salary Info Find Jobs 
Explore More Gas Plant Operators Petroleum Pump System Operators, Refinery Operators, & Gaugers Power Plant Operators Pump Operators Stationary Engineers & Boiler Operators You might like a career in one of these industries: Transportation & Storage Wholesale/Commercial Sales Utilities 
##############################################
Title: Gas Plant Operators 
Information:
Knowledge Safety and Government public safety and security Engineering and Technology mechanical computers and electronics Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns Attention pay attention to something without being distracted do two or more things at the same time Verbal listen and understand what people say communicate by writing Hand and Finger Use hold or move items with your hands 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Integrity Analytical Thinking Cooperation Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software Operating log software Quorum PGAS 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $79,460 $46,190 $107,700 Check out my state Local Salary Info Find Jobs 
Explore More Biomass Plant Technicians Chemical Plant & System Operators Gas Compressor & Gas Pumping Station Operators Petroleum Pump System Operators, Refinery Operators, & Gaugers Power Plant Operators You might like a career in one of these industries: Utilities Transportation & Storage 
##############################################
Title: Gem & Diamond Workers 
Information:
Knowledge Business customer service sales and marketing Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Ideas and Logic notice when problems happen group things in different ways 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Attention to Detail Dependability Persistence Independence Cooperation 
Technology You might use software like this on the job: Computer aided design CAD software GemCad Jewelry design software Accounting software Business accounting software Analytical or scientific software Spectrophotometer analysis software 
Education Education: (rated 3 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,140 $30,150 $76,130 Check out my state Local Salary Info Find Jobs 
Explore More Etchers & Engravers Grinding & Polishing Workers, Hand Jewelers & Precious Stone & Metal Workers Stone Cutters & Carvers, Manufacturing Tool Grinders, Filers, & Sharpeners You might like a career in one of these industries: Retail Manufacturing 
##############################################
Title: General & Operations Managers 
Information:
Knowledge Business management customer service Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Integrity Attention to Detail Leadership Initiative Stress Tolerance 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Data base user interface and query software Blackboard software Yardi software Customer relationship management CRM software Salesforce software Sugar CRM 
Education Education: (rated 4 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,100 $43,470 $221,270 Check out my state Local Salary Info Find Jobs 
Explore More Administrative Services Managers Chief Executives Facilities Managers First-Line Supervisors of Non-Retail Sales Workers Transportation, Storage, & Distribution Managers You might like a career in one of these industries: Retail Professional, Science, & Technical 
##############################################
Title: General Internal Medicine Physicians 
Information:
Knowledge Health medicine and dentistry therapy and counseling Math and Science biology psychology Education and Training teaching and course design Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Integrity Dependability Stress Tolerance Concern for Others Achievement/Effort 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software Epic Systems Electronic mail software Email software MicroFocus GroupWise Internet browser software Microsoft Internet Explorer Web browser software 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $214,460 $64,620 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians Family Medicine Physicians Orthopedic Surgeons Pediatric Surgeons You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Genetic Counselors 
Information:
Knowledge Math and Science biology psychology Health medicine and dentistry therapy and counseling Arts and Humanities English language Business customer service 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Math choose the right type of math to solve a problem 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Integrity Attention to Detail Self Control Cooperation Dependability 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Presentation software Microsoft PowerPoint Medical software Prognosis Innovation Healthcare ChartAccess Wageningen MapChart 
Education Education: (rated 5 of 5) master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $89,990 $64,360 $128,380 Check out my state Local Salary Info Find Jobs 
Explore More Advanced Practice Psychiatric Nurses Clinical Nurse Specialists Family Medicine Physicians Pediatricians, General Psychiatrists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Geneticists 
Information:
Knowledge Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Education and Training teaching and course design Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information using scientific rules and strategies to solve problems Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information group things in different ways Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Analytical Thinking Attention to Detail Persistence Achievement/Effort Initiative 
Technology You might use software like this on the job: Analytical or scientific software SAS JMP SAS/Genetics Presentation software Microsoft PowerPoint Object or component oriented development software Perl R 
Education Education: (rated 5 of 5) post-doctoral training or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $87,300 $50,730 $155,570 Check out my state Local Salary Info Find Jobs 
Explore More Biochemists & Biophysicists Bioinformatics Scientists Medical Scientists Microbiologists Molecular & Cellular Biologists You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Geodetic Surveyors 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics geography Engineering and Technology product and service development computers and electronics Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills using math to solve problems reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic make general rules or come up with answers from lots of detailed information order or arrange things Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Integrity Analytical Thinking Dependability Cooperation Persistence 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Bentley MicroStation Presentation software Microsoft PowerPoint Analytical or scientific software QuickCogo Underhill Geomatics Copan 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $63,080 $39,060 $106,880 Check out my state Local Salary Info Find Jobs 
Explore More Cartographers & Photogrammetrists Geographic Information Systems Technologists & Technicians Geological Technicians Surveying & Mapping Technicians Surveyors You might like a career in one of these industries: Professional, Science, & Technical 
##############################################
Title: Geographers 
Information:
Knowledge Math and Science geography sociology and anthropology Arts and Humanities English language Engineering and Technology computers and electronics Education and Training teaching and course design 
Skills Basic Skills reading work related information writing things for co-workers or customers Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Analytical Thinking Initiative Dependability Integrity Persistence 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software Microsoft PowerPoint Map creation software Leica Geosystems ERDAS IMAGINE Martin D Adamiker's TruFlite 
Education Education: (rated 4 of 5) bachelor's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $88,900 $57,430 $121,060 Check out my state Local Salary Info Find Jobs 
Explore More Anthropologists & Archeologists Data Scientists Geographic Information Systems Technologists & Technicians Geography Teachers, Postsecondary Geoscientists You might like a career in one of these industries: Government 
##############################################
Title: Geographic Information Systems Technologists & Technicians 
Information:
Knowledge Math and Science geography arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics design Arts and Humanities English language Business customer service 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Analytical Thinking Independence Integrity Dependability Initiative 
Technology You might use software like this on the job: Development environment software Microsoft .NET Framework Microsoft Visual Basic for Applications VBA Word processing software Microsoft OneNote Microsoft Word Web platform development software Microsoft ASP.NET Oracle JavaServer Pages JSP 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,740 $46,540 $166,780 Check out my state Local Salary Info Find Jobs 
Explore More Cartographers & Photogrammetrists Data Scientists Geodetic Surveyors Software Developers Surveying & Mapping Technicians You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Geography Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities English language history and archeology Math and Science geography arithmetic, algebra, geometry, calculus, or statistics Education and Training teaching and course design Engineering and Technology computers and electronics 
Skills Basic Skills talking to others writing things for co-workers or customers Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Independence Dependability Initiative Analytical Thinking Achievement/Effort 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software Geographic information system GIS software Word processing software Google Docs Microsoft Word Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB 
Education Education: (rated 5 of 5) doctoral degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $81,920 $49,920 $133,030 Check out my state Local Salary Info Find Jobs 
Explore More Anthropology & Archeology Teachers, Postsecondary Atmospheric, Earth, Marine, & Space Sciences Teachers, Postsecondary Environmental Science Teachers, Postsecondary History Teachers, Postsecondary Sociology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Geological Technicians 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics chemistry Arts and Humanities English language 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic order or arrange things make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Analytical Thinking Independence Initiative 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software Geographic information system GIS software Presentation software Microsoft PowerPoint Analytical or scientific software IHS Petra Techsia Techlog 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $48,490 $34,740 $102,190 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Environmental Science & Protection Technicians, Including Health Geodetic Surveyors Remote Sensing Scientists & Technologists Surveying & Mapping Technicians You might like a career in one of these industries: Professional, Science, & Technical Mining, Oil, & Gas 
##############################################
Title: Geoscientists 
Information:
Knowledge Math and Science geography arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Engineering and Technology computers and electronics product and service development Education and Training teaching and course design 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information group things in different ways Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Integrity Attention to Detail Persistence Initiative Cooperation 
Technology You might use software like this on the job: Analytical or scientific software Gemcom Surpac The MathWorks MATLAB Presentation software Microsoft PowerPoint Map creation software Mapping software SACLANTCEN 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $87,480 $49,150 $173,620 Check out my state Local Salary Info Find Jobs 
Explore More Conservation Scientists Environmental Scientists & Specialists, Including Health Hydrologists Industrial Ecologists Soil & Plant Scientists You might like a career in one of these industries: Professional, Science, & Technical Government Mining, Oil, & Gas Management 
##############################################
Title: Geothermal Production Managers 
Information:
Knowledge Engineering and Technology mechanical product and service development Business management human resources (HR) Manufactured or Agricultural Goods manufacture and distribution of products Safety and Government public safety and security 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Leadership Analytical Thinking Attention to Detail Stress Tolerance 
Technology You might use software like this on the job: Data base user interface and query software Data logging software Microsoft Access Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook 
Education Education: (rated 3 of 5) some college or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $107,560 $67,720 $178,470 Check out my state Local Salary Info Find Jobs 
Explore More Biofuels Production Managers Biomass Power Plant Managers Hydroelectric Production Managers Power Plant Operators Wind Energy Operations Managers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Geothermal Technicians 
Information:
Knowledge Engineering and Technology mechanical design Arts and Humanities English language Math and Science physics chemistry Education and Training teaching and course design 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Ideas and Logic notice when problems happen use rules to solve problems Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns Attention pay attention to something without being distracted do two or more things at the same time Hand and Finger Use keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Analytical Thinking Integrity Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Analytical or scientific software ClimateMaster GeoDesigner WaterFurnace International Ground Loop Design PREMIER Geographic information system Geographic information system GIS systems 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $44,500 $30,270 $70,350 Check out my state Local Salary Info Find Jobs 
Explore More Biomass Plant Technicians Geothermal Production Managers Hydroelectric Plant Technicians Power Plant Operators Stationary Engineers & Boiler Operators You might like a career in one of these industries: Wholesale/Commercial Sales Retail Administration & Support Services Construction Manufacturing 
##############################################
Title: Glass Blowers, Molders, Benders, & Finishers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology design mechanical Business customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat change when and how fast you move based on how something else is moving Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Initiative Persistence Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Accounting software Billing software 
Education Education: (rated 2 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $39,590 $30,040 $56,470 Check out my state Local Salary Info Find Jobs 
Explore More Cutting & Slicing Machine Setters, Operators, & Tenders Extruding, Forming, Pressing, & Compacting Machine Setters, Operators, & Tenders Machine Feeders & Offbearers Molders, Shapers, & Casters Molding, Coremaking, & Casting Machine Setters, Operators, & Tenders, Metal & Plastic You might like a career in one of these industries: Manufacturing 
##############################################
Title: Glaziers 
Information:
Knowledge Engineering and Technology building and construction mechanical Business management customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Attention to Detail Dependability Analytical Thinking Initiative Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Project management software American Glazing Software AGS WindowPricer BidMaster Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $48,720 $34,580 $87,400 Check out my state Local Salary Info Find Jobs 
Explore More Automotive Glass Installers & Repairers Brickmasons & Blockmasons Carpenters Floor Layers Tile & Stone Setters You might like a career in one of these industries: Construction Retail 
##############################################
Title: Government Property Inspectors & Investigators 
Information:
Knowledge Arts and Humanities English language Business customer service administrative services Safety and Government public safety and security law and government Engineering and Technology building and construction 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Cooperation Dependability Adaptability/Flexibility Self Control Attention to Detail 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Presentation software Microsoft PowerPoint Inventory management software Plant Clearance Automated Reutilization Screening System PCARSS Radio frequency identification RFID software 
Education Education: (rated 3 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $71,690 $41,050 $118,060 Check out my state Local Salary Info Find Jobs 
Explore More Aviation Inspectors Compliance Managers Construction & Building Inspectors Environmental Compliance Inspectors Occupational Health & Safety Specialists You might like a career in one of these industries: Government Finance & Insurance 
##############################################
Title: Graders & Sorters, Agricultural Products 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Engineering and Technology mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Verbal listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Cooperation Initiative Self Control Achievement/Effort 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $32,550 $25,350 $40,330 Check out my state Local Salary Info Find Jobs 
Explore More Food & Tobacco Roasting, Baking, & Drying Machine Operators & Tenders Food Batchmakers Meat, Poultry, & Fish Cutters & Trimmers Packaging & Filling Machine Operators & Tenders Packers & Packagers, Hand You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting Manufacturing 
##############################################
Title: Graphic Designers 
Information:
Knowledge Engineering and Technology design computers and electronics Arts and Humanities music, dance, visual arts, drama, or sculpture English language Communications multimedia Business sales and marketing 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic create new and original ideas come up with lots of ideas 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Dependability Adaptability/Flexibility Cooperation Innovation Initiative 
Technology You might use software like this on the job: Web platform development software AJAX Oracle JavaServer Pages JSP Video creation and editing software Adobe Systems Adobe After Effects YouTube Computer aided design CAD software Autodesk AutoCAD Civil 3D Autodesk Revit 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $57,990 $35,430 $100,920 Check out my state Local Salary Info Find Jobs 
Explore More Art Directors Desktop Publishers Fine Artists, Including Painters, Sculptors, & Illustrators Special Effects Artists & Animators Web & Digital Interface Designers You might like a career in one of these industries: Professional, Science, & Technical Manufacturing Media & Communication 
##############################################
Title: Grinding & Polishing Workers, Hand 
Information:
Knowledge Engineering and Technology mechanical Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Independence Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Word processing software Microsoft Word 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $36,960 $28,760 $50,210 Check out my state Local Salary Info Find Jobs 
Explore More Crushing, Grinding, & Polishing Machine Setters, Operators, & Tenders Cutting & Slicing Machine Setters, Operators, & Tenders Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Tool Grinders, Filers, & Sharpeners Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing Administration & Support Services 
##############################################
Title: Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns Reaction Time and Speed quickly move your hand, finger, or foot based on a sound, light, picture or other command 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Cooperation Integrity Achievement/Effort 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Enterprise resource planning ERP software SAP software Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $38,910 $30,390 $58,430 Check out my state Local Salary Info Find Jobs 
Explore More Grinding & Polishing Workers, Hand Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Tool Grinders, Filers, & Sharpeners Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Hairdressers, Hairstylists, & Cosmetologists 
Information:
Knowledge Business customer service sales and marketing Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal communicate by speaking listen and understand what people say Ideas and Logic create new and original ideas come up with lots of ideas 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Self Control Cooperation Dependability Adaptability/Flexibility Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Video creation and editing software YouTube Accounting software Intuit QuickBooks 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $33,290 $21,800 $60,080 Check out my state Local Salary Info Find Jobs 
Explore More Barbers Makeup Artists, Theatrical & Performance Manicurists & Pedicurists Shampooers Skincare Specialists You might like a career in one of these industries: Service 
##############################################
Title: Hazardous Materials Removal Workers 
Information:
Knowledge Safety and Government public safety and security Business management customer service Transportation movement of people or goods by air, rail, sea, or road Engineering and Technology building and construction 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen group things in different ways Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Cooperation Attention to Detail Dependability Self Control Adaptability/Flexibility Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software Database software Xactware Xactimate 
Education Education: (rated 3 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,690 $32,910 $81,130 Check out my state Local Salary Info Find Jobs 
Explore More Highway Maintenance Workers Recycling & Reclamation Workers Refuse & Recyclable Material Collectors Septic Tank Servicers & Sewer Pipe Cleaners Water & Wastewater Treatment Plant & System Operators You might like a career in one of these industries: Administration & Support Services 
##############################################
Title: Health & Safety Engineers 
Information:
Knowledge Arts and Humanities English language Engineering and Technology product and service development mechanical Business customer service management Safety and Government public safety and security 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Math choose the right type of math to solve a problem 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Attention to Detail Dependability Cooperation Initiative Self Control 
Technology You might use software like this on the job: Analytical or scientific software Computational fluid dynamics CFD software The MathWorks MATLAB Presentation software Microsoft PowerPoint Compliance software Material safety data sheet MSDS software Safety, health, and environmental management software 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $100,660 $59,090 $152,550 Check out my state Local Salary Info Find Jobs 
Explore More Environmental Compliance Inspectors Environmental Scientists & Specialists, Including Health Occupational Health & Safety Specialists Occupational Health & Safety Technicians Security Management Specialists You might like a career in one of these industries: Manufacturing Professional, Science, & Technical Government Construction 
##############################################
Title: Health Education Specialists 
Information:
Knowledge Business customer service administrative services Education and Training teaching and course design Arts and Humanities English language Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions changing what is done based on other people's actions People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Cooperation Dependability Attention to Detail Concern for Others Independence 
Technology You might use software like this on the job: Data base user interface and query software Blackboard software Microsoft Access Web page creation and editing software Blogging software Facebook Graphics or photo imaging software Adobe Systems Adobe Photoshop JamBoard 
Education Education: (rated 4 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $59,990 $37,140 $106,210 Check out my state Local Salary Info Find Jobs 
Explore More Community Health Workers Health Informatics Specialists Healthcare Social Workers Nursing Instructors & Teachers, Postsecondary Rehabilitation Counselors You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Health Informatics Specialists 
Information:
Knowledge Business customer service management Engineering and Technology computers and electronics product and service development Education and Training teaching and course design Arts and Humanities English language 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Attention to Detail Cooperation Analytical Thinking Integrity Dependability Initiative 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software Epic Systems Presentation software Microsoft PowerPoint Object or component oriented development software Microsoft SQL Server Reporting Services SSRS Perl 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $102,240 $61,390 $161,980 Check out my state Local Salary Info Find Jobs 
Explore More Clinical Data Managers Health Education Specialists Health Information Technologists & Medical Registrars Nurse Practitioners Patient Representatives You might like a career in one of these industries: Professional, Science, & Technical Finance & Insurance Management 
##############################################
Title: Health Information Technologists & Medical Registrars 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software Epic Systems Desktop communications software Eko Data base user interface and query software Microsoft SQL Server Structured query language SQL 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $58,250 $34,970 $103,380 Check out my state Local Salary Info Find Jobs 
Explore More Clinical Data Managers Document Management Specialists Health Informatics Specialists Medical Records Specialists Patient Representatives You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Health Specialties Teachers, Postsecondary 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language Math and Science biology psychology Health medicine and dentistry 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Cooperation Leadership Concern for Others Dependability Initiative 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Adobe Systems Adobe Presenter Learning management system LMS Medical software InteractElsevier Netter's 3D Interactive Anatomy Medical procedure coding software 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $100,300 $48,670 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Biological Science Teachers, Postsecondary Clinical Nurse Specialists Health Education Specialists Nursing Instructors & Teachers, Postsecondary Recreation & Fitness Studies Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Healthcare Social Workers 
Information:
Knowledge Math and Science psychology sociology and anthropology Health therapy and counseling Arts and Humanities English language philosophy and religion Business customer service 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Integrity Self Control Dependability Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Office suite software Corel WordPerfect Office Suite Microsoft Office software Presentation software Information presentation software Microsoft PowerPoint Medical software Medical records software MEDITECH software 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $60,280 $38,520 $87,830 Check out my state Local Salary Info Find Jobs 
Explore More Child, Family, & School Social Workers Marriage & Family Therapists Mental Health & Substance Abuse Social Workers Mental Health Counselors Rehabilitation Counselors You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Hearing Aid Specialists 
Information:
Knowledge Business customer service sales and marketing Health therapy and counseling medicine and dentistry Engineering and Technology computers and electronics product and service development Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people teaching people how to do something Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use hold or move items with your hands keep your arm or hand steady Ideas and Logic notice when problems happen use rules to solve problems Hearing and Speech speak clearly 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Dependability Concern for Others Attention to Detail Stress Tolerance Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software HIMSA Noah Otometrics OTOsuite 
Education Education: (rated 3 of 5) doctoral degree or high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $59,020 $32,400 $91,740 Check out my state Local Salary Info Find Jobs 
Explore More Neurodiagnostic Technologists Occupational Therapy Aides Ophthalmic Medical Technicians Orthotists & Prosthetists Speech-Language Pathology Assistants You might like a career in one of these industries: Retail Health & Counseling 
##############################################
Title: Heat Treating Equipment Setters, Operators, & Tenders, Metal & Plastic 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Endurance exercise for a long time without getting out of breath Verbal communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Independence Attention to Detail Initiative Leadership 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $40,900 $31,250 $58,970 Check out my state Local Salary Info Find Jobs 
Explore More Furnace, Kiln, Oven, Drier, & Kettle Operators & Tenders Metal-Refining Furnace Operators & Tenders Molding, Coremaking, & Casting Machine Setters, Operators, & Tenders, Metal & Plastic Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders Welding, Soldering, & Brazing Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Heating, Air Conditioning, & Refrigeration Mechanics & Installers 
Information:
Knowledge Engineering and Technology mechanical building and construction Business customer service management Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Ideas and Logic notice when problems happen use rules to solve problems Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Innovation Integrity Independence Persistence 
Technology You might use software like this on the job: Electronic mail software IBM Notes Microsoft Outlook Presentation software Microsoft PowerPoint Facilities management software Computerized maintenance management system CMMS ManagerPlus 
Education Education: (rated 3 of 5) certificate after high school or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $51,390 $36,170 $82,630 Check out my state Local Salary Info Find Jobs 
Explore More Control & Valve Installers & Repairers Home Appliance Repairers Plumbers, Pipefitters, & Steamfitters Solar Thermal Installers & Technicians Stationary Engineers & Boiler Operators You might like a career in one of these industries: Construction 
##############################################
Title: Heavy & Tractor-Trailer Truck Drivers 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Safety and Government public safety and security law and government Business customer service Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement change when and how fast you move based on how something else is moving quickly change the controls of a machine, car, truck or boat Spatial know where things are around you imagine how something will look after it is moved around or changed Vision see details that are far away decide which thing is closer or farther away from you or decide how far away it is from you Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Stress Tolerance Cooperation Integrity 
Technology You might use software like this on the job: Word processing software 3M Post-it App Microsoft Word Data base user interface and query software ddlsoftware.com drivers daily log program DDL TruckersHelper Route navigation software ALK Technologies PC*Miler MarcoSoft Quo Vadis 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $49,920 $35,300 $75,220 Check out my state Local Salary Info Find Jobs 
Explore More Industrial Truck & Tractor Operators Light Truck Drivers Loading & Moving Machine Operators, Underground Mining Shuttle Drivers & Chauffeurs Tank Car, Truck, & Ship Loaders You might like a career in one of these industries: Transportation & Storage Wholesale/Commercial Sales 
##############################################
Title: Helpers--Brickmasons, Blockmasons, Stonemasons, & Tile & Marble Setters 
Information:
Knowledge Engineering and Technology building and construction mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Physical Strength use your lower back and stomach lift, push, pull, or carry Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Self Control Cooperation Initiative Persistence 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk Revit Computer aided design and drafting CADD software Presentation software Microsoft PowerPoint Project management software CPR Visual Estimator RISA Technologies RISAMasonry 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $39,430 $31,010 $62,590 Check out my state Local Salary Info Find Jobs 
Explore More Brickmasons & Blockmasons Helpers--Carpenters Helpers--Painters, Paperhangers, Plasterers, & Stucco Masons Helpers--Pipelayers, Plumbers, Pipefitters, & Steamfitters Terrazzo Workers & Finishers You might like a career in one of these industries: Construction 
##############################################
Title: Helpers--Carpenters 
Information:
Knowledge Engineering and Technology building and construction mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Ideas and Logic notice when problems happen group things in different ways 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Cooperation Initiative Attention to Detail Persistence Innovation Integrity 
Technology You might use software like this on the job: Accounting software Intuit QuickBooks Quicken Presentation software Microsoft PowerPoint Project management software Bosch Punch List Cost estimating software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $37,490 $28,370 $51,320 Check out my state Local Salary Info Find Jobs 
Explore More Helpers--Brickmasons, Blockmasons, Stonemasons, & Tile & Marble Setters Helpers--Electricians Helpers--Installation, Maintenance, & Repair Workers Helpers--Painters, Paperhangers, Plasterers, & Stucco Masons Helpers--Pipelayers, Plumbers, Pipefitters, & Steamfitters You might like a career in one of these industries: Construction 
##############################################
Title: Helpers--Electricians 
Information:
Knowledge Engineering and Technology building and construction mechanical Safety and Government public safety and security Business customer service management Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Achievement/Effort Integrity Cooperation Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Office suite software Microsoft Office software Word processing software Report generation software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $37,070 $28,940 $49,910 Check out my state Local Salary Info Find Jobs 
Explore More Electricians Helpers--Carpenters Helpers--Installation, Maintenance, & Repair Workers Helpers--Painters, Paperhangers, Plasterers, & Stucco Masons Helpers--Pipelayers, Plumbers, Pipefitters, & Steamfitters You might like a career in one of these industries: Construction 
##############################################
Title: Helpers--Extraction Workers 
Information:
Knowledge Engineering and Technology mechanical Arts and Humanities English language Transportation movement of people or goods by air, rail, sea, or road Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Endurance exercise for a long time without getting out of breath Spatial imagine how something will look after it is moved around or changed 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Initiative Attention to Detail Cooperation Achievement/Effort Adaptability/Flexibility 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $43,110 $31,050 $60,100 Check out my state Local Salary Info Find Jobs 
Explore More Earth Drillers Helpers--Installation, Maintenance, & Repair Workers Helpers--Pipelayers, Plumbers, Pipefitters, & Steamfitters Helpers--Production Workers Rotary Drill Operators, Oil & Gas You might like a career in one of these industries: Mining, Oil, & Gas Construction 
##############################################
Title: Helpers--Installation, Maintenance, & Repair Workers 
Information:
Knowledge Engineering and Technology mechanical building and construction Business customer service Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Adaptability/Flexibility Concern for Others Cooperation 
Technology You might use software like this on the job: Word processing software Atlas Construction Business Forms Microsoft Word Presentation software Microsoft PowerPoint Computer aided design CAD software HVAC tools software 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $35,100 $25,880 $49,970 Check out my state Local Salary Info Find Jobs 
Explore More Helpers--Carpenters Helpers--Electricians Helpers--Extraction Workers Helpers--Pipelayers, Plumbers, Pipefitters, & Steamfitters Helpers--Production Workers You might like a career in one of these industries: Construction Service 
##############################################
Title: Helpers--Painters, Paperhangers, Plasterers, & Stucco Masons 
Information:
Knowledge Arts and Humanities English language Engineering and Technology building and construction 
Skills Basic Skills talking to others Social changing what is done based on other people's actions 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Cooperation Attention to Detail Dependability Leadership Self Control Achievement/Effort 
Technology You might use software like this on the job: Office suite software Apple iWork Microsoft Office software Project management software Sage Construction Anywhere Turtle Creek Software Goldenseal Spreadsheet software Microsoft Excel 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $36,080 $27,210 $50,770 Check out my state Local Salary Info Find Jobs 
Explore More Helpers--Brickmasons, Blockmasons, Stonemasons, & Tile & Marble Setters Helpers--Carpenters Helpers--Installation, Maintenance, & Repair Workers Helpers--Pipelayers, Plumbers, Pipefitters, & Steamfitters Plasterers & Stucco Masons You might like a career in one of these industries: Construction 
##############################################
Title: Helpers--Pipelayers, Plumbers, Pipefitters, & Steamfitters 
Information:
Knowledge Engineering and Technology building and construction mechanical Business customer service management Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Cooperation Persistence Integrity Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Office suite software Microsoft Office software Word processing software Microsoft Word 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $36,340 $28,530 $49,250 Check out my state Local Salary Info Find Jobs 
Explore More Helpers--Carpenters Helpers--Electricians Helpers--Installation, Maintenance, & Repair Workers Helpers--Painters, Paperhangers, Plasterers, & Stucco Masons Plumbers, Pipefitters, & Steamfitters You might like a career in one of these industries: Construction 
##############################################
Title: Helpers--Production Workers 
Information:
Knowledge Engineering and Technology mechanical Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Self Control Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $34,670 $25,740 $47,680 Check out my state Local Salary Info Find Jobs 
Explore More Helpers--Brickmasons, Blockmasons, Stonemasons, & Tile & Marble Setters Helpers--Carpenters Helpers--Extraction Workers Helpers--Installation, Maintenance, & Repair Workers Machine Feeders & Offbearers You might like a career in one of these industries: Manufacturing Administration & Support Services 
##############################################
Title: Helpers--Roofers 
Information:
Knowledge Engineering and Technology building and construction mechanical Business management customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Adaptability/Flexibility Self Control Persistence Cooperation 
Technology You might use software like this on the job: Analytical or scientific software Energy cost evaluation software Roofing Calculator Data base user interface and query software Insight Direct ServiceCEO RoofLogic Computer aided design CAD software AppliCad Roof Wizard Ziatek RoofDraw 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $37,020 $29,550 $51,170 Check out my state Local Salary Info Find Jobs 
Explore More Helpers--Brickmasons, Blockmasons, Stonemasons, & Tile & Marble Setters Helpers--Carpenters Helpers--Painters, Paperhangers, Plasterers, & Stucco Masons Helpers--Pipelayers, Plumbers, Pipefitters, & Steamfitters Roofers You might like a career in one of these industries: Construction 
##############################################
Title: Highway Maintenance Workers 
Information:
Knowledge Safety and Government public safety and security Arts and Humanities English language Transportation movement of people or goods by air, rail, sea, or road Engineering and Technology building and construction 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use keep your arm or hand steady hold or move items with your hands Endurance exercise for a long time without getting out of breath Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Concern for Others Integrity Attention to Detail Cooperation Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Word processing software Microsoft Word 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $44,930 $29,940 $63,440 Check out my state Local Salary Info Find Jobs 
Explore More Operating Engineers & Other Construction Equipment Operators Paving, Surfacing, & Tamping Equipment Operators Pile Driver Operators Rail-Track Laying & Maintenance Equipment Operators Septic Tank Servicers & Sewer Pipe Cleaners You might like a career in one of these industries: Government 
##############################################
Title: Histology Technicians 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software Laboratory information system LIS MEDITECH software Label making software Brady Specimen Labeling System Specimen labeling system software 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,380 $35,220 $84,670 Check out my state Local Salary Info Find Jobs 
Explore More Cytogenetic Technologists Histotechnologists Medical & Clinical Laboratory Technicians Medical & Clinical Laboratory Technologists Phlebotomists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Historians 
Information:
Knowledge Arts and Humanities history and archeology English language Business customer service administrative services Education and Training teaching and course design Math and Science geography 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Cooperation Attention to Detail Concern for Others Dependability Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Structured query language SQL Presentation software Microsoft PowerPoint Information retrieval or search software Archival databases Smithsonian Institution digital archives 
Education Education: (rated 5 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $64,540 $37,280 $123,260 Check out my state Local Salary Info Find Jobs 
Explore More Anthropologists & Archeologists Archivists Curators Geographers Sociologists You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: History Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities history and archeology English language Education and Training teaching and course design Safety and Government law and government Math and Science geography 
Skills Basic Skills talking to others reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Analytical Thinking Attention to Detail Persistence Independence Achievement/Effort Concern for Others 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Moodle Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $79,400 $46,540 $134,420 Check out my state Local Salary Info Find Jobs 
Explore More Anthropology & Archeology Teachers, Postsecondary Area, Ethnic, & Cultural Studies Teachers, Postsecondary Philosophy & Religion Teachers, Postsecondary Political Science Teachers, Postsecondary Sociology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Histotechnologists 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software Laboratory information system LIS MEDITECH software Label making software Brady Specimen Labeling System Specimen labeling system software 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,380 $35,220 $84,670 Check out my state Local Salary Info Find Jobs 
Explore More Cytogenetic Technologists Cytotechnologists Histology Technicians Medical & Clinical Laboratory Technicians Medical & Clinical Laboratory Technologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Hoist & Winch Operators 
Information:
Knowledge Engineering and Technology mechanical Business customer service 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hearing and Speech recognize spoken words speak clearly Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Independence Initiative Dependability Attention to Detail Cooperation Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Word processing software Microsoft Word 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $58,950 $33,480 $121,450 Check out my state Local Salary Info Find Jobs 
Explore More Crane & Tower Operators Industrial Truck & Tractor Operators Mobile Heavy Equipment Mechanics Operating Engineers & Other Construction Equipment Operators Riggers You might like a career in one of these industries: Transportation & Storage Manufacturing Construction 
##############################################
Title: Home Appliance Repairers 
Information:
Knowledge Business customer service administrative services Engineering and Technology mechanical computers and electronics Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Attention to Detail Dependability Cooperation Analytical Thinking Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software dESCO ESC RazorSync Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,000 $29,190 $67,300 Check out my state Local Salary Info Find Jobs 
Explore More Control & Valve Installers & Repairers Electric Motor, Power Tool, & Related Repairers Heating, Air Conditioning, & Refrigeration Mechanics & Installers Industrial Machinery Mechanics Outdoor Power Equipment & Other Small Engine Mechanics You might like a career in one of these industries: Service Retail 
##############################################
Title: Home Health Aides 
Information:
Knowledge Business customer service Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention do two or more things at the same time pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Dependability Self Control Concern for Others Attention to Detail Cooperation 
Technology You might use software like this on the job: Operating system software Linux UNIX Video conferencing software FaceTime Electronic mail software Microsoft Exchange Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $30,180 $22,500 $38,350 Check out my state Local Salary Info Find Jobs 
Explore More Licensed Practical & Licensed Vocational Nurses Nursing Assistants Occupational Therapy Aides Personal Care Aides Psychiatric Aides You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Hospitalists 
Information:
Knowledge Health medicine and dentistry therapy and counseling Math and Science biology psychology Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills figuring out how to use new ideas or things listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Visual Understanding see hidden patterns quickly know what you are looking at Attention do two or more things at the same time 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Dependability Attention to Detail Concern for Others Stress Tolerance Self Control 
Technology You might use software like this on the job: Medical software Epic Systems MEDITECH software Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $223,410 $63,970 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Clinical Nurse Specialists Emergency Medicine Physicians Family Medicine Physicians General Internal Medicine Physicians Nurse Practitioners You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Hosts & Hostesses, Restaurant, Lounge, & Coffee Shop 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language Manufactured or Agricultural Goods food production Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Attention do two or more things at the same time 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Cooperation Stress Tolerance Self Control Dependability Social Orientation Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Point of sale POS software Hospitality Control Solutions Aloha Point-of-Sale Data base user interface and query software Avenista Table Reservations Reservation software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $27,720 $20,500 $36,180 Check out my state Local Salary Info Find Jobs 
Explore More Dining Room & Cafeteria Attendants & Bartender Helpers Fast Food & Counter Workers Food Servers, Nonrestaurant Locker Room, Coatroom, & Dressing Room Attendants Waiters & Waitresses You might like a career in one of these industries: Hotel & Food 
##############################################
Title: Hotel, Motel, & Resort Desk Clerks 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Safety and Government public safety and security Engineering and Technology computers and electronics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen group things in different ways 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Concern for Others Dependability Integrity Self Control Stress Tolerance 
Technology You might use software like this on the job: Data base user interface and query software Incident tracking software Yardi software Instant messaging software Blink Facilities management software ASI FrontDesk Resort Data Processing 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $28,910 $22,280 $37,860 Check out my state Local Salary Info Find Jobs 
Explore More Concierges Counter & Rental Clerks Locker Room, Coatroom, & Dressing Room Attendants Lodging Managers Receptionists & Information Clerks You might like a career in one of these industries: Hotel & Food 
##############################################
Title: Human Factors Engineers & Ergonomists 
Information:
Knowledge Engineering and Technology product and service development design Math and Science psychology arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Dependability Analytical Thinking Integrity Attention to Detail Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software Microsoft PowerPoint Web platform development software AJAX JavaScript Object Notation JSON 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $96,350 $62,730 $134,990 Check out my state Local Salary Info Find Jobs 
Explore More Bioengineers & Biomedical Engineers Data Scientists Health & Safety Engineers Industrial Engineers Validation Engineers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Human Resources Assistants 
Information:
Knowledge Business human resources (HR) administrative services Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Cooperation Dependability Integrity Concern for Others Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Word processing software Google Docs Microsoft Word Human resources software Human resource management software HRMS Workscape HR Service Center 
Education Education: (rated 3 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $45,930 $32,240 $62,580 Check out my state Local Salary Info Find Jobs 
Explore More Compensation, Benefits, & Job Analysis Specialists Eligibility Interviewers, Government Programs Human Resources Managers Human Resources Specialists Payroll & Timekeeping Clerks You might like a career in one of these industries: Government Health & Counseling Administration & Support Services 
##############################################
Title: Human Resources Managers 
Information:
Knowledge Business human resources (HR) management Arts and Humanities English language Safety and Government law and government Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Self Control Leadership Dependability Cooperation Initiative 
Technology You might use software like this on the job: Time accounting software ADP ezLaborManager Kronos Workforce Timekeeper Presentation software Mentimeter Microsoft PowerPoint Human resources software Human resource management software HRMS UniFocus Watson Human Resources Manager 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $130,000 $76,610 $224,360 Check out my state Local Salary Info Find Jobs 
Explore More Human Resources Assistants Human Resources Specialists Labor Relations Specialists Management Analysts Training & Development Managers You might like a career in one of these industries: Professional, Science, & Technical Management Manufacturing 
##############################################
Title: Human Resources Specialists 
Information:
Knowledge Business human resources (HR) administrative services Arts and Humanities English language Safety and Government law and government Education and Training teaching and course design 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Cooperation Attention to Detail Self Control Dependability Adaptability/Flexibility 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics Workday software Data base user interface and query software Blackboard software LinkedIn Human resources software Oracle Taleo TempWorks recruiting and staffing software 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $64,240 $39,340 $116,060 Check out my state Local Salary Info Find Jobs 
Explore More Compensation, Benefits, & Job Analysis Specialists Eligibility Interviewers, Government Programs First-Line Supervisors of Office & Administrative Support Workers Human Resources Assistants Human Resources Managers You might like a career in one of these industries: Administration & Support Services Professional, Science, & Technical Government Health & Counseling 
##############################################
Title: Hydroelectric Plant Technicians 
Information:
Knowledge Engineering and Technology mechanical product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Safety and Government public safety and security Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Verbal listen and understand what people say communicate by speaking Hand and Finger Use hold or move items with your hands put together small parts with your fingers Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Integrity Analytical Thinking Independence Self Control 
Technology You might use software like this on the job: Electronic mail software IBM Lotus Notes Microsoft Outlook Industrial control software Distributed control system DCS Supervisory control and data acquisition SCADA software Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $93,060 $52,000 $119,880 Check out my state Local Salary Info Find Jobs 
Explore More Biomass Plant Technicians Geothermal Technicians Hydroelectric Production Managers Power Plant Operators Wind Turbine Service Technicians You might like a career in one of these industries: Utilities Government 
##############################################
Title: Hydroelectric Production Managers 
Information:
Knowledge Engineering and Technology mechanical product and service development Safety and Government public safety and security law and government Business management human resources (HR) Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Math add, subtract, multiply, or divide choose the right type of math to solve a problem Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Leadership Attention to Detail Integrity Analytical Thinking Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Oracle Database Presentation software Microsoft PowerPoint Industrial control software Distributed control system DCS Supervisory control and data acquisition SCADA software 
Education Education: (rated 3 of 5) certificate after high school or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $107,560 $67,720 $178,470 Check out my state Local Salary Info Find Jobs 
Explore More Biofuels Production Managers Biomass Power Plant Managers Geothermal Production Managers Wind Energy Development Managers Wind Energy Operations Managers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Hydrologic Technicians 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software Geographic information system GIS software Presentation software Microsoft PowerPoint Analytical or scientific software Datasurge GEOPRO Salix Applied Earthcare Erosion Draw 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $58,360 $36,880 $108,990 Check out my state Local Salary Info Find Jobs 
Explore More Conservation Scientists Environmental Restoration Planners Environmental Scientists & Specialists, Including Health Hydrologists Water/Wastewater Engineers You might like a career in one of these industries: Government Mining, Oil, & Gas Manufacturing 
##############################################
Title: Hydrologists 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Engineering and Technology product and service development computers and electronics Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Analytical Thinking Attention to Detail Dependability Initiative Achievement/Effort 
Technology You might use software like this on the job: Analytical or scientific software Data visualization software The MathWorks MATLAB Presentation software EnviroInsite Microsoft PowerPoint Data base user interface and query software Microsoft Access Structure query language SQL 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $85,990 $54,590 $150,490 Check out my state Local Salary Info Find Jobs 
Explore More Conservation Scientists Environmental Scientists & Specialists, Including Health Geoscientists Industrial Ecologists Water Resource Specialists You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Industrial Ecologists 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics chemistry Engineering and Technology product and service development computers and electronics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal communicate by writing communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Integrity Initiative Persistence Achievement/Effort 
Technology You might use software like this on the job: Analytical or scientific software StataCorp Stata Substance Flow Analysis STAN Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access Oracle Database 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $76,480 $46,920 $130,770 Check out my state Local Salary Info Find Jobs 
Explore More Conservation Scientists Environmental Restoration Planners Environmental Scientists & Specialists, Including Health Soil & Plant Scientists Water Resource Specialists You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Industrial Engineering Technologists & Technicians 
Information:
Knowledge Engineering and Technology mechanical product and service development Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math add, subtract, multiply, or divide choose the right type of math to solve a problem Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Dependability Analytical Thinking Stress Tolerance Cooperation Integrity 
Technology You might use software like this on the job: Computer aided design CAD software Bentley MicroStation Dassault Systemes SolidWorks Desktop communications software Eko Industrial control software Supervisory control and data acquisition SCADA software VIA Information Tools MAN-IT 
Education Education: (rated 3 of 5) some college or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $61,210 $40,710 $90,430 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Electrical & Electronic Engineering Technologists & Technicians Industrial Engineers Manufacturing Engineers Mechanical Engineering Technologists & Technicians You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Industrial Engineers 
Information:
Knowledge Engineering and Technology product and service development mechanical Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math add, subtract, multiply, or divide choose the right type of math to solve a problem Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Dependability Integrity Adaptability/Flexibility Analytical Thinking Cooperation 
Technology You might use software like this on the job: Analytical or scientific software Minitab The MathWorks MATLAB Presentation software Microsoft PowerPoint Computer aided design CAD software Bentley MicroStation Dassault Systemes SolidWorks 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $96,350 $62,730 $134,990 Check out my state Local Salary Info Find Jobs 
Explore More Electrical Engineers Industrial Production Managers Manufacturing Engineers Mechanical Engineers Validation Engineers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Industrial Machinery Mechanics 
Information:
Knowledge Engineering and Technology mechanical product and service development Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem figuring out how to use new ideas or things Technical figuring out what is causing equipment, machines, wiring, or computer programs to not work planning and doing the basic maintenance on equipment Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Ideas and Logic notice when problems happen order or arrange things Hearing and Speech tell the difference between sounds 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Initiative Adaptability/Flexibility Cooperation Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Industrial control software KEYENCE PLC Ladder Logic Supervisory control and data acquisition SCADA software 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $59,830 $38,900 $82,810 Check out my state Local Salary Info Find Jobs 
Explore More Electric Motor, Power Tool, & Related Repairers Maintenance Workers, Machinery Millwrights Mobile Heavy Equipment Mechanics Multiple Machine Tool Setters, Operators, & Tenders, Metal & Plastic You might like a career in one of these industries: Manufacturing Wholesale/Commercial Sales Service 
##############################################
Title: Industrial Production Managers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Business management customer service Education and Training teaching and course design Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Math add, subtract, multiply, or divide choose the right type of math to solve a problem Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Integrity Leadership Dependability Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Enterprise resource planning ERP software Oracle PeopleSoft SAP software Video creation and editing software Adobe Systems Adobe After Effects YouTube Industrial control software Schneider Electric CitectSCADA Supervisory control and data acquisition SCADA software 
Education Education: (rated 4 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $107,560 $67,720 $178,470 Check out my state Local Salary Info Find Jobs 
Explore More Biofuels Production Managers Biomass Power Plant Managers General & Operations Managers Quality Control Systems Managers Supply Chain Managers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Industrial Truck & Tractor Operators 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions People and Technology Systems thinking about the pros and cons of different options and picking the best one 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use hold or move items with your hands keep your arm or hand steady Spatial imagine how something will look after it is moved around or changed know where things are around you Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Self Control Attention to Detail Dependability Stress Tolerance Integrity Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Inventory management software Inventory management systems RedPrairie DLx Warehouse Materials requirements planning logistics and supply chain software Symphony GOLD Warehouse management system WMS 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $41,230 $32,040 $57,410 Check out my state Local Salary Info Find Jobs 
Explore More Crane & Tower Operators Hoist & Winch Operators Laborers & Freight, Stock, & Material Movers, Hand Loading & Moving Machine Operators, Underground Mining Tank Car, Truck, & Ship Loaders You might like a career in one of these industries: Transportation & Storage Manufacturing Administration & Support Services Wholesale/Commercial Sales 
##############################################
Title: Industrial-Organizational Psychologists 
Information:
Knowledge Business human resources (HR) management Math and Science psychology arithmetic, algebra, geometry, calculus, or statistics Education and Training teaching and course design Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Integrity Initiative Dependability Achievement/Effort Attention to Detail 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics SAS Computer based training software Learning management system LMS Padlet Spreadsheet software Google Sheets Microsoft Excel 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $139,280 $72,490 $210,030 Check out my state Local Salary Info Find Jobs 
Explore More Human Resources Managers Human Resources Specialists Management Analysts Training & Development Managers Training & Development Specialists You might like a career in one of these industries: Education 
##############################################
Title: Information Security Analysts 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Arts and Humanities English language Business management customer service Communications telecommunications 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Integrity Analytical Thinking Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Web platform development software Google Angular Spring Framework Data base user interface and query software Amazon Elastic Compute Cloud EC2 Blackboard software Transaction security and virus protection software NortonLifeLock cybersecurity software Stack smashing protection SSP software 
Education Education: (rated 4 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $112,000 $66,010 $174,540 Check out my state Local Salary Info Find Jobs 
Explore More Computer Network Support Specialists Computer Systems Analysts Information Security Engineers Network & Computer Systems Administrators Penetration Testers You might like a career in one of these industries: Professional, Science, & Technical Finance & Insurance Media & Communication 
##############################################
Title: Information Security Engineers 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Operating system software Bash UNIX Project management software Microsoft SharePoint Microsoft Teams Development environment software Microsoft Azure software Microsoft PowerShell 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,740 $46,540 $166,780 Check out my state Local Salary Info Find Jobs 
Explore More Computer Systems Analysts Computer Systems Engineers/Architects Information Security Analysts Network & Computer Systems Administrators Penetration Testers You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Information Technology Project Managers 
Information:
Knowledge Arts and Humanities English language Business customer service management Engineering and Technology computers and electronics product and service development Communications telecommunications 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one Resource Management managing your time and the time of other people selecting and managing the best workers for a job 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Leadership Integrity Initiative Stress Tolerance Adaptability/Flexibility Dependability 
Technology You might use software like this on the job: Project management software Atlassian JIRA Microsoft Teams Development environment software Microsoft Team Foundation Server Oracle Java 2 Platform Enterprise Edition J2EE Web platform development software React Spring Framework 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,740 $46,540 $166,780 Check out my state Local Salary Info Find Jobs 
Explore More Computer & Information Systems Managers Computer Systems Engineers/Architects Management Analysts Project Management Specialists Software Developers You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Inspectors, Testers, Sorters, Samplers, & Weighers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Business customer service Engineering and Technology mechanical 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem writing things for co-workers or customers Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Self Control Cooperation Independence Stress Tolerance 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks Desktop communications software Skype Analytical or scientific software Minitab The MathWorks MATLAB 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $43,900 $29,950 $69,100 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Calibration Technologists & Technicians Electrical & Electronic Equipment Assemblers Electromechanical Equipment Assemblers Industrial Machinery Mechanics You might like a career in one of these industries: Manufacturing 
##############################################
Title: Instructional Coordinators 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language philosophy and religion Business management customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills using the best training or teaching strategies for learning new things talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something changing what is done based on other people's actions 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems Memory remember words, numbers, pictures, or steps Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Initiative Leadership Dependability Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Video creation and editing software Adobe Systems Adobe After Effects Flipgrid Computer based training software Moodle Schoology Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Illustrator 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $66,490 $42,000 $105,210 Check out my state Local Salary Info Find Jobs 
Explore More Career/Technical Education Teachers, Middle School Education Teachers, Postsecondary Special Education Teachers, Elementary School Training & Development Managers Training & Development Specialists You might like a career in one of these industries: Education 
##############################################
Title: Insulation Workers, Floor, Ceiling, & Wall 
Information:
Knowledge Engineering and Technology building and construction mechanical Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Independence Integrity Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software CMSN FieldPAK Comput-Ability Mechanical Insulation Key Estimator Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $45,380 $29,830 $76,960 Check out my state Local Salary Info Find Jobs 
Explore More Drywall & Ceiling Tile Installers Floor Layers Insulation Workers, Mechanical Roofers Sheet Metal Workers You might like a career in one of these industries: Construction 
##############################################
Title: Insulation Workers, Mechanical 
Information:
Knowledge Engineering and Technology building and construction mechanical Business customer service management Education and Training teaching and course design Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Cooperation Integrity Dependability Self Control Initiative 
Technology You might use software like this on the job: Data base user interface and query software CMSN FieldPAK Comput-Ability Mechanical Insulation Key Estimator Enterprise resource planning ERP software IBM Maximo Asset Management Analytical or scientific software North American Insulation Manufacturers Association NAIMA 3E Plus 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $50,590 $37,460 $93,710 Check out my state Local Salary Info Find Jobs 
Explore More Drywall & Ceiling Tile Installers Floor Layers Insulation Workers, Floor, Ceiling, & Wall Pipelayers Sheet Metal Workers You might like a career in one of these industries: Construction 
##############################################
Title: Insurance Appraisers, Auto Damage 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Engineering and Technology computers and electronics mechanical 
Skills Basic Skills talking to others writing things for co-workers or customers Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Initiative Self Control Concern for Others Dependability 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Project management software Cost estimating software Web-Est estimating software 
Education Education: (rated 3 of 5) some college or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $69,380 $49,940 $94,440 Check out my state Local Salary Info Find Jobs 
Explore More Appraisers & Assessors of Real Estate Appraisers of Personal & Business Property Automotive Body & Related Repairers Cost Estimators Transportation Vehicle, Equipment & Systems Inspectors You might like a career in one of these industries: Finance & Insurance 
##############################################
Title: Insurance Claims & Policy Processing Clerks 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic order or arrange things use rules to solve problems 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Self Control Concern for Others 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Instant messaging software GroupMe Medical software Medical condition coding software Medical procedure coding software 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,080 $33,710 $64,350 Check out my state Local Salary Info Find Jobs 
Explore More Billing & Posting Clerks Bookkeeping, Accounting, & Auditing Clerks Credit Authorizers, Checkers, & Clerks Customer Service Representatives Eligibility Interviewers, Government Programs You might like a career in one of these industries: Finance & Insurance 
##############################################
Title: Insurance Sales Agents 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government law and government 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Social talking people into changing their minds or their behavior looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Independence Attention to Detail Achievement/Effort Dependability Initiative 
Technology You might use software like this on the job: Enterprise resource planning ERP software AMS Services AMS Sagitta Microsoft Dynamics Video creation and editing software Adobe Systems Adobe After Effects YouTube Customer relationship management CRM software Applied Systems Vision Tangle S Creations Your Insurance Office 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,860 $31,530 $130,350 Check out my state Local Salary Info Find Jobs 
Explore More Insurance Claims & Policy Processing Clerks Loan Officers Personal Financial Advisors Sales Representatives of Services Securities, Commodities, & Financial Services Sales Agents You might like a career in one of these industries: Finance & Insurance 
##############################################
Title: Insurance Underwriters 
Information:
Knowledge Arts and Humanities English language Business customer service sales and marketing Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one 
Abilities Verbal read and understand what is written communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Analytical Thinking Dependability Achievement/Effort Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Presentation software Microsoft PowerPoint Financial analysis software Delphi Technology Valen Technologies Risk Manager 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $76,230 $47,340 $130,210 Check out my state Local Salary Info Find Jobs 
Explore More Credit Analysts Financial Risk Specialists Insurance Sales Agents Loan Officers Personal Financial Advisors You might like a career in one of these industries: Finance & Insurance 
##############################################
Title: Intelligence Analysts 
Information:
Knowledge Arts and Humanities English language Safety and Government law and government public safety and security Engineering and Technology computers and electronics Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social bringing people together to solve differences changing what is done based on other people's actions 
Abilities Verbal read and understand what is written communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Integrity Initiative Dependability Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Amazon Web Services AWS software Microsoft SQL Server Presentation software Microsoft PowerPoint Geographic information system ESRI ArcGIS software Geographic information system GIS software 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $86,280 $47,990 $150,570 Check out my state Local Salary Info Find Jobs 
Explore More Business Intelligence Analysts Data Scientists Detectives & Criminal Investigators Information Security Engineers Private Detectives & Investigators You might like a career in one of these industries: Government 
##############################################
Title: Interior Designers 
Information:
Knowledge Engineering and Technology design building and construction Business customer service management Arts and Humanities English language music, dance, visual arts, drama, or sculpture Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic create new and original ideas come up with lots of ideas Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Innovation Dependability Stress Tolerance Cooperation Initiative 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk Revit Trimble SketchUp Pro Presentation software Microsoft PowerPoint Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Illustrator 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $61,590 $36,610 $101,550 Check out my state Local Salary Info Find Jobs 
Explore More Commercial & Industrial Designers Craft Artists Fashion Designers Landscape Architects Set & Exhibit Designers You might like a career in one of these industries: Professional, Science, & Technical Retail 
##############################################
Title: Interpreters & Translators 
Information:
Knowledge Arts and Humanities English language foreign language Business customer service administrative services Education and Training teaching and course design Safety and Government public safety and security 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things notice when problems happen Attention pay attention to something without being distracted do two or more things at the same time 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Integrity Attention to Detail Dependability Independence Cooperation Self Control 
Technology You might use software like this on the job: Web platform development software Extensible hypertext markup language XHTML Hypertext markup language HTML Presentation software Microsoft PowerPoint Foreign language software AceTools.biz Ace Translator Stormdance CatsCradle 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $53,640 $33,540 $93,140 Check out my state Local Salary Info Find Jobs 
Explore More Adult Basic Education, Adult Secondary Education, & English as a Second Language Instructors English Language & Literature Teachers, Postsecondary Foreign Language & Literature Teachers, Postsecondary Speech-Language Pathologists Speech-Language Pathology Assistants You might like a career in one of these industries: Professional, Science, & Technical Education Health & Counseling 
##############################################
Title: Interviewers 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Engineering and Technology computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Adaptability/Flexibility Stress Tolerance Integrity Self Control Dependability 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Project management software Qualtrics Insight SaaS SurveyMonkey 
Education Education: (rated 3 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $38,700 $29,520 $55,620 Check out my state Local Salary Info Find Jobs 
Explore More Eligibility Interviewers, Government Programs Human Resources Assistants Office Clerks, General Patient Representatives Receptionists & Information Clerks You might like a career in one of these industries: Health & Counseling Professional, Science, & Technical 
##############################################
Title: Investment Fund Managers 
Information:
Knowledge Business accounting and economics customer service Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Analytical Thinking Stress Tolerance Achievement/Effort Dependability Persistence 
Technology You might use software like this on the job: Analytical or scientific software SAS Statistical analysis software Presentation software Microsoft PowerPoint Financial analysis software Oracle Hyperion Planning SunGard Financial Systems AddVantage 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $139,790 $79,050 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Financial & Investment Analysts Financial Managers Financial Risk Specialists Personal Financial Advisors Securities, Commodities, & Financial Services Sales Agents You might like a career in one of these industries: Finance & Insurance Professional, Science, & Technical Management 
##############################################
Title: Janitors & Cleaners 
Information:
Knowledge Safety and Government public safety and security Business management 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social changing what is done based on other people's actions 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Independence Self Control Attention to Detail Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Desktop communications software Eko Electronic mail software Microsoft Outlook 
Education Education: (rated 3 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $31,990 $23,160 $46,400 Check out my state Local Salary Info Find Jobs 
Explore More Cleaners of Vehicles & Equipment Dishwashers Laundry & Dry-Cleaning Workers Maids & Housekeeping Cleaners Septic Tank Servicers & Sewer Pipe Cleaners You might like a career in one of these industries: Administration & Support Services Education 
##############################################
Title: Jewelers & Precious Stone & Metal Workers 
Information:
Knowledge Business customer service Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology design mechanical 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Ideas and Logic notice when problems happen group things in different ways 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Analytical Thinking Innovation Initiative 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop Computer aided design CAD software Computer assisted jewelry design CAD software Metal designing software Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,140 $30,150 $76,130 Check out my state Local Salary Info Find Jobs 
Explore More Etchers & Engravers Gem & Diamond Workers Grinding & Polishing Workers, Hand Molders, Shapers, & Casters Shoe & Leather Workers & Repairers You might like a career in one of these industries: Retail Manufacturing 
##############################################
Title: Judges, Magistrate Judges, & Magistrates 
Information:
Knowledge Safety and Government law and government public safety and security Arts and Humanities English language Business management customer service Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Stress Tolerance Attention to Detail Dependability Self Control Analytical Thinking 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Document management software Adobe Systems Adobe Acrobat Hyland OnBase Enterprise Content Management 
Education Education: (rated 5 of 5) doctoral degree or professional degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $151,030 $46,470 $227,850 Check out my state Local Salary Info Find Jobs 
Explore More Administrative Law Judges, Adjudicators, & Hearing Officers Arbitrators, Mediators, & Conciliators Judicial Law Clerks Lawyers Paralegals & Legal Assistants You might like a career in one of these industries: Government 
##############################################
Title: Judicial Law Clerks 
Information:
Knowledge Safety and Government law and government Arts and Humanities English language Business administrative services 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Analytical Thinking Dependability Independence Achievement/Effort 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Orion Law Management Systems Orion Presentation software Microsoft PowerPoint Project management software Canyon Solutions Jcats Thomson Reuters Elite ProLaw 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $57,490 $38,850 $106,670 Check out my state Local Salary Info Find Jobs 
Explore More Administrative Law Judges, Adjudicators, & Hearing Officers Judges, Magistrate Judges, & Magistrates Lawyers Legal Secretaries & Administrative Assistants Paralegals & Legal Assistants You might like a career in one of these industries: Government 
##############################################
Title: Kindergarten Teachers 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language Math and Science psychology arithmetic, algebra, geometry, calculus, or statistics Business customer service 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social teaching people how to do something understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic notice when problems happen come up with lots of ideas Attention pay attention to something without being distracted do two or more things at the same time 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Integrity Adaptability/Flexibility Concern for Others Cooperation Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Computer based training software Children's educational software Padlet Electronic mail software Microsoft Outlook 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $60,490 $40,620 $100,020 Check out my state Local Salary Info Find Jobs 
Explore More Elementary School Teachers Middle School Teachers Preschool Teachers Special Education Teachers, Elementary School Special Education Teachers, Kindergarten You might like a career in one of these industries: Education 
##############################################
Title: Labor Relations Specialists 
Information:
Knowledge Business human resources (HR) management Arts and Humanities English language Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social bringing people together to solve differences talking people into changing their minds or their behavior 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Attention to Detail Cooperation Initiative Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Human resources software Internet Grievance System IGS Micropact entellitrak Labor Relations Edition 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $82,010 $38,900 $131,660 Check out my state Local Salary Info Find Jobs 
Explore More Arbitrators, Mediators, & Conciliators Compensation, Benefits, & Job Analysis Specialists Equal Opportunity Representatives & Officers Human Resources Managers Human Resources Specialists You might like a career in one of these industries: Service 
##############################################
Title: Laborers & Freight, Stock, & Material Movers, Hand 
Information:
Knowledge Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Endurance exercise for a long time without getting out of breath Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Physical Strength lift, push, pull, or carry use your lower back and stomach 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Stress Tolerance Achievement/Effort Integrity Attention to Detail Cooperation 
Technology You might use software like this on the job: Electronic mail software IBM Notes Microsoft Outlook Word processing software Google Docs Microsoft Word Data base user interface and query software Data entry software Oracle Database 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $36,110 $27,610 $48,560 Check out my state Local Salary Info Find Jobs 
Explore More Conveyor Operators & Tenders Industrial Truck & Tractor Operators Machine Feeders & Offbearers Packers & Packagers, Hand Tank Car, Truck, & Ship Loaders You might like a career in one of these industries: Transportation & Storage Administration & Support Services Manufacturing Wholesale/Commercial Sales 
##############################################
Title: Landscape Architects 
Information:
Knowledge Engineering and Technology design building and construction Safety and Government public safety and security law and government Arts and Humanities English language music, dance, visual arts, drama, or sculpture Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic create new and original ideas group things in different ways Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Dependability Integrity Adaptability/Flexibility Cooperation Initiative 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Civil 3D Trimble SketchUp Pro Presentation software Microsoft PowerPoint Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Illustrator 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $73,210 $42,870 $121,660 Check out my state Local Salary Info Find Jobs 
Explore More Architects Civil Engineers Conservation Scientists Environmental Restoration Planners Sustainability Specialists You might like a career in one of these industries: Professional, Science, & Technical Administration & Support Services 
##############################################
Title: Landscaping & Groundskeeping Workers 
Information:
Knowledge Arts and Humanities English language Business customer service Math and Science chemistry 
Skills Basic Skills talking to others Social changing what is done based on other people's actions 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Cooperation Self Control Dependability Achievement/Effort 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software IBM Notes Office suite software Microsoft Office software 
Education Education: (rated 1 of 5) no high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $35,890 $27,550 $48,530 Check out my state Local Salary Info Find Jobs 
Explore More Farmworkers & Laborers, Crop, Nursery, & Greenhouse First-Line Supervisors of Landscaping, Lawn Service, & Groundskeeping Workers Janitors & Cleaners Pesticide Handlers, Sprayers, & Applicators, Vegetation Tree Trimmers & Pruners You might like a career in one of these industries: Administration & Support Services 
##############################################
Title: Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology mechanical Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Controlled Movement quickly change the controls of a machine, car, truck or boat change when and how fast you move based on how something else is moving 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Initiative Stress Tolerance Concern for Others 
Technology You might use software like this on the job: Object or component oriented development software G-code M-code Industrial control software Autodesk HSMWorks Computer numerical control CNC editor software Inventory management software Inventory tracking software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,020 $32,580 $64,450 Check out my state Local Salary Info Find Jobs 
Explore More Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Multiple Machine Tool Setters, Operators, & Tenders, Metal & Plastic Tool Grinders, Filers, & Sharpeners Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Laundry & Dry-Cleaning Workers 
Information:
Knowledge Business customer service Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social understanding people's reactions 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Independence Attention to Detail Integrity Dependability Cooperation Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Email software Point of sale POS software Cents Wash-Dry-Fold POS 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $29,060 $22,060 $37,010 Check out my state Local Salary Info Find Jobs 
Explore More Cleaners of Vehicles & Equipment Cleaning, Washing, & Metal Pickling Equipment Operators & Tenders Pressers, Textile, Garment, & Related Materials Sewing Machine Operators Textile Bleaching & Dyeing Machine Operators & Tenders You might like a career in one of these industries: Service Health & Counseling Hotel & Food 
##############################################
Title: Law Teachers, Postsecondary 
Information:
Knowledge Safety and Government law and government Arts and Humanities English language Education and Training teaching and course design Engineering and Technology computers and electronics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Analytical Thinking Attention to Detail Initiative Achievement/Effort Dependability 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Piazza Information retrieval or search software LexisNexis Thomson Reuters Westlaw 
Education Education: (rated 5 of 5) professional degree or doctoral degree usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $108,860 $43,400 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Business Teachers, Postsecondary Criminal Justice & Law Enforcement Teachers, Postsecondary Economics Teachers, Postsecondary Political Science Teachers, Postsecondary Sociology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Lawyers 
Information:
Knowledge Safety and Government law and government Arts and Humanities English language Business customer service management Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Analytical Thinking Dependability Integrity Attention to Detail Stress Tolerance Initiative 
Technology You might use software like this on the job: Data base user interface and query software LexisNexis CaseMap Microsoft Access Presentation software IDEA TrialPro Microsoft PowerPoint Document management software Adobe Systems Adobe Acrobat Microsoft Office SharePoint Server MOSS 
Education Education: (rated 5 of 5) professional degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $135,740 $66,470 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Administrative Law Judges, Adjudicators, & Hearing Officers Arbitrators, Mediators, & Conciliators Claims Adjusters, Examiners, & Investigators Judges, Magistrate Judges, & Magistrates Judicial Law Clerks You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Layout Workers, Metal & Plastic 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology design mechanical Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills using math to solve problems keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal read and understand what is written 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Achievement/Effort Cooperation Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Procedure management software Hexagon Metrology PC-DMIS Optical Gaging Products Measure-X 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $58,260 $39,320 $69,180 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Model Makers, Metal & Plastic Patternmakers, Metal & Plastic Sheet Metal Workers Structural Metal Fabricators & Fitters You might like a career in one of these industries: Manufacturing Government 
##############################################
Title: Legal Secretaries & Administrative Assistants 
Information:
Knowledge Business administrative services Safety and Government law and government Arts and Humanities English language 
Skills Basic Skills reading work related information writing things for co-workers or customers Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic order or arrange things notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Cooperation Independence Self Control 
Technology You might use software like this on the job: Accounting software Intuit QuickBooks Sage 50 Accounting Cloud-based data access and sharing software Dropbox Data base user interface and query software Database software Microsoft Access 
Education Education: (rated 3 of 5) some college or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $48,780 $31,240 $81,400 Check out my state Local Salary Info Find Jobs 
Explore More Court Reporters & Simultaneous Captioners Court, Municipal, & License Clerks Executive Secretaries & Executive Administrative Assistants Paralegals & Legal Assistants Secretaries & Administrative Assistants You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Legislators 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality People interested in this work like activities that include leading, making decisions, and business. 
Technology You might use software like this on the job: Word processing software Apple iWork Pages Microsoft Word Video conferencing software Cisco Webex LogMeIn GoToMeeting Data base user interface and query software Microsoft SQL Server Structured query language SQL 
Education Education: (rated 4 of 5) Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $48,090 $20,970 $149,710 Check out my state Local Salary Info Find Jobs 
Explore More Chief Executives Judicial Law Clerks Political Scientists Public Relations Specialists Treasurers & Controllers You might like a career in one of these industries: Government 
##############################################
Title: Librarians & Media Collections Specialists 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Engineering and Technology computers and electronics Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things group things in different ways 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Cooperation Dependability Integrity Adaptability/Flexibility Initiative Concern for Others 
Technology You might use software like this on the job: Data base user interface and query software Blackboard software Structured query language SQL Library software Online Computer Library Center (OCLC) databases Surpass management system software Web platform development software Cascading style sheets CSS Drupal 
Education Education: (rated 5 of 5) master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $61,660 $36,260 $98,650 Check out my state Local Salary Info Find Jobs 
Explore More Archivists Instructional Coordinators Library Assistants, Clerical Library Science Teachers, Postsecondary Library Technicians You might like a career in one of these industries: Education Government 
##############################################
Title: Library Assistants, Clerical 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Education and Training teaching and course design Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking read and understand what is written Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Cooperation Dependability Integrity Attention to Detail Concern for Others Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Presentation software Microsoft PowerPoint Library software Online Computer Library Center (OCLC) databases ResourceMate Plus 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $31,150 $22,060 $48,750 Check out my state Local Salary Info Find Jobs 
Explore More Document Management Specialists File Clerks Library Technicians Office Clerks, General Secretaries & Administrative Assistants You might like a career in one of these industries: Government Education Media & Communication 
##############################################
Title: Library Science Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Engineering and Technology computers and electronics Business customer service 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Initiative Achievement/Effort Integrity Adaptability/Flexibility Independence Analytical Thinking 
Technology You might use software like this on the job: Data base user interface and query software Amazon Elastic Compute Cloud EC2 Structured query language SQL Word processing software Google Docs Microsoft Word Library software EBSCO Information Services Academic Search Premier Ulrichsweb 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $76,370 $50,370 $121,230 Check out my state Local Salary Info Find Jobs 
Explore More Computer Science Teachers, Postsecondary Education Teachers, Postsecondary Instructional Coordinators Sociology Teachers, Postsecondary Teaching Assistants, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Library Technicians 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Engineering and Technology computers and electronics Education and Training teaching and course design 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Social looking for ways to help people changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic group things in different ways order or arrange things 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Cooperation Dependability Integrity Self Control Attention to Detail Concern for Others 
Technology You might use software like this on the job: Data base user interface and query software FileMaker Pro Microsoft Access Presentation software Microsoft PowerPoint Library software Online Computer Library Center (OCLC) databases WebClarity Software BookWhere 
Education Education: (rated 3 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $37,600 $25,730 $59,570 Check out my state Local Salary Info Find Jobs 
Explore More Database Administrators Document Management Specialists File Clerks Library Assistants, Clerical Office Clerks, General You might like a career in one of these industries: Government Education 
##############################################
Title: Licensed Practical & Licensed Vocational Nurses 
Information:
Knowledge Business customer service management Arts and Humanities English language Math and Science psychology arithmetic, algebra, geometry, calculus, or statistics Health medicine and dentistry 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social looking for ways to help people changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention do two or more things at the same time pay attention to something without being distracted Hand and Finger Use hold or move items with your hands 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Cooperation Concern for Others Attention to Detail Dependability Integrity Self Control 
Technology You might use software like this on the job: Medical software Epic Systems MEDITECH software Video conferencing software FaceTime Zoom Electronic mail software Microsoft Exchange Microsoft Outlook 
Education Education: (rated 3 of 5) some college or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $54,620 $40,490 $72,650 Check out my state Local Salary Info Find Jobs 
Explore More Acute Care Nurses Clinical Nurse Specialists Critical Care Nurses Nurse Practitioners Registered Nurses You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Lifeguards, Ski Patrol, & Other Recreational Protective Service Workers 
Information:
Knowledge Business customer service Safety and Government public safety and security Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Attention pay attention to something without being distracted do two or more things at the same time Verbal communicate by speaking listen and understand what people say Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Cooperation Concern for Others Self Control Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Instant messaging software GroupMe Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $27,270 $20,460 $36,930 Check out my state Local Salary Info Find Jobs 
Explore More Ambulance Drivers & Attendants Emergency Medical Technicians Firefighters Paramedics Security Guards You might like a career in one of these industries: Arts & Entertainment Government Service 
##############################################
Title: Light Truck Drivers 
Information:
Knowledge Arts and Humanities English language Business customer service Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Spatial know where things are around you Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Endurance exercise for a long time without getting out of breath Verbal communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Self Control Dependability Integrity Stress Tolerance Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Desktop communications software Eko Industrial control software FreightDATA Vehicle location and tracking software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $40,410 $26,740 $75,780 Check out my state Local Salary Info Find Jobs 
Explore More Cargo & Freight Agents Couriers & Messengers Driver/Sales Workers Heavy & Tractor-Trailer Truck Drivers Shuttle Drivers & Chauffeurs You might like a career in one of these industries: Transportation & Storage Retail Wholesale/Commercial Sales 
##############################################
Title: Lighting Technicians 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Data base user interface and query software FileMaker Pro Microsoft Access Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $61,650 $33,800 $135,540 Check out my state Local Salary Info Find Jobs 
Explore More Electrical & Electronic Engineering Technologists & Technicians Electrical & Electronic Equipment Assemblers Electrical & Electronics Installers & Repairers, Transportation Equipment Electricians Helpers--Electricians You might like a career in one of these industries: Arts & Entertainment Hotel & Food Real Estate & Rentals 
##############################################
Title: Loading & Moving Machine Operators, Underground Mining 
Information:
Knowledge Engineering and Technology mechanical Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Attention do two or more things at the same time pay attention to something without being distracted Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Self Control Concern for Others Independence Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Facilities management software Maintenance management software Mine maintenance software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $63,920 $45,780 $75,660 Check out my state Local Salary Info Find Jobs 
Explore More Continuous Mining Machine Operators Excavating & Loading Machine & Dragline Operators, Surface Mining Hoist & Winch Operators Industrial Truck & Tractor Operators Tank Car, Truck, & Ship Loaders You might like a career in one of these industries: Mining, Oil, & Gas 
##############################################
Title: Loan Interviewers & Clerks 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Engineering and Technology computers and electronics Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen order or arrange things Math choose the right type of math to solve a problem add, subtract, multiply, or divide Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Cooperation Initiative Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Rockport Integrated Excel Underwriting Presentation software Microsoft PowerPoint Financial analysis software Fannie Mae Desktop Underwriter Software AG Underwriting Solution 
Education Education: (rated 3 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,490 $31,750 $61,940 Check out my state Local Salary Info Find Jobs 
Explore More Bill & Account Collectors Credit Analysts Credit Authorizers, Checkers, & Clerks Credit Counselors Loan Officers You might like a career in one of these industries: Finance & Insurance 
##############################################
Title: Loan Officers 
Information:
Knowledge Business customer service accounting and economics Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Math add, subtract, multiply, or divide choose the right type of math to solve a problem Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Dependability Cooperation Attention to Detail Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Data base user interface and query software FileMaker Pro Microsoft Access Video conferencing software Zoom Financial analysis software Delphi Discovery White Clarke North America Credit Adjudication and Lending Management 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $65,740 $34,920 $138,580 Check out my state Local Salary Info Find Jobs 
Explore More Credit Analysts Credit Authorizers, Checkers, & Clerks Credit Counselors Loan Interviewers & Clerks Personal Financial Advisors You might like a career in one of these industries: Finance & Insurance 
##############################################
Title: Locker Room, Coatroom, & Dressing Room Attendants 
Information:
Knowledge Business customer service Arts and Humanities English language 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social looking for ways to help people understanding people's reactions 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Self Control Dependability Cooperation Attention to Detail Integrity Social Orientation 
Technology You might use software like this on the job: Spreadsheet software IBM Lotus 1-2-3 Microsoft Excel Inventory management software Inventory tracking software SportSoft Equipment Manager Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $30,210 $22,620 $45,770 Check out my state Local Salary Info Find Jobs 
Explore More Baggage Porters & Bellhops Concierges Food Servers, Nonrestaurant Hotel, Motel, & Resort Desk Clerks Passenger Attendants You might like a career in one of these industries: Arts & Entertainment Service Hotel & Food 
##############################################
Title: Locksmiths & Safe Repairers 
Information:
Knowledge Engineering and Technology mechanical Business customer service sales and marketing Safety and Government public safety and security law and government Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things use rules to solve problems 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Dependability Attention to Detail Stress Tolerance Self Control Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software dESCO ESC WH Software InstaCode 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,400 $30,710 $72,830 Check out my state Local Salary Info Find Jobs 
Explore More Electronic Equipment Installers & Repairers, Motor Vehicles Helpers--Electricians Helpers--Installation, Maintenance, & Repair Workers Mechanical Door Repairers Security & Fire Alarm Systems Installers You might like a career in one of these industries: Administration & Support Services Education 
##############################################
Title: Locomotive Engineers 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Safety and Government public safety and security Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat quickly decide if you should move your hand, foot, or other body part Attention pay attention to something without being distracted do two or more things at the same time Verbal communicate by speaking listen and understand what people say Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Integrity Cooperation Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Expert system software Electronic train management systems ETMS Word processing software Microsoft Word 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $74,570 $53,060 $89,060 Check out my state Local Salary Info Find Jobs 
Explore More Rail Yard Engineers, Dinkey Operators, & Hostlers Railroad Brake, Signal, & Switch Operators & Locomotive Firers Railroad Conductors & Yardmasters Subway & Streetcar Operators Transportation Vehicle, Equipment & Systems Inspectors You might like a career in one of these industries: Transportation & Storage 
##############################################
Title: Lodging Managers 
Information:
Knowledge Arts and Humanities English language Business management human resources (HR) Math and Science arithmetic, algebra, geometry, calculus, or statistics psychology Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen order or arrange things Math choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Persistence Initiative Adaptability/Flexibility Attention to Detail Self Control 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Facilities management software M-Tech Hotel Service Optimization System HotSOS UniResMan 
Education Education: (rated 4 of 5) bachelor's degree or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $61,910 $36,860 $122,440 Check out my state Local Salary Info Find Jobs 
Explore More Concierges First-Line Supervisors of Personal Service Workers Food Service Managers Property, Real Estate, & Community Association Managers Spa Managers You might like a career in one of these industries: Hotel & Food 
##############################################
Title: Log Graders & Scalers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics Business customer service management 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen group things in different ways Hand and Finger Use hold or move items with your hands keep your arm or hand steady Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Integrity Independence Initiative Cooperation 
Technology You might use software like this on the job: Data base user interface and query software AS/400 Database Microsoft Access Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $42,370 $30,890 $61,100 Check out my state Local Salary Info Find Jobs 
Explore More Graders & Sorters, Agricultural Products Inspectors, Testers, Sorters, Samplers, & Weighers Logging Equipment Operators Production, Planning, & Expediting Clerks Weighers, Measurers, Checkers, & Samplers, Recordkeeping You might like a career in one of these industries: Manufacturing 
##############################################
Title: Logging Equipment Operators 
Information:
Knowledge Engineering and Technology mechanical Safety and Government public safety and security Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use keep your arm or hand steady hold or move items with your hands Spatial imagine how something will look after it is moved around or changed know where things are around you Vision decide which thing is closer or farther away from you or decide how far away it is from you 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Initiative Self Control Adaptability/Flexibility Cooperation Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Enterprise resource planning ERP software SAP software 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,400 $29,010 $63,650 Check out my state Local Salary Info Find Jobs 
Explore More Continuous Mining Machine Operators Hoist & Winch Operators Industrial Truck & Tractor Operators Loading & Moving Machine Operators, Underground Mining Operating Engineers & Other Construction Equipment Operators You might like a career in one of these industries: Farming, Forestry, Fishing, & Hunting Manufacturing 
##############################################
Title: Logisticians 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language Business management customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Attention to Detail Initiative Cooperation Analytical Thinking 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft SQL Server Structured query language SQL 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $77,520 $46,260 $124,050 Check out my state Local Salary Info Find Jobs 
Explore More Logistics Analysts Logistics Engineers Project Management Specialists Supply Chain Managers Transportation, Storage, & Distribution Managers You might like a career in one of these industries: Manufacturing Government Professional, Science, & Technical Transportation & Storage Management 
##############################################
Title: Logistics Analysts 
Information:
Knowledge Arts and Humanities English language Engineering and Technology computers and electronics Transportation movement of people or goods by air, rail, sea, or road Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information order or arrange things Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Analytical Thinking Integrity Dependability Attention to Detail Achievement/Effort Adaptability/Flexibility 
Technology You might use software like this on the job: Enterprise resource planning ERP software Oracle PeopleSoft SAP software Presentation software Microsoft PowerPoint Materials requirements planning logistics and supply chain software Cadre Technologies Accuplus Integrated Distribution Logistics System Oracle E-Business Suite Logistics 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $77,520 $46,260 $124,050 Check out my state Local Salary Info Find Jobs 
Explore More Logisticians Logistics Engineers Purchasing Managers Supply Chain Managers Transportation, Storage, & Distribution Managers You might like a career in one of these industries: Manufacturing Government Professional, Science, & Technical Transportation & Storage Management 
##############################################
Title: Logistics Engineers 
Information:
Knowledge Engineering and Technology product and service development design Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Math add, subtract, multiply, or divide choose the right type of math to solve a problem Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Integrity Dependability Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Analytical or scientific software Minitab SAS Presentation software Microsoft PowerPoint Materials requirements planning logistics and supply chain software Logistics Support Analysts SmartLogic Warehouse management system WMS 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $77,520 $46,260 $124,050 Check out my state Local Salary Info Find Jobs 
Explore More Industrial Engineers Logisticians Logistics Analysts Project Management Specialists Transportation, Storage, & Distribution Managers You might like a career in one of these industries: Manufacturing Government Professional, Science, & Technical Transportation & Storage Management 
##############################################
Title: Loss Prevention Managers 
Information:
Knowledge Safety and Government public safety and security law and government Business management customer service Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Leadership Self Control Attention to Detail Initiative 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Structured query language SQL Presentation software Microsoft PowerPoint Electronic mail software IBM Lotus Notes Microsoft Outlook 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $128,620 $63,380 $218,550 Check out my state Local Salary Info Find Jobs 
Explore More Compliance Managers Financial Risk Specialists Information Security Engineers Management Analysts Security Management Specialists You might like a career in one of these industries: Government 
##############################################
Title: Low Vision Therapists, Orientation & Mobility Specialists, & Vision Rehabilitation Therapists 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Math and Science psychology sociology and anthropology Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people teaching people how to do something 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Integrity Dependability Independence Adaptability/Flexibility Self Control 
Technology You might use software like this on the job: Enterprise resource planning ERP software Oracle PeopleSoft SAP software Presentation software Microsoft PowerPoint Device drivers or system software Ai Squared ZoomText ZoomWare Screen Magnifier 
Education Education: (rated 5 of 5) master's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $93,180 $63,320 $123,870 Check out my state Local Salary Info Find Jobs 
Explore More Occupational Therapists Occupational Therapy Aides Occupational Therapy Assistants Recreational Therapists Rehabilitation Counselors You might like a career in one of these industries: Health & Counseling Education 
##############################################
Title: Machine Feeders & Offbearers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Social changing what is done based on other people's actions 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat change when and how fast you move based on how something else is moving 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Cooperation Concern for Others Independence Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $38,040 $28,190 $50,910 Check out my state Local Salary Info Find Jobs 
Explore More Adhesive Bonding Machine Operators & Tenders Crushing, Grinding, & Polishing Machine Setters, Operators, & Tenders Cutting & Slicing Machine Setters, Operators, & Tenders Extruding, Forming, Pressing, & Compacting Machine Setters, Operators, & Tenders Paper Goods Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing Transportation & Storage 
##############################################
Title: Machinists 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology mechanical design Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat change when and how fast you move based on how something else is moving Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Analytical Thinking Innovation Cooperation 
Technology You might use software like this on the job: Computer aided manufacturing CAM software Dassault Systemes SolidWorks Mastercam computer-aided design and manufacturing software Presentation software Microsoft PowerPoint Computer aided design CAD software Autodesk AutoCAD Dassault Systemes CATIA 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $48,510 $33,720 $71,820 Check out my state Local Salary Info Find Jobs 
Explore More Computer Numerically Controlled Tool Operators Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Multiple Machine Tool Setters, Operators, & Tenders, Metal & Plastic Tool & Die Makers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Magnetic Resonance Imaging Technologists 
Information:
Knowledge Business customer service Arts and Humanities English language Math and Science physics biology Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Concern for Others Dependability Integrity Self Control Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software MEDITECH software Radiology information systems (RIS) Office suite software Microsoft Office software 
Education Education: (rated 3 of 5) associate's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $80,090 $60,530 $104,850 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Diagnostic Medical Sonographers Nuclear Medicine Technologists Radiation Therapists Radiologic Technologists & Technicians You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Maids & Housekeeping Cleaners 
Information:
Knowledge Business customer service Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Social looking for ways to help people changing what is done based on other people's actions 
Abilities Endurance exercise for a long time without getting out of breath Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Self Control Attention to Detail Cooperation Dependability Independence Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Desktop communications software Eko Electronic mail software Email software 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $29,960 $22,520 $42,720 Check out my state Local Salary Info Find Jobs 
Explore More Dining Room & Cafeteria Attendants & Bartender Helpers Dishwashers Janitors & Cleaners Laundry & Dry-Cleaning Workers Locker Room, Coatroom, & Dressing Room Attendants You might like a career in one of these industries: Hotel & Food Service Health & Counseling Administration & Support Services 
##############################################
Title: Mail Clerks & Mail Machine Operators 
Information:
Knowledge Business customer service Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information People and Technology Systems thinking about the pros and cons of different options and picking the best one 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Verbal listen and understand what people say read and understand what is written 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Self Control Dependability Attention to Detail Cooperation Stress Tolerance 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access Recordkeeping software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $35,070 $26,280 $47,990 Check out my state Local Salary Info Find Jobs 
Explore More Couriers & Messengers Postal Service Clerks Postal Service Mail Carriers Postal Service Mail Sorters, Processors, & Processing Machine Operators Shipping, Receiving, & Inventory Clerks You might like a career in one of these industries: Administration & Support Services Professional, Science, & Technical Finance & Insurance Government 
##############################################
Title: Maintenance & Repair Workers, General 
Information:
Knowledge Engineering and Technology mechanical building and construction Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem figuring out how to use new ideas or things Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things notice when problems happen Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Dependability Attention to Detail Self Control Independence Cooperation 
Technology You might use software like this on the job: Operating system software Apple macOS Linux Video creation and editing software Loom YouTube Computer aided design CAD software Autodesk AutoCAD Dassault Systemes CATIA 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $44,980 $29,220 $68,930 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Hydroelectric Plant Technicians Millwrights Mobile Heavy Equipment Mechanics Plumbers, Pipefitters, & Steamfitters You might like a career in one of these industries: Real Estate & Rentals Manufacturing Government 
##############################################
Title: Maintenance Workers, Machinery 
Information:
Knowledge Engineering and Technology mechanical design Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Technical planning and doing the basic maintenance on equipment repairing machines or systems using the right tools 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Initiative Cooperation Independence Innovation 
Technology You might use software like this on the job: Enterprise resource planning ERP software Management information systems MIS SAP software Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $53,310 $36,100 $78,690 Check out my state Local Salary Info Find Jobs 
Explore More Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Industrial Machinery Mechanics Mobile Heavy Equipment Mechanics Tool Grinders, Filers, & Sharpeners Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Makeup Artists, Theatrical & Performance 
Information:
Knowledge Business customer service management Arts and Humanities English language music, dance, visual arts, drama, or sculpture Engineering and Technology design Communications multimedia 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal communicate by speaking listen and understand what people say Ideas and Logic create new and original ideas group things in different ways 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Dependability Self Control Stress Tolerance Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Photoshop Autodesk Maya Web page creation and editing software Blogging software Facebook Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 3 of 5) certificate after high school or no high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $96,370 $37,440 $170,860 Check out my state Local Salary Info Find Jobs 
Explore More Costume Attendants Fashion Designers Hairdressers, Hairstylists, & Cosmetologists Painting, Coating, & Decorating Workers Skincare Specialists 
##############################################
Title: Management Analysts 
Information:
Knowledge Arts and Humanities English language Business management customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics psychology Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Dependability Integrity Cooperation Analytical Thinking Attention to Detail Initiative 
Technology You might use software like this on the job: Web platform development software Google Angular Spring Framework Data base user interface and query software Blackboard software Transact-SQL Development environment software Apache Kafka Apache Maven 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $95,290 $55,590 $167,650 Check out my state Local Salary Info Find Jobs 
Explore More Business Intelligence Analysts Data Scientists Information Technology Project Managers Project Management Specialists Training & Development Managers You might like a career in one of these industries: Professional, Science, & Technical Government Finance & Insurance 
##############################################
Title: Manicurists & Pedicurists 
Information:
Knowledge Business customer service Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Adaptability/Flexibility Attention to Detail Self Control Stress Tolerance Concern for Others Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Calendar and scheduling software AppointmentQuest Online Appointment Scheduler DaySmart Software Appointment-Plus Data base user interface and query software Aknaf ADVANTAGE Salon Software and Spa Software DaySmart Software Salon Iris 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $31,130 $23,330 $45,180 Check out my state Local Salary Info Find Jobs 
Explore More Barbers Furniture Finishers Hairdressers, Hairstylists, & Cosmetologists Painting, Coating, & Decorating Workers Skincare Specialists You might like a career in one of these industries: Service 
##############################################
Title: Manufactured Building & Mobile Home Installers 
Information:
Knowledge Engineering and Technology building and construction design Safety and Government public safety and security law and government Business customer service Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions teaching people how to do something 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Endurance exercise for a long time without getting out of breath Reaction Time and Speed quickly move your hand, finger, or foot based on a sound, light, picture or other command 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Adaptability/Flexibility Concern for Others Cooperation Innovation 
Technology You might use software like this on the job: Electronic mail software Email software Internet browser software Web browser software 
Education Education: (rated 2 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $36,820 $27,350 $52,800 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Carpenters Millwrights Structural Iron & Steel Workers Structural Metal Fabricators & Fitters You might like a career in one of these industries: Retail Construction Manufacturing 
##############################################
Title: Manufacturing Engineers 
Information:
Knowledge Engineering and Technology product and service development mechanical Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language 
Skills Basic Skills reading work related information using math to solve problems Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Ideas and Logic group things in different ways make general rules or come up with answers from lots of detailed information Verbal listen and understand what people say communicate by speaking Math add, subtract, multiply, or divide choose the right type of math to solve a problem Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Analytical Thinking Dependability Cooperation Initiative 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks Desktop communications software Eko Electronic mail software IBM Notes Microsoft Outlook 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $96,350 $62,730 $134,990 Check out my state Local Salary Info Find Jobs 
Explore More Chemical Engineers Industrial Engineers Industrial Production Managers Mechanical Engineers Mechatronics Engineers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Marine Engineers & Naval Architects 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Integrity Analytical Thinking Initiative Dependability Achievement/Effort 
Technology You might use software like this on the job: Analytical or scientific software Ansys Fluent The MathWorks MATLAB Video conferencing software Zoom Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $96,910 $66,360 $150,260 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Aerospace Engineers Mechanical Engineering Technologists & Technicians Mechanical Engineers Ship Engineers You might like a career in one of these industries: Professional, Science, & Technical Manufacturing Government 
##############################################
Title: Market Research Analysts & Marketing Specialists 
Information:
Knowledge Arts and Humanities English language Business customer service sales and marketing Math and Science arithmetic, algebra, geometry, calculus, or statistics sociology and anthropology Communications multimedia 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information come up with lots of ideas Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Dependability Integrity Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Data base user interface and query software Airtable Amazon Redshift Information retrieval or search software LexisNexis Verispan Patient Parameters 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $68,230 $38,280 $131,850 Check out my state Local Salary Info Find Jobs 
Explore More Advertising & Promotions Managers Business Intelligence Analysts Management Analysts Marketing Managers Search Marketing Strategists You might like a career in one of these industries: Professional, Science, & Technical Media & Communication Finance & Insurance 
##############################################
Title: Marketing Managers 
Information:
Knowledge Business sales and marketing management Arts and Humanities English language Communications multimedia Engineering and Technology computers and electronics 
Skills Basic Skills figuring out how to use new ideas or things listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems come up with lots of ideas Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Initiative Dependability Leadership Achievement/Effort Adaptability/Flexibility Attention to Detail 
Technology You might use software like this on the job: Data base user interface and query software Airtable Amazon Redshift Web platform development software Oracle JavaServer Pages JSP Ruby on Rails Customer relationship management CRM software QAD Marketing Automation Salesforce software 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $140,040 $76,790 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Advertising & Promotions Managers Business Intelligence Analysts Market Research Analysts & Marketing Specialists Sales Managers Search Marketing Strategists You might like a career in one of these industries: Professional, Science, & Technical Management Media & Communication Finance & Insurance 
##############################################
Title: Marriage & Family Therapists 
Information:
Knowledge Health therapy and counseling Math and Science psychology sociology and anthropology Business customer service administrative services Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Integrity Self Control Dependability Stress Tolerance Social Orientation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software eMDs Medisoft Synergistic Office Solutions SOS Case Manager 
Education Education: (rated 5 of 5) master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $56,570 $36,840 $98,700 Check out my state Local Salary Info Find Jobs 
Explore More Child, Family, & School Social Workers Clinical & Counseling Psychologists Healthcare Social Workers Mental Health & Substance Abuse Social Workers Mental Health Counselors You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Massage Therapists 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language Math and Science biology psychology Health medicine and dentistry 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath Physical Strength exercise for a long time without your muscles getting tired 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Dependability Integrity Self Control Cooperation Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software ICS Software SammyUSA WinCity Custom Software WinCity Massage SOAP Notes Word processing software Microsoft Word 
Education Education: (rated 3 of 5) certificate after high school or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $49,860 $29,040 $90,530 Check out my state Local Salary Info Find Jobs 
Explore More Occupational Therapy Aides Occupational Therapy Assistants Physical Therapist Aides Physical Therapist Assistants Recreational Therapists You might like a career in one of these industries: Service Health & Counseling 
##############################################
Title: Materials Engineers 
Information:
Knowledge Engineering and Technology product and service development design Math and Science chemistry physics Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic group things in different ways make general rules or come up with answers from lots of detailed information Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Analytical Thinking Attention to Detail Innovation Persistence Achievement/Effort 
Technology You might use software like this on the job: Analytical or scientific software Minitab The MathWorks MATLAB Presentation software Microsoft PowerPoint Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks 
Education Education: (rated 4 of 5) bachelor's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $100,140 $60,700 $162,300 Check out my state Local Salary Info Find Jobs 
Explore More Chemical Engineers Industrial Engineers Manufacturing Engineers Materials Scientists Mechanical Engineers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Materials Scientists 
Information:
Knowledge Engineering and Technology product and service development computers and electronics Math and Science chemistry arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Integrity Attention to Detail Innovation Initiative Persistence 
Technology You might use software like this on the job: Analytical or scientific software Bruker AXS LEPTOS IBM SPSS Statistics Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 5 of 5) bachelor's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $104,380 $61,730 $165,840 Check out my state Local Salary Info Find Jobs 
Explore More Chemical Engineers Materials Engineers Mechanical Engineers Microsystems Engineers Nanosystems Engineers You might like a career in one of these industries: Professional, Science, & Technical Manufacturing Education 
##############################################
Title: Mathematical Science Teachers, Postsecondary 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Education and Training teaching and course design Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills using math to solve problems talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Analytical Thinking Integrity Independence Dependability Attention to Detail Cooperation 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Moodle Data base user interface and query software Blackboard software Structured query language SQL 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $77,420 $47,740 $159,730 Check out my state Local Salary Info Find Jobs 
Explore More Atmospheric, Earth, Marine, & Space Sciences Teachers, Postsecondary Computer Science Teachers, Postsecondary Economics Teachers, Postsecondary Education Teachers, Postsecondary Physics Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Mathematicians 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Engineering and Technology computers and electronics Education and Training teaching and course design 
Skills Basic Skills using math to solve problems thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal listen and understand what people say read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Persistence Achievement/Effort Innovation Initiative 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software Microsoft PowerPoint Object or component oriented development software C# Perl 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $112,110 $57,680 $171,540 Check out my state Local Salary Info Find Jobs 
Explore More Bioinformatics Scientists Computer & Information Research Scientists Data Scientists Physicists Statisticians You might like a career in one of these industries: Government Professional, Science, & Technical Education 
##############################################
Title: Meat, Poultry, & Fish Cutters & Trimmers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products food production Safety and Government public safety and security Math and Science arithmetic, algebra, geometry, calculus, or statistics Business management 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Social changing what is done based on other people's actions 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Achievement/Effort Integrity Independence Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Point of sale POS software Sales software Internet browser software Web browser software 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $35,070 $26,950 $44,560 Check out my state Local Salary Info Find Jobs 
Explore More Butchers & Meat Cutters Farmworkers, Farm, Ranch, & Aquacultural Animals Graders & Sorters, Agricultural Products Packers & Packagers, Hand Slaughterers & Meat Packers You might like a career in one of these industries: Manufacturing Retail 
##############################################
Title: Mechanical Door Repairers 
Information:
Knowledge Engineering and Technology mechanical building and construction Business customer service 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Cooperation Independence Integrity Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $47,010 $32,520 $65,500 Check out my state Local Salary Info Find Jobs 
Explore More Elevator & Escalator Installers & Repairers Glaziers Locksmiths & Safe Repairers Maintenance & Repair Workers, General Sheet Metal Workers You might like a career in one of these industries: Construction Retail 
##############################################
Title: Mechanical Drafters 
Information:
Knowledge Engineering and Technology design product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills figuring out how to use new ideas or things listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal communicate by speaking communicate by writing Ideas and Logic come up with lots of ideas create new and original ideas 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Analytical Thinking Dependability Integrity Initiative Cooperation 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Civil 3D Autodesk Revit Presentation software Microsoft PowerPoint Graphics or photo imaging software Adobe Systems Adobe After Effects Trimble SketchUp Pro 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $61,310 $39,030 $99,070 Check out my state Local Salary Info Find Jobs 
Explore More Architectural & Civil Drafters Computer Numerically Controlled Tool Programmers Electrical & Electronics Drafters Layout Workers, Metal & Plastic Mechanical Engineering Technologists & Technicians You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Mechanical Engineering Technologists & Technicians 
Information:
Knowledge Engineering and Technology mechanical product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic order or arrange things make general rules or come up with answers from lots of detailed information Math add, subtract, multiply, or divide choose the right type of math to solve a problem Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Analytical Thinking Dependability Initiative Integrity Persistence 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk Revit Bentley MicroStation Presentation software Microsoft PowerPoint Analytical or scientific software Finite element method FEM software The MathWorks MATLAB 
Education Education: (rated 3 of 5) bachelor's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $61,990 $39,890 $94,720 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Calibration Technologists & Technicians Electrical & Electronic Engineering Technologists & Technicians Industrial Engineering Technologists & Technicians Robotics Technicians You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Mechanical Engineers 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic order or arrange things make general rules or come up with answers from lots of detailed information Verbal listen and understand what people say read and understand what is written Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Innovation Dependability Cooperation Integrity 
Technology You might use software like this on the job: Analytical or scientific software Minitab The MathWorks MATLAB Instant messaging software Blink Computer aided design CAD software Autodesk AutoCAD Civil 3D Autodesk Revit 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $96,310 $61,990 $151,260 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Electrical Engineers Electronics Engineers Mechanical Engineering Technologists & Technicians Mechatronics Engineers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Mechatronics Engineers 
Information:
Knowledge Engineering and Technology product and service development design Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics physics 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Math choose the right type of math to solve a problem add, subtract, multiply, or divide Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Analytical Thinking Innovation Dependability Integrity Stress Tolerance 
Technology You might use software like this on the job: Development environment software C Microsoft Visual Basic Presentation software Microsoft PowerPoint Analytical or scientific software Finite element method FEM software Vector CANalyzer 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $104,600 $59,380 $168,320 Check out my state Local Salary Info Find Jobs 
Explore More Electronics Engineers Mechanical Engineering Technologists & Technicians Robotics Engineers Robotics Technicians Software Developers You might like a career in one of these industries: Professional, Science, & Technical Government Manufacturing 
##############################################
Title: Media Programming Directors 
Information:
Knowledge Communications multimedia telecommunications Engineering and Technology computers and electronics product and service development Arts and Humanities English language Business management 
Skills Basic Skills talking to others reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen come up with lots of ideas Attention pay attention to something without being distracted do two or more things at the same time 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Leadership Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software FileMaker Pro Microsoft SQL Server Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $85,320 $42,140 $179,930 Check out my state Local Salary Info Find Jobs 
Explore More Information Technology Project Managers Media Technical Directors/Managers Producers & Directors Project Management Specialists Public Relations Specialists You might like a career in one of these industries: Media & Communication Arts & Entertainment 
##############################################
Title: Media Technical Directors/Managers 
Information:
Knowledge Communications multimedia telecommunications Engineering and Technology computers and electronics product and service development Arts and Humanities English language Business management 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted do two or more things at the same time Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Cooperation Attention to Detail Adaptability/Flexibility Stress Tolerance Dependability Initiative 
Technology You might use software like this on the job: Video creation and editing software Adobe Systems Adobe After Effects YouTube Object or component oriented development software Perl Swift Development environment software C Software development tools 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $85,320 $42,140 $179,930 Check out my state Local Salary Info Find Jobs 
Explore More Information Technology Project Managers Media Programming Directors Producers & Directors Project Management Specialists Sound Engineering Technicians You might like a career in one of these industries: Media & Communication Arts & Entertainment 
##############################################
Title: Medical & Clinical Laboratory Technicians 
Information:
Knowledge Math and Science biology chemistry Arts and Humanities English language Business customer service administrative services Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use put together small parts with your fingers hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Independence Analytical Thinking Cooperation 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Word processing software Google Docs Microsoft Word Medical software MEDITECH software Test routing software 
Education Education: (rated 3 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,380 $35,220 $84,670 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Cytogenetic Technologists Histology Technicians Histotechnologists Medical & Clinical Laboratory Technologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Medical & Clinical Laboratory Technologists 
Information:
Knowledge Health medicine and dentistry Math and Science biology chemistry Business customer service administrative services Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Dependability Integrity Analytical Thinking Cooperation Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software MEDITECH software Test routing software 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,380 $35,220 $84,670 Check out my state Local Salary Info Find Jobs 
Explore More Cytogenetic Technologists Cytotechnologists Histology Technicians Histotechnologists Medical & Clinical Laboratory Technicians You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Medical & Health Services Managers 
Information:
Knowledge Business management customer service Arts and Humanities English language Education and Training teaching and course design Engineering and Technology computers and electronics 
Skills Basic Skills talking to others thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions bringing people together to solve differences 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Cooperation Leadership Dependability Adaptability/Flexibility Self Control 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software Epic Systems Data base user interface and query software Blackboard software Yardi software Analytical or scientific software IBM SPSS Statistics SAS 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $104,830 $64,100 $209,990 Check out my state Local Salary Info Find Jobs 
Explore More Clinical Nurse Specialists Health Education Specialists Health Informatics Specialists Health Specialties Teachers, Postsecondary Social & Community Service Managers You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Medical Appliance Technicians 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Business customer service Arts and Humanities English language Engineering and Technology mechanical 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Concern for Others Cooperation Integrity Innovation 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Ohio Willow Wood OMEGA Tracer System Medical software Footmaxx Metascan software Gait analysis software Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $42,160 $31,990 $64,580 Check out my state Local Salary Info Find Jobs 
Explore More Dental Laboratory Technicians Medical Equipment Repairers Molders, Shapers, & Casters Ophthalmic Laboratory Technicians Structural Metal Fabricators & Fitters You might like a career in one of these industries: Manufacturing Health & Counseling Wholesale/Commercial Sales 
##############################################
Title: Medical Assistants 
Information:
Knowledge Arts and Humanities English language Business customer service administrative services Health medicine and dentistry therapy and counseling Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Attention to Detail Dependability Concern for Others Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software Epic Systems Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $38,270 $30,390 $51,710 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Licensed Practical & Licensed Vocational Nurses Ophthalmic Medical Technicians Physical Therapist Aides Surgical Assistants You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Medical Dosimetrists 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Medical software Medical condition coding software MEDITECH software 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $128,970 $90,970 $163,040 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Nuclear Medicine Technologists Nuclear Monitoring Technicians Radiation Therapists Radiologic Technologists & Technicians You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Medical Equipment Preparers 
Information:
Knowledge Business customer service Arts and Humanities English language Math and Science biology Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Independence Stress Tolerance Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software eClinicalWorks EHR software MEDITECH software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $41,480 $31,570 $62,960 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Endoscopy Technicians Medical & Clinical Laboratory Technicians Medical & Clinical Laboratory Technologists Surgical Technologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Medical Equipment Repairers 
Information:
Knowledge Engineering and Technology mechanical computers and electronics Business customer service Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Technical repairing machines or systems using the right tools planning and doing the basic maintenance on equipment Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers hold or move items with your hands Ideas and Logic notice when problems happen use rules to solve problems Verbal read and understand what is written listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Adaptability/Flexibility Persistence Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Video conferencing software FaceTime Medical software Medical equipment diagnostic software 
Education Education: (rated 3 of 5) associate's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $57,860 $35,530 $94,310 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Calibration Technologists & Technicians Electrical & Electronic Engineering Technologists & Technicians Electrical & Electronics Repairers, Commercial & Industrial Equipment Medical Appliance Technicians You might like a career in one of these industries: Wholesale/Commercial Sales Health & Counseling Service 
##############################################
Title: Medical Records Specialists 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software Epic Systems Desktop communications software Eko Data base user interface and query software Microsoft SQL Server Structured query language SQL 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $47,180 $31,710 $75,460 Check out my state Local Salary Info Find Jobs 
Explore More Health Information Technologists & Medical Registrars Medical Assistants Medical Secretaries & Administrative Assistants Medical Transcriptionists Patient Representatives You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Medical Scientists 
Information:
Knowledge Math and Science biology chemistry Arts and Humanities English language Health medicine and dentistry Engineering and Technology computers and electronics 
Skills Basic Skills writing things for co-workers or customers figuring out how to use new ideas or things Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information group things in different ways Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Innovation Integrity Attention to Detail Achievement/Effort Cooperation 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics SAS Presentation software Microsoft PowerPoint Data base user interface and query software Database software Waters Empower 2 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $99,930 $58,190 $170,260 Check out my state Local Salary Info Find Jobs 
Explore More Biochemists & Biophysicists Geneticists Medical & Clinical Laboratory Technologists Microbiologists Physicians, Pathologists You might like a career in one of these industries: Professional, Science, & Technical Health & Counseling Education 
##############################################
Title: Medical Secretaries & Administrative Assistants 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Health medicine and dentistry Engineering and Technology computers and electronics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Attention do two or more things at the same time 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Concern for Others Attention to Detail Integrity Dependability Stress Tolerance Self Control 
Technology You might use software like this on the job: Medical software Epic Systems MEDITECH software Word processing software Google Docs Microsoft Word Accounting software Billing software Intuit QuickBooks 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $38,500 $30,230 $55,620 Check out my state Local Salary Info Find Jobs 
Explore More Medical Assistants Medical Records Specialists Office Clerks, General Receptionists & Information Clerks Secretaries & Administrative Assistants You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Medical Transcriptionists 
Information:
Knowledge Arts and Humanities English language Business administrative services Engineering and Technology computers and electronics Health medicine and dentistry 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic order or arrange things group things in different ways 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Dependability Attention to Detail Integrity Independence Cooperation Persistence 
Technology You might use software like this on the job: Word processing software Boston Bar Systems Corporation Sonnet Microsoft Word Video creation and editing software YouTube Medical software eClinicalWorks EHR software SpectraMedi EasyFlow 
Education Education: (rated 3 of 5) certificate after high school or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $34,730 $24,340 $51,280 Check out my state Local Salary Info Find Jobs 
Explore More Emergency Medical Technicians Health Information Technologists & Medical Registrars Medical Assistants Medical Records Specialists Word Processors & Typists You might like a career in one of these industries: Health & Counseling Administration & Support Services 
##############################################
Title: Meeting, Convention, & Event Planners 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Communications multimedia Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions looking for ways to help people 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted do two or more things at the same time 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Dependability Stress Tolerance Adaptability/Flexibility Cooperation Self Control 
Technology You might use software like this on the job: Project management software Microsoft Project Oracle Primavera Enterprise Project Portfolio Management Word processing software Google Docs Microsoft Word Data base user interface and query software FileMaker Pro Microsoft Access 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $52,560 $32,050 $92,880 Check out my state Local Salary Info Find Jobs 
Explore More Executive Secretaries & Executive Administrative Assistants First-Line Supervisors of Entertainment & Recreation Workers Fundraisers Project Management Specialists Public Relations Specialists You might like a career in one of these industries: Service Education Arts & Entertainment 
##############################################
Title: Mental Health & Substance Abuse Social Workers 
Information:
Knowledge Health therapy and counseling medicine and dentistry Math and Science psychology sociology and anthropology Arts and Humanities English language philosophy and religion Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Integrity Self Control Adaptability/Flexibility Stress Tolerance Dependability 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Information presentation software Microsoft PowerPoint Medical software Client records software Medical procedure coding software 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $51,240 $34,630 $97,660 Check out my state Local Salary Info Find Jobs 
Explore More Healthcare Social Workers Marriage & Family Therapists Mental Health Counselors Rehabilitation Counselors Substance Abuse & Behavioral Disorder Counselors You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Mental Health Counselors 
Information:
Knowledge Math and Science psychology sociology and anthropology Health therapy and counseling Business customer service management Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Stress Tolerance Integrity Self Control Social Orientation Adaptability/Flexibility 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics Oracle PeopleSoft Presentation software Microsoft PowerPoint Internet browser software Netscape Navigator Web browser software 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $49,710 $34,580 $82,710 Check out my state Local Salary Info Find Jobs 
Explore More Clinical & Counseling Psychologists Healthcare Social Workers Marriage & Family Therapists Mental Health & Substance Abuse Social Workers Rehabilitation Counselors You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Merchandise Displayers & Window Trimmers 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use hold or move items with your hands put together small parts with your fingers Ideas and Logic come up with lots of ideas create new and original ideas Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Cooperation Dependability Integrity Innovation Adaptability/Flexibility 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Trimble SketchUp Pro Word processing software Google Docs Microsoft Word Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 3 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $35,380 $28,740 $49,990 Check out my state Local Salary Info Find Jobs 
Explore More Advertising Sales Agents Fashion Designers Graphic Designers Interior Designers Retail Salespersons You might like a career in one of these industries: Retail Wholesale/Commercial Sales Professional, Science, & Technical 
##############################################
Title: Metal-Refining Furnace Operators & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical Safety and Government public safety and security Business management 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Self Control Independence Cooperation Analytical Thinking 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Office suite software Microsoft Office software Data base user interface and query software Process safety management software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $50,280 $36,460 $78,090 Check out my state Local Salary Info Find Jobs 
Explore More Furnace, Kiln, Oven, Drier, & Kettle Operators & Tenders Heat Treating Equipment Setters, Operators, & Tenders, Metal & Plastic Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders Stationary Engineers & Boiler Operators Welding, Soldering, & Brazing Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Meter Readers, Utilities 
Information:
Knowledge Business customer service Safety and Government public safety and security Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use keep your arm or hand steady 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Dependability Cooperation Self Control Integrity Attention to Detail Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Geographic information system Geographic information system GIS systems 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $44,760 $29,130 $78,600 Check out my state Local Salary Info Find Jobs 
Explore More Control & Valve Installers & Repairers Electrical & Electronics Repairers, Commercial & Industrial Equipment Electrical & Electronics Repairers, Powerhouse, Substation, & Relay Inspectors, Testers, Sorters, Samplers, & Weighers Power Distributors & Dispatchers You might like a career in one of these industries: Government Utilities Administration & Support Services 
##############################################
Title: Microbiologists 
Information:
Knowledge Math and Science biology chemistry Arts and Humanities English language Engineering and Technology computers and electronics Education and Training teaching and course design 
Skills Basic Skills using scientific rules and strategies to solve problems reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information group things in different ways Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Integrity Analytical Thinking Dependability Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Database management software Microsoft Access Presentation software Microsoft PowerPoint Analytical or scientific software BD Biosciences CellQuest Verity Software House ModFit LT 
Education Education: (rated 5 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $81,990 $46,270 $136,630 Check out my state Local Salary Info Find Jobs 
Explore More Biochemists & Biophysicists Biological Technicians Geneticists Histotechnologists Medical Scientists You might like a career in one of these industries: Professional, Science, & Technical Government Manufacturing 
##############################################
Title: Microsystems Engineers 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Innovation Integrity Initiative Dependability 
Technology You might use software like this on the job: Analytical or scientific software SAS The MathWorks MATLAB Presentation software Microsoft PowerPoint Operating system software Bash Microsoft Windows Server 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $104,600 $59,380 $168,320 Check out my state Local Salary Info Find Jobs 
Explore More Electronics Engineers Materials Scientists Mechatronics Engineers Nanosystems Engineers Photonics Engineers You might like a career in one of these industries: Professional, Science, & Technical Government Manufacturing 
##############################################
Title: Middle School Teachers 
Information:
Knowledge Arts and Humanities English language history and archeology Education and Training teaching and course design Business customer service management Math and Science psychology 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Integrity Adaptability/Flexibility Cooperation Self Control Stress Tolerance 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Computer based training software Moodle Schoology Video creation and editing software Flipgrid Screencastify 
Education Education: (rated 4 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $61,810 $45,910 $100,570 Check out my state Local Salary Info Find Jobs 
Explore More Adult Basic Education, Adult Secondary Education, & English as a Second Language Instructors Elementary School Teachers Secondary School Teachers Special Education Teachers, Elementary School Tutors You might like a career in one of these industries: Education 
##############################################
Title: Midwives 
Information:
Knowledge Business customer service management Health medicine and dentistry therapy and counseling Math and Science psychology biology Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Concern for Others Integrity Self Control Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software MEDITECH software Private Practice 
Education Education: (rated 5 of 5) certificate after high school or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $60,160 $31,830 $115,600 Check out my state Local Salary Info Find Jobs 
Explore More Emergency Medicine Physicians Family Medicine Physicians Nurse Midwives Nurse Practitioners Obstetricians & Gynecologists You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic 
Information:
Knowledge Engineering and Technology mechanical computers and electronics Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Persistence Stress Tolerance Independence 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Siemens Solid Edge Presentation software Microsoft PowerPoint Object or component oriented development software G-code M-code 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,870 $33,240 $67,620 Check out my state Local Salary Info Find Jobs 
Explore More Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Multiple Machine Tool Setters, Operators, & Tenders, Metal & Plastic Tool Grinders, Filers, & Sharpeners Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Millwrights 
Information:
Knowledge Engineering and Technology mechanical design Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Technical watching gauges, dials, or display screens to make sure a machine is working installing equipment, machines, wiring, or computer programs Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Ideas and Logic order or arrange things notice when problems happen Verbal listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Adaptability/Flexibility Stress Tolerance 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $60,930 $38,940 $81,300 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Engine & Other Machine Assemblers Industrial Machinery Mechanics Maintenance & Repair Workers, General Mobile Heavy Equipment Mechanics You might like a career in one of these industries: Construction Manufacturing 
##############################################
Title: Mining & Geological Engineers, Including Mining Safety Engineers 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic group things in different ways make general rules or come up with answers from lots of detailed information Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Dependability Cooperation Integrity Independence 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Oracle Database Presentation software Microsoft PowerPoint Analytical or scientific software Maptek Vulcan Ventsim 
Education Education: (rated 4 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $97,490 $55,460 $158,540 Check out my state Local Salary Info Find Jobs 
Explore More Civil Engineers Geological Technicians Geothermal Production Managers Industrial Engineers Petroleum Engineers You might like a career in one of these industries: Professional, Science, & Technical Mining, Oil, & Gas 
##############################################
Title: Mixing & Blending Machine Setters, Operators, & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Verbal listen and understand what people say read and understand what is written Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Initiative Achievement/Effort Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Email software Microsoft Outlook Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $43,410 $30,880 $61,480 Check out my state Local Salary Info Find Jobs 
Explore More Cleaning, Washing, & Metal Pickling Equipment Operators & Tenders Crushing, Grinding, & Polishing Machine Setters, Operators, & Tenders Food Batchmakers Machine Feeders & Offbearers Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Mobile Heavy Equipment Mechanics 
Information:
Knowledge Engineering and Technology mechanical computers and electronics Business customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government public safety and security 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Technical figuring out what is causing equipment, machines, wiring, or computer programs to not work repairing machines or systems using the right tools 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Ideas and Logic notice when problems happen order or arrange things Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Independence Analytical Thinking Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software Database software Recordkeeping software Electronic mail software Microsoft Outlook 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $59,440 $38,950 $80,080 Check out my state Local Salary Info Find Jobs 
Explore More Bus & Truck Mechanics & Diesel Engine Specialists Industrial Machinery Mechanics Maintenance Workers, Machinery Millwrights Rail Car Repairers You might like a career in one of these industries: Wholesale/Commercial Sales Construction Real Estate & Rentals Government 
##############################################
Title: Model Makers, Metal & Plastic 
Information:
Knowledge Engineering and Technology design product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say communicate by speaking Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Analytical Thinking Innovation Dependability Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Computer aided manufacturing CAM software CNC Software Mastercam Office suite software Microsoft Office software 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $57,620 $37,950 $85,280 Check out my state Local Salary Info Find Jobs 
Explore More Layout Workers, Metal & Plastic Model Makers, Wood Patternmakers, Metal & Plastic Structural Metal Fabricators & Fitters Tool & Die Makers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Model Makers, Wood 
Information:
Knowledge Engineering and Technology design product and service development Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen order or arrange things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Innovation Adaptability/Flexibility Analytical Thinking 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Computer aided design CAD software Dassault Systemes CATIA Siemens NX 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $46,940 $35,350 $83,230 Check out my state Local Salary Info Find Jobs 
Explore More Cabinetmakers & Bench Carpenters Layout Workers, Metal & Plastic Model Makers, Metal & Plastic Patternmakers, Wood Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing Government 
##############################################
Title: Models 
Information:
Knowledge Business customer service Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions Social understanding people's reactions 
Abilities Verbal listen and understand what people say Flexibility, Balance, and Coordination keep your balance or stay upright 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Dependability Integrity Cooperation Self Control Adaptability/Flexibility Independence 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Photoshop Trimble SketchUp Pro Video creation and editing software YouTube Web page creation and editing software Facebook LinkedIn 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $43,130 $27,170 $156,000 Check out my state Local Salary Info Find Jobs 
Explore More Costume Attendants Demonstrators & Product Promoters Fashion Designers Fine Artists, Including Painters, Sculptors, & Illustrators Makeup Artists, Theatrical & Performance You might like a career in one of these industries: Education 
##############################################
Title: Molders, Shapers, & Casters 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical 
Skills Basic Skills reading work related information keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal read and understand what is written listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Self Control Independence Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Computer aided manufacturing CAM software Mastercam computer-aided design and manufacturing software 
Education Education: (rated 2 of 5) Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $39,590 $30,040 $56,470 Check out my state Local Salary Info Find Jobs 
Explore More Cutters & Trimmers, Hand Extruding, Forming, Pressing, & Compacting Machine Setters, Operators, & Tenders Foundry Mold & Coremakers Grinding & Polishing Workers, Hand Painting, Coating, & Decorating Workers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Molding, Coremaking, & Casting Machine Setters, Operators, & Tenders, Metal & Plastic 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Cooperation Integrity Independence Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Computer aided manufacturing CAM software Intera Systems Hawk-i Visi-Trak True-Trak 20/20 Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $37,050 $28,830 $54,900 Check out my state Local Salary Info Find Jobs 
Explore More Extruding & Drawing Machine Setters, Operators, & Tenders, Metal & Plastic Extruding & Forming Machine Setters, Operators, & Tenders, Synthetic & Glass Fibers Extruding, Forming, Pressing, & Compacting Machine Setters, Operators, & Tenders Molders, Shapers, & Casters Multiple Machine Tool Setters, Operators, & Tenders, Metal & Plastic You might like a career in one of these industries: Manufacturing 
##############################################
Title: Molecular & Cellular Biologists 
Information:
Knowledge Math and Science biology chemistry Arts and Humanities English language Education and Training teaching and course design Engineering and Technology computers and electronics 
Skills Basic Skills using scientific rules and strategies to solve problems reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information group things in different ways Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Persistence Integrity Analytical Thinking Cooperation Dependability 
Technology You might use software like this on the job: Analytical or scientific software Minitab RasMol Presentation software Microsoft PowerPoint Graphics or photo imaging software Adobe Systems Adobe Illustrator Corel CorelDraw Graphics Suite 
Education Education: (rated 5 of 5) post-doctoral training or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $87,300 $50,730 $155,570 Check out my state Local Salary Info Find Jobs 
Explore More Biochemists & Biophysicists Bioinformatics Scientists Biological Science Teachers, Postsecondary Geneticists Medical & Clinical Laboratory Technologists You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Morticians, Undertakers, & Funeral Arrangers 
Information:
Knowledge Business customer service management Math and Science psychology chemistry Arts and Humanities English language philosophy and religion Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Attention do two or more things at the same time pay attention to something without being distracted 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Concern for Others Integrity Dependability Self Control Adaptability/Flexibility 
Technology You might use software like this on the job: Office suite software Corel WordPerfect Office Suite Microsoft Office software Presentation software Microsoft PowerPoint Data base user interface and query software HMIS Advantage Twin Tier Technologies MIMS 
Education Education: (rated 3 of 5) associate's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $51,570 $30,440 $93,250 Check out my state Local Salary Info Find Jobs 
Explore More Crematory Operators Embalmers Funeral Attendants Funeral Home Managers Personal Care Aides You might like a career in one of these industries: Service 
##############################################
Title: Motion Picture Projectionists 
Information:
Knowledge Engineering and Technology computers and electronics mechanical Business customer service Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Independence Integrity Adaptability/Flexibility Stress Tolerance 
Technology You might use software like this on the job: Operating system software Apple macOS Microsoft operating system Presentation software Microsoft PowerPoint Word processing software Microsoft Outlook Microsoft Word 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $33,360 $21,730 $50,220 Check out my state Local Salary Info Find Jobs 
Explore More Audio & Video Technicians Audiovisual Equipment Installers & Repairers Camera & Photographic Equipment Repairers Lighting Technicians Photographic Process Workers & Processing Machine Operators You might like a career in one of these industries: Media & Communication 
##############################################
Title: Motorboat Mechanics & Service Technicians 
Information:
Knowledge Engineering and Technology mechanical computers and electronics Business customer service management Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Initiative Integrity Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Analytical or scientific software CDI Electronics M.E.D.S. Rinda Technologies DIACOM Marine 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $48,280 $31,930 $71,760 Check out my state Local Salary Info Find Jobs 
Explore More Automotive Service Technicians & Mechanics Bus & Truck Mechanics & Diesel Engine Specialists Electric Motor, Power Tool, & Related Repairers Motorcycle Mechanics Outdoor Power Equipment & Other Small Engine Mechanics You might like a career in one of these industries: Retail Service Arts & Entertainment 
##############################################
Title: Motorboat Operators 
Information:
Knowledge Business customer service Arts and Humanities English language Education and Training teaching and course design Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use hold or move items with your hands keep your arm or hand steady Spatial know where things are around you imagine how something will look after it is moved around or changed Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Concern for Others Achievement/Effort Initiative Persistence Independence 
Technology You might use software like this on the job: Analytical or scientific software Echo sounder software Radar software Expert system software Autopilot software Mobile location based services software Global positioning system GPS software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $41,430 $29,640 $73,650 Check out my state Local Salary Info Find Jobs 
Explore More Captains, Mates, & Pilots of Water Vessels Dredge Operators Fishing & Hunting Workers Sailors & Marine Oilers Ship Engineers You might like a career in one of these industries: Transportation & Storage Construction Arts & Entertainment Government 
##############################################
Title: Motorcycle Mechanics 
Information:
Knowledge Engineering and Technology mechanical computers and electronics Arts and Humanities English language Business customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills figuring out how to use new ideas or things keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Verbal listen and understand what people say communicate by speaking Hearing and Speech tell the difference between sounds 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Persistence Initiative Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software AbbottSoft QuickFix TRACKUM Repair Manager Point of sale POS software LightSpeed Cloud Santa Maria Software Counterman Pro 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $43,370 $28,710 $63,200 Check out my state Local Salary Info Find Jobs 
Explore More Automotive Service Technicians & Mechanics Bicycle Repairers Bus & Truck Mechanics & Diesel Engine Specialists Motorboat Mechanics & Service Technicians Outdoor Power Equipment & Other Small Engine Mechanics You might like a career in one of these industries: Retail Service 
##############################################
Title: Multiple Machine Tool Setters, Operators, & Tenders, Metal & Plastic 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical design Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Ideas and Logic notice when problems happen order or arrange things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Persistence Dependability Adaptability/Flexibility Integrity Stress Tolerance 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Spreadsheet software Microsoft Excel Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $39,210 $29,410 $58,560 Check out my state Local Salary Info Find Jobs 
Explore More Cutting, Punching, & Press Machine Setters, Operators, & Tenders, Metal & Plastic Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Tool Grinders, Filers, & Sharpeners You might like a career in one of these industries: Manufacturing Administration & Support Services 
##############################################
Title: Museum Technicians & Conservators 
Information:
Knowledge Arts and Humanities music, dance, visual arts, drama, or sculpture English language Safety and Government public safety and security Business management Math and Science chemistry 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things create new and original ideas Hand and Finger Use keep your arm or hand steady put together small parts with your fingers 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Analytical Thinking Initiative 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop Presentation software Microsoft PowerPoint Data base user interface and query software Gallery Systems EmbARK Questor Systems QScan32 
Education Education: (rated 4 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $47,270 $29,640 $81,650 Check out my state Local Salary Info Find Jobs 
Explore More Archivists Curators Forest & Conservation Technicians Historians Set & Exhibit Designers You might like a career in one of these industries: Arts & Entertainment Government Education 
##############################################
Title: Music Directors & Composers 
Information:
Knowledge Arts and Humanities music, dance, visual arts, drama, or sculpture English language Education and Training teaching and course design Engineering and Technology computers and electronics Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic come up with lots of ideas create new and original ideas Hearing and Speech tell the difference between sounds pay attention to one sound while there are other distracting sounds Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Dependability Integrity Attention to Detail Initiative Cooperation Independence 
Technology You might use software like this on the job: Presentation software MediaShout Microsoft PowerPoint Music or sound editing software Audacity ZynAddSubFX Spreadsheet software Microsoft Excel 
Education Education: (rated 4 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $62,940 $31,750 $165,760 Check out my state Local Salary Info Find Jobs 
Explore More Art, Drama, & Music Teachers, Postsecondary Choreographers Musicians & Singers Producers & Directors Talent Directors You might like a career in one of these industries: Service 
##############################################
Title: Music Therapists 
Information:
Knowledge Health therapy and counseling Math and Science psychology sociology and anthropology Arts and Humanities music, dance, visual arts, drama, or sculpture English language Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Social understanding people's reactions looking for ways to help people People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted Hearing and Speech recognize spoken words 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Self Control Integrity Adaptability/Flexibility Dependability Cooperation 
Technology You might use software like this on the job: Office suite software Microsoft Office software Music or sound editing software Avid Technology Pro Tools Virtual instrument software Medical software Electronic health record EHR software 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $60,800 $37,990 $111,800 Check out my state Local Salary Info Find Jobs 
Explore More Advanced Practice Psychiatric Nurses Art Therapists Occupational Therapists Recreational Therapists Speech-Language Pathologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Musical Instrument Repairers & Tuners 
Information:
Knowledge Business customer service Arts and Humanities music, dance, visual arts, drama, or sculpture English language Engineering and Technology mechanical 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Hearing and Speech tell the difference between sounds pay attention to one sound while there are other distracting sounds Verbal communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Persistence Independence Achievement/Effort 
Technology You might use software like this on the job: Analytical or scientific software Katsura Shareware KS Strobe Tuner Veritune Verituner 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $38,150 $27,250 $63,110 Check out my state Local Salary Info Find Jobs 
Explore More Coil Winders, Tapers, & Finishers Electric Motor, Power Tool, & Related Repairers Timing Device Assemblers & Adjusters Tool Grinders, Filers, & Sharpeners Watch & Clock Repairers You might like a career in one of these industries: Retail Service 
##############################################
Title: Musicians & Singers 
Information:
Knowledge Arts and Humanities music, dance, visual arts, drama, or sculpture English language Communications multimedia 
Skills Basic Skills talking to others keeping track of how well people and/or groups are doing in order to make improvements Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Memory remember words, numbers, pictures, or steps Hearing and Speech tell the difference between sounds pay attention to one sound while there are other distracting sounds Visual Understanding quickly know what you are looking at 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Dependability Attention to Detail Achievement/Effort Cooperation Persistence Stress Tolerance 
Technology You might use software like this on the job: Web page creation and editing software Blogging software Facebook Video creation and editing software YouTube Music or sound editing software Avid Technology Pro Tools iZotope Ozone 
Education Education: (rated 4 of 5) Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $39.14 $14.42 $100.22 Check out my state Local Salary Info Find Jobs 
Explore More Actors Art, Drama, & Music Teachers, Postsecondary Choreographers Dancers Music Directors & Composers You might like a career in one of these industries: Service 
##############################################
Title: Nannies 
Information:
Knowledge Arts and Humanities English language Business customer service Math and Science psychology Education and Training teaching and course design 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Integrity Concern for Others Self Control Adaptability/Flexibility Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Computer based training software Educational software Word processing software Microsoft Word 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $28,520 $21,250 $39,090 Check out my state Local Salary Info Find Jobs 
Explore More Child, Family, & School Social Workers Childcare Workers Home Health Aides Personal Care Aides Preschool Teachers You might like a career in one of these industries: Health & Counseling Service Education 
##############################################
Title: Nanosystems Engineers 
Information:
Knowledge Engineering and Technology product and service development computers and electronics Math and Science physics chemistry Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Integrity Persistence Initiative Cooperation 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks Presentation software Microsoft PowerPoint Analytical or scientific software Dassault Systemes Abaqus UTQUANT 
Education Education: (rated 5 of 5) doctoral degree or bachelor's degree usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $104,600 $59,380 $168,320 Check out my state Local Salary Info Find Jobs 
Explore More Bioengineers & Biomedical Engineers Chemical Engineers Materials Scientists Microsystems Engineers Nanotechnology Engineering Technologists & Technicians You might like a career in one of these industries: Professional, Science, & Technical Government Manufacturing 
##############################################
Title: Nanotechnology Engineering Technologists & Technicians 
Information:
Knowledge Engineering and Technology product and service development computers and electronics Math and Science chemistry physics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted Hand and Finger Use keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Analytical Thinking Persistence Dependability Integrity Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Analytical or scientific software Simulation software SPMLab 
Education Education: (rated 4 of 5) Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $61,210 $40,710 $90,430 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Chemists Industrial Engineering Technologists & Technicians Mechanical Engineering Technologists & Technicians Nanosystems Engineers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Natural Sciences Managers 
Information:
Knowledge Math and Science biology chemistry Arts and Humanities English language Business management administrative services Engineering and Technology computers and electronics 
Skills Basic Skills using scientific rules and strategies to solve problems keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information order or arrange things Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Attention to Detail Dependability Persistence Cooperation Initiative 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software Microsoft PowerPoint Spreadsheet software IBM Lotus 1-2-3 Microsoft Excel 
Education Education: (rated 5 of 5) bachelor's degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $144,440 $73,730 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Bioengineers & Biomedical Engineers Data Scientists Environmental Science Teachers, Postsecondary Molecular & Cellular Biologists Project Management Specialists You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Naturopathic Physicians 
Information:
Knowledge Health medicine and dentistry therapy and counseling Math and Science biology psychology Business customer service administrative services Arts and Humanities English language 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Concern for Others Integrity Dependability Self Control Attention to Detail Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software Trigram Software AcuBase Pro ZYTO LSA Pro Label making software Labeling software 
Education Education: (rated 5 of 5) doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $106,230 $60,520 $193,560 Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Chiropractors Emergency Medicine Physicians General Internal Medicine Physicians Pediatric Surgeons You might like a career in one of these industries: Government Health & Counseling 
##############################################
Title: Network & Computer Systems Administrators 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Arts and Humanities English language Business customer service administrative services Communications telecommunications 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic notice when problems happen order or arrange things Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Analytical Thinking Dependability Integrity Cooperation Independence 
Technology You might use software like this on the job: Web platform development software Django Microsoft ASP.NET Data base user interface and query software Blackboard software Transact-SQL Network monitoring software Remote monitoring software Wireshark 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $90,520 $56,260 $140,430 Check out my state Local Salary Info Find Jobs 
Explore More Computer Network Architects Computer Network Support Specialists Database Administrators Information Security Engineers Software Developers You might like a career in one of these industries: Professional, Science, & Technical Education Media & Communication 
##############################################
Title: Neurodiagnostic Technologists 
Information:
Knowledge Arts and Humanities English language Business customer service Engineering and Technology computers and electronics Health medicine and dentistry 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Concern for Others Integrity Cooperation Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software MEDITECH software Neurotronics Polysmith 
Education Education: (rated 3 of 5) associate's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $44,990 $32,140 $76,760 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Diagnostic Medical Sonographers Magnetic Resonance Imaging Technologists Ophthalmic Medical Technologists Radiologic Technologists & Technicians You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Neurologists 
Information:
Knowledge Health medicine and dentistry therapy and counseling Arts and Humanities English language Math and Science psychology biology Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Concern for Others Integrity Attention to Detail Stress Tolerance Cooperation Dependability 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software Epic Systems Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $224,260 $79,530 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians General Internal Medicine Physicians Orthopedic Surgeons Pediatric Surgeons You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Neuropsychologists 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics Statistical software Presentation software Microsoft PowerPoint Medical software Patient electronic medical record EMR software The Tova Company Test of Variables of Attention 
Education Education: (rated 5 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $106,420 $40,000 $138,860 Check out my state Local Salary Info Find Jobs 
Explore More Advanced Practice Psychiatric Nurses Clinical & Counseling Psychologists Clinical Neuropsychologists Neurologists Psychiatrists You might like a career in one of these industries: Government 
##############################################
Title: New Accounts Clerks 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language Engineering and Technology computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Math choose the right type of math to solve a problem 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Cooperation Dependability Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software Corporate Information Factory CIF Harland Financial Solutions DepositPro 
Education Education: (rated 3 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $40,820 $32,760 $55,400 Check out my state Local Salary Info Find Jobs 
Explore More Brokerage Clerks Credit Authorizers, Checkers, & Clerks Sales Representatives of Services Securities, Commodities, & Financial Services Sales Agents Tellers You might like a career in one of these industries: Finance & Insurance 
##############################################
Title: News Analysts, Reporters, & Journalists 
Information:
Knowledge Arts and Humanities English language Communications multimedia telecommunications Safety and Government law and government Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information create new and original ideas 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Dependability Attention to Detail Integrity Initiative Stress Tolerance Achievement/Effort 
Technology You might use software like this on the job: Video creation and editing software Adobe Systems Adobe After Effects YouTube Data base user interface and query software Microsoft Access Microsoft SQL Server Web page creation and editing software Facebook Social media sites 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $55,960 $30,470 $136,500 Check out my state Local Salary Info Find Jobs 
Explore More Broadcast Announcers & Radio Disc Jockeys Editors Poets, Lyricists & Creative Writers Producers & Directors Writers & Authors You might like a career in one of these industries: Media & Communication 
##############################################
Title: Non-Destructive Testing Specialists 
Information:
Knowledge Engineering and Technology product and service development computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Dependability Attention to Detail Achievement/Effort Analytical Thinking Stress Tolerance 
Technology You might use software like this on the job: Electronic mail software IBM Notes Microsoft Outlook Presentation software Microsoft PowerPoint Analytical or scientific software Fractal Concept SoftScan Visualization Sciences Group VSG Avizo Fire 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $65,520 $38,570 $105,120 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Automotive Engineering Technicians Calibration Technologists & Technicians Inspectors, Testers, Sorters, Samplers, & Weighers Mechanical Engineering Technologists & Technicians You might like a career in one of these industries: Professional, Science, & Technical Government Manufacturing Administration & Support Services 
##############################################
Title: Nuclear Engineers 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills using scientific rules and strategies to solve problems keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen group things in different ways Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Integrity Analytical Thinking Dependability Cooperation Initiative 
Technology You might use software like this on the job: Analytical or scientific software SAS The MathWorks MATLAB Presentation software Microsoft PowerPoint Development environment software Formula translation/translator FORTRAN TOTE code 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $122,480 $79,440 $169,580 Check out my state Local Salary Info Find Jobs 
Explore More Chemical Engineers Environmental Engineers Nuclear Monitoring Technicians Nuclear Power Reactor Operators Nuclear Technicians You might like a career in one of these industries: Utilities Professional, Science, & Technical Government 
##############################################
Title: Nuclear Medicine Technologists 
Information:
Knowledge Business customer service administrative services Math and Science biology physics Arts and Humanities English language Health medicine and dentistry 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Concern for Others Dependability Cooperation Independence Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software MEDITECH software Medovation RadRunner 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $85,300 $64,680 $114,100 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Magnetic Resonance Imaging Technologists Radiation Therapists Radiologic Technologists & Technicians Radiologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Nuclear Monitoring Technicians 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Safety and Government public safety and security Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted do two or more things at the same time Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Stress Tolerance Adaptability/Flexibility Self Control 
Technology You might use software like this on the job: Operating system software Microsoft Windows Microsoft Windows Server Presentation software Microsoft PowerPoint Analytical or scientific software Gamma waste assay system GWAS RESRAD 
Education Education: (rated 3 of 5) associate's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $100,420 $63,500 $126,050 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Environmental Science & Protection Technicians, Including Health Nuclear Medicine Technologists Nuclear Power Reactor Operators Nuclear Technicians You might like a career in one of these industries: Utilities Professional, Science, & Technical 
##############################################
Title: Nuclear Power Reactor Operators 
Information:
Knowledge Math and Science physics arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology mechanical product and service development Safety and Government public safety and security 
Skills Basic Skills reading work related information keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Stress Tolerance Analytical Thinking Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Structured query language SQL Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $115,870 $85,580 $146,100 Check out my state Local Salary Info Find Jobs 
Explore More Hydroelectric Plant Technicians Nuclear Monitoring Technicians Nuclear Technicians Power Plant Operators Stationary Engineers & Boiler Operators You might like a career in one of these industries: Utilities Government 
##############################################
Title: Nuclear Technicians 
Information:
Knowledge Safety and Government public safety and security law and government Engineering and Technology mechanical product and service development Math and Science physics chemistry Education and Training teaching and course design 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking read and understand what is written Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns Ideas and Logic make general rules or come up with answers from lots of detailed information order or arrange things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Stress Tolerance Adaptability/Flexibility Analytical Thinking 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Structured query language SQL Presentation software Microsoft PowerPoint Operating system software Linux Microsoft Windows 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $100,420 $63,500 $126,050 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Hydroelectric Plant Technicians Nuclear Monitoring Technicians Nuclear Power Reactor Operators Power Plant Operators You might like a career in one of these industries: Utilities Professional, Science, & Technical 
##############################################
Title: Nurse Anesthetists 
Information:
Knowledge Health medicine and dentistry therapy and counseling Business customer service management Math and Science biology chemistry Education and Training teaching and course design 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen order or arrange things Attention pay attention to something without being distracted do two or more things at the same time Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Dependability Stress Tolerance Integrity Concern for Others Adaptability/Flexibility 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software MEDITECH software Word processing software Microsoft Word 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $203,090 $143,870 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Anesthesiologist Assistants Anesthesiologists Critical Care Nurses Nurse Practitioners Registered Nurses You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Nurse Midwives 
Information:
Knowledge Health medicine and dentistry therapy and counseling Math and Science psychology biology Arts and Humanities English language Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted do two or more things at the same time Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Stress Tolerance Dependability Integrity Cooperation Self Control 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software Epic Systems Spreadsheet software Microsoft Excel Office suite software Microsoft Office software 
Education Education: (rated 5 of 5) master's degree or certificate after master's usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $120,880 $77,510 $171,230 Check out my state Local Salary Info Find Jobs 
Explore More Clinical Nurse Specialists Midwives Nurse Practitioners Obstetricians & Gynecologists Registered Nurses You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Nurse Practitioners 
Information:
Knowledge Health medicine and dentistry therapy and counseling Arts and Humanities English language Math and Science biology psychology Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly know what you are looking at Attention do two or more things at the same time 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Attention to Detail Dependability Integrity Concern for Others Adaptability/Flexibility Stress Tolerance 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software Epic Systems Presentation software Microsoft PowerPoint Internet browser software Microsoft Internet Explorer Web browser software 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $121,610 $87,340 $165,240 Check out my state Local Salary Info Find Jobs 
Explore More Advanced Practice Psychiatric Nurses Clinical Nurse Specialists Emergency Medicine Physicians Family Medicine Physicians Registered Nurses You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Nursing Assistants 
Information:
Knowledge Business customer service management Arts and Humanities English language Health medicine and dentistry therapy and counseling Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Social looking for ways to help people understanding people's reactions 
Abilities Verbal listen and understand what people say communicate by speaking Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Dependability Self Control Attention to Detail Integrity Adaptability/Flexibility 
Technology You might use software like this on the job: Medical software Epic Systems MEDITECH software Video conferencing software FaceTime Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $35,760 $28,030 $45,940 Check out my state Local Salary Info Find Jobs 
Explore More Acute Care Nurses Home Health Aides Licensed Practical & Licensed Vocational Nurses Psychiatric Aides Registered Nurses You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Nursing Instructors & Teachers, Postsecondary 
Information:
Knowledge Health medicine and dentistry therapy and counseling Education and Training teaching and course design Math and Science psychology biology Arts and Humanities English language 
Skills Basic Skills talking to others using the best training or teaching strategies for learning new things Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Integrity Attention to Detail Initiative Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Common Curriculum Moodle Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $78,580 $47,760 $127,290 Check out my state Local Salary Info Find Jobs 
Explore More Clinical Nurse Specialists Health Education Specialists Health Informatics Specialists Health Specialties Teachers, Postsecondary Registered Nurses You might like a career in one of these industries: Education 
##############################################
Title: Obstetricians & Gynecologists 
Information:
Knowledge Health medicine and dentistry therapy and counseling Math and Science biology psychology Arts and Humanities English language Business customer service 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Hand and Finger Use keep your arm or hand steady hold or move items with your hands Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Concern for Others Dependability Stress Tolerance Self Control Attention to Detail 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software MEDITECH software Spreadsheet software Microsoft Excel Electronic mail software Email software 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $239,200+ $83,280 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians General Internal Medicine Physicians Pediatric Surgeons Pediatricians, General You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Occupational Health & Safety Specialists 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Math and Science chemistry arithmetic, algebra, geometry, calculus, or statistics Safety and Government public safety and security 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems measuring how well a system is working and how to improve it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Math choose the right type of math to solve a problem 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Cooperation Attention to Detail Dependability Adaptability/Flexibility Concern for Others 
Technology You might use software like this on the job: Data base user interface and query software EcoLogic ADAM Indoor Air Quality and Analytical Data Management Microsoft Access Presentation software Microsoft PowerPoint Compliance software ESS Compliance Suite Primatech AUDITWorks 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $78,570 $47,160 $121,510 Check out my state Local Salary Info Find Jobs 
Explore More Environmental Compliance Inspectors Environmental Science & Protection Technicians, Including Health Environmental Scientists & Specialists, Including Health Health & Safety Engineers Occupational Health & Safety Technicians You might like a career in one of these industries: Government Manufacturing Professional, Science, & Technical Construction 
##############################################
Title: Occupational Health & Safety Technicians 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Business customer service administrative services Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Concern for Others Dependability Attention to Detail Analytical Thinking Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 3 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,970 $36,180 $94,830 Check out my state Local Salary Info Find Jobs 
Explore More Environmental Compliance Inspectors Environmental Science & Protection Technicians, Including Health Environmental Scientists & Specialists, Including Health Health & Safety Engineers Occupational Health & Safety Specialists You might like a career in one of these industries: Manufacturing Professional, Science, & Technical Construction 
##############################################
Title: Occupational Therapists 
Information:
Knowledge Health therapy and counseling medicine and dentistry Math and Science psychology biology Business customer service Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social looking for ways to help people teaching people how to do something People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Dependability Concern for Others Cooperation Self Control Adaptability/Flexibility 
Technology You might use software like this on the job: Word processing software Crick Software Clicker 4 Microsoft Word Computer based training software Text reader software Text to speech software Medical software eClinicalWorks EHR software Lexrotech LxPediatric 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $93,180 $63,320 $123,870 Check out my state Local Salary Info Find Jobs 
Explore More Occupational Therapy Assistants Physical Medicine & Rehabilitation Physicians Physical Therapist Assistants Physical Therapists Recreational Therapists You might like a career in one of these industries: Health & Counseling Education 
##############################################
Title: Occupational Therapy Aides 
Information:
Knowledge Health therapy and counseling medicine and dentistry Arts and Humanities English language Business customer service administrative services Math and Science psychology 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Cooperation Concern for Others Dependability Adaptability/Flexibility Integrity Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software Electronic medical record EMR software MEDITECH software 
Education Education: (rated 3 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $37,060 $25,100 $68,070 Check out my state Local Salary Info Find Jobs 
Explore More Occupational Therapy Assistants Physical Therapist Aides Physical Therapist Assistants Psychiatric Technicians Rehabilitation Counselors You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Occupational Therapy Assistants 
Information:
Knowledge Business customer service administrative services Math and Science psychology sociology and anthropology Education and Training teaching and course design Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Integrity Cooperation Adaptability/Flexibility Attention to Detail Dependability 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Presentation software Microsoft PowerPoint Medical software eClinicalWorks EHR software Visual Health Information VHI PC-Kits 
Education Education: (rated 3 of 5) associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $64,250 $47,940 $85,580 Check out my state Local Salary Info Find Jobs 
Explore More Occupational Therapy Aides Physical Therapist Aides Physical Therapist Assistants Psychiatric Technicians Respiratory Therapists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Office Clerks, General 
Information:
Knowledge Business administrative services customer service Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Social changing what is done based on other people's actions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Cooperation Self Control Dependability Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Blackboard software Yardi software Medical software Henry Schein Dentrix MEDITECH software Accounting software Intuit QuickBooks Sage 50 Accounting 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $38,040 $26,000 $59,700 Check out my state Local Salary Info Find Jobs 
Explore More Administrative Services Managers File Clerks Medical Secretaries & Administrative Assistants Receptionists & Information Clerks Secretaries & Administrative Assistants You might like a career in one of these industries: Health & Counseling Education Government 
##############################################
Title: Office Machine Operators 
Information:
Knowledge Arts and Humanities English language Business customer service Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Hand and Finger Use keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Adaptability/Flexibility Cooperation Integrity Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Desktop communications software Eko Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $36,710 $27,040 $51,210 Check out my state Local Salary Info Find Jobs 
Explore More Computer, Automated Teller, & Office Machine Repairers Data Entry Keyers Prepress Technicians & Workers Print Binding & Finishing Workers Printing Press Operators You might like a career in one of these industries: Administration & Support Services Finance & Insurance Media & Communication 
##############################################
Title: Online Merchants 
Information:
Knowledge Arts and Humanities English language Business sales and marketing customer service Communications multimedia Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Social looking for ways to help people talking people into changing their minds or their behavior Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Dependability Initiative Persistence Integrity Adaptability/Flexibility 
Technology You might use software like this on the job: Web platform development software AJAX Oracle JavaServer Pages JSP Graphics or photo imaging software Adobe Systems Adobe Photoshop JamBoard Point of sale POS software CCBill Square 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $75,990 $40,310 $131,260 Check out my state Local Salary Info Find Jobs 
Explore More Advertising Sales Agents Market Research Analysts & Marketing Specialists Marketing Managers Sales Managers Wholesale & Retail Buyers You might like a career in one of these industries: Government Professional, Science, & Technical Education 
##############################################
Title: Operating Engineers & Other Construction Equipment Operators 
Information:
Knowledge Engineering and Technology mechanical Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use keep your arm or hand steady hold or move items with your hands Vision decide which thing is closer or farther away from you or decide how far away it is from you see details up close Attention do two or more things at the same time 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Initiative Adaptability/Flexibility Attention to Detail Cooperation Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Facilities management software Maintenance record software 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $51,430 $36,750 $93,060 Check out my state Local Salary Info Find Jobs 
Explore More Continuous Mining Machine Operators Excavating & Loading Machine & Dragline Operators, Surface Mining Hoist & Winch Operators Industrial Truck & Tractor Operators Mobile Heavy Equipment Mechanics You might like a career in one of these industries: Construction Government 
##############################################
Title: Operations Research Analysts 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Engineering and Technology computers and electronics product and service development Business management 
Skills Basic Skills using math to solve problems thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems measuring how well a system is working and how to improve it thinking about the pros and cons of different options and picking the best one 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Integrity Attention to Detail Dependability Innovation Achievement/Effort 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Word processing software Google Docs Microsoft Word Operating system software Bash UNIX Shell 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $85,720 $50,440 $149,640 Check out my state Local Salary Info Find Jobs 
Explore More Business Intelligence Analysts Computer Systems Analysts Data Scientists Management Analysts Software Developers You might like a career in one of these industries: Professional, Science, & Technical Finance & Insurance Government 
##############################################
Title: Ophthalmic Laboratory Technicians 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics Business customer service 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Verbal read and understand what is written 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Self Control Concern for Others Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software Electronic medical record EMR software 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $36,810 $27,930 $58,430 Check out my state Local Salary Info Find Jobs 
Explore More Camera & Photographic Equipment Repairers Grinding & Polishing Workers, Hand Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Tool Grinders, Filers, & Sharpeners You might like a career in one of these industries: Manufacturing Health & Counseling Retail 
##############################################
Title: Ophthalmic Medical Technicians 
Information:
Knowledge Business customer service Health medicine and dentistry Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use keep your arm or hand steady put together small parts with your fingers 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Self Control Attention to Detail Dependability Concern for Others Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software EyeMD EMR Healthcare Systems EyeMD EMR NaviNet Open 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $38,860 $29,830 $58,970 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Medical Assistants Neurodiagnostic Technologists Ophthalmic Medical Technologists Surgical Assistants You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Ophthalmic Medical Technologists 
Information:
Knowledge Business customer service administrative services Health medicine and dentistry Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Dependability Attention to Detail Cooperation Integrity Concern for Others Self Control 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Medical software EyeMD EMR Healthcare Systems EyeMD EMR NaviNet Open Computer aided design CAD software Autodesk AutoCAD Computer aided design and drafting CADD software 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $44,990 $32,140 $76,760 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Diagnostic Medical Sonographers Neurodiagnostic Technologists Ophthalmic Medical Technicians Radiologic Technologists & Technicians You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Ophthalmologists 
Information:
Knowledge Health medicine and dentistry Arts and Humanities English language Business customer service management Math and Science biology 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Dependability Attention to Detail Integrity Analytical Thinking Self Control Stress Tolerance 
Technology You might use software like this on the job: Medical software Bizmatics PrognoCIS EMR eClinicalWorks EHR software Spreadsheet software Microsoft Excel Electronic mail software Email software 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $219,810 $65,510 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Dermatologists Emergency Medicine Physicians Orthopedic Surgeons Pediatric Surgeons You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Opticians, Dispensing 
Information:
Knowledge Business customer service sales and marketing Math and Science arithmetic, algebra, geometry, calculus, or statistics psychology Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social looking for ways to help people changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Attention do two or more things at the same time 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Cooperation Self Control Integrity Dependability Analytical Thinking 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software First Insight MaximEyes Specialist Data Solutions OctoPlus Data base user interface and query software Database software EZ-Zone Optizone Enterprise 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $39,610 $29,540 $64,760 Check out my state Local Salary Info Find Jobs 
Explore More Ophthalmic Laboratory Technicians Ophthalmic Medical Technicians Ophthalmic Medical Technologists Optometrists Pharmacy Aides You might like a career in one of these industries: Health & Counseling Retail 
##############################################
Title: Optometrists 
Information:
Knowledge Health medicine and dentistry therapy and counseling Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Business customer service management Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Concern for Others Dependability Integrity Analytical Thinking Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Microsoft SQL Server Medical software First Insight MaximEyes VisionScience Software Acuity Pro Spreadsheet software Microsoft Excel 
Education Education: (rated 5 of 5) doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $125,590 $62,150 $191,430 Check out my state Local Salary Info Find Jobs 
Explore More Dermatologists Emergency Medicine Physicians General Internal Medicine Physicians Ophthalmologists Pediatric Surgeons You might like a career in one of these industries: Health & Counseling Retail 
##############################################
Title: Oral & Maxillofacial Surgeons 
Information:
Knowledge Health medicine and dentistry therapy and counseling Arts and Humanities English language Math and Science biology psychology Business customer service 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Concern for Others Self Control Stress Tolerance 
Technology You might use software like this on the job: Graphics or photo imaging software Apteryx Imaging Suite Sirona SIDEXIS XG Medical software DecisionBase TiME for OMS Dolphin Imaging & Management Solutions Dolphin Management Development environment software Ada 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $239,200+ $112,610 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Dentists, General Dermatologists Orthopedic Surgeons Pediatric Surgeons Prosthodontists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Order Clerks 
Information:
Knowledge Business customer service administrative services Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology computers and electronics product and service development Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Cooperation Integrity Self Control Stress Tolerance Dependability 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $38,060 $28,470 $56,220 Check out my state Local Salary Info Find Jobs 
Explore More Customer Service Representatives Postal Service Clerks Procurement Clerks Shipping, Receiving, & Inventory Clerks Stockers & Order Fillers You might like a career in one of these industries: Retail Wholesale/Commercial Sales Manufacturing Transportation & Storage 
##############################################
Title: Orderlies 
Information:
Knowledge Business customer service Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Physical Strength use your lower back and stomach lift, push, pull, or carry Verbal listen and understand what people say communicate by speaking Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Concern for Others Self Control Stress Tolerance Cooperation Dependability Social Orientation 
Technology You might use software like this on the job: Operating system software Microsoft Windows Medical software Electronic medical record EMR software Medical record charting software Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $34,520 $27,630 $46,700 Check out my state Local Salary Info Find Jobs 
Explore More Home Health Aides Nursing Assistants Physical Therapist Aides Psychiatric Aides Surgical Assistants You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Orthodontists 
Information:
Knowledge Health medicine and dentistry Business customer service management Arts and Humanities English language Math and Science biology 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal communicate by speaking communicate by writing Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Dependability Integrity Concern for Others Independence Cooperation 
Technology You might use software like this on the job: Office suite software Microsoft Office software Medical software Patient management software PerfectByte Ortho Graphics or photo imaging software American Orthodontics Compu-Ceph GAC International OrthoPlex 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $174,360 $91,340 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Dentists, General Oral & Maxillofacial Surgeons Orthopedic Surgeons Pediatric Surgeons Prosthodontists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Orthopedic Surgeons 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Medical software Epic Systems MEDITECH software Graphics or photo imaging software Computer imaging software Operating system software Microsoft Windows 
Education Education: (rated 5 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $239,200+ $79,400 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Dermatologists Ophthalmologists Oral & Maxillofacial Surgeons Pediatric Surgeons Urologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Orthoptists 
Information:
Knowledge Arts and Humanities English language Health medicine and dentistry Business customer service Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Concern for Others Integrity Cooperation Dependability Self Control 
Technology You might use software like this on the job: Word processing software Eye Tracking Exercises Enterprises Track with Letters Microsoft Word Presentation software Microsoft PowerPoint Medical software Computer Aided Vision Therapy CAVT Therapeutic orthoptic software 
Education Education: (rated 5 of 5) certificate after college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $106,230 $60,520 $193,560 Check out my state Local Salary Info Find Jobs 
Explore More Emergency Medicine Physicians Family Medicine Physicians Ophthalmologists Optometrists Physician Assistants You might like a career in one of these industries: Government Health & Counseling 
##############################################
Title: Orthotists & Prosthetists 
Information:
Knowledge Business customer service management Engineering and Technology design mechanical Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people understanding people's reactions 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Hand and Finger Use keep your arm or hand steady put together small parts with your fingers 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Concern for Others Attention to Detail Dependability Self Control Adaptability/Flexibility 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Ohio Willow Wood OMEGA Tracer System Presentation software Microsoft PowerPoint Medical software Healthcare common procedure coding system HCPCS OPIE Practice Management Suite 
Education Education: (rated 5 of 5) master's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $77,070 $40,360 $110,120 Check out my state Local Salary Info Find Jobs 
Explore More Audiologists Medical Appliance Technicians Orthopedic Surgeons Paramedics Physical Medicine & Rehabilitation Physicians You might like a career in one of these industries: Health & Counseling Manufacturing Retail 
##############################################
Title: Outdoor Power Equipment & Other Small Engine Mechanics 
Information:
Knowledge Engineering and Technology mechanical product and service development Business customer service sales and marketing Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Integrity Independence Achievement/Effort Analytical Thinking 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software RepairTRAX Smart Equipment Repair Analytical or scientific software Land & Sea DYNO-MAX VersaDyne small engine test system 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $40,030 $29,170 $59,090 Check out my state Local Salary Info Find Jobs 
Explore More Bus & Truck Mechanics & Diesel Engine Specialists Electric Motor, Power Tool, & Related Repairers Mobile Heavy Equipment Mechanics Motorboat Mechanics & Service Technicians Motorcycle Mechanics You might like a career in one of these industries: Retail Administration & Support Services Arts & Entertainment 
##############################################
Title: Packaging & Filling Machine Operators & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical Safety and Government public safety and security Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Independence Cooperation Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $36,750 $28,300 $52,760 Check out my state Local Salary Info Find Jobs 
Explore More Adhesive Bonding Machine Operators & Tenders Extruding, Forming, Pressing, & Compacting Machine Setters, Operators, & Tenders Machine Feeders & Offbearers Packers & Packagers, Hand Paper Goods Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing Administration & Support Services 
##############################################
Title: Packers & Packagers, Hand 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Business customer service Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements People and Technology Systems thinking about the pros and cons of different options and picking the best one 
Abilities Hand and Finger Use hold or move items with your hands Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Cooperation Integrity Dependability Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Enterprise resource planning ERP software SAP software Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $32,920 $23,870 $42,070 Check out my state Local Salary Info Find Jobs 
Explore More Adhesive Bonding Machine Operators & Tenders Cutting & Slicing Machine Setters, Operators, & Tenders Laborers & Freight, Stock, & Material Movers, Hand Machine Feeders & Offbearers Packaging & Filling Machine Operators & Tenders You might like a career in one of these industries: Retail Manufacturing Administration & Support Services Transportation & Storage 
##############################################
Title: Painters, Construction & Maintenance 
Information:
Knowledge Safety and Government public safety and security Business management customer service Arts and Humanities English language Engineering and Technology building and construction 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Social understanding people's reactions 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Leadership Dependability Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Project management software Evergreen Technology Eagle Bid Estimating Turtle Creek Software Goldenseal Graphics or photo imaging software Corel Paint Shop Pro Corel Painter 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,090 $32,510 $72,160 Check out my state Local Salary Info Find Jobs 
Explore More Coating, Painting, & Spraying Machine Setters, Operators, & Tenders Furniture Finishers Helpers--Painters, Paperhangers, Plasterers, & Stucco Masons Painting, Coating, & Decorating Workers Plasterers & Stucco Masons You might like a career in one of these industries: Construction 
##############################################
Title: Painting, Coating, & Decorating Workers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Social changing what is done based on other people's actions 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Achievement/Effort Persistence Stress Tolerance 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop Spreadsheet software Microsoft Excel Office suite software Corel WordPerfect Office Suite 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $38,270 $28,850 $55,120 Check out my state Local Salary Info Find Jobs 
Explore More Coating, Painting, & Spraying Machine Setters, Operators, & Tenders Etchers & Engravers Furniture Finishers Grinding & Polishing Workers, Hand Molders, Shapers, & Casters You might like a career in one of these industries: Manufacturing 
##############################################
Title: Paper Goods Machine Setters, Operators, & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Integrity Initiative Cooperation Stress Tolerance 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop Desktop publishing software Adobe Systems Adobe InDesign Quark enterprise publishing software Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $45,710 $31,290 $62,730 Check out my state Local Salary Info Find Jobs 
Explore More Adhesive Bonding Machine Operators & Tenders Cutting & Slicing Machine Setters, Operators, & Tenders Extruding, Forming, Pressing, & Compacting Machine Setters, Operators, & Tenders Machine Feeders & Offbearers Textile Cutting Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Paperhangers 
Information:
Knowledge Business customer service management Engineering and Technology building and construction mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Initiative Independence Integrity Self Control 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Project management software On Center Quick Bid PlanSwift Accounting software A-Systems JobView Turtle Creek Software Goldenseal 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $44,930 $30,250 $71,220 Check out my state Local Salary Info Find Jobs 
Explore More Carpet Installers Drywall & Ceiling Tile Installers Floor Layers Painters, Construction & Maintenance Painting, Coating, & Decorating Workers You might like a career in one of these industries: Professional, Science, & Technical Construction 
##############################################
Title: Paralegals & Legal Assistants 
Information:
Knowledge Arts and Humanities English language Business administrative services customer service Safety and Government law and government Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Initiative Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Document management software Adobe Systems Adobe Acrobat Document management system software Word processing software Google Docs Microsoft Word Information retrieval or search software LexisNexis Thomson CompuMark SAEGIS 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $59,200 $37,690 $94,960 Check out my state Local Salary Info Find Jobs 
Explore More Court Reporters & Simultaneous Captioners Court, Municipal, & License Clerks Eligibility Interviewers, Government Programs Legal Secretaries & Administrative Assistants Title Examiners, Abstractors, & Searchers You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Paramedics 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Information retrieval or search software Epocrates TechOnSoftware HazMatCE Pro 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $49,090 $36,490 $76,630 Check out my state Local Salary Info Find Jobs 
Explore More Critical Care Nurses Emergency Medical Technicians Licensed Practical & Licensed Vocational Nurses Registered Nurses Surgical Assistants You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Park Naturalists 
Information:
Knowledge Business customer service Education and Training teaching and course design Arts and Humanities English language history and archeology Math and Science biology 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems notice when problems happen Hearing and Speech speak clearly recognize spoken words 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Cooperation Self Control Concern for Others Dependability Social Orientation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software MicroFocus GroupWise Microsoft Outlook 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $64,460 $41,360 $102,670 Check out my state Local Salary Info Find Jobs 
Explore More Forest & Conservation Technicians Forestry & Conservation Science Teachers, Postsecondary Historians Recreation Workers Tour Guides & Escorts You might like a career in one of these industries: Government Service 
##############################################
Title: Parking Attendants 
Information:
Knowledge Business customer service Arts and Humanities English language Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social looking for ways to help people understanding people's reactions 
Abilities Spatial know where things are around you Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Self Control Dependability Stress Tolerance Independence Adaptability/Flexibility 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Spreadsheet software Microsoft Excel Point of sale POS software CorePark Valet SMS Valet 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $30,570 $22,580 $38,760 Check out my state Local Salary Info Find Jobs 
Explore More Baggage Porters & Bellhops Counter & Rental Clerks Parking Enforcement Workers Passenger Attendants Shuttle Drivers & Chauffeurs You might like a career in one of these industries: Service Retail 
##############################################
Title: Parking Enforcement Workers 
Information:
Knowledge Arts and Humanities English language Safety and Government public safety and security law and government Engineering and Technology computers and electronics Education and Training teaching and course design 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Self Control Integrity Independence Attention to Detail Stress Tolerance Dependability 
Technology You might use software like this on the job: Data base user interface and query software Complus Data Innovations FastTrack Microsoft Access Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $41,570 $29,220 $64,460 Check out my state Local Salary Info Find Jobs 
Explore More Parking Attendants Railroad Conductors & Yardmasters Security Guards Traffic Technicians Transit & Railroad Police You might like a career in one of these industries: Government Education 
##############################################
Title: Parts Salespersons 
Information:
Knowledge Business customer service sales and marketing Engineering and Technology computers and electronics mechanical Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social talking people into changing their minds or their behavior looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Cooperation Attention to Detail Initiative Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Inventory management software Inventory management systems Inventory tracking software 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $35,800 $23,510 $59,070 Check out my state Local Salary Info Find Jobs 
Explore More Counter & Rental Clerks Door-to-Door Sales Workers, News & Street Vendors, & Related Workers Retail Salespersons Shipping, Receiving, & Inventory Clerks Stockers & Order Fillers You might like a career in one of these industries: Retail Wholesale/Commercial Sales 
##############################################
Title: Passenger Attendants 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language Business customer service Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Self Control Stress Tolerance Concern for Others Cooperation Integrity 
Technology You might use software like this on the job: Office suite software Microsoft Office software Electronic mail software Email software Operating system software Microsoft Windows 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $34,630 $22,020 $50,000 Check out my state Local Salary Info Find Jobs 
Explore More Baggage Porters & Bellhops Bus Drivers, Transit & Intercity First-Line Supervisors of Passenger Attendants Flight Attendants Reservation & Transportation Ticket Agents & Travel Clerks You might like a career in one of these industries: Transportation & Storage Government 
##############################################
Title: Patient Representatives 
Information:
Knowledge Business customer service administrative services Health medicine and dentistry therapy and counseling Math and Science sociology and anthropology psychology Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Adaptability/Flexibility Cooperation Integrity Self Control Stress Tolerance 
Technology You might use software like this on the job: Medical software Epic Systems MEDITECH software Word processing software Google Docs Microsoft Word Customer relationship management CRM software CareOne CareEnsure Microsoft Dynamics 
Education Education: (rated 3 of 5) Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $44,990 $32,140 $76,760 Check out my state Local Salary Info Find Jobs 
Explore More Community Health Workers Health Education Specialists Health Informatics Specialists Health Information Technologists & Medical Registrars Medical Records Specialists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Patternmakers, Metal & Plastic 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical design Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Stress Tolerance Cooperation Independence Integrity 
Technology You might use software like this on the job: Computer aided design CAD software 3D Systems Geomagic Design X Autodesk AutoCAD Computer aided manufacturing CAM software Delcam PowerMILL Mastercam computer-aided design and manufacturing software Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) some college or high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $54,970 $35,840 $74,280 Check out my state Local Salary Info Find Jobs 
Explore More Fabric & Apparel Patternmakers Layout Workers, Metal & Plastic Model Makers, Metal & Plastic Patternmakers, Wood Tool & Die Makers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Patternmakers, Wood 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology design product and service development Business management customer service Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Ideas and Logic order or arrange things group things in different ways 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Initiative Cooperation Integrity Stress Tolerance 
Technology You might use software like this on the job: Computer aided design CAD software 3D Systems Geomagic Design X Autodesk AutoCAD Computer aided manufacturing CAM software Delcam PowerMILL Mastercam computer-aided design and manufacturing software Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $44,290 $31,730 $60,220 Check out my state Local Salary Info Find Jobs 
Explore More Cabinetmakers & Bench Carpenters Fabric & Apparel Patternmakers Layout Workers, Metal & Plastic Model Makers, Wood Patternmakers, Metal & Plastic You might like a career in one of these industries: Manufacturing 
##############################################
Title: Paving, Surfacing, & Tamping Equipment Operators 
Information:
Knowledge Engineering and Technology building and construction mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Independence Self Control Achievement/Effort 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Spreadsheet software Microsoft Excel Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $47,270 $33,980 $82,040 Check out my state Local Salary Info Find Jobs 
Explore More Cement Masons & Concrete Finishers Construction Laborers Operating Engineers & Other Construction Equipment Operators Pile Driver Operators Segmental Pavers You might like a career in one of these industries: Construction Government 
##############################################
Title: Payroll & Timekeeping Clerks 
Information:
Knowledge Business administrative services accounting and economics Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Cooperation Dependability Attention to Detail Self Control Independence 
Technology You might use software like this on the job: Time accounting software Kronos Workforce Payroll Kronos Workforce Timekeeper Presentation software Microsoft PowerPoint Enterprise resource planning ERP software Microsoft Dynamics Workday software 
Education Education: (rated 3 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $49,630 $34,220 $73,580 Check out my state Local Salary Info Find Jobs 
Explore More Billing & Posting Clerks Bookkeeping, Accounting, & Auditing Clerks First-Line Supervisors of Office & Administrative Support Workers Human Resources Assistants Office Clerks, General You might like a career in one of these industries: Professional, Science, & Technical Administration & Support Services Health & Counseling 
##############################################
Title: Pediatric Surgeons 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Medical software Epic Systems MEDITECH software Graphics or photo imaging software Computer imaging software Operating system software Microsoft Windows 
Education Education: (rated 5 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $239,200+ $80,890 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Dermatologists General Internal Medicine Physicians Ophthalmologists Orthopedic Surgeons Urologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Pediatricians, General 
Information:
Knowledge Health medicine and dentistry therapy and counseling Math and Science biology psychology Business customer service human resources (HR) Arts and Humanities English language 
Skills Basic Skills talking to others thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Attention pay attention to something without being distracted do two or more things at the same time Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Concern for Others Integrity Self Control Dependability Attention to Detail Persistence 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software MEDITECH software Information retrieval or search software Drug reference software Medical information databases Electronic mail software Email software 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $190,350 $78,230 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians Family Medicine Physicians General Internal Medicine Physicians Neurologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Penetration Testers 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Development environment software Go Oracle Java 2 Platform Enterprise Edition J2EE Operating system software Bash Shell script Transaction security and virus protection software Invicti Acunetix Metasploit 
Education Education: (rated 4 of 5) Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,740 $46,540 $166,780 Check out my state Local Salary Info Find Jobs 
Explore More Computer Systems Engineers/Architects Information Security Analysts Information Security Engineers Software Developers Software Quality Assurance Analysts & Testers You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Personal Care Aides 
Information:
Knowledge Arts and Humanities English language Business customer service Transportation movement of people or goods by air, rail, sea, or road Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Cooperation Stress Tolerance Attention to Detail Dependability Initiative 
Technology You might use software like this on the job: Electronic mail software Email software Voltage SecureMail Computer based training software Appletree Medical software MEDITECH software 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $30,180 $22,500 $38,350 Check out my state Local Salary Info Find Jobs 
Explore More Child, Family, & School Social Workers Home Health Aides Nursing Assistants Psychiatric Aides Social & Human Service Assistants You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Personal Financial Advisors 
Information:
Knowledge Business customer service accounting and economics Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics psychology Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Math add, subtract, multiply, or divide choose the right type of math to solve a problem Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Attention to Detail Analytical Thinking Concern for Others Achievement/Effort 
Technology You might use software like this on the job: Data base user interface and query software Microsoft SQL Server Structured query language SQL Presentation software Financial planning presentation software Microsoft PowerPoint Financial analysis software Oracle E-Business Suite Financials WealthTec Foundations 
Education Education: (rated 4 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $95,390 $46,700 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Credit Counselors Financial & Investment Analysts Financial Risk Specialists Loan Officers Securities, Commodities, & Financial Services Sales Agents You might like a career in one of these industries: Finance & Insurance 
##############################################
Title: Pest Control Workers 
Information:
Knowledge Business customer service Safety and Government public safety and security Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention do two or more things at the same time pay attention to something without being distracted Hearing and Speech speak clearly 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Dependability Attention to Detail Independence Cooperation Stress Tolerance 
Technology You might use software like this on the job: Word processing software Microsoft Word Report writing software Video creation and editing software YouTube Data base user interface and query software Database software Marathon Data Systems PestPac 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $38,310 $29,250 $58,970 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Landscaping, Lawn Service, & Groundskeeping Workers Hazardous Materials Removal Workers Landscaping & Groundskeeping Workers Pesticide Handlers, Sprayers, & Applicators, Vegetation Septic Tank Servicers & Sewer Pipe Cleaners You might like a career in one of these industries: Administration & Support Services 
##############################################
Title: Pesticide Handlers, Sprayers, & Applicators, Vegetation 
Information:
Knowledge Business customer service management Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Independence Cooperation Integrity Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Analytical or scientific software Rate calculation software Unit conversion software Geographic information system Geographic information system GIS systems 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $41,440 $29,270 $56,400 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Equipment Operators Farmworkers & Laborers, Crop, Nursery, & Greenhouse Landscaping & Groundskeeping Workers Pest Control Workers Water & Wastewater Treatment Plant & System Operators You might like a career in one of these industries: Administration & Support Services Wholesale/Commercial Sales Farming, Forestry, Fishing, & Hunting 
##############################################
Title: Petroleum Engineers 
Information:
Knowledge Engineering and Technology product and service development computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Business management accounting and economics Arts and Humanities English language 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Integrity Dependability Initiative Cooperation 
Technology You might use software like this on the job: Analytical or scientific software SAS The MathWorks MATLAB Presentation software Microsoft PowerPoint Object or component oriented development software C# R 
Education Education: (rated 4 of 5) bachelor's degree or certificate after master's usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $131,800 $76,960 $220,040 Check out my state Local Salary Info Find Jobs 
Explore More Chemical Engineers Geothermal Production Managers Industrial Engineers Mining & Geological Engineers, Including Mining Safety Engineers Water/Wastewater Engineers You might like a career in one of these industries: Mining, Oil, & Gas Management Professional, Science, & Technical 
##############################################
Title: Petroleum Pump System Operators, Refinery Operators, & Gaugers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Safety and Government public safety and security Engineering and Technology mechanical computers and electronics Business management 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns Attention pay attention to something without being distracted Ideas and Logic order or arrange things notice when problems happen Verbal communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Independence Integrity Analytical Thinking Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Email software Microsoft Outlook Industrial control software Programmable logic controller PLC software Supervisory control and data acquisition SCADA software 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $85,090 $52,920 $107,810 Check out my state Local Salary Info Find Jobs 
Explore More Chemical Plant & System Operators Gas Compressor & Gas Pumping Station Operators Gas Plant Operators Power Plant Operators Wellhead Pumpers You might like a career in one of these industries: Manufacturing Transportation & Storage Mining, Oil, & Gas 
##############################################
Title: Pharmacists 
Information:
Knowledge Health medicine and dentistry Math and Science arithmetic, algebra, geometry, calculus, or statistics chemistry Arts and Humanities English language Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen group things in different ways Math add, subtract, multiply, or divide choose the right type of math to solve a problem Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Concern for Others Dependability Stress Tolerance Cooperation Integrity 
Technology You might use software like this on the job: Medical software Epic Systems MEDITECH software Presentation software Microsoft PowerPoint Data base user interface and query software Healthprolink MedAtlas Recordkeeping software 
Education Education: (rated 5 of 5) doctoral degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $132,750 $79,950 $164,230 Check out my state Local Salary Info Find Jobs 
Explore More Emergency Medicine Physicians Family Medicine Physicians General Internal Medicine Physicians Nurse Practitioners Physician Assistants You might like a career in one of these industries: Retail Health & Counseling 
##############################################
Title: Pharmacy Aides 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Math choose the right type of math to solve a problem 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Self Control Cooperation Attention to Detail Stress Tolerance Dependability 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $33,270 $24,590 $50,180 Check out my state Local Salary Info Find Jobs 
Explore More Medical Assistants Medical Equipment Preparers Opticians, Dispensing Pharmacy Technicians Phlebotomists You might like a career in one of these industries: Retail Health & Counseling 
##############################################
Title: Pharmacy Technicians 
Information:
Knowledge Business customer service administrative services Health medicine and dentistry Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Hand and Finger Use put together small parts with your fingers hold or move items with your hands Attention do two or more things at the same time 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Cooperation Self Control Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software MEDITECH software Pharmaceutical software 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $37,790 $29,640 $50,640 Check out my state Local Salary Info Find Jobs 
Explore More Licensed Practical & Licensed Vocational Nurses Medical & Clinical Laboratory Technicians Medical Assistants Pharmacy Aides Phlebotomists You might like a career in one of these industries: Retail Health & Counseling 
##############################################
Title: Philosophy & Religion Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities philosophy and religion English language Education and Training teaching and course design Math and Science sociology and anthropology Safety and Government law and government 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Analytical Thinking Independence Dependability Integrity Achievement/Effort Initiative 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Moodle Data base user interface and query software InteLext Past Masters Philosopher's Information Center The Philosopher's Index 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $78,780 $46,940 $132,600 Check out my state Local Salary Info Find Jobs 
Explore More Anthropology & Archeology Teachers, Postsecondary Area, Ethnic, & Cultural Studies Teachers, Postsecondary English Language & Literature Teachers, Postsecondary History Teachers, Postsecondary Sociology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Phlebotomists 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Education and Training teaching and course design Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Concern for Others Dependability Integrity Stress Tolerance Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software Medical procedure coding software MEDITECH Laboratory and Microbiology Electronic mail software Microsoft Outlook 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $38,530 $30,250 $51,610 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Histology Technicians Histotechnologists Medical & Clinical Laboratory Technicians Medical & Clinical Laboratory Technologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Photographers 
Information:
Knowledge Business customer service sales and marketing Engineering and Technology computers and electronics Arts and Humanities English language music, dance, visual arts, drama, or sculpture Communications multimedia 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic create new and original ideas notice when problems happen 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Dependability Integrity Attention to Detail Concern for Others Self Control Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Cradoc fotoBiz Microsoft Access Video creation and editing software Adobe Systems Adobe After Effects YouTube Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Illustrator 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $40,170 $26,990 $82,450 Check out my state Local Salary Info Find Jobs 
Explore More Art Directors Fine Artists, Including Painters, Sculptors, & Illustrators Graphic Designers Photographic Process Workers & Processing Machine Operators Special Effects Artists & Animators You might like a career in one of these industries: Professional, Science, & Technical 
##############################################
Title: Photographic Process Workers & Processing Machine Operators 
Information:
Knowledge Business customer service Engineering and Technology computers and electronics Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use keep your arm or hand steady hold or move items with your hands Attention pay attention to something without being distracted Ideas and Logic order or arrange things 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Independence Self Control Cooperation Integrity 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access Structured query language SQL 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $36,280 $27,650 $66,150 Check out my state Local Salary Info Find Jobs 
Explore More Camera & Photographic Equipment Repairers Etchers & Engravers Office Machine Operators Prepress Technicians & Workers Printing Press Operators You might like a career in one of these industries: Professional, Science, & Technical Service Retail 
##############################################
Title: Photonics Engineers 
Information:
Knowledge Engineering and Technology product and service development computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information order or arrange things Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Dependability Innovation Integrity Achievement/Effort 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks Presentation software Microsoft PowerPoint Analytical or scientific software SAS The MathWorks MATLAB 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $104,600 $59,380 $168,320 Check out my state Local Salary Info Find Jobs 
Explore More Electrical & Electronic Engineering Technologists & Technicians Electronics Engineers Mechatronics Engineers Microsystems Engineers Photonics Technicians You might like a career in one of these industries: Professional, Science, & Technical Government Manufacturing 
##############################################
Title: Photonics Technicians 
Information:
Knowledge Engineering and Technology product and service development computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by writing listen and understand what people say Hand and Finger Use put together small parts with your fingers hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Analytical Thinking Initiative 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks Presentation software Microsoft PowerPoint Analytical or scientific software Data acquisition software The MathWorks MATLAB 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $65,520 $38,570 $105,120 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Calibration Technologists & Technicians Electrical & Electronic Engineering Technologists & Technicians Photonics Engineers Robotics Technicians You might like a career in one of these industries: Professional, Science, & Technical Government Manufacturing Administration & Support Services 
##############################################
Title: Physical Medicine & Rehabilitation Physicians 
Information:
Knowledge Health medicine and dentistry therapy and counseling Math and Science psychology biology Education and Training teaching and course design Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Attention to Detail Concern for Others Persistence Self Control Cooperation 
Technology You might use software like this on the job: Medical software Bizmatics PrognoCIS EMR eClinicalWorks EHR software Electronic mail software Email software Word processing software Microsoft Word 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $223,410 $63,970 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians General Internal Medicine Physicians Orthopedic Surgeons Pediatric Surgeons You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Physical Therapist Aides 
Information:
Knowledge Business customer service Arts and Humanities English language Engineering and Technology computers and electronics Health therapy and counseling 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Social looking for ways to help people changing what is done based on other people's actions 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use keep your arm or hand steady 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Cooperation Dependability Integrity Attention to Detail Adaptability/Flexibility 
Technology You might use software like this on the job: Medical software Epic Systems MEDITECH software Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $31,410 $22,380 $41,320 Check out my state Local Salary Info Find Jobs 
Explore More Occupational Therapy Aides Occupational Therapy Assistants Physical Therapist Assistants Psychiatric Aides Psychiatric Technicians You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Physical Therapist Assistants 
Information:
Knowledge Business customer service Health therapy and counseling medicine and dentistry Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted Hand and Finger Use keep your arm or hand steady 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Integrity Cooperation Self Control Concern for Others Attention to Detail 
Technology You might use software like this on the job: Data base user interface and query software FileMaker Pro Microsoft Access Presentation software Microsoft PowerPoint Medical software eClinicalWorks EHR software TherAssist 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $62,770 $43,340 $85,230 Check out my state Local Salary Info Find Jobs 
Explore More Occupational Therapy Aides Occupational Therapy Assistants Physical Therapist Aides Psychiatric Technicians Recreational Therapists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Physical Therapists 
Information:
Knowledge Business customer service management Health therapy and counseling medicine and dentistry Math and Science psychology biology Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Integrity Dependability Social Orientation Cooperation Self Control 
Technology You might use software like this on the job: Word processing software Exercise routine creation software Microsoft Word Medical software Clinicient Insight MEDITECH software Spreadsheet software Microsoft Excel 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $97,720 $67,910 $128,830 Check out my state Local Salary Info Find Jobs 
Explore More Advanced Practice Psychiatric Nurses Clinical Nurse Specialists Emergency Medicine Physicians Occupational Therapists Physical Medicine & Rehabilitation Physicians You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Physician Assistants 
Information:
Knowledge Health medicine and dentistry therapy and counseling Math and Science biology psychology Arts and Humanities English language Business customer service 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Attention to Detail Concern for Others Integrity Dependability Cooperation Stress Tolerance 
Technology You might use software like this on the job: Medical software Epic Systems MEDITECH software Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook 
Education Education: (rated 5 of 5) master's degree or professional degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $126,010 $83,820 $168,120 Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians Family Medicine Physicians Nurse Practitioners Pediatric Surgeons You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Physicians, Pathologists 
Information:
Knowledge Health medicine and dentistry Math and Science biology chemistry Arts and Humanities English language Business customer service 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by writing listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly know what you are looking at Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Dependability Integrity Achievement/Effort Stress Tolerance Analytical Thinking 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Presentation software Microsoft PowerPoint Medical software CPSI CPSI System Wyndgate Technologies ElDorado Donor 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $239,200+ $67,190 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Histotechnologists Medical & Clinical Laboratory Technologists Pediatric Surgeons Radiologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Physicists 
Information:
Knowledge Math and Science physics arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology product and service development computers and electronics Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills using scientific rules and strategies to solve problems reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal read and understand what is written communicate by speaking Ideas and Logic come up with lots of ideas create new and original ideas Visual Understanding quickly know what you are looking at 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Innovation Achievement/Effort Analytical Thinking Initiative Attention to Detail Integrity 
Technology You might use software like this on the job: Analytical or scientific software The MathWorks MATLAB Wolfram Research Mathematica Presentation software Microsoft PowerPoint Development environment software Eclipse IDE Microsoft Azure software 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $142,850 $74,760 $219,760 Check out my state Local Salary Info Find Jobs 
Explore More Astronomers Biochemists & Biophysicists Data Scientists Mathematicians Physics Teachers, Postsecondary You might like a career in one of these industries: Professional, Science, & Technical Government Education Health & Counseling 
##############################################
Title: Physics Teachers, Postsecondary 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Education and Training teaching and course design Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one 
Abilities Verbal communicate by speaking communicate by writing Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Analytical Thinking Integrity Achievement/Effort Attention to Detail Persistence 
Technology You might use software like this on the job: Analytical or scientific software The MathWorks MATLAB Wolfram Research Mathematica Word processing software Google Docs Microsoft Word Development environment software C National Instruments LabVIEW 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $86,550 $50,820 $166,570 Check out my state Local Salary Info Find Jobs 
Explore More Atmospheric, Earth, Marine, & Space Sciences Teachers, Postsecondary Biological Science Teachers, Postsecondary Chemistry Teachers, Postsecondary Engineering Teachers, Postsecondary Mathematical Science Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Pile Driver Operators 
Information:
Knowledge Engineering and Technology building and construction mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics Transportation movement of people or goods by air, rail, sea, or road Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use hold or move items with your hands keep your arm or hand steady Spatial imagine how something will look after it is moved around or changed know where things are around you Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Concern for Others Self Control Cooperation Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Analytical or scientific software GRL Engineers Wave Equation Analysis Program GRLWEAP Pile Dynamics Pile Driving Analyzer PDA Electronic mail software Email software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $64,310 $38,260 $107,580 Check out my state Local Salary Info Find Jobs 
Explore More Excavating & Loading Machine & Dragline Operators, Surface Mining Hoist & Winch Operators Industrial Truck & Tractor Operators Operating Engineers & Other Construction Equipment Operators Paving, Surfacing, & Tamping Equipment Operators You might like a career in one of these industries: Construction 
##############################################
Title: Pipelayers 
Information:
Knowledge Engineering and Technology building and construction mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government public safety and security Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say communicate by speaking Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Initiative Cooperation Persistence Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Office suite software Microsoft Office software Word processing software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $45,990 $34,180 $77,760 Check out my state Local Salary Info Find Jobs 
Explore More Construction Laborers Helpers--Pipelayers, Plumbers, Pipefitters, & Steamfitters Insulation Workers, Mechanical Plumbers, Pipefitters, & Steamfitters Septic Tank Servicers & Sewer Pipe Cleaners You might like a career in one of these industries: Construction Government 
##############################################
Title: Plasterers & Stucco Masons 
Information:
Knowledge Engineering and Technology building and construction design Business management customer service Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Self Control Cooperation Initiative 
Technology You might use software like this on the job: Operating system software Linux Microsoft operating system Graphics or photo imaging software Autodesk Maya Corel Painter Project management software Cost estimating software Sage Construction Anywhere 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $49,730 $34,880 $82,510 Check out my state Local Salary Info Find Jobs 
Explore More Drywall & Ceiling Tile Installers Helpers--Brickmasons, Blockmasons, Stonemasons, & Tile & Marble Setters Helpers--Painters, Paperhangers, Plasterers, & Stucco Masons Insulation Workers, Floor, Ceiling, & Wall Tile & Stone Setters You might like a career in one of these industries: Construction 
##############################################
Title: Plating Machine Setters, Operators, & Tenders, Metal & Plastic 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics chemistry Arts and Humanities English language Engineering and Technology mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Achievement/Effort Integrity Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Procurement software Oracle Advanced Procurement 
Education Education: (rated 2 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $37,900 $29,140 $53,560 Check out my state Local Salary Info Find Jobs 
Explore More Coating, Painting, & Spraying Machine Setters, Operators, & Tenders Extruding & Forming Machine Setters, Operators, & Tenders, Synthetic & Glass Fibers Heat Treating Equipment Setters, Operators, & Tenders, Metal & Plastic Molding, Coremaking, & Casting Machine Setters, Operators, & Tenders, Metal & Plastic Welding, Soldering, & Brazing Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Plumbers, Pipefitters, & Steamfitters 
Information:
Knowledge Engineering and Technology building and construction mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Business customer service management Safety and Government public safety and security 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements People and Technology Systems thinking about the pros and cons of different options and picking the best one Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers hold or move items with your hands Ideas and Logic notice when problems happen use rules to solve problems Physical Strength use your lower back and stomach exercise for a long time without your muscles getting tired Spatial imagine how something will look after it is moved around or changed 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Independence Leadership Achievement/Effort Analytical Thinking 
Technology You might use software like this on the job: Accounting software Bookkeeping software Intuit QuickBooks Analytical or scientific software Bentley Systems AutoPIPE Watter Hammer Software Hytran Computer aided design CAD software Drawing and drafting software ViziFlow 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $60,090 $37,250 $101,190 Check out my state Local Salary Info Find Jobs 
Explore More Boilermakers Control & Valve Installers & Repairers Helpers--Pipelayers, Plumbers, Pipefitters, & Steamfitters Pipelayers Septic Tank Servicers & Sewer Pipe Cleaners You might like a career in one of these industries: Construction 
##############################################
Title: Podiatrists 
Information:
Knowledge Health medicine and dentistry therapy and counseling Business customer service management Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills figuring out how to use new ideas or things listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly know what you are looking at Hand and Finger Use hold or move items with your hands 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Concern for Others Integrity Dependability Achievement/Effort Analytical Thinking 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Medical software Advantage Software Podiatry Advantage Quick Notes PDQ Podiatry Electronic mail software Email software 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $148,720 $60,280 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Dermatologists Oral & Maxillofacial Surgeons Orthopedic Surgeons Pediatric Surgeons Physical Medicine & Rehabilitation Physicians You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Poets, Lyricists & Creative Writers 
Information:
Knowledge Arts and Humanities English language Communications multimedia Math and Science psychology 
Skills Basic Skills writing things for co-workers or customers reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic come up with lots of ideas create new and original ideas 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Persistence Independence Initiative Innovation Achievement/Effort Attention to Detail 
Technology You might use software like this on the job: Word processing software Apple iWork Pages Microsoft Word Desktop communications software Skype Web page creation and editing software Facebook WordPress 
Education Education: (rated 4 of 5) bachelor's degree or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $73,150 $39,610 $161,260 Check out my state Local Salary Info Find Jobs 
Explore More Editors News Analysts, Reporters, & Journalists Producers & Directors Proofreaders & Copy Markers Writers & Authors You might like a career in one of these industries: Professional, Science, & Technical Media & Communication 
##############################################
Title: Police & Sheriff's Patrol Officers 
Information:
Knowledge Safety and Government law and government public safety and security Arts and Humanities English language Math and Science psychology geography Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly know what you are looking at Physical Strength use your lower back and stomach 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Self Control Stress Tolerance Attention to Detail Leadership 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Presentation software Microsoft PowerPoint Graphics or photo imaging software Computer aided composite drawing software SmugMug Flickr 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $65,790 $40,560 $104,100 Check out my state Local Salary Info Find Jobs 
Explore More Bailiffs Correctional Officers & Jailers Detectives & Criminal Investigators First-Line Supervisors of Police & Detectives Transit & Railroad Police You might like a career in one of these industries: Government 
##############################################
Title: Police Identification & Records Officers 
Information:
Knowledge Safety and Government law and government public safety and security Arts and Humanities English language Business customer service administrative services 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information order or arrange things Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Dependability Adaptability/Flexibility Initiative Stress Tolerance 
Technology You might use software like this on the job: Graphics or photo imaging software DesignWare 3D EyeWitness Digital Image Management Solutions Crime Scene Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access National Crime Information Center (NCIC) database 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $86,280 $47,990 $150,570 Check out my state Local Salary Info Find Jobs 
Explore More Coroners Detectives & Criminal Investigators Forensic Science Technicians Fraud Examiners, Investigators & Analysts Private Detectives & Investigators You might like a career in one of these industries: Government 
##############################################
Title: Political Science Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities English language history and archeology Safety and Government law and government Education and Training teaching and course design Math and Science sociology and anthropology 
Skills Basic Skills talking to others reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something changing what is done based on other people's actions 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Independence Analytical Thinking Achievement/Effort Persistence Stress Tolerance Initiative 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Moodle Analytical or scientific software Empirisoft MediaLab W-NOMINATE 
Education Education: (rated 5 of 5) doctoral degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $83,770 $49,300 $164,370 Check out my state Local Salary Info Find Jobs 
Explore More Economics Teachers, Postsecondary History Teachers, Postsecondary Law Teachers, Postsecondary Political Scientists Sociology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Political Scientists 
Information:
Knowledge Safety and Government law and government Arts and Humanities English language history and archeology Education and Training teaching and course design Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information figuring out how to use new ideas or things Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Achievement/Effort Initiative Persistence Attention to Detail Integrity 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics SAS Presentation software Microsoft PowerPoint Information retrieval or search software EBSCO Publishing Political Science Complete Sage Reference Online 
Education Education: (rated 5 of 5) doctoral degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $128,020 $65,200 $176,280 Check out my state Local Salary Info Find Jobs 
Explore More Economists Law Teachers, Postsecondary Political Science Teachers, Postsecondary Sociologists Sociology Teachers, Postsecondary You might like a career in one of these industries: Government Professional, Science, & Technical Service 
##############################################
Title: Postal Service Clerks 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use hold or move items with your hands keep your arm or hand steady Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Dependability Integrity Attention to Detail Cooperation Self Control Stress Tolerance 
Technology You might use software like this on the job: Operating system software Microsoft Windows Accounting software Budgeting software Point of sale POS software NCR Advanced Store 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $56,200 $39,770 $69,620 Check out my state Local Salary Info Find Jobs 
Explore More Mail Clerks & Mail Machine Operators Order Clerks Postal Service Mail Carriers Postal Service Mail Sorters, Processors, & Processing Machine Operators Shipping, Receiving, & Inventory Clerks You might like a career in one of these industries: Government 
##############################################
Title: Postal Service Mail Carriers 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Dependability Attention to Detail Integrity Independence Self Control Stress Tolerance 
Technology You might use software like this on the job: Office suite software Microsoft Office software Data base user interface and query software Address Management System AMS End of Run Report EOR Enterprise resource planning ERP software Delivery operations information system DOIS 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $54,250 $39,640 $71,340 Check out my state Local Salary Info Find Jobs 
Explore More Cargo & Freight Agents Couriers & Messengers Mail Clerks & Mail Machine Operators Postal Service Clerks Shipping, Receiving, & Inventory Clerks You might like a career in one of these industries: Government 
##############################################
Title: Postal Service Mail Sorters, Processors, & Processing Machine Operators 
Information:
Knowledge Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Verbal read and understand what is written communicate by speaking 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Dependability Cooperation Self Control Stress Tolerance 
Technology You might use software like this on the job: Enterprise resource planning ERP software Delivery operations information system DOIS SAP software Data base user interface and query software Address Management System AMS Directory software Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $49,130 $38,580 $68,310 Check out my state Local Salary Info Find Jobs 
Explore More Laborers & Freight, Stock, & Material Movers, Hand Mail Clerks & Mail Machine Operators Postal Service Clerks Shipping, Receiving, & Inventory Clerks Stockers & Order Fillers You might like a career in one of these industries: Government 
##############################################
Title: Postmasters & Mail Superintendents 
Information:
Knowledge Business management customer service Safety and Government public safety and security law and government Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Social changing what is done based on other people's actions understanding people's reactions Resource Management managing your time and the time of other people selecting and managing the best workers for a job 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Adaptability/Flexibility Integrity Leadership Self Control Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software Collection Point Management System CPMS Web Box Activity Tracing System WebBATS 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $82,760 $74,900 $100,340 Check out my state Local Salary Info Find Jobs 
Explore More Administrative Services Managers Dispatchers First-Line Supervisors of Office & Administrative Support Workers Postal Service Clerks Transportation, Storage, & Distribution Managers You might like a career in one of these industries: Government 
##############################################
Title: Potters, Manufacturing 
Information:
Knowledge Arts and Humanities music, dance, visual arts, drama, or sculpture Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology design product and service development Business customer service 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Ideas and Logic create new and original ideas group things in different ways 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Independence Initiative Integrity Persistence Achievement/Effort 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Inventory management software Inventory control software 
Education Education: (rated 3 of 5) some college or high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $39,590 $30,040 $56,470 Check out my state Local Salary Info Find Jobs 
Explore More Craft Artists Grinding & Polishing Workers, Hand Jewelers & Precious Stone & Metal Workers Molders, Shapers, & Casters Painting, Coating, & Decorating Workers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Pourers & Casters, Metal 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical Education and Training teaching and course design Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Integrity Persistence Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $45,070 $33,230 $66,120 Check out my state Local Salary Info Find Jobs 
Explore More Forging Machine Setters, Operators, & Tenders, Metal & Plastic Grinding & Polishing Workers, Hand Metal-Refining Furnace Operators & Tenders Molders, Shapers, & Casters Plating Machine Setters, Operators, & Tenders, Metal & Plastic You might like a career in one of these industries: Manufacturing 
##############################################
Title: Power Distributors & Dispatchers 
Information:
Knowledge Safety and Government public safety and security Arts and Humanities English language Engineering and Technology product and service development computers and electronics Communications telecommunications 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Stress Tolerance Self Control Integrity Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Industrial control software Supervisory control and data acquisition SCADA software Wide area monitoring system WAMS software 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $101,650 $66,090 $135,620 Check out my state Local Salary Info Find Jobs 
Explore More Electrical & Electronics Repairers, Powerhouse, Substation, & Relay Hydroelectric Plant Technicians Petroleum Pump System Operators, Refinery Operators, & Gaugers Power Plant Operators Stationary Engineers & Boiler Operators You might like a career in one of these industries: Utilities Government Management 
##############################################
Title: Power Plant Operators 
Information:
Knowledge Engineering and Technology mechanical Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted do two or more things at the same time 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Stress Tolerance Self Control Adaptability/Flexibility Independence 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Industrial control software Distributed control system DCS Yokogawa FAST/TOOLS 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $93,060 $52,000 $119,880 Check out my state Local Salary Info Find Jobs 
Explore More Biomass Plant Technicians Geothermal Technicians Hydroelectric Plant Technicians Petroleum Pump System Operators, Refinery Operators, & Gaugers Stationary Engineers & Boiler Operators You might like a career in one of these industries: Utilities Government 
##############################################
Title: Precision Agriculture Technicians 
Information:
Knowledge Business customer service sales and marketing Engineering and Technology computers and electronics product and service development Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Attention to Detail Adaptability/Flexibility Persistence Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Ag Leader Technology SMS Advanced Microsoft Access Presentation software Microsoft PowerPoint Analytical or scientific software Farm Works Site Pro MapShots EASi Suite 
Education Education: (rated 3 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $41,760 $29,900 $63,720 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Engineers Agricultural Technicians Conservation Scientists Industrial Ecologists Soil & Plant Scientists You might like a career in one of these industries: Professional, Science, & Technical Education Farming, Forestry, Fishing, & Hunting 
##############################################
Title: Prepress Technicians & Workers 
Information:
Knowledge Engineering and Technology computers and electronics design Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Initiative Stress Tolerance Independence 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop Presentation software Microsoft PowerPoint Desktop publishing software Adobe Systems Adobe InDesign QuarkXPress 
Education Education: (rated 3 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $43,560 $29,580 $62,270 Check out my state Local Salary Info Find Jobs 
Explore More Etchers & Engravers Office Machine Operators Photographic Process Workers & Processing Machine Operators Print Binding & Finishing Workers Printing Press Operators You might like a career in one of these industries: Manufacturing 
##############################################
Title: Preschool Teachers 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language Safety and Government public safety and security Business customer service 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social teaching people how to do something changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen create new and original ideas 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Self Control Dependability Concern for Others Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Computer based training software Padlet Schoology Desktop communications software Edmodo Tadpoles 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $35,330 $23,920 $58,580 Check out my state Local Salary Info Find Jobs 
Explore More Elementary School Teachers Kindergarten Teachers Special Education Teachers, Elementary School Special Education Teachers, Kindergarten Special Education Teachers, Preschool You might like a career in one of these industries: Health & Counseling Education Service 
##############################################
Title: Pressers, Textile, Garment, & Related Materials 
Information:
Knowledge Business customer service Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Independence Stress Tolerance Innovation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Email software Word processing software Microsoft Word 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $29,690 $22,520 $37,760 Check out my state Local Salary Info Find Jobs 
Explore More Cutters & Trimmers, Hand Laundry & Dry-Cleaning Workers Machine Feeders & Offbearers Paper Goods Machine Setters, Operators, & Tenders Sewing Machine Operators You might like a career in one of these industries: Service 
##############################################
Title: Preventive Medicine Physicians 
Information:
Knowledge Health medicine and dentistry therapy and counseling Math and Science biology psychology Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Visual Understanding see hidden patterns quickly know what you are looking at Math choose the right type of math to solve a problem 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Concern for Others Analytical Thinking Achievement/Effort Attention to Detail Cooperation 
Technology You might use software like this on the job: Analytical or scientific software SAS The MathWorks MATLAB Presentation software Microsoft PowerPoint Data base user interface and query software Database software Microsoft Access 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $223,410 $63,970 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians Family Medicine Physicians General Internal Medicine Physicians Pediatricians, General You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Print Binding & Finishing Workers 
Information:
Knowledge Business management customer service Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers hold or move items with your hands Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Independence Self Control Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Email software Microsoft Outlook Label making software Label printing software 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $36,970 $27,220 $51,660 Check out my state Local Salary Info Find Jobs 
Explore More Adhesive Bonding Machine Operators & Tenders Cutting & Slicing Machine Setters, Operators, & Tenders Machine Feeders & Offbearers Paper Goods Machine Setters, Operators, & Tenders Sewing Machine Operators You might like a career in one of these industries: Manufacturing 
##############################################
Title: Printing Press Operators 
Information:
Knowledge Engineering and Technology mechanical Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal communicate by speaking listen and understand what people say Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Integrity Stress Tolerance Cooperation 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop Desktop publishing software Adobe Systems Adobe InDesign QuarkXPress Document management software Adobe Systems Adobe Acrobat Adobe Systems Adobe LifeCycle Production Print ES3 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $39,350 $28,760 $59,980 Check out my state Local Salary Info Find Jobs 
Explore More Adhesive Bonding Machine Operators & Tenders Cutting, Punching, & Press Machine Setters, Operators, & Tenders, Metal & Plastic Paper Goods Machine Setters, Operators, & Tenders Photographic Process Workers & Processing Machine Operators Prepress Technicians & Workers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Private Detectives & Investigators 
Information:
Knowledge Arts and Humanities English language Business customer service administrative services Safety and Government law and government public safety and security Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Attention pay attention to something without being distracted Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Attention to Detail Dependability Independence Initiative Persistence 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Operating system software Microsoft operating system Microsoft Windows 
Education Education: (rated 3 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $52,120 $33,710 $92,660 Check out my state Local Salary Info Find Jobs 
Explore More Detectives & Criminal Investigators Fraud Examiners, Investigators & Analysts Intelligence Analysts Police Identification & Records Officers Retail Loss Prevention Specialists You might like a career in one of these industries: Administration & Support Services Professional, Science, & Technical Finance & Insurance Government Retail 
##############################################
Title: Probation Officers & Correctional Treatment Specialists 
Information:
Knowledge Safety and Government law and government public safety and security Arts and Humanities English language Math and Science psychology sociology and anthropology Health therapy and counseling 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions bringing people together to solve differences 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Self Control Dependability Stress Tolerance Attention to Detail Concern for Others 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Office suite software Corel WordPerfect Office Suite Microsoft Office software 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $59,860 $38,550 $101,080 Check out my state Local Salary Info Find Jobs 
Explore More Child, Family, & School Social Workers Correctional Officers & Jailers First-Line Supervisors of Correctional Officers Mental Health & Substance Abuse Social Workers Rehabilitation Counselors You might like a career in one of these industries: Government 
##############################################
Title: Procurement Clerks 
Information:
Knowledge Arts and Humanities English language Business administrative services customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Dependability Attention to Detail Integrity Cooperation Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Enterprise resource planning ERP software Oracle PeopleSoft SAP software Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access Oracle Database 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $45,240 $32,450 $62,280 Check out my state Local Salary Info Find Jobs 
Explore More Order Clerks Production, Planning, & Expediting Clerks Shipping, Receiving, & Inventory Clerks Stockers & Order Fillers Wholesale & Retail Buyers You might like a career in one of these industries: Government Manufacturing Wholesale/Commercial Sales Health & Counseling 
##############################################
Title: Producers & Directors 
Information:
Knowledge Communications multimedia telecommunications Arts and Humanities English language Engineering and Technology computers and electronics Business management 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems notice when problems happen Visual Understanding see hidden patterns quickly know what you are looking at Attention do two or more things at the same time 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Video creation and editing software Adobe Systems Adobe After Effects YouTube Web page creation and editing software Facebook LinkedIn Music or sound editing software Adobe Systems Adobe Audition Magix Vegas Pro 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $85,320 $42,140 $179,930 Check out my state Local Salary Info Find Jobs 
Explore More Art Directors Film & Video Editors Media Programming Directors Media Technical Directors/Managers Talent Directors You might like a career in one of these industries: Media & Communication Arts & Entertainment 
##############################################
Title: Production, Planning, & Expediting Clerks 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Business customer service administrative services Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic order or arrange things notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Self Control Cooperation Independence Integrity 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Data base user interface and query software Airtable Oracle Database Materials requirements planning logistics and supply chain software Bill of lading software Waterloo Hydrogeologic TACTIC 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $50,630 $35,030 $79,260 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Production & Operating Workers Project Management Specialists Shipping, Receiving, & Inventory Clerks Weighers, Measurers, Checkers, & Samplers, Recordkeeping You might like a career in one of these industries: Manufacturing Health & Counseling 
##############################################
Title: Project Management Specialists 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Project management software Atlassian Confluence Microsoft Teams Video creation and editing software Flipgrid YouTube 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $95,370 $52,500 $159,150 Check out my state Local Salary Info Find Jobs 
Explore More Administrative Services Managers Computer & Information Systems Managers Information Technology Project Managers Logisticians Management Analysts You might like a career in one of these industries: Professional, Science, & Technical Construction 
##############################################
Title: Proofreaders & Copy Markers 
Information:
Knowledge Arts and Humanities English language Communications multimedia Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information writing things for co-workers or customers Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Integrity Independence Cooperation Persistence 
Technology You might use software like this on the job: Word processing software Microsoft Word WhiteSmoke Computer based training software Adobe Systems Adobe Captivate InScribe Desktop publishing software Adobe Systems Adobe InDesign QuarkXPress 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $45,410 $29,350 $72,660 Check out my state Local Salary Info Find Jobs 
Explore More Desktop Publishers Editors File Clerks Technical Writers Word Processors & Typists You might like a career in one of these industries: Media & Communication Professional, Science, & Technical Administration & Support Services 
##############################################
Title: Property, Real Estate, & Community Association Managers 
Information:
Knowledge Business customer service management Arts and Humanities English language Safety and Government law and government public safety and security Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills talking to others reading work related information Social changing what is done based on other people's actions bringing people together to solve differences Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Attention to Detail Stress Tolerance Initiative Leadership 
Technology You might use software like this on the job: Data base user interface and query software Propertyware Yardi software Word processing software Google Docs Microsoft Word Accounting software Intuit QuickBooks Sage 50 Accounting 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $60,670 $35,210 $129,900 Check out my state Local Salary Info Find Jobs 
Explore More Appraisers & Assessors of Real Estate Facilities Managers General & Operations Managers Real Estate Brokers Real Estate Sales Agents You might like a career in one of these industries: Real Estate & Rentals 
##############################################
Title: Prosthodontists 
Information:
Knowledge Health medicine and dentistry Business customer service management Math and Science psychology chemistry Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use put together small parts with your fingers hold or move items with your hands Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Dependability Integrity Self Control Persistence Stress Tolerance 
Technology You might use software like this on the job: Medical software Henry Schein Dentrix Practice-Web Dental Graphics or photo imaging software Image management software 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $159,530 $71,460 $239,200+ Local Salary Info Find Jobs 
Explore More Dentists, General Oral & Maxillofacial Surgeons Orthodontists Orthopedic Surgeons Pediatric Surgeons You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Psychiatric Aides 
Information:
Knowledge Math and Science psychology sociology and anthropology Business customer service administrative services Arts and Humanities English language Health therapy and counseling 
Skills Basic Skills talking to others keeping track of how well people and/or groups are doing in order to make improvements Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Stress Tolerance Self Control Cooperation Dependability Social Orientation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 2 of 5) some college or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $37,160 $25,900 $52,050 Check out my state Local Salary Info Find Jobs 
Explore More Home Health Aides Nursing Assistants Occupational Therapy Aides Physical Therapist Aides Psychiatric Technicians You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Psychiatric Technicians 
Information:
Knowledge Math and Science psychology sociology and anthropology Arts and Humanities English language Health therapy and counseling medicine and dentistry Business customer service 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social understanding people's reactions changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Self Control Concern for Others Stress Tolerance Cooperation Integrity 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software GE Healthcare Centricity EMR Netsmart Technologies Avatar Clinical Workstation CWS Electronic mail software Microsoft Outlook 
Education Education: (rated 3 of 5) some college or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $37,380 $28,660 $58,880 Check out my state Local Salary Info Find Jobs 
Explore More Occupational Therapy Assistants Physical Therapist Aides Physical Therapist Assistants Psychiatric Aides Recreational Therapists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Psychiatrists 
Information:
Knowledge Health therapy and counseling medicine and dentistry Math and Science psychology biology Arts and Humanities English language philosophy and religion Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Attention pay attention to something without being distracted Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Concern for Others Integrity Dependability Stress Tolerance Attention to Detail Self Control 
Technology You might use software like this on the job: Medical software Epic Systems MEDITECH software Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $226,880 $66,230 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Advanced Practice Psychiatric Nurses Clinical Neuropsychologists Family Medicine Physicians Neurologists Neuropsychologists You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Psychology Teachers, Postsecondary 
Information:
Knowledge Math and Science psychology sociology and anthropology Arts and Humanities English language Education and Training teaching and course design Health therapy and counseling 
Skills Basic Skills using the best training or teaching strategies for learning new things reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Achievement/Effort Dependability Independence Persistence Initiative Integrity 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Computer based training software Learning management system LMS Moodle Word processing software Google Docs Microsoft Word 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $78,810 $47,270 $136,680 Check out my state Local Salary Info Find Jobs 
Explore More Anthropology & Archeology Teachers, Postsecondary Education Teachers, Postsecondary Health Specialties Teachers, Postsecondary Social Work Teachers, Postsecondary Sociology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Public Relations Managers 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Video creation and editing software Adobe Systems Adobe After Effects YouTube Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Illustrator Desktop publishing software Adobe Systems Adobe InDesign Microsoft Publisher 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $129,430 $71,710 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Advertising & Promotions Managers Management Analysts Market Research Analysts & Marketing Specialists Marketing Managers Public Relations Specialists You might like a career in one of these industries: Professional, Science, & Technical Service Education 
##############################################
Title: Public Relations Specialists 
Information:
Knowledge Communications multimedia Arts and Humanities English language Business customer service management Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Integrity Initiative Dependability Persistence Adaptability/Flexibility 
Technology You might use software like this on the job: Web page creation and editing software Facebook WordPress Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software JamBoard Web platform development software Cascading style sheets CSS Drupal 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $67,440 $38,630 $128,450 Check out my state Local Salary Info Find Jobs 
Explore More Advertising & Promotions Managers Fundraising Managers Market Research Analysts & Marketing Specialists Public Relations Managers Writers & Authors You might like a career in one of these industries: Service Professional, Science, & Technical Education Government 
##############################################
Title: Public Safety Telecommunicators 
Information:
Knowledge Safety and Government public safety and security law and government Arts and Humanities English language Communications telecommunications multimedia Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted do two or more things at the same time Ideas and Logic notice when problems happen order or arrange things Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Self Control Stress Tolerance Attention to Detail Integrity Dependability Cooperation 
Technology You might use software like this on the job: Office suite software Corel WordPerfect Office Suite Microsoft Office software Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access National Law Enforcement Telecommunications System NLETS 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $46,900 $31,090 $70,730 Check out my state Local Salary Info Find Jobs 
Explore More Dispatchers Emergency Medical Technicians First-Line Supervisors of Security Workers Paramedics Switchboard Operators, Including Answering Service You might like a career in one of these industries: Government 
##############################################
Title: Pump Operators 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Engineering and Technology mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Independence Dependability Cooperation Achievement/Effort Analytical Thinking 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Facilities management software Computerized maintenance management system CMMS 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $55,860 $36,700 $85,430 Check out my state Local Salary Info Find Jobs 
Explore More Gas Compressor & Gas Pumping Station Operators Petroleum Pump System Operators, Refinery Operators, & Gaugers Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders Stationary Engineers & Boiler Operators Wellhead Pumpers You might like a career in one of these industries: Mining, Oil, & Gas Construction Transportation & Storage Wholesale/Commercial Sales 
##############################################
Title: Purchasing Agents 
Information:
Knowledge Arts and Humanities English language Business management customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government law and government 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social bringing people together to solve differences talking people into changing their minds or their behavior 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Dependability Stress Tolerance Analytical Thinking Initiative 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Word processing software Google Docs Microsoft Word Data base user interface and query software Microsoft Access Oracle Database 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $67,620 $41,060 $116,370 Check out my state Local Salary Info Find Jobs 
Explore More Buyers & Purchasing Agents, Farm Products Logistics Analysts Procurement Clerks Purchasing Managers Wholesale & Retail Buyers You might like a career in one of these industries: Manufacturing Wholesale/Commercial Sales Government 
##############################################
Title: Purchasing Managers 
Information:
Knowledge Business management customer service Arts and Humanities English language Safety and Government law and government Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social bringing people together to solve differences understanding people's reactions Resource Management selecting and managing the best workers for a job managing your time and the time of other people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic come up with lots of ideas use rules to solve problems Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Attention to Detail Cooperation Dependability Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Presentation software Microsoft PowerPoint Procurement software Ariba Spend Management Suite PurchasingNet eProcurement 
Education Education: (rated 4 of 5) bachelor's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $131,350 $80,000 $210,000 Check out my state Local Salary Info Find Jobs 
Explore More Logisticians Logistics Analysts Procurement Clerks Purchasing Agents Wholesale & Retail Buyers You might like a career in one of these industries: Manufacturing Management Government Wholesale/Commercial Sales 
##############################################
Title: Quality Control Analysts 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics chemistry Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Business administrative services 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it 
Abilities Verbal read and understand what is written communicate by writing Ideas and Logic notice when problems happen use rules to solve problems Math choose the right type of math to solve a problem Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Adaptability/Flexibility Analytical Thinking Dependability Cooperation 
Technology You might use software like this on the job: Program testing software Hewlett Packard LoadRunner Selenium Desktop communications software Eko Data base user interface and query software Microsoft SQL Server Structured query language SQL 
Education Education: (rated 3 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $50,290 $33,270 $90,760 Check out my state Local Salary Info Find Jobs 
Explore More Calibration Technologists & Technicians Chemical Technicians Industrial Engineers Quality Control Systems Managers Validation Engineers You might like a career in one of these industries: Education Professional, Science, & Technical Administration & Support Services Government 
##############################################
Title: Quality Control Systems Managers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Education and Training teaching and course design Math and Science chemistry 
Skills Basic Skills reading work related information keeping track of how well people and/or groups are doing in order to make improvements People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Attention to Detail Initiative Leadership Cooperation Analytical Thinking 
Technology You might use software like this on the job: Data base user interface and query software Microsoft SQL Server Structured query language SQL Desktop communications software Eko Analytical or scientific software Minitab Thermo Fisher Scientific Laboratory Information Management Systems LIMS 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $107,560 $67,720 $178,470 Check out my state Local Salary Info Find Jobs 
Explore More Industrial Engineers Industrial Production Managers Quality Control Analysts Software Quality Assurance Analysts & Testers Validation Engineers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Radiation Therapists 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Health medicine and dentistry therapy and counseling Math and Science physics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen order or arrange things Hand and Finger Use keep your arm or hand steady hold or move items with your hands Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Attention to Detail Concern for Others Dependability Cooperation Integrity Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software eClinicalWorks EHR software Sun Nuclear MapCHECK Graphics or photo imaging software Image processing software 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $89,530 $65,760 $133,260 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Medical Dosimetrists Nuclear Medicine Technologists Radiologic Technologists & Technicians Respiratory Therapists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Radio Frequency Identification Device Specialists 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Arts and Humanities English language Business customer service management Education and Training teaching and course design 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Adaptability/Flexibility Analytical Thinking Cooperation Dependability Achievement/Effort 
Technology You might use software like this on the job: Program testing software JUnit Selenium Presentation software Microsoft PowerPoint Development environment software Microsoft Visual Studio Ruby 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $108,170 $74,880 $171,430 Check out my state Local Salary Info Find Jobs 
Explore More Computer Hardware Engineers Electrical & Electronic Engineering Technologists & Technicians Electronics Engineers Microsystems Engineers Software Developers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical Media & Communication Government 
##############################################
Title: Radio, Cellular, & Tower Equipment Installers & Repairers 
Information:
Knowledge Engineering and Technology computers and electronics mechanical Communications telecommunications multimedia Business customer service management Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Integrity Cooperation Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Analytical or scientific software AERONET calculator Zoho WebNMS Cell Tower Manager 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $59,720 $37,290 $93,840 Check out my state Local Salary Info Find Jobs 
Explore More Audiovisual Equipment Installers & Repairers Avionics Technicians Electrical & Electronics Repairers, Commercial & Industrial Equipment Radio Frequency Identification Device Specialists Telecommunications Equipment Installers & Repairers You might like a career in one of these industries: Media & Communication Construction Retail Government 
##############################################
Title: Radiologic Technologists & Technicians 
Information:
Knowledge Health medicine and dentistry Arts and Humanities English language Business customer service administrative services Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Social understanding people's reactions looking for ways to help people 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Concern for Others Dependability Integrity Cooperation Self Control Attention to Detail 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software MEDITECH software Virtual reality computed tomography CT imaging software 
Education Education: (rated 3 of 5) associate's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $65,140 $47,760 $97,940 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Diagnostic Medical Sonographers Magnetic Resonance Imaging Technologists Neurodiagnostic Technologists Radiation Therapists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Radiologists 
Information:
Knowledge Health medicine and dentistry Math and Science biology physics Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Integrity Analytical Thinking Concern for Others Dependability Stress Tolerance 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software MEDITECH software Spreadsheet software Microsoft Excel Electronic mail software Email software 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $239,200+ $77,570 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians General Internal Medicine Physicians Orthopedic Surgeons Pediatric Surgeons You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Rail Car Repairers 
Information:
Knowledge Engineering and Technology mechanical design Manufactured or Agricultural Goods manufacture and distribution of products Business management Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Technical figuring out what is causing equipment, machines, wiring, or computer programs to not work repairing machines or systems using the right tools 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Adaptability/Flexibility Attention to Detail Independence Innovation Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Internet browser software Microsoft Internet Explorer Mozilla Firefox 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $62,510 $38,320 $84,330 Check out my state Local Salary Info Find Jobs 
Explore More Automotive Service Technicians & Mechanics Bus & Truck Mechanics & Diesel Engine Specialists Engine & Other Machine Assemblers Maintenance Workers, Machinery Mobile Heavy Equipment Mechanics You might like a career in one of these industries: Transportation & Storage Government 
##############################################
Title: Rail Yard Engineers, Dinkey Operators, & Hostlers 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Safety and Government public safety and security Business management customer service Engineering and Technology mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat quickly decide if you should move your hand, foot, or other body part Verbal listen and understand what people say communicate by speaking Hand and Finger Use hold or move items with your hands keep your arm or hand steady Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Adaptability/Flexibility Cooperation Concern for Others Self Control 
Technology You might use software like this on the job: Inventory management software Railyard inventory software Softrail AEI Rail & Road Manager Industrial control software RailComm DocYard Softrail AEI Automatic Yard Tracking System Expert system software Positive train control PTC systems 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $56,340 $35,340 $83,390 Check out my state Local Salary Info Find Jobs 
Explore More Bus & Truck Mechanics & Diesel Engine Specialists Locomotive Engineers Operating Engineers & Other Construction Equipment Operators Railroad Brake, Signal, & Switch Operators & Locomotive Firers Railroad Conductors & Yardmasters You might like a career in one of these industries: Transportation & Storage 
##############################################
Title: Rail-Track Laying & Maintenance Equipment Operators 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Engineering and Technology mechanical building and construction Safety and Government public safety and security Business management 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Spatial imagine how something will look after it is moved around or changed 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Concern for Others Cooperation Integrity Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Office suite software Microsoft Office software Time accounting software Timekeeping software 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $63,230 $39,000 $84,460 Check out my state Local Salary Info Find Jobs 
Explore More Millwrights Mobile Heavy Equipment Mechanics Operating Engineers & Other Construction Equipment Operators Paving, Surfacing, & Tamping Equipment Operators Pile Driver Operators You might like a career in one of these industries: Transportation & Storage Government Construction 
##############################################
Title: Railroad Brake, Signal, & Switch Operators & Locomotive Firers 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Safety and Government public safety and security Engineering and Technology mechanical Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Attention pay attention to something without being distracted Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Stress Tolerance Self Control Adaptability/Flexibility Independence 
Technology You might use software like this on the job: Expert system software Electronic train management systems ETMS Route navigation software Route mapping software Time accounting software Time tracking software 
Education Education: (rated 2 of 5) Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $67,540 $43,830 $79,580 Check out my state Local Salary Info Find Jobs 
Explore More Bus & Truck Mechanics & Diesel Engine Specialists Hoist & Winch Operators Locomotive Engineers Rail Yard Engineers, Dinkey Operators, & Hostlers Railroad Conductors & Yardmasters You might like a career in one of these industries: Transportation & Storage 
##############################################
Title: Railroad Conductors & Yardmasters 
Information:
Knowledge Safety and Government public safety and security law and government Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Attention pay attention to something without being distracted do two or more things at the same time Hearing and Speech recognize spoken words 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Dependability Integrity Self Control Independence Cooperation 
Technology You might use software like this on the job: Office suite software Microsoft Office software Presentation software Microsoft PowerPoint Industrial control software RailComm DocYard SAIC government services and IT support software 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $68,180 $49,680 $88,230 Check out my state Local Salary Info Find Jobs 
Explore More Dispatchers Locomotive Engineers Rail Yard Engineers, Dinkey Operators, & Hostlers Railroad Brake, Signal, & Switch Operators & Locomotive Firers Subway & Streetcar Operators You might like a career in one of these industries: Transportation & Storage Government 
##############################################
Title: Range Managers 
Information:
Knowledge Math and Science biology geography Arts and Humanities English language Safety and Government law and government public safety and security Business administrative services 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking read and understand what is written Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Initiative Integrity Cooperation Independence Adaptability/Flexibility 
Technology You might use software like this on the job: Analytical or scientific software SAS The MathWorks MATLAB Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access National Resources Conservation Service Ecological Site Information System ESIS 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $64,460 $41,360 $102,670 Check out my state Local Salary Info Find Jobs 
Explore More Conservation Scientists Environmental Restoration Planners Foresters Industrial Ecologists Soil & Plant Scientists You might like a career in one of these industries: Government Service 
##############################################
Title: Real Estate Brokers 
Information:
Knowledge Business sales and marketing customer service Arts and Humanities English language Safety and Government law and government Engineering and Technology computers and electronics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social bringing people together to solve differences talking people into changing their minds or their behavior Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems notice when problems happen 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Initiative Stress Tolerance Attention to Detail Persistence Achievement/Effort 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Yardi software Presentation software Microsoft PowerPoint Internet browser software Microsoft Internet Explorer Web browser software 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $62,190 $36,360 $173,000 Check out my state Local Salary Info Find Jobs 
Explore More Appraisers & Assessors of Real Estate Appraisers of Personal & Business Property Property, Real Estate, & Community Association Managers Real Estate Sales Agents Securities, Commodities, & Financial Services Sales Agents You might like a career in one of these industries: Real Estate & Rentals 
##############################################
Title: Real Estate Sales Agents 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language Safety and Government law and government Communications multimedia 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social bringing people together to solve differences changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems group things in different ways 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Stress Tolerance Initiative Achievement/Effort Attention to Detail 
Technology You might use software like this on the job: Data base user interface and query software Xactware Xactimate Yardi software Word processing software Google Docs Microsoft Word Financial analysis software RealData Comparative Lease Analysis Wheatworks Real Estate Calculator Suite 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $49,980 $29,130 $113,320 Check out my state Local Salary Info Find Jobs 
Explore More Appraisers & Assessors of Real Estate Appraisers of Personal & Business Property Property, Real Estate, & Community Association Managers Real Estate Brokers Sales Representatives of Services You might like a career in one of these industries: Real Estate & Rentals 
##############################################
Title: Receptionists & Information Clerks 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Self Control Dependability Stress Tolerance Concern for Others Cooperation 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Word processing software Google Docs Microsoft Word Data base user interface and query software Claim processing system software FileMaker Pro 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $33,960 $24,350 $46,220 Check out my state Local Salary Info Find Jobs 
Explore More Customer Service Representatives Medical Secretaries & Administrative Assistants Office Clerks, General Secretaries & Administrative Assistants Switchboard Operators, Including Answering Service You might like a career in one of these industries: Health & Counseling Professional, Science, & Technical Service 
##############################################
Title: Recreation & Fitness Studies Teachers, Postsecondary 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language Engineering and Technology computers and electronics Math and Science psychology 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Initiative Integrity Persistence Self Control Analytical Thinking 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Softworks Global PESoftOne Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $72,650 $39,920 $133,490 Check out my state Local Salary Info Find Jobs 
Explore More Education Teachers, Postsecondary Environmental Science Teachers, Postsecondary Family & Consumer Sciences Teachers, Postsecondary Health Specialties Teachers, Postsecondary Psychology Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Recreation Workers 
Information:
Knowledge Arts and Humanities English language Safety and Government public safety and security law and government Business customer service administrative services Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social changing what is done based on other people's actions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention do two or more things at the same time pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Self Control Cooperation Integrity Concern for Others Dependability Leadership 
Technology You might use software like this on the job: Office suite software Corel WordPerfect Office Suite Microsoft Office software Computer based training software Appletree Data base user interface and query software Database software Recordkeeping software 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $31,680 $22,580 $48,270 Check out my state Local Salary Info Find Jobs 
Explore More Entertainment & Recreation Managers Recreational Therapists Rehabilitation Counselors Social & Community Service Managers Training & Development Managers You might like a career in one of these industries: Government Health & Counseling Arts & Entertainment Service 
##############################################
Title: Recreational Therapists 
Information:
Knowledge Math and Science psychology sociology and anthropology Health therapy and counseling medicine and dentistry Business customer service management Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention do two or more things at the same time pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Cooperation Dependability Adaptability/Flexibility Integrity Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Music or sound editing software Avid Technology Sibelius Steinberg Cubase Pro 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $51,330 $33,930 $84,410 Check out my state Local Salary Info Find Jobs 
Explore More Art Therapists Music Therapists Occupational Therapists Occupational Therapy Assistants Psychiatric Technicians You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Recreational Vehicle Service Technicians 
Information:
Knowledge Business customer service management Engineering and Technology mechanical 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Independence Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software RV Damage Repair Estimator Topline Software Solutions Topline Service Manager Electronic mail software Email software 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $45,030 $30,900 $65,390 Check out my state Local Salary Info Find Jobs 
Explore More Bus & Truck Mechanics & Diesel Engine Specialists Mobile Heavy Equipment Mechanics Motorboat Mechanics & Service Technicians Motorcycle Mechanics Outdoor Power Equipment & Other Small Engine Mechanics You might like a career in one of these industries: Retail Service 
##############################################
Title: Recycling & Reclamation Workers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical Business management customer service Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Self Control Attention to Detail Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Office suite software Microsoft Office software Word processing software Microsoft Word 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $36,110 $27,610 $48,560 Check out my state Local Salary Info Find Jobs 
Explore More Hazardous Materials Removal Workers Laborers & Freight, Stock, & Material Movers, Hand Packaging & Filling Machine Operators & Tenders Recycling Coordinators Refuse & Recyclable Material Collectors You might like a career in one of these industries: Transportation & Storage Administration & Support Services Manufacturing Wholesale/Commercial Sales 
##############################################
Title: Recycling Coordinators 
Information:
Knowledge Business customer service management Education and Training teaching and course design Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social changing what is done based on other people's actions understanding people's reactions People and Technology Systems thinking about the pros and cons of different options and picking the best one 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems order or arrange things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Cooperation Dependability Leadership Attention to Detail Adaptability/Flexibility Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,860 $37,310 $87,900 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Helpers, Laborers, & Material Movers, Hand First-Line Supervisors of Material-Moving Machine & Vehicle Operators First-Line Supervisors of Mechanics, Installers, & Repairers First-Line Supervisors of Production & Operating Workers Recycling & Reclamation Workers You might like a career in one of these industries: Transportation & Storage Retail Wholesale/Commercial Sales 
##############################################
Title: Refractory Materials Repairers 
Information:
Knowledge Engineering and Technology mechanical Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Persistence Self Control Cooperation Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $50,550 $30,780 $65,840 Check out my state Local Salary Info Find Jobs 
Explore More Brickmasons & Blockmasons Foundry Mold & Coremakers Helpers--Brickmasons, Blockmasons, Stonemasons, & Tile & Marble Setters Molders, Shapers, & Casters Terrazzo Workers & Finishers You might like a career in one of these industries: Manufacturing Construction 
##############################################
Title: Refuse & Recyclable Material Collectors 
Information:
Knowledge Engineering and Technology mechanical Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Independence Cooperation Attention to Detail Integrity Initiative 
Technology You might use software like this on the job: Data base user interface and query software Dossier software Mileage logging software Mobile location based services software Global positioning system GPS software Facilities management software Computerized maintenance management system CMMS 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $43,540 $28,190 $64,390 Check out my state Local Salary Info Find Jobs 
Explore More Hazardous Materials Removal Workers Heavy & Tractor-Trailer Truck Drivers Recycling & Reclamation Workers Recycling Coordinators Septic Tank Servicers & Sewer Pipe Cleaners You might like a career in one of these industries: Administration & Support Services Government 
##############################################
Title: Registered Nurses 
Information:
Knowledge Math and Science psychology arithmetic, algebra, geometry, calculus, or statistics Business customer service administrative services Health medicine and dentistry therapy and counseling Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Attention do two or more things at the same time 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Attention to Detail Cooperation Concern for Others Integrity Stress Tolerance 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software Epic Systems Word processing software Google Docs Microsoft Word Data base user interface and query software Database software Microsoft Access 
Education Education: (rated 4 of 5) bachelor's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $81,220 $61,250 $129,400 Check out my state Local Salary Info Find Jobs 
Explore More Acute Care Nurses Clinical Nurse Specialists Critical Care Nurses Licensed Practical & Licensed Vocational Nurses Nurse Practitioners You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Regulatory Affairs Managers 
Information:
Knowledge Arts and Humanities English language Safety and Government law and government Business management administrative services Math and Science biology 
Skills Basic Skills reading work related information talking to others People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Attention to Detail Dependability Stress Tolerance Leadership Analytical Thinking 
Technology You might use software like this on the job: Document management software Adlib Express Adobe Systems Adobe Acrobat Presentation software Microsoft PowerPoint Compliance software Aris Global Register Thomson Reuters Liquent InSight Suite 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $128,620 $63,380 $218,550 Check out my state Local Salary Info Find Jobs 
Explore More Clinical Data Managers Compliance Managers Environmental Compliance Inspectors Quality Control Systems Managers Regulatory Affairs Specialists You might like a career in one of these industries: Government 
##############################################
Title: Regulatory Affairs Specialists 
Information:
Knowledge Arts and Humanities English language Safety and Government law and government Math and Science biology Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Cooperation Analytical Thinking Persistence 
Technology You might use software like this on the job: Data base user interface and query software Microsoft SQL Server Yardi software Presentation software Microsoft PowerPoint Medical software Healthcare common procedure coding system HCPCS Medical procedure coding software 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $71,690 $41,050 $118,060 Check out my state Local Salary Info Find Jobs 
Explore More Compliance Managers Environmental Compliance Inspectors Government Property Inspectors & Investigators Quality Control Systems Managers Regulatory Affairs Managers You might like a career in one of these industries: Government Finance & Insurance 
##############################################
Title: Rehabilitation Counselors 
Information:
Knowledge Business customer service administrative services Health therapy and counseling Education and Training teaching and course design Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Integrity Dependability Initiative Persistence Cooperation 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Internet browser software Microsoft Mobile Explorer MME Web browser software 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $39,990 $29,120 $71,270 Check out my state Local Salary Info Find Jobs 
Explore More Child, Family, & School Social Workers Healthcare Social Workers Mental Health & Substance Abuse Social Workers Mental Health Counselors Occupational Therapy Aides You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Reinforcing Iron & Rebar Workers 
Information:
Knowledge Engineering and Technology building and construction design Math and Science arithmetic, algebra, geometry, calculus, or statistics Business management Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath Physical Strength lift, push, pull, or carry use your lower back and stomach Controlled Movement use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Leadership Initiative Integrity Cooperation 
Technology You might use software like this on the job: Project management software Application Software SHEAR Applied Systems Associates aSa Rebar Computer aided design CAD software OTP ArmaCAD Data base user interface and query software RebarWin 
Education Education: (rated 2 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $51,070 $38,310 $89,880 Check out my state Local Salary Info Find Jobs 
Explore More Cement Masons & Concrete Finishers Drywall & Ceiling Tile Installers Sheet Metal Workers Structural Iron & Steel Workers Structural Metal Fabricators & Fitters You might like a career in one of these industries: Construction 
##############################################
Title: Remote Sensing Scientists & Technologists 
Information:
Knowledge Math and Science geography arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics product and service development Arts and Humanities English language Business customer service 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Analytical Thinking Attention to Detail Integrity Achievement/Effort Persistence Adaptability/Flexibility 
Technology You might use software like this on the job: Analytical or scientific software Calibration software The MathWorks MATLAB Video creation and editing software YouTube Object or component oriented development software Objective C Scala 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $107,970 $56,520 $176,280 Check out my state Local Salary Info Find Jobs 
Explore More Electro-Mechanical & Mechatronics Technologists & Technicians Geodetic Surveyors Geographic Information Systems Technologists & Technicians Geological Technicians Remote Sensing Technicians You might like a career in one of these industries: Government Education Professional, Science, & Technical Farming, Forestry, Fishing, & Hunting 
##############################################
Title: Remote Sensing Technicians 
Information:
Knowledge Math and Science geography arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics product and service development Business customer service Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Ideas and Logic notice when problems happen order or arrange things Verbal communicate by speaking listen and understand what people say Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Analytical Thinking Dependability Initiative Persistence Achievement/Effort 
Technology You might use software like this on the job: Analytical or scientific software SAS The MathWorks MATLAB Presentation software Microsoft PowerPoint Operating system software Microsoft Windows Server UNIX Shell 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $50,290 $33,270 $90,760 Check out my state Local Salary Info Find Jobs 
Explore More Camera Operators, Television, Video, & Film Electro-Mechanical & Mechatronics Technologists & Technicians Geodetic Surveyors Geological Technicians Remote Sensing Scientists & Technologists You might like a career in one of these industries: Education Professional, Science, & Technical Administration & Support Services Government 
##############################################
Title: Reservation & Transportation Ticket Agents & Travel Clerks 
Information:
Knowledge Business customer service Arts and Humanities English language Engineering and Technology computers and electronics Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Self Control Dependability Attention to Detail Cooperation Stress Tolerance Social Orientation 
Technology You might use software like this on the job: Data base user interface and query software Amadeus Altea Reservation Microsoft Access Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $39,160 $29,990 $68,090 Check out my state Local Salary Info Find Jobs 
Explore More Baggage Porters & Bellhops Concierges Flight Attendants Passenger Attendants Travel Agents You might like a career in one of these industries: Transportation & Storage Administration & Support Services 
##############################################
Title: Residential Advisors 
Information:
Knowledge Business customer service management Safety and Government public safety and security law and government Education and Training teaching and course design Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Social understanding people's reactions changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Adaptability/Flexibility Concern for Others Self Control Cooperation Integrity Leadership 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Electronic mail software Email software Microsoft Outlook Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) some college or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $35,720 $25,580 $51,480 Check out my state Local Salary Info Find Jobs 
Explore More Child, Family, & School Social Workers First-Line Supervisors of Personal Service Workers Rehabilitation Counselors Social & Community Service Managers Social & Human Service Assistants You might like a career in one of these industries: Health & Counseling Education 
##############################################
Title: Respiratory Therapists 
Information:
Knowledge Business customer service Health medicine and dentistry Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Hand and Finger Use hold or move items with your hands put together small parts with your fingers Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Cooperation Concern for Others Attention to Detail Dependability Integrity Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software HMS MEDITECH software 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $70,540 $51,970 $100,520 Check out my state Local Salary Info Find Jobs 
Explore More Acute Care Nurses Cardiovascular Technologists & Technicians Physical Therapist Assistants Physical Therapists Radiation Therapists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Retail Loss Prevention Specialists 
Information:
Knowledge Safety and Government public safety and security law and government Arts and Humanities English language Business customer service management Education and Training teaching and course design 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Self Control Attention to Detail Stress Tolerance Cooperation Dependability 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Structured query language SQL Presentation software Microsoft PowerPoint Transaction security and virus protection software McAfee NortonLifeLock cybersecurity software 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $36,460 $28,110 $62,380 Check out my state Local Salary Info Find Jobs 
Explore More Compliance Managers Financial Risk Specialists Fraud Examiners, Investigators & Analysts Private Detectives & Investigators Security Managers You might like a career in one of these industries: Education Government Administration & Support Services 
##############################################
Title: Retail Salespersons 
Information:
Knowledge Business sales and marketing customer service Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social talking people into changing their minds or their behavior looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Attention to Detail Cooperation Integrity Self Control Adaptability/Flexibility 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Illustrator Word processing software Google Docs Microsoft Word Point of sale POS software Plexis Software Plexis POS TokenWorks Magnetic Card Reader 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $30,600 $22,590 $47,200 Check out my state Local Salary Info Find Jobs 
Explore More Cashiers Counter & Rental Clerks Demonstrators & Product Promoters Door-to-Door Sales Workers, News & Street Vendors, & Related Workers Stockers & Order Fillers You might like a career in one of these industries: Retail 
##############################################
Title: Riggers 
Information:
Knowledge Engineering and Technology mechanical design Manufactured or Agricultural Goods manufacture and distribution of products Safety and Government public safety and security Business customer service 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Cooperation Integrity Initiative Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Graphics or photo imaging software Autodesk Maya 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $54,680 $35,140 $85,250 Check out my state Local Salary Info Find Jobs 
Explore More Crane & Tower Operators Hoist & Winch Operators Industrial Truck & Tractor Operators Millwrights Mobile Heavy Equipment Mechanics You might like a career in one of these industries: Construction Manufacturing Government 
##############################################
Title: Robotics Engineers 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it thinking about the pros and cons of different options and picking the best one 
Abilities Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Verbal listen and understand what people say read and understand what is written Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Persistence Achievement/Effort Initiative Integrity 
Technology You might use software like this on the job: Analytical or scientific software MathWorks Simulink The MathWorks MATLAB Presentation software Microsoft PowerPoint Industrial control software Rockwell RSLogix Supervisory control and data acquisition SCADA software 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $104,600 $59,380 $168,320 Check out my state Local Salary Info Find Jobs 
Explore More Electronics Engineers Mechanical Engineers Mechatronics Engineers Microsystems Engineers Software Developers You might like a career in one of these industries: Professional, Science, & Technical Government Manufacturing 
##############################################
Title: Robotics Technicians 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it Technical figuring out what is causing equipment, machines, wiring, or computer programs to not work repairing machines or systems using the right tools 
Abilities Verbal listen and understand what people say read and understand what is written Hand and Finger Use put together small parts with your fingers hold or move items with your hands Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Analytical Thinking Dependability Integrity Adaptability/Flexibility Independence 
Technology You might use software like this on the job: Analytical or scientific software MathWorks Simulink The MathWorks MATLAB Presentation software Microsoft PowerPoint Industrial control software FANUC Robotics Through Arc Seam Tracking TAST Supervisory control and data acquisition SCADA software 
Education Education: (rated 3 of 5) associate's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $60,570 $40,300 $99,390 Check out my state Local Salary Info Find Jobs 
Explore More Aerospace Engineering & Operations Technologists & Technicians Calibration Technologists & Technicians Electrical & Electronic Engineering Technologists & Technicians Electrical & Electronics Repairers, Commercial & Industrial Equipment Mechanical Engineering Technologists & Technicians You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Rock Splitters, Quarry 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical 
Skills Basic Skills listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Independence Integrity Social Orientation Attention to Detail Concern for Others 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Application server software Apache HTTP Server 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $46,010 $30,520 $63,380 Check out my state Local Salary Info Find Jobs 
Explore More Earth Drillers Foundry Mold & Coremakers Grinding & Polishing Workers, Hand Rotary Drill Operators, Oil & Gas Stone Cutters & Carvers, Manufacturing You might like a career in one of these industries: Mining, Oil, & Gas Manufacturing 
##############################################
Title: Rolling Machine Setters, Operators, & Tenders, Metal & Plastic 
Information:
Knowledge Engineering and Technology mechanical Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat change when and how fast you move based on how something else is moving Hand and Finger Use hold or move items with your hands keep your arm or hand steady Reaction Time and Speed quickly move your hand, finger, or foot based on a sound, light, picture or other command Verbal communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Integrity Independence Self Control Cooperation 
Technology You might use software like this on the job: Electronic mail software Email software Internet browser software Web browser software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,310 $32,910 $66,220 Check out my state Local Salary Info Find Jobs 
Explore More Cutting & Slicing Machine Setters, Operators, & Tenders Cutting, Punching, & Press Machine Setters, Operators, & Tenders, Metal & Plastic Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic You might like a career in one of these industries: Manufacturing 
##############################################
Title: Roof Bolters, Mining 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Education and Training teaching and course design Engineering and Technology mechanical Safety and Government public safety and security 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use keep your arm or hand steady hold or move items with your hands Attention pay attention to something without being distracted do two or more things at the same time Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Concern for Others Integrity Self Control 
Technology You might use software like this on the job: Enterprise resource planning ERP software Caterpillar Cat MineStar System Industrial control software Caterpillar Command 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $60,210 $51,320 $77,370 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Continuous Mining Machine Operators Earth Drillers Riggers Structural Iron & Steel Workers You might like a career in one of these industries: Mining, Oil, & Gas 
##############################################
Title: Roofers 
Information:
Knowledge Engineering and Technology building and construction design Business customer service management Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath Flexibility, Balance, and Coordination keep your balance or stay upright bend, stretch, twist, or reach with your body, arms, and/or legs Controlled Movement use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Self Control Concern for Others Integrity Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Computer aided design CAD software AppliCad Roof Wizard ASR Software TopView LE Data base user interface and query software CADAFIS Insight Direct ServiceCEO 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,920 $31,470 $75,930 Check out my state Local Salary Info Find Jobs 
Explore More Drywall & Ceiling Tile Installers Helpers--Roofers Insulation Workers, Floor, Ceiling, & Wall Insulation Workers, Mechanical Plasterers & Stucco Masons You might like a career in one of these industries: Construction 
##############################################
Title: Rotary Drill Operators, Oil & Gas 
Information:
Knowledge Engineering and Technology mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics Business management human resources (HR) Education and Training teaching and course design 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Leadership Cooperation Initiative Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Data base user interface and query software Pason WellView Field Solution Structure query language SQL Industrial control software CAPSHER Technology SureTec Drillingsoftware DrillPro 
Education Education: (rated 2 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $55,260 $37,320 $84,530 Check out my state Local Salary Info Find Jobs 
Explore More Earth Drillers Helpers--Extraction Workers Roustabouts, Oil & Gas Service Unit Operators, Oil & Gas Wellhead Pumpers You might like a career in one of these industries: Mining, Oil, & Gas 
##############################################
Title: Roustabouts, Oil & Gas 
Information:
Knowledge Engineering and Technology mechanical Business customer service 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Leadership Self Control Attention to Detail Stress Tolerance 
Technology You might use software like this on the job: Enterprise resource planning ERP software Maintenance software SAP software Presentation software Microsoft PowerPoint Operating system software Microsoft Windows Microsoft Windows XP 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $43,590 $31,640 $61,180 Check out my state Local Salary Info Find Jobs 
Explore More Derrick Operators, Oil & Gas Earth Drillers Hoist & Winch Operators Riggers Rotary Drill Operators, Oil & Gas You might like a career in one of these industries: Mining, Oil, & Gas 
##############################################
Title: Sailors & Marine Oilers 
Information:
Knowledge Safety and Government public safety and security Transportation movement of people or goods by air, rail, sea, or road Engineering and Technology mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hearing and Speech pay attention to one sound while there are other distracting sounds tell the difference between sounds Vision see details that are far away 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Cooperation Concern for Others Attention to Detail Self Control Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software KNMI TurboWin Kongsberg Maritime K-Log Deck Logbook 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,490 $30,530 $75,210 Check out my state Local Salary Info Find Jobs 
Explore More Captains, Mates, & Pilots of Water Vessels Dredge Operators Motorboat Operators Riggers Ship Engineers You might like a career in one of these industries: Transportation & Storage Government 
##############################################
Title: Sales Engineers 
Information:
Knowledge Business customer service sales and marketing Engineering and Technology product and service development design Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social talking people into changing their minds or their behavior understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math add, subtract, multiply, or divide choose the right type of math to solve a problem Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Integrity Dependability Independence Cooperation Initiative 
Technology You might use software like this on the job: Enterprise resource planning ERP software Oracle PeopleSoft SAP software Project management software Microsoft Project Microsoft Teams Data base user interface and query software Amazon Web Services AWS software IBM DB2 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $108,530 $64,790 $195,140 Check out my state Local Salary Info Find Jobs 
Explore More Architectural & Engineering Managers Industrial Engineers Logistics Engineers Sales Representatives, Wholesale & Manufacturing, Technical & Scientific Products Software Developers You might like a career in one of these industries: Professional, Science, & Technical Wholesale/Commercial Sales Manufacturing Media & Communication 
##############################################
Title: Sales Managers 
Information:
Knowledge Business sales and marketing customer service Arts and Humanities English language Education and Training teaching and course design Communications multimedia 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social talking people into changing their minds or their behavior bringing people together to solve differences Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems come up with lots of ideas Math add, subtract, multiply, or divide choose the right type of math to solve a problem Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Initiative Integrity Adaptability/Flexibility Dependability Leadership Stress Tolerance 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Data base user interface and query software Airtable Yardi software Customer relationship management CRM software Eden Sales Manager Salesforce software 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $130,600 $61,040 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Advertising & Promotions Managers Market Research Analysts & Marketing Specialists Marketing Managers Purchasing Managers Sales Representatives of Services You might like a career in one of these industries: Wholesale/Commercial Sales Retail Professional, Science, & Technical Finance & Insurance Manufacturing 
##############################################
Title: Sales Representatives of Services 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Graphics or photo imaging software JamBoard Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $62,400 $34,390 $135,210 Check out my state Local Salary Info Find Jobs 
Explore More Advertising Sales Agents Customer Service Representatives New Accounts Clerks Sales Managers Telemarketers You might like a career in one of these industries: Professional, Science, & Technical Media & Communication Administration & Support Services Retail 
##############################################
Title: Sales Representatives, Wholesale & Manufacturing 
Information:
Knowledge Business sales and marketing customer service Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social bringing people together to solve differences talking people into changing their minds or their behavior Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic come up with lots of ideas group things in different ways 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Analytical Thinking Dependability Initiative Adaptability/Flexibility Stress Tolerance Attention to Detail 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Data base user interface and query software Blackboard software Yardi software Customer relationship management CRM software Salesforce software Soffront CRM Portal 
Education Education: (rated 4 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $63,230 $35,790 $129,450 Check out my state Local Salary Info Find Jobs 
Explore More Advertising Sales Agents Sales Engineers Sales Representatives of Services Sales Representatives, Wholesale & Manufacturing, Technical & Scientific Products Wholesale & Retail Buyers You might like a career in one of these industries: Wholesale/Commercial Sales Manufacturing 
##############################################
Title: Sales Representatives, Wholesale & Manufacturing, Technical & Scientific Products 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social talking people into changing their minds or their behavior bringing people together to solve differences Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems come up with lots of ideas Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Initiative Cooperation Dependability Persistence Stress Tolerance 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Video conferencing software LogMeIn GoToMeeting Zoom Customer relationship management CRM software Salesforce software Sybase iAnywhere Sales Anywhere 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $97,710 $46,160 $182,610 Check out my state Local Salary Info Find Jobs 
Explore More Industrial Production Managers Sales Engineers Sales Representatives, Wholesale & Manufacturing Solar Sales Representatives & Assessors Wholesale & Retail Buyers You might like a career in one of these industries: Wholesale/Commercial Sales Manufacturing Professional, Science, & Technical Media & Communication 
##############################################
Title: Sawing Machine Setters, Operators, & Tenders, Wood 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat change when and how fast you move based on how something else is moving Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Stress Tolerance Integrity Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Document management software Adobe Systems Adobe Acrobat 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $36,270 $26,930 $49,500 Check out my state Local Salary Info Find Jobs 
Explore More Cutting & Slicing Machine Setters, Operators, & Tenders Cutting, Punching, & Press Machine Setters, Operators, & Tenders, Metal & Plastic Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: School Bus Monitors 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Operating system software Microsoft Windows Internet browser software Web browser software 
Education Education: (rated 2 of 5) Get started on your career: Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $29,880 $23,410 $37,960 Check out my state Local Salary Info Find Jobs 
Explore More Bus Drivers, School Bus Drivers, Transit & Intercity Crossing Guards & Flaggers Passenger Attendants Shuttle Drivers & Chauffeurs You might like a career in one of these industries: Education Transportation & Storage Government 
##############################################
Title: School Psychologists 
Information:
Knowledge Math and Science psychology sociology and anthropology Health therapy and counseling Education and Training teaching and course design Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Dependability Integrity Cooperation Persistence Self Control Social Orientation 
Technology You might use software like this on the job: Word processing software Ewing Solutions QuickWriter Microsoft Word Video conferencing software Google Meet Data base user interface and query software Centris Group IEP Direct Vision Management Consulting IEP PlaNET 
Education Education: (rated 5 of 5) certificate after master's or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $81,500 $53,040 $128,800 Check out my state Local Salary Info Find Jobs 
Explore More Clinical & Counseling Psychologists Clinical Neuropsychologists Educational, Guidance, & Career Counselors & Advisors Marriage & Family Therapists Neuropsychologists You might like a career in one of these industries: Education 
##############################################
Title: Search Marketing Strategists 
Information:
Knowledge Business sales and marketing customer service Engineering and Technology computers and electronics Arts and Humanities English language Communications multimedia 
Skills Basic Skills reading work related information figuring out how to use new ideas or things Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic come up with lots of ideas notice when problems happen 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Analytical Thinking Attention to Detail Initiative Dependability Adaptability/Flexibility Achievement/Effort 
Technology You might use software like this on the job: Web platform development software AJAX Microsoft ASP.NET Graphics or photo imaging software Adobe Systems Adobe Photoshop JamBoard Data base user interface and query software Microsoft SQL Server Structured query language SQL 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $68,230 $38,280 $131,850 Check out my state Local Salary Info Find Jobs 
Explore More Advertising & Promotions Managers Business Intelligence Analysts Market Research Analysts & Marketing Specialists Marketing Managers Web & Digital Interface Designers You might like a career in one of these industries: Professional, Science, & Technical Media & Communication Finance & Insurance 
##############################################
Title: Secondary School Teachers 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Math and Science psychology arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems notice when problems happen Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Adaptability/Flexibility Self Control Concern for Others Dependability Cooperation 
Technology You might use software like this on the job: Cloud-based data access and sharing software Google Drive Microsoft SharePoint Computer based training software Moodle Schoology Video creation and editing software Flipgrid Screencastify 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $62,360 $46,480 $101,710 Check out my state Local Salary Info Find Jobs 
Explore More Adult Basic Education, Adult Secondary Education, & English as a Second Language Instructors Elementary School Teachers Middle School Teachers Special Education Teachers, Elementary School Tutors You might like a career in one of these industries: Education 
##############################################
Title: Secretaries & Administrative Assistants 
Information:
Knowledge Business administrative services customer service Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Cooperation Dependability Concern for Others Self Control 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Data base user interface and query software Blackboard software Yardi software Video conferencing software Cisco Webex Zoom 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $41,000 $29,000 $60,790 Check out my state Local Salary Info Find Jobs 
Explore More Administrative Services Managers Executive Secretaries & Executive Administrative Assistants Legal Secretaries & Administrative Assistants Office Clerks, General Receptionists & Information Clerks You might like a career in one of these industries: Education Health & Counseling Service 
##############################################
Title: Securities, Commodities, & Financial Services Sales Agents 
Information:
Knowledge Business customer service accounting and economics Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic use rules to solve problems notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Stress Tolerance Attention to Detail Initiative Persistence 
Technology You might use software like this on the job: Enterprise resource planning ERP software Oracle PeopleSoft SAP software Presentation software Microsoft PowerPoint Financial analysis software Oracle E-Business Suite Financials Web-based trading systems 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $67,480 $40,200 $189,620 Check out my state Local Salary Info Find Jobs 
Explore More Brokerage Clerks Financial & Investment Analysts Financial Risk Specialists Investment Fund Managers Personal Financial Advisors You might like a career in one of these industries: Finance & Insurance 
##############################################
Title: Security & Fire Alarm Systems Installers 
Information:
Knowledge Safety and Government public safety and security Engineering and Technology computers and electronics building and construction Business customer service Communications telecommunications 
Skills Basic Skills talking to others thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Attention to Detail Independence Self Control Stress Tolerance 
Technology You might use software like this on the job: Operating system software Microsoft operating system Microsoft Windows Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $50,130 $34,840 $77,980 Check out my state Local Salary Info Find Jobs 
Explore More Electrical & Electronics Installers & Repairers, Transportation Equipment Electronic Equipment Installers & Repairers, Motor Vehicles Lighting Technicians Security Management Specialists Telecommunications Equipment Installers & Repairers You might like a career in one of these industries: Administration & Support Services Construction 
##############################################
Title: Security Guards 
Information:
Knowledge Safety and Government public safety and security law and government Business customer service management Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal listen and understand what people say communicate by speaking Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Dependability Self Control Concern for Others Cooperation Attention to Detail 
Technology You might use software like this on the job: Spreadsheet software IBM Lotus 1-2-3 Microsoft Excel Video conferencing software FaceTime Office suite software Corel WordPerfect Office Suite Microsoft Office software 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $34,750 $24,020 $51,350 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Security Workers Police & Sheriff's Patrol Officers Public Safety Telecommunicators Transit & Railroad Police Transportation Security Screeners You might like a career in one of these industries: Administration & Support Services 
##############################################
Title: Security Management Specialists 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Enterprise resource planning ERP software Management information systems MIS SAP software Presentation software Microsoft PowerPoint Network security and virtual private network VPN equipment software TrueCrypt Virtual private networking VPN software 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $75,990 $40,310 $131,260 Check out my state Local Salary Info Find Jobs 
Explore More Compliance Managers Health & Safety Engineers Management Analysts Security & Fire Alarm Systems Installers Security Managers You might like a career in one of these industries: Government Professional, Science, & Technical Education 
##############################################
Title: Security Managers 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Project management software Incident command system ICS software Microsoft Project Presentation software Microsoft PowerPoint Facilities management software Alarm system software Maintenance management software 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $99,030 $59,280 $164,080 Check out my state Local Salary Info Find Jobs 
Explore More Compliance Managers Emergency Management Directors First-Line Supervisors of Security Workers Management Analysts Security Management Specialists You might like a career in one of these industries: Education Government Health & Counseling Manufacturing 
##############################################
Title: Segmental Pavers 
Information:
Knowledge Engineering and Technology mechanical building and construction Business management Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social changing what is done based on other people's actions understanding people's reactions 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Verbal communicate by speaking listen and understand what people say Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Concern for Others Integrity Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Graphics or photo imaging software Decorative Software Online Visualizers Depiction Software Deco-Con 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $44,890 $31,420 $74,160 Check out my state Local Salary Info Find Jobs 
Explore More Cement Masons & Concrete Finishers Floor Layers Paving, Surfacing, & Tamping Equipment Operators Terrazzo Workers & Finishers Tile & Stone Setters You might like a career in one of these industries: Construction 
##############################################
Title: Self-Enrichment Teachers 
Information:
Knowledge Education and Training teaching and course design Business customer service Arts and Humanities English language 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Adaptability/Flexibility Dependability Attention to Detail Self Control Concern for Others Integrity 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Photoshop Corel Paint Shop Pro Video creation and editing software Video editing software YouTube Computer based training software Educational software Schoology 
Education Education: (rated 3 of 5) master's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $44,110 $26,290 $81,340 Check out my state Local Salary Info Find Jobs 
Explore More Elementary School Teachers Middle School Teachers Secondary School Teachers Special Education Teachers, Elementary School Tutors You might like a career in one of these industries: Education Service 
##############################################
Title: Semiconductor Processing Technicians 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Safety and Government public safety and security Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written listen and understand what people say Hand and Finger Use keep your arm or hand steady put together small parts with your fingers 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Cooperation Dependability Stress Tolerance Adaptability/Flexibility Concern for Others 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Industrial control software Camstar Systems Camstar Semiconductor Suite Eyelit Manufacturing Development environment software National Instruments TestStand 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $44,690 $33,120 $72,030 Check out my state Local Salary Info Find Jobs 
Explore More Electrical & Electronic Equipment Assemblers Extruding & Drawing Machine Setters, Operators, & Tenders, Metal & Plastic Extruding & Forming Machine Setters, Operators, & Tenders, Synthetic & Glass Fibers Plating Machine Setters, Operators, & Tenders, Metal & Plastic Welding, Soldering, & Brazing Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Self Control Attention to Detail Independence Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $46,250 $31,430 $69,920 Check out my state Local Salary Info Find Jobs 
Explore More Chemical Equipment Operators & Tenders Cleaning, Washing, & Metal Pickling Equipment Operators & Tenders Crushing, Grinding, & Polishing Machine Setters, Operators, & Tenders Furnace, Kiln, Oven, Drier, & Kettle Operators & Tenders Mixing & Blending Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Septic Tank Servicers & Sewer Pipe Cleaners 
Information:
Knowledge Business customer service management Transportation movement of people or goods by air, rail, sea, or road Engineering and Technology mechanical Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Verbal listen and understand what people say communicate by speaking Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Integrity Independence Cooperation Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Accounting software Intuit QuickBooks Route navigation software Route mapping software 
Education Education: (rated 1 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $45,610 $31,120 $66,250 Check out my state Local Salary Info Find Jobs 
Explore More Cleaners of Vehicles & Equipment Helpers--Pipelayers, Plumbers, Pipefitters, & Steamfitters Pipelayers Plumbers, Pipefitters, & Steamfitters Water & Wastewater Treatment Plant & System Operators You might like a career in one of these industries: Administration & Support Services Government Construction 
##############################################
Title: Service Unit Operators, Oil & Gas 
Information:
Knowledge Engineering and Technology mechanical product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics chemistry Business customer service sales and marketing Safety and Government public safety and security 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Attention pay attention to something without being distracted do two or more things at the same time Hearing and Speech tell the difference between sounds 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Initiative Leadership Attention to Detail Persistence Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $50,140 $35,530 $83,530 Check out my state Local Salary Info Find Jobs 
Explore More Earth Drillers Petroleum Pump System Operators, Refinery Operators, & Gaugers Rotary Drill Operators, Oil & Gas Roustabouts, Oil & Gas Wellhead Pumpers You might like a career in one of these industries: Mining, Oil, & Gas 
##############################################
Title: Set & Exhibit Designers 
Information:
Knowledge Arts and Humanities music, dance, visual arts, drama, or sculpture history and archeology Engineering and Technology design computers and electronics Communications multimedia Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic come up with lots of ideas create new and original ideas 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Dependability Cooperation Adaptability/Flexibility Initiative Innovation 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk Revit Trimble SketchUp Pro Presentation software Microsoft PowerPoint Video creation and editing software Adobe Systems Adobe After Effects Autodesk 3ds Max 
Education Education: (rated 5 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $59,990 $33,160 $118,180 Check out my state Local Salary Info Find Jobs 
Explore More Commercial & Industrial Designers Craft Artists Fine Artists, Including Painters, Sculptors, & Illustrators Graphic Designers Interior Designers You might like a career in one of these industries: Media & Communication 
##############################################
Title: Sewers, Hand 
Information:
Knowledge Business customer service sales and marketing Engineering and Technology design Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic group things in different ways order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Innovation Integrity Achievement/Effort 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop Presentation software Microsoft PowerPoint Computer aided design CAD software Embroidery design software Template design software 
Education Education: (rated 2 of 5) no high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $31,530 $24,250 $43,260 Check out my state Local Salary Info Find Jobs 
Explore More Fabric & Apparel Patternmakers Sewing Machine Operators Shoe & Leather Workers & Repairers Tailors, Dressmakers, & Custom Sewers Upholsterers You might like a career in one of these industries: Manufacturing Retail 
##############################################
Title: Sewing Machine Operators 
Information:
Knowledge Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat change when and how fast you move based on how something else is moving 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Self Control Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Email software Microsoft Outlook Office suite software Microsoft Office software 
Education Education: (rated 1 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $31,740 $22,880 $43,500 Check out my state Local Salary Info Find Jobs 
Explore More Cutters & Trimmers, Hand Cutting & Slicing Machine Setters, Operators, & Tenders Pressers, Textile, Garment, & Related Materials Shoe Machine Operators & Tenders Textile Knitting & Weaving Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Shampooers 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social looking for ways to help people 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use keep your arm or hand steady hold or move items with your hands 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Self Control Adaptability/Flexibility Concern for Others Stress Tolerance Integrity 
Technology You might use software like this on the job: Web page creation and editing software Facebook Electronic mail software Email software Internet browser software Web browser software 
Education Education: (rated 2 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $27,860 $21,430 $33,770 Check out my state Local Salary Info Find Jobs 
Explore More Barbers Hairdressers, Hairstylists, & Cosmetologists Manicurists & Pedicurists Massage Therapists Skincare Specialists You might like a career in one of these industries: Service 
##############################################
Title: Sheet Metal Workers 
Information:
Knowledge Engineering and Technology mechanical building and construction Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Business management 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Attention pay attention to something without being distracted Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Initiative Achievement/Effort Cooperation Persistence 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD PTC Creo Parametric Computer aided manufacturing CAM software Applied Production ProFab WiCAM PN4000 Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $55,350 $35,570 $99,560 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Drywall & Ceiling Tile Installers Layout Workers, Metal & Plastic Structural Iron & Steel Workers Structural Metal Fabricators & Fitters You might like a career in one of these industries: Construction Manufacturing 
##############################################
Title: Ship Engineers 
Information:
Knowledge Engineering and Technology mechanical product and service development Business management Arts and Humanities English language Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Ideas and Logic notice when problems happen use rules to solve problems Controlled Movement quickly change the controls of a machine, car, truck or boat 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Analytical Thinking Attention to Detail Independence Integrity Self Control 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Oracle Database Presentation software Microsoft PowerPoint Facilities management software Computerized maintenance management system CMMS Marine Software Marine Planned Maintenance 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $87,300 $52,520 $139,990 Check out my state Local Salary Info Find Jobs 
Explore More Hydroelectric Plant Technicians Marine Engineers & Naval Architects Motorboat Mechanics & Service Technicians Sailors & Marine Oilers Stationary Engineers & Boiler Operators You might like a career in one of these industries: Transportation & Storage Government 
##############################################
Title: Shipping, Receiving, & Inventory Clerks 
Information:
Knowledge Business administrative services management Engineering and Technology computers and electronics Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use keep your arm or hand steady hold or move items with your hands 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Independence Concern for Others 
Technology You might use software like this on the job: Enterprise resource planning ERP software Oracle JD Edwards EnterpriseOne SAP software Presentation software Microsoft PowerPoint Materials requirements planning logistics and supply chain software Warehouse management system WMS WindowBook Postal Package Partner 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $37,760 $28,970 $55,730 Check out my state Local Salary Info Find Jobs 
Explore More Cargo & Freight Agents Laborers & Freight, Stock, & Material Movers, Hand Postal Service Clerks Stockers & Order Fillers Weighers, Measurers, Checkers, & Samplers, Recordkeeping You might like a career in one of these industries: Manufacturing Retail Wholesale/Commercial Sales Transportation & Storage 
##############################################
Title: Shoe & Leather Workers & Repairers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Business customer service 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social changing what is done based on other people's actions 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Ideas and Logic notice when problems happen group things in different ways 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Cooperation Attention to Detail Dependability Independence Analytical Thinking Concern for Others 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Accounting software Bookkeeping software Financial accounting software Point of sale POS software Sale processing software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $32,460 $22,830 $47,230 Check out my state Local Salary Info Find Jobs 
Explore More Molders, Shapers, & Casters Sewers, Hand Sewing Machine Operators Shoe Machine Operators & Tenders Tailors, Dressmakers, & Custom Sewers You might like a career in one of these industries: Manufacturing Retail 
##############################################
Title: Shoe Machine Operators & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical Education and Training teaching and course design 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Self Control Adaptability/Flexibility Attention to Detail Integrity Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $33,060 $24,450 $44,400 Check out my state Local Salary Info Find Jobs 
Explore More Cutting & Slicing Machine Setters, Operators, & Tenders Grinding & Polishing Workers, Hand Sewing Machine Operators Shoe & Leather Workers & Repairers Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Shuttle Drivers & Chauffeurs 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Mobile location based services software Global positioning system GPS software TSS Wireless Fleet Management Suite Data base user interface and query software Actsoft Comet Tracker TranWare Enterprise Suite 
Education Education: (rated 2 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $32,800 $23,680 $48,070 Check out my state Local Salary Info Find Jobs 
Explore More Bus Drivers, Transit & Intercity Dispatchers Heavy & Tractor-Trailer Truck Drivers Light Truck Drivers Taxi Drivers You might like a career in one of these industries: Transportation & Storage Health & Counseling 
##############################################
Title: Signal & Track Switch Repairers 
Information:
Knowledge Engineering and Technology mechanical product and service development Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Technical figuring out what is causing equipment, machines, wiring, or computer programs to not work planning and doing the basic maintenance on equipment 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Spatial imagine how something will look after it is moved around or changed Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Controlled Movement quickly change the controls of a machine, car, truck or boat 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Stress Tolerance Integrity Initiative 
Technology You might use software like this on the job: Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook Industrial control software Supervisory control and data acquisition SCADA software 
Education Education: (rated 3 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $81,300 $50,020 $92,900 Check out my state Local Salary Info Find Jobs 
Explore More Electrical & Electronics Installers & Repairers, Transportation Equipment Electrical & Electronics Repairers, Powerhouse, Substation, & Relay Electrical Power-Line Installers & Repairers Lighting Technicians Power Distributors & Dispatchers You might like a career in one of these industries: Transportation & Storage Government 
##############################################
Title: Skincare Specialists 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social looking for ways to help people changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use keep your arm or hand steady put together small parts with your fingers 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Dependability Concern for Others Self Control Integrity Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Office suite software Microsoft Office software 
Education Education: (rated 3 of 5) certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $38,060 $25,540 $79,300 Check out my state Local Salary Info Find Jobs 
Explore More Barbers Hairdressers, Hairstylists, & Cosmetologists Massage Therapists Shampooers Surgical Technologists You might like a career in one of these industries: Service Health & Counseling 
##############################################
Title: Slaughterers & Meat Packers 
Information:
Knowledge Business customer service Manufactured or Agricultural Goods food production manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills talking to others Social understanding people's reactions 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Physical Strength lift, push, pull, or carry use your lower back and stomach 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Attention to Detail Adaptability/Flexibility Stress Tolerance Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Inventory management software AgInfoLink Meat Inventory Tracking System MITS Traceability software Enterprise resource planning ERP software Integrated Management Systems Food Connex Cloud Second Foundation NaviMeat 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $35,240 $27,460 $43,700 Check out my state Local Salary Info Find Jobs 
Explore More Animal Breeders Butchers & Meat Cutters Farmworkers, Farm, Ranch, & Aquacultural Animals Graders & Sorters, Agricultural Products Meat, Poultry, & Fish Cutters & Trimmers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Social & Community Service Managers 
Information:
Knowledge Business customer service management Arts and Humanities English language Math and Science psychology sociology and anthropology Education and Training teaching and course design 
Skills Basic Skills figuring out how to use new ideas or things keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Leadership Dependability Adaptability/Flexibility Initiative Self Control 
Technology You might use software like this on the job: Spreadsheet software IBM Lotus 1-2-3 Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software FileMaker Pro Microsoft Access 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $74,240 $46,770 $123,320 Check out my state Local Salary Info Find Jobs 
Explore More Child, Family, & School Social Workers Community Health Workers Education & Childcare Administrators, Preschool & Daycare Healthcare Social Workers Rehabilitation Counselors You might like a career in one of these industries: Health & Counseling Government Service 
##############################################
Title: Social & Human Service Assistants 
Information:
Knowledge Business customer service administrative services Math and Science psychology sociology and anthropology Health therapy and counseling Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Dependability Concern for Others Cooperation Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Presentation software Microsoft PowerPoint Medical software MEDITECH software PointClickCare healthcare software 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $38,520 $28,610 $58,770 Check out my state Local Salary Info Find Jobs 
Explore More Child, Family, & School Social Workers Healthcare Social Workers Mental Health & Substance Abuse Social Workers Mental Health Counselors Rehabilitation Counselors You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Social Science Research Assistants 
Information:
Knowledge Arts and Humanities English language Engineering and Technology computers and electronics Business administrative services customer service Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Dependability Cooperation Attention to Detail Independence Adaptability/Flexibility Integrity 
Technology You might use software like this on the job: Object or component oriented development software Perl R Computer based training software Appletree Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $50,470 $33,860 $82,350 Check out my state Local Salary Info Find Jobs 
Explore More Clinical Data Managers Data Scientists Sociologists Statisticians Survey Researchers You might like a career in one of these industries: Education Professional, Science, & Technical 
##############################################
Title: Social Work Teachers, Postsecondary 
Information:
Knowledge Education and Training teaching and course design Math and Science sociology and anthropology psychology Health therapy and counseling Arts and Humanities English language 
Skills Basic Skills talking to others using the best training or teaching strategies for learning new things Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Independence Initiative Concern for Others Analytical Thinking Dependability 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Moodle Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $66,510 $40,130 $109,610 Check out my state Local Salary Info Find Jobs 
Explore More Education Teachers, Postsecondary Family & Consumer Sciences Teachers, Postsecondary Psychology Teachers, Postsecondary Sociology Teachers, Postsecondary Special Education Teachers, Elementary School You might like a career in one of these industries: Education 
##############################################
Title: Sociologists 
Information:
Knowledge Math and Science sociology and anthropology arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language history and archeology Education and Training teaching and course design Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Initiative Persistence Achievement/Effort Independence Integrity 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics SAS Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access QSR International NVivo 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $98,590 $57,490 $166,040 Check out my state Local Salary Info Find Jobs 
Explore More Anthropologists & Archeologists Political Scientists Social Science Research Assistants Social Work Teachers, Postsecondary Sociology Teachers, Postsecondary You might like a career in one of these industries: Professional, Science, & Technical Government Education 
##############################################
Title: Sociology Teachers, Postsecondary 
Information:
Knowledge Arts and Humanities English language history and archeology Math and Science sociology and anthropology psychology Education and Training teaching and course design Engineering and Technology computers and electronics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social teaching people how to do something understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Analytical Thinking Dependability Achievement/Effort Self Control Independence 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Sakai CLE 
Education Education: (rated 5 of 5) doctoral degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $78,970 $47,990 $155,040 Check out my state Local Salary Info Find Jobs 
Explore More Anthropology & Archeology Teachers, Postsecondary Education Teachers, Postsecondary Political Science Teachers, Postsecondary Psychology Teachers, Postsecondary Social Work Teachers, Postsecondary You might like a career in one of these industries: Education 
##############################################
Title: Software Developers 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Development environment software Oracle Java 2 Platform Enterprise Edition J2EE Oracle SQL Developer Data base user interface and query software Blackboard software GraphQL Object or component oriented development software Apache Spark TypeScript 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $127,260 $71,280 $198,100 Check out my state Local Salary Info Find Jobs 
Explore More Computer Hardware Engineers Computer Programmers Computer Systems Analysts Computer Systems Engineers/Architects Software Quality Assurance Analysts & Testers You might like a career in one of these industries: Professional, Science, & Technical Media & Communication Finance & Insurance 
##############################################
Title: Software Quality Assurance Analysts & Testers 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems measuring how well a system is working and how to improve it figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Analytical Thinking Cooperation Integrity Adaptability/Flexibility Dependability 
Technology You might use software like this on the job: Development environment software Apache Subversion SVN Oracle Java 2 Platform Enterprise Edition J2EE Data base user interface and query software Apache Hive Blackboard software Program testing software JUnit Selenium 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $99,620 $55,510 $159,740 Check out my state Local Salary Info Find Jobs 
Explore More Computer Systems Analysts Computer Systems Engineers/Architects Penetration Testers Software Developers Validation Engineers You might like a career in one of these industries: Professional, Science, & Technical Media & Communication Finance & Insurance 
##############################################
Title: Soil & Plant Scientists 
Information:
Knowledge Math and Science biology chemistry Arts and Humanities English language Engineering and Technology computers and electronics product and service development Education and Training teaching and course design 
Skills Basic Skills figuring out how to use new ideas or things reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic group things in different ways make general rules or come up with answers from lots of detailed information Math add, subtract, multiply, or divide choose the right type of math to solve a problem Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Analytical Thinking Attention to Detail Dependability Persistence Cooperation 
Technology You might use software like this on the job: Analytical or scientific software STATISTICA Water Erosion Prediction Project WEPP Presentation software Microsoft PowerPoint Data base user interface and query software Microsoft Access SoilVision Systems SVOFFICE 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $65,730 $38,520 $123,480 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Engineers Biologists Conservation Scientists Industrial Ecologists Precision Agriculture Technicians You might like a career in one of these industries: Professional, Science, & Technical Education Government Farming, Forestry, Fishing, & Hunting Wholesale/Commercial Sales 
##############################################
Title: Solar Energy Installation Managers 
Information:
Knowledge Business customer service management Engineering and Technology building and construction design Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Social changing what is done based on other people's actions teaching people how to do something Resource Management managing your time and the time of other people selecting and managing the best workers for a job 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Analytical Thinking Adaptability/Flexibility 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Civil 3D Trimble SketchUp Pro Presentation software Microsoft PowerPoint Project management software Microsoft Project Oracle Primavera Enterprise Project Portfolio Management 
Education Education: (rated 3 of 5) certificate after high school or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $74,080 $47,600 $116,940 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Construction Trades & Extraction Workers Project Management Specialists Solar Photovoltaic Installers Solar Sales Representatives & Assessors Solar Thermal Installers & Technicians You might like a career in one of these industries: Construction 
##############################################
Title: Solar Energy Systems Engineers 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Business management Arts and Humanities English language 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic notice when problems happen use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Adaptability/Flexibility Persistence 
Technology You might use software like this on the job: Analytical or scientific software Data visualization software The MathWorks MATLAB Presentation software Microsoft PowerPoint Computer aided design CAD software Autodesk Revit Trimble SketchUp Pro 
Education Education: (rated 4 of 5) bachelor's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $104,600 $59,380 $168,320 Check out my state Local Salary Info Find Jobs 
Explore More Energy Engineers Solar Energy Installation Managers Solar Sales Representatives & Assessors Solar Thermal Installers & Technicians Wind Energy Engineers You might like a career in one of these industries: Professional, Science, & Technical Government Manufacturing 
##############################################
Title: Solar Photovoltaic Installers 
Information:
Knowledge Engineering and Technology mechanical building and construction Arts and Humanities English language Business customer service Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers hold or move items with your hands Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Cooperation Dependability Attention to Detail Integrity Initiative Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Customer relationship management CRM software Salesforce software 
Education Education: (rated 2 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $45,230 $35,520 $63,330 Check out my state Local Salary Info Find Jobs 
Explore More Geothermal Technicians Lighting Technicians Solar Energy Installation Managers Solar Thermal Installers & Technicians Wind Turbine Service Technicians You might like a career in one of these industries: Construction 
##############################################
Title: Solar Sales Representatives & Assessors 
Information:
Knowledge Business sales and marketing customer service Arts and Humanities English language Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social looking for ways to help people talking people into changing their minds or their behavior Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic use rules to solve problems notice when problems happen 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Initiative Persistence Dependability Attention to Detail Cooperation Integrity 
Technology You might use software like this on the job: Office suite software Google Workspace software Microsoft Office software Video conferencing software Google Meet Zoom Customer relationship management CRM software Salesforce software Salesforce.com Salesforce CRM 
Education Education: (rated 3 of 5) some college or bachelor's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $97,710 $46,160 $182,610 Check out my state Local Salary Info Find Jobs 
Explore More Energy Auditors Energy Engineers Solar Energy Installation Managers Solar Energy Systems Engineers Wind Energy Development Managers You might like a career in one of these industries: Wholesale/Commercial Sales Manufacturing Professional, Science, & Technical Media & Communication 
##############################################
Title: Solar Thermal Installers & Technicians 
Information:
Knowledge Engineering and Technology mechanical building and construction Business customer service management Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills reading work related information keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Ideas and Logic notice when problems happen order or arrange things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Adaptability/Flexibility Analytical Thinking 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes SolidWorks Presentation software Microsoft PowerPoint Development environment software Microsoft Visual Basic for Applications VBA National Instruments LabVIEW 
Education Education: (rated 3 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $60,090 $37,250 $101,190 Check out my state Local Salary Info Find Jobs 
Explore More Heating, Air Conditioning, & Refrigeration Mechanics & Installers Insulation Workers, Floor, Ceiling, & Wall Plumbers, Pipefitters, & Steamfitters Solar Energy Systems Engineers Solar Photovoltaic Installers You might like a career in one of these industries: Construction 
##############################################
Title: Sound Engineering Technicians 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Communications multimedia telecommunications Business customer service management Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic order or arrange things create new and original ideas Attention pay attention to something without being distracted Hearing and Speech tell the difference between sounds 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Adaptability/Flexibility Cooperation Stress Tolerance Achievement/Effort 
Technology You might use software like this on the job: Operating system software Apple macOS UNIX Presentation software Microsoft PowerPoint Music or sound editing software Adobe Systems Adobe Audition Audio editing software 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $60,670 $31,200 $135,950 Check out my state Local Salary Info Find Jobs 
Explore More Audio & Video Technicians Broadcast Technicians Calibration Technologists & Technicians Lighting Technicians Media Technical Directors/Managers You might like a career in one of these industries: Media & Communication 
##############################################
Title: Spa Managers 
Information:
Knowledge Business administrative services customer service Arts and Humanities English language Education and Training teaching and course design Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Social changing what is done based on other people's actions looking for ways to help people People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Dependability Cooperation Concern for Others Initiative Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Video creation and editing software YouTube Data base user interface and query software DaySmart Software Salon Iris Syntec Systems Insight 
Education Education: (rated 3 of 5) certificate after high school or associate's degree usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $56,090 $35,010 $96,930 Check out my state Local Salary Info Find Jobs 
Explore More First-Line Supervisors of Entertainment & Recreation Workers First-Line Supervisors of Personal Service Workers Fitness & Wellness Coordinators Lodging Managers Skincare Specialists You might like a career in one of these industries: Service 
##############################################
Title: Special Education Teachers, Elementary School 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Computer based training software EasyCBM Rethink Ed Device drivers or system software Screen reader software The vOICe Learning Edition 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $62,390 $45,730 $101,100 Check out my state Local Salary Info Find Jobs 
Explore More Adult Basic Education, Adult Secondary Education, & English as a Second Language Instructors Special Education Teachers, Kindergarten Special Education Teachers, Middle School Special Education Teachers, Preschool Special Education Teachers, Secondary School You might like a career in one of these industries: Education 
##############################################
Title: Special Education Teachers, Kindergarten 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Computer based training software EasyCBM Rethink Ed Device drivers or system software Screen reader software The vOICe Learning Edition 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $62,390 $45,730 $101,100 Check out my state Local Salary Info Find Jobs 
Explore More Adult Basic Education, Adult Secondary Education, & English as a Second Language Instructors Special Education Teachers, Elementary School Special Education Teachers, Middle School Special Education Teachers, Preschool Special Education Teachers, Secondary School You might like a career in one of these industries: Education 
##############################################
Title: Special Education Teachers, Middle School 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Math and Science psychology arithmetic, algebra, geometry, calculus, or statistics Business customer service 
Skills Basic Skills talking to others figuring out how to use new ideas or things Social teaching people how to do something understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Adaptability/Flexibility Dependability Self Control Cooperation Stress Tolerance 
Technology You might use software like this on the job: Presentation software Microsoft PowerPoint Pear Deck Computer based training software Padlet Schoology Video creation and editing software Flipgrid Video editing software 
Education Education: (rated 4 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $62,990 $46,220 $100,560 Check out my state Local Salary Info Find Jobs 
Explore More Adult Basic Education, Adult Secondary Education, & English as a Second Language Instructors Special Education Teachers, Elementary School Special Education Teachers, Kindergarten Special Education Teachers, Preschool Special Education Teachers, Secondary School You might like a career in one of these industries: Education 
##############################################
Title: Special Education Teachers, Preschool 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Math and Science psychology sociology and anthropology Safety and Government public safety and security 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted do two or more things at the same time 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Adaptability/Flexibility Integrity Self Control Achievement/Effort Cooperation Dependability 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Device drivers or system software Screen magnification software Screen reader software 
Education Education: (rated 5 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $62,240 $38,020 $113,700 Check out my state Local Salary Info Find Jobs 
Explore More Special Education Teachers, Elementary School Special Education Teachers, Kindergarten Special Education Teachers, Middle School Special Education Teachers, Secondary School Teaching Assistants, Special Education You might like a career in one of these industries: Education Health & Counseling 
##############################################
Title: Special Education Teachers, Secondary School 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Engineering and Technology computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills using the best training or teaching strategies for learning new things listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social looking for ways to help people teaching people how to do something 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen come up with lots of ideas Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Concern for Others Stress Tolerance Cooperation Integrity Adaptability/Flexibility 
Technology You might use software like this on the job: Presentation software Microsoft PowerPoint Graphics or photo imaging software Adobe Systems Adobe Illustrator Adobe Systems Adobe Photoshop Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 4 of 5) bachelor's degree or certificate after college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $63,560 $46,880 $102,850 Check out my state Local Salary Info Find Jobs 
Explore More Special Education Teachers, Elementary School Special Education Teachers, Kindergarten Special Education Teachers, Middle School Special Education Teachers, Preschool Teaching Assistants, Special Education You might like a career in one of these industries: Education 
##############################################
Title: Special Effects Artists & Animators 
Information:
Knowledge Engineering and Technology computers and electronics design Arts and Humanities English language Communications multimedia telecommunications Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Cooperation Integrity Achievement/Effort Adaptability/Flexibility Dependability 
Technology You might use software like this on the job: Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Trimble SketchUp Pro Video creation and editing software Adobe Systems Adobe After Effects YouTube Development environment software Adobe Systems Adobe Creative Suite C 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $98,950 $52,660 $174,140 Check out my state Local Salary Info Find Jobs 
Explore More Art Directors Desktop Publishers Film & Video Editors Graphic Designers Video Game Designers You might like a career in one of these industries: Media & Communication Professional, Science, & Technical 
##############################################
Title: Speech-Language Pathologists 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Business customer service administrative services Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions teaching people how to do something 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Hearing and Speech recognize spoken words speak clearly Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Integrity Concern for Others Self Control Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Desktop communications software Tadpoles Medical software eClinicalWorks EHR software Micro Video Voice Speech Training System 
Education Education: (rated 5 of 5) master's degree or certificate after master's usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $84,140 $56,370 $126,680 Check out my state Local Salary Info Find Jobs 
Explore More Advanced Practice Psychiatric Nurses Audiologists Low Vision Therapists, Orientation & Mobility Specialists, & Vision Rehabilitation Therapists Occupational Therapists Psychiatrists You might like a career in one of these industries: Health & Counseling Education 
##############################################
Title: Speech-Language Pathology Assistants 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Health therapy and counseling Math and Science psychology 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Hearing and Speech recognize spoken words speak clearly 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Adaptability/Flexibility Concern for Others Dependability Integrity Self Control Social Orientation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Medical software Biofeedback software Micro Video Video Voice Speech Training System Text to speech conversion software Text to speech software 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $40,420 $28,750 $61,520 Check out my state Local Salary Info Find Jobs 
Explore More Audiologists Occupational Therapy Aides Occupational Therapy Assistants Psychiatric Technicians Speech-Language Pathologists You might like a career in one of these industries: Health & Counseling Government Education 
##############################################
Title: Sports Medicine Physicians 
Information:
Knowledge Health medicine and dentistry Math and Science biology Arts and Humanities English language Business customer service 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Concern for Others Attention to Detail Dependability Cooperation Initiative 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software Epic Systems Spreadsheet software Microsoft Excel Electronic mail software Email software 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $223,410 $63,970 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians Orthopedic Surgeons Pediatric Surgeons Physical Medicine & Rehabilitation Physicians You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Stationary Engineers & Boiler Operators 
Information:
Knowledge Engineering and Technology mechanical product and service development Math and Science chemistry physics Safety and Government public safety and security Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use hold or move items with your hands keep your arm or hand steady Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Independence Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Facilities management software Building management system software Computerized maintenance management system CMMS 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $67,120 $40,910 $108,790 Check out my state Local Salary Info Find Jobs 
Explore More Biomass Plant Technicians Control & Valve Installers & Repairers Engine & Other Machine Assemblers Gas Compressor & Gas Pumping Station Operators Power Plant Operators You might like a career in one of these industries: Government Education Health & Counseling Manufacturing 
##############################################
Title: Statistical Assistants 
Information:
Knowledge Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics Business customer service 
Skills Basic Skills using math to solve problems reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal read and understand what is written communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information order or arrange things Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Analytical Thinking Dependability Integrity Cooperation Initiative 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software Microsoft PowerPoint Object or component oriented development software C# R 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $48,880 $35,820 $79,250 Check out my state Local Salary Info Find Jobs 
Explore More Business Intelligence Analysts Clinical Data Managers Data Scientists Health Information Technologists & Medical Registrars Statisticians You might like a career in one of these industries: Education Finance & Insurance Professional, Science, & Technical Government 
##############################################
Title: Statisticians 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills using math to solve problems thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Analytical Thinking Attention to Detail Integrity Dependability Achievement/Effort Persistence 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software Microsoft PowerPoint Object or component oriented development software C++ R 
Education Education: (rated 5 of 5) master's degree or doctoral degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,920 $58,090 $161,300 Check out my state Local Salary Info Find Jobs 
Explore More Bioinformatics Scientists Biostatisticians Clinical Data Managers Data Scientists Mathematicians You might like a career in one of these industries: Professional, Science, & Technical Government Education 
##############################################
Title: Stockers & Order Fillers 
Information:
Knowledge Business customer service Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Social looking for ways to help people 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Independence Stress Tolerance 
Technology You might use software like this on the job: Enterprise resource planning ERP software Oracle JD Edwards EnterpriseOne SAP software Word processing software Google Docs Microsoft Word Inventory management software Inventory tracking software Ordering software 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $34,220 $25,680 $47,360 Check out my state Local Salary Info Find Jobs 
Explore More Counter & Rental Clerks Laborers & Freight, Stock, & Material Movers, Hand Production, Planning, & Expediting Clerks Shipping, Receiving, & Inventory Clerks Weighers, Measurers, Checkers, & Samplers, Recordkeeping You might like a career in one of these industries: Retail Transportation & Storage Wholesale/Commercial Sales 
##############################################
Title: Stone Cutters & Carvers, Manufacturing 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical design Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic group things in different ways notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Self Control Dependability Integrity Cooperation Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Graphics or photo imaging software Corel Paint Shop Pro Word processing software Microsoft Word 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $39,590 $30,040 $56,470 Check out my state Local Salary Info Find Jobs 
Explore More Furniture Finishers Grinding & Polishing Workers, Hand Molders, Shapers, & Casters Terrazzo Workers & Finishers Tile & Stone Setters You might like a career in one of these industries: Manufacturing 
##############################################
Title: Stonemasons 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology building and construction design Safety and Government public safety and security Education and Training teaching and course design 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem figuring out how to use new ideas or things Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions understanding people's reactions 
Abilities Endurance exercise for a long time without getting out of breath Hand and Finger Use keep your arm or hand steady hold or move items with your hands Physical Strength lift, push, pull, or carry use your lower back and stomach Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Achievement/Effort Initiative Persistence Leadership Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Analytical or scientific software Gregg Software Gregg Rock-It Tradesman's Software Master Estimator Accounting software Intuit QuickBooks 
Education Education: (rated 3 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $50,210 $34,740 $82,430 Check out my state Local Salary Info Find Jobs 
Explore More Brickmasons & Blockmasons Cement Masons & Concrete Finishers Helpers--Brickmasons, Blockmasons, Stonemasons, & Tile & Marble Setters Terrazzo Workers & Finishers Tile & Stone Setters You might like a career in one of these industries: Construction 
##############################################
Title: Structural Iron & Steel Workers 
Information:
Knowledge Engineering and Technology building and construction mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government public safety and security Business management 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat Endurance exercise for a long time without getting out of breath Physical Strength lift, push, pull, or carry 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Cooperation Initiative Concern for Others 
Technology You might use software like this on the job: Project management software Cost estimating software Project scheduling software Electronic mail software Microsoft Outlook Accounting software Turtle Creek Software Goldenseal 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $60,500 $37,890 $100,930 Check out my state Local Salary Info Find Jobs 
Explore More Carpenters Millwrights Reinforcing Iron & Rebar Workers Sheet Metal Workers Structural Metal Fabricators & Fitters You might like a career in one of these industries: Construction 
##############################################
Title: Structural Metal Fabricators & Fitters 
Information:
Knowledge Engineering and Technology mechanical building and construction Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Cooperation Dependability Concern for Others Integrity Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Computer aided design CAD software Dassault Systemes CATIA Tekla software Electronic mail software Microsoft Outlook 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,200 $33,870 $64,970 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Layout Workers, Metal & Plastic Sheet Metal Workers Structural Iron & Steel Workers Welding, Soldering, & Brazing Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Substance Abuse & Behavioral Disorder Counselors 
Information:
Knowledge Health therapy and counseling medicine and dentistry Math and Science psychology sociology and anthropology Education and Training teaching and course design Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions looking for ways to help people Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Attention pay attention to something without being distracted do two or more things at the same time 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Integrity Dependability Stress Tolerance Self Control Social Orientation 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Medical software Addison Health Systems WritePad EHR Varian Medical Systems 
Education Education: (rated 5 of 5) master's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $49,710 $34,580 $82,710 Check out my state Local Salary Info Find Jobs 
Explore More Healthcare Social Workers Marriage & Family Therapists Mental Health & Substance Abuse Social Workers Mental Health Counselors Rehabilitation Counselors You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Substitute Teachers, Short-Term 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Computer based training software Moodle Schoology Multi-media educational software Nearpod Seesaw Spreadsheet software Microsoft Excel 
Education Education: (rated 4 of 5) Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $35,250 $22,850 $61,190 Check out my state Local Salary Info Find Jobs 
Explore More Adult Basic Education, Adult Secondary Education, & English as a Second Language Instructors Special Education Teachers, Secondary School Teaching Assistants, Preschool, Elementary, Middle, & Secondary School Teaching Assistants, Special Education Tutors You might like a career in one of these industries: Education 
##############################################
Title: Subway & Streetcar Operators 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Safety and Government public safety and security Business customer service Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Attention pay attention to something without being distracted do two or more things at the same time Hand and Finger Use keep your arm or hand steady hold or move items with your hands Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Stress Tolerance Self Control Attention to Detail Integrity Cooperation 
Technology You might use software like this on the job: Office suite software Microsoft Office software Word processing software 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $88,260 $49,260 $89,180 Check out my state Local Salary Info Find Jobs 
Explore More Bus Drivers, Transit & Intercity Locomotive Engineers Rail Yard Engineers, Dinkey Operators, & Hostlers Railroad Brake, Signal, & Switch Operators & Locomotive Firers Railroad Conductors & Yardmasters You might like a career in one of these industries: Government 
##############################################
Title: Supply Chain Managers 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Business management accounting and economics Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Leadership Attention to Detail Integrity Cooperation Analytical Thinking Dependability 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Presentation software Microsoft PowerPoint Materials requirements planning logistics and supply chain software Infor Lawson Supply Chain Management Swisslog WarehouseManager 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,560 $57,780 $169,070 Check out my state Local Salary Info Find Jobs 
Explore More Industrial Production Managers Logisticians Logistics Analysts Logistics Engineers Transportation, Storage, & Distribution Managers You might like a career in one of these industries: Transportation & Storage Wholesale/Commercial Sales Manufacturing Government 
##############################################
Title: Surgical Assistants 
Information:
Knowledge Health medicine and dentistry Business customer service Arts and Humanities English language Math and Science biology 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social changing what is done based on other people's actions looking for ways to help people 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen order or arrange things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Self Control Attention to Detail Dependability Integrity Stress Tolerance Concern for Others 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software MEDITECH software Surgery workflow communication software 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $57,290 $35,130 $95,060 Check out my state Local Salary Info Find Jobs 
Explore More Anesthesiologist Assistants Cardiovascular Technologists & Technicians Emergency Medical Technicians Paramedics Surgical Technologists You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Surgical Technologists 
Information:
Knowledge Business customer service Health medicine and dentistry Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Verbal listen and understand what people say communicate by speaking Attention pay attention to something without being distracted Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Integrity Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Email software Graphics or photo imaging software Graphics software 
Education Education: (rated 3 of 5) associate's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $55,960 $38,860 $78,560 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Diagnostic Medical Sonographers Endoscopy Technicians Medical Equipment Preparers Surgical Assistants You might like a career in one of these industries: Health & Counseling 
##############################################
Title: Survey Researchers 
Information:
Knowledge Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics sociology and anthropology Business customer service management Engineering and Technology computers and electronics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by writing read and understand what is written Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Integrity Analytical Thinking Dependability Cooperation Initiative 
Technology You might use software like this on the job: Analytical or scientific software IBM SPSS Statistics The MathWorks MATLAB Presentation software COMCON DataFriend Microsoft PowerPoint Data base user interface and query software Database software Microsoft Access 
Education Education: (rated 5 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $60,410 $30,770 $104,890 Check out my state Local Salary Info Find Jobs 
Explore More Data Scientists Management Analysts Social Science Research Assistants Statistical Assistants Statisticians You might like a career in one of these industries: Professional, Science, & Technical Education 
##############################################
Title: Surveying & Mapping Technicians 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Math and Science geography arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Business customer service 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic notice when problems happen use rules to solve problems Hand and Finger Use put together small parts with your fingers 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Analytical Thinking Cooperation Integrity Independence 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Civil 3D Bentley MicroStation Presentation software Microsoft PowerPoint Analytical or scientific software Coordinate geometry COGO software Triton Elics International Isis 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $47,180 $31,840 $77,310 Check out my state Local Salary Info Find Jobs 
Explore More Cartographers & Photogrammetrists Geodetic Surveyors Geographic Information Systems Technologists & Technicians Geological Technicians Surveyors You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Surveyors 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics geography Engineering and Technology product and service development computers and electronics Business customer service management Arts and Humanities English language 
Skills Basic Skills using math to solve problems reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal read and understand what is written communicate by writing Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Spatial imagine how something will look after it is moved around or changed 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Persistence Analytical Thinking Cooperation 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Civil 3D Bentley MicroStation Analytical or scientific software Sokkia Spectrum Survey Suite Survey software Map creation software Geomechanical design analysis GDA software Sokkia Imap 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $63,080 $39,060 $106,880 Check out my state Local Salary Info Find Jobs 
Explore More Cartographers & Photogrammetrists Construction & Building Inspectors Geodetic Surveyors Geological Technicians Surveying & Mapping Technicians You might like a career in one of these industries: Professional, Science, & Technical 
##############################################
Title: Sustainability Specialists 
Information:
Knowledge Business management customer service Safety and Government law and government Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Initiative Cooperation Persistence Analytical Thinking Integrity Leadership 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Civil 3D Autodesk Revit Presentation software Microsoft PowerPoint Analytical or scientific software PE INTERNATIONAL SoFi PRe Consultants SimaPro 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $75,990 $40,310 $131,260 Check out my state Local Salary Info Find Jobs 
Explore More Chief Sustainability Officers Climate Change Policy Analysts Environmental Scientists & Specialists, Including Health Industrial Ecologists Wind Energy Development Managers You might like a career in one of these industries: Government Professional, Science, & Technical Education 
##############################################
Title: Switchboard Operators, Including Answering Service 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Engineering and Technology computers and electronics Communications telecommunications 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Dependability Self Control Stress Tolerance Attention to Detail Concern for Others Cooperation 
Technology You might use software like this on the job: Electronic mail software IBM Notes Microsoft Outlook Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $34,670 $25,640 $52,710 Check out my state Local Salary Info Find Jobs 
Explore More Customer Service Representatives Office Clerks, General Public Safety Telecommunicators Receptionists & Information Clerks Telephone Operators You might like a career in one of these industries: Health & Counseling Administration & Support Services 
##############################################
Title: Tailors, Dressmakers, & Custom Sewers 
Information:
Knowledge Business customer service management Arts and Humanities English language Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Cooperation Attention to Detail Integrity Self Control Stress Tolerance Achievement/Effort 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Word processing software Google Docs Microsoft Word Accounting software Bookkeeping software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $34,280 $23,910 $54,080 Check out my state Local Salary Info Find Jobs 
Explore More Fabric & Apparel Patternmakers Pressers, Textile, Garment, & Related Materials Sewers, Hand Sewing Machine Operators Shoe & Leather Workers & Repairers You might like a career in one of these industries: Retail Service 
##############################################
Title: Talent Directors 
Information:
Knowledge Arts and Humanities English language music, dance, visual arts, drama, or sculpture Business customer service human resources (HR) Communications multimedia 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions bringing people together to solve differences 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information create new and original ideas 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Cooperation Attention to Detail Persistence Integrity Concern for Others 
Technology You might use software like this on the job: Operating system software Linux UNIX Presentation software Microsoft PowerPoint Data base user interface and query software Amazon Web Services AWS software Database software 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $85,320 $42,140 $179,930 Check out my state Local Salary Info Find Jobs 
Explore More Agents & Business Managers of Artists, Performers, & Athletes Art Directors Media Programming Directors Music Directors & Composers Producers & Directors You might like a career in one of these industries: Media & Communication Arts & Entertainment 
##############################################
Title: Tank Car, Truck, & Ship Loaders 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills reading work related information keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Cooperation Adaptability/Flexibility Integrity Concern for Others 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Materials requirements planning logistics and supply chain software Distributed control system DCS Warehouse management system WMS Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $53,930 $36,730 $88,650 Check out my state Local Salary Info Find Jobs 
Explore More Conveyor Operators & Tenders Industrial Truck & Tractor Operators Laborers & Freight, Stock, & Material Movers, Hand Loading & Moving Machine Operators, Underground Mining Pump Operators You might like a career in one of these industries: Transportation & Storage Manufacturing Wholesale/Commercial Sales 
##############################################
Title: Tapers 
Information:
Knowledge Engineering and Technology building and construction Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Social understanding people's reactions 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Leadership Independence Integrity Cooperation 
Technology You might use software like this on the job: Accounting software Intuit QuickBooks Turtle Creek Software Goldenseal Project management software DevWave Estimate Works On Center Quick Bid Enterprise resource planning ERP software Microsoft Dynamics 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $62,360 $38,780 $100,820 Check out my state Local Salary Info Find Jobs 
Explore More Cement Masons & Concrete Finishers Drywall & Ceiling Tile Installers Insulation Workers, Floor, Ceiling, & Wall Insulation Workers, Mechanical Plasterers & Stucco Masons You might like a career in one of these industries: Construction 
##############################################
Title: Tax Examiners & Collectors, & Revenue Agents 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information order or arrange things Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Dependability Self Control Independence Stress Tolerance 
Technology You might use software like this on the job: Accounting software Fund accounting software Intuit QuickBooks Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 3 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $57,950 $36,570 $107,120 Check out my state Local Salary Info Find Jobs 
Explore More Accountants & Auditors Bill & Account Collectors Bookkeeping, Accounting, & Auditing Clerks Credit Authorizers, Checkers, & Clerks Tax Preparers You might like a career in one of these industries: Government 
##############################################
Title: Tax Preparers 
Information:
Knowledge Business customer service accounting and economics Arts and Humanities English language Engineering and Technology computers and electronics Safety and Government law and government 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say read and understand what is written Math add, subtract, multiply, or divide choose the right type of math to solve a problem Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Self Control Cooperation Stress Tolerance 
Technology You might use software like this on the job: Accounting software Intuit QuickBooks Tax software Tax preparation software Intuit TurboTax Petz Enterprises V-Tax Financial analysis software Sungard Relius Sync Essentials Trade Accountant 
Education Education: (rated 3 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $48,250 $27,680 $96,610 Check out my state Local Salary Info Find Jobs 
Explore More Accountants & Auditors Bookkeeping, Accounting, & Auditing Clerks Credit Analysts Personal Financial Advisors Tax Examiners & Collectors, & Revenue Agents You might like a career in one of these industries: Professional, Science, & Technical 
##############################################
Title: Taxi Drivers 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Mobile location based services software Digital Dispatch TSS Wireless Fleet Management Suite Data base user interface and query software Actsoft Comet Tracker TranWare Enterprise Suite 
Education Education: (rated 2 of 5) Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $30,670 $22,880 $41,710 Check out my state Local Salary Info Find Jobs 
Explore More Bus Drivers, Transit & Intercity Dispatchers Light Truck Drivers Shuttle Drivers & Chauffeurs Subway & Streetcar Operators 
##############################################
Title: Teaching Assistants, Postsecondary 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design Engineering and Technology computers and electronics Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic come up with lots of ideas create new and original ideas 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Integrity Dependability Attention to Detail Concern for Others Self Control Leadership 
Technology You might use software like this on the job: Word processing software Google Docs Microsoft Word Computer based training software Learning management system LMS Sakai CLE Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 5 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $38,050 $23,190 $63,630 Check out my state Local Salary Info Find Jobs 
Explore More Education Teachers, Postsecondary Elementary School Teachers Secondary School Teachers Teaching Assistants, Preschool, Elementary, Middle, & Secondary School Tutors You might like a career in one of these industries: Education 
##############################################
Title: Teaching Assistants, Preschool, Elementary, Middle, & Secondary School 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Computer based training software Padlet Schoology Desktop communications software ParentSquare Tadpoles 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $30,920 $22,720 $46,840 Check out my state Local Salary Info Find Jobs 
Explore More Elementary School Teachers Special Education Teachers, Secondary School Teaching Assistants, Postsecondary Teaching Assistants, Special Education Tutors You might like a career in one of these industries: Education Health & Counseling 
##############################################
Title: Teaching Assistants, Special Education 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Computer based training software Padlet Schoology Data base user interface and query software Blackboard software Student information systems SIS 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $30,920 $22,720 $46,840 Check out my state Local Salary Info Find Jobs 
Explore More Instructional Coordinators Special Education Teachers, Elementary School Special Education Teachers, Kindergarten Special Education Teachers, Secondary School Teaching Assistants, Preschool, Elementary, Middle, & Secondary School You might like a career in one of these industries: Education Health & Counseling 
##############################################
Title: Team Assemblers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical computers and electronics Safety and Government public safety and security Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Verbal listen and understand what people say communicate by speaking 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Cooperation Self Control Dependability Initiative Achievement/Effort 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $37,280 $28,620 $58,450 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Electrical & Electronic Equipment Assemblers Electromechanical Equipment Assemblers Engine & Other Machine Assemblers Industrial Engineering Technologists & Technicians You might like a career in one of these industries: Manufacturing Administration & Support Services 
##############################################
Title: Technical Writers 
Information:
Knowledge Arts and Humanities English language Engineering and Technology computers and electronics Business administrative services management Communications multimedia 
Skills Basic Skills writing things for co-workers or customers reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic use rules to solve problems order or arrange things 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Independence Initiative 
Technology You might use software like this on the job: Web platform development software Microsoft ASP.NET Oracle JavaServer Pages JSP Web page creation and editing software Adobe Systems Adobe Dreamweaver Google Sites Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Illustrator 
Education Education: (rated 4 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $79,960 $47,460 $128,110 Check out my state Local Salary Info Find Jobs 
Explore More Clinical Data Managers Document Management Specialists Editors Software Developers Writers & Authors You might like a career in one of these industries: Professional, Science, & Technical Manufacturing Media & Communication 
##############################################
Title: Telecommunications Engineering Specialists 
Information:
Knowledge Communications telecommunications Engineering and Technology computers and electronics product and service development Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Analytical Thinking Initiative Stress Tolerance 
Technology You might use software like this on the job: Operating system software Microsoft Windows Server Shell script Project management software Microsoft Project Microsoft Teams Electronic mail software IBM Notes Microsoft Outlook 
Education Education: (rated 3 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $126,900 $73,490 $185,170 Check out my state Local Salary Info Find Jobs 
Explore More Computer Network Architects Computer Network Support Specialists Computer Systems Engineers/Architects Software Developers Telecommunications Equipment Installers & Repairers You might like a career in one of these industries: Professional, Science, & Technical Media & Communication 
##############################################
Title: Telecommunications Equipment Installers & Repairers 
Information:
Knowledge Business customer service Communications telecommunications Engineering and Technology computers and electronics mechanical Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Verbal listen and understand what people say communicate by speaking Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Attention to Detail Cooperation Adaptability/Flexibility Analytical Thinking 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Analytical or scientific software Fluke ClearSight Analyzer Fluke Networks TechAdvisor Field Access System 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $59,960 $36,240 $88,770 Check out my state Local Salary Info Find Jobs 
Explore More Audiovisual Equipment Installers & Repairers Electrical & Electronics Repairers, Commercial & Industrial Equipment Power Distributors & Dispatchers Radio, Cellular, & Tower Equipment Installers & Repairers Telecommunications Line Installers & Repairers You might like a career in one of these industries: Media & Communication Construction 
##############################################
Title: Telecommunications Line Installers & Repairers 
Information:
Knowledge Communications telecommunications multimedia Business customer service Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills talking to others thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use keep your arm or hand steady hold or move items with your hands 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Cooperation Self Control Integrity Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Cloud-based data access and sharing software Slack Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $60,580 $38,020 $97,220 Check out my state Local Salary Info Find Jobs 
Explore More Audiovisual Equipment Installers & Repairers Electrical & Electronics Installers & Repairers, Transportation Equipment Electrical Power-Line Installers & Repairers Radio, Cellular, & Tower Equipment Installers & Repairers Telecommunications Equipment Installers & Repairers You might like a career in one of these industries: Media & Communication Construction 
##############################################
Title: Telemarketers 
Information:
Knowledge Business sales and marketing customer service Arts and Humanities English language Engineering and Technology computers and electronics Communications multimedia 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social talking people into changing their minds or their behavior looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Persistence Integrity Adaptability/Flexibility Initiative Stress Tolerance Dependability 
Technology You might use software like this on the job: Customer relationship management CRM software Database Systems Corp Telemation Microsoft Dynamics Video conferencing software Zoom Helpdesk or call center software Acarda Sales Technologies Acarda Outbound Softphone software 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $31,030 $22,350 $46,100 Check out my state Local Salary Info Find Jobs 
Explore More Advertising Sales Agents Counter & Rental Clerks Customer Service Representatives Sales Representatives of Services Telephone Operators You might like a career in one of these industries: Administration & Support Services 
##############################################
Title: Telephone Operators 
Information:
Knowledge Business customer service Arts and Humanities English language Communications telecommunications multimedia 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Stress Tolerance Integrity Dependability Adaptability/Flexibility Self Control Attention to Detail 
Technology You might use software like this on the job: Operating system software Handheld computer device software Microsoft Windows Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $38,330 $27,430 $64,390 Check out my state Local Salary Info Find Jobs 
Explore More Customer Service Representatives Public Safety Telecommunicators Receptionists & Information Clerks Switchboard Operators, Including Answering Service Telemarketers You might like a career in one of these industries: Health & Counseling Media & Communication Hotel & Food 
##############################################
Title: Tellers 
Information:
Knowledge Business customer service accounting and economics Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Math add, subtract, multiply, or divide choose the right type of math to solve a problem Attention pay attention to something without being distracted Ideas and Logic notice when problems happen 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Dependability Attention to Detail Self Control Cooperation Stress Tolerance 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Accounting software Sage 50 Accounting Southern Data Systems TellerPro 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $36,380 $28,570 $46,350 Check out my state Local Salary Info Find Jobs 
Explore More Bookkeeping, Accounting, & Auditing Clerks Brokerage Clerks Credit Authorizers, Checkers, & Clerks New Accounts Clerks Securities, Commodities, & Financial Services Sales Agents You might like a career in one of these industries: Finance & Insurance 
##############################################
Title: Terrazzo Workers & Finishers 
Information:
Knowledge Engineering and Technology building and construction design Math and Science arithmetic, algebra, geometry, calculus, or statistics chemistry Arts and Humanities English language Business customer service 
Skills Basic Skills talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Cooperation Self Control Dependability Initiative Achievement/Effort 
Technology You might use software like this on the job: Accounting software CPR International GeneralCOST Estimator Intuit QuickBooks Project management software CPR Visual Estimator On Center Quick Bid Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $52,330 $35,480 $103,080 Check out my state Local Salary Info Find Jobs 
Explore More Brickmasons & Blockmasons Cement Masons & Concrete Finishers Floor Layers Helpers--Brickmasons, Blockmasons, Stonemasons, & Tile & Marble Setters Tile & Stone Setters You might like a career in one of these industries: Construction 
##############################################
Title: Textile Bleaching & Dyeing Machine Operators & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat change when and how fast you move based on how something else is moving 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Integrity Stress Tolerance Cooperation 
Technology You might use software like this on the job: Operating system software Hewlett-Packard HP OpenVMS Linux Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $32,680 $25,890 $43,880 Check out my state Local Salary Info Find Jobs 
Explore More Cleaning, Washing, & Metal Pickling Equipment Operators & Tenders Extruding, Forming, Pressing, & Compacting Machine Setters, Operators, & Tenders Furnace, Kiln, Oven, Drier, & Kettle Operators & Tenders Machine Feeders & Offbearers Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Textile Cutting Machine Setters, Operators, & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Integrity Self Control Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Enterprise resource planning ERP software SAP software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $34,420 $25,250 $45,260 Check out my state Local Salary Info Find Jobs 
Explore More Cutting & Slicing Machine Setters, Operators, & Tenders Cutting, Punching, & Press Machine Setters, Operators, & Tenders, Metal & Plastic Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Paper Goods Machine Setters, Operators, & Tenders Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Textile Knitting & Weaving Machine Setters, Operators, & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Engineering and Technology mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Self Control Persistence Independence Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $35,750 $26,380 $46,160 Check out my state Local Salary Info Find Jobs 
Explore More Paper Goods Machine Setters, Operators, & Tenders Sewing Machine Operators Textile Bleaching & Dyeing Machine Operators & Tenders Textile Cutting Machine Setters, Operators, & Tenders Textile Winding, Twisting, & Drawing Out Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Textile Winding, Twisting, & Drawing Out Machine Setters, Operators, & Tenders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Business management Engineering and Technology mechanical Safety and Government public safety and security 
Skills Basic Skills talking to others keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Independence Dependability Integrity Attention to Detail Self Control Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Office suite software Microsoft Office software 
Education Education: (rated 2 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $33,720 $28,200 $44,010 Check out my state Local Salary Info Find Jobs 
Explore More Cutting & Slicing Machine Setters, Operators, & Tenders Extruding & Forming Machine Setters, Operators, & Tenders, Synthetic & Glass Fibers Machine Feeders & Offbearers Paper Goods Machine Setters, Operators, & Tenders Textile Knitting & Weaving Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing 
##############################################
Title: Tile & Stone Setters 
Information:
Knowledge Engineering and Technology building and construction design Math and Science arithmetic, algebra, geometry, calculus, or statistics Business customer service 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Endurance exercise for a long time without getting out of breath Hand and Finger Use hold or move items with your hands keep your arm or hand steady Ideas and Logic notice when problems happen order or arrange things Spatial imagine how something will look after it is moved around or changed 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Achievement/Effort Integrity Self Control 
Technology You might use software like this on the job: Office suite software Microsoft Office software Presentation software Microsoft PowerPoint Computer aided design CAD software EasyCAD Iris 2D TileGem 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $48,340 $33,330 $79,130 Check out my state Local Salary Info Find Jobs 
Explore More Brickmasons & Blockmasons Carpet Installers Floor Layers Helpers--Brickmasons, Blockmasons, Stonemasons, & Tile & Marble Setters Terrazzo Workers & Finishers You might like a career in one of these industries: Construction Manufacturing 
##############################################
Title: Timing Device Assemblers & Adjusters 
Information:
Knowledge Engineering and Technology mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Dependability Independence Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Office suite software Microsoft Office software Point of sale POS software Retail sales software Analytical or scientific software Maplesoft Maple 
Education Education: (rated 2 of 5) high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $42,290 $31,210 $71,020 Check out my state Local Salary Info Find Jobs 
Explore More Electrical & Electronic Equipment Assemblers Electromechanical Equipment Assemblers Engine & Other Machine Assemblers Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Watch & Clock Repairers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Tire Builders 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Business management Engineering and Technology mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Adaptability/Flexibility Cooperation Achievement/Effort Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software IBM Lotus Notes Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $51,650 $35,800 $63,230 Check out my state Local Salary Info Find Jobs 
Explore More Engine & Other Machine Assemblers Fiberglass Laminators & Fabricators Grinding & Polishing Workers, Hand Molders, Shapers, & Casters Tire Repairers & Changers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Tire Repairers & Changers 
Information:
Knowledge Engineering and Technology mechanical Business customer service management 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement use your arms and/or legs together while sitting, standing, or lying down quickly change the controls of a machine, car, truck or boat 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Independence Self Control Stress Tolerance 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Word processing software Microsoft Word 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $34,240 $27,320 $45,710 Check out my state Local Salary Info Find Jobs 
Explore More Automotive Service Technicians & Mechanics Bicycle Repairers Motorcycle Mechanics Rail Car Repairers Tire Builders You might like a career in one of these industries: Retail 
##############################################
Title: Title Examiners, Abstractors, & Searchers 
Information:
Knowledge Arts and Humanities English language Safety and Government law and government Business administrative services customer service Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Integrity Dependability Cooperation Stress Tolerance Analytical Thinking 
Technology You might use software like this on the job: Data base user interface and query software Data Trace Title IQ Microsoft Access Presentation software Microsoft PowerPoint Document management software Adobe Systems Adobe Acrobat File management software 
Education Education: (rated 2 of 5) high school diploma/GED or bachelor's degree usually needed Get started on your career: Find Training Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $50,490 $34,060 $83,990 Check out my state Local Salary Info Find Jobs 
Explore More Appraisers & Assessors of Real Estate Appraisers of Personal & Business Property Court, Municipal, & License Clerks File Clerks Paralegals & Legal Assistants You might like a career in one of these industries: Finance & Insurance Professional, Science, & Technical 
##############################################
Title: Tool & Die Makers 
Information:
Knowledge Engineering and Technology mechanical design Math and Science arithmetic, algebra, geometry, calculus, or statistics Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands put together small parts with your fingers Ideas and Logic notice when problems happen group things in different ways Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Persistence Cooperation Independence Initiative 
Technology You might use software like this on the job: Computer aided design CAD software Bentley MicroStation Dassault Systemes SolidWorks Presentation software Microsoft PowerPoint Computer aided manufacturing CAM software 1CadCam Unigraphics OPEN MIND Technologies hyperMILL 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $59,800 $38,470 $79,540 Check out my state Local Salary Info Find Jobs 
Explore More Cutting, Punching, & Press Machine Setters, Operators, & Tenders, Metal & Plastic Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Model Makers, Metal & Plastic Tool Grinders, Filers, & Sharpeners You might like a career in one of these industries: Manufacturing 
##############################################
Title: Tool Grinders, Filers, & Sharpeners 
Information:
Knowledge Engineering and Technology mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady hold or move items with your hands Controlled Movement quickly change the controls of a machine, car, truck or boat change when and how fast you move based on how something else is moving Attention pay attention to something without being distracted Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Independence Adaptability/Flexibility Cooperation Analytical Thinking 
Technology You might use software like this on the job: Computer aided manufacturing CAM software ANCA ToolRoom Dassault Systemes SolidWorks Spreadsheet software Microsoft Excel Electronic mail software IBM Lotus Notes 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $41,940 $29,020 $65,650 Check out my state Local Salary Info Find Jobs 
Explore More Grinding & Polishing Workers, Hand Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Woodworking Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing Service Wholesale/Commercial Sales 
##############################################
Title: Tour Guides & Escorts 
Information:
Knowledge Business customer service Arts and Humanities English language history and archeology Communications multimedia Education and Training teaching and course design 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Self Control Adaptability/Flexibility Concern for Others Cooperation Dependability Attention to Detail 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Presentation software Microsoft PowerPoint Pear Deck Customer relationship management CRM software Centaur Systems Centaur Travel Business Management System TBMS TourTech Systems TourTools 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $34,440 $23,400 $55,130 Check out my state Local Salary Info Find Jobs 
Explore More Historians Park Naturalists Travel Agents Travel Guides Ushers, Lobby Attendants, & Ticket Takers You might like a career in one of these industries: Arts & Entertainment Administration & Support Services 
##############################################
Title: Traffic Technicians 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Safety and Government public safety and security law and government Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Integrity Cooperation Adaptability/Flexibility Analytical Thinking 
Technology You might use software like this on the job: Analytical or scientific software SAS The MathWorks MATLAB Presentation software Microsoft PowerPoint Computer aided design CAD software Autodesk AutoCAD Bentley MicroStation 
Education Education: (rated 3 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $50,050 $34,370 $84,520 Check out my state Local Salary Info Find Jobs 
Explore More Air Traffic Controllers Civil Engineering Technologists & Technicians Construction & Building Inspectors Railroad Conductors & Yardmasters Transportation Vehicle, Equipment & Systems Inspectors You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Training & Development Managers 
Information:
Knowledge Education and Training teaching and course design Arts and Humanities English language Business customer service management Math and Science psychology 
Skills Basic Skills using the best training or teaching strategies for learning new things listening to others, not interrupting, and asking good questions Social teaching people how to do something changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic come up with lots of ideas use rules to solve problems 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Integrity Leadership Initiative Dependability Adaptability/Flexibility Self Control 
Technology You might use software like this on the job: Presentation software Caliban Mindwear HyperGASP Microsoft PowerPoint Computer based training software Common Curriculum Moodle Web page creation and editing software Adobe Systems Adobe Dreamweaver SAFARI Video Networks eZediaQTI 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $120,000 $68,450 $210,470 Check out my state Local Salary Info Find Jobs 
Explore More Education & Childcare Administrators, Preschool & Daycare Instructional Coordinators Management Analysts Social & Community Service Managers Training & Development Specialists You might like a career in one of these industries: Professional, Science, & Technical Management Education Finance & Insurance 
##############################################
Title: Training & Development Specialists 
Information:
Knowledge Education and Training teaching and course design Business customer service human resources (HR) Arts and Humanities English language Math and Science psychology 
Skills Basic Skills talking to others using the best training or teaching strategies for learning new things Social teaching people how to do something understanding people's reactions People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic come up with lots of ideas notice when problems happen Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Cooperation Dependability Adaptability/Flexibility Integrity Self Control Initiative 
Technology You might use software like this on the job: Web platform development software Django Drupal Computer based training software Common Curriculum Moodle Graphics or photo imaging software Adobe Systems Adobe Creative Cloud software Adobe Systems Adobe Illustrator 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $63,080 $34,950 $108,530 Check out my state Local Salary Info Find Jobs 
Explore More Human Resources Specialists Industrial-Organizational Psychologists Instructional Coordinators Management Analysts Training & Development Managers You might like a career in one of these industries: Professional, Science, & Technical Health & Counseling Education 
##############################################
Title: Transit & Railroad Police 
Information:
Knowledge Safety and Government public safety and security law and government Arts and Humanities English language Business customer service management Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it Social understanding people's reactions changing what is done based on other people's actions 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Hearing and Speech speak clearly recognize spoken words Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Self Control Stress Tolerance Dependability Leadership Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software Law enforcement information databases National Crime Information Center (NCIC) database 
Education Education: (rated 3 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $69,150 $50,080 $114,310 Check out my state Local Salary Info Find Jobs 
Explore More Detectives & Criminal Investigators First-Line Supervisors of Police & Detectives First-Line Supervisors of Security Workers Police & Sheriff's Patrol Officers Security Guards You might like a career in one of these industries: Government 
##############################################
Title: Transportation Engineers 
Information:
Knowledge Engineering and Technology product and service development design Transportation movement of people or goods by air, rail, sea, or road Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Math add, subtract, multiply, or divide choose the right type of math to solve a problem Ideas and Logic group things in different ways make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Attention to Detail Analytical Thinking Cooperation Dependability Initiative 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Civil 3D Bentley MicroStation Presentation software Microsoft PowerPoint Analytical or scientific software Citilabs Cube Trafficware SynchroGreen 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $89,940 $61,040 $138,690 Check out my state Local Salary Info Find Jobs 
Explore More Civil Engineering Technologists & Technicians Civil Engineers Construction Managers Traffic Technicians Transportation Planners You might like a career in one of these industries: Professional, Science, & Technical Government Construction 
##############################################
Title: Transportation Inspectors 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language Business customer service management Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Attention to Detail Adaptability/Flexibility Initiative Analytical Thinking 
Technology You might use software like this on the job: Electronic mail software Email software Microsoft Outlook Spreadsheet software Microsoft Excel Document management software Adobe Systems Adobe Acrobat 
Education Education: (rated 4 of 5) Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $79,570 $36,770 $126,150 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Cargo Handling Supervisors Aviation Inspectors Construction & Building Inspectors Ship Engineers Transportation Vehicle, Equipment & Systems Inspectors You might like a career in one of these industries: Government Transportation & Storage 
##############################################
Title: Transportation Planners 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics geography Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic come up with lots of ideas make general rules or come up with answers from lots of detailed information Math choose the right type of math to solve a problem add, subtract, multiply, or divide Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Cooperation Analytical Thinking Attention to Detail Dependability Initiative 
Technology You might use software like this on the job: Analytical or scientific software Citilabs Cube SAS Presentation software Microsoft PowerPoint Geographic information system ESRI ArcGIS software Geographic information system GIS software 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $88,900 $56,120 $137,020 Check out my state Local Salary Info Find Jobs 
Explore More Civil Engineers Logistics Engineers Transportation Engineers Transportation, Storage, & Distribution Managers Urban & Regional Planners You might like a career in one of these industries: Government Professional, Science, & Technical Education 
##############################################
Title: Transportation Security Screeners 
Information:
Knowledge Safety and Government public safety and security Business customer service Arts and Humanities English language 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Social changing what is done based on other people's actions understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Dependability Attention to Detail Self Control Stress Tolerance Adaptability/Flexibility 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Cloud-based data access and sharing software Slack Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,710 $38,540 $59,950 Check out my state Local Salary Info Find Jobs 
Explore More Baggage Porters & Bellhops Flight Attendants Reservation & Transportation Ticket Agents & Travel Clerks Security Guards Transit & Railroad Police You might like a career in one of these industries: Government 
##############################################
Title: Transportation Vehicle, Equipment & Systems Inspectors 
Information:
Knowledge Engineering and Technology mechanical Transportation movement of people or goods by air, rail, sea, or road Arts and Humanities English language Safety and Government public safety and security 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Hand and Finger Use keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Independence Integrity Cooperation Attention to Detail Concern for Others Dependability 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software Past Inspection Query PIQ Structured query language SQL 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $79,570 $36,770 $126,150 Check out my state Local Salary Info Find Jobs 
Explore More Aviation Inspectors Bus & Truck Mechanics & Diesel Engine Specialists Construction & Building Inspectors Inspectors, Testers, Sorters, Samplers, & Weighers Transportation Inspectors You might like a career in one of these industries: Government Transportation & Storage 
##############################################
Title: Transportation, Storage, & Distribution Managers 
Information:
Knowledge Transportation movement of people or goods by air, rail, sea, or road Business management customer service Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills talking to others thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Integrity Leadership Stress Tolerance Attention to Detail Cooperation 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Presentation software Microsoft PowerPoint Materials requirements planning logistics and supply chain software USPS.com Warehouse management system WMS 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,560 $57,780 $169,070 Check out my state Local Salary Info Find Jobs 
Explore More General & Operations Managers Logisticians Logistics Analysts Logistics Engineers Supply Chain Managers You might like a career in one of these industries: Transportation & Storage Wholesale/Commercial Sales Manufacturing Government 
##############################################
Title: Travel Agents 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language Math and Science geography Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen order or arrange things Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Attention to Detail Integrity Dependability Self Control Achievement/Effort Cooperation 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Travel Agent CMS Video conferencing software Zoom Calendar and scheduling software Apollo Reservation System Rezgo online booking software 
Education Education: (rated 3 of 5) associate's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $46,400 $29,650 $64,100 Check out my state Local Salary Info Find Jobs 
Explore More Concierges Counter & Rental Clerks Reservation & Transportation Ticket Agents & Travel Clerks Sales Representatives of Services Travel Guides You might like a career in one of these industries: Administration & Support Services 
##############################################
Title: Travel Guides 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social looking for ways to help people understanding people's reactions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen order or arrange things Attention do two or more things at the same time pay attention to something without being distracted 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Concern for Others Dependability Attention to Detail Cooperation Stress Tolerance Independence 
Technology You might use software like this on the job: Data base user interface and query software Microsoft Access Structured query language SQL Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $34,440 $23,400 $55,130 Check out my state Local Salary Info Find Jobs 
Explore More Concierges Lodging Managers Reservation & Transportation Ticket Agents & Travel Clerks Tour Guides & Escorts Travel Agents You might like a career in one of these industries: Arts & Entertainment Administration & Support Services 
##############################################
Title: Treasurers & Controllers 
Information:
Knowledge Business accounting and economics management Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal read and understand what is written communicate by speaking Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Visual Understanding quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Dependability Analytical Thinking Persistence Stress Tolerance 
Technology You might use software like this on the job: Enterprise resource planning ERP software Microsoft Dynamics SAP software Presentation software Microsoft PowerPoint Accounting software Intuit QuickBooks Sage 50 Accounting 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $139,790 $79,050 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Accountants & Auditors Budget Analysts Chief Executives Financial Examiners Financial Managers You might like a career in one of these industries: Finance & Insurance Professional, Science, & Technical Management 
##############################################
Title: Tree Trimmers & Pruners 
Information:
Knowledge Business customer service Engineering and Technology mechanical 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Endurance exercise for a long time without getting out of breath Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Cooperation Concern for Others Dependability Integrity Self Control 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Electronic mail software Microsoft Outlook Word processing software Microsoft Word 
Education Education: (rated 2 of 5) no high school diploma/GED or high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,080 $30,650 $72,370 Check out my state Local Salary Info Find Jobs 
Explore More Agricultural Equipment Operators Fallers Farmworkers & Laborers, Crop, Nursery, & Greenhouse Landscaping & Groundskeeping Workers Pesticide Handlers, Sprayers, & Applicators, Vegetation You might like a career in one of these industries: Administration & Support Services 
##############################################
Title: Tutors 
Information:
Knowledge Business customer service Arts and Humanities English language Education and Training teaching and course design Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it Social teaching people how to do something understanding people's reactions 
Abilities Verbal listen and understand what people say communicate by speaking Ideas and Logic come up with lots of ideas make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Concern for Others Dependability Integrity Social Orientation Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Video conferencing software Google Meet Zoom Computer based training software Moodle Schoology Multi-media educational software Nearpod Seesaw 
Education Education: (rated 3 of 5) bachelor's degree or some college usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $36,680 $26,040 $73,400 Check out my state Local Salary Info Find Jobs 
Explore More Adult Basic Education, Adult Secondary Education, & English as a Second Language Instructors Middle School Teachers Secondary School Teachers Special Education Teachers, Elementary School Teaching Assistants, Preschool, Elementary, Middle, & Secondary School You might like a career in one of these industries: Education 
##############################################
Title: Umpires, Referees, & Other Sports Officials 
Information:
Knowledge Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills talking to others thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Attention do two or more things at the same time pay attention to something without being distracted Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns Memory remember words, numbers, pictures, or steps 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Self Control Attention to Detail Stress Tolerance Cooperation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $36,010 $22,090 $69,010 Check out my state Local Salary Info Find Jobs 
Explore More Amusement & Recreation Attendants Animal Trainers Athletes & Sports Competitors Coaches & Scouts First-Line Supervisors of Police & Detectives You might like a career in one of these industries: Arts & Entertainment Government 
##############################################
Title: Upholsterers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology design 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down Ideas and Logic notice when problems happen order or arrange things 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Initiative Social Orientation 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Accounting software Intuit QuickBooks 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $39,600 $28,300 $59,660 Check out my state Local Salary Info Find Jobs 
Explore More Cabinetmakers & Bench Carpenters Furniture Finishers Grinding & Polishing Workers, Hand Sewers, Hand Shoe & Leather Workers & Repairers You might like a career in one of these industries: Manufacturing Service 
##############################################
Title: Urban & Regional Planners 
Information:
Knowledge Safety and Government law and government Arts and Humanities English language Math and Science geography sociology and anthropology Transportation movement of people or goods by air, rail, sea, or road 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Cooperation Dependability Analytical Thinking Self Control Attention to Detail 
Technology You might use software like this on the job: Computer aided design CAD software Bentley MicroStation Trimble SketchUp Pro Presentation software Microsoft PowerPoint Geographic information system ESRI ArcGIS software Geographic information system GIS software 
Education Education: (rated 5 of 5) master's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $79,540 $49,960 $121,460 Check out my state Local Salary Info Find Jobs 
Explore More Brownfield Redevelopment Specialists & Site Managers Chief Sustainability Officers Project Management Specialists Sustainability Specialists Transportation Planners You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################
Title: Urologists 
Information:
Knowledge Health medicine and dentistry therapy and counseling Math and Science biology psychology Arts and Humanities English language Education and Training teaching and course design 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by speaking Ideas and Logic notice when problems happen make general rules or come up with answers from lots of detailed information Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things Hand and Finger Use keep your arm or hand steady 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Dependability Integrity Attention to Detail Stress Tolerance Achievement/Effort Analytical Thinking 
Technology You might use software like this on the job: Medical software eClinicalWorks EHR software MEDITECH software Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 5 of 5) post-doctoral training or doctoral degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $223,410 $63,970 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Cardiologists Emergency Medicine Physicians General Internal Medicine Physicians Orthopedic Surgeons Pediatric Surgeons You might like a career in one of these industries: Health & Counseling Government 
##############################################
Title: Ushers, Lobby Attendants, & Ticket Takers 
Information:
Knowledge Business customer service Arts and Humanities English language Safety and Government public safety and security Communications multimedia 
Skills Basic Skills talking to others listening to others, not interrupting, and asking good questions Social understanding people's reactions looking for ways to help people 
Abilities Verbal communicate by speaking listen and understand what people say Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Self Control Cooperation Social Orientation Dependability Integrity Concern for Others 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Optical character reader OCR or scanning software Ticket Alternative Express Entry Ticket scanning software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $27,650 $20,020 $37,300 Check out my state Local Salary Info Find Jobs 
Explore More Baggage Porters & Bellhops Hosts & Hostesses, Restaurant, Lounge, & Coffee Shop Hotel, Motel, & Resort Desk Clerks Locker Room, Coatroom, & Dressing Room Attendants Passenger Attendants You might like a career in one of these industries: Media & Communication Arts & Entertainment Administration & Support Services 
##############################################
Title: Validation Engineers 
Information:
Knowledge Engineering and Technology product and service development design Manufactured or Agricultural Goods manufacture and distribution of products Arts and Humanities English language Business customer service 
Skills Basic Skills reading work related information talking to others Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Attention to Detail Integrity Dependability Analytical Thinking Cooperation Adaptability/Flexibility 
Technology You might use software like this on the job: Development environment software Apache Kafka Apache Maven Presentation software Microsoft PowerPoint Analytical or scientific software Minitab The MathWorks MATLAB 
Education Education: (rated 4 of 5) bachelor's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $96,350 $62,730 $134,990 Check out my state Local Salary Info Find Jobs 
Explore More Industrial Engineers Manufacturing Engineers Mechatronics Engineers Quality Control Systems Managers Software Quality Assurance Analysts & Testers You might like a career in one of these industries: Manufacturing Professional, Science, & Technical 
##############################################
Title: Veterinarians 
Information:
Knowledge Health medicine and dentistry Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Business customer service human resources (HR) Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math choose the right type of math to solve a problem add, subtract, multiply, or divide Hand and Finger Use put together small parts with your fingers 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Attention to Detail Analytical Thinking Stress Tolerance Dependability Cooperation 
Technology You might use software like this on the job: Data base user interface and query software IDEXX Laboratories IDEXX VPM Microsoft Access Presentation software Microsoft PowerPoint Medical software American Data Systems PAWS Veterinary Practice Management Vetport 
Education Education: (rated 5 of 5) doctoral degree or post-doctoral training usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $103,260 $62,350 $174,500 Check out my state Local Salary Info Find Jobs 
Explore More Allergists & Immunologists Dermatologists Emergency Medicine Physicians General Internal Medicine Physicians Physicians, Pathologists You might like a career in one of these industries: Professional, Science, & Technical 
##############################################
Title: Veterinary Assistants & Laboratory Animal Caretakers 
Information:
Knowledge Business customer service administrative services Arts and Humanities English language Math and Science biology Health medicine and dentistry 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Hand and Finger Use hold or move items with your hands keep your arm or hand steady 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Integrity Attention to Detail Self Control Cooperation Persistence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Medical software McAllister Software Systems AVImark Practice management software PMS 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $34,740 $25,270 $44,920 Check out my state Local Salary Info Find Jobs 
Explore More Animal Caretakers Medical Assistants Paramedics Phlebotomists Veterinary Technologists & Technicians You might like a career in one of these industries: Professional, Science, & Technical 
##############################################
Title: Veterinary Technologists & Technicians 
Information:
Knowledge Health medicine and dentistry Business customer service Arts and Humanities English language Math and Science biology 
Skills Basic Skills listening to others, not interrupting, and asking good questions thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Hand and Finger Use hold or move items with your hands keep your arm or hand steady Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Stress Tolerance Dependability Integrity Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Data base user interface and query software FileMaker Pro Microsoft Access Presentation software Microsoft PowerPoint Medical software McAllister Software Systems AVImark Veterinary practice management software PMS 
Education Education: (rated 3 of 5) high school diploma/GED or associate's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $38,240 $29,000 $54,680 Check out my state Local Salary Info Find Jobs 
Explore More Cardiovascular Technologists & Technicians Medical Assistants Surgical Assistants Surgical Technologists Veterinary Assistants & Laboratory Animal Caretakers You might like a career in one of these industries: Professional, Science, & Technical 
##############################################
Title: Video Game Designers 
Information:
Knowledge Engineering and Technology design computers and electronics Communications multimedia Math and Science arithmetic, algebra, geometry, calculus, or statistics psychology Arts and Humanities English language 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal read and understand what is written communicate by writing Ideas and Logic come up with lots of ideas create new and original ideas Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include creating, designing, and making your own rules. They do well at jobs that need: Adaptability/Flexibility Cooperation Attention to Detail Initiative Achievement/Effort Persistence 
Technology You might use software like this on the job: Development environment software C Microsoft Visual Studio Data base user interface and query software Blackboard software Microsoft SQL Server Object or component oriented development software C# Objective C 
Education Education: (rated 4 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $83,240 $43,100 $166,180 Check out my state Local Salary Info Find Jobs 
Explore More Graphic Designers Software Developers Special Effects Artists & Animators Web & Digital Interface Designers Web Developers You might like a career in one of these industries: Professional, Science, & Technical Media & Communication 
##############################################
Title: Waiters & Waitresses 
Information:
Knowledge Business customer service sales and marketing Arts and Humanities English language Manufactured or Agricultural Goods food production 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social looking for ways to help people understanding people's reactions 
Abilities Verbal communicate by speaking listen and understand what people say Attention do two or more things at the same time pay attention to something without being distracted Endurance exercise for a long time without getting out of breath 
Personality People interested in this work like activities that include helping people, teaching, and talking. They do well at jobs that need: Dependability Attention to Detail Self Control Cooperation Stress Tolerance Social Orientation 
Technology You might use software like this on the job: Web page creation and editing software Facebook Instant messaging software Blink Point of sale POS software Hospitality Control Solutions Aloha Point-of-Sale Intuit QuickBooks Point of Sale 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $29,120 $18,250 $55,360 Check out my state Local Salary Info Find Jobs 
Explore More Bartenders Dining Room & Cafeteria Attendants & Bartender Helpers Fast Food & Counter Workers Food Servers, Nonrestaurant Hosts & Hostesses, Restaurant, Lounge, & Coffee Shop You might like a career in one of these industries: Hotel & Food 
##############################################
Title: Watch & Clock Repairers 
Information:
Knowledge Business customer service management Engineering and Technology mechanical product and service development Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem listening to others, not interrupting, and asking good questions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use put together small parts with your fingers keep your arm or hand steady Ideas and Logic order or arrange things notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Integrity Analytical Thinking Self Control Dependability Independence 
Technology You might use software like this on the job: Accounting software Intuit QuickBooks Sage Software Sage50 Project management software GrenSoft WorkTracer Upland Consulting Group Repair Traq Spreadsheet software Microsoft Excel 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $48,370 $28,080 $75,870 Check out my state Local Salary Info Find Jobs 
Explore More Coin, Vending, & Amusement Machine Servicers & Repairers Electric Motor, Power Tool, & Related Repairers Grinding & Polishing Workers, Hand Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Timing Device Assemblers & Adjusters You might like a career in one of these industries: Retail Service 
##############################################
Title: Water & Wastewater Treatment Plant & System Operators 
Information:
Knowledge Math and Science chemistry biology Engineering and Technology mechanical product and service development Manufactured or Agricultural Goods manufacture and distribution of products Safety and Government public safety and security 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements listening to others, not interrupting, and asking good questions People and Technology Systems thinking about the pros and cons of different options and picking the best one measuring how well a system is working and how to improve it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic use rules to solve problems notice when problems happen 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Integrity Dependability Attention to Detail Independence Concern for Others Initiative 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Presentation software Microsoft PowerPoint Data base user interface and query software Database software Operational Data Store ODS software 
Education Education: (rated 3 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $51,600 $34,100 $81,210 Check out my state Local Salary Info Find Jobs 
Explore More Biomass Plant Technicians Chemical Plant & System Operators Petroleum Pump System Operators, Refinery Operators, & Gaugers Power Plant Operators Separating, Filtering, Clarifying, Precipitating, & Still Machine Setters, Operators, & Tenders You might like a career in one of these industries: Government Utilities 
##############################################
Title: Water Resource Specialists 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking listen and understand what people say Ideas and Logic make general rules or come up with answers from lots of detailed information notice when problems happen Math add, subtract, multiply, or divide choose the right type of math to solve a problem Visual Understanding see hidden patterns 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Integrity Analytical Thinking Attention to Detail Dependability Initiative Achievement/Effort 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software Geographic information system GIS software Presentation software Microsoft PowerPoint Analytical or scientific software Laboratory information management system LIMS Wallingford Software InfoWater 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $144,440 $73,730 $239,200+ Check out my state Local Salary Info Find Jobs 
Explore More Brownfield Redevelopment Specialists & Site Managers Conservation Scientists Environmental Restoration Planners Industrial Ecologists Water/Wastewater Engineers You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Water/Wastewater Engineers 
Information:
Knowledge Engineering and Technology product and service development design Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Business management 
Skills Basic Skills reading work related information thinking about the pros and cons of different ways to solve a problem People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information order or arrange things Math choose the right type of math to solve a problem add, subtract, multiply, or divide Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Analytical Thinking Attention to Detail Integrity Dependability Cooperation Initiative 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Civil 3D Autodesk Revit Presentation software Microsoft PowerPoint Analytical or scientific software Minitab NIWA Tideda 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $89,940 $61,040 $138,690 Check out my state Local Salary Info Find Jobs 
Explore More Brownfield Redevelopment Specialists & Site Managers Civil Engineers Environmental Engineers Environmental Science & Protection Technicians, Including Health Water Resource Specialists You might like a career in one of these industries: Professional, Science, & Technical Government Construction 
##############################################
Title: Weatherization Installers & Technicians 
Information:
Knowledge Engineering and Technology building and construction mechanical Business customer service management Education and Training teaching and course design Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Ideas and Logic notice when problems happen order or arrange things Verbal communicate by speaking listen and understand what people say 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Self Control Dependability Attention to Detail Concern for Others Cooperation Integrity 
Technology You might use software like this on the job: Data base user interface and query software Database software Microsoft Access Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $44,890 $31,420 $74,160 Check out my state Local Salary Info Find Jobs 
Explore More Insulation Workers, Floor, Ceiling, & Wall Insulation Workers, Mechanical Maintenance & Repair Workers, General Plumbers, Pipefitters, & Steamfitters Solar Thermal Installers & Technicians You might like a career in one of these industries: Construction 
##############################################
Title: Web & Digital Interface Designers 
Information:
Knowledge Knowledge information for this career will be available soon. 
Skills Skills information for this career will be available soon. 
Abilities Abilities information for this career will be available soon. 
Personality Information for this career will be available soon. 
Technology You might use software like this on the job: Web platform development software Bootstrap React Data base user interface and query software Blackboard software Transact-SQL Development environment software Apache Maven Oracle Java 2 Platform Enterprise Edition J2EE 
Education Education: (rated 4 of 5) Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $83,240 $43,100 $166,180 Check out my state Local Salary Info Find Jobs 
Explore More Computer Programmers Graphic Designers Software Developers Web Administrators Web Developers You might like a career in one of these industries: Professional, Science, & Technical Media & Communication 
##############################################
Title: Web Administrators 
Information:
Knowledge Engineering and Technology computers and electronics product and service development Arts and Humanities English language Communications multimedia Business customer service 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems measuring how well a system is working and how to improve it thinking about the pros and cons of different options and picking the best one 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Analytical Thinking Persistence Achievement/Effort Adaptability/Flexibility 
Technology You might use software like this on the job: Web platform development software Apache Tomcat jQuery Presentation software Microsoft PowerPoint Operating system software Shell script UNIX 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $98,740 $46,540 $166,780 Check out my state Local Salary Info Find Jobs 
Explore More Database Administrators Network & Computer Systems Administrators Software Developers Web & Digital Interface Designers Web Developers You might like a career in one of these industries: Professional, Science, & Technical Government 
##############################################
Title: Web Developers 
Information:
Knowledge Engineering and Technology computers and electronics Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Communications multimedia 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Ideas and Logic use rules to solve problems notice when problems happen Verbal listen and understand what people say read and understand what is written Visual Understanding see hidden patterns quickly compare groups of letters, numbers, pictures, or other things 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Analytical Thinking Persistence Dependability Adaptability/Flexibility Initiative 
Technology You might use software like this on the job: Web platform development software Bootstrap Vue.js Data base user interface and query software Blackboard software GraphQL Development environment software Apache Subversion SVN Oracle Java 2 Platform Enterprise Edition J2EE 
Education Education: (rated 3 of 5) bachelor's degree or certificate after high school usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $78,580 $40,460 $144,690 Check out my state Local Salary Info Find Jobs 
Explore More Computer Programmers Search Marketing Strategists Software Developers Web & Digital Interface Designers Web Administrators You might like a career in one of these industries: Professional, Science, & Technical Media & Communication 
##############################################
Title: Weighers, Measurers, Checkers, & Samplers, Recordkeeping 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Math and Science arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Business administrative services 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal read and understand what is written communicate by speaking Attention pay attention to something without being distracted Math add, subtract, multiply, or divide choose the right type of math to solve a problem 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Attention to Detail Dependability Integrity Cooperation Initiative Stress Tolerance 
Technology You might use software like this on the job: Enterprise resource planning ERP software Oracle JD Edwards EnterpriseOne SAP software Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $39,950 $30,000 $53,600 Check out my state Local Salary Info Find Jobs 
Explore More Inspectors, Testers, Sorters, Samplers, & Weighers Machine Feeders & Offbearers Packers & Packagers, Hand Production, Planning, & Expediting Clerks Shipping, Receiving, & Inventory Clerks You might like a career in one of these industries: Transportation & Storage Manufacturing Administration & Support Services Wholesale/Commercial Sales Retail 
##############################################
Title: Welders, Cutters, Solderers, & Brazers 
Information:
Knowledge Manufactured or Agricultural Goods manufacture and distribution of products Engineering and Technology mechanical 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use keep your arm or hand steady put together small parts with your fingers Ideas and Logic notice when problems happen use rules to solve problems 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Cooperation Initiative Self Control Integrity 
Technology You might use software like this on the job: Electronic mail software IBM Notes Microsoft Outlook Analytical or scientific software Scientific Software Group Filter Drain FD Value Analysis Data base user interface and query software Oracle Database Recordkeeping software 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $47,540 $35,380 $68,750 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Boilermakers Electrical & Electronic Equipment Assemblers Structural Metal Fabricators & Fitters Welding, Soldering, & Brazing Machine Setters, Operators, & Tenders You might like a career in one of these industries: Manufacturing Construction 
##############################################
Title: Welding, Soldering, & Brazing Machine Setters, Operators, & Tenders 
Information:
Knowledge Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology mechanical 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Initiative Cooperation Self Control Integrity 
Technology You might use software like this on the job: Operating system software Linux Microsoft Windows Presentation software Microsoft PowerPoint Electronic mail software Email software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $44,920 $31,930 $63,010 Check out my state Local Salary Info Find Jobs 
Explore More Aircraft Structure, Surfaces, Rigging, & Systems Assemblers Industrial Machinery Mechanics Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Structural Metal Fabricators & Fitters Welders, Cutters, Solderers, & Brazers You might like a career in one of these industries: Manufacturing 
##############################################
Title: Wellhead Pumpers 
Information:
Knowledge Engineering and Technology mechanical Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements thinking about the pros and cons of different ways to solve a problem Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Dependability Attention to Detail Integrity Initiative Self Control Independence 
Technology You might use software like this on the job: Spreadsheet software Microsoft Excel Industrial control software Moxa software Supervisory control and data acquisition SCADA software Electronic mail software Microsoft Outlook 
Education Education: (rated 2 of 5) high school diploma/GED or certificate after high school usually needed Get started on your career: Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $63,280 $36,100 $93,730 Check out my state Local Salary Info Find Jobs 
Explore More Gas Compressor & Gas Pumping Station Operators Gas Plant Operators Petroleum Pump System Operators, Refinery Operators, & Gaugers Pump Operators Rotary Drill Operators, Oil & Gas You might like a career in one of these industries: Mining, Oil, & Gas 
##############################################
Title: Wholesale & Retail Buyers 
Information:
Knowledge Business sales and marketing customer service Arts and Humanities English language Math and Science arithmetic, algebra, geometry, calculus, or statistics Engineering and Technology computers and electronics 
Skills Basic Skills listening to others, not interrupting, and asking good questions talking to others Social bringing people together to solve differences talking people into changing their minds or their behavior Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking listen and understand what people say Math choose the right type of math to solve a problem add, subtract, multiply, or divide Ideas and Logic use rules to solve problems notice when problems happen 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Cooperation Adaptability/Flexibility Dependability Attention to Detail Integrity Stress Tolerance 
Technology You might use software like this on the job: Enterprise resource planning ERP software Oracle JD Edwards EnterpriseOne SAP software Presentation software Microsoft PowerPoint Point of sale POS software Plexis Software Plexis POS Windward Software Windward System Five 
Education Education: (rated 3 of 5) bachelor's degree or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $67,620 $41,060 $116,370 Check out my state Local Salary Info Find Jobs 
Explore More Buyers & Purchasing Agents, Farm Products Purchasing Agents Purchasing Managers Sales Representatives, Wholesale & Manufacturing Securities, Commodities, & Financial Services Sales Agents You might like a career in one of these industries: Manufacturing Wholesale/Commercial Sales Government 
##############################################
Title: Wind Energy Development Managers 
Information:
Knowledge Business management accounting and economics Arts and Humanities English language Engineering and Technology building and construction product and service development Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information Problem Solving noticing a problem and figuring out the best way to solve it Resource Management managing your time and the time of other people selecting and managing the best workers for a job 
Abilities Verbal listen and understand what people say read and understand what is written Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Math choose the right type of math to solve a problem add, subtract, multiply, or divide 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Persistence Attention to Detail Integrity Initiative Adaptability/Flexibility Cooperation 
Technology You might use software like this on the job: Project management software Microsoft Project Oracle Primavera Systems Presentation software Microsoft PowerPoint Spreadsheet software Microsoft Excel 
Education Education: (rated 4 of 5) bachelor's degree or associate's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $128,620 $63,380 $218,550 Check out my state Local Salary Info Find Jobs 
Explore More Energy Engineers Hydroelectric Production Managers Solar Energy Systems Engineers Wind Energy Engineers Wind Energy Operations Managers You might like a career in one of these industries: Government 
##############################################
Title: Wind Energy Engineers 
Information:
Knowledge Engineering and Technology product and service development design Math and Science arithmetic, algebra, geometry, calculus, or statistics physics Arts and Humanities English language Business management 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem reading work related information People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Math choose the right type of math to solve a problem add, subtract, multiply, or divide Verbal read and understand what is written communicate by speaking Ideas and Logic use rules to solve problems make general rules or come up with answers from lots of detailed information 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Analytical Thinking Attention to Detail Initiative Adaptability/Flexibility Dependability Integrity 
Technology You might use software like this on the job: Analytical or scientific software ANSYS simulation software The MathWorks MATLAB Presentation software Microsoft PowerPoint Development environment software Microsoft .NET Framework Microsoft Visual Studio 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Find Licenses Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $104,600 $59,380 $168,320 Check out my state Local Salary Info Find Jobs 
Explore More Electrical Engineers Energy Engineers Mechanical Engineers Solar Energy Systems Engineers Wind Energy Development Managers You might like a career in one of these industries: Professional, Science, & Technical Government Manufacturing 
##############################################
Title: Wind Energy Operations Managers 
Information:
Knowledge Business management human resources (HR) Engineering and Technology mechanical product and service development Safety and Government public safety and security Math and Science arithmetic, algebra, geometry, calculus, or statistics 
Skills Basic Skills listening to others, not interrupting, and asking good questions keeping track of how well people and/or groups are doing in order to make improvements Social understanding people's reactions changing what is done based on other people's actions Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic notice when problems happen order or arrange things Visual Understanding quickly compare groups of letters, numbers, pictures, or other things see hidden patterns 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Leadership Dependability Integrity Attention to Detail Cooperation Initiative 
Technology You might use software like this on the job: Electronic mail software IBM Lotus Notes Microsoft Outlook Presentation software Microsoft PowerPoint Facilities management software Computerized maintenance management system CMMS Oracle Enterprise Asset Management eAM 
Education Education: (rated 3 of 5) associate's degree or bachelor's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $128,620 $63,380 $218,550 Check out my state Local Salary Info Find Jobs 
Explore More Geothermal Production Managers Hydroelectric Production Managers Wind Energy Development Managers Wind Energy Engineers Wind Turbine Service Technicians You might like a career in one of these industries: Government 
##############################################
Title: Wind Turbine Service Technicians 
Information:
Knowledge Engineering and Technology mechanical computers and electronics Arts and Humanities English language Safety and Government public safety and security Education and Training teaching and course design 
Skills Basic Skills thinking about the pros and cons of different ways to solve a problem keeping track of how well people and/or groups are doing in order to make improvements Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems figuring out how a system should work and how changes in the future will affect it measuring how well a system is working and how to improve it 
Abilities Hand and Finger Use put together small parts with your fingers hold or move items with your hands Verbal listen and understand what people say communicate by speaking Ideas and Logic notice when problems happen use rules to solve problems Attention pay attention to something without being distracted 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Cooperation Dependability Concern for Others Adaptability/Flexibility Self Control 
Technology You might use software like this on the job: Enterprise resource planning ERP software IBM Maximo Asset Management SAP software Presentation software Microsoft PowerPoint Industrial control software Supervisory control and data acquisition SCADA software Vestas Wind Systems A/S Vestas Remote Panel 
Education Education: (rated 3 of 5) certificate after high school or high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Bright New job opportunities are very likely in the future. Salary: $57,320 $45,150 $80,170 Check out my state Local Salary Info Find Jobs 
Explore More Geothermal Technicians Hydroelectric Plant Technicians Power Plant Operators Stationary Engineers & Boiler Operators Wind Energy Operations Managers You might like a career in one of these industries: Utilities Service Construction 
##############################################
Title: Woodworking Machine Setters, Operators, & Tenders 
Information:
Knowledge Engineering and Technology mechanical Manufactured or Agricultural Goods manufacture and distribution of products 
Skills Basic Skills keeping track of how well people and/or groups are doing in order to make improvements talking to others Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Hand and Finger Use hold or move items with your hands keep your arm or hand steady Controlled Movement quickly change the controls of a machine, car, truck or boat use your arms and/or legs together while sitting, standing, or lying down 
Personality People interested in this work like activities that include practical, hands-on problems and solutions. They do well at jobs that need: Attention to Detail Dependability Adaptability/Flexibility Independence Integrity Cooperation 
Technology You might use software like this on the job: Computer aided design CAD software Autodesk AutoCAD Dassault Systemes CATIA Video creation and editing software YouTube Operating system software Apple macOS Microsoft Windows 
Education Education: (rated 2 of 5) high school diploma/GED or no high school diploma/GED usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $36,830 $27,910 $49,510 Check out my state Local Salary Info Find Jobs 
Explore More Cutting & Slicing Machine Setters, Operators, & Tenders Grinding, Lapping, Polishing, & Buffing Machine Tool Setters, Operators, & Tenders, Metal & Plastic Lathe & Turning Machine Tool Setters, Operators, & Tenders, Metal & Plastic Milling & Planing Machine Setters, Operators, & Tenders, Metal & Plastic Sawing Machine Setters, Operators, & Tenders, Wood You might like a career in one of these industries: Manufacturing 
##############################################
Title: Word Processors & Typists 
Information:
Knowledge Business administrative services customer service Arts and Humanities English language Engineering and Technology computers and electronics 
Skills Basic Skills reading work related information listening to others, not interrupting, and asking good questions Social looking for ways to help people 
Abilities Verbal read and understand what is written listen and understand what people say Ideas and Logic order or arrange things group things in different ways 
Personality People interested in this work like activities that include data, detail, and regular routines. They do well at jobs that need: Integrity Attention to Detail Cooperation Dependability Independence Initiative 
Technology You might use software like this on the job: Office suite software Corel WordPerfect Office Suite Microsoft Office software Presentation software Microsoft PowerPoint Customer relationship management CRM software Act! Blackbaud CRM 
Education Education: (rated 2 of 5) high school diploma/GED or some college usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $44,330 $31,140 $59,640 Check out my state Local Salary Info Find Jobs 
Explore More Court Reporters & Simultaneous Captioners Data Entry Keyers File Clerks Medical Transcriptionists Office Clerks, General You might like a career in one of these industries: Government Education 
##############################################
Title: Writers & Authors 
Information:
Knowledge Business sales and marketing customer service Communications multimedia Engineering and Technology computers and electronics 
Skills Basic Skills writing things for co-workers or customers reading work related information Social changing what is done based on other people's actions talking people into changing their minds or their behavior Problem Solving noticing a problem and figuring out the best way to solve it 
Abilities Verbal communicate by writing read and understand what is written Ideas and Logic come up with lots of ideas create new and original ideas 
Personality People interested in this work like activities that include leading, making decisions, and business. They do well at jobs that need: Dependability Persistence Cooperation Initiative Attention to Detail Adaptability/Flexibility 
Technology You might use software like this on the job: Video creation and editing software Adobe Systems Adobe After Effects YouTube Web page creation and editing software LinkedIn WordPress Cloud-based data access and sharing software Microsoft SharePoint Slack 
Education Education: (rated 4 of 5) bachelor's degree or some college usually needed Get started on your career: Find Training Apprenticeship.gov 
Job Outlook Average New job opportunities are likely in the future. Salary: $73,150 $39,610 $161,260 Check out my state Local Salary Info Find Jobs 
Explore More Advertising Sales Agents Editors News Analysts, Reporters, & Journalists Poets, Lyricists & Creative Writers Public Relations Specialists You might like a career in one of these industries: Professional, Science, & Technical Media & Communication 
##############################################
Title: Zoologists & Wildlife Biologists 
Information:
Knowledge Math and Science biology arithmetic, algebra, geometry, calculus, or statistics Arts and Humanities English language Business customer service management Safety and Government law and government 
Skills Basic Skills listening to others, not interrupting, and asking good questions reading work related information Problem Solving noticing a problem and figuring out the best way to solve it People and Technology Systems thinking about the pros and cons of different options and picking the best one figuring out how a system should work and how changes in the future will affect it 
Abilities Verbal communicate by speaking communicate by writing Ideas and Logic make general rules or come up with answers from lots of detailed information use rules to solve problems Attention pay attention to something without being distracted do two or more things at the same time Math add, subtract, multiply, or divide 
Personality People interested in this work like activities that include ideas, thinking, and figuring things out. They do well at jobs that need: Cooperation Integrity Initiative Dependability Attention to Detail Adaptability/Flexibility 
Technology You might use software like this on the job: Geographic information system ESRI ArcGIS software Geographic information system GIS software Presentation software Microsoft PowerPoint Analytical or scientific software SAS Statistical software 
Education Education: (rated 4 of 5) bachelor's degree or master's degree usually needed Get started on your career: Find Training Find Certifications Apprenticeship.gov 
Job Outlook Below Average New job opportunities are less likely in the future. Salary: $67,430 $44,610 $104,750 Check out my state Local Salary Info Find Jobs 
Explore More Biologists Conservation Scientists Environmental Restoration Planners Range Managers Soil & Plant Scientists You might like a career in one of these industries: Government Professional, Science, & Technical 
##############################################`