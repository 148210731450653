import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  Tabs,
  Typography,
  Tab,
  Grid,
  Chip,
  TextField,
  InputAdornment,
} from "@mui/material";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";

let theme = createTheme();
theme.typography.btn1 = {
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Poppins",
  fontStyle: "normal",
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
  "@media (min-width:600px)": {
    fontSize: "15px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "15px",
    fontWeight: 600,
  },
};

const columns = [
  { id: "title", label: "Title", minWidth: 100 },
  { id: "desc", label: "Decription", minWidth: 170 },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProdTab = styled(Tab)({
  textTransform: "none",
  fontFamily: "Gotham, sans-serif",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  color: "#9B9FAC",
  "& .MuiTab-root.Mui-selected": {
    color: "red",
  },
  // "&:focus": {
  //   color: "#616DED",
  // }
});
const tableHeadStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "15px",
  color: "#9B9FAC",
  backgroundColor: "#F0F1FF",
};

const tableBodyStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  color: "#242424",
};

const actionsBtnStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  dispaly: "flex",
  textTransform: "none",
  padding: "10px 15px",
  minWidth: "unset",
  width: "100%",
  borderRadius: "5px",
  justifyContent: "flex-start",
  "&:hover": {
    background: "rgba(97, 109, 237, 0.15)",
    color: "#0A9929",
  },
};

const assignmentBtnStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  dispaly: "flex",
  textTransform: "none",
  padding: "10px 15px",
  minWidth: "unset",
  width: "100%",
  borderRadius: "5px",
  "&:hover": {
    background: "rgba(51, 192, 82, 0.15)",
    color: "#0A9929",
  },
};

const staticButtonStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "11.1486px",
  display: "flex",
  textTransform: "none",
  padding: "10px 15px",
  minWidth: "unset",
  width: "100%",
  borderRadius: "5px",
};

const tableContainerStyle = {
  overflowX: "auto",
  overflowY: "auto",
  height: "53vh",
};

const RedButton = styled(Chip)`
  &:hover {
    background-color: rgba(223, 0, 0, 0.2);
    color: #ff2f2f;
  }
  background-color: rgba(223, 0, 0, 0.2);
  color: #ff2f2f;
  margin-bottom: 5px;
`;

const GreenButton = styled(Chip)`
  background: rgba(0, 223, 9, 0.2);
  color: #09a835;
  &:hover {
    background: rgba(0, 223, 9, 0.2);
    color: #09a835;
  }
  margin-bottom: 5px;
`;

const YellowButton = styled(Chip)`
  &:hover {
    background-color: rgba(242, 204, 2, 0.3);
    color: #a68b00;
  }
  background-color: rgba(242, 204, 2, 0.3);
  color: #a68b00;
  margin-bottom: 5px;
`;

const BlueButton = styled(Chip)`
  &:hover {
    background-color: #cde1fa;
    color: #0066c3;
  }
  background-color: #cde1fa;
  color: #0066c3;
  margin-bottom: 5px;
`;

export default function PodcastTable(props) {
  const { podcasts, setPage, setSelectedPodcast } = props;
  const [value, setValue] = React.useState(0);
  const [filteredPodcasts, setFilteredPodcasts] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchedPodcasts, setSearchedPodcasts] = useState([]);

  const handleQueryChange = (event) => {
    event.preventDefault();
    const _change = event.target.value;
    setSearchQuery(_change);
    if (_change == "") {
      setSearchedPodcasts(filteredPodcasts);
    } else {
      setSearchedPodcasts(
        filteredPodcasts.filter((item) =>
          item?.title.toLowerCase().includes(_change.toLowerCase())
        )
      );
    }
  };

  const getSubmissionButton = (submission) => {
    if (submission == "submitted") {
      return <GreenButton sx={staticButtonStyle} label="Submitted" />;
    } else if (submission == "resubmitted") {
      return <BlueButton sx={staticButtonStyle} label="Resubmitted" />;
    } else if (submission == "not submitted") {
      return <RedButton sx={staticButtonStyle} label="Not Submitted" />;
    } else {
      return <Button>Unknown</Button>;
    }
  };

  const getApprovalButton = (approval) => {
    if (approval == "approved") {
      return <GreenButton sx={staticButtonStyle} label="Approved" />;
    } else if (approval == "pending") {
      return <YellowButton sx={staticButtonStyle} label="Pending" />;
    } else if (approval == "not approved") {
      return <RedButton sx={staticButtonStyle} label="Not Approved" />;
    } else {
      return <Button>Unknown</Button>;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchQuery("");
    if (newValue == 0) {
      setFilteredPodcasts(podcasts);
      setSearchedPodcasts(podcasts);
    } else if (newValue == 1) {
      const _completePodcasts = podcasts.filter(
        (item) =>
          item.submission != "not submitted" && item.approval == "approved"
      );
      setFilteredPodcasts(_completePodcasts);
      setSearchedPodcasts(_completePodcasts);
    } else if (newValue == 2) {
      const _nonSubmittedPodcasts = podcasts.filter(
        (item) => item.submission == "not submitted"
      );
      setFilteredPodcasts(_nonSubmittedPodcasts);
      setSearchedPodcasts(_nonSubmittedPodcasts);
    } else if (newValue == 3) {
      const _pendingCheckPodcasts = podcasts.filter(
        (item) =>
          item.submission != "not submitted" && item.approval == "pending"
      );
      setFilteredPodcasts(_pendingCheckPodcasts);
      setSearchedPodcasts(_pendingCheckPodcasts);
    } else {
      setFilteredPodcasts([]);
    }
  };

  useEffect(() => {
    setFilteredPodcasts(podcasts);
    setSearchedPodcasts(podcasts);
  }, [podcasts]);

  return (
    <>
      <Grid
        container
        className="d-flex justify-content-between align-items-center mb-1"
      >
        <Grid item lg={6} md={6} sm={12} className="d-flex align-items-center">
          <Button
            className="mb-1"
            variant="text"
            sx={{ minWidth: "unset", width: "fit-content" }}
            startIcon={
              <img
                width="150%"
                height="150%"
                src="assets/img/icon/back.svg"
                className="logo"
                alt=""
              />
            }
            onClick={() => {
              props.setPage("main");
            }}
          />
          <h1
            style={{
              fontfamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "20px",
              color: "#000",
            }}
          >
            Podcast Assignments
          </h1>
        </Grid>

        <Grid item lg={4} md={4} sm={12} className="d-flex align-items-center">
          <TextField
            value={searchQuery}
            fullWidth
            onChange={handleQueryChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            placeholder="Search podcast"
          />
        </Grid>
      </Grid>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#616DED",
              },
            }}
          >
            <ProdTab
              label="All Assignments"
              {...a11yProps(0)}
              sx={{ textTransform: "none" }}
            />
            <ProdTab label="Submitted" {...a11yProps(1)} />
            <ProdTab label="Not Submitted" {...a11yProps(2)} />
            <ProdTab label="Pending Approval" {...a11yProps(3)} />
          </Tabs>
        </Box>
        {!!podcasts.length ? (
          <TableContainer
            sx={{ overflowX: "hidden", overflowY: "scroll", height: "55vh" }}
            className="mt-1"
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F0F1FF" }}>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ ...tableHeadStyle, padding: "0px 10px" }}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell sx={{ ...tableHeadStyle, padding: "0px 10px" }}>
                    Actions
                  </TableCell>
                  <TableCell sx={{ ...tableHeadStyle, padding: "0px 10px" }}>
                    Status/Approval
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(searchedPodcasts || []).map((row) => {
                  const isSubmissionDisabled =
                    row.approval == "approved" ||
                    (row.approval == "pending" &&
                      row.submission != "not submitted");
                  const buttonText =
                    row.approval == "approved"
                      ? "Completed!"
                      : row.approval == "pending" &&
                        row.submission != "not submitted"
                        ? "Awaiting Check"
                        : "Start Assignment";
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row?.id}
                    >
                      <TableCell
                        sx={{
                          ...tableBodyStyle,
                          borderBottom: "none",
                        }}
                      >
                        {row?.podcast?.title}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...tableBodyStyle,
                          borderBottom: "none",
                        }}
                      >
                        <span dangerouslySetInnerHTML={{ __html: row?.podcast?.description }} />
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Box className="d-flex flex-column gap-2">
                          <Button
                            style={{ color: "#33C052" }}
                            sx={actionsBtnStyle}
                            disabled={isSubmissionDisabled}
                            onClick={() => {
                              setSelectedPodcast(row);
                              setPage("chat");
                            }}
                          >
                            {buttonText}
                          </Button>
                          <Button
                            style={{ color: "#616DED" }}
                            sx={actionsBtnStyle}
                            onClick={() => {
                              setSelectedPodcast(row);
                              setPage("details");
                            }}
                          >
                            Details
                          </Button>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Box className="d-flex flex-column gap-2">
                          {getSubmissionButton(row.submission)}
                          {getApprovalButton(row.approval)}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Box>
    </>
  );
}
