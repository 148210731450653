import React from 'react'

const CalculatorIcon = ({color}) => {
  return (
    <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_2905)">
<path d="M17.0554 27.4789C16.5108 27.3063 16.114 26.9867 15.9985 26.398C15.9782 26.2794 15.9691 26.1593 15.9716 26.039C15.9716 21.8567 15.9716 17.6746 15.9716 13.4927C15.9716 12.5954 16.5184 12.0432 17.4232 12.0432C20.5089 12.0432 23.5939 12.055 26.6789 12.0343C27.5028 12.0287 28.0786 12.3048 28.3379 13.1145V26.3938C28.1775 26.957 27.8153 27.3187 27.2512 27.4789H17.0554ZM27.4538 19.7814C27.4538 17.6896 27.4538 15.5977 27.4538 13.5058C27.4538 13.0806 27.3038 12.9267 26.8793 12.9267C23.7328 12.9267 20.5861 12.9267 17.4391 12.9267C17.0008 12.9267 16.8536 13.0786 16.8536 13.521C16.8536 17.679 16.8536 21.8369 16.8536 25.9949C16.8536 26.4532 16.9981 26.5981 17.455 26.5981H26.842C27.3155 26.5981 27.4538 26.4601 27.4538 25.9769V19.7814Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M10.1734 27.4796C6.31977 27.3567 2.53109 25.3571 2.0168 21.2137C1.60206 20.1749 1.96773 14.0291 3.42624 14.33C3.65366 14.4329 3.73454 14.6751 3.63361 14.9436C1.8592 18.7744 2.45298 24.6828 7.09811 26.0287C8.72598 26.5706 10.4091 26.6776 12.1041 26.5623C12.9253 26.5071 13.7382 26.3324 14.5545 26.2048C14.831 26.1613 15.0522 26.2772 15.1179 26.5264C15.1338 26.5851 15.1369 26.6465 15.1271 26.7065C15.1173 26.7665 15.0947 26.8237 15.0609 26.8743C15.0272 26.9249 14.9829 26.9677 14.9312 26.9999C14.8795 27.032 14.8216 27.0527 14.7612 27.0606C13.2432 27.3636 11.7232 27.5562 10.1734 27.4796Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M15.5766 7.55397C16.5961 7.74102 17.5169 8.3574 17.2577 9.52044C17.1898 9.81971 17.0333 10.0917 16.8086 10.3009C16.5838 10.5102 16.3012 10.6471 15.9975 10.6938C13.0024 10.7815 9.99756 10.7014 6.99965 10.7228C6.43768 10.6849 5.49206 12.2703 4.99852 12.7045C4.74068 13.0061 4.33976 12.9806 4.2029 12.6513C3.96926 12.2054 5.40082 10.972 5.69183 10.5206C4.30935 9.43347 4.92524 7.63611 6.70104 7.62161C5.69252 5.96506 4.24368 4.24984 4.31764 2.21573C4.51327 1.00921 5.69943 1.29634 6.44528 1.83403C7.27477 2.33307 8.45817 2.63677 9.21439 1.85888C10.395 0.742086 11.8245 0.740016 13.0114 1.85888C13.5346 2.3517 14.1485 2.44903 14.8086 2.24541C15.4079 2.0556 15.9505 1.71945 16.5139 1.45164C17.2563 1.14794 17.8964 1.5676 17.9365 2.37172C17.8701 4.31472 16.5719 5.9692 15.5766 7.55397ZM8.91024 7.63956C8.91024 6.89204 8.90402 6.18593 8.91577 5.44394C8.92061 5.1782 9.08927 5.01185 9.32568 5.00081C10.1324 4.8814 9.68858 7.05493 9.79572 7.61678H12.4452C12.5489 7.05632 12.1245 4.91522 12.8745 5.00081C13.6625 4.90901 13.2201 7.0715 13.3273 7.63956C13.7517 7.59884 14.2259 7.77761 14.5418 7.467C14.9745 7.07426 18.7258 0.982286 16.2954 2.54773C14.6206 3.53269 13.3231 3.45262 11.9012 2.10736C11.2335 1.75189 10.4621 1.86509 9.90424 2.41797C8.88812 3.52234 7.23882 3.36979 6.05473 2.62504C3.41005 0.801446 7.25127 7.06805 7.73513 7.52429C8.04896 7.76725 8.4955 7.58917 8.91024 7.63956ZM11.1042 8.5196H6.70933C6.28077 8.46646 5.82524 8.70183 5.82386 9.15669C5.79413 9.62535 6.2386 9.89592 6.68376 9.84692C9.63904 9.84692 12.5943 9.84692 15.5496 9.84692C16.5484 9.99946 16.7717 8.53548 15.6789 8.52098C14.154 8.51776 12.6289 8.5173 11.1035 8.5196H11.1042Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M11.5764 18.1781C15.6464 18.9332 15.6547 23.3031 11.5605 23.9526C11.7492 25.0852 10.5195 25.1632 10.6785 24.0029C9.28562 23.6261 7.84992 23.0138 7.61213 21.4305C7.53679 21.1109 7.59969 20.6602 7.99784 20.6539C8.86604 20.6167 8.09738 22.8026 10.6626 23.0159V19.1437C6.73011 18.4355 6.47366 14.1782 10.4614 13.4293C10.9515 13.3879 10.384 12.5534 11.0704 12.4989C11.6462 12.4409 11.4955 13.0683 11.6234 13.381C11.7022 13.4721 11.913 13.4438 12.0623 13.4818C13.3957 13.7896 14.5778 14.7304 14.6379 16.1909C14.6828 16.7831 13.8374 16.8494 13.7662 16.2634C13.5755 15.1218 12.7432 14.3584 11.5764 14.3052V18.1781ZM10.666 14.2831C7.75176 14.6545 7.78356 17.8806 10.666 18.156V14.2831ZM11.575 23.0332C14.4443 22.6991 14.4457 19.4523 11.575 19.1534V23.0332Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M22.1426 17.7755C21.1513 17.7755 20.1606 17.7755 19.1703 17.7755C18.5212 17.7755 18.188 17.4386 18.1859 16.7871C18.1859 16.2535 18.1818 15.7193 18.1859 15.1857C18.1908 14.6101 18.5481 14.2546 19.126 14.2539C21.143 14.2539 23.1599 14.2539 25.1764 14.2539C25.7578 14.2539 26.1158 14.6052 26.122 15.1809C26.128 15.7404 26.128 16.3 26.122 16.8595C26.1158 17.4248 25.764 17.772 25.1951 17.7748C24.1769 17.7782 23.1594 17.7755 22.1426 17.7755ZM19.0866 15.4238V16.5972C19.0866 16.6721 19.1164 16.7439 19.1694 16.7969C19.2224 16.8498 19.2943 16.8795 19.3693 16.8795H24.9359C25.0109 16.8795 25.0828 16.8498 25.1358 16.7969C25.1888 16.7439 25.2186 16.6721 25.2186 16.5972V15.4238C25.2186 15.349 25.1888 15.2772 25.1358 15.2242C25.0828 15.1713 25.0109 15.1415 24.9359 15.1415H19.3693C19.2943 15.1415 19.2224 15.1713 19.1694 15.2242C19.1164 15.2772 19.0866 15.349 19.0866 15.4238Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M19.2778 21.7448C19.0628 21.7448 18.8472 21.7517 18.6315 21.7448C18.3612 21.7351 18.187 21.5591 18.1863 21.3099C18.1841 21.2515 18.194 21.1932 18.2154 21.1388C18.2368 21.0843 18.2693 21.0349 18.3108 20.9936C18.3523 20.9523 18.4019 20.92 18.4565 20.8988C18.5111 20.8776 18.5695 20.8678 18.628 20.8703C19.0677 20.8613 19.5073 20.8606 19.9469 20.8703C20.0599 20.8743 20.167 20.9218 20.2456 21.0029C20.3243 21.084 20.3685 21.1923 20.3689 21.3052C20.3694 21.4181 20.326 21.5267 20.248 21.6084C20.17 21.6901 20.0633 21.7385 19.9504 21.7434C19.7264 21.7524 19.5025 21.7448 19.2778 21.7448Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M22.3834 19.1043C22.5991 19.1043 22.8147 19.0981 23.0297 19.1043C23.2986 19.1147 23.4749 19.2941 23.4742 19.5419C23.4753 19.5982 23.4652 19.6542 23.4445 19.7066C23.4239 19.759 23.393 19.8068 23.3537 19.8472C23.3145 19.8876 23.2676 19.9199 23.2158 19.9421C23.1639 19.9644 23.1082 19.9761 23.0518 19.9768C22.6039 19.9899 22.1532 19.9892 21.7074 19.9768C21.6487 19.9776 21.5905 19.9662 21.5364 19.9435C21.4824 19.9208 21.4336 19.8871 21.3932 19.8447C21.3527 19.8022 21.3215 19.7519 21.3015 19.6968C21.2814 19.6417 21.273 19.5831 21.2767 19.5247C21.2843 19.279 21.4627 19.1147 21.7371 19.1023H22.3834V19.1043Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M25.4551 23.5056C25.2394 23.5056 25.0238 23.5125 24.8088 23.5056C24.5454 23.496 24.3581 23.3061 24.3609 23.0673C24.3618 22.9535 24.4071 22.8444 24.4873 22.7635C24.5675 22.6825 24.6761 22.636 24.7901 22.6339C25.2381 22.6207 25.6887 22.6207 26.1353 22.6339C26.1927 22.6344 26.2494 22.6461 26.3022 22.6685C26.355 22.6909 26.4029 22.7234 26.4431 22.7643C26.4833 22.8052 26.5151 22.8535 26.5365 22.9067C26.558 22.9598 26.5688 23.0166 26.5683 23.0739C26.5678 23.1312 26.5561 23.1878 26.5336 23.2405C26.5112 23.2933 26.4786 23.3411 26.4377 23.3812C26.3968 23.4214 26.3483 23.4531 26.2951 23.4746C26.242 23.496 26.185 23.5068 26.1277 23.5063C25.9037 23.5125 25.6798 23.5056 25.4551 23.5056Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M25.4643 25.2681C25.2403 25.2681 25.0157 25.2764 24.7917 25.2681C24.6782 25.2679 24.5692 25.223 24.4886 25.1432C24.408 25.0633 24.3622 24.9549 24.3611 24.8415C24.3574 24.7321 24.3963 24.6254 24.4696 24.544C24.5429 24.4625 24.645 24.4126 24.7544 24.4046C25.2279 24.386 25.7028 24.386 26.1763 24.4046C26.2857 24.4126 26.3878 24.4626 26.4611 24.5443C26.5343 24.6259 26.5729 24.7327 26.5689 24.8422C26.5674 24.9557 26.5213 25.064 26.4404 25.1437C26.3596 25.2234 26.2505 25.2681 26.1369 25.2681C25.9143 25.2764 25.6883 25.2681 25.4643 25.2681Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M25.4787 19.1043C25.6943 19.1043 25.91 19.0981 26.125 19.1043C26.1824 19.1036 26.2393 19.1141 26.2926 19.1353C26.3459 19.1565 26.3945 19.188 26.4356 19.2279C26.4767 19.2679 26.5095 19.3156 26.5322 19.3682C26.5549 19.4208 26.5669 19.4774 26.5677 19.5347C26.5685 19.592 26.558 19.6488 26.5367 19.7021C26.5155 19.7553 26.4839 19.8038 26.4439 19.8449C26.4039 19.8859 26.3562 19.9187 26.3035 19.9413C26.2508 19.964 26.1941 19.976 26.1367 19.9768C25.6888 19.9885 25.2381 19.9885 24.7916 19.9768C24.7329 19.9779 24.6746 19.9672 24.6201 19.9454C24.5657 19.9235 24.5162 19.8909 24.4746 19.8495C24.433 19.8082 24.4002 19.7588 24.3782 19.7045C24.3561 19.6502 24.3453 19.592 24.3463 19.5334C24.3472 19.4748 24.36 19.417 24.3839 19.3634C24.4077 19.3099 24.4421 19.2616 24.485 19.2217C24.528 19.1817 24.5785 19.1507 24.6337 19.1307C24.6888 19.1107 24.7475 19.1019 24.8061 19.105C25.0301 19.1002 25.254 19.1043 25.4787 19.1043Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M19.3052 19.104C19.5202 19.104 19.7358 19.0964 19.9508 19.104C20.0626 19.1069 20.1691 19.1526 20.2481 19.2317C20.3271 19.3107 20.3727 19.4171 20.3755 19.5287C20.3782 19.6404 20.3379 19.7488 20.2629 19.8316C20.1878 19.9145 20.0838 19.9653 19.9722 19.9737C19.516 19.9882 19.0584 19.9875 18.6022 19.9737C18.5458 19.9731 18.4902 19.9612 18.4385 19.9387C18.3869 19.9161 18.3403 19.8834 18.3016 19.8425C18.2629 19.8016 18.2329 19.7533 18.2132 19.7006C18.1936 19.6478 18.1849 19.5917 18.1875 19.5354C18.1875 19.2842 18.3603 19.1165 18.6333 19.1006H19.3059L19.3052 19.104Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M22.3849 21.7453C22.161 21.7453 21.9363 21.7529 21.7124 21.7453C21.654 21.7462 21.596 21.7352 21.542 21.7129C21.488 21.6907 21.4391 21.6577 21.3983 21.616C21.3574 21.5743 21.3256 21.5247 21.3046 21.4702C21.2836 21.4158 21.2739 21.3577 21.2762 21.2994C21.2758 21.243 21.2867 21.1872 21.3082 21.1351C21.3297 21.083 21.3614 21.0357 21.4015 20.996C21.4415 20.9562 21.4891 20.9249 21.5414 20.9037C21.5937 20.8826 21.6497 20.8721 21.7062 20.8728C22.1541 20.8627 22.6022 20.8627 23.0506 20.8728C23.107 20.873 23.1628 20.8844 23.2148 20.9063C23.2668 20.9282 23.3138 20.9603 23.3533 21.0006C23.3927 21.0408 23.4237 21.0886 23.4445 21.1409C23.4653 21.1933 23.4754 21.2493 23.4743 21.3056C23.4743 21.5527 23.2988 21.7294 23.0313 21.7474C23.014 21.7474 22.9967 21.7474 22.9794 21.7474H22.3849V21.7453Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M25.4552 21.7451C25.2409 21.7451 25.0259 21.752 24.8116 21.7451C24.7542 21.7455 24.6972 21.7347 24.6439 21.7131C24.5907 21.6916 24.5422 21.6598 24.5012 21.6196C24.4603 21.5793 24.4277 21.5314 24.4052 21.4786C24.3828 21.4257 24.3711 21.369 24.3706 21.3116C24.3702 21.2542 24.381 21.1973 24.4026 21.1442C24.4242 21.091 24.456 21.0426 24.4963 21.0017C24.5366 20.9608 24.5846 20.9282 24.6375 20.9058C24.6904 20.8835 24.7473 20.8717 24.8047 20.8712C25.2423 20.8616 25.6805 20.8623 26.1181 20.8712C26.1755 20.8708 26.2325 20.8816 26.2858 20.9032C26.339 20.9247 26.3875 20.9565 26.4285 20.9968C26.4694 21.037 26.502 21.0849 26.5245 21.1378C26.5469 21.1906 26.5586 21.2473 26.5591 21.3047C26.5595 21.3621 26.5487 21.419 26.5271 21.4722C26.5055 21.5254 26.4737 21.5738 26.4334 21.6147C26.3931 21.6556 26.3451 21.6881 26.2922 21.7105C26.2393 21.7329 26.1824 21.7446 26.125 21.7451C25.9017 21.752 25.6784 21.7451 25.4552 21.7451Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M22.3675 23.5056C22.1518 23.5056 21.9362 23.5125 21.7212 23.5056C21.453 23.496 21.2754 23.3158 21.276 23.0694C21.2745 23.013 21.2842 22.9569 21.3047 22.9043C21.3251 22.8517 21.3559 22.8037 21.3952 22.7631C21.4344 22.7225 21.4814 22.6902 21.5334 22.668C21.5853 22.6458 21.6412 22.6342 21.6977 22.6339C22.1546 22.6214 22.6115 22.6201 23.0677 22.6339C23.1242 22.6361 23.1796 22.6495 23.2307 22.6734C23.2819 22.6973 23.3277 22.7312 23.3655 22.7731C23.4033 22.815 23.4324 22.864 23.4509 22.9172C23.4695 22.9705 23.4771 23.0269 23.4735 23.0832C23.4666 23.3213 23.2972 23.4925 23.0394 23.5029C22.8161 23.5125 22.5915 23.5056 22.3675 23.5056Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M22.3537 25.2683C22.1387 25.2683 21.9224 25.2765 21.7074 25.2683C21.6512 25.2692 21.5954 25.2589 21.5433 25.2381C21.4911 25.2173 21.4436 25.1863 21.4035 25.147C21.3635 25.1077 21.3316 25.0609 21.3099 25.0091C21.2881 24.9574 21.2768 24.9019 21.2768 24.8458C21.2731 24.7895 21.2809 24.733 21.2997 24.6797C21.3185 24.6265 21.3479 24.5776 21.3862 24.536C21.4244 24.4944 21.4707 24.461 21.5222 24.4377C21.5737 24.4144 21.6294 24.4018 21.686 24.4006C22.1422 24.3834 22.5998 24.3834 23.0553 24.4006C23.1139 24.4016 23.1716 24.4147 23.2248 24.439C23.2781 24.4634 23.3257 24.4985 23.3647 24.5421C23.4036 24.5858 23.4331 24.637 23.4513 24.6926C23.4694 24.7482 23.4758 24.807 23.4701 24.8652C23.4562 25.1012 23.2869 25.2558 23.0235 25.2724C23.0062 25.2724 22.989 25.2724 22.9717 25.2724H22.3496L22.3537 25.2683Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M19.2782 23.5064C19.0625 23.5064 18.8469 23.5126 18.6319 23.5064C18.3554 23.4968 18.1867 23.3263 18.1853 23.075C18.1832 23.0166 18.1932 22.9583 18.2146 22.9038C18.236 22.8494 18.2683 22.7999 18.3097 22.7585C18.3511 22.717 18.4005 22.6845 18.455 22.663C18.5095 22.6415 18.5678 22.6314 18.6263 22.6333C19.0655 22.6222 19.5049 22.6222 19.9445 22.6333C20.0032 22.6319 20.0614 22.6427 20.1156 22.665C20.1698 22.6873 20.2188 22.7206 20.2595 22.7628C20.3002 22.8049 20.3316 22.8551 20.3519 22.91C20.3722 22.965 20.3808 23.0235 20.3773 23.0819C20.3724 23.3318 20.2003 23.4961 19.9245 23.5071C19.7102 23.5119 19.4945 23.5064 19.2782 23.5064Z" fill={color} stroke={color} stroke-width="0.355942"/>
<path d="M19.2714 25.2692C19.0557 25.2692 18.84 25.2761 18.6251 25.2692C18.361 25.2581 18.1972 25.0959 18.1875 24.855C18.1826 24.7967 18.1899 24.7379 18.2091 24.6825C18.2282 24.6271 18.2586 24.5763 18.2985 24.5333C18.3384 24.4903 18.3868 24.4561 18.4406 24.4328C18.4945 24.4095 18.5526 24.3977 18.6112 24.3981C19.0585 24.3829 19.5099 24.3829 19.9543 24.3981C20.013 24.3976 20.0711 24.4094 20.125 24.4325C20.1789 24.4557 20.2273 24.4899 20.2673 24.5328C20.3072 24.5757 20.3377 24.6265 20.3569 24.6819C20.3761 24.7372 20.3835 24.796 20.3787 24.8543C20.3698 25.0952 20.2059 25.2581 19.9426 25.2685C19.7193 25.2761 19.4946 25.2692 19.2714 25.2692Z" fill={color} stroke={color} stroke-width="0.355942"/>
</g>
<defs>
<clipPath id="clip0_1_2905">
<rect width="28.48" height="28.48" fill="white" transform="translate(0.859863 0.0205078)"/>
</clipPath>
</defs>
</svg>

  )
}

export default CalculatorIcon