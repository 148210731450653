import React from 'react';

import { AiOutlineQuestionCircle } from "react-icons/ai"

export default function Header() {
    return (<>
        <div className="appHeader">
            <div className="left">
                <a href="#" className="headerButton goBack" onClick={() => window.history.go(-1)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div className="pageTitle">
                Select Your Account
            </div>
            <div className="right">
                <AiOutlineQuestionCircle />
            </div>
        </div>
    </>)
}
