import { Box, Button, Typography } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from "react";
import { GetInterestBasedjobsBykeyword } from "../_api/get-jobs-by-keyword";
import "./index.css"
const DreamJobInput = ({ setRanderState, setRecomendedJobs }) => {
    const [isloading, setisloading] = useState(false)
    const [keyword, setKeyword] = useState("")
    // GetInterestBasedjobsBykeyword
    const getInterestBasedjobs = async () => {
        setisloading(true);
        try {
            const response = await GetInterestBasedjobsBykeyword(keyword)
            console.log("🚀 ~ getInterestBasedjobs ~ response:", response)
            setRecomendedJobs(response)
            setisloading(false);
            setRanderState(2);
        } catch (error) {
            setisloading(false);
            console.error('Error sending message to ChatGPT:', error);
        }
    };
    return (
        <Box width={"50%"} display={"flex"} flexDirection={'column'} alignItems={'center'} height={'100%'} justifyContent={'space-between'} >
            {isloading ?
                <Box
                    display={"flex"}
                    flexDirection={'column'}
                    gap={3}
                    alignItems={'center'}>
                    <img src='./gif/loading.gif' style={{width: 100 }} />
                    <Typography fontFamily={'Poppins'} variant='h4'>Discovering Jobs</Typography>
                </Box> :
                <>
                    <Box
                        display="flex"
                        alignItems="center"
                        padding={0}
                        gap={0.3}

                    >
                        <div style={{ textAlign: 'center', padding: '5px' }}>
                            <Typography variant="body1" fontWeight={'bold'} paragraph>
                                Describe your dream job in a few words or enter key words
                            </Typography>
                            <div class="input-container">
                                <SearchIcon />
                                <input
                                    type="text"
                                    class="custom-input"
                                    placeholder="Search..."
                                    onChange={e => setKeyword(e.target.value)
                                    } />
                            </div>
                        </div>
                    </Box>
                    <Box width={'90%'} marginTop={2} position={'relative'} display={'flex'} justifyContent={"flex-end"} >
                        <Button
                            onClick={getInterestBasedjobs}
                            disabled={keyword.length < 5}
                            endIcon={<ArrowForwardIcon />} variant="contained" color="primary">
                            <Typography variant="button">Let's Go</Typography>
                        </Button>
                    </Box>
                </>}
        </Box>
    )
}
const styles = {
    textWhite: { fontWeight: "bold", color: "black" }
}
export default DreamJobInput