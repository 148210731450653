import React from 'react';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { Link } from 'react-router-dom'

export default function Field() {
    return (<>
        <li data-bs-toggle="modal" data-bs-target="#development">
            <a  className="item px-1">
                <div className="icon-box">
                    <CelebrationIcon />
                </div>
                <div className="in">
                    <span>
                        View Credit Events
                    </span>
                    <div className="text-primary"><b></b></div>
                </div>
            </a>
        </li>
    </>)
}
