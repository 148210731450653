import * as Axios from "axios";

const API_URL = "https://sb-interview-simulator.web.musketeers.dev";
const axios = Axios.create();

export const interviewQuestions = (title,jobRole,description,interViewLevel) => {
  return new Promise(async (res, rej) => {
    // let formData = new FormData();
    // formData.append("job_title", title);
    // formData.append("interview_topic", jobRole);
    // formData.append("interview_description", description);
    // formData.append("interviewer_level", interViewLevel);
    try {
      const resp = await axios.post(
        `${API_URL}/interview_preps${title ? "?job_title=" + title : ""}${
          description ? "&interview_topic=" + jobRole : ""}
          ${description ? "&interview_description=" + description : ""}
          ${description ? "&interviewer_level=" + interViewLevel : ""}`
      );
      return res(resp.data);
    } catch (error) {
      rej(error);
    }
  });
};
export const interviewSubmitAnswers = (question, blob) => {
  return new Promise(async (res, rej) => {
    let formData = new FormData();
    formData.append("video_file_uploaded", blob, "video.webm");
    try {
      const resp = await axios.post(
        `${API_URL}/grading_answers${question ? "?question=" + question : ""}`,
        formData
      );
      return res(resp.data);
    } catch (error) {
      rej(error);
    }
  });
};