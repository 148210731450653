
const SavingIcon = ({color}) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_71_5283)">
<path d="M24.5536 7.44675V5.26046L25.4896 5.20873L25.3976 3.54248L24.5536 3.58915V0.76001H6.19736V4.60331L5.26131 4.65504L5.35337 6.32129L6.19736 6.27462V7.44675C4.33331 7.58942 2.85986 9.15065 2.85986 11.0506V25.6244C2.85986 27.618 4.48183 29.24 6.47549 29.24H24.2755C26.2691 29.24 27.8911 27.618 27.8911 25.6244V11.0506C27.8911 9.15065 26.4177 7.58942 24.5536 7.44675ZM7.86611 2.42876H22.8849V3.68132L7.86611 4.51108V2.42876ZM7.86611 6.18245L22.8849 5.35269V7.43501H7.86611V6.18245ZM14.6373 22.565C14.6796 22.5715 14.7221 22.5772 14.7648 22.5826C14.7642 22.6137 14.7636 22.6449 14.7636 22.6763C14.7636 23.2206 14.853 23.7443 15.0177 24.2338H7.86611V22.565H14.6373ZM9.53486 20.8963V19.2275H10.7165C10.9165 19.8465 11.2366 20.4119 11.6493 20.8963H9.53486ZM18.5967 17.8976C16.9953 18.2532 15.6867 19.3953 15.0987 20.8963H14.7821C13.2858 20.6168 12.1492 19.3019 12.1492 17.7256C12.1492 15.9467 13.5965 14.4994 15.3755 14.4994C17.1544 14.4994 18.6017 15.9467 18.6017 17.7256C18.6017 17.7831 18.5997 17.8404 18.5967 17.8976ZM19.6586 19.45C21.4376 19.45 22.8849 20.8973 22.8849 22.6763C22.8849 24.4552 21.4376 25.9025 19.6586 25.9025C17.8797 25.9025 16.4324 24.4552 16.4324 22.6763C16.4324 20.8973 17.8797 19.45 19.6586 19.45ZM26.2224 25.6244C26.2224 26.6979 25.349 27.5713 24.2755 27.5713H19.6586H9.36799V25.9025H15.9806C16.8784 26.9248 18.1943 27.5713 19.6586 27.5713C22.3577 27.5713 24.5536 25.3754 24.5536 22.6763C24.5536 20.1839 22.6812 18.1207 20.2691 17.8193C20.2697 17.788 20.2705 17.7568 20.2705 17.7256C20.2705 15.0265 18.0746 12.8306 15.3755 12.8306C12.7322 12.8306 10.572 14.9367 10.4837 17.5588H7.86611V20.8963H6.19736V25.9025H7.69924V27.5713H6.47549C5.40198 27.5713 4.52861 26.6979 4.52861 25.6244V11.0506C4.52861 9.97713 5.40198 9.10376 6.47549 9.10376H24.2755C25.349 9.10376 26.2224 9.97713 26.2224 11.0506V25.6244Z" fill={color}/>
</g>
<defs>
<clipPath id="clip0_71_5283">
<rect width="28.48" height="28.48" fill="white" transform="translate(0.859863 0.76001)"/>
</clipPath>
</defs>
</svg>

  )
}

export default SavingIcon