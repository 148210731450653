import CurrencyFormat from "react-currency-format";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function Category({
  key,
  data,
  type,
  singleSubCategoryHandler,
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.common.background,
        p: 1.2,
        borderRadius: "8px",
      }}
    >
      <Box display="flex" alignItems="center" gap={1}>
        {data?.icon && (
          <Box
            sx={{
              bgcolor:
                data.type === "Add"
                  ? theme.palette.primary.main
                  : theme.palette.success.main,
              borderRadius: "50%",
              width: "36px",
              height: "36px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {<data.icon sx={{ color: theme.palette.common.white }} />}
          </Box>
        )}
        <Box>
          <Box variant="body1" fontWeight="600">
            {data?.title}
          </Box>
          <Box>
            <Typography variant="body2">
              {data?.avg ? (
                ""
              ) : data?.lmMoney > 0 ? (
                <>
                  <CurrencyFormat
                    value={parseInt(data?.lmMoney)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                  last mont
                </>
              ) : (
                "$0 last month"
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display='flex' gap={2} alignItems='center'>
        <Typography variant="h4">$ {data?.value}</Typography>
      <IconButton disableRipple onClick={() => singleSubCategoryHandler(data)}>
        <VisibilityIcon />
      </IconButton>
      </Box>
    </Box>

    //     <li
    //         key={props.modalId + "field"}
    //         data-bs-toggle={props.modalId && "modal"} data-bs-target={props.modalId ? "#" + props.modalId : ""}
    //          onClick={() => { props.category && navigate("/bill-history/" + props.category + "/?type=" + props.type) }}
    //      >
    //     <div className="item item1" >
    //         <div className={"icon-box " + props.bg}>
    //             {props.icon}
    //         </div>
    //         <div className="in">
    //             <div>{props.title} <br /> <span className="text-secondary">{props.avg ? "" : props.lmMoney > 0 ? <> <CurrencyFormat value={parseInt(props.lmMoney)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> last month </> : "$0 last month"}</span></div>
    //             <div className="align-center">
    //                 <strong className='text-grren pe-1'>{props.money !== null ? <CurrencyFormat value={parseInt(props.money)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : ""}

    //                 </strong>
    //                {
    //                 props?.add==="+"&&
    //                 <AddCircleOutlineIcon
    //                 style={{ color: "black" }}

    //               />
    //                }
    //             </div>
    //         </div>
    //     </div>
    // </li>
    // <li
    //     key={props.modalId + "field"}
    //     data-bs-toggle={props.modalId && "modal"} data-bs-target={props.modalId ? "#" + props.modalId : ""}
    //     onClick={() => { props.category && navigate("/bill-history/" + props.category + "/?type=" + props.type) }}
    // >
    //     <div className="item item1" >
    //         <div className={"icon-box " + props.bg}>
    //             {props.icon}
    //         </div>
    //         <div className="in">
    //             <div>{props.title} <br /> <span className="text-secondary">{props.avg ? "" : props.lmMoney > 0 ? <> <CurrencyFormat value={parseInt(props.lmMoney)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> last month </> : "$0 last month"}</span></div>
    //             <div className="align-center">
    //                 <strong>{props.money !== null ? <CurrencyFormat value={parseInt(props.money)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : ""}</strong>
    //                 <div className="icon-box icon-box1">
    //                     {
    //                         props.add ?
    //                             <ion-icon name="add-circle-outline"></ion-icon>
    //                             : <i className="icon ion-md-switch text-secondary"></i>
    //                     }
    //                 </div>

    //             </div>
    //         </div>
    //     </div>
    // </li>
  );
}
