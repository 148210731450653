import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { QuillEditor } from './quil-editor'
import ReactPlayer from 'react-player'
import { TikTokEmbed } from 'react-social-media-embed';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ReplayIcon from '@mui/icons-material/Replay';
import CollapsibleTable from './collapse'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {
    Tabs,
    Tab,
    Divider,
    IconButton,
    Grid,
    Box,
    Button,
    Typography,
    Dialog,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Unity, useUnityContext } from "react-unity-webgl";
import SimpleImageSlider from "react-simple-image-slider"
import { getForms, getJotForms, getAllIcons } from '../../_api'
import DialogTitle from "@mui/material/DialogTitle";
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';



/*----------------------------------------------------------------
    Main render function
-------------------------------------------------------------------*/
export default function ContentTyps({ content }) {
    return <>
        {content?.contentType === "Image-Stickbot-Audio" && <RenderImageStickbotAudio content={content} />}
        {content?.contentType === "Video-Stickbot-Audio" && <RenderVideoStickbotAudio content={content} />}
        {content?.contentType === "Metaverse" && <Metaverse content={content} />}
        {content?.contentType === "CareerHistory" && <CareerHistory content={content} />}
        {content?.contentType === 'Text' && <RenderText content={content} />}
        {(content?.contentType === 'Wireframe-Stickbot' || content?.contentType === 'Stickbot-Wireframe' || content?.contentType === 'Youtube-Stickbot') && <WireframeStickbot content={content} />}
        {content?.contentType === 'Matterport-Stickbot' ? <RenderMatterportStickbot content={content} /> : <></>}
        {content?.contentType === 'Iframe-Iframe' || content?.contentType === 'Iframe-TikTok' ? <RenderIframIfram content={content} /> : <></>}
        {content?.contentType === 'Iframe-Audio' ? <RenderIframAudio content={content} /> : <></>}
        {content?.contentType === 'Image-Video' ? <RenderImageVideo content={content} /> : <></>}
        {content?.contentType === 'Image-Audio' ? <RenderImageAudio content={content} /> : <></>}
        {content?.contentType === 'Iframe-Video' ? <RenderIframVideo content={content} /> : <></>}
        {content?.contentType === 'Video' && <ContentVideo content={content} />}
        {content?.contentType === 'Audio' && <RenderAudio content={content} />}
        {content?.contentType === 'Doodle-Video' && <RenderDoodleVideo content={content} />}
        {content?.contentType === 'Youtube' && <RenderYoutube content={content} />}
        {content?.contentType === 'TikTok' && <RenderTiktok content={content} />}
        {content?.contentType === "Tiktok-Hybrid" && <RenderTiktokHybrid content={content} />}
        {content?.contentType === "Video-Six-Grid" && <VideoSixGrid content={content} />}
        {content?.contentType === "Image-Grid" && <RenderImageGrid content={content} />}
        {content?.contentType === 'Image' && <RenderImage content={content} />}
        {content?.contentType === 'PPT-Content-Image' && <PPTImage content={content} />}
        {content?.contentType === 'Content-Stickbot-Vertical' && <RenderStickbotVertical content={content} />}
        {content?.contentType === 'Content-Image-Vertical' && <RenderImageVertical content={content} />}
        {(content?.contentType === 'Content-Image-Wireframe' || content?.contentType === 'Content-Image-Stickbot') && <RenderImageWireframeAndImageStickbot content={content} />}
        {content?.contentType === 'Wireframe' && <RenderWireframe content={content} />}
        {content?.contentType === 'Matterport' && <RenderMatterport content={content} />}
        {(content?.contentType === 'GoogleForm' || content?.contentType === 'FormAutomation') && <GoogleForm content={content} />}
        {content?.contentType === 'Dropdown' && <RenderDropdown content={content} />}
    </>
}




const CustomAudioPlayer = (props) => {
    const { src, autoPlay, text } = props
    const [ccModal, setCCModal] = useState(false)

    return (
        <>
            <Dialog
                open={ccModal}
                keepMounted
                onClose={() => setCCModal(false)}
                aria-describedby="alert-dialog-slide-description"
                maxWidth='xxs'
                style={{
                    backgroundColor: 'rgba(1000, 1000, 1000, 0.2)',
                    overflow: 'visible',
                }}
            >
                <DialogTitle>Transcript</DialogTitle>
                <p style={audioText}>
                    {text}
                </p>
            </Dialog>
            <div style={audioPlayerStyle}>
                <AudioPlayer
                    autoPlay={autoPlay}
                    src={src}
                />
                <IconButton style={ccButton} onClick={() => setCCModal(true)}>
                    <ClosedCaptionIcon fontSize="large" />
                </IconButton>
            </div>
        </>

    )
}

const CustomVideoPlayer = (props) => {
    const { url, text } = props
    const [ccModal, setCCModal] = useState(false)

    return (
        <>
            <Dialog
                open={ccModal}
                keepMounted
                onClose={() => setCCModal(false)}
                aria-describedby="alert-dialog-slide-description"
                maxWidth='xxs'
                style={{
                    backgroundColor: 'rgba(1000, 1000, 1000, 0.2)',
                    overflow: 'visible',
                }}
            >
                <DialogTitle>Transcript</DialogTitle>
                <p style={audioText}>
                    {text}
                </p>
            </Dialog>
            <div style={audioPlayerStyle}>
                <ReactPlayer
                    url={url}
                    playing={true}
                    loop={true}
                    controls={true}
                    width="100%"
                    height="auto"
                />
                <IconButton style={ccVideoButton} onClick={() => setCCModal(true)}>
                    <ClosedCaptionIcon fontSize="large" />
                </IconButton>
            </div>
        </>

    )
}

/*----------------------------------------------------------------
   Iframe links checking if valid
-------------------------------------------------------------------*/
const isValidUrl = urlString => {
    try {
        return Boolean(new URL(urlString));
    }
    catch (e) {
        return false;
    }
}

const getName = (user) => {
    if (user?.firstName) {
        return `${user?.firstName}`
    } else {
        return "_User"
    }
}

const Video = ({ src }) => {
    const [muted, setMuted] = useState(false)
    const videoEl = useRef(null);
    const attemptPlay = () => {
        if (videoEl && videoEl?.current) {
            videoEl?.current?.play()

        }
    };
    useEffect(() => {
        attemptPlay();
    }, [videoEl?.current]);
    return (
        <>
            <div style={{ width: 'fit-content', position: 'relative' }}>
                <video
                    playsInline
                    loop
                    muted={!muted}
                    controls={false}
                    alt="All the devices"
                    ref={videoEl}
                    src={src}
                >

                </video>
                <IconButton onClick={() => setMuted(!muted)}
                    style={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        backgroundColor: 'rgba(1000,1000,1000,0.7)',
                    }}
                >
                    {
                        muted ?
                            <VolumeUpIcon />
                            :
                            <VolumeOffIcon />
                    }
                </IconButton>

            </div>
        </>
    )
}


/*----------------------------------------------------------------
    All Content Types rendering functions
-------------------------------------------------------------------*/
const RenderImageStickbotAudio = ({ content, user }) => {
    const myObj = JSON.parse(content?.content)
    const { video_link, audio_link, heading1, heading2, stickbot_link, text } = myObj
    const { name, contentType: type } = content
    const [data, setStateData] = useState({
        name, type, wireframe_link: video_link, wireframe_link1: audio_link, heading1, heading2, link_1: stickbot_link, text
    })
    const imagesVisibility = () => {
        return data?.wireframe_link?.map((image) => {
            return { url: process.env.REACT_APP_S3_BUCKET_LINK + image }
        })
    }
    return (

        <Grid
            container
            sx={{ paddingX: 10 }}
        >
            <Grid xs={6}>
                <Typography variant="h5" sx={{ textAlign: 'center', m: 2 }}>{data?.heading1}</Typography>

                <Box style={{ position: 'relative', height: '450px', overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <SimpleImageSlider
                        width="100%"
                        height='400px'
                        images={imagesVisibility()}
                        showBullets={false}
                        showNavs={false}
                        autoPlay={true}
                        autoPlayDelay={parseFloat(myObj?.timer) || 1}
                    />
                </Box>
                <Box sx={{ py: 2 }}>
                    {
                        !!Object.keys(data).length &&
                        <CustomAudioPlayer
                            autoPlay={false}
                            src={data?.wireframe_link1}
                            text={data?.text || "No subtitle to show"}
                        />

                    }
                </Box>
            </Grid>
            <Grid xs={6}>
                <Typography variant="h5" sx={{ textAlign: 'center', m: 2 }}>{data?.heading2}</Typography>
                <iframe
                    style={{
                        width: "100%",
                        height: "400px",
                        paddingX: "5px",
                    }}
                    src={isValidUrl(data?.link_1) ? data?.link_1 + `?userId=${getName(user)}` : "https://www.google.com"}
                    frameBorder="0"
                ></iframe>

            </Grid>
        </Grid>
    )
}

const RenderVideoStickbotAudio = ({ content, user }) => {
    const myObj = JSON.parse(content?.content)
    const { video_link, audio_link, heading1, heading2, stickbot_link, text } = myObj
    const { name, contentType: type } = content
    const [data, setStateData] = useState({
        name, type, wireframe_link: video_link, wireframe_link1: audio_link, heading1, heading2, link_1: stickbot_link, text
    })
    const videoVisibility = () => {
        return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link
    }
    return (
        <div className="col-12" style={containerStyle}>
            <div className="container-ss">
                <Grid
                    container
                >
                    <Grid xs={6}>
                        <Typography variant="h5" sx={{ textAlign: 'center', m: 2 }}>{data?.heading1}</Typography>
                        <Box sx={{ width: '100%', height: '400px' }}>
                            <ReactPlayer width="100%" height="400px" url={videoVisibility()} controls={true} />
                        </Box>
                        <Box sx={{ p: 2, height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5 }}>
                            <CustomAudioPlayer
                                autoPlay={false}
                                src={data?.wireframe_link1}
                                text={data?.text || 'No subtitle to show'}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={6}>
                        <Typography variant="h5" sx={{ textAlign: 'center', m: 2 }}>{data?.heading2}</Typography>
                        <iframe
                            style={{
                                width: "100%",
                                height: "550px",
                                paddingX: "5px",
                            }}
                            src={isValidUrl(data?.link_1) ? data?.link_1 + `?userId=${getName(user)}` : "https://www.google.com"}
                            frameBorder="0"
                        ></iframe>
                    </Grid>
                </Grid>
            </div>
        </div >
    )
}

const Metaverse = ({ content }) => {

    //Stickball Mall
    const { unityProvider, requestFullscreen, loadingProgression, isLoaded, unload: unloadMall } = useUnityContext({
        loaderUrl: "https://stickball.s3.amazonaws.com/BuildMall/built+41.loader.js",
        dataUrl: "https://stickball.s3.amazonaws.com/BuildMall/built+41.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/BuildMall/built+41.framework.js",
        codeUrl: "https://stickball.s3.amazonaws.com/BuildMall/built+41.wasm",
    });

    //Stickball Show Room
    const { unityProvider: unityProviderRoom,
        requestFullscreen: requestFullscreenRoom,
        loadingProgression: loadingProgressionRoom,
        isLoaded: isLoadedRoom,
        unload: unloadRoom
    } = useUnityContext({
        loaderUrl: "https://stickball.s3.amazonaws.com/Build/Built.loader.js",
        dataUrl: "https://stickball.s3.amazonaws.com/Build/Built.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/Build/Built.framework.js",
        codeUrl: "https://stickball.s3.amazonaws.com/Build/Built.wasm"
    });

    //Stickball Grocery Store
    const { unityProvider: unityProviderGrocery,
        requestFullscreen: requestFullscreenGrocery,
        loadingProgression: loadingProgressionGrocery,
        isLoaded: isLoadedGrocery,
        unload: unloadGrocery
    } = useUnityContext({
        loaderUrl: process.env.REACT_APP_GROCERY_STORE_LOADER_URL,
        dataUrl: process.env.REACT_APP_GROCERY_STORE_DATA_URL,
        frameworkUrl: process.env.REACT_APP_GROCERY_STORE_FRAMEWORK_URL,
        codeUrl: process.env.REACT_APP_GROCERY_STORE_WASM_URL,
    });

    //Stickball Grocery Stock
    const { unityProvider: unityProviderGroceryStock,
        requestFullscreen: requestFullscreenGroceryStock,
        loadingProgression: loadingProgressionGroceryStock,
        isLoaded: isLoadedGroceryStock,
        unload: unloadGroceryStock
    } = useUnityContext({
        loaderUrl: process.env.REACT_APP_GROCERY_STOCK_LOADER_URL,
        dataUrl: process.env.REACT_APP_GROCERY_STOCK_DATA_URL,
        frameworkUrl: process.env.REACT_APP_GROCERY_STOCK_FRAMEWORK_URL,
        codeUrl: process.env.REACT_APP_GROCERY_STOCK_WASM_URL,
    });

    //Stickball Financial Advisors
    const { unityProvider: unityProviderAdvisor,
        requestFullscreen: requestFullscreenAdvisor,
        loadingProgression: loadingProgressionAdvisor,
        isLoaded: isLoadedAdvisor,
        unload: unloadAdvisor
    } = useUnityContext({
        loaderUrl: "https://stickball.s3.amazonaws.com/BuildStock/stockbroker.loader.js",
        dataUrl: "https://stickball.s3.amazonaws.com/BuildStock/stockbroker.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/BuildStock/stockbroker.framework.js",
        codeUrl: "https://stickball.s3.amazonaws.com/BuildStock/stockbroker.wasm",
    });

    //Stickball Doctor Office
    const { unityProvider: unityProviderDrOffice,
        requestFullscreen: requestFullscreenDrOffice,
        loadingProgression: loadingProgressionDrOffice,
        isLoaded: isLoadedDrOffice,
        unload: unloadDrOffice
    } = useUnityContext({
        loaderUrl: "https://stickball.s3.amazonaws.com/hospital/hospital.loader.js",
        dataUrl: "https://stickball.s3.amazonaws.com/hospital/hospital.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/hospital/hospital.framework.js",
        codeUrl: "https://stickball.s3.amazonaws.com/hospital/hospital.wasm",
    });

    //Stickball Hospital
    const { unityProvider: unityProviderHospital,
        requestFullscreen: requestFullscreenHospital,
        loadingProgression: loadingProgressionHospital,
        isLoaded: isLoadedHospital,
        unload: unloadHospital
    } = useUnityContext({
        dataUrl: "https://stickball.s3.amazonaws.com/old-hospital/OldHospital.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/old-hospital/OldHospital.framework.js",
        loaderUrl: "https://stickball.s3.amazonaws.com/old-hospital/OldHospital.loader.js",
        codeUrl: "https://stickball.s3.amazonaws.com/old-hospital/OldHospital.wasm",
    });

    //Stickball Human Resources
    const { unityProvider: unityProviderHumanResources,
        requestFullscreen: requestFullscreenHumanResources,
        loadingProgression: loadingProgressionHumanResources,
        isLoaded: isLoadedHumanResources,
        unload: unloadHumanResources
    } = useUnityContext({
        loaderUrl: "https://stickball.s3.amazonaws.com/human-resources-unity/human-resources.loader.js",
        dataUrl: "https://stickball.s3.amazonaws.com/human-resources-unity/human-resources.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/human-resources-unity/human-resources.framework.js",
        codeUrl: "https://stickball.s3.amazonaws.com/human-resources-unity/human-resources.wasm",
    });

    //Stickball Personal Banking
    const { unityProvider: unityProviderPersonalBanking,
        requestFullscreen: requestFullscreenPersonalBanking,
        loadingProgression: loadingProgressionPersonalBanking,
        isLoaded: isLoadedPersonalBanking,
        unload: unloadPersonalBanking
    } = useUnityContext({
        dataUrl: "https://stickball.s3.amazonaws.com/PersonalBanking/personal-banking.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/PersonalBanking/personal-banking.framework.js",
        loaderUrl: "https://stickball.s3.amazonaws.com/PersonalBanking/personal-banking.loader.js",
        codeUrl: "https://stickball.s3.amazonaws.com/PersonalBanking/personal-banking.wasm",
    });

    //Stickball Banking Roles
    const { unityProvider: unityProviderBankingRoles,
        requestFullscreen: requestFullscreenBankingRoles,
        loadingProgression: loadingProgressionBankingRoles,
        isLoaded: isLoadedBankingRoles,
        unload: unloadBankingRoles
    } = useUnityContext({
        dataUrl: "https://stickball.s3.amazonaws.com/BankingRoles/banking-roles.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/BankingRoles/banking-roles.framework.js",
        loaderUrl: "https://stickball.s3.amazonaws.com/BankingRoles/banking-roles.loader.js",
        codeUrl: "https://stickball.s3.amazonaws.com/BankingRoles/banking-roles.wasm",
    });

    //Stickball First Tech
    const { unityProvider: unityProviderFirstTech,
        requestFullscreen: requestFullscreenFirstTech,
        loadingProgression: loadingProgressionFirstTech,
        isLoaded: isLoadedFirstTech,
        unload: unloadFirstTech
    } = useUnityContext({
        dataUrl: "https://stickball.s3.amazonaws.com/FirstTechBank/firsttech-bank.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/FirstTechBank/firsttech-bank.framework.js",
        loaderUrl: "https://stickball.s3.amazonaws.com/FirstTechBank/firsttech-bank.loader.js",
        codeUrl: "https://stickball.s3.amazonaws.com/FirstTechBank/firsttech-bank.wasm",
    });

    //Stickball Bank
    const { unityProvider: unityProviderStickballBank,
        requestFullscreen: requestFullscreenStickballBank,
        loadingProgression: loadingProgressionStickballBank,
        isLoaded: isLoadedStickballBank,
        unload: unloadStickballBank
    } = useUnityContext({
        dataUrl: "https://stickball.s3.amazonaws.com/stickballBank/stickball-bank.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/stickballBank/stickball-bank.framework.js",
        loaderUrl: "https://stickball.s3.amazonaws.com/stickballBank/stickball-bank.loader.js",
        codeUrl: "https://stickball.s3.amazonaws.com/stickballBank/stickball-bank.wasm",
    });

    //Fidelity Investments
    const { unityProvider: unityProviderFidelityInvestments,
        requestFullscreen: requestFullscreenFidelityInvestments,
        loadingProgression: loadingProgressionFidelityInvestments,
        isLoaded: isLoadedFidelityInvestments,
        unload: unloadFidelityInvestments
    } = useUnityContext({
        loaderUrl: "https://stickball.s3.amazonaws.com/FidelityInvestments/fidelity-investments.loader.js",
        dataUrl: "https://stickball.s3.amazonaws.com/FidelityInvestments/fidelity-investments.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/FidelityInvestments/fidelity-investments.framework.js",
        codeUrl: "https://stickball.s3.amazonaws.com/FidelityInvestments/fidelity-investments.wasm",
    });

    //Stickball Company
    const { unityProvider: unityProviderStickballCompany,
        requestFullscreen: requestFullscreenStickballCompany,
        loadingProgression: loadingProgressionStickballCompany,
        isLoaded: isLoadedStickballCompany,
        unload: unloadStickballCompany
    } = useUnityContext({
        loaderUrl: "https://stickball.s3.amazonaws.com/stickball-company-unity/stickball-company.loader.js",
        dataUrl: "https://stickball.s3.amazonaws.com/stickball-company-unity/stickball-company.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/stickball-company-unity/stickball-company.framework.js",
        codeUrl: "https://stickball.s3.amazonaws.com/stickball-company-unity/stickball-company.wasm",
    });

    const [muted, setMuted] = useState(false)
    const { contentType: type } = content
    const [data, setData] = useState({
        content: content?.content,
        type,
        name: 'Metaverse'
    })


    const videoEl = useRef(null);

    const attemptPlay = () => {
        if (videoEl && videoEl?.current) {
            videoEl?.current?.play()

        }
    };
    useEffect(() => {
        attemptPlay();
    }, [videoEl?.current]);

    function handleClickEnterFullscreen() {
        requestFullscreen(true);
    }
    function handleClickEnterFullscreenRoom() {
        requestFullscreenRoom(true);
    }
    function handleClickEnterFullscreenGrocery() {
        requestFullscreenGrocery(true);
    }
    function handleClickEnterFullscreenAdvisor() {
        requestFullscreenAdvisor(true);
    }
    function handleClickEnterFullscreenDrOffice() {
        requestFullscreenDrOffice(true);
    }
    function handleClickEnterFullscreenHospital() {
        requestFullscreenHospital(true);
    }
    function handleClickEnterFullscreenGroceryStock() {
        requestFullscreenGroceryStock(true);
    }
    function handleClickEnterFullscreenHumanResources() {
        requestFullscreenHumanResources(true);
    }
    function handleClickEnterFullscreenPersonalBanking() {
        requestFullscreenPersonalBanking(true);
    }
    function handleClickEnterFullscreenBankingRoles() {
        requestFullscreenBankingRoles(true);
    }
    function handleClickEnterFullscreenFirstTech() {
        requestFullscreenFirstTech(true);
    }
    function handleClickEnterFullscreenStickballBank() {
        requestFullscreenStickballBank(true);
    }
    function handleClickEnterFullscreenFidelityInvestments() {
        requestFullscreenFidelityInvestments(true);
    }

    function handleClickEnterFullscreenStickballCompany() {
        requestFullscreenStickballCompany(true);
    }

    return (
        <div className="row">
            {(data?.content === "mall") ?
                <div className="d-flex flex-column">
                    {
                        !isLoaded &&
                        <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <p>Loading Stickball Mall... {Math.round(loadingProgression * 100)}%</p>
                            <Video src="https://stickball.s3.amazonaws.com/Stickball+Mall+Intro.mp4" />
                        </div>
                    }
                    <div className="d-flex flex-column align-items-center">
                        <button onClick={handleClickEnterFullscreen} style={{ visibility: isLoaded ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                        <Unity
                            unityProvider={unityProvider}
                            style={{ visibility: isLoaded ? "visible" : "hidden", width: 1024, height: 600 }}
                        />
                    </div>
                </div>
                : data?.content === "room" ?
                    <>
                        {
                            !isLoadedRoom && <>

                                <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <p>Loading Stickball Showroom... {Math.round(loadingProgressionRoom * 100)}%</p>
                                    <Video src="https://stickball.s3.amazonaws.com/Stickball-1.m4v" />
                                </div>
                            </>
                        }
                        <div className="d-flex flex-column align-items-center">
                            <button onClick={handleClickEnterFullscreenRoom} style={{ visibility: isLoadedRoom ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                            <Unity
                                unityProvider={unityProviderRoom}
                                style={{ visibility: isLoadedRoom ? "visible" : "hidden", width: 1024, height: 600 }}
                            />
                        </div>
                    </>
                    : data?.content === "advisor" ?
                        <>
                            {
                                !isLoadedAdvisor && <>

                                    <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <p>Loading Stickball Financial Advisors... {Math.round(loadingProgressionAdvisor * 100)}%</p>
                                        <Video src="https://stickball.s3.amazonaws.com/Financial+Advisors+Intro.mp4" />
                                    </div>
                                </>
                            }
                            <div className="d-flex flex-column align-items-center">
                                <button onClick={handleClickEnterFullscreenAdvisor} style={{ visibility: isLoadedAdvisor ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                                <Unity
                                    unityProvider={unityProviderAdvisor}
                                    style={{ visibility: isLoadedAdvisor ? "visible" : "hidden", width: 1024, height: 600 }}
                                />
                            </div>
                        </>
                        : data?.content === "doctor-office" ?
                            <>
                                {
                                    !isLoadedDrOffice && <>

                                        <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <p>Loading Stickball Doctor's Office... {Math.round(loadingProgressionDrOffice * 100)}%</p>
                                            <Video src="https://stickball.s3.amazonaws.com/Doctor's+Office+Intro.mp4" />
                                        </div>
                                    </>
                                }
                                <div className="d-flex flex-column align-items-center">
                                    <button onClick={handleClickEnterFullscreenDrOffice} style={{ visibility: isLoadedDrOffice ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                                    <Unity
                                        unityProvider={unityProviderDrOffice}
                                        style={{ visibility: isLoadedDrOffice ? "visible" : "hidden", width: 1024, height: 600 }}
                                    />
                                </div>
                            </>
                            : data?.content === "hospital" ?
                                <>
                                    {
                                        !isLoadedHospital && <>

                                            <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                <p>Loading Stickball Hospital... {Math.round(loadingProgressionHospital * 100)}%</p>
                                                <Video src="https://stickball.s3.amazonaws.com/Unity+Hospital+Link+Video(Compressed).mp4" />
                                            </div>
                                        </>
                                    }
                                    <div className="d-flex flex-column align-items-center">
                                        <button onClick={handleClickEnterFullscreenHospital} style={{ visibility: isLoadedHospital ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                                        <Unity
                                            unityProvider={unityProviderHospital}
                                            style={{ visibility: isLoadedHospital ? "visible" : "hidden", width: 1024, height: 600 }}
                                        />
                                    </div>
                                </>
                                : data?.content === "stock" ?
                                    <>
                                        {
                                            !isLoadedGroceryStock && <>

                                                <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                    <p>Loading Stickball Grocery Stock... {Math.round(loadingProgressionGroceryStock * 100)}%</p>
                                                    <Video src="https://stickball.s3.amazonaws.com/GroceryStock_720p.mp4" />
                                                </div>
                                            </>
                                        }
                                        <div className="d-flex flex-column align-items-center">
                                            <button onClick={handleClickEnterFullscreenGroceryStock} style={{ visibility: isLoadedGroceryStock ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                                            <Unity
                                                unityProvider={unityProviderGroceryStock}
                                                style={{ visibility: isLoadedGroceryStock ? "visible" : "hidden", width: 1024, height: 600 }}
                                            />
                                        </div>
                                    </>
                                    : data?.content === "resources" ?
                                        <>
                                            {
                                                !isLoadedHumanResources && <>

                                                    <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        <p>Loading Stickball Human Resources... {Math.round(loadingProgressionHumanResources * 100)}%</p>
                                                        <Video src="https://stickball.s3.amazonaws.com/Human+Resources+Link+Load+Video(Compressed+10+MB).mp4" />
                                                    </div>
                                                </>
                                            }
                                            <div className="d-flex flex-column align-items-center">
                                                <button onClick={handleClickEnterFullscreenHumanResources} style={{ visibility: isLoadedHumanResources ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                                                <Unity
                                                    unityProvider={unityProviderHumanResources}
                                                    style={{ visibility: isLoadedHumanResources ? "visible" : "hidden", width: 1024, height: 600 }}
                                                />
                                            </div>
                                        </>
                                        : data?.content === "personal-banking" ?
                                            <>
                                                {
                                                    !isLoadedPersonalBanking && <>

                                                        <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                            <p>Loading Stickball Personal Banking... {Math.round(loadingProgressionPersonalBanking * 100)}%</p>
                                                            <Video src="https://stickball.s3.amazonaws.com/PersonalBanking/Intro+Personal+Banking.mp4" />
                                                        </div>
                                                    </>
                                                }
                                                <div className="d-flex flex-column align-items-center">
                                                    <button onClick={handleClickEnterFullscreenPersonalBanking} style={{ visibility: isLoadedPersonalBanking ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                                                    <Unity
                                                        unityProvider={unityProviderPersonalBanking}
                                                        style={{ visibility: isLoadedPersonalBanking ? "visible" : "hidden", width: 1024, height: 600 }}
                                                    />
                                                </div>
                                            </>
                                            : data?.content === "banking-roles" ?
                                                <>
                                                    {
                                                        !isLoadedBankingRoles && <>

                                                            <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                <p>Loading Stickball Banking Roles... {Math.round(loadingProgressionBankingRoles * 100)}%</p>
                                                                <Video src="https://stickball.s3.amazonaws.com/Banking+Roles+Link+Video+(Compressed).mp4" />
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="d-flex flex-column align-items-center">
                                                        <button onClick={handleClickEnterFullscreenBankingRoles} style={{ visibility: isLoadedBankingRoles ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                                                        <Unity
                                                            unityProvider={unityProviderBankingRoles}
                                                            style={{ visibility: isLoadedBankingRoles ? "visible" : "hidden", width: 1024, height: 600 }}
                                                        />
                                                    </div>
                                                </> : data?.content === "first-tech" ?
                                                    <>
                                                        {
                                                            !isLoadedFirstTech && <>

                                                                <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                    <p>Loading Stickball First Tech... {Math.round(loadingProgressionFirstTech * 100)}%</p>
                                                                    <Video src="https://stickball.s3.amazonaws.com/FirstTech+Bank+Link+Video+(Compressed).mp4" />
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="d-flex flex-column align-items-center">
                                                            <button onClick={handleClickEnterFullscreenFirstTech} style={{ visibility: isLoadedFirstTech ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                                                            <Unity
                                                                unityProvider={unityProviderFirstTech}
                                                                style={{ visibility: isLoadedFirstTech ? "visible" : "hidden", width: 1024, height: 600 }}
                                                            />
                                                        </div>
                                                    </>
                                                    : data?.content === "stickball-bank" ?
                                                        <>
                                                            {
                                                                !isLoadedStickballBank && <>

                                                                    <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                        <p>Loading Stickball Bank... {Math.round(loadingProgressionStickballBank * 100)}%</p>
                                                                        <Video src="https://stickball.s3.amazonaws.com/Stickball+Bank+Intro.mp4" />
                                                                    </div>
                                                                </>
                                                            }
                                                            <div className="d-flex flex-column align-items-center">
                                                                <button onClick={handleClickEnterFullscreenStickballBank} style={{ visibility: isLoadedStickballBank ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                                                                <Unity
                                                                    unityProvider={unityProviderStickballBank}
                                                                    style={{ visibility: isLoadedStickballBank ? "visible" : "hidden", width: 1024, height: 600 }}
                                                                />
                                                            </div>
                                                        </>
                                                        : data?.content === "fidelity-investments" ?
                                                            <>
                                                                {
                                                                    !isLoadedFidelityInvestments && <>

                                                                        <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                            <p>Loading Fidelity Investments... {Math.round(loadingProgressionFidelityInvestments * 100)}%</p>
                                                                            <Video src="https://stickball.s3.amazonaws.com/Fidelity+Investments+Intro.mp4" />
                                                                        </div>
                                                                    </>
                                                                }
                                                                <div className="d-flex flex-column align-items-center">
                                                                    <button onClick={handleClickEnterFullscreenFidelityInvestments} style={{ visibility: isLoadedFidelityInvestments ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                                                                    <Unity
                                                                        unityProvider={unityProviderFidelityInvestments}
                                                                        style={{ visibility: isLoadedFidelityInvestments ? "visible" : "hidden", width: 1024, height: 600 }}
                                                                    />
                                                                </div>
                                                            </>
                                                            : data?.content === "stickball-company" ?
                                                                <>
                                                                    {
                                                                        !isLoadedStickballCompany && <>

                                                                            <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                                <p>Loading Stickball Company... {Math.round(loadingProgressionStickballCompany * 100)}%</p>
                                                                                <Video src="https://stickball.s3.amazonaws.com/StickBall+Compnay+Link+Video(Compressed).mp4" />
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    <div className="d-flex flex-column align-items-center">
                                                                        <button onClick={handleClickEnterFullscreenStickballCompany} style={{ visibility: isLoadedStickballCompany ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                                                                        <Unity
                                                                            unityProvider={unityProviderStickballCompany}
                                                                            style={{ visibility: isLoadedStickballCompany ? "visible" : "hidden", width: 1024, height: 600 }}
                                                                        />
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        !isLoadedGrocery && <>

                                                                            <div style={{ with: '100%', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                                <p>Loading Stickball Grocery Store... {Math.round(loadingProgressionGrocery * 100)}%</p>
                                                                                <Video src="https://stickball.s3.amazonaws.com/Grocery+Store+Compressed.mp4" />
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    <div className="d-flex flex-column align-items-center">
                                                                        <button onClick={handleClickEnterFullscreenGrocery} style={{ visibility: isLoadedGrocery ? "visible" : "hidden", width: 'fit-content' }}>Enter Fullscreen</button>
                                                                        <Unity
                                                                            unityProvider={unityProviderGrocery}
                                                                            style={{ visibility: isLoadedGrocery ? "visible" : "hidden", width: 1024, height: 600 }}
                                                                        />
                                                                    </div>
                                                                </>}
        </div>
    )
}

const CareerHistory = ({ content }) => {
    const [value, setValue] = useState(0)
    const { name, description, type } = content
    const [data, setStateData] = useState({
        name, description, type, content: JSON.parse(content?.content) || []
    })
    const [history, setHistory] = useState([])

    useEffect(() => {
        if (data?.content) {
            setHistory(data?.content[0]?.history || [])
        }
    }, [data])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <>

            {/* <QuillEditor
                sx={{ marginTop: "20px", fontSize: '16px' }}
                value={data?.name}
                readOnly={true}
            /> */}
            <div className="row d-flex justify-content-center pt-3" style={{ marginLeft: '30px' }}>
                <Tabs
                    value={value} onChange={handleChange}
                    style={{ width: 'fit-content' }}
                    aria-label="icon label tabs example">
                    {
                        data?.content?.map((career, i) => {
                            return (
                                <Tab
                                    sx={{ width: 'fit-content' }}
                                    key={i + 'career2'}
                                    icon={
                                        <Avatar
                                            sx={{ width: 66, height: 66, }}
                                            style={{ backgroundColor: '#ccc', padding: 10 }}
                                            alt="X"
                                            src={career?.icon}

                                        />
                                    }
                                    style={{ backgroundColor: 'transparent' }}
                                    onClick={() => { setHistory(career?.history) }}
                                />
                            )
                        })
                    }

                </Tabs>
                <Divider />
            </div>
            <div className="row d-flex justify-content-center pt-5 mt-5 ml-3" style={{ marginLeft: '30px' }}>
                {
                    history?.slice(0, 3)?.map((his, ind) => {
                        return (
                            <div className="col-4 d-flex flex-column justify-content-center align-items-center">
                                <div className="side-history" key={ind}>
                                    <img src={ind === 0 ? "assets/img/money.png" : ind === 1 ? "assets/img/cloud.png" : "assets/img/bulb.png"} alt="" />
                                    <h4>{his?.year}</h4>
                                    <QuillEditor
                                        sx={{ marginTop: "20px" }}
                                        value={his?.description}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
                {
                    history?.slice(3, 5)?.map((his, ind) => {
                        return (
                            <div className="col-4 d-flex flex-column justify-content-center align-items-center pt-5 mt-5">
                                <div className="side-history-right" key={ind}>
                                    <img src={ind === 0 ? "assets/img/building.png" : "assets/img/desk.png"} alt="" />
                                    <h4>{his?.year}</h4>
                                    <QuillEditor
                                        sx={{ marginTop: "20px" }}
                                        value={his?.description}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </div>

        </>
    )
}

const RenderText = ({ content }) => {
    const { type, name } = content
    const [data, setStateData] = useState({
        type,
        name,
        content: content.content
    })

    return (
        <QuillEditor
            value={data?.content}
            readOnly={true}
        />
    )
}

const WireframeStickbot = ({ content, user }) => {
    const myOBJ = JSON.parse(content?.content)
    const [activeLink, setActiveLink] = useState(1)
    const { name, contentType: type } = content
    const [data, setStateData] = useState({
        name,
        type,
        content: myOBJ,
        wireframe_link: myOBJ.wireframe_link,
        wireframe_link1: myOBJ.skill_assignment
    })

    return (
        <div className='d-flex flex-column align-items-center'>
            {
                data?.type === 'Stickbot-Wireframe' ?
                    <Grid container style={gridStyle} >
                        {/* <QuillEditor
                            sx={quilStyle}
                            value={data?.name}
                            readOnly={true}
                        /> */}
                        <div
                            style={stickbotButtons}
                        >
                            <Button variant="contained" onClick={() => setActiveLink(1)}>{data?.content?.link_1_name}</Button>
                            {
                                !!data?.content?.link_2_name &&
                                <Button variant="contained" onClick={() => setActiveLink(2)}>{data?.content?.link_2_name}</Button>
                            }
                            {
                                !!data?.content?.link_3_name &&
                                <Button variant="contained" onClick={() => setActiveLink(3)}>{data?.content?.link_3_name}</Button>
                            }
                            {
                                !!data?.content?.link_4_name &&
                                <Button variant="contained" onClick={() => setActiveLink(4)}>{data?.content?.link_4_name}</Button>
                            }
                            {
                                !!data?.content?.link_5_name &&
                                <Button variant="contained" onClick={() => setActiveLink(5)}>{data?.content?.link_5_name}</Button>
                            }
                        </div>
                        <iframe
                            style={stickbotIframeStyle}
                            className="i-frame"
                            src={activeLink === 1 ? (isValidUrl(data?.content?.link_1) ? data?.content?.link_1 + `?userId=${getName(user)}` : "")
                                : activeLink === 2 ? (isValidUrl(data?.content?.link_2) ? data?.content?.link_2 + `?userId=${getName(user)}` : "")
                                    : activeLink === 3 ? (isValidUrl(data?.content?.link_3) ? data?.content?.link_3 + `?userId=${getName(user)}` : "")
                                        : activeLink === 4 ? (isValidUrl(data?.content?.link_4) ? data?.content?.link_4 + `?userId=${getName(user)}` : "")
                                            : (isValidUrl(data?.content?.link_5) ? data?.content?.link_5 + `?userId=${getName(user)}` : "")
                            }
                            frameBorder="0"
                        ></iframe>
                        <QuillEditor
                            sx={quilStyle}
                            value={data.wireframe_link1}
                            readOnly={true}
                        />
                        <iframe
                            style={stickbotIframeStyle}
                            className="i-frame"
                            src={isValidUrl(data?.wireframe_link) ? data?.wireframe_link : "https://www.google.com"}
                            frameBorder="0"
                        ></iframe>

                    </Grid>
                    :
                    <Grid container style={gridStyle} >
                        {/* <QuillEditor
                            sx={quilStyle}
                            value={data?.name}
                            readOnly={true}
                        /> */}
                        {
                            data?.type === 'Wireframe-Stickbot' ?
                                <>
                                    <iframe
                                        style={stickbotIframeStyle}
                                        className="i-frame"
                                        src={isValidUrl(data?.wireframe_link) ? data?.wireframe_link : "www.google.com"}
                                        frameBorder="0"
                                    ></iframe>

                                    <QuillEditor
                                        sx={quilStyle}
                                        value={data?.wireframe_link1}
                                        readOnly={true}
                                    />

                                </>
                                :
                                <ReactPlayer
                                    className="i-frame-video"
                                    url={data?.wireframe_link}
                                    playing={true}
                                    loop={true}
                                    controls={true}
                                />
                        }
                        <div style={stickbotButtons} >
                            <Button variant="contained" onClick={() => setActiveLink(1)}>{data?.content?.link_1_name}</Button>
                            {
                                !!data?.content?.link_2_name &&
                                <Button variant="contained" onClick={() => setActiveLink(2)}>{data?.content?.link_2_name}</Button>
                            }
                            {
                                !!data?.content?.link_3_name &&
                                <Button variant="contained" onClick={() => setActiveLink(3)}>{data?.content?.link_3_name}</Button>
                            }
                            {
                                !!data?.content?.link_4_name &&
                                <Button variant="contained" onClick={() => setActiveLink(4)}>{data?.content?.link_4_name}</Button>
                            }
                            {
                                !!data?.content?.link_5_name &&
                                <Button variant="contained" onClick={() => setActiveLink(5)}>{data?.content?.link_5_name}</Button>
                            }
                        </div>
                        <iframe
                            style={stickbotIframeStyle}
                            className="i-frame"
                            src={activeLink === 1 ? (isValidUrl(data?.content?.link_1) ? data?.content?.link_1 + `?userId=${getName(user)}` : "")
                                : activeLink === 2 ? (isValidUrl(data?.content?.link_2) ? data?.content?.link_2 + `?userId=${getName(user)}` : "")
                                    : activeLink === 3 ? (isValidUrl(data?.content?.link_3) ? data?.content?.link_3 + `?userId=${getName(user)}` : "")
                                        : activeLink === 4 ? (isValidUrl(data?.content?.link_4) ? data?.content?.link_4 + `?userId=${getName(user)}` : "")
                                            : (isValidUrl(data?.content?.link_5) ? data?.content?.link_5 + `?userId=${getName(user)}` : "")
                            }
                            frameBorder="0"
                        ></iframe>
                    </Grid>
            }
        </div>
    )
}

const RenderMatterportStickbot = ({ content, user }) => {
    const myObj = JSON.parse(content?.content)
    const { wireframe_link, wireframe_link1 } = myObj
    const { name, type } = content
    const [data, setStateData] = useState({
        name, type, wireframe_link, wireframe_link1
    })

    return (
        <Grid
            container
            style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'
            }}
        >
            {/* <QuillEditor
                sx={quilStyle}
                value={data?.name}
                readOnly={true}
            /> */}
            <iframe
                className="i-matport"
                width='800px'
                height='390px'
                style={{ marginLeft: '60px' }}
                src={isValidUrl(data?.wireframe_link) ? data?.wireframe_link : "www.google.com"}
                frameBorder="0">
            </iframe>
            <iframe
                style={{
                    width: "800px",
                    height: "390px",
                    padding: "5px",
                    marginTop: "15px",
                }}
                className="i-frame"
                src={isValidUrl(data?.wireframe_link) ? data?.wireframe_link + `?userId=${getName(user)}` : "www.google.com"}
                frameBorder="0"
            ></iframe>

        </Grid>
    )
}

const RenderIframIfram = ({ content }) => {
    const myObj = JSON.parse(content?.content)
    const { wireframe_link, wireframe_link1 } = myObj
    const { name, contentType: type } = content
    const [data, setStateData] = useState({
        name, type, wireframe_link, wireframe_link1
    })
    return (
        <Grid
            container
            style={gridStyle}
        >
            {/* <QuillEditor
                sx={quilStyle}
                value={data?.name}
                readOnly={true}
            /> */}
            {
                data?.type === 'Iframe-Iframe' ?
                    <>
                        <iframe
                            style={{
                                width: "844px",
                                height: "390px",
                                padding: "5px",
                                marginTop: "15px",
                            }}
                            className="i-frame"
                            src={isValidUrl(data?.wireframe_link) ? data?.wireframe_link : "www.google.com"}
                            frameBorder="0"
                        ></iframe>
                        <QuillEditor
                            sx={quilStyle}
                            value={data?.description}
                            readOnly={true}
                        />
                        <iframe
                            style={{
                                width: "844px",
                                height: "390px",
                                padding: "5px",
                                marginTop: "15px",
                            }}
                            className="i-frame"
                            src={isValidUrl(data?.wireframe_link1) ? data?.wireframe_link1 : "www.google.com"}
                            frameBorder="0"
                        ></iframe>
                    </>
                    :
                    <>
                        <iframe
                            style={{
                                width: "844px",
                                height: "390px",
                                padding: "5px",
                                marginTop: "15px",
                            }}
                            className="i-frame"
                            src={isValidUrl(data?.wireframe_link) ? data?.wireframe_link : "www.google.com"}
                            frameBorder="0"
                        ></iframe>
                        <TikTokEmbed url={data?.wireframe_link1} />

                    </>
            }
        </Grid>
    )
}

const RenderIframAudio = ({ content }) => {
    const myObj = JSON.parse(content?.content)
    const { wireframe_link, wireframe_link1, text } = myObj
    const { name, type } = content
    const [data, setStateData] = useState({
        name, type, wireframe_link, wireframe_link1, text
    })

    return (
        <Grid container style={gridStyle} >
            {/* <QuillEditor
                sx={quilStyle}
                value={data?.name}
                readOnly={true}
            /> */}
            <iframe
                style={{
                    width: "844px",
                    height: "390px",
                    padding: "5px",
                    marginTop: "15px",
                }}
                className="i-frame"
                src={isValidUrl(data?.wireframe_link) ? data?.wireframe_link : "www.google.com"}
                frameBorder="0"
            ></iframe>
            <CustomAudioPlayer
                autoPlay={false}
                src={data?.wireframe_link1}
                text={data?.text || "No subtitle to show"}
            />
        </Grid>
    )
}

const RenderImageVideo = ({ content }) => {
    const myObj = JSON.parse(content?.content)
    const { wireframe_link, wireframe_link1, text } = myObj
    const { name, description, type } = content
    const [data, setStateData] = useState({
        name, type, wireframe_link, wireframe_link1, text
    })
    const handleImageVisibility = () => {
        return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link
    }
    const handleVideoVisibility = () => {
        return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link1
    }
    return (
        <div className-="px-2">
            {/* <QuillEditor
                sx={{ marginTop: "20px", paddingX: '200px', }}
                value={data?.name}
                readOnly={true}
            /> */}
            <Grid
                container
            >
                <Grid xs={12} md={6} lg={6} sm={12} style={{
                    display: "flex",
                    alignItems: "center",
                    width: 'fit-content',
                    position: "relative"
                }}>
                    <img width="90%" src={handleImageVisibility()} alt="" />
                </Grid>
                <Grid xs={12} md={6} lg={6} sm={12} style={{
                    display: "flex",
                    alignItems: "center",
                    width: 'fit-content',
                    position: "relative"
                }}  >
                    <CustomVideoPlayer
                        url={handleVideoVisibility()}
                        text={data?.text || "No Transcript Added"}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

const RenderImageAudio = ({ content }) => {
    const myObj = JSON.parse(content?.content)
    const { wireframe_link, wireframe_link1, text } = myObj
    const { name, type } = content
    const [data, setStateData] = useState({
        name, type, wireframe_link, wireframe_link1, text
    })
    const handleImageVisibility = () => {
        return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link
    }

    return (
        <Grid
            container
            style={gridStyle}
        >
            <img
                width="50%"
                src={handleImageVisibility()}
                frameBorder="0"
            />
            <CustomAudioPlayer
                style={{}}
                autoPlay={false}
                src={data?.wireframe_link1}
                text={data?.text || "No subtile to show"}
            />
        </Grid>
    )
}

const RenderIframVideo = ({ content }) => {
    const myObj = JSON.parse(content?.content)
    const { wireframe_link, wireframe_link1 } = myObj
    const { name, type } = content
    const [data, setStateData] = useState({
        name, type, wireframe_link, wireframe_link1
    })
    const handleVideoVisibility = () => {
        return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link1
    }
    return (
        <Grid
            container
            style={gridStyle}
        >
            {/* <QuillEditor
                sx={quilStyle}
                value={data?.name}
                readOnly={true}
            /> */}
            <iframe
                style={{
                    width: "100%",
                    height: "390px",
                    padding: "5px",
                    marginTop: "15px",
                }}
                className="i-frame"
                width="100%"
                src={isValidUrl(data?.wireframe_link) ? data?.wireframe_link : "www.google.com"}
                frameBorder="0"
            ></iframe>

            <ReactPlayer
                url={handleVideoVisibility()}
                className="i-frame1"
                playing={true}
                loop={true}
                controls={true}
                style={{ width: '80%' }}
                width="100%"
            />

        </Grid>
    )
}

const ContentVideo = ({ content }) => {
    const isJSON = (str) => {
        try {
            JSON.parse(str)
            return true
        } catch (e) {
            return false
        }
    }
    const [videoPlay, setVideoPlay] = useState(false)
    const { name, type } = content
    const [data, setStateData] = useState({
        type,
        name,
        content: isJSON(content.content) ? JSON.parse(content.content)?.link : content.content,
        text: isJSON(content.content) ? JSON.parse(content.content)?.text : ""
    })

    const videoVisibility = () => {
        return process.env.REACT_APP_S3_BUCKET_LINK + data?.content
    }
    return (
        <div className="col-12 d-flex flex-column align-items-center" style={{ height: '100%', width: '100%' }}>
            <div className="container-ss" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* <QuillEditor
                    sx={quilStyle}
                    value={data?.name}
                    readOnly={true}
                /> */}
                <CustomVideoPlayer
                    url={videoVisibility()}
                    text={data?.text || "No Transcript Added"}
                />
            </div>
        </div>
    )
}

const RenderAudio = ({ content }) => {
    const { type, name } = content
    const [data, setStateData] = useState({
        type,
        name,
        content: JSON.parse(content.content)?.link,
        text: JSON.parse(content.content)?.text,
    })

    return (

        <CustomAudioPlayer
            style={{ marginTop: '40px' }}
            autoPlay={false}
            src={data?.content}
            text={data?.text || "No subtitle to show"}
        />
    )
}

const RenderDoodleVideo = ({ content }) => {
    const { type, name } = content
    const [data, setStateData] = useState({
        type,
        name,
        content: content.content
    })
    const videoVisibility = () => {
        return process.env.REACT_APP_S3_BUCKET_LINK + data?.content
    }
    return (
        <div className="col-12 d-flex flex-column align-items-center" style={{ height: '100%', width: '100%' }}>
            <div className="container-s1" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* <QuillEditor
                    sx={quilStyle}
                    value={data?.name}
                    readOnly={true}
                /> */}
                <ReactPlayer
                    className="video80"
                    url={videoVisibility()}
                    playing={true}
                    loop={true}
                    controls={true}
                    style={{ width: '90%', height: '100%' }}
                    width='90%'
                    height='100%'
                />
            </div>
        </div>
    )
}

const RenderYoutube = ({ content }) => {
    const { type, name } = content
    const [data, setStateData] = useState({
        type,
        name,
        content: content.content
    })
    return (
        <div className="col-12" style={containerStyle}>
            <div className="container-ss">
                {/* <QuillEditor
                    sx={quilStyle}
                    value={data?.name}
                    readOnly={true}
                /> */}
                <ReactPlayer
                    className="i-frame-video"
                    url={data?.content}
                    playing={true}
                    loop={true}
                    controls={true}
                />
            </div>
        </div>
    )
}

const RenderTiktok = ({ content }) => {
    const { type, name } = content
    const [data, setStateData] = useState({
        type,
        name,
        content: content?.content
    })

    return (

        <div className="row d-flex justify-content-center px-3 mt-3" style={{ width: '100%' }}>
            <div className="col-sm-6">
                <TikTokEmbed url={data?.content} />
            </div>
        </div>
    )
}

const RenderTiktokHybrid = ({ content }) => {
    const { type, name } = content
    const [data, setStateData] = useState({
        type,
        name,
        content: content.content
    })

    const videoVisibility = () => {
        return process.env.REACT_APP_S3_BUCKET_LINK + data?.content
    }
    return (

        <div className="row d-flex justify-content-center px-3 mt-3" style={{ width: '100%' }}>
            <ReactPlayer
                url={videoVisibility()}
                playing={true}
                loop={true}
                controls={true}
                style={{ width: "300px", height: "300px" }}
                width="100%"
            />
        </div>
    )
}

const VideoSixGrid = ({ content }) => {
    const [muted, setMuted] = useState(false)
    const [replay, setReplay] = useState(false)
    const contentArr = content?.content?.split("$") || []
    const { name, type } = content
    const [data, setStateData] = useState({
        name,
        type,
        grid: contentArr,
    })
    const [nextPlay, setNextPlay] = useState([])

    const videoVisibility = (url) => {
        return process.env.REACT_APP_S3_BUCKET_LINK + url
    }
    useEffect(() => {
        data?.grid?.map((item, i) => {
            if (i === 0) {
                setNextPlay(old => [...old, true])
            } else {
                setNextPlay(old => [...old, false])
            }
        })
    }, [data])
    function playVid(index) {
        var vid = document.getElementById(index + 1);
        if (vid) {
            vid.play();
        }
        if (data?.grid?.length > 4) {
            if (data?.grid?.length === index + 2) {
                setReplay(true)
            } else {
                setReplay(false)
            }
        } else {
            if (data?.grid?.length === index + 3) {
                setReplay(true)
            } else {
                setReplay(false)
            }
        }
    }
    return (
        <Grid style={gridWraper} >
            <Grid container style={gridContainer} >
                {!!data?.grid?.length && <div style={actionBtns}>
                    <IconButton className="no-style" style={{ color: '#fff' }}
                        onClick={() => setMuted(!muted)}
                        aria-label="Volume">
                        {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                    </IconButton>
                    <IconButton
                        className="no-style"
                        style={{ color: replay ? '#fff' : '#ccc' }}
                        disabled={!replay} aria-label="Replay"
                        onClick={() => playVid(-1)}
                    >
                        <ReplayIcon />
                    </IconButton>
                </div>}
                {data?.grid?.map((arr, index) => {
                    return (arr !== '' && <Grid xs={6} style={gridItem} key={arr}  >
                        <video style={{ width: '100%' }} id={index} controls={false} autoPlay={nextPlay[index]} onEnded={(e) => {
                            // setPlayVideo((index + 1).toString())
                            playVid(index);
                        }} muted={muted}  >
                            <source type="video/mp4" src={videoVisibility(arr)} />
                        </video>
                    </Grid>)
                })}
            </Grid>
        </Grid>
    )
}

const RenderImageGrid = ({ content }) => {
    const contentArr = content?.content?.split("$") || []
    const { name, type } = content
    const [data, setStateData] = useState({
        name,
        type,
        grid: contentArr,
    })
    const imageVisibility = (url) => {
        return process.env.REACT_APP_S3_BUCKET_LINK + url
    }

    return (

        <Grid style={gridWraper}>
            <Grid container style={gridContainer} >
                {
                    data?.grid?.map((arr, index) => {
                        return (
                            arr !== '' &&
                            <Grid xs={6} style={gridItem} key={arr}>
                                <img width="100%" src={imageVisibility(arr)} alt="" />
                            </Grid>)
                    })
                }
            </Grid>
        </Grid>
    )
}

const RenderImage = ({ content }) => {
    const { type, name } = content
    const [data, setStateData] = useState({
        type,
        name,
        content: process.env.REACT_APP_S3_BUCKET_LINK + content.content
    })
    const handleImageVisibility = () => {
        return data?.content
    }
    return (
        <img style={imgStyle}
            src={handleImageVisibility()}
            alt="" />
    )
}

const PPTImage = ({ content }) => {

    const { type, name } = content
    const [data, setStateData] = useState({
        type,
        name,
        content: content.content
    })



    const handleImageVisibility = () => {
        return process.env.REACT_APP_S3_BUCKET_LINK + data?.content
    }
    return (
        <div className="d-flex align-items-center">
            <div className="col-6" style={{ textDescription: 'none', display: 'flex', flexDirection: 'column', alignItems: 'start', marginLeft: '90px' }}>
                <h1 style={{ textAlign: 'left' }} className="text-primary1"
                    data-aos="fade-up" data-aos-easing="linear"
                >
                    {`{Skill Name}`}
                </h1>
                <h1 style={{ textAlign: 'left' }} className="text-danger"
                    data-aos="fade-up" data-aos-easing="linear"
                >
                    {'{Sub Skill Name}'}
                </h1>
            </div>
            <div className="col-5 text-right d-flex flex-column align-items-center justify-content-center" style={{ textDescription: 'none', }}>
                <img
                    style={{
                        width: '80%',
                        marginTop: "-5px"
                    }}
                    src={handleImageVisibility()}
                    alt=""
                    data-aos="fade-right"
                    data-aos-delay="2000"
                    data-aos-easing="linear"
                />

            </div>
        </div>
    )
}

const RenderStickbotVertical = ({ content, user }) => {
    const { name, type } = content
    const [data, setStateData] = useState({
        name,
        type,
        content: content?.content
    })
    return (
        <Grid container justifyContent="center" >
            <iframe
                style={{
                    width: '80%'
                }}
                src={isValidUrl(data?.content) ? data?.content + `?userId=${getName(user)}` : "www.google.com"}
                width='85%'
                height='400px'
                frameBorder="0">
            </iframe>
        </Grid>
    )
}

const RenderImageVertical = ({ content }) => {
    const { type, name } = content
    const [data, setStateData] = useState({
        type,
        name,
        content: process.env.REACT_APP_S3_BUCKET_LINK + content.content
    })
    const handleImageVisibility = () => {
        return data?.content
    }
    return (
        <Grid container justifyContent="center" >
            <img
                style={{
                    width: '70%',
                    marginTop: "-5px"
                }}
                src={handleImageVisibility()}
                alt=""
            />
        </Grid>

    )
}

const RenderImageWireframeAndImageStickbot = ({ content, user }) => {
    const myObj = JSON.parse(content?.content)
    const { wireframe_link, wireframe_link1 } = myObj
    const { name, type } = content
    const [data, setStateData] = useState({
        name, type, wireframe_link, wireframe_link1
    })
    const imageVisibility = () => {
        return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link1
    }

    return (
        <>
            <div className="d-flex justify-content-center" style={{ height: 'fit-content' }}>
                <img
                    style={{
                        width: '80%',
                    }}
                    src={imageVisibility()}
                    alt=""
                />
            </div>
            <iframe
                height="400px"
                src={isValidUrl(data?.wireframe_link) ? data?.wireframe_link + `?userId=${getName(user)}` : "www.google.com"}
                frameBorder="0">
            </iframe>
        </>
    )
}

const RenderWireframe = ({ content, user }) => {
    const { name, type } = content
    const [data, setStateData] = useState({
        type,
        name,
        content: content.content
    })

    const isTypeBot = content?.content ? content?.content?.includes("stickbot-viewer.vercel.app") : false

    return (

        <iframe
            height="350px"
            src={isTypeBot ? `${isValidUrl(data?.content) ? data?.content : ""}?userId=${getName(user)}` : isValidUrl(data?.content) ? data?.content : ""}
            frameBorder="0">
        </iframe>
    )
}

const RenderMatterport = ({ content }) => {
    const { name, type } = content
    const [data, setStateData] = useState({
        name,
        type,
        content: content?.content

    })
    return (
        <iframe
            width='100%'
            height='350px'
            src={isValidUrl(data?.content) ? data?.content : "www.google.com"}
            frameBorder="0">
        </iframe>
    )
}

const GoogleForm = ({ content }) => {
    const { name, contentType: type } = content
    const [data, setStateData] = useState({
        name,
        type,
        content: content?.content

    })

    const getLink = () => {
        if (!data?.content) return ""
        if (type === "GoogleForm") return process.env.REACT_APP_GOOLE_FORM_LINK + data.content + "?userID="
        return process.env.REACT_APP_JOT_FORM_LINK + data.content + "?userID="
    }

    return (
        <iframe
            height="350px"
            className="i-frame"
            src={getLink()}>
        </iframe>
    )
}

const RenderDropdown = ({ content }) => {
    const { name, type } = content
    const [data, setStateData] = useState({
        name,
        type,
        dropdowns: JSON.parse(content?.content)
    })

    return (
        <CollapsibleTable
            data={data}
        />
    )
}



/*-----------------------------------------------------------------
    Styles
-------------------------------------------------------------------*/


const containerStyle = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}

const gridStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
}

const quilStyle = {
    fontSize: '16px'
}

const imgStyle = {
    width: '100%',
    maxWidth: "100%",
    borderRadius: "10px",
}

const inputStyle = {
    position: "absolute",
    left: "1px",
    backgroundColor: "white",
    width: '95%',
    opacity: "0%",
    textAlign: 'center'
}
const gridWraper = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0px",
    overflow: "hidden"
}
const gridContainer = {
    display: "flex",
    justifyContent: "center",
    margin: "0px",
    width: "100%",
    position: "relative",
}
const actionBtns = {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: 'rgba(0,0,0,0.7)',
    color: '#fff'
}
const gridItem = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 'fit-content',
    position: "relative"
}
const stickbotIframeStyle = {
    width: "844px",
    height: "390px",
    padding: "5px",
    marginTop: "15px",
}
const stickbotButtons = {
    width: "90%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '50px 30px 10px 30px'
}

const audioPlayerStyle = {
    position: 'relative',
    width: '100%'

}
const ccButton = {
    position: 'absolute',
    bottom: '2px',
    left: '50px',
}
const ccVideoButton = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    color: '#fff'
}
const audioText = {
    minWidth: 200,
    maxWidth: 500,
    textAlign: 'center',
    padding: '20px',
    margin: 0
}