import { Box, Typography } from '@mui/material'
import React from 'react'
const FieldSetComp = ({ children, width, heading, color }) => {
    return (
        <Box sx={{
            width: width || '30%',
            border: `0.7px solid ${color || "#616DED"}`,
            position: "relative",
            paddingTop: 5,
            paddingX: 2,
            paddingBottom: 2,
            minHeight: 230,
            '@media (max-width: 700px)': {
                width: '90%',
            },
        }}>
            <Typography
                fontSize={23}
                color={color || "#616DED"}
                backgroundColor="white"
                position="absolute"
                top={-20}
                left={10}
                padding={0.5}
                fontFamily={'Poppins'}
                      >
                      {heading?.replace("_"," ")}</Typography>
            {children}
        </Box>
    )
}
export default FieldSetComp