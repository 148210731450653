import { Link } from 'react-router-dom';

function AddRule() {
    return (<>
        <div className="appHeader">
            <div className="left">
                <a href="#" className="headerButton goBack" onClick={() => window.history.go(-1)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div className="pageTitle">Create a Rule</div>
            <div className="right">
            </div>
        </div>
        <p className="text-center mt-5 pt-5 text-primary"><b>If the transaction...</b></p>
        <div id="appCapsule" className="m-0 p-0">
            <ul className="listview image-listview inset" >
                <li>
                    <Link to="/add-rule" className="item" style={{ width: 'fit-content', alignItems: 'center' }}>
                        <div className="in">
                            <p className="mt-2" style={{ fontSize: '12px' }}>Tap to add matching criteria...</p>
                        </div>
                    </Link>
                </li>
            </ul>
        </div>
        <p className="text-center mt-4 text-primary"><b>Apply these updates...</b></p>
        <div id="appCapsule" className="m-0 p-0">
            <ul className="listview image-listview inset" >
                <li>
                    <Link to="/categories-and-rules" className="item" style={{ width: 'fit-content', alignItems: 'center' }}>
                        <div className="in">
                            <p className="mt-2" style={{ fontSize: '12px' }}>Change Category</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/categories-and-rules" className="item" style={{ width: 'fit-content', alignItems: 'center' }}>
                        <div className="in">
                            <p className="mt-2" style={{ fontSize: '12px' }}>Remaining Transaction</p>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/categories-and-rules" className="item" style={{ width: 'fit-content', alignItems: 'center' }}>
                        <div className="in">
                            <p className="mt-2" style={{ fontSize: '12px' }}>Assign to bill</p>
                        </div>
                    </Link>
                </li>
            </ul>
        </div>
    </>);
}

export default AddRule;