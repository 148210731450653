import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getQuote, getAllData } from "../_api/index";
import ScoreIcon from "@mui/icons-material/Score";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import HomeIcon from "@mui/icons-material/Home";
import CircularProgress from "@mui/material/CircularProgress";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import CalculateIcon from "@mui/icons-material/Calculate";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Modal, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ExploreIcon from '@mui/icons-material/Explore';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import {
  loading_iframe, docAi,
  Dictionary,
  healthCare,
  practiseShoping,
} from '../assets/';
import SkillAssignments from "../common/SkillAssignments";
import CareerCompassModal from '../home/CareerCompassModal'
import TurboTaxes from "../home/TurboTaxes";
import LoaderComp from "./loaderComp";
import "./index.css"
const Link1 = ({ children, to }) => {
  const navigate = useNavigate()
  const handleNav = () => {
    navigate('/', { replace: true })
    navigate(to)
  }

  return <a className="item" onClick={handleNav}> {children} </a>
}

export default function SideBar(props) {
  const organizationId = props?.user?.OrganizationId
  const isFinLitFair = organizationId == "65fdbd97bdd44a00129afad7"
  const [openPracticeModal, setOpenPracticeModal] = useState(false);
  const [openHealth, setOpenHealth] = useState(false);
  const [showSkillmodal, setShowSkillModal] = useState(false);
  const [showZillomodal, setShowZilloModal] = useState(false);
  const [showShopmodal, setShowShopModal] = useState(false);
  const [showDocumentmodal, setShowDocumentModal] = useState(false);
  const [partName, setPartName] = useState();
  const [isLoadIframe, setIframeLoaded] = useState(false);
  const [compassModal, setCompassModal] = useState(false);
  const [showStockModal, setShowStockModal] = useState(false)
  const [openTurboTaxes, setOpenTurboTaxes] = useState(false)

  const handleOpen = () => {
    setOpenPracticeModal(true);
    setIframeLoaded(true)
  };

  // const { isLoading: quoteLoading, data: quote } = useQuery("quote", getQuote);
  const { isLoading: dataLoading, data: allData } = useQuery(
    "budget",
    getAllData, {
    enabled: Boolean(sessionStorage.getItem('token'))
  }
  );
  useEffect(() => {
    if (!dataLoading) {
      props.setUser(allData?.user || null);
    }
  }, [dataLoading, allData]);
  return (
    <div>
      <TurboTaxes openTurboTaxes={openTurboTaxes} setOpenTurboTaxes={setOpenTurboTaxes} />
      <div
        className="modal fade panelbox panelbox-left"
        id="sidebarPanel"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="profileBox pt-3 pb-2 bg-primary">
                <div className="in text-white">
                  {/* <Chip icon={<FaceIcon />} label="Premium Member" variant="outlined" /> */}
                </div>
                <a
                  href="#"
                  className="btn btn-icon sidebar-close text-white"
                  data-bs-dismiss="modal"
                >
                  <ion-icon name="close-outline" size="large"></ion-icon>
                </a>
              </div>
              <div className="sidebar-balance pl-5">
                <div className="in">
                  <h3 className="amount1">
                    {dataLoading ? <CircularProgress /> : <>Hi </>}
                  </h3>
                  <h1 className="amount">
                    {dataLoading ? (
                      <CircularProgress />
                    ) : allData?.user?.firstName
                    }
                  </h1>
                  {/* <p className="text-white" style={{ fontSize: "12px" }}>
                    {quoteLoading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        "{quote?.contents.quotes[0].quote}" <br />
                        <b>-{quote?.contents.quotes[0].author}</b>
                      </>
                    )}
                  </p> */}
                </div>
              </div>
              <ul className="listview flush transparent no-line image-listview mt-3 ml-4">
                <li data-bs-dismiss="modal">
                  <Link1 to="/" className="item">
                    <div className="icon-box">
                      <img
                        width="30px"
                        height="30px"
                        src="assets/img/icon/home sidebar.svg"
                        className="logo"
                        alt=""
                      />
                    </div>
                    <div className="in">Home</div>
                  </Link1>
                </li>
                <li data-bs-dismiss="modal">
                  <Link1 to="/manage-budget" className="item">
                    <div className="icon-box">
                      <img
                        width="30px"
                        height="30px"
                        src="assets/img/icon/manage budget sidebar.svg"
                        className="logo"
                        alt=""
                      />
                    </div>
                    <div className="in">Budget</div>
                  </Link1>
                </li>
               { !isFinLitFair &&  <li data-bs-dismiss="modal">
                  <Link1 to="/financial-goals" className="item">
                    <div className="icon-box">
                      <img
                        width="30px"
                        height="30px"
                        src="assets/img/icon/financial goals sidebar.svg"
                        className="logo"
                        alt=""
                      />
                    </div>
                    <div className="in">Goals</div>
                  </Link1>
                </li>}
               { !isFinLitFair &&  <li data-bs-dismiss="modal">
                  <Link1 to="/finance-calculator" className="item">
                    <div className="icon-box">
                      <img
                        width="30px"
                        height="30px"
                        src="assets/img/icon/financial calculator sidebar.svg"
                        className="logo"
                        alt=""
                      />
                    </div>
                    <div className="in">Calculators</div>
                  </Link1>
                </li>}
               { !isFinLitFair &&  <li data-bs-dismiss="modal">
                  <Link1 to="/net-worth" className="item">
                    <div className="icon-box">
                      <img
                        width="30px"
                        height="30px"
                        src="assets/img/icon/net worth sidebar.svg"
                        className="logo"
                        alt=""
                      />
                    </div>
                    <div className="in">Net Worth</div>
                  </Link1>
                </li>}
                { !isFinLitFair &&  <li data-bs-dismiss="modal">
                  <Link1 to="/credit-score" className="item">
                    <div className="icon-box">
                      <img
                        width="30px"
                        height="30px"
                        src="assets/img/icon/credit score sidebar.svg"
                        className="logo"
                        alt=""
                      />
                    </div>
                    <div className="in">Credit Score</div>
                  </Link1>
                </li>}

                { !isFinLitFair &&  <li
                  data-bs-dismiss="modal"
                >
                  <Link
                    to="#"
                    className="item"
                    data-bs-toggle="modal"
                    data-bs-target="#zilloApp"
                    onClick={() => { setShowZilloModal(true); setIframeLoaded(true) }}
                  >
                    <div className="icon-box">
                      <img
                        width="30px"
                        height="30px"
                        src="assets/img/icon/home-rent-sidebar.svg"
                        className="logo"
                        alt=""
                      />
                    </div>
                    <div className="in">Rent & Home</div>
                  </Link>
                </li>}

               { !isFinLitFair &&  <li data-bs-dismiss="modal">
                  <Link
                    to="#"
                    className="item"
                    data-bs-toggle="modal"
                    onClick={() => { setShowShopModal(true); setIframeLoaded(true) }}
                  >
                    <div className="icon-box">
                      <img
                        width="30px"
                        height="30px"
                        src="assets/img/icon/financial-decision-sidebar.svg"
                        className="logo"
                        alt=""
                      />
                    </div>
                    <div className="in">Shop & Compare</div>
                  </Link>
                </li>}

               { !isFinLitFair &&  <li data-bs-dismiss="modal">
                  <Link
                    to="#"
                    className="item"
                    data-bs-toggle="modal"
                    onClick={handleOpen}
                  >
                    <div className="icon-box">
                      <img
                        width="30px"
                        height="30px"
                        src={practiseShoping}
                        className="logo"
                        alt=""
                      />
                    </div>
                    <div className="in">Practice Shopping</div>
                  </Link>
                </li>}

                {!isFinLitFair && <li data-bs-dismiss="modal">
                  <Link
                    to="#"
                    className="item"
                    data-bs-toggle="modal"
                    onClick={() => { setShowDocumentModal(true); setIframeLoaded(true) }}
                  >
                    <div className="icon-box">
                      <img
                        width="30px"
                        height="30px"
                        src={docAi}
                        className="logo"
                        alt=""
                      />
                    </div>
                    <div className="in">Document AI</div>
                  </Link>
                </li>}

                { !isFinLitFair &&  <li data-bs-dismiss="modal">
                  <Link
                    to="#"
                    className="item"
                    data-bs-toggle="modal"
                    onClick={() => { setOpenHealth(true); setIframeLoaded(true) }}
                  >
                    <div className="icon-box">
                      <img
                        width="30px"
                        height="30px"
                        src={healthCare}
                        className="logo"
                        alt=""
                      />
                    </div>
                    <div className="in">Health Care</div>
                  </Link>
                </li>}

                <li data-bs-dismiss="modal">
                  <Link1 to="/Dictionary" className="item">
                    <div className="icon-box">
                      <img
                        width="30px"
                        height="30px"
                        src={Dictionary}
                        className="logo"
                        alt=""
                      />
                    </div>
                    <div className="in">Dictionary</div>
                  </Link1>
                </li>

                {!isFinLitFair && <li data-bs-dismiss="modal">
                  <Link1 to="/boost" className="item">
                    <div className="icon-box">
                      <img
                        width="30px"
                        height="30px"
                        src="assets/img/icon/boost-icon.png"
                        className="logo"
                        alt=""
                      />
                    </div>
                    <div className="in">Boost</div>
                  </Link1>
                </li>}
               {!isFinLitFair &&  <li data-bs-dismiss="modal">
                  <Link
                    to="#"
                    className="item"
                    data-bs-toggle="modal"
                    onClick={() => { setOpenTurboTaxes(true) }}
                  >
                    <div className="icon-box">
                      <AssuredWorkloadIcon style={{
                        height: "30px",
                        width: "30px"
                      }} className="logo" />
                    </div>
                    <div className="in">Taxes</div>
                  </Link>
                </li>}

                <li data-bs-dismiss="modal">
                  <Link to="#" className="item"
                    data-bs-toggle="modal"
                    data-bs-target="#careerCompass"
                  // onClick={() => {
                  //   setCompassModal(true)
                  //   setIframeLoaded(true)
                  // }}
                  >
                    <div className="icon-box">
                      <ExploreIcon style={{
                        height: "30px",
                        width: "30px"
                      }} className="logo" />
                    </div>
                    <div className="in">Workforce Readiness</div>
                  </Link>
                </li>

               {!isFinLitFair && <li data-bs-dismiss="modal">
                  <Link
                    to="#"
                    className="item"
                    data-bs-toggle="modal"
                    onClick={() => { setShowStockModal(true); setIframeLoaded(true) }}
                  >
                    <div className="icon-box">
                      <AutoGraphIcon style={{
                        height: "30px",
                        width: "30px"
                      }} className="logo" />
                    </div>
                    <div className="in">Trading Flow</div>
                  </Link>
                </li>}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal
          open={showZillomodal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            // maxWidth: "100%",
            marginTop: '5%',
            display: 'flex',
            justifyContent: 'center',
          }}
          sx={{
            padding: "0px 100px 0px 100px",
            '@media( max-width:520px )': {
              padding: "0px 40px 0px 40px",
            },
            '@media( max-width:360px )': {
              padding: "0px 20px 0px 20px",
            },
          }}
        >
          <div
            className="modal-content zillo-modal-container"
            style={{
              minWidth: "100%",
              height: '90%'
            }}
          >
            <div className="modal-header">
              <div className="btn-inline">
                <div className="text-primary bg-white">
                  <CloseIcon onClick={() => setShowZilloModal(false)} color='red' />
                </div>
              </div>
            </div>
            <div className="modal-body pb-3 p-0" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: "100%" }}>
              {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
              <iframe
                onLoad={() => setIframeLoaded(false)}
                src={process.env.REACT_APP_ZILLOW + "?token=" + sessionStorage.getItem('token')}
                allowFullScreen="true"
                width="100%"
                height="100%"
                style={{
                  border: 0,
                  zIndex: 10
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>

            </div>
          </div>
        </Modal >
        <Modal
          open={showStockModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            maxWidth: "100%",
            marginTop: '5%',
            display: 'flex',
            justifyContent: 'center',
          }}
          sx={{
            padding: "0px 100px 0px 100px",
            '@media( max-width:520px )': {
              padding: "0px 40px 0px 40px",
            },
            '@media( max-width:360px )': {
              padding: "0px 20px 0px 20px",
            },
          }}
        >
          <div
            className="modal-content zillo-modal-container"
            style={{
              // maxWidth: "100%",
              height: '90%'
            }}
          >
            <div className="modal-header">
              <Button sx={{
                fontWeight: 'bold',
                textTransform: 'capitalize',
                border: '1px solid #616DED',
                borderRadius: "10px",
              }} variant='outlined' onClick={() => { setShowSkillModal(true); setPartName("trading-flow") }}>Skill Assignment</Button>
              <div className="btn-inline">
                <div className="text-primary bg-white">
                  <CloseIcon onClick={() => setShowStockModal(false)} color='red' />
                </div>
              </div>
            </div>
            <div className="modal-body pb-3" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: "100%" }}>
              {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
              <iframe
                onLoad={() => setIframeLoaded(false)}
                src={'https://stock-game.stickball.biz/'}
                allowFullScreen="true"
                width="100%"
                height="100%"
                style={{
                  border: 0,
                  zIndex: 10
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <SkillAssignments user={props?.user?.id} open={showSkillmodal} setOpen={setShowSkillModal} partName={partName} />
            </div>
          </div>
        </Modal >
        <Modal
          open={showShopmodal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            maxWidth: "100%",
            marginTop: '5%',
            display: 'flex',
            justifyContent: 'center',
          }}
          sx={{
            padding: "0px 100px 0px 100px",
            '@media( max-width:520px )': {
              padding: "0px 40px 0px 40px",
            },
            '@media( max-width:360px )': {
              padding: "0px 20px 0px 20px",
            },
          }}
        >
          <div
            className="modal-content zillo-modal-container"
            style={{
              // maxWidth: "100%",
              height: '90%'
            }}
          >
            <div className="modal-body pb-0" style={{ position: 'relative' }}>
              <div className="text-primary bg-white" style={{ position: 'absolute', top: 5, right: 5 }}>
                <CloseIcon onClick={() => setShowShopModal(false)} color='red' />
              </div>
              {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
              <iframe
                onLoad={() => setIframeLoaded(false)}
                src={`${process.env.REACT_APP_SUPER_MONEY}?token=${sessionStorage.getItem('token')}`}
                allowFullScreen="true"
                width="100%"
                height="100%"
                style={{
                  border: 0,
                  zIndex: 10
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </Modal >
        <Modal
          open={showDocumentmodal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            maxWidth: "100%",
            marginTop: '5%',
            display: 'flex',
            justifyContent: 'center',
          }}
          sx={{
            padding: "0px 100px 0px 100px",
            '@media( max-width:520px )': {
              padding: "0px 40px 0px 40px",
            },
            '@media( max-width:360px )': {
              padding: "0px 20px 0px 20px",
            },
          }}
        >
          <div
            className="modal-content zillo-modal-container"
            style={{
              // maxWidth: "100%",
              height: '90%'
            }}
          >
            <div className="modal-header">
              <Button sx={{
                fontWeight: 'bold',
                textTransform: 'capitalize',
                border: '1px solid #616DED',
                borderRadius: "10px",
              }} variant='outlined' onClick={() => { setShowSkillModal(true); setPartName("document-ai") }}>Skill Assignment</Button>
              <div className="btn-inline">
                <div className="text-primary bg-white">
                  <CloseIcon onClick={() => setShowDocumentModal(false)} color='red' />
                </div>
              </div>
            </div>
            <div className="modal-body pb-3" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: "100%" }}>
              {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
              <iframe
                onLoad={() => setIframeLoaded(false)}
                src={"https://document-ocr.stickball.biz/"}
                allowFullScreen="true"
                width="100%"
                height="100%"
                style={{
                  border: 0,
                  zIndex: 10
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <SkillAssignments user={props?.user?.id} open={showSkillmodal} setOpen={setShowSkillModal} partName={partName} />
          </div>
        </Modal >
        <Modal
          open={openHealth}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            maxWidth: "100%",
            marginTop: '5%',
            display: 'flex',
            justifyContent: 'center',
          }}
          sx={{
            padding: "0px 100px 0px 100px",
            '@media( max-width:520px )': {
              padding: "0px 40px 0px 40px",
            },
            '@media( max-width:360px )': {
              padding: "0px 20px 0px 20px",
            },
          }}
        >
          <div
            className="modal-content zillo-modal-container"
            style={{
              // maxWidth: "100%",
              height: '90%'
            }}
          >
            <div className="modal-header">
              <Button sx={{
                fontWeight: 'bold',
                textTransform: 'capitalize',
                border: '1px solid #616DED',
                borderRadius: "10px",
              }} variant='outlined' onClick={() => { setShowSkillModal(true); setPartName("health-care") }}>Skill Assignment</Button>
              <div className="btn-inline">
                <div className="text-primary bg-white">
                  <CloseIcon onClick={() => setOpenHealth(false)} color='red' />
                </div>
              </div>
            </div>

            <div className="modal-body pb-3" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: "100%" }}>
              {isLoadIframe && <img src={'./gif/loading.gif'} style={{ width: "100%", position: 'absolute' }} />}
              <iframe
                onLoad={() => setIframeLoaded(false)}
                src={"https://unity.stickball.biz/doctor-office"}
                allowFullScreen="true"
                width="100%"
                style={{
                  border: 0,
                  zIndex: 10
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                height="100%"
              ></iframe>
              <SkillAssignments user={props?.user?.id} open={showSkillmodal} setOpen={setShowSkillModal} partName={partName} />
            </div>
          </div>
        </Modal>
        
        <Modal
          open={openPracticeModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            maxWidth: "100%",
            marginTop: '5%',
            display: 'flex',
            justifyContent: 'center',
          }}
          sx={{
            padding: "0px 100px 0px 100px",
            '@media( max-width:520px )': {
              padding: "0px 40px 0px 40px",
            },
            '@media( max-width:360px )': {
              padding: "0px 20px 0px 20px",
            },
          }}
        >
          <div
            className="modal-content zillo-modal-container"
            style={{
              // maxWidth: "100%",
              height: '90%'
            }}
          >
            <div className="modal-header">
              <div className="btn-inline">
                <div className="text-primary bg-white">
                  <CloseIcon onClick={() => setOpenPracticeModal(false)} color='red' />
                </div>
              </div>
            </div>
            <div className="modal-body pb-3" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minWidth: "100%" }} >
              {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
              <iframe
                onLoad={() => setIframeLoaded(false)}
                src={`${process.env.REACT_APP_SUPER_STORE}?token=${sessionStorage.getItem('token')}`}
                width="100%"
                height="100%"
                style={{
                  border: 0,
                  zIndex: 10
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </Modal>

      <CareerCompassModal />
    </div>
  );
}
