import { useState, useEffect } from 'react'
import { currencyFormat } from '../_budget-functionality/index'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Header from './Header'
import Loading from '../common/loading';
import { useQuery } from 'react-query';
import { getAllData } from '../_api/index'
import { budgetHistory, selectDate, selectCompareDate } from '../_budget-functionality/index';
import { toLatestArray } from '../_budget-functionality/index'

function BudgetHistory() {
    const { isLoading, data: allData } = useQuery("budget-calculator", getAllData, {
        enabled: Boolean(sessionStorage.getItem('token'))
    });

    const [duration, setDuration] = useState("This Month")
    const [data, setData] = useState([])
    const { id } = useParams();
    useEffect(() => {
        if (!isLoading) {
            setData(budgetHistory(id, allData, "ManageBudget", selectDate(duration), selectCompareDate(duration)))
        }
    }, [allData, duration]);
    if (isLoading) {
        return (<Loading />)
    } else
        return (<>
            <Header />
            <div className="icon-box history-icon bg-success-dark">
                <ion-icon name="wallet"></ion-icon>
            </div>
            <div id="appCapsule" className="mt-5  p-5">
                <h1 className="mt-5 pt-5">Monthly Income</h1>
                <div>
                    <h3 data-bs-toggle="modal" data-bs-target="#changeDuration" className="text-success">
                        <select className="text-success" style={{ border: 'none', outline: 'none', fontWeight: '500', backgroundColor: 'transparent' }} value={duration} onChange={(e) => setDuration(e.target.value)}>
                            <option value="This Month">This Month</option>
                            <option value="Last Month">Last Month</option>
                            <option value="This Week">This Week</option>
                            <option value="Last Week">Last Week</option>
                            <option value="Past Three Months">Past Three Months</option>
                            <option value="This Year">This Year</option>
                            <option value="Last Year">Last Year</option>
                            <option value="Month Name">Month Name</option>
                        </select>
                    </h3>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    {
                                        data.length === 0 ?
                                            <th scope="col" className="text-center">No Data Found</th>
                                            :
                                            <>
                                                <th scope="col">Date</th>
                                                <th scope="col" className="text-end">Amount</th>
                                            </>
                                    }
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    data.length != 0 ?
                                        toLatestArray(data).map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td scope="row">
                                                        {moment(item.attributes.createdAt).format("MMMM Do YYYY")} <br />
                                                        {moment(item.attributes.createdAt).format("h:mm:ss A")}
                                                    </td>
                                                    <td className="text-end text-primary">{currencyFormat(parseInt(item.attributes.amount))}</td>
                                                </tr>)
                                        })
                                        :
                                        <tr scope="row">
                                            <td>No history for this time priod</td>
                                        </tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>);
}

export default BudgetHistory;