import React from "react";

function CardRapper({ children, title }) {
    return <div className="cal-card">
        <div className="cal-card-title">{title||"--"}</div>
        <div className="cal-card-body">
            {children}

        </div>
    </div>

}

export default CardRapper;
