import React, { useEffect, useState } from 'react';
import MobileView, { FinanceCalculatorAccess } from './MobileView';
import isAuthenticated from '../components/_budget-functionality/auth'
import swal from "sweetalert";
import 'react-circular-progressbar/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import ThemeProviderCustom from '../ThemeProvider';

//Google Analytics Intergration
import ReactGA from 'react-ga';
import "./index.css"
import Layout from '../layouts';
import { useQuery } from 'react-query';
import { getUserByToken } from '../components/_api/user';
const TRACKING_ID = process.env.REACT_APP_GA_TID; //Google Analytics Tracking ID

ReactGA.initialize(TRACKING_ID);

function App() {
  var url = new URL(window.location.href);
  var token = url?.searchParams?.get("token");
  var isIframe = url?.searchParams?.get("isIframe");
  var isSticky = url?.searchParams?.get("sticky");

  const { data: user } = useQuery("get-user-by-id", getUserByToken, { enabled: isAuthenticated(sessionStorage.getItem("token")) })
  console.log("User: ", user)
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  if (url.pathname.includes('/finance-calculator')) {
    return <FinanceCalculatorAccess />
  }
  if (token) {
    sessionStorage.setItem("token", token)
  }
  if (isIframe) {
    sessionStorage.setItem("isIframe", isIframe)
  }
  if (isSticky === "true") {
    sessionStorage.setItem("isSticky", isSticky)
  }

  // if (!isAuthenticated(sessionStorage.getItem("token")) && isSticky !== 'true') {
  //   window.location.href = process.env.REACT_APP_CLIENT + "/login";
  //   return
  // }
  else {
    // return <MobileView />
    return (
      <ThemeProviderCustom user={user}>
        <Layout user={user}>
          <MobileView />
        </Layout>
      </ThemeProviderCustom>
    )
  }
}

export default App;
