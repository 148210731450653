import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from '@mui/icons-material/Add';
import { loading_iframe } from '../assets';
import { Modal, Button, Typography } from "@mui/material";
import swal from "sweetalert";
import SkillAssignments from '../common/SkillAssignments';
import { Link, useNavigate } from "react-router-dom";
import { replace } from "formik";
import ResumeAnalyzer from "./resumeAnalyzer/resumeAnalyzer";
import InterViewAnalyst from "./interviewSimulator/interviewAnalyst/interviewAnalyst"
import "./index.css"
import LoaderComp from "../common/loaderComp";
const viewTypes = [
    {
        available: true,
        title: 'Assessments & Counseling',
        route: '/assessments-and-counseling'
    },
    {
        available: true,
        title: 'Search',
        route: '/dream-job-search'
    },
    {
        available: true,
        title: 'Resume',
        route: '/resume-analyzer'
    },
    {
        available: true,
        title: 'Interview Simulator',
        route: '/interview-simulator'
    },
    // {
    //     title: 'Personal Branding'
    //     available: false,
    // },
    // {
    //     available: false,
    //     title: 'Mentorship Program'
    // },
    // {
    //     available: false,
    //     title: 'Virtual Internship'
    // },
    {
        available: true,
        title: 'Shadowing',
        route: '/shadowing'
    },
    {
        available: false,
        title: 'Interview Consulting'
    },
    {
        available: true,
        title: 'Onboarding',
        route: '/onboarding'
    },
]

export default function CareerCompassModal(props) {
    const organizationId = props?.user?.OrganizationId
    // organizationId !== "658ae128b2a112001209ad89" ?
    const navigate = useNavigate();
    // var tabRef = "jobs"
    const [tabRef, setTabRef] = useState("jobs")

    const [compassModal, setCompassModal] = useState(false);
    const [isLoadIframe, setIframeLoaded] = useState(false);
    const [activeItem, setActiveItem] = useState("")
    const [showSkillmodal, setShowSkillModal] = useState(false)
    const [viewButtons, setViewButtons] = useState(viewTypes)

    useEffect(() => {
        handleOrgCheck()
    }, [props?.user?.OrganizationId])

    const handleOrgCheck = () => {
        const checkNorthwell = true // process.env.REACT_APP_API_LINK.includes("northwell")
        // const { OrganizationId } = props?.user
        // if(OrganizationId !== "658ae128b2a112001209ad89") return;
        if (!checkNorthwell) return;
        const removedButtons = ['Interview Consulting']
        setViewButtons(viewTypes.filter(btn => !removedButtons.includes(btn.title)))
    }
    try {
        window.document.getElementsByClassName("modal-backdrop fade show").forEach(el => el.remove())
    } catch (error) {
    }
    return (
        <>
            <SkillAssignments
                user={props?.user?.id}
                open={showSkillmodal}
                setOpen={setShowSkillModal}
                partName={'search'} />
            <div
                key="addbudget"
                className={`modal fade action-sheet`}
                id="careerCompass"
                tabIndex="-1"
                role="dialog"
                style={{ zIndex: 1060 }}
            >
                <div
                    className="modal-dialog"
                    role="document"
                    style={{ height: "100%", width: '100%' }}
                >
                    <div className="compass-modal-container" style={{ height: "100%", paddingTop: '50px' }}>
                        <div className="modal-header" style={{ border: "none" }}>
                            <div className="d-flex justify-content-between">
                                <div class="back_btn">
                                    <a class="headerBtn goBack" href="/" data-bs-dismiss="modal">
                                        <ion-icon
                                            name="chevron-back-outline"
                                            role="img"
                                            class="md hydrated"
                                            aria-label="chevron back outline"
                                        ></ion-icon>
                                        <h3> Back </h3>
                                    </a>
                                </div>
                                <Button sx={{
                                    fontWeight: 'bold',
                                    textTransform: 'capitalize',
                                    border: '1px solid #616DED',
                                    borderRadius: "10px",
                                }} variant='outlined' onClick={() => { setShowSkillModal(true) }}>Skill Assignment</Button>

                            </div>
                        </div>
                        <div className="modal-body" id="style-3" style={{ height: "100%" }}>
                            <div className="both-box career-boxes">
                                {viewButtons.map(item => {
                                    return (
                                        <div
                                            key={item.title}
                                            className="box1 career-box"
                                            data-bs-dismiss={item.available && "modal"}
                                            // data-bs-dismiss="modal"
                                            onClick={() => {
                                                if (item.available && !item.route) {
                                                    setCompassModal(true)
                                                    setIframeLoaded(true)
                                                    setActiveItem(item.title)
                                                    document.getElementsByTagName('body')[0].style = ''
                                                } else if (item.route) {
                                                    navigate(item.route, { replace: true })
                                                }
                                                else {
                                                    swal("These features will be added soon")
                                                }
                                            }}
                                        >
                                            <h1>{item.title}</h1>
                                            <div className="expenses_vector i-vec-laptop">
                                                <img src="./assets//img/vector-up.png" alt="" className="vector-up" />
                                                <img src="./assets//img/Vector-expen.png" alt="" className="vector-img" />
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={compassModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                style={{
                    // maxWidth: "100%",
                    marginTop: '5%',
                    display: 'flex',
                    justifyContent: 'center',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: 'center'
                }}
            >
                <div
                    className="modal-content career-compass-container"
                    style={{
                        // maxWidth:"none",
                        // maxWidth: "100%",
                        height: "80vh",
                        overflowY: "auto"
                    }}
                >
                    <div className="modal-header">
                        {/*<Button sx={{ fontWeight: 'bold' }} variant='text' onClick={() => { setCompassModal(true) }}>Skill Assignment</Button> */}
                        <div></div>
                        <div className="btn-inline">
                            <div className="text-primary bg-white">
                                <CloseIcon onClick={() => { setCompassModal(false); setIframeLoaded(true) }} color='red'
                                    data-bs-toggle="modal"
                                    data-bs-target="#careerCompass" />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column', alignItems: "center", width: "100%" }}>
                        {
                            activeItem === "Search" ?
                                <>
                                    <p style={{ paddingRight: '30px', paddingLeft: '30px' }}>
                                        Career Compass is a career planning and management tool that helps job seekers navigate the job market. It offers
                                        job search features, job wage information, and a college cost affordability tool. Users can also compare colleges
                                        using the college navigator feature.
                                    </p>
                                    {isLoadIframe && <img src={loading_iframe} style={{ width: "97%", position: 'absolute' }} />}
                                    <iframe
                                        onLoad={() => setIframeLoaded(false)}
                                        src="https://table.stickball.biz"
                                        allowFullScreen="true"
                                        width="100%"
                                        height="490px"
                                        style={{
                                            border: 0,
                                            zIndex: 10
                                        }}
                                        loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </>
                                : activeItem === "Resume" ?
                                    <>
                                        <ResumeAnalyzer isLoadIframe={isLoadIframe} setIframeLoaded={setIframeLoaded} />
                                    </> :
                                    activeItem === "Interview Simulator" ? <InterViewAnalyst />
                                        :
                                        activeItem === "Onboarding" ?
                                            <div
                                                className="modal-content"
                                                style={{
                                                    maxWidth: "100%",
                                                    maxHeight: "600px",
                                                }}
                                            >
                                                <div className="modal-body pb-3">
                                                    {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe}/>}
                                                    <iframe
                                                        onLoad={() => setIframeLoaded(false)}
                                                        src={"https://document-ocr.stickball.biz/"}
                                                        allowFullScreen="true"
                                                        width="100%"
                                                        height="490px"
                                                        style={{
                                                            border: 0,
                                                            zIndex: 10
                                                        }}
                                                        loading="lazy"
                                                        referrerpolicy="no-referrer-when-downgrade"
                                                    ></iframe>
                                                </div>
                                                {/* <SkillAssignments userID={props?.user?.id} open={showSkillmodal} setOpen={setShowSkillModal} /> */}
                                            </div> :
                                            activeItem === "Shadowing" ?
                                                <div
                                                    className="modal-content"
                                                    style={{
                                                        maxWidth: "100%",
                                                        maxHeight: "600px",
                                                    }}
                                                >
                                                    <div className="modal-body pb-3">
                                                        {/* <div className="extraHeader bg-primary"> */}
                                                        <ul className="nav nav-tabs lined" role="tablist">
                                                            <li
                                                                className="nav-item"
                                                                onClick={() => setTabRef('jobs')}
                                                            >
                                                                <a
                                                                    className={
                                                                        tabRef === "food" ? "nav-link1 active" : "nav-link1"
                                                                    }
                                                                    style={{ cursor: "pointer" }}
                                                                    // data-bs-toggle="tab"
                                                                    href="#jobs"
                                                                    role="tab"
                                                                >
                                                                    Jobs
                                                                </a>
                                                            </li>
                                                            <li
                                                                className="nav-item"
                                                                // onClick={() => navigate("/net-worth?tab=assets")}
                                                                onClick={() => setTabRef('food')}
                                                            >
                                                                <a
                                                                    className={
                                                                        tabRef === "jobs" ? "nav-link1 active" : "nav-link1"
                                                                    }
                                                                    // data-bs-toggle="tab"
                                                                    style={{ cursor: "pointer" }}
                                                                    href="#food"
                                                                // role="tab"
                                                                >
                                                                    Fast Food Cashier
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        {/* </div> */}

                                                        <div id="appCapsule" className="extra-header-active full-height p-0">
                                                            <div className="section tab-content p-0 m-0">
                                                                {/* summary tab */}
                                                                <div
                                                                    className={
                                                                        tabRef === "jobs"
                                                                            ? "tab-pane fade show active"
                                                                            : "tab-pane fade"
                                                                    }
                                                                    id="jobs"
                                                                    role="tabpanel"
                                                                >

                                                                    <iframe
                                                                        onLoad={() => setIframeLoaded(false)}
                                                                        src="https://unity.stickball.biz/jobs"
                                                                        allowFullScreen="true"
                                                                        width="100%"
                                                                        height="490px"
                                                                        style={{
                                                                            border: 0,
                                                                            zIndex: 10
                                                                        }}
                                                                        loading="lazy"
                                                                        referrerpolicy="no-referrer-when-downgrade"
                                                                    ></iframe>

                                                                </div>

                                                                <div
                                                                    className={
                                                                        tabRef === "food"
                                                                            ? "tab-pane fade show active"
                                                                            : "tab-pane fade"
                                                                    }
                                                                    id="food"
                                                                    role="tabpanel"
                                                                >

                                                                    <iframe
                                                                        onLoad={() => setIframeLoaded(false)}
                                                                        src="https://unity.stickball.biz/fast-food"
                                                                        allowFullScreen="true"
                                                                        width="100%"
                                                                        height="490px"
                                                                        style={{
                                                                            border: 0,
                                                                            zIndex: 10
                                                                        }}
                                                                        loading="lazy"
                                                                        referrerpolicy="no-referrer-when-downgrade"
                                                                    ></iframe>

                                                                </div>
                                                            </div>
                                                        </div>


                                                        {/* <iframe
                                                onLoad={() => setIframeLoaded(false)}
                                                src="https://unity-northwell.stickball.biz/jobs"
                                                allowFullScreen="true"
                                                width="100%"
                                                height="490px"
                                                style={{
                                                    border: 0,
                                                    zIndex: 10
                                                }}
                                                loading="lazy"
                                                referrerpolicy="no-referrer-when-downgrade"
                                            ></iframe> */}


                                                    </div>
                                                </div>
                                                :
                                                <></>
                        }

                    </div>
                </div>
            </Modal >
        </>
    );
}
