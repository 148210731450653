

const BankIcon = ({color}) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_71_1084)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8983 3.13001L2.97617 10.9369C3.00168 10.9433 3.03488 10.9481 3.07726 10.9481H27.1225C27.1648 10.9481 27.1982 10.9433 27.2236 10.9369L15.3015 3.13001C15.2396 3.09687 15.1703 3.07946 15.0999 3.07946C15.0295 3.07946 14.9602 3.09687 14.8983 3.13001ZM13.8518 1.38504C14.2301 1.16251 14.661 1.04517 15.0999 1.04517C15.5388 1.04517 15.9697 1.16251 16.348 1.38504C16.362 1.3933 16.3758 1.40189 16.3894 1.4108L28.4147 9.28515C29.2675 9.84732 29.5262 10.8178 29.1969 11.6467C28.8801 12.4437 28.0805 12.9824 27.1225 12.9824H3.07726C2.11932 12.9824 1.31967 12.4437 1.00297 11.6467C0.673622 10.8178 0.932232 9.84732 1.78517 9.28517L1.7877 9.28348L13.8103 1.4108C13.8239 1.40189 13.8378 1.3933 13.8518 1.38504Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.859863 24.171C0.859863 23.0475 1.77064 22.1367 2.89415 22.1367H27.3056C28.4291 22.1367 29.3399 23.0475 29.3399 24.171V27.2224C29.3399 28.346 28.4291 29.2567 27.3056 29.2567H2.89415C1.77064 29.2567 0.859863 28.346 0.859863 27.2224V24.171ZM27.3056 24.171H2.89415V27.2224H27.3056V24.171Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.92876 10.9485C5.49051 10.9485 5.94591 11.4039 5.94591 11.9656V23.1542C5.94591 23.7159 5.49051 24.1714 4.92876 24.1714C4.36702 24.1714 3.91162 23.7159 3.91162 23.1542V11.9656C3.91162 11.4039 4.36702 10.9485 4.92876 10.9485Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0137 10.9485C10.5755 10.9485 11.0309 11.4039 11.0309 11.9656V23.1542C11.0309 23.7159 10.5755 24.1714 10.0137 24.1714C9.45198 24.1714 8.99658 23.7159 8.99658 23.1542V11.9656C8.99658 11.4039 9.45198 10.9485 10.0137 10.9485Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0997 10.9485C15.6614 10.9485 16.1168 11.4039 16.1168 11.9656V23.1542C16.1168 23.7159 15.6614 24.1714 15.0997 24.1714C14.5379 24.1714 14.0825 23.7159 14.0825 23.1542V11.9656C14.0825 11.4039 14.5379 10.9485 15.0997 10.9485Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1856 10.9485C20.7473 10.9485 21.2027 11.4039 21.2027 11.9656V23.1542C21.2027 23.7159 20.7473 24.1714 20.1856 24.1714C19.6239 24.1714 19.1685 23.7159 19.1685 23.1542V11.9656C19.1685 11.4039 19.6239 10.9485 20.1856 10.9485Z" fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.2715 10.9485C25.8332 10.9485 26.2887 11.4039 26.2887 11.9656V23.1542C26.2887 23.7159 25.8332 24.1714 25.2715 24.1714C24.7099 24.1714 24.2544 23.7159 24.2544 23.1542V11.9656C24.2544 11.4039 24.7099 10.9485 25.2715 10.9485Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_71_1084">
    <rect width="28.48" height="28.48" fill="white" transform="translate(0.859863 0.76001)"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default BankIcon