import { Typography } from "@mui/material"

const LoaderComp = ({isLoadIframe}) => {
    return (
        <>
            {isLoadIframe && <div
                style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center" }}
            ><img src='./gif/loading.gif' style={{ width: 100 }} />
                <Typography fontFamily={'Poppins'} textAlign={'center'} variant='h4'>{"Loading..."}</Typography>
            </div>}
        </>
    )
}
export default LoaderComp