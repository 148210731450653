import { Link } from 'react-router-dom';

function LinkModal(props) {
    return (<>
        <div key={props.id} className="modal fade action-sheet" id={props.id} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div id="appCapsule" className="bg-white mx-3 mt-2 pt-0 pb-1">
                            <ul className="listview image-listview inset">
                                <li data-bs-dismiss="modal">
                                    <Link to="/select-account" className="item pb-4">
                                        <div className="icon-box text-primary">
                                            <ion-icon name="wallet-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            <span>
                                                Link Account
                                                <footer className="text-dark">Link your account with credentials and get automatic updates</footer>
                                            </span>
                                            <div className="text-dark"><b></b></div>
                                        </div>
                                    </Link>
                                </li>
                                <li data-bs-dismiss="modal">
                                    <Link to={"/add-plan/" + props.id} className="item mt-2 pb-4">
                                        <div className="icon-box text-primary">
                                            <ion-icon name="wallet-outline"></ion-icon>
                                        </div>
                                        <div className="in">
                                            <span>
                                                Add Manually
                                                <footer className="text-dark">Enter your account details manually and update your self over time</footer>
                                            </span>
                                            <div className="text-dark"><b></b></div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default LinkModal;