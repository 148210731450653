import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Paper, Typography, } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { scrapeData } from './scrapdata';
import { GetInterestBasedjobs } from '../_api/get-recommended-jobs';
const interest_level = ['Strogly Dislike', 'Dislike', `don't know`, 'Like', 'Strongly Like']
const staticData = [
    { id: 0, question: 'Assemble electronic parts', color: '#B8D9C3', data: [1, 2, 3, 4, 5] },
    { id: 1, question: 'Build kitchen cabinets', color: '#A3BFAC', data: [1, 2, 3, 4, 5] },
    { id: 2, question: 'Buy and sell stocks and bonds', color: '#E5BED3', data: [1, 2, 3, 4, 5] },
    { id: 3, question: 'Calculate the wages of employees', color: '#FCFCB3', data: [1, 2, 3, 4, 5] },
    { id: 4, question: 'Compose or arrange music', color: '#AFA3BF', data: [1, 2, 3, 4, 5] },
    { id: 5, question: 'Conduct chemical experiments', color: '#B8D9C3', data: [1, 2, 3, 4, 5] },
    { id: 6, question: 'Create special effects for movies', color: '#A3BFAC', data: [1, 2, 3, 4, 5] },
    { id: 7, question: 'Develop a new medicine', color: '#E5BED3', data: [1, 2, 3, 4, 5] },
    { id: 8, question: 'Develop a spreadsheet using computer software', color: '#FCFCB3', data: [1, 2, 3, 4, 5] },
    { id: 9, question: 'Develop a way to better predict the weather', color: '#AFA3BF', data: [1, 2, 3, 4, 5] },
    { id: 10, question: 'Do laboratory tests to identify diseases', color: '#B8D9C3', data: [1, 2, 3, 4, 5] },
    { id: 11, question: 'Do volunteer work at a non-profit organization', color: '#A3BFAC', data: [1, 2, 3, 4, 5] },
    { id: 12, question: 'Draw pictures', color: '#E5BED3', data: [1, 2, 3, 4, 5] },
    { id: 13, question: 'Drive a truck to deliver packages to offices and homes', color: '#FCFCB3', data: [1, 2, 3, 4, 5] },
    { id: 14, question: 'Edit movies', color: '#AFA3BF', data: [1, 2, 3, 4, 5] },
    { id: 15, question: 'Examine blood samples using a microscope', color: '#B8D9C3', data: [1, 2, 3, 4, 5] },
    { id: 16, question: 'Give career guidance to people', color: '#A3BFAC', data: [1, 2, 3, 4, 5] },
    { id: 17, question: 'Help conduct a group therapy session', color: '#E5BED3', data: [1, 2, 3, 4, 5] },
    { id: 18, question: 'Help people with personal or emotional problems', color: '#FCFCB3', data: [1, 2, 3, 4, 5] },
    { id: 19, question: 'Install software across computers on a large network', color: '#AFA3BF', data: [1, 2, 3, 4, 5] },
    { id: 20, question: 'Invent a replacement for sugar', color: '#B8D9C3', data: [1, 2, 3, 4, 5] },
    { id: 21, question: 'Inventory supplies using a hand-held computer', color: '#A3BFAC', data: [1, 2, 3, 4, 5] },
    { id: 22, question: 'Investigate the cause of a fire', color: '#E5BED3', data: [1, 2, 3, 4, 5] },
    { id: 23, question: 'Keep inventory records', color: '#FCFCB3', data: [1, 2, 3, 4, 5] },
    { id: 24, question: 'Keep shipping and receiving records', color: '#AFA3BF', data: [1, 2, 3, 4, 5] },
    { id: 25, question: 'Lay brick or tile', color: '#B8D9C3', data: [1, 2, 3, 4, 5] },
    { id: 26, question: 'Manage a clothing store', color: '#A3BFAC', data: [1, 2, 3, 4, 5] },
    { id: 27, question: 'Manage a department within a large company', color: '#E5BED3', data: [1, 2, 3, 4, 5] },
    { id: 28, question: 'Manage a retail store', color: '#FCFCB3', data: [1, 2, 3, 4, 5] },
    { id: 29, question: 'Market a new line of clothing', color: '#AFA3BF', data: [1, 2, 3, 4, 5] },
    { id: 30, question: 'Negotiate business contracts', color: '#B8D9C3', data: [1, 2, 3, 4, 5] },
    { id: 31, question: 'Operate a beauty salon or barber shop', color: '#A3BFAC', data: [1, 2, 3, 4, 5] },
    { id: 32, question: 'Operate a calculator', color: '#E5BED3', data: [1, 2, 3, 4, 5] },
    { id: 33, question: 'Paint sets for plays', color: '#FCFCB3', data: [1, 2, 3, 4, 5] },
    { id: 34, question: 'Perform jazz or tap dance', color: '#AFA3BF', data: [1, 2, 3, 4, 5] },
    { id: 35, question: 'Perform rehabilitation therapy', color: '#B8D9C3', data: [1, 2, 3, 4, 5] },
    { id: 36, question: 'Play a musical instrument', color: '#A3BFAC', data: [1, 2, 3, 4, 5] },
    { id: 37, question: 'Proofread records or forms', color: '#E5BED3', data: [1, 2, 3, 4, 5] },
    { id: 38, question: 'Put out forest fires', color: '#FCFCB3', data: [1, 2, 3, 4, 5] },
    { id: 39, question: 'Raise fish in a fish hatchery', color: '#AFA3BF', data: [1, 2, 3, 4, 5] },
    { id: 40, question: 'Record rent payments', color: '#B8D9C3', data: [1, 2, 3, 4, 5] },
    { id: 41, question: 'Repair and install locks', color: '#A3BFAC', data: [1, 2, 3, 4, 5] },
    { id: 42, question: 'Repair household appliances', color: '#E5BED3', data: [1, 2, 3, 4, 5] },
    { id: 43, question: 'Represent a client in a lawsuit', color: '#FCFCB3', data: [1, 2, 3, 4, 5] },
    { id: 44, question: 'Sell merchandise at a department store', color: '#AFA3BF', data: [1, 2, 3, 4, 5] },
    { id: 45, question: 'Set up and operate machines to make products', color: '#B8D9C3', data: [1, 2, 3, 4, 5] },
    { id: 46, question: 'Sing in a band', color: '#A3BFAC', data: [1, 2, 3, 4, 5] },
    { id: 47, question: 'Stamp, sort, and distribute mail for an organization', color: '#E5BED3', data: [1, 2, 3, 4, 5] },
    { id: 48, question: 'Start your own business', color: '#FCFCB3', data: [1, 2, 3, 4, 5] },
    { id: 49, question: 'Study the movement of planets', color: '#AFA3BF', data: [1, 2, 3, 4, 5] },
    { id: 50, question: 'Study ways to reduce water pollution', color: '#B8D9C3', data: [1, 2, 3, 4, 5] },
    { id: 51, question: 'Take care of children at a day-care center', color: '#A3BFAC', data: [1, 2, 3, 4, 5] },
    { id: 52, question: 'Teach a high-school class', color: '#E5BED3', data: [1, 2, 3, 4, 5] },
    { id: 53, question: 'Teach an individual an exercise routine', color: '#FCFCB3', data: [1, 2, 3, 4, 5] },
    { id: 54, question: 'Teach children how to play sports', color: '#AFA3BF', data: [1, 2, 3, 4, 5] },
    { id: 55, question: 'Teach sign language to people who are deaf or hard of hearing', color: '#B8D9C3', data: [1, 2, 3, 4, 5] },
    { id: 56, question: 'Test the quality of parts before shipment', color: '#A3BFAC', data: [1, 2, 3, 4, 5] },
    { id: 57, question: 'Work in a biology lab', color: '#E5BED3', data: [1, 2, 3, 4, 5] },
    { id: 58, question: 'Write books or plays', color: '#FCFCB3', data: [1, 2, 3, 4, 5] },
    { id: 59, question: 'Write scripts for movies or television shows', color: '#AFA3BF', data: [1, 2, 3, 4, 5] },
];
// const staticData = [
//     { "id": 0, "question": "Assemble electronic parts", "color": "#B8D9C3", "data": [1, 2, true, 4, 5], "Interest": "don't know" },
//     { "id": 1, "question": "Build kitchen cabinets", "color": "#A3BFAC", "data": [1, 2, 3, true, 5], "Interest": "Like" },
//     { "id": 2, "question": "Buy and sell stocks and bonds", "color": "#E5BED3", "data": [1, 2, 3, 4, true], "Interest": "Strongly Like" },
//     { "id": 3, "question": "Calculate the wages of employees", "color": "#FCFCB3", "data": [1, 2, 3, 4, true], "Interest": "Strongly Like" },
//     { "id": 4, "question": "Compose or arrange music", "color": "#AFA3BF", "data": [1, 2, 3, 4, true], "Interest": "Strongly Like" },
//     { "id": 5, " question": "Conduct chemical experiments", "color": "#B8D9C3", "data": [1, 2, 3, true, 5] },
//     { "id": 6, "question": "Create special effects for movies", "color": "#A3BFAC", "data": [1, 2, true, 4, 5] },
//     { "id": 7, "question": "Develop a new medicine", "color": "#E5BED3", "data": [1, 2, 3, true, 5] },
//     { "id": 8, "question": "Develop a spreadsheet using computer software", "color": "#FCFCB3", "data": [1, 2, 3, true, 5] },
//     { "id": 9, "question": "Develop a way to better predict the weather", "color": "#AFA3BF", "data": [1, 2, 3, true, 5] },
//     { "id": 10, "question": "Do laboratory tests to identify diseases", "color": "#B8D9C3", "data": [1, 2, 3, true, 5] },
//     { "id": 11, "question": "Do volunteer work at a non-profit organization", "color": "#A3BFAC", "data": [1, 2, 3, true, 5] },
//     { "id": 12, "question": "Draw pictures", "color": "#E5BED3", "data": [1, 2, true, 4, 5] },
//     { "id": 13, "question": "Drive a truck to deliver packages to offices and homes", "color": "#FCFCB3", "data": [1, true, 3, 4, 5] },
//     { "id": 14, "question": "Edit movies", "color": "#AFA3BF", "data": [1, 2, 3, 4, true] },
//     { "id": 15, "question": "Examine blood samples using a microscope", "color": "#B8D9C3", "data": [1, 2, 3, true, 5] },
//     { "id": 16, "question": "Give career guidance to people", "color": "#A3BFAC", "data": [1, true, 3, 4, 5] },
//     { "id": 17, "question": "Help conduct a group therapy session", "color": "#E5BED3", "data": [1, 2, 3, 4, true] },
//     { "id": 18, "question": "Help people with personal or emotional problems", "color": "#FCFCB3", "data": [1, 2, true, 4, 5] },
//     { "id": 19, "question": "Install software across computers on a large network", "color": "#AFA3BF", "data": [1, 2, 3, 4, true] },
//     { "id": 20, "question": "Invent a replacement for sugar", "color": "#B8D9C3", "data": [1, 2, 3, 4, true] },
//     { "id": 21, "question": "Inventory supplies using a hand-held computer", "color": "#A3BFAC", "data": [true, 2, 3, 4, 5] },
//     { "id": 22, "question": "Investigate the cause of a fire", "color": "#E5BED3", "data": [1, true, 3, 4, 5] },
//     { "id": 23, "question": "Keep inventory records", "color": "#FCFCB3", "data": [1, 2, 3, 4, true] },
//     { "id": 24, "question": "Keep shipping and receiving records", "color": "#AFA3BF", "data": [1, 2, true, 4, 5] },
//     { "id": 25, "question": "Lay brick or tile", "color": "#B8D9C3", "data": [1, 2, true, 4, 5] },
//     { "id": 26, "question": "Manage a clothing store", "color": "#A3BFAC", "data": [true, 2, 3, 4, 5] },
//     { "id": 27, "question": "Manage a department within a large company", "color": "#E5BED3", "data": [1, 2, 3, 4, true] },
//     { "id": 28, "question": "Manage a retail store", "color": "#FCFCB3", "data": [1, true, 3, 4, 5] },
//     { "id": 29, "question": "Market a new line of clothing", "color": "#AFA3BF", "data": [1, 2, 3, 4, true] },
//     { "id": 30, "question": "Negotiate business contracts", "color": "#B8D9C3", "data": [1, 2, 3, true, 5] },
//     { "id": 31, "question": "Operate a beauty salon or barber shop", "color": "#A3BFAC", "data": [1, true, 3, 4, 5] },
//     { "id": 32, "question": "Operate a calculator", "color": "#E5BED3", "data": [1, 2, true, 4, 5] },
//     { "id": 33, "question": "Paint sets for plays", "color": "#FCFCB3", "data": [1, 2, 3, 4, true] },
//     { "id": 34, "question": "Perform jazz or tap dance", "color": "#AFA3BF", "data": [true, 2, 3, 4, 5] },
//     { "id": 35, "question": "Perform rehabilitation therapy", "color": "#B8D9C3", "data": [1, 2, 3, true, 5] },
//     { "id": 36, "question": "Play a musical instrument", "color": "#A3BFAC", "data": [1, true, 3, 4, 5] },
//     { "id": 37, "question": "Proofread records or forms", "color": "#E5BED3", "data": [1, 2, 3, 4, true] },
//     { "id": 38, "question": "Put out forest fires", "color": "#FCFCB3", "data": [1, 2, true, 4, 5] },
//     { "id": 39, "question": "Raise fish in a fish hatchery", "color": "#AFA3BF", "data": [1, 2, 3, 4, true] },
//     { "id": 40, "question": "Record rent payments", "color": "#B8D9C3", "data": [1, 2, 3, 4, true] },
//     { "id": 41, "question": "Repair and install locks", "color": "#A3BFAC", "data": [1, 2, true, 4, 5] },
//     { "id": 42, "question": "Repair household appliances", "color": "#E5BED3", "data": [1, 2, 3, 4, true] },
//     { "id": 43, "question": "Represent a client in a lawsuit", "color": "#FCFCB3", "data": [1, 2, true, 4, 5] },
//     { "id": 44, "question": "Sell merchandise at a department store", "color": "#AFA3BF", "data": [1, 2, 3, 4, true] },
//     { "id": 45, "question": "Set up and operate machines to make products", "color": "#B8D9C3", "data": [1, 2, 3, true, 5] },
//     { "id": 46, "question": "Sing in a band", "color": "#A3BFAC", "data": [1, 2, true, 4, 5] },
//     { "id": 47, "question": "Stamp, sort, and distribute mail for an organization", "color": "#E5BED3", "data": [1, 2, 3, true, 5] },
//     { "id": 48, "question": "Start your own business", "color": "#FCFCB3", "data": [1, 2, true, 4, 5] },
//     { "id": 49, "question": "Study the movement of planets", "color": "#AFA3BF", "data": [1, 2, 3, 4, true] },
//     { "id": 50, "question": "Study ways to reduce water pollution", "color": "#B8D9C3", "data": [1, 2, 3, 4, true] },
//     { "id": 51, "question": "Take care of children at a day-care center", "color": "#A3BFAC", "data": [1, 2, true, 4, 5] },
//     { "id": 52, "question": "Teach a high-school class", "color": "#E5BED3", "data": [1, 2, 3, 4, true] },
//     { "id": 53, "question": "Teach an individual an exercise routine", "color": "#FCFCB3", "data": [1, 2, true, 4, 5] },
//     { "id": 54, "question": "Teach children how to play sports", "color": "#AFA3BF", "data": [1, 2, 3, true, 5] },
//     { "id": 55, "question": "Teach sign language to people who are deaf or hard of hearing", "color": "#B8D9C3", "data": [1, 2, 3, true, 5] },
//     { "id": 56, "question": "Test the quality of parts before shipment", "color": "#A3BFAC", "data": [1, 2, true, 4, 5] },
//     { "id": 57, "question": "Work in a biology lab", "color": "#E5BED3", "data": [1, 2, 3, true, 5] },
//     { "id": 58, "question": "Write books or plays", "color": "#FCFCB3", "data": [1, 2, true, 4, 5] },
//     { "id": 59, "question": "Write scripts for movies or television shows", "color": "#AFA3BF", "data": [1, 2, true, 4, 5] }
// ];



const emojiData = [
    "☹️",
    "😟",
    "😕",
    "😊",
    "😄",
]
const Interests = ({ setRanderState,setRecomendedJobs }) => {

    const getInterestBasedjobs = async (filteredData) => {
        setisloading(true);
        try {
            const response = await GetInterestBasedjobs(filteredData)
            console.log("🚀 ~ getInterestBasedjobs ~ response:", response)
            setRecomendedJobs(response)
            setisloading(false);
            setRanderState(2);
        } catch (error) {
            setisloading(false);
            console.error('Error sending message to ChatGPT:', error);
        }
    };

    const length_of_questions = staticData.length

    const [data, setData] = useState(staticData);

    const [pagination, setPagination] = useState({
        start: 0,
        end: 5,
        pageNum: 1
    });
    const [percentage, setPercentage] = useState(0)
    const [doneQuestions, setDoneQuestions] = useState(0)
    const [isloading, setisloading] = useState(false)

    // Ensuring every question has answered
    const onePageData = data.slice(pagination?.start, pagination.end).every((val) => (val.data.includes(true)))
    const nextPagedata = data.slice(pagination?.start + 5, pagination.end + 5).every((val) => (val.data.includes(true)))


    function totalPages(totalItems, itemsPerPage) {
        return Math.ceil(totalItems / itemsPerPage);
    }

    useEffect(() => {
        const filterTickedquestion = data.filter((val) => (
            val.data.includes(true)
        ))
        console.log("🚀 ~ useEffect ~ filterTickedquestion:", filterTickedquestion)
        setDoneQuestions(filterTickedquestion.length)
        setPercentage(filterTickedquestion.length / staticData.length * 100)
    }, [data])

    const updateElementByIndex = (index, index2, newValue, isLastIndex) => {

        setData((prevArray) => {
            const newArray = [...prevArray];
            newArray[index].data = [1, 2, 3, 4, 5];
            newArray[index].data[index2] = newValue;
            return newArray;
        });
    };

    const handleNextPage = () => {
        const sortScrapedData = scrapeData.slice(0, (scrapeData.length / 8))
        let condition = (element) => element === true;
        let filteredData = data.map((val) => {
            let index = val.data.findIndex(condition);
            return { ...val, Interest: interest_level[index] };
        });
        console.log("filteredData", filteredData);
        if (length_of_questions / pagination?.pageNum == 5 || length_of_questions / pagination?.pageNum < 5 || !onePageData) {
            console.log(filteredData)
            getInterestBasedjobs(filteredData)
        }
        else {
            setPagination({
                start: pagination.start + 5,
                end: pagination.end + 5,
                pageNum: pagination.pageNum + 1
            })
            if (!nextPagedata) {
                setTimeout(() => {
                    setisloading(false)
                }, 1400)
            }
        }

    }

    const handlePrevPage = () => {
        const pageNum = pagination.pageNum
        if (pageNum == 1) {
            setRanderState(0)
        } else {
            setPagination({
                start: pagination.start - 5,
                end: pagination.end - 5,
                pageNum: pagination.pageNum - 1
            })
        }
    }

    return (
        <>
        {!isloading &&
            <Box width={"90%"} flexDirection={'row-reverse'} display={"flex"} alignItems={'center'}>
                <Box
                    display="flex"
                    alignItems="center"
                    padding={0}
                    gap={1}
                >
                    {emojiData.map((item, idx) => (
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Typography fontFamily={'Poppins'} fontSize={29} margin={0} padding={0}>
                                {item}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box display={"flex"} justifyContent={'space-around'} alignItems={'center'} width={'100%'}>
                    <Typography fontFamily={'Poppins'} color={"white"}>
                        Progress <b style={{ fontWeight: "bold" }}>{percentage.toFixed()}%</b>
                    </Typography>
                    <Box>
                        <Typography fontFamily={'Poppins'} color={"white"}>
                            Page <b style={{ fontWeight: "bold", }}>
                                <b style={{ fontWeight: "normal" }}> {pagination.pageNum} of</b> {totalPages(staticData.length, 5)}</b>
                        </Typography>
                        <Typography fontFamily={'Poppins'} color={"white"}>
                            <b style={{ fontWeight: "bold", }}>{doneQuestions}<b style={{ fontWeight: "normal" }}> of </b>
                                {data.length} Questions</b>
                        </Typography>
                    </Box>
                </Box>
            </Box>}
            {/* Contents */}
            {isloading ?
                <Box display={"flex"} flexDirection={'column'} gap={3} alignItems={'center'} marginTop={5}>
                    <img src='./gif/loading.gif' style={{width: 80 }} />
                    <Typography fontFamily={'Poppins'} color={"white"} variant='h6'>Generating Interests...</Typography>
                </Box> :
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    width={"90%"}
                >
                    {data.slice(pagination.start, pagination.end).map((val, index, array) => {
                        const isPrev = val.id == 0 ? true : val.id > 0 && data[val.id - 1].data?.includes(true);
                        const isLastIndex = index == array.length - 1;
                        return (
                            <Box
                                key={val.id}
                                display="flex"
                                alignItems="center"
                                gap={2}
                                width={"100%"}
                                // backgroundColor={val.color}
                                padding={0.8}
                                flexDirection={'row-reverse'}
                            >
                                <Box display={"flex"} gap={1} alignItems={'center'}>
                                    {val.data.map((item, idx) => {
                                        return (
                                            <>
                                                {/* <Box style={{ width: "50%" }}>
                                                      {isPrev?<Typography color={'white'} fontFamily={'poppins'}>
                                                       {interest_level[val.data.findIndex(val2 => val2 === true)]}
                                                       </Typography>:null}
                                                       <input 
                                                            type='range' 
                                                            disabled={!isPrev} 
                                                            value={val.data.findIndex(val2 => val2 === true) || 0} 
                                                            onChange={(e) => updateElementByIndex(val.id, e.target.value, true)} 
                                                            max={4} min={0} 
                                                            style={{ width: "100%" }} />
                                                        </Box> */}
                                                <Box
                                                    display={'flex'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    padding={1}
                                                    border={"1px solid #fff3"}
                                                    onClick={
                                                        isPrev || val.id == 0 ?
                                                            () => {
                                                                updateElementByIndex(val.id, idx, true, isLastIndex)
                                                            } : null}
                                                    sx={{ cursor: isPrev ? "pointer" : "not-allowed" }}
                                                >
                                                    <Box
        
                                                        key={idx}
                                                        height={19}
                                                        width={19}
                                                        borderRadius={'50%'}

                                                        backgroundColor={!isPrev ? "#DDDDDD" : item !== true ? '#fff' : "#0176FF"}
                                                        border={`0.3px solid black`}
                                                    ></Box>
                                                </Box>
                                            </>
                                        )
                                    })}
                                </Box>
                                <Box display={'flex'} gap={1} flexDirection={'row'} width={'100%'} alignItems={'center'}>
                                    <Box
                                        display={'flex'}
                                        height={'30px'}
                                        width={'30px'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        backgroundColor={"white"}
                                        borderRadius={'50%'}
                                    >
                                        <Typography fontFamily={'Poppins'} color="black" fontSize={16}>
                                            {val.id + 1}
                                        </Typography>
                                    </Box>
                                    <Typography maxWidth={'85%'} fontFamily={'Poppins'} color="white" textAlign={'start'} fontSize={16}>
                                        {val.question}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            }
            <Box width={'90%'} padding={2} position={'relative'} display={'flex'} justifyContent={"space-between"} >
                <Button
                    onClick={handlePrevPage}
                    startIcon={
                        <img
                          width="150%"
                          height="150%"
                          src="assets/img/icon/arrow.svg"
                          className="logo"
                          alt=""
                          style={{filter: 'brightness(2000%)'}}
                        />
                      }
                    variant="contained"
                    disabled={isloading}
                    color="primary" >
                    <Typography variant="button">Back</Typography>
                </Button>
                <Button
                    onClick={handleNextPage}
                    disabled={!onePageData || isloading}
                    endIcon={ 
                    <img
                        width="150%"
                        height="150%"
                        src="assets/img/icon/arrow.svg"
                        className="logo"
                        alt=""
                        style={{filter:onePageData&&'brightness(2000%)',transform:'rotate(180deg)'}}
                      />} variant="contained" color="primary">
                    <Typography variant="button">Next</Typography>
                </Button>
            </Box>
        </>
    )
}

export default Interests