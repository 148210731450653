import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { QuillEditor } from './quil-editor'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function Row(props) {
    const { row, index } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <TableRow className="dropdown-row" sx={{ '& > *': { borderBottom: 'unset' } }} fullWidth hover onClick={() => setOpen(!open)}>
                <TableCell component="th" fullWidth style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography style={{ fontWeight: 'bold' }}>
                        {index + 1 + ". "} {row.title}
                    </Typography>
                    <IconButton
                        className="dropdown-button"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} fullWidth>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ m: '10px 10px 10px 0px' }}>
                            <QuillEditor
                                readOnly={true}
                                value={row.detail}
                                sx={{ height: 'fit-content' }}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}


export default function CollapsibleTable(props) {
    const { data } = props;
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableBody>
                    {data?.dropdowns?.map((row, index) => (
                        <Row
                            key={row.name}
                            row={row}
                            index={index}
                        />
                    ))}
                    {!data?.dropdowns?.length && "No Dropdowns to show"}
                </TableBody>
            </Table>
        </TableContainer>
    );
}