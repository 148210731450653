import { forwardRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Button } from '@mui/material';
import SkillAssignments from '../common/SkillAssignments';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function TurboTaxes(props) {
    const { setOpenTurboTaxes, openTurboTaxes, } = props;
    const [showSkillmodal, setShowSkillModal] = useState(false)
    return (
        <div>
            <Dialog
                open={openTurboTaxes}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenTurboTaxes(false)}
                sx={{ mt: 2 }}
                maxWidth={'xl'}
                fullWidth
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                    <Button sx={{
                        fontWeight: 'bold',
                        textTransform: 'capitalize',
                        border: '1px solid #616DED',
                        borderRadius: "10px",
                    }} variant='outlined' onClick={() => { setShowSkillModal(true) }}>Skill Assignment</Button>
                    <span style={{ fontWeight: 700 }}>Taxes</span>
                    <IconButton onClick={() => setOpenTurboTaxes(false)} ><CloseIcon /></IconButton>
                </DialogTitle>
                <DialogContent sx={{ overflow: 'auto' }} >
                    <iframe style={{ width: '115%', height: '78vh', overflow: 'auto' }} scrolling="yes" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FVZjXQUqymxyNTU1xP6I91M%2FTurbo-Tax%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-2%26viewport%3D-11116%252C375%252C0.1%26scaling%3Dmin-zoom%26starting-point-node-id%3D1%253A2" allowfullscreen></iframe>

                    <SkillAssignments user={props?.user?.id} open={showSkillmodal} setOpen={setShowSkillModal} partName={'taxes'} />
                </DialogContent>
            </Dialog>
        </div >
    );
}