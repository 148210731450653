import { currencyFormat } from "../_budget-functionality";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";

const SingleAsset = ({ key, keyId, data,  onViewDetails }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const handleViewDetailsClick = () => {
    onViewDetails(data);
  };
  return (
    <Box
      key={keyId}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={theme.palette.common.background}
      padding="11px"
      borderRadius="8px"
    >
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography fontSize={{ xs: ".7rem", md: ".9rem" }} fontWeight="600">
          {data?.name}
        </Typography>
        <Typography fontSize={{ xs: ".7rem", md: ".9rem" }} fontWeight="600">
          {moment(data?.createdAt).format("ll")}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap={{ xs: 1, md: 3 }}>
        <Box display="flex" alignItems="center">
          <Typography variant="body1" display={{ xs: "none", md: "block" }}>
            Amount :{" "}
          </Typography>
          <Typography fontSize={{ xs: "1rem", md: "1.3rem" }} fontWeight="700">
            {" "}
            {currencyFormat(parseInt(data?.amount))}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            startIcon={<VisibilityIcon />}
            onClick={handleViewDetailsClick}
            sx={{
              bgcolor: theme.palette.common.text,
              color: theme.palette.common.white,
              fontSize: { xs: "10px", md: "20px" },
              "&:hover": {
                bgcolor: theme.palette.common.text,
                opacity: 0.9,
              },
            }}
          >
            {isSmallScreen ? "View" : "View Detail"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleAsset;
