
import React, { useEffect, useState } from 'react'
import { useQuery } from "react-query";
import { getdictionaries } from "../_api/get-dictionaries";
import toast from 'react-hot-toast'
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import { Container, Typography, Card, CardContent, CardActions, Stack, Pagination } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.common.white,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  border: `1px solid ${theme.palette.common.disabled}`,
  borderRadius: '10px',
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(3),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  }
}));

const applyFilters = (dictionaries, queryFilter) => {
  return (
    dictionaries?.filter((value) => {
      if (queryFilter) {
        let queryMatched = false;
        if (
          String(value.name)
            .toLowerCase()
            .includes(queryFilter.toLowerCase())
        ) {
          queryMatched = true;
        }
        if (!queryMatched) {
          return false;
        }
      }
      return true;
    })
  )
}

const Dictionary = () => {
  const theme = useTheme()
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(0)



  const { isLoading, data: allDictionarys } = useQuery("get-dictionaries", getdictionaries);
  useEffect(() => {
    if (!isLoading) {
      setData(allDictionarys)
      toast.dismiss()
    } else {
      toast.loading("Please wait...")
    }
  }, [allDictionarys])

  const handleQueryChange = (event) => {
    setIndex(0)
    setData(applyFilters(allDictionarys, event.target.value))
  };

  const handleChange = (event, value) => {
    setIndex(value - 1);
  };

  return (
    <Container maxWidth="md">
      <Card>
        <Card sx={{
          bgcolor: theme.palette.primary.light,
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: "22px",
          display: "flex",
          flexDirection: { md: "row", xs: "column" }
        }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: { md: "50%", xs: "100%" } }}>
            <Typography variant="h2" color="primary">
              Hi there!
            </Typography>
            <Typography variant="body1" color="initial">
              Start typing to search the dictionary
            </Typography>
          </Box>
          <Box sx={{ width: { md: "50%", xs: "100%" } }}>
            <Search className='w-100'>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                className='input-search-bar'
                placeholder="Type any word or term here"
                // inputProps={{ ref: queryRef }}
                onChange={handleQueryChange}
              />
            </Search>
          </Box>
        </Card>

        <Card sx={{ mt: "20px" }}>
          {
            data?.length > 0 ?
              <CardContent sx={{ height: "50vh", overflowY: "auto" }}>
                <Stack spacing={2}>
                  <Typography variant="h6" color="initial">{data?.[index]?.name}</Typography>
                  <Typography variant="body1" sx={{ color: theme.palette.warning.main }}><i>Description:</i></Typography>
                  <Box>
                    <Typography variant="body1" sx={{ color: theme.palette.common.text, ml: 2 }}>
                      {data?.[index]?.description}
                    </Typography>
                  </Box>
                  <Typography variant="body1" color="success" sx={{ color: theme.palette.success.main }}><i>Examples:</i></Typography>

                  <Box>
                    {JSON.parse(data?.[index]?.example || "[]")?.map(value => {
                      return <Typography variant="body1" sx={{ color: theme.palette.common.text, ml: 2 }}>{value}</Typography>
                    })}
                  </Box>
                  {/* <Typography variant="body1" sx={{ color: theme.palette.common.text, ml: 2 }}>{data?.[index]?.example}</Typography> */}
                </Stack>
              </CardContent>
              :
              <Typography variant='body1' color="text">No results found to show!</Typography>
          }

          {
            data?.length > 1 &&
            <CardActions>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Pagination
                  color='primary'
                  count={data?.length - 1}
                  variant="outlined"
                  shape="rounded"
                  page={index + 1} onChange={handleChange}
                />
              </Box>
            </CardActions>
          }
        </Card>

      </Card>
    </Container>
  )
}

export default Dictionary
