import * as Axios from 'axios';

const API_URL = process.env.REACT_APP_API_LINK;
const axios = Axios.create({
    'headers': { 'x-api-key': process.env.REACT_APP_X_API_KEY }
})

// getting skills of money
export const getSkills = async () => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.get(`${API_URL}/money-skill`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        return res.data;
    }

}






// getting sub skills of money
export const getSubSkills = async () => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.get(`${API_URL}/money-subskill`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        return res.data;
    }

}


//getting skill section of money
export const getSkillSectionsBySubSkillID = async (id) => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.get(`${API_URL}/money-skill-section/get-by-money-subskill?moneySubSkillId=${id}`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        return res.data;
    }
}

export const getSkillSection = async (id) => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.get(`${API_URL}/money-skill-section/${id}`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        return res.data;
    }
}