
const CashIcon = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
<g clip-path="url(#clip0_71_1045)">
<path d="M23.11 25.4568H3.53002C2.18025 25.4568 1.08252 24.3591 1.08252 23.0093V10.5493C1.08252 9.19953 2.18025 8.10181 3.53002 8.10181H23.11C24.4598 8.10181 25.5575 9.19953 25.5575 10.5493V23.0093C25.5575 24.3591 24.4598 25.4568 23.11 25.4568ZM3.53002 9.43681C2.91636 9.43681 2.41752 9.93565 2.41752 10.5493V23.0093C2.41752 23.623 2.91636 24.1218 3.53002 24.1218H23.11C23.7237 24.1218 24.2225 23.623 24.2225 23.0093V10.5493C24.2225 9.93565 23.7237 9.43681 23.11 9.43681H3.53002Z" fill={color}/>
<path d="M20.4399 22.7877H6.19994C5.83139 22.7877 5.53244 22.4888 5.53244 22.1202C5.53244 21.5065 5.0336 21.0077 4.41994 21.0077C4.05139 21.0077 3.75244 20.7088 3.75244 20.3402V13.2202C3.75244 12.8517 4.05139 12.5527 4.41994 12.5527C5.0336 12.5527 5.53244 12.0539 5.53244 11.4402C5.53244 11.0717 5.83139 10.7727 6.19994 10.7727H20.4399C20.8085 10.7727 21.1074 11.0717 21.1074 11.4402C21.1074 12.0539 21.6063 12.5527 22.2199 12.5527C22.5885 12.5527 22.8874 12.8517 22.8874 13.2202V20.3402C22.8874 20.7088 22.5885 21.0077 22.2199 21.0077C21.6063 21.0077 21.1074 21.5065 21.1074 22.1202C21.1074 22.4888 20.8085 22.7877 20.4399 22.7877ZM6.77533 21.4527H19.8646C20.0958 20.6387 20.7389 19.996 21.5524 19.7653V13.7951C20.7389 13.5644 20.0958 12.9217 19.8646 12.1077H6.77533C6.5441 12.9217 5.90099 13.5644 5.08744 13.7951V19.7653C5.90099 19.996 6.5441 20.6387 6.77533 21.4527Z" fill={color}/>
<path d="M27.5609 21.657C27.3315 21.657 27.1081 21.5388 26.9838 21.3259C26.7978 21.0078 26.9047 20.5988 27.2237 20.4132C27.574 20.2085 27.7825 19.8487 27.7825 19.4498V6.98983C27.7825 6.37617 27.2837 5.87733 26.67 5.87733H7.09003C6.69113 5.87733 6.33041 6.08639 6.12616 6.4366C5.93926 6.75424 5.53083 6.86247 5.21355 6.67601C4.89457 6.49 4.78768 6.08149 4.97369 5.76296C5.42047 4.99854 6.21133 4.54224 7.09003 4.54224H26.67C28.0198 4.54224 29.1175 5.64041 29.1175 6.98974V19.4497C29.1175 20.3284 28.6612 21.1194 27.8964 21.5656C27.7904 21.6277 27.6748 21.657 27.5609 21.657Z" fill={color}/>
<path d="M13.3201 19.2273C11.9703 19.2273 10.8726 18.1295 10.8726 16.7798C10.8726 15.43 11.9703 14.3323 13.3201 14.3323C14.6698 14.3323 15.7676 15.43 15.7676 16.7798C15.7676 18.1295 14.6698 19.2273 13.3201 19.2273ZM13.3201 15.6673C12.7064 15.6673 12.2076 16.1661 12.2076 16.7798C12.2076 17.3934 12.7064 17.8923 13.3201 17.8923C13.9337 17.8923 14.4326 17.3934 14.4326 16.7798C14.4326 16.1661 13.9337 15.6673 13.3201 15.6673Z" fill={color}/>
<path d="M7.98033 17.6699C8.47187 17.6699 8.87033 17.2714 8.87033 16.7799C8.87033 16.2884 8.47187 15.8899 7.98033 15.8899C7.4888 15.8899 7.09033 16.2884 7.09033 16.7799C7.09033 17.2714 7.4888 17.6699 7.98033 17.6699Z" fill={color}/>
<path d="M18.66 17.6699C19.1516 17.6699 19.55 17.2714 19.55 16.7799C19.55 16.2884 19.1516 15.8899 18.66 15.8899C18.1685 15.8899 17.77 16.2884 17.77 16.7799C17.77 17.2714 18.1685 17.6699 18.66 17.6699Z" fill={color}/>
</g>
<defs>
<clipPath id="clip0_71_1045">
<rect width="28.48" height="28.48" fill="white" transform="translate(0.859863 0.76001)"/>
</clipPath>
</defs>
</svg>
  )
}

export default CashIcon