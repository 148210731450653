import {
  Box,
  Card,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useEffect, useRef, useState } from "react";
import TaxesIcon from "../svgIcons/TaxesIcon";
import { sendMessageToGpt } from "../_api/chatbot";

const Taxes = () => {
  const theme = useTheme();
  const taxesData = [
    {
      id: 1,
      heading: "General Tax Questions:",
      question1: "What is income tax?",
      question2: "What are tax brackets?",
    },
    {
      id: 2,
      heading: "Filing Taxes:",
      question1: "Which filing status should I choose?",
      question2: "How do I claim deductions?",
    },
    {
      id: 3,
      heading: "Tax Credits & Deductions:",
      question1: "What is the Child Tax Credit?",
      question2: "How do I claim mortgage interest?",
    },
    {
      id: 4,
      heading: "Specific Situations:",
      question1: "How do I file taxes?",
      question2: "What should I do if I receive tax refund?",
    },
  ];

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  //   const { t } = useTranslation()
  const messagesEndRef = useRef();
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [gradeNumber, setGradeNumber] = useState(12);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(true);

  const open = Boolean(anchorEl);

  useEffect(() => {
    setGradeNumber(localStorage.getItem("gradeNumber") || 12);
  }, []);

  // Old Implementation

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handlesendMessage = async (inputMessage) => {
    if (!inputMessage.trim()) return;

    const newMessages = [
      ...messages,
      { sender: "user", message: inputMessage },
    ];
    setMessages(newMessages);
    setInputText("");

    try {
      setIsLoading(true);
      let botReply;
      botReply = await sendMessageToGpt(newMessages, "taxes", gradeNumber);

      setIsLoading(false);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "assistant", message: botReply },
      ]);
      scrollToBottom();
    } catch (error) {
      setIsLoading(false);
      console.error("Error sending message:", error);
      // setChatBotModal(false)
      // Swal.fire({
      //     icon: "error",
      //     title: "Oops...",
      //     text: "Something went wrong!",
      // });
    }
  };

  const handleQuestionClick = (question) => {
    setInputText(question);
    handlesendMessage(question);
  };

  const handleSendClick = () => {
    handlesendMessage(inputText);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Container maxWidth="md">
      <Card sx={{ height: {xs:"70vh",md:"75vh"}, position: "relative" }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={{ xs: 0.5, md: 2 }}
          width="100%"
          height="100%"
        >
          {!messages.length && (
            <>
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.extraLight,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 1,
                }}
              >
                <TaxesIcon color={theme.palette.primary.main} />
              </Box>
              <Typography variant="h1" fontWeight="700" textAlign="center">
                Welcome to the Tax Chatbot!
              </Typography>
              <Typography variant="body1" textAlign='center'>
                Here are some example questions you can ask
              </Typography>
              <Grid
                container
                spacing={{ xs: 1, md: 2 }}
                justifyContent="center"
                sx={{ px: { xs: 0, md: 15 } }}
              >
                {taxesData?.map((data) => {
                  return (
                    <Grid item xs={12} md={6} key={data.id}>
                      <Box
                        sx={{
                          backgroundColor: theme.palette.primary.extraLight,
                          border: `1px solid ${theme.palette.primary.main}`,
                          borderRadius: "1rem",
                          padding: "12px",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            padding: "2px 10px",
                            borderRadius: "22px",
                            width: "fit-content",
                            mb: {xs:"1rem",md:"2rem"},
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "8px",
                              color: theme.palette.common.white,
                            }}
                          >
                            {data.heading}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "8px",
                              color: theme.palette.primary.main,
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => handleQuestionClick(data.question1)}
                          >
                            {data.question1}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "8px",
                              color: theme.palette.primary.main,
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => handleQuestionClick(data.question2)}
                          >
                            {data.question2}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}

          <Box sx={{ width: "100%", height: "100%", overflowY: "auto" }}>
            {messages?.map((val) => (
              <>
                <EachMessage isLoading={isLoading} val={val} />
              </>
            ))}
            {isLoading && (
              <EachMessage
                val={{ sender: "assistant", message: "typing..." + "..." }}
              />
            )}
            <div ref={messagesEndRef} />
          </Box>

          <TextField
            placeholder="Ask Anything"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handlesendMessage(inputText);
              }
            }}
            sx={{
              width: "90%",
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              position: "absolute",
              bottom: "1rem",
            }}
            variant="outlined"
            InputProps={{
              sx: { borderRadius: 50 },
              endAdornment: (
                <InputAdornment position="end">
                  <Box
                    sx={{
                      backgroundColor: theme.palette.common.text,
                      borderRadius: "50%",
                    }}
                  >
                    <ArrowUpwardIcon
                      sx={{
                        cursor: "pointer",
                        color: theme.palette.common.white,
                        fontSize: "2rem",
                      }}
                      onClick={handleSendClick}
                    />
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Card>
    </Container>
  );
};

export default Taxes;

const EachMessage = ({ val }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const botBorderRadius = "0px 14px 14px 14px";
  const userBorderRadius = "14px 0px 14px 14px";
  const isBot = val.sender == "assistant";
  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: isBot ? "flex-start" : "flex-end",
          mt: 2,
        }}
      >
        <div
          className="chat-bot-profile-pic"
          style={{ padding: 0, background: !isBot && "white" }}
        >
          {isBot && (
            <img
              width={isMobile ? "30px" : "50px"}
              src={"../../assets/img/bot/bot-icon.svg"}
              alt=""
            />
          )}
          {!isBot && (
            <img
              width={isMobile ? "30px" : "50px"}
              src={"../../assets/img/bot/chatbot-user.png"}
              alt=""
            />
          )}
        </div>
        <Box
          sx={{
            display: "flex",
            padding: { sm: "10px 25px", xs: "5px 10px" },
            borderRadius: isBot ? botBorderRadius : userBorderRadius,
            backgroundColor: isBot ? "#106EAC" : "lightgray",
            maxWidth: { sm: "50%", xs: "70%" },
            width: "fit-content",
            position: "relative",
            top: { sm: "28px", xs: "16px" },
            color: isBot ? "white" : "black",
            alignItems: "center",
          }}
        >
          <Typography variant="body1">{val?.message}</Typography>
        </Box>
      </Box>
    </>
  );
};
