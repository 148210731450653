import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
export default function Header() {
    const navigate = useNavigate()
    return (<>
        <div className="appHeader">
            <div className="left">
                <Link
                    style={{ width: '150px', height: 'fit-content', border: 'none' }}
                    to="/net-worth"
                    onClick={(e) => {
                        e.preventDefault()
                        navigate(-1)
                    }}
                    className="appHeader customHeader mb-5 goBack">
                    <div className="customLeft left">
                        <a href="#" className="headerButton" >
                            <ion-icon name="chevron-back-outline"></ion-icon>
                            <h4>Go Back</h4>
                        </a>
                    </div>
                </Link>
            </div>
        </div>
    </>)
}
