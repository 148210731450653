import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";

export default function CalTab({ children, tabValue, setTabValue, leftTitle, rightTitle }) {
  const { t } = useTranslation()
  return (
    <div className="results">
      <div className="result-tabs">
        <Tabs
          className="tabs"
          value={tabValue}
          onChange={(e, val) => setTabValue(val)}
          style={{ width: "fit-content" }}
          indicatorColor="none"
        >
          <Tab sx={{
            color: tabValue !== "Graph" ? "#ffc324" : "#363636",
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: { sm: "20px", xs: "12px" },
            borderBottom: `5px solid ${tabValue !== "Graph" ? "#ffc324" : "#00000000"}`,
            borderRadius: 4,
            padding: 0
          }} label={leftTitle || t("paymentBreakdown")} value="Results" />
          <Tab style={{
            color: tabValue === "Graph" ? "#ffc324" : "#363636",
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: { sm: "20px", xs: "12px" },
            borderBottom: `5px solid ${tabValue === "Graph" ? "#ffc324" : "#00000000"}`,
            borderRadius: 4,
            padding: 0
          }} label={rightTitle || t("graph")} value="Graph" />
        </Tabs>
      </div>
      {children}
    </div>
  );
}
