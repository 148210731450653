import React, { useState } from "react";
import ListTable from "./tabs/table";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "./tabs/cardRapper";
import CalTab from "./tabs";
import {
  ListL_LG,
  renderFormatData,
  NumericFormat,
  RecalculateButton,
  TextField,
} from "./tabs/list";

import Footer from "../footer";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { Box, Button, Card, Typography } from "@mui/material";

export default function MySavingGrow() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [interestRate, setInterestRate] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [initialBalance, setInitialBalance] = useState(null);
  const [amountSavings, setAmountSavings] = useState(null);
  const [numOfYears, setNumOfYears] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();

  const handleSubmit = (e) => {
    e.preventDefault();
    const initial_balance = Number(initialBalance?.replace(/[^0-9.-]+/g, ""));
    const interest_rate = Number(interestRate?.replace(/[^0-9.-]+/g, "")) / 100;
    const yearlySavings = Number(amountSavings?.replace(/[^0-9.-]+/g, ""));
    const numberOfYears = Number(numOfYears?.replace(/[^0-9.-]+/g, ""));

    const futureValue =
      initial_balance * Math.pow(1 + interest_rate, numberOfYears) +
      yearlySavings *
        ((Math.pow(1 + interest_rate, numberOfYears) - 1) / interest_rate);

    let startBalance = initial_balance;
    let endBalance = initial_balance;
    let interestEarned;

    const detailedTable = [];
    for (let year = 1; year <= numberOfYears; ++year) {
      startBalance = endBalance;
      interestEarned = (startBalance + yearlySavings) * interest_rate;
      endBalance = startBalance + yearlySavings + interestEarned;

      const dataRow = {
        year,
        startBalance: startBalance.toFixed(2),
        yearlySavings: yearlySavings.toFixed(2),
        interestEarned: interestEarned.toFixed(2),
        endBalance: endBalance.toFixed(2),
      };
      detailedTable.push(dataRow);
    }

    setShowResults(true);
    let temp = [
      {
        color: "#4898FF",
        data: endBalance,
        label: t("compoundedAnually"),
      },
      {
        color: "#51F074",
        data: numberOfYears,
        label: t("overNextYear"),
      },
    ];
    setChartData(temp);
    setTableData(detailedTable);
  };

  const applyPagination = (logData, page, rowsPerPage) =>
    logData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const renderResultTabs = () => {
    return (
      <>
        <Box>
          <Card
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 1,
            }}
          >
            <ListTable
              data={paginatedTableData}
              count={chartData?.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          </Card>

          <Box>
            {chartData?.map((item, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: theme.palette.common.background,
                  p: 1,
                  mt: 1,
                  borderRadius: 1,
                }}
              >
                <Typography>{item?.label}</Typography>
                <Typography>
                  {i == 1 || i == 2
                      ? item.data.toFixed(0)
                      : renderFormatData(item.data)}
                </Typography>
              </Box>
            ))}
            <Box mt={2} textAlign="center">
              <RecalculateButton
                onClick={() => {
                  setShowResults(false);
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* <CalTab
        leftTitle={t("paymentBreakdown")}
        rightTitle={t("details")}
        setTabValue={setTabValue}
        tabValue={tabValue}
      >
        {tabValue === "Results" && (
          <Grid item xs={12} md={12} sx={{ mt: 3 }}>
            <List>
              {chartData?.map((item, i) => (
                <ListL_LG
                  key={i}
                  left={item?.label}
                  right={
                    i == 1 || i == 2
                      ? item.data.toFixed(0)
                      : renderFormatData(item.data)
                  }
                />
              ))}
              <Divider />
            </List>
          </Grid>
        )}
        {tabValue === "Graph" && (
          <ListTable
            data={paginatedTableData}
            count={chartData?.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            page={page}
          />
        )}
        <RecalculateButton
          onClick={() => {
            setShowResults(false);
          }}
        />
      </CalTab> */}
      </>
    );
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="Initial balance or deposit"
        required
        variant="standard"
        value={initialBalance}
        onChange={(e) => {
          setInitialBalance(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Interest rate you expect to receive on your savings"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Amount of savings you will add each year"
        required
        variant="standard"
        value={amountSavings}
        onChange={(e) => {
          setAmountSavings(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Number of years"
        required
        variant="standard"
        value={numOfYears}
        onChange={(e) => {
          setNumOfYears(e.target.value);
        }}
        thousandSeparator=","
        customInput={TextField}
      />

      <Box textAlign="center" mt={1}>
        <Button
          variant="contained"
          sx={{ width: "70%" }}
          type="submit"
          className="btn btn-calculate"
        >
          {t("calculate")}
        </Button>
      </Box>
    </form>
  );

  const paginatedTableData = applyPagination(tableData, page, rowsPerPage);
  return showResults ? renderResultTabs() : renderForm();
}
