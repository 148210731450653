import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  IconButton,
  Card,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { CustomDropDown, CustomTextField } from "./CustomField";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(3, "Name must be at least 3 characters")
    .required("Please Enter Person Name"),
  type: Yup.string().required("Please select a type"),
  description: Yup.string().required("Please enter a description"),
  endDate: Yup.date().required("Please enter an end date"),
  person: Yup.string().required("Please enter the name of the person"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter an email address"),
  frequency: Yup.number()
    .min(1, "Frequency must be between 1 and 365")
    .max(365, "Frequency must be between 1 and 365")
    .required("Please select the frequency"),
});

const AddGoalDialog = ({
  openModal,
  setOpenModal,
  cardType,
  postFinancialData,
}) => {
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      description: "",
      endDate: "",
      person: "",
      email: "",
      frequency: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      postFinancialData({
        ...values,
        status: "todo",
      });
      formik.resetForm();
      setOpenModal(false);
    },
  });

  return (
    <Dialog
      open={openModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle
        style={{ textAlign: "center", fontWeight: 600, position: "relative" }}
        id="alert-dialog-title"
      >
        Add Goal
        <IconButton
          aria-label="close"
          onClick={() => setOpenModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card
              width="100%"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 0.5,
                mb: "1rem",
              }}
            >
              <img src="assets/img/iconsBg.png" alt="iconsBg" />
              <Typography variant="body1" textAlign="center">
                Add Icon For Your Goal
              </Typography>
              <Typography
                variant="h4"
                color={theme.palette.success.main}
                textAlign="center"
              >
                Explore{" "}
                <span style={{ color: theme.palette.primary.main }}>Icons</span>
              </Typography>
              <TextField
                variant="filled"
                placeholder="Type any word or term here"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ pb: "1rem" }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiFilledInput-root": {
                    backgroundColor: "transparent",
                    border: `1px solid ${theme.palette.common.lightGray}`,
                    padding: "5px 5px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "transparent",
                      borderColor: theme.palette.common.black,
                    },
                    "&.Mui-focused": {
                      backgroundColor: "transparent",
                      borderColor: theme.palette.common.black,
                    },
                  },
                  "& .MuiFilledInput-input": {
                    paddingTop: "8px",
                    paddingBottom: "2px",
                  },
                }}
              />
            </Card>
          </Grid>
          <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
            <Grid
              container
              spacing={2}
              height="14rem"
              overflow="auto"
              mt={2}
              px={1}
            >
              <Grid item md={6} xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  height="100%"
                  justifyContent="space-between"
                >
                  <CustomTextField
                    label="Enter Name"
                    placeholder="Type..."
                    {...formik.getFieldProps("name")}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <CustomDropDown
                    placeholder="Select Type"
                    options={cardType}
                    {...formik.getFieldProps("type")}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Description"
                  placeholder="Type..."
                  multiline
                  rows={4}
                  {...formik.getFieldProps("description")}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Goal Start Date"
                  type="date"
                  InputLabelProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    ),
                    shrink: true,
                  }}
                  {...formik.getFieldProps("startDate")}
                  error={
                    formik.touched.startDate && Boolean(formik.errors.startDate)
                  }
                  helperText={
                    formik.touched.startDate && formik.errors.startDate
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Goal End Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...formik.getFieldProps("endDate")}
                  error={
                    formik.touched.endDate && Boolean(formik.errors.endDate)
                  }
                  helperText={formik.touched.endDate && formik.errors.endDate}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Person Name Will Check Your Progress"
                  placeholder="Type..."
                  {...formik.getFieldProps("person")}
                  error={formik.touched.person && Boolean(formik.errors.person)}
                  helperText={formik.touched.person && formik.errors.person}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Email Address of Person Who Will Check In"
                  placeholder="Type..."
                  type="email"
                  {...formik.getFieldProps("email")}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomDropDown
                  label="Frequency of Check-ins"
                  options={[
                    { value: 7, name: "Weekly" },
                    { value: 14, name: "Bi-weekly" },
                    { value: 30, name: "Monthly" },
                  ]}
                  {...formik.getFieldProps("frequency")}
                  error={
                    formik.touched.frequency && Boolean(formik.errors.frequency)
                  }
                  helperText={
                    formik.touched.frequency && formik.errors.frequency
                  }
                />
              </Grid>
            </Grid>
            <DialogActions
              sx={{ mt: "1rem", display: "flex", justifyContent: "center" }}
            >
              <Button type="submit" variant="contained" sx={{ width: "50%" }}>
                Add Goal
              </Button>
            </DialogActions>
          </form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddGoalDialog;
