import { Link } from 'react-router-dom';

function LoanModal() {
    return (<>
        <div className="modal fade action-sheet" id="loanModal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content p-3">
                    <div className="modal-body">
                        <h3>Do you have a loan for your vehicle?</h3>
                        <p>If you have a loan, you can add the loan and your car at the same time</p>
                        <Link to="/select-account">
                            <h3 className="mt-4 mb-3 text-center" data-bs-dismiss="modal">No, I don't need a loan</h3>
                        </Link>
                        <Link to="/add-plan">
                            <button className="btn btn-lg btn-primary btn-block m-4" data-bs-dismiss="modal">Yes, I have a loan</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default LoanModal;