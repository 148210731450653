import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Refresh } from "@mui/icons-material";
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import "./index.css"
const tableHeadStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "15px",
  color: "#9B9FAC",
  backgroundColor: "#F0F1FF",
  width: "25%",
  padding: "5px 10px",
};

const tableBodyStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  padding: "6px 0px 8px 0px",
  BorderBottom: "unset",
};

const titleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "14px",
  color: "#242424",
};

const descStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  color: "#9B9FAC",
};

export default function AllPodcasts(props) {
  const { podcasts, submittedPodcasts, setPage, refresh } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPodcasts, setFilteredPodcasts] = useState([]);

  const handleQueryChange = (event) => {
    event.preventDefault();
    const _change = event.target.value;
    setSearchQuery(_change);
    if (_change == "") {
      setFilteredPodcasts(submittedPodcasts);
    } else {
      setFilteredPodcasts(
        submittedPodcasts.filter((item) =>
          item?.title.toLowerCase().includes(_change.toLowerCase())
        )
      );
    }
  };

  useEffect(() => {
    setFilteredPodcasts(submittedPodcasts);
  }, [podcasts]);

  return (
    <>
      <Grid
        container
        className="d-flex justify-content-between align-items-center mb-1"
      >
        <Grid item lg={3} md={3} sm={12} className="d-flex align-items-center">
          <h1
            style={{
              fontfamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "20px",
            }}
          >
            All Podcasts
          </h1>
        </Grid>

        <Grid item lg={4} md={4} sm={12} className="d-flex align-items-center" width={"inherit"}>
          <TextField
            value={searchQuery}
            fullWidth
            onChange={handleQueryChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
            placeholder="Search podcast"
          />
        </Grid>

        <Grid item lg={5} md={5} sm={12} sx={{ textAlign: "right" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 20,
              justifyContent: "flex-end",
              alignItems: "center",
              margin:'10px 0px'
            }}
          >
            <Button
              startIcon={<Refresh />}
              onClick={() => {
                setSearchQuery("");
                refresh();
              }}
            >
              Refresh
            </Button>

            <Button
              sx={{
                "&:hover": {
                  backgroundColor: "#3D49C9",
                  borderColor: "#0062cc",
                  boxShadow: "none",
                },
                textTransform: "none",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "0.938rem",
                px: 3,
                py: 1.2,
                borderRadius: "15px",
                bgcolor: "#616DED",
                '@media(max-width:450px)':{
                  px:0.8,
                  py:0.6
                }
              }}
              onClick={() => {
                setPage("all");
              }}
            >
              <Typography variant="btn1" sx={{ color: "#fff" }}>
                Podcast Assignments
              </Typography>
            </Button>
          </div>
        </Grid>
      </Grid>

      <TableContainer
        sx={{ overflowX: "hidden", overflowY: "scroll", height: "60vh" }}
        className="mt-1"
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#F0F1FF" }}>
              <TableCell sx={tableHeadStyle}>Image</TableCell>
              <TableCell sx={{ ...tableHeadStyle, flexDirection: "column" }}>
                <Typography sx={titleStyle}>Title</Typography>
                <Typography>Description</Typography>
              </TableCell>
              <TableCell sx={{ ...tableHeadStyle, width: '48%' }}>File</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(filteredPodcasts || []).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
                  <TableCell sx={{ ...tableBodyStyle, borderBottom: "none" }}>
                    <img
                      src={row.podcastImage}
                      alt="Podcast image here! "
                      className="podcast-image"
                      style={{ borderRadius: "8px" }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      ...tableBodyStyle,
                      flexDirection: "column",
                      borderBottom: "none",
                    }}
                  >
                    <Typography sx={titleStyle}>{row.title}</Typography>
                    <Typography sx={descStyle}>
                      <span dangerouslySetInnerHTML={{ __html: row.description }} />
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>

                    <CustomAudioPlayer url={row?.submittedFile} text={row?.submittedChat || []} />
                    {/* <audio controls>
                      <source src={row.submittedFile} />
                    </audio> */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const CustomAudioPlayer = ({ url, text }) => {
  const [ccModal, setCCModal] = useState(false)
  const Message = ({ index, username, text }) => {
    return (
      <Grid container>
        <Grid
          item
          xs={2.5}
          sx={{ color: index == 0 ? "#616DED" : "#FB785C", fontWeight: "bold" }}
        >
          {username}:
        </Grid>
        <Grid item xs={9.5} sx={{ color: "#000" }}>
          {text}
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Dialog
        open={ccModal}
        keepMounted
        onClose={() => setCCModal(false)}
        maxWidth='sm'
        fullWidth
        style={{
          backgroundColor: 'rgba(1000, 1000, 1000, 0.2)',
          overflow: 'visible',
        }}
      >
        <DialogTitle>Transcript</DialogTitle>

        <Grid container
          sx={{ height: '50vh' }}>
          <Grid
            container
            item
            xs={12}
            sx={{ display: 'flex', flexDirection: 'column' }}
            p={2}
          >
            {
              !text?.length ?
                "No transcript found"
                :
                text?.map((itemSting, index) => {
                  const item = JSON?.parse(itemSting)
                  return (
                  <Message
                    username={item?.user?.name}
                    index={item?.user?.id}
                    text={item?.text}
                    key={`${index}`}
                  />
                )})}
          </Grid>
        </Grid>
      </Dialog>
      <div style={audioPlayerStyle}>
        <audio
          style={audioStyle}
          src={url}
          controls="true"
          controlsList="nodownload"
          className="audio-1"
        ></audio>
        <IconButton style={ccButton} onClick={() => setCCModal(true)}>
          <ClosedCaptionIcon fontSize="large" />
        </IconButton>
      </div>
    </>

  )
}

const audioPlayerStyle = {
  position: 'relative',
  width: '100%',
  height: 'auto',
  backgroundColor: '#f8f9fa',
  display: 'flex'
}
const ccButton = {
  position: 'absolute',
  top: '0px',
  right: '5px',
  color: '#000'
}

const audioStyle = {
  width: "90%",
  background: 'transparent',
  border: 'none'
};