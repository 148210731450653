import IconButton from '@mui/material/IconButton';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import HealingIcon from '@mui/icons-material/Healing';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import HotTubOutlinedIcon from '@mui/icons-material/HotTubOutlined';
import DirectionsBikeOutlinedIcon from '@mui/icons-material/DirectionsBikeOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import SettingsInputComponentOutlinedIcon from '@mui/icons-material/SettingsInputComponentOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import LunchDiningOutlinedIcon from '@mui/icons-material/LunchDiningOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import PetsOutlinedIcon from '@mui/icons-material/PetsOutlined';
import AddRoadOutlinedIcon from '@mui/icons-material/AddRoadOutlined';
import CloudQueueOutlinedIcon from '@mui/icons-material/CloudQueueOutlined';
import WatchOutlinedIcon from '@mui/icons-material/WatchOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import LaptopMacOutlinedIcon from '@mui/icons-material/LaptopMacOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined';
import VoicemailOutlinedIcon from '@mui/icons-material/VoicemailOutlined';
import SetMealOutlinedIcon from '@mui/icons-material/SetMealOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import EvStationOutlinedIcon from '@mui/icons-material/EvStationOutlined';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import MoodOutlinedIcon from '@mui/icons-material/MoodOutlined';
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WcOutlinedIcon from '@mui/icons-material/WcOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded';
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import GrassRoundedIcon from '@mui/icons-material/GrassRounded';
import SettingsRemoteRoundedIcon from '@mui/icons-material/SettingsRemoteRounded';
import DirectionsBoatFilledRoundedIcon from '@mui/icons-material/DirectionsBoatFilledRounded';
import PoolRoundedIcon from '@mui/icons-material/PoolRounded';
import SurfingRoundedIcon from '@mui/icons-material/SurfingRounded';
import IceSkatingRoundedIcon from '@mui/icons-material/IceSkatingRounded';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import NightlightRoundedIcon from '@mui/icons-material/NightlightRounded';
import LiveTvRoundedIcon from '@mui/icons-material/LiveTvRounded';
import FreeBreakfastRoundedIcon from '@mui/icons-material/FreeBreakfastRounded';

function IconModal() {
    return (<>
        <div className="modal fade action-sheet" id="iconModal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body p-3 text-center text-dark">
                        <IconButton >
                            <InsertEmoticonIcon />
                        </IconButton>
                        <IconButton >
                            <HealingIcon />
                        </IconButton>
                        <IconButton >
                            <CoffeeMakerIcon />
                        </IconButton>

                        <IconButton >
                            <HexagonOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <EmojiObjectsOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <SportsBasketballOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <HotTubOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <DirectionsBikeOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <LocalAtmOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <SettingsInputComponentOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <AddCardOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <AccountBalanceWalletOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <CreditCardOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <PhoneIphoneOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <ViewInArOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <LunchDiningOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <CameraAltOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <CakeOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <StyleOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <ShoppingCartOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <CardGiftcardOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <PetsOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <AddRoadOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <CloudQueueOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <WatchOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <ExploreOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <LaptopMacOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <PrecisionManufacturingOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <ChairOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <VoicemailOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <SetMealOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <SportsEsportsOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <EvStationOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <RedeemOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <MoodOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <HeadphonesOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <HomeOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <WcOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <ConstructionOutlinedIcon />
                        </IconButton>

                        <IconButton >
                            <AttachMoneyRoundedIcon />
                        </IconButton>

                        <IconButton >
                            <MusicNoteRoundedIcon />
                        </IconButton>

                        <IconButton >
                            <ColorLensRoundedIcon />
                        </IconButton>

                        <IconButton >
                            <LocationOnRoundedIcon />
                        </IconButton>

                        <IconButton >
                            <GrassRoundedIcon />
                        </IconButton>

                        <IconButton >
                            <SettingsRemoteRoundedIcon />
                        </IconButton>

                        <IconButton >
                            <DirectionsBoatFilledRoundedIcon />
                        </IconButton>

                        <IconButton >
                            <PoolRoundedIcon />
                        </IconButton>

                        <IconButton >
                            <SurfingRoundedIcon />
                        </IconButton>

                        <IconButton >
                            <IceSkatingRoundedIcon />
                        </IconButton>

                        <IconButton >
                            <ModeEditRoundedIcon />
                        </IconButton>

                        <IconButton >
                            <NightlightRoundedIcon />
                        </IconButton>

                        <IconButton >
                            <LiveTvRoundedIcon />
                        </IconButton>


                        <IconButton >
                            <FreeBreakfastRoundedIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default IconModal;