import * as React from "react";

import {
  LineChart,
  BarChart as BChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { CustomTooltip } from "../credit-score";
import { barChartData, chartData, currencyFormat } from "../_budget-functionality";
import { Tab, Tabs } from "@mui/material";
import moment from "moment";
import "./index.css"
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const tabs = [
  { label: "5 D", value: "5day" },
  { label: "1 W", value: "1week" },
  { label: "6 M", value: "6month" },
  { label: "Max", value: "max" },
];

export default class BarChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.merge
        ? []
        : chartData(this.props.data.netWorth, null, 'NetWorth').reverse(),
      currentTab: "max",
      assetDebt: this.props.assetDebt || false,
    };
  }

  componentDidMount() {
    this.handleData();
    this.setState({ data: this.splitData() });
  }

  handleData = async () => {
    setTimeout(() => {
      let tagVal = document.getElementsByClassName(
        "recharts-text recharts-cartesian-axis-tick-value"
      );
      tagVal.forEach((nodes) => {
        nodes.childNodes.forEach((el) => {
          let val = el.innerHTML;
          if (Number(val))
            el.innerHTML = "$" + val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          if (val == 0)
            el.innerHTML = "$" + val.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        });
      });
    }, 100);
  };

  handleTabsChange = (event, currentTab) => {
    this.setState({ currentTab }, () => {
      const d = this.splitData();
      this.setState({ data: d });
    });
  };

  splitData = () => {
    const { currentTab } = this.state;
    const { merge, isBudget } = this.props;
    const tempChartData = merge
      ? this.mergeGoal().reverse()
      : isBudget ?
        this.mergeGoal().reverse()
        : chartData(this.props.data.netWorth, this.handleDataWithTab(), "NetWorth").reverse();
    if (currentTab === "6month")
      return tempChartData.slice(tempChartData.length - 6);
    if (currentTab === "1week")
      return tempChartData.slice(tempChartData.length - 7);
    if (currentTab === "5day")
      return tempChartData.slice(tempChartData.length - 5);
    if (currentTab === "6month")
      return tempChartData.slice(tempChartData.length - 6);

    return tempChartData;
  };

  mergeChartData = () => {
    const assetsAndDebits = chartData(
      this.props.data.netWorth,
      this.handleDataWithTab(),
      "NetWorth"
    );
    const incomeAndExpense = barChartData(
      this.props.data.allData,
      0,
      this.handleDataWithTab()
    );

    if (incomeAndExpense.length > assetsAndDebits.length) {
      return incomeAndExpense.map((val) => {
        let tempData = assetsAndDebits.find((el) => el.Month == val.Month);
        const payload = val;
        if (tempData) {
          payload.Assets = tempData.Assets;
          payload.Debts = tempData.Debts;
          return payload;
        }
        payload.Assets = 0;
        payload.Debts = 0;
        return payload;
      });
    }

    return assetsAndDebits.map((val) => {
      let tempData = incomeAndExpense.find((el) => el.Month == val.Month);
      const payload = val;
      if (tempData) {
        payload.Income = tempData.Income;
        payload.Expenses = tempData.Expenses;
        return payload;
      }
      payload.Income = 0;
      payload.Expenses = 0;
      return payload;
    });
  };

  mergeGoal = () => {
    const { data, currentTab } = this.props;
    const fromatData = data.goals
      ?.filter((el) => el.status == "progress")
      .map((val) => ({
        ...val,
        monthName: moment(val.updatedAt).format(
          this.handleDataWithTab() == "day" ? "ddd" : "MMM"
        ),
        monthNumber: moment(val.updatedAt).format("M"),
      }));
    const goalObj = {};
    fromatData?.forEach((goal) => {
      if (!goalObj[goal.monthName]) goalObj[goal.monthName] = [];
      goalObj[goal.monthName].push(goal);
    });

    return this.mergeChartData().map((main) => ({
      ...main,
      finance_goal: goalObj[main.Month] || [],
    }));
  };

  handleDataWithTab = () => {
    const { currentTab } = this.state;

    if (currentTab === "1week" || currentTab === "5day") return "day";
    if (currentTab == "6month" || currentTab == "max") return "month";
    return "month";
  };


  render() {
    const { data, currentTab, assetDebt } = this.state;
    const { merge, height, isHideTitle, top } = this.props;
    const legendPayload = [
      { value: !assetDebt ? 'Assets' : 'Income', type: 'square', color: !assetDebt ? '#616DED' : '#1DCC70' },
      { value: !assetDebt ? 'Debts' : 'Expenses', type: 'square', color: !assetDebt ? '#fb785c' : '#75879d' },
    ];
    const currencyFormat = (value) => "$" + `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return (
      <div
        style={{ width: "100%", minWidth: '20%', backgroundColor: "#ffffff", borderRadius: 15 }}
      >
        {(!isHideTitle && !this.props?.isBudget) && (
          <h1 style={{ fontSize: 30, textAlign: "center" }}>
            Assets & Debts Summary
          </h1>
        )}

        <div style={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {
            merge &&
            <ToggleButtonGroup
              color="primary"
              sx={{ mt: 2 }}
              value={assetDebt}
              exclusive
              onChange={(e, newVal) => this.setState({ assetDebt: newVal })}
              aria-label="Platform"
            >
              <ToggleButton sx={{ borderRadius: '16px', p: '5px 10px', textTransform: 'inherit' }} value={false}>Asset-Debt</ToggleButton>
              <ToggleButton sx={{ borderRadius: '16px', p: '5px 10px', textTransform: 'inherit' }} value={true}>Income-Expense</ToggleButton>
            </ToggleButtonGroup>
          }
          <Tabs
            indicatorColor="none"
            onChange={this.handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            variant="scrollable"
            sx={{
              "& button.Mui-selected": {
                backgroundColor: "#616DED",
                color: "#FFFFFF",
              },
              "& button:hover": {
                backgroundColor: "#EAECFF",
                borderColor: "#0062cc",
                boxShadow: "none",
              },

              mt: top || 2,
            }}
          >
            {tabs.map((tab) => {
              return (
                <Tab
                  // className="btn btn-outline-primary"
                  sx={{
                    border: "1px solid #616DED",
                    borderRadius: "40px",
                    m: 1,
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "15px",
                    padding: 0,
                    lineHeight: 2,
                    minHeight: 0,
                    minWidth: '100px',
                  }}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              );
            })}
          </Tabs>
        </div>

        <div style={{ height: height || 200, width: "100%" }}>
          <ResponsiveContainer width="100%" height="100%">
            <BChart
              width={500}
              height={300}
              data={data || []}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Month" />
              <YAxis tickFormatter={currencyFormat} />
              <Tooltip content={<CustomTooltip />} formatter={currencyFormat} />
              <Legend payload={(merge || this.props.isBudget) && [...legendPayload, { value: 'Goals', type: 'square', id: 'extra-legend' }]} />
              {!assetDebt ?
                <>
                  <Bar dataKey="Assets" fill="#616DED" />
                  <Bar dataKey="Debts" fill="#fb785c" />
                </>
                :
                <>
                  <Bar dataKey="Income" fill="#1DCC70" />
                  <Bar dataKey="Expenses" fill="#75879d " />
                </>
              }
            </BChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}
