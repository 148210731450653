import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ScoreIcon from "@mui/icons-material/Score";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import Marquee from "react-fast-marquee";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { style } from "@mui/system";
import { getEnrollment, resetCalculator } from "../_api/skill-assignments";
import { getAllData } from "../_api";
import toast from "react-hot-toast";
import { useQuery, useMutation } from "react-query";
import swal from "sweetalert";
import { setTickerData, currencyFormat } from "../_budget-functionality";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import {
  Tab,
  Tabs,
  Box,
  Grid,
  Paper,
  Card,
  responsiveFontSizes,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

let theme = createTheme();
theme.typography.h1 = {
  fontSize: "12px",
  fontWeight: 400,
  fontFamily: "Poppins",
  fontStyle: "normal",
  display: "flex",
  alignItems: "center",
  textTransform: "uppercase",
  color: "#FFFFFF",

  "@media (min-width:600px)": {
    fontSize: "16px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
    fontWeight: 600,
  },
};
theme.typography.h2 = {
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Poppins",
  fontStyle: "normal",
  display: "flex",
  alignItems: "center",
  color: "#616DED",
  "@media (min-width:600px)": {
    fontSize: "15px",
    fontWeight: 600,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "15px",
    fontWeight: 600,
  },
};
const HoverTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#616DED",
    marginLeft: 80,
    borderTopRightRadius: "30%",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    width: "259px",
    height: "127.5px",
    paddingTop: 28,
    paddingBottom: 40,
    paddingLeft: 30,
    paddingRight: 20,
    fontSize: theme.typography.pxToRem(12),
    background: "#616DED",
    borderRadius: "15px",
  },
}));
export default function HomeHeader1() {
  const [index, setIndex] = useState(-1);
  const [framLink, setFramLink] = useState("");
  const [news, setNews] = useState([]);
  const [marqeeData, setMarqeeData] = useState([]);
  useEffect(() => {
    getNews();
  }, []);

  const navigateTo = () => {
    if (sessionStorage.getItem("path") === "lesson") {
      window.location.href =
        process.env.REACT_APP_CLIENT +
        "/" +
        sessionStorage.getItem("path") +
        "?id=" +
        sessionStorage.getItem("id");
    } else if (sessionStorage.getItem("path") === "skills") {
      window.location.href = `${process.env.REACT_APP_CLIENT
        }/${sessionStorage.getItem("path")}?moduleID=${sessionStorage.getItem(
          "moduleID"
        )}&lessonID=${sessionStorage.getItem(
          "lessonID"
        )}&skillID=${sessionStorage.getItem("skillID")}`;
    } else if (
      sessionStorage.getItem("id") === null &&
      sessionStorage.getItem("moduleID") === null &&
      sessionStorage.getItem("path" !== null)
    ) {
      window.location.href = process.env.REACT_APP_CLIENT + "/module";
    }
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("path");
    sessionStorage.removeItem("moduleID");
    sessionStorage.removeItem("lessonID");
    sessionStorage.removeItem("skillID");

    const lightBlink = () => {
      setInterval(() => {
        style = { color: "black" };
      }, 1000);
    };
  };

  const getNews = async () => {
    const newsData = localStorage.getItem("newsData");
    if (newsData) return setNews(JSON.parse(newsData));

    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        "https://newsdata.io/api/1/news?apikey=pub_13329c08f62a65b582c65daed6348dda041ae&q=financial%20",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          localStorage.setItem("newsData", JSON.stringify(result.results));

          setNews(result.results);
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log("🚀 ~ file: header.js ~ line 63 ~ getNews ~ error", error);
    }
  };

  const { data: enrollment } = useQuery("getting-enrollment", getEnrollment);

  const { data: allData } = useQuery("budgeting-calculators", getAllData, {
    enabled: Boolean(sessionStorage.getItem('token'))
  });
  useEffect(() => {
    if (!!allData) {
      setMarqeeData(setTickerData(allData));
    }
  }, [allData]);
  const { mutateAsync: budgetReset } = useMutation(resetCalculator, {
    onSuccess: () => {
      toast.dismiss();
      toast.success("Budget Calculator restored successfully");
    },
  });
  const resetBudget = () => {
    toast.loading("Reseting, Please wait...");
    budgetReset();
  };
  const capitalize = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        className="appHeader bg-primary text-light"
        style={{
          marginTop: 0,
          height: "83px",
        }}
      >
        <Grid
          item
          sm={4}
          md={4}
          xs={4}
          className="left"
          sx={{ ml: 3, mt: 0.5 }}
        >
          <div
            className="headerButton"
            data-bs-toggle="modal"
            data-bs-target="#sidebarPanel"
          >
            <img
              width="100%"
              height="auto"
              src="assets/img/icon/widgets.svg"
              className="logo"
              alt=""
            />
          </div>

          <div className="pageTitle">
            {/* <img
              src={process.env.REACT_APP_ENV === "stag" ? "assets/img/ft-logo.png" : "assets/img/logo-white.gif"}
              className="logo"
              alt=""
              style={{ verticalAlign: 0, maxWidth: "100%", width: "102px", height:"auto" }}
            /> */}
          </div>
        </Grid>
        <Grid
          sm={4}
          md={4}
          xs={4}
          className="pageTitle header-heading"
          style={{ marginTop: 3, marginLeft: "7%" }}
        >
          <ThemeProvider theme={theme}>
            <Typography variant="h1" className="d-block text-center">Manage Budget</Typography>
          </ThemeProvider>
        </Grid>
        <Grid sm={4} md={4} xs={4} className="right">
          <div
            className="headerButton"
            data-bs-toggle="modal"
            data-bs-target="#sidebarPanel"
            style={{ marginTop: 30 }}
          >
            <img
              width="64px"
              height="83px"
              src="assets/img/icon/stick.svg"
              className="logo"
              alt=""
            />
          </div>
        </Grid>
      </Grid>
      {!!news.length && (
        <div
          className="appHeader"
          style={{ backgroundColor: 'rgb(255 247 246)', height: 34, top: 83, border: 'none' }}
        >
          <h4 className='news-day'>News of the Day</h4>
          <Marquee gradient={false} className="border-none">

            {news.map((value) => (
              <a
                style={{ textDecoration: "none" }}
                key={value.title}
                href={value.link}
                target="_blank"
              >
                <labal style={{ color: "#fa7f6d", marginLeft: 20 }}>
                  {"* " + value.title}
                </labal>
              </a>
            ))}
          </Marquee>
        </div>
      )}

    </>
  );
}