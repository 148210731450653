import React from "react";
import {
  Grid,
  Button,
  Slide,
  Card,
  CardContent,
  Divider,
  IconButton,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AddIcon from "@mui/icons-material/Add";
import { CustomTextButton, CustomButton } from "../../Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { postTimeTrackers } from '../../../_api/time-management'
import { toast } from "react-hot-toast";
import "./track-time.css";

const fieldGroup = {
  display: "flex",
  flexDirection: "column",
  gap: 10,
};
const labelStyle = {
  fontSize: "16px",
  fontWeight: 500,
  color: "#000",
  marginLeft: 2,
  marginBottom: 14,
  height: 24,
};
const inputStyle = {
  padding: 10,
  border: "1px solid",
  borderRadius: "10px",
  width: "100%",
};
const dollarSignStyle = {
  position: "absolute",
  left: "8px",
  top: "50%",
  transform: "translateY(-50%)",
};
const CustomInput = (props) => {
  const {
    name,
    error,
    label,
    type,
    helperText,
    onChange,
    value,
    onBlur,
    placeholder,
    fullWidth,
    showIcon,
    multiline,
    rows,
    subLabel,
    others,
  } = props;
  if (multiline) {
    return (
      <>
        <div style={fieldGroup}>
          <label style={labelStyle}>{label}</label>
          {subLabel && (
            <p
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                fontSize: "12px",
                margin: "0px",
              }}
            >
              {subLabel}
            </p>
          )}
          <textarea
            {...others}
            name={name}
            placeholder={placeholder}
            style={{
              ...inputStyle,
              paddingLeft: "10px",
              borderColor: error ? "red" : "rgba(0, 0, 0, 0.23)",
              width: fullWidth ? "100%" : "fit-content",
            }}
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            rows={rows}
            type={type}
          />
          <p
            style={{
              color: error ? "red" : "rgba(0, 0, 0, 0.6)",
              fontSize: "12px",
              margin: "0px",
            }}
          >
            {helperText}
          </p>
        </div>
      </>
    );
  }
  return (
    <>
      <div style={fieldGroup}>
        <label style={labelStyle}>{label}</label>
        {subLabel && (
          <p
            style={{
              color: "rgba(0, 0, 0, 0.6)",
              fontSize: "12px",
              margin: "0px",
            }}
          >
            {subLabel}
          </p>
        )}
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          {showIcon && (
            <span style={dollarSignStyle}>
              <MonetizationOnIcon />
            </span>
          )}
          <input
            {...others}
            name={name}
            placeholder={placeholder}
            style={{
              ...inputStyle,
              borderColor: error ? "red" : "rgba(0, 0, 0, 0.23)",
              paddingLeft: showIcon ? "35px" : "10px",
              width: "100%",
            }}
            onBlur={onBlur}
            value={value}
            onChange={onChange}
            type={type}
          />
        </div>
        <p
          style={{
            color: error ? "red" : "rgba(0, 0, 0, 0.6)",
            fontSize: "12px",
            margin: "0px",
          }}
        >
          {helperText}
        </p>
      </div>
    </>
  );
};

export default function TrackTime({ setPage, page }) {
  const unixCalculator = (date, time) => {
    var currentDate = new Date(date);
    var [hours, minutes] = time.split(":");
    return (
      currentDate.getTime() +
      parseInt(hours) * 3600000 +
      parseInt(minutes) * 60000
    ).toString();
  };

  const formik = useFormik({
    initialValues: {
      jobName: "",
      hourlyRate: "",
      jobCode: "",
      trackingDate: "",
      punchIn: "",
      punchOut: "",
      breaks: [
        { start: "", end: "" },
        { start: "", end: "" },
      ],
      note: "",
      label: "",
    },
    validationSchema: Yup.object({
      jobName: Yup.string("")
        .trim()
        .required("Job Name is required")
        .matches(/^[A-Z]{0,3}[A-Za-z0-9\s]*$/, "Please enter valid Job Name")
        .min(3, "Job Name must be at least Three Characters")
        .max(120, "Job Name must be at most 120 Characters"),
      jobCode: Yup.string("")
        .trim()
        .required("Job Code/Name is required")
        .matches(
          /^[A-Z]{0,3}[A-Za-z0-9\s]*$/,
          "Please enter valid Job Code/Name"
        )
        .min(3, "Job Code/Name must be at least Three Characters")
        .max(120, "Job Code/Name must be at most 120 Characters"),
      trackingDate: Yup.string().required(["Tracking Date is required"]),
      hourlyRate: Yup.string().required(["This field is required"]),
      punchIn: Yup.string().required(["This field is required"]),
      punchOut: Yup.string().required(["This field is required"]),
      note: Yup.string()
        .trim()
        .required(["This field is required"])
        .min(5, "Note must be at least 5 characters long"),
      label: Yup.string()
        .trim()
        .required(["This field is required"])
        .min(3, "Name must be at least 3 characters long"),
      breaks: Yup.array()
        .of(
          Yup.object().shape({
            start: Yup.string(),
            end: Yup.string(),
          })
        )
        .required("There must be at least 2 breaks")
        .min(2, "There must be at least 2 breaks"),
    }),
    onSubmit: async (values) => {
      let tempBody = {
        ...values,
        trackingDate: unixCalculator(values.trackingDate, "0:0"),
        punchIn: unixCalculator(values.trackingDate, values.punchIn),
        punchOut: unixCalculator(values.trackingDate, values.punchOut),
        breaks: values?.breaks?.map((item) => {
          if (item?.start && item.end) {
            return {
              start: unixCalculator(values.trackingDate, item.start),
              end: unixCalculator(values.trackingDate, item.end),
            };
          }
        }),
      };
      try {
        toast.loading("Saving please wait...");
        // await postTimeTrackers(tempBody)
        toast.dismiss();
        toast.success("Saved!");
        setPage("income");
      } catch (err) {
        console.log(err.message);
        toast.dismiss();
        toast.error(err.message);
      }
    },
  });
  return (
    <Slide
      direction="left"
      in={page === "trackTime"}
      mountOnEnter
      unmountOnExit
    >
      <Grid container sx={mainPageStyle}>
        <Card sx={{ ...cardStyle, width: "80%" }}>
          <Typography
            sx={{ p: 2, textAlign: "center", fontWeight: 600 }}
            variant="h5"
          >
            Track Time
          </Typography>
          <Divider />
          <CardContent>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ px: 8, py: 8 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <CustomInput
                      name="jobName"
                      helperText={
                        formik.touched.jobName && formik.errors.jobName
                      }
                      error={Boolean(
                        formik.touched.jobName && formik.errors.jobName
                      )}
                      onChange={formik.handleChange}
                      value={formik.values.jobName}
                      onBlur={formik.handleBlur}
                      fullWidth
                      label="Job Name"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInput
                      name="jobName"
                      helperText={
                        formik.touched.jobCode && formik.errors.jobCode
                      }
                      error={Boolean(
                        formik.touched.jobCode && formik.errors.jobCode
                      )}
                      onChange={formik.handleChange}
                      value={formik.values.jobCode}
                      onBlur={formik.handleBlur}
                      fullWidth
                      label="Job Code/Name"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="mt-2">
                  <Grid item xs={6}>
                    <label style={labelStyle}>W2 Tax</label>
                    <RadioGroup
                      sx={{ ml: 1 }}
                      row
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="standard"
                        control={<Radio />}
                        label="Standard"
                      />
                      <FormControlLabel
                        value="non-standard"
                        control={<Radio />}
                        label="Non-Standard"
                      />
                    </RadioGroup>
                    <p
                      style={{ color: "red", fontSize: "12px", margin: "0px" }}
                    ></p>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <TextField
                        select={true}
                        // name="taxr"
                        // helperText={formik.touched.jobName && formik.errors.jobName}
                        // error={Boolean(formik.touched.jobName && formik.errors.jobName)}
                        // onChange={formik.handleChange}
                        // value={formik.values.jobName}
                        // onBlur={formik.handleBlur}
                        fullWidth
                        label="State of Tax"
                        SelectProps={{
                          sx: { borderRadius: "10px" },
                          native: true,
                        }}
                        InputLabelProps={{ shrink: true }}
                      >
                        <option value="">Choose one</option>
                        <option value="m-1">Menu Item</option>
                        <option value="m-2">Menu Item</option>
                        <option value="m-3">Menu Item</option>
                        <option value="m-4">Menu Item</option>
                      </TextField>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="mt-2">
                  <Grid item xs={6}>
                    <CustomInput
                      name="trackingDate"
                      helperText={
                        formik.touched.trackingDate &&
                        formik.errors.trackingDate
                      }
                      error={Boolean(
                        formik.touched.trackingDate &&
                          formik.errors.trackingDate
                      )}
                      onChange={formik.handleChange}
                      value={formik.values.trackingDate}
                      onBlur={formik.handleBlur}
                      type="date"
                      fullWidth
                      label="Date"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInput
                      name="hourlyRate"
                      helperText={
                        formik.touched.hourlyRate && formik.errors.hourlyRate
                      }
                      error={Boolean(
                        formik.touched.hourlyRate && formik.errors.hourlyRate
                      )}
                      onChange={formik.handleChange}
                      value={formik.values.hourlyRate}
                      onBlur={formik.handleBlur}
                      fullWidth
                      label="Hourly Rate"
                      showIcon={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="mt-2">
                  <Grid item xs={3}>
                    <CustomInput
                      name="punchIn"
                      helperText={
                        formik.touched.punchIn && formik.errors.punchIn
                      }
                      error={Boolean(
                        formik.touched.punchIn && formik.errors.punchIn
                      )}
                      onChange={formik.handleChange}
                      value={formik.values.punchIn}
                      onBlur={formik.handleBlur}
                      type="time"
                      label="Punch In"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomInput
                      name="punchOut"
                      helperText={
                        formik.touched.punchOut && formik.errors.punchOut
                      }
                      error={Boolean(
                        formik.touched.punchOut && formik.errors.punchOut
                      )}
                      onChange={formik.handleChange}
                      value={formik.values.punchOut}
                      onBlur={formik.handleBlur}
                      type="time"
                      label="Punch Out"
                    />
                  </Grid>
                  <Grid item xs={3} />
                </Grid>
                <Grid container spacing={2} className="mt-2">
                  <Grid item xs={3}>
                    <CustomInput
                      value={formik.values.breaks[0]?.start}
                      onChange={(e) => {
                        let breaks = formik.values.breaks;
                        breaks[0].start = e.target.value;
                        formik.setFieldValue("breaks", breaks);
                      }}
                      type="time"
                      disabled={false}
                      label="Breaks"
                      subLabel="Start Time"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomInput
                      value={formik.values.breaks[0]?.end}
                      onChange={(e) => {
                        let breaks = formik.values.breaks;
                        breaks[0].end = e.target.value;
                        formik.setFieldValue("breaks", breaks);
                      }}
                      type="time"
                      label=""
                      subLabel="End Time"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <CustomTextButton
                      onClick={() => {
                        let breaks = formik.values.breaks;
                        breaks.push({ start: "", end: "" });
                        formik.setFieldValue("breaks", breaks);
                      }}
                      style={{ marginTop: "70px" }}
                    >
                      <AddIcon style={{ marginRight: "10px" }} />
                      Add Break Time
                    </CustomTextButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        padding: "13px",
                        backgroundColor: "rgba(169, 169, 169, 0.1)",
                        borderRadius: "10px",
                      }}
                    >
                      {formik.values.breaks[1]?.start || "17:05:00"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        padding: "13px",
                        backgroundColor: "rgba(169, 169, 169, 0.1)",
                        borderRadius: "10px",
                      }}
                    >
                      {formik.values.breaks[1]?.end || "18:05:00"}
                    </Typography>
                  </Grid>

                  {/* <Grid item xs={3}>
                                        <CustomInput
                                            value={formik.values.breaks[1]?.start}
                                            onChange={(e) => {
                                                let breaks = formik.values.breaks
                                                breaks[1].start = e.target.value
                                                formik.setFieldValue('breaks', breaks)
                                            }} type="time" label={""} subLabel={""} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CustomInput
                                            value={formik.values.breaks[1]?.end}
                                            onChange={(e) => {
                                                let breaks = formik.values.breaks
                                                breaks[1].end = e.target.value
                                                formik.setFieldValue('breaks', breaks)
                                            }}
                                            type="time" label="" subLabel={""} />
                                    </Grid> */}
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <IconButton
                      sx={{
                        color: "#ef5350",
                        marginLeft: "8px",
                        "&:hover": {
                          backgroundColor: "rgba(241, 66, 244, 0.1)",
                        },
                      }}
                      // onClick={() => {
                      //     let breaks = formik.values.breaks
                      //     let newBreaks = breaks?.filter((_, ind) => index !== ind)
                      //     formik.setFieldValue('breaks', newBreaks)
                      // }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} sx={{ mt: 0, pt: -4 }}></Grid>
                </Grid>

                <Grid item xs={12} sx={{ mt: 5 }}>
                  <CustomInput
                    name="note"
                    helperText={formik.touched.note && formik.errors.note}
                    error={Boolean(formik.touched.note && formik.errors.note)}
                    onChange={formik.handleChange}
                    value={formik.values.note}
                    onBlur={formik.handleBlur}
                    multiline
                    rows={7}
                    fullWidth
                    label="Tasks & Notes"
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }} className="mt-2">
                  <CustomInput
                    name="label"
                    helperText={
                      formik.touched.label && formik.errors.label
                        ? formik.errors.label
                        : ""
                    }
                    error={Boolean(formik.touched.label && formik.errors.label)}
                    onChange={formik.handleChange}
                    value={formik.values.label}
                    onBlur={formik.handleBlur}
                    fullWidth
                    label="Assignee Name"
                    type="text"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  className="mt-2"
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <CustomButton
                    onClick={(e) => {
                      console.log("do something");
                    }}
                    style={{
                      textColor: "white",
                      padding: "15px 20px",
                    }}
                  >
                    Save
                  </CustomButton>
                </Grid>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Slide>
  );
}

const mainPageStyle = {
  background: "transparent",
};
const cardStyle = {
  m: "20px 170px",
  borderRadius: "10px",
};
