import React from "react"
const ResumeIframe = ({setIframeLoaded}) => {
    return (
        <iframe
            onLoad={() => setIframeLoaded(false)}
            src="https://resumebuilder.indeed.com"
            allowFullScreen="true"
            width="100%"
            height="490px"
            style={{
                border: 0,
                zIndex: 10
            }}
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
    )
}
export default ResumeIframe