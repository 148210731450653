import React from 'react';
import Loading from '../common/loading';
import startCase from 'lodash.startcase'
import { deleteBudgetEntry } from '../_api/index'
import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
export default function DeleteModel(props) {
    const queryClient = useQueryClient();
    const { isLoading: isLoading, isSuccess: isSuccess, mutate: delTransaction } = useMutation(deleteBudgetEntry, {
        onSuccess: () => {
            queryClient.invalidateQueries('budgeting-calculators');
            toast.success("Transaction deleted successfully")
        },
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            id: props.id,
        }
        delTransaction(data);
    }
    if (isLoading && !isSuccess) {
        return (<Loading />)
    } else
        return (
            <div key={props.id} className="modal fade action-sheet" id={"delMod" + props.id} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content income-inner-content m-auto">
                        <div className="modal-header">
                            <h2 className="modal-title text-dark">{startCase(props.title)}</h2>
                        </div>
                        <div className="modal-body overflow-hidden">
                            <div className="row py-3">
                                <h5 className='delete_para_mob' style={{ maxWidth: 'fit-content' }}>Are you sure you want to delete this transaction?</h5>
                            </div>
                            <div className=" py-3 mb-2">
                                <button data-bs-dismiss="modal" className="btn btn-lg btn-red btn-block" onClick={(e) => handleSubmit(e)}>Yes</button>
                                <button data-bs-dismiss="modal" className=" mt-2 btn btn-lg btn-primary  btn-block"
                                    style={{ borderRadius: '12px' }}>No</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>);
}
