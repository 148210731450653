import { useState } from "react";
import LoaderComp from "../../common/loaderComp"

const Shadowing = () => {
    const [isLoadIframe, setIframeLoaded] = useState(false);
    const [tabRef, setTabRef] = useState("jobs")
    const isIframe = sessionStorage.getItem('isIframe');

    return (
        <div style={{ marginTop: isIframe !== "true"  ? 200 : 35, overflow:"hidden"}}>
            <div
                className="modal-content"
                style={{
                    maxWidth: "100%",
                    minHeight:"800px"
                }}
            >
                <div className="modal-body pb-3">
                    {/* <div className="extraHeader bg-primary"> */}
                    <ul className="nav nav-tabs lined" role="tablist">
                        <li
                            className="nav-item"
                            onClick={() => setTabRef('jobs')}
                        >
                            <a
                                className={
                                    tabRef === "food" ? "nav-link1 active" : "nav-link1"
                                }
                                style={{ cursor: "pointer" }}
                                // data-bs-toggle="tab"
                                href="#jobs"
                                role="tab"
                            >
                                Jobs
                            </a>
                        </li>
                        <li
                            className="nav-item"
                            // onClick={() => navigate("/net-worth?tab=assets")}
                            onClick={() => setTabRef('food')}
                        >
                            <a
                                className={
                                    tabRef === "jobs" ? "nav-link1 active" : "nav-link1"
                                }
                                // data-bs-toggle="tab"
                                style={{ cursor: "pointer" }}
                                href="#food"
                            // role="tab"
                            >
                                Fast Food Cashier
                            </a>
                        </li>
                    </ul>
                    {/* </div> */}

                    <div id="appCapsule" className="extra-header-active full-height p-0">
                        <div className="section tab-content p-0 m-0">
                            {/* summary tab */}
                            <div
                                className={
                                    tabRef === "jobs"
                                        ? "tab-pane fade show active"
                                        : "tab-pane fade"
                                }
                                id="jobs"
                                role="tabpanel"
                            >
                                {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
                                <iframe
                                    onLoad={() => setIframeLoaded(false)}
                                    src="https://unity.stickball.biz/jobs"
                                    allowFullScreen="true"
                                    width="100%"
                                    height="490px"
                                    style={{
                                        border: 0,
                                        zIndex: 10
                                    }}
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                ></iframe>

                            </div>

                            <div
                                className={
                                    tabRef === "food"
                                        ? "tab-pane fade show active"
                                        : "tab-pane fade"
                                }
                                id="food"
                                role="tabpanel"
                            >
                                {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
                                <iframe
                                    onLoad={() => setIframeLoaded(false)}
                                    src="https://unity.stickball.biz/fast-food"
                                    allowFullScreen="true"
                                    width="100%"
                                    height="490px"
                                    style={{
                                        border: 0,
                                        zIndex: 10
                                    }}
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                ></iframe>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Shadowing