import React from 'react';
import { useNavigate } from 'react-router-dom'
// import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
export default function Header({ onGoBack }) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    // const { t } = useTranslation()
    const navigate = useNavigate()
    return (<>
        <Box className="appHeader p-2" sx={{ justifyContent: { sm: "center", xs: "flex-end" } }}>
            <div className="left">
                <div onClick={onGoBack ? onGoBack : () => navigate(-1)} className="headerButton goBack">
                    <ion-icon name="chevron-back-outline"> </ion-icon>
                    {
                        !isMobile &&
                        <p style={{ margin: '0 20px', cursor: 'pointer' }}>{("goBack")}</p>
                    }

                </div>
            </div>
            <div className="pageTitle">
                financeCalculator
            </div>
            <div className="right">
            </div>
        </Box >
    </>)
}
