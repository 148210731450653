import React from "react";
import moment from "moment";
import "./style.css";

export default function GoalScale(props) {
  const renderFutureDate = (endDate) => {
    const { dob } = props.user;
    const m = moment(endDate);
    const y = m.diff(dob, "years");
    const mt = m.diff(dob, "months");
    const fixMonth = mt - y * 12;

    return `${y}Y ${fixMonth ? fixMonth + "M" : ""}`;
  };

  return (
    <div className="range-selector">
      {props.data?.map((card) => {
        return (
          <div className="handle-candle" key={card.id}>
            <div className="small-candle"></div>
            <div className="small-candle"></div>
            <div className="small-candle"></div>
            <div className="small-candle"></div>
            <div className="small-candle"></div>
            <div className="candle colored">
              <p style={{ fontSize: "16px" }}>
                {renderFutureDate(card?.endDate)}
              </p>
            </div>
            <div className="small-candle"></div>
            <div className="small-candle"></div>
            <div className="small-candle"></div>
            <div className="small-candle"></div>
            <div className="small-candle"></div>
            <div className="small-candle"></div>
          </div>
        );
      })}
    </div>
  );
}
