import React, { useState } from "react";
import { Link, Router } from "react-router-dom";
import Loading from "../common/loading";
import { addNewBudgetEntry } from "../_api/index";
import { useMutation, useQueryClient } from "react-query";
import CurrencyFormat from "react-currency-format";

import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Input from "@mui/material/Input";

import CloseIcon from "@mui/icons-material/Close";
import toast from 'react-hot-toast';
export default function ValueChangeModal(props) {
  const queryClient = useQueryClient();
  const {
    isLoading: isLoading,
    isSuccess: isSuccess,
    mutate: addAmount,
  } = useMutation(addNewBudgetEntry, {
    onSuccess: () => {
      queryClient.invalidateQueries("budgeting-calculators");
      toast.success("Added Successfully");
      if (props?.handleClose) props?.handleClose()
    },
    onError:()=>{
      toast.error("Something went wrong!")
    }
  });

  const [valueInput, setValueInput] = useState(0);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(null);
  const [recuring, setRecuring] = useState(false);
  const [recuringError, setRecuringError] = useState(null);
  const [frequency, setFrequency] = useState("monthly");
  const [frequencyError, setFrequencyError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("")
  const [error, setError] = useState(true)

  const handleSubmit = (e) => {
    if (!description || !valueInput) {
      setError(true)
      setErrorMessage("Please fill all the fields")
      return
    } else {
      setError(false)
      setErrorMessage("")
    }
    e.preventDefault();
    const data = {
      amount: parseInt(valueInput),
      mainCategory: "ManageBudget",
      subCategory: props.category,
      type: props.type,
      description: description,
      isRecurring: recuring,
      frequency: recuring ? frequency : "",
    };
    addAmount(data);
  };
  if (isLoading && !isSuccess) {
    return <Loading />;
  } else
    return (
      <>
        <div
          style={{ zIndex: 300000, background: "rgb(0 0 0 / 45%)" }}
          key={props.id}
          className="modal fade action-sheet"
          id={props.id}
          tabIndex="-1"
          role="dialog"
        >
          <div
            className="modal-dialog  py-3 d-flex"
            role="document"
            style={{ height: "100%" }}
          >
            <div className="modal-content mx-auto my-auto income-inner-content" style={{ height: "fit-content" }}>
              <div className="modal-header" style={{ borderColor: 'transparent' }}>
                <h2
                  className="modal-title1"
                >{props.title}</h2>
              </div>
              <div
                className="modal-body"
                id="style-3"
                style={{ height: "100%", overflowX: "unset" }}
              >
                <h3 className="text-muted" style={{ fontSize: 15 }}>Description</h3>
                <Input className="w-100 description_input"
                  onChange={(e) => {
                    if (valueInput && e.target.value) {
                      setError(false)
                    } else {
                      setError(true)
                    }
                    setDescription(e.target.value)
                  }} />
                <div className="row">
                  <h3 className="text-muted pb-2" style={{ fontSize: 15 }}>Enter a value</h3>

                  <div className="action-sheet-content py-0 overflow-hidden">
                    <div className="form-group basic changing-modal">
                      <span
                        className="dialog-btn"
                        onClick={() => {
                          if (description && valueInput) {
                            setError(false)
                          } else {
                            setError(true)
                          }
                          setValueInput(
                            valueInput > 0 ? parseInt(valueInput) - 1 : 1
                          );
                        }}
                      >
                        <b>-</b>
                      </span>
                      <div className="input-group mb-2">
                        <CurrencyFormat
                          value={
                            valueInput !== 0 &&
                              valueInput !== "0" &&
                              valueInput !== ""
                              ? valueInput
                              : ""
                          }
                          thousandSeparator={true}
                          prefix={"$"}
                          onValueChange={(values) => {

                            const { value } = values;
                            if (description && value) {
                              setError(false)
                            } else {
                              setError(true)
                            }
                            setValueInput(value);
                          }}
                          style={{ textAlign: "center", fontSize: "30px" }}
                          className="form-control text-primary"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          type="text"
                          name="creditcard"
                          placeholder="$0"
                        // required
                        />
                      </div>
                      <span
                        className="dialog-btn"
                        onClick={() => {
                          if (description && valueInput) {
                            setError(false)
                          } else {
                            setError(true)
                          }
                          setValueInput(
                            valueInput > 0 ? parseInt(valueInput) + 1 : 1
                          );
                        }}
                      >
                        <b>+</b>
                      </span>
                    </div>
                    {
                      error &&
                      <p className="text-danger">{errorMessage}</p>
                    }
                    <div data-bs-dismiss={!error && "modal"} style={{ marginTop: '-20px', marginBottom: '-20px' }}>
                      <button
                        onClick={handleSubmit}
                        className="btn btn-primary btn_amount btn-block btn-lg "
                      >
                        Set Amount
                      </button>
                    </div>
                  </div>
                </div>
                <hr className="dolor_bottom_line" />
                <ul>
                  <li>
                    <FormControlLabel
                      className="checkbox-recuring"
                      control={<Checkbox />}
                      label="Is It Recurring?"
                      onChange={(e) => {
                        recuring !== "" ? setRecuringError(false) : setRecuringError(true)
                        setRecuring(e.target.checked)
                      }}
                    />
                  </li>
                  <li>
                    <select
                      className="form-select select_frequency"
                      aria-label="Default select example"
                      disabled={!recuring}
                      defaultValue={frequency}
                      required={recuring}
                      onChange={(e) => {
                        frequency !== "" ? setFrequencyError(false) : setFrequencyError(true)
                        setFrequency(e.target.value)
                      }}
                    >
                      {/* <option selected>Select A Frequency</option> */}
                      <option value="weekly">Weekly</option>
                      <option value="everyOtherWeek">Every Other Week</option>
                      <option value="twiceAMonth">Twice A Month</option>
                      <option value="monthly">Monthly</option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{ zIndex: 300000 }} key={props.id} className="modal fade action-sheet" id={props.id} tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document" style={{ boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.20)' }}>
              <div className="modal-content">
                  <div className="modal-header">
                      <h5 className="modal-title">{props.title}</h5>
                      <div className="d-flex justify-content-between">
                          <p>Enter a value</p>
                          <CloseIcon data-bs-dismiss="modal" style={{ marginTop: "-30px" }} />
                      </div>
                  </div>
                  <div className="modal-body">
                      <div className="action-sheet-content">
                          <div className="form-group basic changing-modal">
                              <span className="dialog-btn" onClick={() => { setValueInput(valueInput > 0 ? parseInt(valueInput) - 1 : 1) }}><b>-</b></span>
                              <div className="input-group mb-2">
                                  <CurrencyFormat
                                      value={valueInput !== 0 && valueInput !== "0" && valueInput !== "" ? valueInput : ""}
                                      thousandSeparator={true} prefix={'$'}
                                      onValueChange={(values) => {
                                          const { value } = values;
                                          setValueInput(value)
                                      }}
                                      style={{ textAlign: 'center', fontSize: "30px" }}
                                      className="form-control text-primary"
                                      inputmode="numeric" pattern="[0-9]*" type="text" name="creditcard"
                                      placeholder="$0"
                                  // required
                                  />

                              </div>
                              <span className="dialog-btn" onClick={() => { setValueInput(valueInput > 0 ? parseInt(valueInput) + 1 : 1) }}><b>+</b></span>
                          </div>
                          <div data-bs-dismiss="modal" className="text-center m-3">
                              <Link to={props.historyLink ? props.historyLink + "/" + props.category : 0} className="text-center text-primary">{props.linkText ? props.linkText : ""}</Link> <br />
                              <Link to="/edit-bill" className="text-center text-primary"><b>{props.editBill ? "Edit Bill" : ""}</b></Link>
                          </div>
                          <div data-bs-dismiss="modal" className="form-group basic">
                              <button onClick={handleSubmit} className="btn btn-primary btn-block btn-lg"
                              >Set Amount</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> */}
      </>
    );
}
