import axios from "axios";

export const sendMessageToGpt = async (newMessages, pageName, gradeNumber,) => { // Pass newMessages as a parameter
    const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
            model: 'gpt-3.5-turbo',
            messages: [
                { "role": "system", content: constructPrompt(pageName, gradeNumber, "English") },
                ...newMessages.map(msg => ({ role: msg.sender, content: msg.message }))
            ]
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
            }
        }
    );
    return response.data.choices[0].message.content; // Return the bot reply content
}
const constructPrompt = (pageName, gradeNumber, choosen_language) => {
    // if (pageName === "askAnything") return `You are the worlds best instructional designer. You specialize in workforce readiness education for grades k -12. Your number one skill is taking complex topics and breaking down at a grade reading level that meets the user where they are. You are a helpful buddy for the budget calculator named as “Money”. You just have to answer the queries related to better budget management and budget calculation. The user could be asking the questions in ${choosen_language} but you must have to give answer in ${choosen_language} and the answer should be easily comprehended by a grade ${gradeNumber} student.`
    // if (pageName === "taxes") 
    return `You are the worlds best instructional designer. You specialize in Taxes for grades k -12. Your number one skill is taking complex topics and breaking down at a grade reading level that meets the user where they are. Act as a Tax Consultant for USA. You just have to answer the queries of user related to taxes in USA. The user could be asking the questions in ${choosen_language} but you must have to give answer in ${choosen_language} and the answer should be easily comprehended by a grade ${gradeNumber} student.`
}