import React, { useState, useEffect } from "react";
import { Box, Card, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Layout from "./Layout";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import CardRapper from "./tabs/cardRapper";
import CalTab from "./tabs";
import {
  ListL_LG,
  renderFormatData,
  NumericFormat,
  RecalculateButton,
  TextField,
  PieChart,
} from "./tabs/list";
import Footer from "../footer";
import { useTranslation } from "react-i18next";

export default function CarAfford() {
  const { t } = useTranslation();
  const [downAfford, setDownAfford] = useState(null);
  const [duration, setDuration] = useState(null);
  const [salesTax, setSalesTax] = useState(null);
  const [tradeValue, setTradeValue] = useState(null);
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [interestRate, setInterestRate] = useState(null);
  const [vehicleRebates, setVehicleRebates] = useState(null);
  const [perMonthPay, setPerMonthPay] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [results, setResults] = useState([]);
  const theme = useTheme();
  const trials = [
    {
      label: "one Year",
      value: 12,
    },
    {
      label: "two Years",
      value: 24,
    },
    {
      label: "three Years",
      value: 36,
    },
    {
      label: "four Years",
      value: 48,
    },
    {
      label: "five Years",
      value: 60,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    var downPayment = Number(downAfford.replace(/[^0-9.-]+/g, ""));
    var tradein = Number(tradeValue.replace(/[^0-9.-]+/g, ""));
    var rebates = Number(vehicleRebates.replace(/[^0-9.-]+/g, ""));

    var loanTermMonths = duration;
    var payment = Number(perMonthPay.replace(/[^0-9.-]+/g, ""));
    var ir = Number(interestRate.replace(/[^0-9.-]+/g, ""));
    var st = Number(salesTax.replace(/[^0-9.-]+/g, ""));

    var total_down = downPayment + tradein + rebates;
    var i = ir / 100.0 / 12.0;
    var st = st / 100.0;
    var n = loanTermMonths;
    var loan = 0.0;

    if (ir > 0) {
      var tmp = (1.0 / i) * (1.0 - Math.pow(1.0 / (1.0 + i), n));
      loan = payment * tmp;
    } else {
      loan = payment * loanTermMonths;
    }

    var afford = (loan + total_down) / (1.0 + st);
    var sales_tax = afford * st;
    var cost_car = afford;
    var loan_amount = afford + sales_tax - total_down;
    let res = [
      {
        title: "cost of Car",
        value: cost_car,
      },
      {
        title: "down Payment Plus",
        value: total_down,
      },
      {
        title: "tax",
        value: sales_tax,
      },
      {
        title: "loan Amount",
        value: loan_amount,
      },
    ];
    setResults(res);
    var totalPrinciple = loan;
    var totalPayments = payment * loanTermMonths;
    var totalInterest = totalPayments - totalPrinciple;

    setShowResults(true);
    let temp = [
      {
        color: "#4898FF",
        data: totalInterest,
        label: "interest Paid",
      },
      {
        color: "#51F074",
        data: totalPrinciple,
        label: "pricipal Paid",
      },
    ];
    setChartData(temp);
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <NumericFormat
        className="card-input"
        label="How much do you have saved for a down payment?"
        required
        variant="standard"
        value={downAfford}
        onChange={(e) => {
          setDownAfford(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Trade-in value of your current vehicle"
        required
        variant="standard"
        value={tradeValue}
        onChange={(e) => {
          setTradeValue(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Vehicle rebates"
        variant="standard"
        value={vehicleRebates}
        onChange={(e) => {
          setVehicleRebates(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <TextField
        className="card-input"
        select
        label="How quickly do you want to pay off your car?"
        required
        variant="standard"
        value={duration}
        onChange={(e) => {
          setDuration(e.target.value);
        }}
      >
        {trials.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <NumericFormat
        className="card-input"
        label="How much can you afford to pay per month for a car?"
        required
        variant="standard"
        value={perMonthPay}
        onChange={(e) => {
          setPerMonthPay(e.target.value);
        }}
        prefix="$"
        thousandSeparator=","
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="What interest rate do you think you can qualify for on a loan?"
        required
        variant="standard"
        value={interestRate}
        onChange={(e) => {
          setInterestRate(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <NumericFormat
        className="card-input"
        label="Sales tax in the county in which you live"
        required
        variant="standard"
        value={salesTax}
        onChange={(e) => {
          setSalesTax(e.target.value);
        }}
        suffix="%"
        customInput={TextField}
      />
      <Box display="flex" justifyContent="center" mt="1.5rem">
        <RecalculateButton
          btnText="calculate"
          type={"submit"}
          className={"btn btn-calculate"}
        />
      </Box>
    </form>
  );

  const renderResultTabs = () => {
    console.log(chartData);

    return (
      <>
        <Box>
          <Card
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: 1,
            }}
          >
            <PieChart data={chartData} />
          </Card>

          <Box>
            {results?.map((item, i) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: theme.palette.common.background,
                  p: 1,
                  mt: 1,
                  borderRadius: 1,
                }}
              >
                <Typography>{item?.title}</Typography>
                <Typography>{renderFormatData(item.value)}</Typography>
              </Box>
            ))}
            <Box mt={2} textAlign="center">
              <RecalculateButton
                onClick={() => {
                  setShowResults(false);
                }}
              />
            </Box>
          </Box>
        </Box>
        {/* <CalTab setTabValue={setTabValue} tabValue={tabValue}>
      {tabValue === "Results" && (
        <Grid item xs={12} md={12} sx={{ mt: 3 }}>
          <List>
            {results?.map((item, i) => (
              <ListL_LG
                key={i}
                left={item?.title}
                right={renderFormatData(item.value)}
              />
            ))}
            <Divider />
          </List>
        </Grid>
      )}
      {tabValue === "Graph" && <PieChart data={chartData} />}
      <RecalculateButton
        onClick={() => {
          setShowResults(false);
        }}
      />
    </CalTab> */}
      </>
    );
  };

  return showResults ? renderResultTabs() : renderForm();
}
