import React from 'react'

const CryptoIcon = ({color}) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.8805 9.12677C14.8805 8.70334 14.5372 8.36011 14.1138 8.36011C13.6904 8.36011 13.3471 8.70334 13.3471 9.12677V10.1125H11.2662C10.8427 10.1125 10.4995 10.4558 10.4995 10.8791C10.4995 11.3026 10.8427 11.6458 11.2662 11.6458H11.3757V18.8744H11.2662C10.8427 18.8744 10.4995 19.2176 10.4995 19.6411C10.4995 20.0645 10.8427 20.4077 11.2662 20.4077H13.3471V21.3934C13.3471 21.8169 13.6904 22.1601 14.1138 22.1601C14.5372 22.1601 14.8805 21.8169 14.8805 21.3934V20.4077H15.5376V21.3934C15.5376 21.8169 15.8809 22.1601 16.3043 22.1601C16.7277 22.1601 17.071 21.8169 17.071 21.3934V20.4057C18.6534 20.3481 19.9185 19.0471 19.9185 17.4506C19.9185 16.5821 19.5442 15.8011 18.948 15.2601C19.5442 14.7191 19.9185 13.9381 19.9185 13.0696C19.9185 11.4731 18.6534 10.1721 17.071 10.1145V9.12677C17.071 8.70334 16.7277 8.36011 16.3043 8.36011C15.8809 8.36011 15.5376 8.70334 15.5376 9.12677V10.1125H14.8805V9.12677ZM12.909 11.6458H16.9614C17.7478 11.6458 18.3852 12.2833 18.3852 13.0696C18.3852 13.856 17.7478 14.4934 16.9614 14.4934H12.909V11.6458ZM12.909 16.0268H16.9614C17.7478 16.0268 18.3852 16.6642 18.3852 17.4506C18.3852 18.2369 17.7478 18.8744 16.9614 18.8744H12.909V16.0268Z" fill={color}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.1001 3.76001C8.74884 3.76001 3.6001 8.90875 3.6001 15.26C3.6001 21.6113 8.74884 26.76 15.1001 26.76C21.4514 26.76 26.6001 21.6113 26.6001 15.26C26.6001 8.90875 21.4514 3.76001 15.1001 3.76001ZM5.13343 15.26C5.13343 9.75557 9.59566 5.29334 15.1001 5.29334C20.6045 5.29334 25.0668 9.75557 25.0668 15.26C25.0668 20.7644 20.6045 25.2267 15.1001 25.2267C9.59566 25.2267 5.13343 20.7644 5.13343 15.26Z" fill={color}/>
</svg>

  )
}

export default CryptoIcon