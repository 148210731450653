import React, { useEffect, useState }  from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { CustomTextButton } from "../Button";
import {
  Button,
  Typography,
} from "@mui/material";
import { Modal } from "@mui/material";
import { loading_iframe } from "../assets";
import LoaderComp from "../common/loaderComp";

export const InitialContractModal = (props) => {
  const [isLoadIframe, setIsLoadIframe] = useState(false)
  useEffect(() => {
    setIsLoadIframe(true)
  },[props.open])
  return(
  <>
  <Modal
          open={props.open}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{
            maxWidth: "100%",
            display:"flex",
            padding: "0px 100px 0px 100px",
            marginTop: '5%',
display:'flex',
justifyContent:'center',
            justifyContent:"center"
          }}
          onClose={props.close}
        >
          <div
            className="modal-content"
            style={{
              // maxWidth: "100%",
              height: '90%'
            }}
          >
            {/* <div className="modal-header">
              <Button sx={{
                fontWeight: 'bold',
                textTransform: 'capitalize',
                border: '1px solid #616DED',
                borderRadius: "10px",
              }} variant='outlined' 
              // onClick={() => { setShowSkillModal(true); setPartName("document-ai") }}
              >Skill Assignment</Button>
              <div className="btn-inline">
                <div className="text-primary bg-white">
                  <CloseIcon onClick={() => setShowDocumentModal(false)} color='red' />
                </div>
              </div>
            </div> */}
            <div className="modal-body pb-3">
              {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe}/>}
              <iframe
                onLoad={() => setIsLoadIframe(false)}
                src={"https://document-ocr.stickball.biz/"}
                allowFullScreen="true"
                width="100%"
                height="100%"
                style={{
                  border: 0,
                  zIndex: 10
                }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            {/* <SkillAssignments user={props?.user?.id} open={showSkillmodal} setOpen={setShowSkillModal} partName={partName} /> */}
          </div>
        </Modal >
</>
  );
}
