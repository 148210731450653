import * as Axios from 'axios';

const API_URL = process.env.REACT_APP_API_LINK;
const QUOTE_API_URL = "https://quotes.rest/qod";
const axios = Axios.create({
    'headers': { 'x-api-key': process.env.REACT_APP_X_API_KEY }
})

// getting all the data from api
export const getAllData = async () => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.get(`${API_URL}/budgeting-calculator/list`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        return res.data;
    }
    // return staticResponse

}

// getting all the data from api
export const getStockData = async () => {
    const res = await axios.get(`${API_URL}/budgeting-calculator/stock`,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}


//geting quote from api
export const getQuote = async () => {
    const quote = await axios.get(QUOTE_API_URL);
    return quote.data;
}
//updating a new entry in api
export const updateBudgetEntry = async (data) => {
    let id = data.id
    delete data.id
    const res = await axios.put(`${API_URL}/budgeting-calculator/${id}`, data,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}

//deleting an en entry from api
export const deleteBudgetEntry = async (data) => {
    const res = await axios.delete(`${API_URL}/budgeting-calculator/${data.id}`,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}

//adding new item to the api
export const addNewBudgetEntry = async (data) => {
    delete data.id
    const res = await axios.post(`${API_URL}/budgeting-calculator`, data,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}

export const tokenInit = async (data) => {
    const res = await axios.post(`${API_URL}/budgeting-calculator/init`, data,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}



// Financial Goals

export const getFinancialGoals = async () => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.get(`${API_URL}/financial-goal`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        return res.data;
    }

}
export const getFinancialGoalsTrash = async () => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.get(`${API_URL}/financial-goal?trash=true&status=trash`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        return res.data;
    }

}
export const setFinancialGoals = async (data) => {
    const res = await axios.post(`${API_URL}/financial-goal`, data,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}

export const putFinancialGoals = async (data) => {
    let header = {
        status: data.status
    }
    const res = await axios.patch(`${API_URL}/financial-goal/${data.id}`, header,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}
export const putFinancialGoalsFull = async (data) => {
    let id = data.id
    delete data.id

    const res = await axios.patch(`${API_URL}/financial-goal/${id}`, data,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}
export const deleteFinancialGoals = async (data) => {
    const res = await axios.delete(`${API_URL}/financial-goal/${data.id}`,
        {
            headers: {
                'Authorization': `Stickball ${sessionStorage.getItem('token')}`
            }
        });
    return res.data;
}

export const resetCalculator = async () => {
    if (sessionStorage.getItem("token") !== null) {
        const res = await axios.delete(`${API_URL}/budgeting-calculator/reset-my-calculator`,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            });
        return res.data;
    }

}


const staticResponse = [
    {
        "id": 111,
        "name": null,
        "amount": 2000,
        "mainCategory": "ManageBudget",
        "subCategory": "MonthlyIncome",
        "type": "Add",
        "description": "Income",
        "frequency": "",
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-23T12:23:46.983Z",
        "updatedAt": "2024-08-23T12:23:46.983Z"
    },
    {
        "id": 12,
        "name": null,
        "amount": 2100,
        "mainCategory": "ManageBudget",
        "subCategory": "MonthlyIncome",
        "type": "Add",
        "description": "Income",
        "frequency": "",
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-24T12:23:46.983Z",
        "updatedAt": "2024-08-24T12:23:46.983Z"
    },
    {
        "id": 13,
        "name": null,
        "amount": 800,
        "mainCategory": "ManageBudget",
        "subCategory": "MonthlyIncome",
        "type": "Add",
        "description": "Income",
        "frequency": "",
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-22T12:23:46.983Z",
        "updatedAt": "2024-08-22T12:23:46.983Z"
    },
    {
        "id": 15,
        "name": null,
        "amount": 8000,
        "mainCategory": "ManageBudget",
        "subCategory": "MonthlyIncome",
        "type": "Add",
        "description": "Income",
        "frequency": "",
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-25T12:23:46.983Z",
        "updatedAt": "2024-08-25T12:23:46.983Z"
    },
    {
        "id": 2,
        "name": null,
        "amount": 3000,
        "mainCategory": "ManageBudget",
        "subCategory": "CashAndCheck",
        "type": "Add",
        "description": "adsfads ",
        "frequency": "",
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-23T12:23:55.254Z",
        "updatedAt": "2024-08-23T12:23:55.254Z"
    },
    {
        "id": 3,
        "name": null,
        "amount": 3000,
        "mainCategory": "ManageBudget",
        "subCategory": "Checking",
        "type": "Add",
        "description": "vdsfg",
        "frequency": "",
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-23T12:24:02.351Z",
        "updatedAt": "2024-08-23T12:24:02.351Z"
    },
    {
        "id": 4,
        "name": null,
        "amount": 4000,
        "mainCategory": "ManageBudget",
        "subCategory": "AutoAndTransport",
        "type": "Delete",
        "description": "sdfadsf",
        "frequency": "",
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-23T12:24:12.802Z",
        "updatedAt": "2024-08-23T12:24:12.802Z"
    },
    {
        "id": 5,
        "name": null,
        "amount": 1888,
        "mainCategory": "ManageBudget",
        "subCategory": "Education",
        "type": "Delete",
        "description": "cvbxfb",
        "frequency": "",
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-23T12:24:24.847Z",
        "updatedAt": "2024-08-23T12:24:24.847Z"
    },
    {
        "id": 6,
        "name": null,
        "amount": 5098,
        "mainCategory": "ManageBudget",
        "subCategory": "Fees",
        "type": "Delete",
        "description": "dfgdfg",
        "frequency": "",
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-23T12:24:47.871Z",
        "updatedAt": "2024-08-23T12:24:47.871Z"
    },
    {
        "id": 7,
        "name": "Cash",
        "amount": 399,
        "mainCategory": "NetWorth",
        "subCategory": "Cash",
        "type": "Add",
        "description": "hjfgkghjk",
        "frequency": null,
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-23T12:25:12.427Z",
        "updatedAt": "2024-08-23T12:25:12.427Z"
    },
    {
        "id": 8,
        "name": "Bank",
        "amount": 48887,
        "mainCategory": "NetWorth",
        "subCategory": "Bank",
        "type": "Add",
        "description": "hjfghj",
        "frequency": null,
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-23T12:25:19.502Z",
        "updatedAt": "2024-08-23T12:25:19.502Z"
    },
    {
        "id": 9,
        "name": "Savings",
        "amount": 565,
        "mainCategory": "NetWorth",
        "subCategory": "Savings",
        "type": "Add",
        "description": "6tyurty",
        "frequency": null,
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-23T12:25:25.702Z",
        "updatedAt": "2024-08-23T12:25:25.702Z"
    },
    {
        "id": 10,
        "name": "Credit Card",
        "amount": 56564,
        "mainCategory": "NetWorth",
        "subCategory": "CreditCard",
        "type": "Delete",
        "description": "dhfghd",
        "frequency": null,
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-23T12:25:35.859Z",
        "updatedAt": "2024-08-23T12:25:35.859Z"
    },
    {
        "id": 11,
        "name": "Student Loan",
        "amount": 455,
        "mainCategory": "NetWorth",
        "subCategory": "StudentLoan",
        "type": "Delete",
        "description": "ghdfg",
        "frequency": null,
        "isRecurring": false,
        "userId": 2,
        "createdAt": "2024-08-23T12:25:45.269Z",
        "updatedAt": "2024-08-23T12:25:45.269Z"
    }
]