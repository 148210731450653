import AcUnitIcon from '@mui/icons-material/AcUnit';
import { Link } from 'react-router-dom';
import Header from './header'
function LinkedAccounts() {
    return (<>
        <Header />
        <div className="container">
            <h2 className="mt-5 pt-5">Linked Accounts</h2>
            <p className="mt-3">Link all of your financial accounts to get a complete view of your finances</p>
            <Link to="/select-account" className="text-primary mt-2">+Add Account</Link>
            <div className="row text-center">
                <div className="text-success text-center mt-5"><AcUnitIcon style={{ fontSize: '50px' }} /></div>
                <h2 className="mt-2">Find your subscriptions</h2>
                <p>Link your bank or credit card statement and we'll find your subscriptions for you</p>
                <button className="btn btn-lg btn-primary btn-block mt-5" onClick={() => { }}>Link an Account</button>
            </div>

        </div>

    </>);
}

export default LinkedAccounts;