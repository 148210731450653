import * as Axios from "axios";

const API_URL = "https://sb-resume-analyzer.web.musketeers.dev";
const axios = Axios.create();

export const resumeAnalyzer = (title, description, resume) => {
  return new Promise(async (res, rej) => {
    let formData = new FormData();
    formData.append("resume_file", resume);
    try {
      const resp = await axios.post(
        `${API_URL}/resume_analyzer${title ? "?job_title=" + title : ""}${
          description ? "&job_description=" + description : ""
        }`,
        formData
      );
      return res(resp.data);
    } catch (error) {
      rej(error);
    }
  });
};
