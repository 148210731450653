import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
export default function Header({onGoBack}) {
    const navigate = useNavigate()
    return (<>
        <div className="appHeader p-2">
            <div className="left">
                <div onClick={onGoBack ? onGoBack : () => navigate(-1)} className="headerButton goBack">
                <ion-icon name="chevron-back-outline"> </ion-icon> <p style={{margin:'0 20px' , cursor:'pointer'}}>Go Back</p>
                </div>
            </div>
            <div className="pageTitle">
                Boost-Why, What and How
            </div>
            <div className="right">
            </div>
        </div>
    </>)
}
