
import { Link } from 'react-router-dom';

function MCQs() {
    const questions = [
        {
            id: 1,
            question: "Which is a license plate number associated with a vehicle registered in your name ?",
            options: [
                {
                    id: 11,
                    option: "019GFH"
                },
                {
                    id: 21,
                    option: "966LST"
                },
                {
                    id: 31,
                    option: "7574JP"
                },
                {
                    id: 41,
                    option: "366TLD"
                },
                {
                    id: 51,
                    option: "NONE OF THE ABOVE/DOES NOT APPLY"
                },
            ],
            radio: "radio1",
        },
        {
            id: 2,
            question: "Which of the following is a current or previous employer ?", options: [
                {
                    id: 12,
                    option: "019GFH"
                },
                {
                    id: 22,
                    option: "966LST"
                },
                {
                    id: 32,
                    option: "7574JP"
                },
                {
                    id: 42,
                    option: "366TLD"
                },
                {
                    id: 52,
                    option: "NONE OF THE ABOVE/DOES NOT APPLY"
                },
            ],
            radio: "radio2",
        },
        {
            id: 3,
            question: "According to our records, you currently own/lease, or have owned/leased within the past year, one of the following vehicles. Select the vehicle purchase or leased prior to March 2018 that meets this criteria.", options: [
                {
                    id: 13,
                    option: "019GFH"
                },
                {
                    id: 23,
                    option: "966LST"
                },
                {
                    id: 33,
                    option: "7574JP"
                },
                {
                    id: 43,
                    option: "366TLD"
                },
                {
                    id: 53,
                    option: "NONE OF THE ABOVE/DOES NOT APPLY"
                },
            ],
            radio: "radio3",
        },
    ]
    return (<>
        <div className="appHeader">
            <div className="left">
                <a href="#" className="headerButton goBack" onClick={() => window.history.go(-1)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div className="pageTitle">

            </div>
        </div>
        <div className="card m-2 p-3">
            <div className="container mt-5 pt-5 px-4 pb-5">
                <h2>Answer the following questions</h2>
                <p>To verify your identity, there are a few multple choice questions to answer</p>
                <div className="row pb-5 mb-5">
                    {
                        questions.map((item, key) => {
                            return (<>
                                <div key={key} id="appCapsule" className="bg-white m-0 p-0 mt-3">
                                    <div className="listview-title p-0">Question No {item.id}</div>
                                    <label>
                                        {item.question}
                                    </label>
                                    {
                                        item.options.map((opt, key) => {
                                            return (<>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name={item.radio} id={opt.id} key={key} />
                                                    <label className="form-check-label" htmlFor={opt.id}>
                                                        {opt.option}
                                                    </label>
                                                </div>
                                            </>)
                                        })
                                    }
                                </div>
                            </>)
                        })
                    }
                </div>
            </div>
        </div>
        <div className="fixed-bar text-center">
            <Link to="/credit-activated" style={{ width: '100%' }}><button type="submit" className="btn btn-lg btn-primary btn-block mb-2">Continue</button></Link>
        </div>
    </>);
}

export default MCQs;