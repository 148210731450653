import React, { useState, useEffect, useRef } from 'react'
import Backdrop from '@mui/material/Backdrop';
import LinearProgress from '@mui/material/LinearProgress';
export default function Loading() {

    const [progress, setProgress] = useState(0);
    const [buffer, setBuffer] = useState(10);

    const progressRef = useRef(() => { });

    useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 100;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });
    useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 100);

        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <>
            <div >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                    open={true}
                    style={{ display: 'flex', flexDirection: 'column' }}
                    className="backdrop-loading"
                >
                    <div>
                        <LinearProgress size={50} variant="buffer" value={progress} valueBuffer={buffer} />
                    </div>
                    <img className="rotate-image" width="130px" src={process.env.REACT_APP_ENV === "stag" ? "" : "assets/img/logo-white.gif"} alt="" />
                    {/* <img className="rotate-image" width="130px" src={process.env.REACT_APP_ENV === "stag" ? "assets/img/ft-logo.png" : "assets/img/logo-white.gif"} alt="" /> */}
                </Backdrop>
            </div>
        </>
        // <div id="loader">
        //     <img src={publicUrl + '/assets/img/loading-icon.png'} alt="icon" className="loading-icon" />
        // </div>
    )
}
