// import Head from 'next/head'
import React, { useMemo, useState } from "react";
// import dynamic from 'next/dynamic'
import { v4 as uuidv4 } from "uuid";
import { saveSvgAsPng } from "save-svg-as-png";
import ReactWordcloud from "react-wordcloud";
import { Box, Button, Card, Grid, Radio, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokenizeWords } from "./nlp";
import { useEffect } from "react";
// const ReactWordcloud = dynamic(() => import('react-wordcloud'), {
//   ssr: false,
// })

export default function WordCloud(props) {
  const [randomSeed, setRandomSeed] = useState(uuidv4());
  const [string, setString] = useState(
    "Start building your word financial goal cloud"
  );
  const [totalWords, setTotalWords] = useState(250);
  const [orientationFrom, setOrientationFrom] = useState(-60);
  const [orientationTo, setOrientationTo] = useState(60);
  const [spiral, setSpiral] = useState("archimedean");
  const [mobile, setMobile] = useState(false);
  const [pc, setPC] = useState(true);
  const [render, setRender] = useState(true);
  const initialSettings = {
    content: {
      allowNumbers: false,
      maxWords: totalWords,
      stemmer: true,
      stopwordsInput: "",
    },
    wordcloud: {
      colors: [
        "#1f77b4",
        "#ff7f0e",
        "#2ca02c",
        "#d62728",
        "#9467bd",
        "#8c564b",
        "#e377c2",
        "#7f7f7f",
        "#bcbd22",
        "#17becf",
      ],
      fontFamily: "impact",
      fontSizes: [28, 144],
      padding: 1,
      rotations: 5,
      rotationAngles: [parseInt(orientationFrom), orientationTo],
      spiral: spiral,
      scale: "linear",
      transitionDuration: 500,
      // Non-configurable
      deterministic: true,
      enableOptimizations: true,
      enableTooltip: true,
    },
  };
  const [selectedWord, setSelectedWord] = useState(null);
  const { content: contentSettings, wordcloud: wordcloudSettings } =
    initialSettings;
  const words = useMemo(
    () => tokenizeWords(string, contentSettings),
    [string, contentSettings]
  );
  const wordcloudOptions = useMemo(
    () => ({
      ...wordcloudSettings,
      randomSeed,
    }),
    [wordcloudSettings, randomSeed]
  );
  const wordcloudCallbacks = useMemo(
    () => ({
      onWordClick: (word) => setSelectedWord(word.text),
    }),
    []
  );

  function handleSave() {
    const svgElement = document.querySelector("#word-cloud-save svg");
    saveSvgAsPng(svgElement, "wordcloud.png");
  }

  function handleAnimate() {
    setRandomSeed(uuidv4());
    setSelectedWord();
  }

  useEffect(() => {
    setString(props.string);
  }, [props.string]);
  const theme = useTheme();

  return (
    <Card>
      <Grid
        container
        spacing={4}
        flexDirection={{ xs: "column-reverse", md: "row" }}
      >
        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            p={{ xs: ".5rem", md: "1.5rem" }}
          >
            <Box display={{ xs: "none", md: "block" }}>
              <Typography variant="h2">Word Cloud</Typography>
            </Box>

            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography htmlFor="inlineRadio2" sx={{ fontSize: "1rem" }}>
                  Desktop Wallpaper
                </Typography>
                <Radio
                  disableRipple
                  sx={{
                    color: theme.palette.primary.main,
                    borderRadius: "20px",
                    "&.Mui-checked": {
                      color: theme.palette.primary.main,
                    },
                    size: {
                      xs: "medium",
                      md: "large",
                    },
                  }}
                  name="wallpaper"
                  id="inlineRadio2"
                  value="desktop"
                  checked={pc}
                  onChange={(e) => {
                    setPC(e.target.checked);
                    if (e.target.checked) {
                      setRender(false);
                      setMobile(false);
                      document.querySelector("#word-cloud-save").style.width =
                        "100%";
                      document.querySelector(
                        "#word-cloud-save svg"
                      ).style.width = "100%";
                      handleAnimate();
                      setRender(true);
                    }
                  }}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography htmlFor="inlineRadio1" sx={{ fontSize: "1rem" }}>
                  Mobile Wallpaper
                </Typography>

                <Radio
                  disableRipple
                  sx={{
                    color: theme.palette.primary.main,
                    borderRadius: "20px",
                    "&.Mui-checked": {
                      color: theme.palette.primary.main,
                    },
                    size: {
                      xs: "medium",
                      md: "large",
                    },
                  }}
                  name="wallpaper"
                  id="inlineRadio1"
                  value="mobile"
                  checked={mobile}
                  onChange={(e) => {
                    setMobile(e.target.checked);
                    if (e.target.checked) {
                      setRender(false);
                      setPC(false);
                      document.querySelector("#word-cloud-save").style.width =
                        "300px";
                      document.querySelector(
                        "#word-cloud-save svg"
                      ).style.width = "100%";
                      handleAnimate();
                      setRender(true);
                    }
                  }}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={{ xs: "row", md: "column" }}
              gap={{ xs: 0.5, md: 1 }}
            >
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  props.setWords("all");
                  props.getTimeLine("all");
                }}
                sx={{ fontSize: { xs: ".8rem", md: "1rem" } }}
              >
                All Words
              </Button>
              <Button
                onClick={handleSave}
                variant="contained"
                fullWidth
                sx={{ fontSize: { xs: ".8rem", md: "1rem" } }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box display="flex" justifyContent="center" width="100%">
            {render && (
              <ReactWordcloud
                callbacks={wordcloudCallbacks}
                options={wordcloudOptions}
                words={words}
                id="word-cloud-save"
                style={{
                  width: "100%",
                  border: `1px solid ${theme.palette.common.disabled}`,
                  borderRadius: "1rem",
                  height: "50vh",
                  svg: {
                    width: "100%",
                  },
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
