import { forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import PodcastingTabs from "./PodcastingTabs";
import Slide from '@mui/material/Slide';
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function PodcastModal({ open, setOpen }) {
  let isSticky = sessionStorage.getItem('isSticky');

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      sx={{ mt: 8, }}
      maxWidth={'md'}
      fullWidth={true}
    // disableEnforceFocus={true}
    >
      {/* <div
        className="modal fade dialogbox dialogbox1"
        id="spotifyModal"
        data-bs-backdrop="static"
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{
            maxWidth: "100%",
            padding: "0px 20px",
            margin: 0,
          }}
        >*/}
      <div
        style={{
          height: "75vh",
          overflowY: "auto",
        }}
      >
        <div className="text-primary bg-white px-2 mt-2" onClick={() => setOpen(false)}>
          <ClearRoundedIcon style={{ cursor: "pointer" }} />
        </div>
        <PodcastingTabs />
        {/* </div>
        </div>*/}
      </div>
    </Dialog>
  );
}
