import * as React from 'react';
import { useState } from "react"
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t, i18n } = useTranslation()
    const [flag, setFlag] = useState(false)
    return (
        <div
            style={{
                position: 'relative',
                margin: '0',
                padding: '1rem',
                background: '#ffc324',
                color: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: "100%",
                borderRadius: "0 0  6.5px 6.5px"
            }}>
            <div>
                <div className="mydialogue" style={{ display: flag ? 'block' : 'none', borderRadius: "12px", position: 'absolute', background: 'white', border: '1px solid rgba(0,0,0,0.2)', boxShadow: '0 5px 10px rgb(0 0 0 / 20%)', padding: "15px", maxWidth: '567px', top: '-261px' }}>
                    <div onClick={() => setFlag(false)} style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                        <img src='/assets/img/iconclose.png' width="20px" />
                    </div>
                    <p
                        style={{
                            textDecoration: 'italic',
                            fontWeight: '200',
                            fontSize: '14px',
                            lineHeight: '22px',
                            color: '#3d3d3d',
                            fontWeight: '300',
                            margin: '0'
                        }}>
                        <i>
                            {t("disclaimerText")}
                        </i>
                    </p>
                </div>
                <p style={{ color: "white", cursor: 'pointer', maxWidth: "300px", margin: "0" }} onClick={() => setFlag(true)}>{t("disclaimer")}</p>
            </div>
            <img style={{ width: "25%" }} src={i18n.language == "sp" ? "/assets/img/icon/money-logo-spanish.png" : '/assets/img/icon/money-logo2.png'} />
        </div>
    )
}

export default Footer