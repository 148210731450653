import {
  Box,
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Chart from "react-apexcharts";
import { NumericFormat } from "./list";

const PieChart = (props) => {
  const theme = useTheme();
  const data = {
    series: props?.data,
  };

  const chartOptions = {
    tooltip: {
      y: {
        formatter: function (y) {
          return "$" + y.toFixed(0);
        },
      },
    },
    chart: {
      background: "transparent",
      toolbar: { show: false },
    },
    colors: data.series.map((item) => item.color),
    dataLabels: {
      enabled: false,
    },
    labels: data.series.map((item) => item.label),
    legend: { show: false },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
          labels: {
            show: true,
            total: {
              show: true,
              label: "Total",
              fontSize: "22px",
              color: theme.palette.text.primary,
            },
          },
        },
      },
    },
    stroke: {
      colors: [theme.palette.background.paper],
      width: 0.7,
    },
    theme: {
      mode: theme.palette.mode,
    },
  };

  const chartSeries = data.series.map((item) => parseInt(item.data));

  return (
    <Box sx={{ backgroundColor: "background.default" }}>
      <Box sx={{ position: "relative", width: "fit-content", height: "fit-content" }}>
        <Chart
          height={150}
          options={chartOptions}
          series={chartSeries}
          type="donut"
        />
      </Box>

      {/* <Grid item xs={12} md={6}> */}
      {/* {data.series.map((item) => (
            <Box
              key={item.label}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "space-between",
              }}
            >
              <Typography variant="body1" color="text.secondary">
                {item.label}
              </Typography>

              <Typography variant="h6" color="primary" sx={{ fontWeight: 600 }}>
                <NumericFormat
                  displayType="text"
                  value={item.data}
                  thousandSeparator=","
                  decimalScale={2}
                  prefix={
                    item?.label?.includes("in years") ||
                    item?.label?.includes("in months") ||
                    item?.label?.includes("Total months") ||
                    item?.label?.includes("Children") ||
                    item?.label?.includes("rate years")
                      ? ""
                      : "$"
                  }
                />
              </Typography>
            </Box>
          ))} */}
      {/* </Grid> */}
    </Box>
  );
};

export default PieChart;
