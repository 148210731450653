import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container .MuiDialog-paper": {
    borderRadius: "20px",
  },
  "& .MuiDialogTitle-root": {
    textAlign: "center",
  },
  "& .MuiDialogContent-root .MuiTypography-root": {
    padding: "50px 60px 122px 60px",
    color: "#958D9E",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    textAlign: "center",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        background: "#616DED",
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "32px",
      }}
      {...other}
    >
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ListDialog({ modalText, modalTitle, open, setOpen }) {
  console.log({ modalText, modalTitle, open, setOpen });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {modalTitle}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {modalText ||
              "This application is just an MVP, These features will be added soon"}
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
