const TaxesIcon = ({ color }) => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.9961 3.37407H13.0586C12.6459 3.37407 12.3113 3.70863 12.3113 4.12134V15.6835C12.3113 16.096 11.9764 16.431 11.5639 16.431C11.1515 16.431 10.8165 16.096 10.8165 15.6835C10.8165 10.6807 10.8165 6.31177 10.8165 4.12134C10.8165 2.8832 11.8203 1.87939 13.0586 1.87939H27.2583C27.4279 1.87939 27.5926 1.9371 27.7251 2.04311L38.9353 11.0113C39.1125 11.1531 39.2158 11.3678 39.2158 11.5949V39.994C39.2157 41.2323 38.212 42.2361 36.9737 42.2361C32.2642 42.2361 17.7681 42.2361 13.0586 42.2361C11.8203 42.2361 10.8165 41.2323 10.8165 39.994C10.8165 39.2776 10.8165 38.3281 10.8165 37.1995C10.8165 36.787 11.1515 36.4522 11.5639 36.4522C11.9764 36.4522 12.3113 36.787 12.3113 37.1995V39.994C12.3113 40.4067 12.6459 40.7414 13.0586 40.7414H36.9737C37.3865 40.7414 37.721 40.4067 37.721 39.994V11.954L26.9961 3.37407Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.7258 2.04315C27.5015 1.8637 27.1942 1.82872 26.9352 1.95316C26.6763 2.07759 26.5116 2.3395 26.5116 2.62671V10.1002C26.5116 11.3385 27.5154 12.3423 28.7537 12.3423H38.4692C38.7865 12.3423 39.0693 12.1417 39.1743 11.8423C39.2794 11.5429 39.1839 11.2096 38.9361 11.0114L27.7258 2.04315ZM36.3386 10.8475L28.0063 4.18165V10.1002C28.0063 10.513 28.341 10.8475 28.7537 10.8475H36.3386Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.0065 19.0683C28.0065 18.0771 27.6129 17.1267 26.912 16.426C26.2113 15.7252 25.2609 15.3315 24.2698 15.3315C20.1342 15.3315 12.711 15.3315 8.57551 15.3315C7.58448 15.3315 6.63395 15.7252 5.93322 16.426C5.23249 17.1267 4.83875 18.0771 4.83875 19.0683V34.0152C4.83875 35.0063 5.23249 35.9567 5.93322 36.6574C6.63395 37.3582 7.58448 37.7519 8.57551 37.7519H24.2697C25.2609 37.7519 26.2113 37.3582 26.912 36.6574C27.6129 35.9567 28.0065 35.0063 28.0065 34.0152V19.0683ZM26.5118 19.0683V34.0152C26.5118 34.6098 26.2756 35.1801 25.8552 35.6006C25.4347 36.021 24.8644 36.2572 24.2697 36.2572C20.1342 36.2572 12.711 36.2572 8.57551 36.2572C7.98078 36.2572 7.41054 36.021 6.99016 35.6006C6.56964 35.1801 6.33342 34.6098 6.33342 34.0152C6.33342 30.0377 6.33342 23.0457 6.33342 19.0683C6.33342 18.4736 6.56964 17.9033 6.99016 17.4828C7.41054 17.0624 7.98078 16.8262 8.57551 16.8262H24.2697C24.8644 16.8262 25.4347 17.0624 25.8552 17.4828C26.2756 17.9033 26.5118 18.4736 26.5118 19.0683Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0694 23.5518H14.5535C14.9659 23.5518 15.3009 23.217 15.3009 22.8044C15.3009 22.392 14.9659 22.0571 14.5535 22.0571H10.0694C9.65698 22.0571 9.32202 22.392 9.32202 22.8044C9.32202 23.217 9.65698 23.5518 10.0694 23.5518Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.136 29.6868L21.8727 33.4236C22.1644 33.7152 22.638 33.7152 22.9297 33.4236C23.2213 33.1319 23.2213 32.6583 22.9297 32.3666L19.1929 28.6299C18.9013 28.3382 18.4277 28.3382 18.136 28.6299C17.8443 28.9215 17.8443 29.3952 18.136 29.6868Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2901 23.5518H22.7742C23.1867 23.5518 23.5216 23.217 23.5216 22.8044C23.5216 22.392 23.1867 22.0571 22.7742 22.0571H18.2901C17.8777 22.0571 17.5427 22.392 17.5427 22.8044C17.5427 23.217 17.8777 23.5518 18.2901 23.5518Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0694 31.7725H14.5535C14.9659 31.7725 15.3009 31.4377 15.3009 31.0251C15.3009 30.6127 14.9659 30.2778 14.5535 30.2778H10.0694C9.65698 30.2778 9.32202 30.6127 9.32202 31.0251C9.32202 31.4377 9.65698 31.7725 10.0694 31.7725Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5648 20.5633V25.0474C11.5648 25.4598 11.8996 25.7948 12.3122 25.7948C12.7247 25.7948 13.0595 25.4598 13.0595 25.0474V20.5633C13.0595 20.1507 12.7247 19.8159 12.3122 19.8159C11.8996 19.8159 11.5648 20.1507 11.5648 20.5633Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.2338 25.7946H20.8318C21.2442 25.7946 21.5791 25.4597 21.5791 25.0472C21.5791 24.6348 21.2442 24.2998 20.8318 24.2998H20.2338C19.8214 24.2998 19.4866 24.6348 19.4866 25.0472C19.4866 25.4597 19.8214 25.7946 20.2338 25.7946Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.2338 21.3106H20.8318C21.2442 21.3106 21.5791 20.9758 21.5791 20.5633C21.5791 20.1507 21.2442 19.8159 20.8318 19.8159H20.2338C19.8214 19.8159 19.4866 20.1507 19.4866 20.5633C19.4866 20.9758 19.8214 21.3106 20.2338 21.3106Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.8727 28.6299L18.136 32.3666C17.8443 32.6583 17.8443 33.1319 18.136 33.4236C18.4277 33.7152 18.9013 33.7152 19.1929 33.4236L22.9297 29.6868C23.2213 29.3952 23.2213 28.9215 22.9297 28.6299C22.638 28.3382 22.1644 28.3382 21.8727 28.6299Z"
        fill={color}
      />
    </svg>
  );
};

export default TaxesIcon;
