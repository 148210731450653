import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Loading from '../common/loading';
import { addNewBudgetEntry } from '../_api/index'
import { useMutation, useQueryClient } from 'react-query';
import CurrencyFormat from 'react-currency-format';
import SelectAccount from '../select-account/index'
import CloseIcon from '@mui/icons-material/Close';
import "./index.css"
export default function SelectAccountModal(props) {
    const queryClient = useQueryClient();
    const { isLoading: isLoading, isSuccess: isSuccess, mutate: addAmount } = useMutation(addNewBudgetEntry, {
        onSuccess: () => {
            queryClient.invalidateQueries('budgeting-calculators');
        },
    });
    const [valueInput, setValueInput] = useState(props.value)
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            amount: parseInt(valueInput),
            mainCategory: "ManageBudget",
            subCategory: props.category,
            type: props.type,
        }
        addAmount(data);
    }
    if (isLoading && !isSuccess) {
        return (<Loading />)
    } else
        return (
            <>
                <div style={{ zIndex: 300000 }} key={props.id} className="modal fade action-sheet" id={props.id} tabIndex="-1" role="dialog">
                    <div className="modal-dialog select-account-body" role="document" style={{ boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.20)', height: '80vh' }}>
                        <div className=" modal-content" style={{ height: '100%'}}>
                            <div className="modal-header">
                                {/* <h5 className="modal-title">{props.title}</h5> */}
                                <div className="d-flex justify-content-between">
                                    {/* <p>Select your account</p> */}
                                    <p></p>
                                    <CloseIcon data-bs-dismiss="modal" style={{ margin: "10px" }} />
                                </div>
                            </div>
                            <div className="modal-body">
                                <SelectAccount />
                            </div>
                        </div>
                    </div>
                </div>
            </>);
}
