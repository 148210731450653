import React, { useState } from 'react';
import startCase from 'lodash.startcase'
import Loading from '../common/loading';
import { updateBudgetEntry } from '../_api/index'
import { useMutation, useQueryClient } from 'react-query';
import CurrencyFormat from 'react-currency-format';
import toast from 'react-hot-toast';
import { Input, FormControlLabel, Checkbox } from '@mui/material';
import { useEffect } from 'react';

export const recuringList = {
    weekly: "Weekly",
    everyOtherWeek: "Every Other Week",
    twiceAMonth: "Twice A Month",
    monthly: "Monthly"
}

export default function EditModel(props) {
    const queryClient = useQueryClient();
    const { isLoading: isLoading, isSuccess: isSuccess, mutate: updateAmount } = useMutation(updateBudgetEntry, {
        onSuccess: () => {
            queryClient.invalidateQueries('budgeting-calculators');
            toast.success("Amount Edited Successfully")
        },
    });

    const [valueInput, setValueInput] = useState()
    const [description, setDescription] = useState("");
    const [isRecuring, setIsRecuring] = useState(false);
    const [frequency, setFrequency] = useState("monthly")
    const [errorMessage, setErrorMessage] = useState("")
    const [error, setError] = useState(true)

    useEffect(() => {
        setValueInput(props.value);
        setDescription(props.description);
        setIsRecuring(props.isRecurring);
        setFrequency(props.frequency || "monthly")
    }, [props])

    const handleSubmit = (e) => {
        if (!description || !valueInput) {
            setError(true)
            setErrorMessage("Please fill all the fields")
            return
        } else {
            setError(false)
            setErrorMessage("")
        }
        e.preventDefault();
        const data = {
            id: props.id,
            amount: parseInt(valueInput),
            mainCategory: "ManageBudget",
            subCategory: props.title,
            type: props.type,
            frequency: isRecuring ? frequency : "",
            isRecurring: isRecuring ? true : false,
            description: description
        }
        updateAmount(data);
    }
    if (isLoading && !isSuccess) {
        return (<Loading />)
    } else
        return (
            <div key={props.title} className="modal fade action-sheet" id={"mod" + props.id} tabIndex="-1" role="dialog">
                <div className="modal-dialog  modal-dialog-centered" role="document">
                    <div className="modal-content income-inner-content m-auto">
                        <div className="modal-header">
                            <h2 className="modal-title text-dark">{startCase(props.title)}</h2>
                        </div>
                        <div className="modal-body mb-1">
                            <div className="action-sheet-content px-0">
                                <h3 className="text-muted" style={{ fontSize: 15 }}>Description</h3>
                                <Input className="w-100 description_input" value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value)
                                    }} />
                                <div className="form-group basic changing-modal pt-4">
                                    <span className="dialog1-btn" onClick={() => { setValueInput(valueInput > 0 ? parseInt(valueInput) - 1 : 1) }}><b>-</b></span>
                                    <div className="input-group mb-2">
                                        <CurrencyFormat
                                            value={valueInput !== 0 && valueInput !== "0" && valueInput !== "" ? valueInput : ""}
                                            thousandSeparator={true} prefix={'$'}
                                            onValueChange={(values) => {
                                                const { value } = values;
                                                setValueInput(value)
                                            }}
                                            style={{ textAlign: 'center', fontSize: "30px" }}
                                            className="form-control text-primary border-bottom-black"
                                            inputmode="numeric" pattern="[0-9]*" type="text" name="creditcard"
                                            placeholder="$0"
                                        // required
                                        />
                                    </div>
                                    <span className="dialog1-btn" onClick={() => { setValueInput(valueInput > 0 ? parseInt(valueInput) + 1 : 1) }}><b>+</b></span>
                                </div>
                                <div data-bs-dismiss="modal" className="form-group basic pb-2">
                                    <button onClick={handleSubmit} className="btn btn-primary btn-block btn-lg "
                                        style={{ borderRadius: '12px' }}
                                    >Set Amount</button>
                                </div>
                                <ul>
                                    <li>
                                        <FormControlLabel
                                            className="checkbox-recuring"
                                            control={<Checkbox />}
                                            checked={isRecuring}
                                            label="Is It Recurring?"
                                            onChange={(e) => {
                                                setIsRecuring(e.target.checked)
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <select
                                            className="form-select select_frequency"
                                            aria-label="Default select example"
                                            value={frequency}
                                            disabled={!isRecuring}
                                            onChange={(e) => { setFrequency(e.target.value) }}
                                            required={isRecuring}
                                        >
                                            {Object.keys(recuringList).map(el => <option value={el}>{recuringList[el]}</option>)}
                                        </select>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            // <div key={props.title} className="modal fade action-sheet" id={"mod" + props.id} tabIndex="-1" role="dialog">
            //     <div className="modal-dialog" role="document">
            //         <div className="modal-content">
            //             <div className="modal-header">
            //                 <h5 className="modal-title">{startCase(props.title)}</h5>
            //             </div>
            //             <div className="modal-body">
            //                 <div className="action-sheet-content">
            //                     <div className="form-group basic changing-modal">
            //                         <span className="dialog-btn" onClick={() => { setValueInput(valueInput > 0 ? parseInt(valueInput) - 1 : 1) }}><b>-</b></span>
            //                         <div className="input-group mb-2">
            //                             <CurrencyFormat
            //                                 value={valueInput !== 0 && valueInput !== "0" && valueInput !== "" ? valueInput : ""}
            //                                 thousandSeparator={true} prefix={'$'}
            //                                 type="number"
            //                                 onValueChange={(values) => {
            //                                     const { value } = values;
            //                                     setValueInput(value)
            //                                 }}
            //                                 style={{ textAlign: 'center', fontSize: "30px" }}
            //                                 className="form-control text-primary"
            //                                 inputmode="numeric" pattern="[0-9]*" type="text" name="creditcard"
            //                                 placeholder="$0"
            //                             // required
            //                             />
            //                             {/* <input type="text" className="form-control text-primary" onChange={(e) => setValueInput(inputCurrencyFormat(e.target.value))} placeholder="0"
            //                                     value={valueInput !== 0 && valueInput !== "0" && valueInput !== "" ? inputCurrencyFormat(valueInput) : ""}
            //                                     style={{ textAlign: 'center', fontSize: "30px" }} /> */}
            //                         </div>
            //                         <span className="dialog-btn" onClick={() => { setValueInput(valueInput > 0 ? parseInt(valueInput) + 1 : 1) }}><b>+</b></span>
            //                     </div>
            //                     <div data-bs-dismiss="modal" className="form-group basic">
            //                         <button onClick={handleSubmit} className="btn btn-primary btn-block btn-lg"
            //                         >Set Amount</button>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        );
}
