
import { useState } from 'react'
import {Link } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

function AddBill() {
    const [value, setValue] = useState("")
    return (
        <>
            <div className="appHeader">
                <div className="left">
                    <a href="#" className="headerButton goBack" onClick={() => window.history.go(-1)}>
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
            </div>
            <div id="appCapsule" className="bg-white" style={{ height: '100%' }}>
                <h2 className="mx-4 mt-3">Add a Bill</h2>
                <p className="text-left mx-4 pt-1">Enter the name of the bill or subscription you are looking to add</p>
                <form action="/" className="p-4">
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '100%' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="standard-search"
                            label="Bill Name"
                            type="search"
                            variant="standard"
                            placeholder="(ex. comscast or Rent)"
                            onChange={(e) => { setValue(e.target.value) }}
                        />
                    </Box>
                </form>
                {
                    value.length > 3
                    &&
                    <ul className="listview image-listview inset" style={{ boxShadow: 'none' }} >
                        <li>
                            <Link to="/search-transaction" className="item" style={{ width: 'fit-content', alignItems: 'baseline' }}>
                                <div className="in text-left ">
                                    <div className="icon-box text-primary">+</div>
                                </div>
                                <p className="text-left text-primary"> <b>Add"{value}"</b></p>
                            </Link>
                        </li>
                    </ul>
                }
            </div>
        </>
    );
}

export default AddBill;