import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import HelpIcon from '@mui/icons-material/Help';
import { getAssignments } from '../_api/skill-assignments'
import { getEnrollment, resetCalculator } from '../_api/skill-assignments';
import { getAllData } from '../_api';
import toast from 'react-hot-toast'
import { useQuery, useMutation } from 'react-query';
import swal from 'sweetalert';
import { setTickerData, currencyFormat } from '../_budget-functionality';
import Marquee from 'react-fast-marquee';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import HomeHeader1 from '../common/homeHeader1';

export default function Header(props) {
    const {
        mutateAsync: mutateAssignments,
        data: assignments,
    } = useMutation(getAssignments);
    const videoRef = useRef()

    const [index, setIndex] = useState(-1)
    const [framLink, setFramLink] = useState("")
    const [news, setNews] = useState([])
    const [marqeeData, setMarqeeData] = useState([])
    const { data: enrollment } = useQuery("getting-enrollment", getEnrollment);

    const { data: allData } = useQuery("budgeting-calculators", getAllData, {
        enabled: Boolean(sessionStorage.getItem('token'))
    });
    useEffect(() => {
        if (!!allData) {
            setMarqeeData(setTickerData(allData))
        }
    }, [allData])
    const {
        mutateAsync: budgetReset,
    } = useMutation(resetCalculator, {
        onSuccess: () => {
            toast.dismiss()
            toast.success("Budget Calculator restored successfully")
        }
    });
    const resetBudget = () => {
        toast.loading("Reseting, Please wait...")
        budgetReset()
    }
    const capitalize = (text) => {
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return (finalResult)
    }

    useEffect(() => {
        mutateAssignments("manage-budget")
        getNews()
    }, [])


    const getNews = async () => {

        const newsData = localStorage.getItem('newsData')
        // console.log("🚀 ~ file: header.js ~ line 66 ~ getNews ~ newsData", newsData)
        if (newsData) return setNews(JSON.parse(newsData))

        try {

            fetch("https://search.cnbc.com/rs/search/combinedcms/view.xml?partnerId=wrss01&id=10000664")
                .then(response => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then(data => {
                    const items = data.querySelectorAll("item");
                    const newsx = []
                    items.forEach((item) => {
                        const title = item.querySelector("title").textContent;
                        const link = item.querySelector("link").textContent;
                        newsx.push({ title, link })
                    })

                    setNews(newsx.slice(3))
                    localStorage.setItem('newsData', JSON.stringify(newsx.slice(3)))
                }
                ).catch(err => console.log("newerror:", err))
        } catch (error) {
            console.log("🚀 ~ file: comp/common/header.js ~ line 91 ~ getNews ~ error: ", error)
        }

    }

    return (
        <>
            {<div className='appHeader2'>
                <div>
                    <Marquee className='mob-marquee' pauseOnHover={true} speed={marqeeData?.length < 8 ? 0 : 20} gradient={false} style={{ cursor: 'pointer', marginLeft: marqeeData?.length < 8 ? "20%" : 0 }}>
                        {
                            !marqeeData.length ?
                                <li style={{ color: '#fa7f6d', listStyle: 'none' }}>No data to show, please add income or expenses</li>
                                :
                                marqeeData.map(item => {
                                    return (<>
                                        <li key={item.id} style={{ color: '#fa7f6d', listStyle: 'none', marginLeft: '20px' }}>
                                            {capitalize(item.title)}
                                            <span style={{ marginLeft: '10px' }}>{currencyFormat(item.value)}</span>
                                            {item.success ?
                                                <span className="text-danger">
                                                    {/* <ArrowDropDownIcon /> */}
                                                    <img src="./assets//img/arrow-down.png" alt="img" className='px-1' />
                                                    {item.percent + "%"}
                                                </span>
                                                :
                                                <span className="text-success">
                                                    <img src="./assets//img/arrow-up.png" alt="img" className='px-1' />
                                                    {/* <ArrowDropUpIcon /> */}
                                                    {item.percent + "%"}
                                                </span>
                                            }

                                        </li>
                                    </>)
                                })
                        }
                    </Marquee>
                </div>
                <div className="appHeader1">
                    <div className="left">
                        <Link to="/" className="headerButton goBack">
                            <ion-icon name="chevron-back-outline"></ion-icon>
                            <h4> Go Back </h4>
                        </Link>
                    </div>
                    <div className="btn-right">
                        <button
                            className="btn  btn-primary1"
                            data-bs-toggle="modal"
                            data-bs-target="#skillAssignment"
                            style={{ border: "1px solid #616DED" }}
                        >
                            Skill Assignment
                        </button>
                    </div>
                </div>
            </div>}
            <div className="modal fade dialogbox dialogbox1" id="videoModal" data-bs-backdrop="static" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document" style={{ maxWidth: '100%', padding: '0px 200px 0px 200px' }}>
                    <div className="modal-content" style={{ maxWidth: '100%' }}>
                        <div className="modal-header">
                            <div className="btn-inline" >
                                <div className="text-primary bg-white" data-bs-dismiss="modal" onClick={() => { videoRef.current.pause() }}><ClearRoundedIcon /></div>
                            </div>
                        </div>
                        <div className="modal-body pb-3">
                            <video ref={videoRef} style={{ maxWidth: '100%', maxHeight: 300 }} className='rounded center fit' controls src='https://stickball.s3.amazonaws.com/stickball-unity.mp4'></video>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade dialogbox dialogbox1" id="skillAssignment" data-bs-backdrop="static" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document" style={{ maxWidth: '100%', padding: '0px 100px 0px 100px' }}>
                    <div className="modal-content" style={{ maxWidth: '100%' }}>
                        <div className="modal-header">
                            <div className="btn-inline" onClick={() => setIndex(-1)}>
                                <div className="text-primary bg-white" data-bs-dismiss="modal"><ClearRoundedIcon /></div>
                            </div>
                        </div>
                        <div className="modal-body p-3">

                            {
                                index === -1 ?
                                    <>
                                        {
                                            assignments?.data?.map((assignment, ind) => {
                                                return (<button className="btn btn-primary mt-5 mb-5 btn-primary1 mx-5"
                                                    onClick={() => {
                                                        setIndex(1)
                                                        setFramLink(assignment.iframeLink + "?userID=" + props?.user?.id)
                                                    }}
                                                >{assignment.assignmentName}</button>)
                                            })
                                        }
                                    </>
                                    :
                                    <iframe
                                        allowFullScreen="true"
                                        src={framLink}
                                        width="100%"
                                        height="600"
                                        style={{ border: 0 }}
                                        loading="lazy"
                                        referrerpolicy="no-referrer-when-downgrade"
                                    ></iframe>
                            }
                            {
                                !assignments?.length &&
                                <div className="m-5 p-5 d-flex justify-content-center align-items-center">
                                    <h4 className="text-center">No assignments for Manage Budget</h4>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>

            {/* {<div className='appHeader' style={{ backgroundColor: 'black', height: 25, alignItems: 'center' }}>
            <div>
                <Marquee pauseOnHover={true} speed={marqeeData?.length < 8 ? 0 : 20} gradient={false} style={{ cursor: 'pointer', marginLeft: marqeeData?.length < 8 ? "20%" : 0 }}>
                    {
                        !marqeeData.length ?
                            <li style={{ color: 'white', listStyle: 'none' }}>No data to show, please add income or expenses</li>
                            :
                            marqeeData.map(item => {
                                return (<>
                                    <li key={item.id} style={{ color: 'white', listStyle: 'none', marginLeft: '20px' }}>
                                        {capitalize(item.title)}
                                        <span style={{ marginLeft: '10px' }}>{currencyFormat(item.value)}</span>
                                        {item.success ?
                                            <span className="text-success">
                                                <ArrowDropDownIcon />
                                                {item.percent + "%"}
                                            </span>
                                            :
                                            <span className="text-danger">
                                                <ArrowDropUpIcon />
                                                {item.percent + "%"}
                                            </span>}

                                    </li>
                                </>)
                            })
                    }
                </Marquee>
            </div>
        </div>}
        {!!news.length && <div className='appHeader' style={{ backgroundColor: '#75879D', height: 25, top: 25 }}>
            <Marquee gradient={false}>
                {news.map(value => <a style={{ textDecoration: 'none' }} key={value.title} href={value.link} target="_blank"><labal style={{ color: "#ffffff", marginLeft: 20 }}>{"* " + value.title}</labal></a>)}
            </Marquee>
        </div>}
        <div className="appHeader" style={{ marginTop: 50, }}>
            <div className="left">
                <Link to="/" className="headerButton goBack">
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </Link>
            </div>
            <div className="pageTitle">
                Manage Budget
            </div>

            <button className="btn btn-primary btn-primary1 my-2 mx-5 right" data-bs-toggle="modal" data-bs-target="#skillAssignment"
                style={{ border: '1px solid #4e1dff', height: '40px' }}>Skill Assignment</button>

            <div data-bs-toggle="modal" data-bs-target="#videoModal">
                <HelpIcon />
            </div>
        </div>

        <div className="modal fade dialogbox dialogbox1" id="videoModal" data-bs-backdrop="static" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document" style={{ maxWidth: '100%', padding: '0px 200px 0px 200px' }}>
                <div className="modal-content" style={{ maxWidth: '100%' }}>
                    <div className="modal-header">
                        <div className="btn-inline" >
                            <div className="text-primary bg-white" data-bs-dismiss="modal" onClick={() => { videoRef.current.pause() }}><ClearRoundedIcon /></div>
                        </div>
                    </div>
                    <div className="modal-body pb-3">
                        <video ref={videoRef} style={{ maxWidth: '100%', maxHeight: 300 }} className='rounded center fit' controls src='https://stickball.s3.amazonaws.com/stickball-unity.mp4'></video>
                    </div>

                </div>
            </div>
        </div>

        <div className="modal fade dialogbox dialogbox1" id="skillAssignment" data-bs-backdrop="static" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document" style={{ maxWidth: '100%', padding: '0px 100px 0px 100px' }}>
                <div className="modal-content" style={{ maxWidth: '100%' }}>
                    <div className="modal-header">
                        <div className="btn-inline" onClick={() => setIndex(-1)}>
                            <div className="text-primary bg-white" data-bs-dismiss="modal"><ClearRoundedIcon /></div>
                        </div>
                    </div>
                    <div className="modal-body p-3">

                        {
                            index === -1 ?
                                <>
                                    {
                                        assignments?.data?.map((assignment, ind) => {
                                            return (<button className="btn btn-primary mt-5 mb-5 btn-primary1 mx-5"
                                                onClick={() => {
                                                    setIndex(1)
                                                    setFramLink(assignment.iframeLink + "?userID=" + props?.user?.id)
                                                }}
                                            >{assignment.assignmentName}</button>)
                                        })
                                    }
                                </>
                                :
                                <iframe
                                    src={framLink}
                                    width="100%"
                                    height="600"
                                    style={{ border: 0 }}
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                                ></iframe>
                        }
                        {
                            !assignments?.length &&
                            <div className="m-5 p-5 d-flex justify-content-center align-items-center">
                                <h4 className="text-center">No assignments for Manage Budget</h4>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div> */}
        </>
    )
}
