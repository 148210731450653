import React from 'react'

const CreditCardIcon = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
<path d="M26.7181 11.4845H25.7651V3.38184C25.765 1.9362 24.5889 0.76001 23.1432 0.76001H3.48162C2.03598 0.76001 0.859848 1.9362 0.859848 3.38184V15.8937C0.859848 17.3394 2.03598 18.5155 3.48162 18.5155H4.43464V26.6182C4.43464 28.0661 5.60555 29.24 7.05642 29.24H26.718C28.1637 29.24 29.3398 28.0638 29.3398 26.6182V14.1063C29.3398 12.6606 28.1637 11.4845 26.7181 11.4845ZM2.5286 3.38184C2.5286 2.85635 2.95613 2.42876 3.48162 2.42876H23.1432C23.6687 2.42876 24.0962 2.85635 24.0962 3.38184V4.33486H2.5286V3.38184ZM2.5286 6.00361H24.0962V7.90971H2.5286V6.00361ZM3.48162 16.8468C2.95613 16.8468 2.5286 16.4192 2.5286 15.8937V9.57841H24.0962V15.8937C24.0962 16.4192 23.6687 16.8468 23.1432 16.8468H3.48162ZM27.6711 26.6182C27.6711 27.1437 27.2436 27.5713 26.7181 27.5713H7.05647C6.52987 27.5713 6.10345 27.1455 6.10345 26.6182V18.5155H23.1432C24.5671 18.5155 25.765 17.3641 25.765 15.8937V13.1533H26.718C27.2435 13.1533 27.671 13.5808 27.671 14.1063V26.6182H27.6711Z" fill={color}/>
<path d="M21.3555 11.4846H17.7807C16.6761 11.5251 16.6745 13.1123 17.7807 13.1534H21.3555C21.8163 13.1534 22.1899 12.7798 22.1899 12.319C22.1899 11.8582 21.8163 11.4846 21.3555 11.4846Z" fill={color}/>
<path d="M14.2064 23.9963H8.84418C7.74002 24.0366 7.73752 25.6239 8.84418 25.6651H14.2064C14.6672 25.6651 15.0408 25.2915 15.0408 24.8307C15.0408 24.3699 14.6672 23.9963 14.2064 23.9963Z" fill={color}/>
<path d="M23.143 20.4211H21.3556C19.9074 20.4211 18.7339 21.592 18.7339 23.0429C18.7339 24.4908 19.9048 25.6647 21.3556 25.6647H23.143C26.6171 25.5335 26.6165 20.5518 23.143 20.4211ZM23.143 23.9959H21.3556C20.8289 23.9959 20.4026 23.5701 20.4026 23.0429C20.4026 22.5174 20.8273 22.0898 21.3556 22.0898H23.143C24.4055 22.1377 24.4058 23.9479 23.143 23.9959Z" fill={color}/>
</svg>
  )
}

export default CreditCardIcon