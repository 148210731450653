import { useCallback, useEffect, useState } from 'react';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
    Paper,
    Badge,
    Stack,
    Box,
    Typography,
    IconButton,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import Send from '@mui/icons-material/Send'
import chatBotImg from '../assets/images/chatbot-img.svg'
import CloseIcon from '@mui/icons-material/Close';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            content: '""',
        },
    },
}));

const getRandomMsg = () => {
    let slogan = ["Crack the code to financial success.",
        "Find the treasure in your finances.",
        "Uncover the mysteries of wealth building.",
        "Unlock the power of financial literacy.",
        "Explore the unknowns of money management.",
        "Open the doors to financial freedom.",
        "Reveal the secrets of financial abundance.",
        "Break the spell of financial confusion.",
        "Transform your finances with hidden insights.",
        "Unleash the magic of financial knowledge."]
    return slogan[Math.floor(Math.random() * 10)]
}

function ChatBot(props) {
    const { open, setOpen } = props;
    const [isLoading, setLoading] = useState(false)
    const [msgHistory, setMsgHistory] = useState([
        { AI: getRandomMsg() }
    ])
    const [message, setMessage] = useState('')
    const [email, setEmail] = useState('')
    const [emailErr, setEmailErr] = useState('')
    const [msgErr, setMsgErr] = useState('')
    const [token, setToken] = useState()
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const scrollChatContainer = () => {
            const chatContainer = document.getElementById('chat-container');
            if (chatContainer) {
                chatContainer.scrollTo(0, chatContainer.scrollHeight);
            }
        };

        setTimeout(scrollChatContainer, 0);
    }, [msgHistory]);

    const sentEmail = () => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            const payload = {
                email
            }
            setMsgHistory([...msgHistory, { Human: "My email is " + email }, { AI: "Thank you for providing your email." }])
        } else {
            setEmailErr('Email is badly formatted')
        }
    }

    const handleRequest = async () => {
        if (isLoading) return
        if (message.length == 0) {
            setMsgErr('Please enter any query')
        }
        else {
            const axiosRef = axios.create({ 'headers': { 'x-api-key': "ee323a-1232asdfdasf2323@32asdfds-asdfd|--|ee323a-1232asdfdasf2323@32asdfds-asdfd" } })
            setMsgHistory([...msgHistory, { Human: message }])
            let _msg = message
            setMessage("")
            try {
                setLoading(true)
                const res = await axiosRef.post(`${process.env.REACT_APP_CHATBOT_SERVER}/CFO?text=${_msg}&authtoken=${sessionStorage.getItem("token")}`)
                console.log("Res: ", res)
                setMsgHistory([...msgHistory, { Human: message }, { AI: res?.data || "N/A" }])
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }
    }

    const handleChange = (event) => {
        setMessage(event.target.value)
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    position: 'absolute',
                    right: 0,
                    bottom: 80,
                    borderRadius: "10px !important",
                    maxWidth: 400,
                    height: 500
                },
            }}
        >
            <DialogTitle id="alert-dialog-title"
                sx={{
                    bgcolor: '#616ded',
                    padding: '5px 16px',
                    color: '#fff',
                    justifyContent: 'space-between',
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                }}
            >
                <Stack direction="row">
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                    >
                        <Avatar
                            sx={{
                                width: '32px',
                                height: '32px',
                                border: '2px solid',
                            }}
                            src={chatBotImg} alt="ChatBot" />
                    </StyledBadge>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            lineHeight: '14px',
                            color: '#fff',
                            fontFamily: "Poppins",
                            marginBottom: 0,
                            alignItems: 'center',
                            display: 'flex',
                            marginLeft: '14px'
                        }}
                    >AskMoney AI</Typography>
                </Stack>
                <IconButton sx={{color: 'white'}} onClick={handleClose}>
                    <CloseIcon  />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{
                height: '360px',
                mt: 5,
                overflowY: 'auto',
            }}
                id="chat-container"
            >
                {msgHistory?.map((val, ind) => (
                    <Box key={ind}>
                        {val?.AI &&
                            <>
                                <Grid container>
                                    <Grid item md={2} lg={2}>
                                        <Avatar className="avatar" src={chatBotImg} alt="ChatBot" />
                                    </Grid>
                                    <Grid item md={10} lg={10}>
                                        <Paper elevation={0}
                                            sx={{
                                                background: 'rgb(245, 248, 250)',
                                                color: 'rgb(65, 65, 65)',
                                                position: 'relative',
                                                borderRadius: '10px',
                                                padding: '8px 12px',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                            }}
                                        >
                                            <div style={{
                                                position: 'absolute',
                                                top: 4,
                                                left: -6,
                                                width: '15px',
                                                height: '15px',
                                                backgroundColor: 'rgb(245, 248, 250)',
                                                transform: 'rotate(-45deg)',
                                            }}>
                                            </div>
                                            <DialogContentText id="alert-dialog-description">
                                                {val.AI}
                                            </DialogContentText>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {val?.Human &&
                            <Grid container justifyContent={'end'} my={3}>
                                <Paper elevation={0}
                                    sx={{
                                        background: '#616ded',
                                        position: 'relative',
                                        borderRadius: '10px',
                                        padding: '8px 12px',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        display: "flex",
                                        maxWidth: "80%",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <div style={{
                                        position: 'absolute',
                                        bottom: 4,
                                        right: -3,
                                        width: '15px',
                                        height: '15px',
                                        backgroundColor: '#616ded',
                                        transform: 'rotate(-20deg)',
                                    }}>
                                    </div>
                                    <DialogContentText id="alert-dialog-description" sx={{ color: '#ffffff' }}>
                                        {val.Human}
                                    </DialogContentText>
                                </Paper>
                            </Grid>
                        }
                    </Box>
                ))}
                {!!isLoading && <>
                    <img src='./gif/msg_typing.gif' width={100} />
                </>
                }
            </DialogContent>
            <DialogActions>
                {/* {(msgHistory?.length !== 2 && msgHistory?.length !== 3) && */}
                <Grid container m='10px' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item md={11} lg={11}>
                        <TextField
                            // label="Compose..."
                            autoFocus
                            multiline
                            variant='standard'
                            placeholder='Compose'
                            fullWidth
                            size="small"
                            error={!!msgErr}
                            helperText={msgErr && "Please enter some text"}
                            value={message}
                            disabled={isLoading}
                            onChange={
                                (e) => {
                                    if (!!msgErr) setMsgErr("")
                                    handleChange(e)
                                }
                            }
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    handleRequest()
                                }
                            }}
                        />
                    </Grid>
                    <Grid item md={1} lg={1} mt='5px'>
                        <Send onClick={handleRequest} className='ms-1'
                            disabled={message === ""} style={{ color: '#0176ff' }} />
                    </Grid>
                </Grid>
                {/*}
                {(msgHistory?.length === 2 || msgHistory?.length === 3) &&
                    <Grid container m='10px'>
                        <Grid item md={11} lg={11}>
                            <TextField
                                id="outlined-required"
                                label="Enter your email..."
                                fullWidth
                                size="small"
                                required
                                value={email}
                                disabled={isLoading}
                                error={!!emailErr}
                                helperText={emailErr && "Please enter valid Email"}
                                onChange={(e) => {
                                    if (!!emailErr) setEmailErr("")
                                    setEmail(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        sentEmail()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={1} lg={1} mt='5px'>
                            <Send onClick={sentEmail} className='ms-1' style={{ color: '#0176ff' }} />
                        </Grid>
                    </Grid>
                } */}
            </DialogActions>
        </Dialog >
    );
}

export default ChatBot;