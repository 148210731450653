import { Link } from 'react-router-dom';

function CreditActivated() {
    return (<>
        <div className="appHeader">
            <div className="left">
                <a href="#" className="headerButton goBack" onClick={() => window.history.go(-1)}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
            </div>
            <div className="pageTitle">

            </div>
        </div>
        <div className="section mt-5 pt-5">
            <div className=" mt-5 pt-5 mb-5 text-center">
                <div className="mb-3 d-flex justify-content-center">
                    <img src="assets/img/img-money.png" alt="" className="imaged square w140" />
                </div>
                <h2 className="mb-2 text-primary">Your credit score has been activated</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquam.
                </p>
            </div>
        </div>
        <div className="fixed-bar">
            <Link to="/credit-score" style={{width: '100%'}}><button className="btn btn-lg btn-primary btn-block">Continue</button></Link>
        </div>
    </>);
}

export default CreditActivated;