import React from 'react';
import Header from './Header'
import Field from './Field'

import { SiChase } from "react-icons/si"

const bankData = [
    {
        title: 'First Tech Federal Credit Union',
        webLink: 'www.firsttechfed.com',
        icon: <img width="50px" src="assets/img/ftfcu.png" alt="" />
    },
    {
        title: 'Chase',
        webLink: 'www.chase.com',
        icon: <SiChase />
    },
    {
        title: 'Capital One',
        webLink: 'www.capitalone.com',
        icon: <img width="30px" src="assets/img/capital.png" alt="" />
    },
    {
        title: 'Wells Fargo',
        webLink: 'www.wellsfargo.com',
        icon: <img width="30px" src="/assets/img/wells-forgo.png" alt="" />
    },
    {
        title: 'Bank of America',
        webLink: 'www.bankofamerica.com',
        icon: <img width="30px" src="assets/img/bank-of-america.png" alt="" />
    },
    {
        title: 'Discover',
        webLink: 'www.discover.com',
        icon: <img width="30px" src="assets/img/discover.png" alt="" />
    },
    {
        title: 'American Express',
        webLink: 'www.americanexpress.com',
        icon: <img width="30px" src="assets/img/american-express.jpg" alt="" />
    },

    {
        title: 'Navy Federal Credit Union',
        webLink: 'www.navyfederal.com',
        icon: <img width="30px" src="assets/img/navey.PNG" alt="" />
    },
    {
        title: 'Citibank Online',
        webLink: 'www.citibankonline.com',
        icon: <img width="30px" src="assets/img/citi.png" alt="" />
    },
]

export default function SelectAccount() {
    return (<>
        {/* <Header /> */}


        <div id="appCapsule" className="bg-white pt-0">
            <div className="container">
                <h2 className="">Select your Account</h2>
                {/* <form className="search-form py-1 px-3">
                <div className="form-group searchbox">
                    <input style={{ outline: 'none', border: 'none', backgroundColor: '#eee' }} type="text" className="form-control" placeholder="Search all institutions" />
                    <i className="input-icon">
                        <ion-icon name="search-outline"></ion-icon>
                    </i>
                </div>
            </form> */}

                <ul className="listview image-listview inset mb-2">
                    <div className="listview-title mt-1">Most Popular</div>
                    {
                        bankData.map((item, i) => {
                            return (
                                <Field item={item} key={i} />
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    </>)
}
