import React, { useState } from "react"
import ResumeIframe from "./resumeIframe"
import "./index.css"
import VoltageBtn from "./voltage-btn/voltage_Btn"
import InputField from "./inputComp/inputComp"
import UploadPdf from "./uploadFile/uploadFile"
import { loading_iframe } from '../../assets';
import { resumeAnalyzer as resumeAnalyzer_req } from "../../_api/resume-analyzer"
import { Box, Typography } from "@mui/material"
import DetailedComp from "./detailedComp/detailedComp"
import { ArrowBack } from "@mui/icons-material"
import LoaderComp from "../../common/loaderComp"
const ResumeAnalyzer = ({ setIframeLoaded, isLoadIframe }) => {
    const [resumeAnalyzer, setResumeAnalyzer] = useState(false)
    const [resumeDetails, setResumeDetails] = useState(false)
    const [title, setTitle] = useState()
    const [description, setDescription] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");
    const [titleErrorMsg, setTitleErrorMsg] = useState("");
    const [descriptionErrorMsg, setDescriptionErrorMsg] = useState("");
    //  const [isLoadIframe, setIframeLoaded] = useState(false);
    const isIframe = sessionStorage.getItem('isIframe');

    const handleNextAndSubmit = async () => {
        if (!title) {
            setTitleErrorMsg("Job title is required !")
            return
        }
        if (!description) {
            setDescriptionErrorMsg("Job description is required !")
            return
        }
        if (!selectedFile) {
            setErrorMsg("Please upload PDF/Word file.")
            return
        }
        try {
            setErrorMsg(null)
            setIsLoading(true)

            const response = await resumeAnalyzer_req(title, description, selectedFile);
            setTitle("")
            setDescription("")
            // Handle the response data here
            console.log("Response from API:", response);
            setIsLoading(false)
            setResumeDetails(response)
        } catch (error) {
            setIsLoading(false)
            // Handle errors
            console.error("Error:", error);
        }
        //    setResumeDetails(true)
        //    setResumeDetails({ title: resumeDetails.title, description: resumeDetails.description });
    };
    const handleBack = () => {
        if (resumeDetails) {
            setResumeDetails(false)
        } else {
            setResumeAnalyzer(false)
        }
    }
    return (
        <div className="resume-analyzer" style={{ marginTop: isIframe !== "true"  ? "200px" : "30px",}}>
            {resumeAnalyzer ?
                <ArrowBack
                    style={styles.ArrowBack}
                    onClick={handleBack}
                /> : null}
            {
                isLoading ?
                    <Box
                        display={"flex"}
                        flexDirection={'column'}
                        gap={3}
                        alignItems={'center'}>
                        <img src='./gif/loading.gif' style={{ width: 100 }} />
                        <Typography fontFamily={'Poppins'} variant='h4'>Analyzing Resume</Typography>
                    </Box> :
                    <>
                        {resumeAnalyzer ?
                            !resumeDetails ? <div className="resume-analyzer-form">
                                <div className="resume-analyzer-input-div">
                                    <p className="main-title">Job Based <b style={{ color: "#6e7bee" }}> Resume</b> Analysis</p>
                                    <div style={styles.inputContainer}  className="resume-analyzer-inputContainer">
                                        <InputField
                                            width={"100%"}
                                            onChange={(e) => {
                                                setTitle(e.target.value);
                                                if (e.target.value) {
                                                    setTitleErrorMsg("")
                                                }
                                            }}
                                            name={"Job Title"}
                                            placeholder="e.g., Web Developer, Data Scientist" />
                                        {(titleErrorMsg) && <p style={styles.errorMsg}>{titleErrorMsg}</p>}
                                    </div>
                                    <div style={styles.inputContainer} className="resume-analyzer-inputContainer">
                                        <InputField
                                            width={"100%"}
                                            textaria={true}
                                            onChange={(e) => {
                                                setDescription(e.target.value);
                                                if (e.target.value) {
                                                    setDescriptionErrorMsg("")
                                                }
                                            }}
                                            name={"Job Description"} placeholder="e.g., Responsibilities, Requirements" />
                                        {(descriptionErrorMsg) && <p style={styles.errorMsg}>{descriptionErrorMsg}</p>}
                                    </div>
                                    <VoltageBtn normalBtn={true} onClick={handleNextAndSubmit} name={"Submit"} />
                                </div>
                                <div style={styles.uploadpdf}>
                                    <UploadPdf selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
                                    {(errorMsg && !selectedFile) && <p style={{ color: "red", fontFamily: "Poppins", fontSize: 18, marginTop: 5 }}>{errorMsg}</p>}
                                </div>
                            </div>
                                : <DetailedComp detail={resumeDetails} />
                            : null}
                        {!resumeAnalyzer ? <>
                            {isLoadIframe && <LoaderComp isLoadIframe={isLoadIframe} />}
                            <ResumeIframe setIframeLoaded={setIframeLoaded} />
                            <VoltageBtn onClick={() => setResumeAnalyzer(true)} name={"Resume Analyzer"} /></>
                            : null}</>
            }
        </div>
    )
}
export default ResumeAnalyzer

const styles = {
    uploadpdf: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    ArrowBack: {
        position: "absolute",
        top: -40,
        left: 20,
        cursor: "pointer"
    },
    errorMsg: { color: "red", fontFamily: "Poppins", fontSize: 18, marginTop: 5 },
    inputContainer: { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }
}