import React, { useState } from 'react';
import { Box, Button, Typography, } from '@mui/material';
import { Link } from 'react-router-dom';


const CareerList = ({ setRanderState,recomendedJobs}) => {
    const length_of_questions = recomendedJobs.length
    let Jobs=[]
for (let i = 0; i < length_of_questions; i++) {
    Jobs.push({...recomendedJobs[i],id:i+1})
}
console.log("Jobs",Jobs)
    const [pagination, setPagination] = useState({
        start: 0,
        end: 5,
        pageNum: 1
    });
    const [isloading, setisloading] = useState(false)

    // Ensuring every question has answered


    function totalPages(totalItems, itemsPerPage) {
        return Math.ceil(totalItems / itemsPerPage);
    }


    const handleNextPage = () => {
        const pageNum = pagination.pageNum
        if (length_of_questions / pageNum == 5 || length_of_questions / pageNum < 5) {
            alert("pages reached its maxed level")
        }
        else {
            setPagination({
                start: pagination.start + 5,
                end: pagination.end + 5,
                pageNum: pagination.pageNum + 1
            })
        }
    }

    const handlePrevPage = () => {
        const pageNum = pagination.pageNum
        if (pageNum == 1) {
            setRanderState(0)
        } else {
            setPagination({
                start: pagination.start - 5,
                end: pagination.end - 5,
                pageNum: pagination.pageNum - 1
            })
        }
    }
    return (
        <>
            <Box width={"90%"} flexDirection={'column'} gap={2} display={"flex"} alignItems={'center'}>
                <Box display={"flex"} justifyContent={'space-around'} alignItems={'center'} width={'100%'}>
                    <Typography fontFamily={'Poppins'} color={"black"} fontWeight={'bold'}>
                        Careers that fit your interests and preparation level:
                    </Typography>
                    <Box>
                        <Typography fontFamily={'Poppins'} color={"black"}>
                            Page <b style={{ fontWeight: "bold", }}>
                                <b style={{ fontWeight: "normal" }}> {pagination.pageNum} of</b> { totalPages(recomendedJobs.length, 5) }</b>
                        </Typography>
                    </Box>

                </Box>
            </Box>
            {/* List */}
            {
                isloading ?
                    <Box display={"flex"} flexDirection={'column'} gap={3} alignItems={'center'}>
                        <img src='/assets/img/loader.gif' style={{ borderRadius: "50%", width: 200 }} />
                        <Typography fontFamily={'Poppins'} variant='h4'>Generating Interests</Typography>
                    </Box> :
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        width={"90%"}
                    >
                        {Jobs?.slice(pagination.start, pagination.end).map((val, index, array) => {
                            return (
                                <Box
                                    key={val.id}
                                    display="flex"
                                    alignItems="center"
                                    gap={2}
                                    width={"100%"}
                                    // backgroundColor={val.color}
                                    padding={0}
                                    flexDirection={'row'}
                                >
                                    <Box display={'flex'} gap={1} flexDirection={'row'} width={'90%'} alignItems={'center'}>
                                        <Box
                                            display={'flex'}
                                            height={'40px'}
                                            width={'40px'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            backgroundColor={"white"}
                                            borderRadius={'50%'}
                                            >
                                            <Typography fontFamily={'Poppins'} color="black" fontSize={16}>
                                                {val?.id}
                                            </Typography>
                                        </Box>
                                        <Link
                                            to={'/career-sammary'}
                                            maxWidth={'85%'}
                                            fontFamily={'Poppins'}
                                            style={{ fontSize: 18,color:"#616DED"}}
                                            textAlign={'start'}
                                            fontSize={17}
                                            state={val}
                                            >
                                            {val?.profession}
                                        </Link>
                                    </Box>

                                </Box>
                            )
                        })}
                    </Box>
            }


            <Box width={'90%'} marginTop={2} position={'relative'} display={'flex'} justifyContent={"space-between"} >
                <Button
                    onClick={handlePrevPage}
                    // disabled={pagination?.pageNum == 1}
                    startIcon={
                        <img
                          width="150%"
                          height="150%"
                          src="assets/img/icon/arrow.svg"
                          className="logo"
                          alt=""
                          style={{filter: 'brightness(2000%)'}}
                        />
                      }
                    variant="contained"
                    color="primary" >
                    <Typography variant="button">Back</Typography>
                </Button>
                <Button
                    onClick={handleNextPage}
                    disabled={length_of_questions / pagination?.pageNum == 5 || length_of_questions / pagination?.pageNum < 5}
                    endIcon={ 
                        <img
                            width="150%"
                            height="150%"
                            src="assets/img/icon/arrow.svg"
                            className="logo"
                            alt=""
                            style={{filter:!(length_of_questions / pagination?.pageNum == 5 || length_of_questions / pagination?.pageNum < 5)&&'brightness(2000%)',transform:'rotate(180deg)'}}
                          />} variant="contained" color="primary">
                    <Typography variant="button">Next</Typography>
                </Button> 


            </Box>
        </>
    )
}

export default CareerList