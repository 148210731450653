import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";

const SubCategory = ({ data, singleSubCategoryDetailsHandler }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.common.background,
        p: 1.2,
        borderRadius: "8px",
      }}
    >
      <Box>
        <Typography>{moment(data?.createdAt).format("ll")}</Typography>
        <Typography>{moment(data?.createdAt).format("LT")}</Typography>
      </Box>
      <Box display="flex" gap={2} alignItems="center">
        <Typography variant="h4">$ {data?.amount}</Typography>
        <IconButton
          disableRipple
          onClick={() => singleSubCategoryDetailsHandler(data)}
        >
          <VisibilityIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SubCategory;
