import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import CardRapper from "./tabs/cardRapper";
import CalTab from "./tabs";
import {
  ListL_LG,
  renderFormatData,
  RecalculateButton,
  PieChart,
  NumericFormat,
  TextField,
} from "./tabs/list";
import Footer from "../footer";
import { useState } from "react";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
  Button,
  Typography,
  Card,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Layout from "./Layout";
import { useTheme } from "@mui/material/styles";

const calculateNetPaycheck = (
  annualSalary,
  payFrequency,
  otherAnnualIncome,
  annualPretaxDeductions,
  annualItemizedDeductions,
  stateTaxRate,
  cityTaxRate,
  isSelfEmployed
) => {
  const jobIncome = annualSalary;
  payFrequency = payFrequency;
  const otherIncome = otherAnnualIncome;
  const pretaxDeductions = annualPretaxDeductions;
  const itemizedDeductions = annualItemizedDeductions;
  stateTaxRate = stateTaxRate / 100;
  cityTaxRate = cityTaxRate / 100;

  const annualGrossIncome = jobIncome + otherIncome;
  const totalDeductions = pretaxDeductions + itemizedDeductions;
  const taxableIncome = annualGrossIncome - totalDeductions;

  const federalTaxRate = 0.12; // Assuming a 12% federal tax rate for simplicity
  const totalTax =
    taxableIncome * (federalTaxRate + stateTaxRate + cityTaxRate);

  const annualNetIncome = taxableIncome - totalTax;
  const netPayPerPeriod = annualNetIncome / payFrequency;

  return netPayPerPeriod.toFixed(2);
};

export default function PayCheck() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState("Results");
  const [showResults, setShowResults] = useState(false);
  const [chartData, setChartData] = useState([]);
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      income: "",
      frequency: 24,
      status: 1,
      childrens: "",
      otherDependents: "",
      otherIncome: "",
      withheldDeductions: "",
      withoutheldDeductions: "",
      itemizedDeductions: "",
      hasOtherIncome: "",
      stateIncomeTax: "",
      cityIncomeTax: "",
      isSelfEmployed: "",
      income1: 0,
      income2: 0,

      //result values
      isSubmitted: false,
      grossPay: 0,
      fedralIncomeTax: 0,
      socialSecurityTax: 0,
      medicareTax: 0,
      stateIncomeTax: 0,
      cityIncomeTax: 0,
      deductionsWithheld: 0,
      finalPayCheck: 0,
    },
    validationSchema: Yup.object({
      income: Yup.string().required(t("enterIncome")),
      income1: Yup.string().min(0, t("enterValidAmount")),
      income2: Yup.string().min(0, t("enterValidAmount")),
      frequency: Yup.string().required(t("selectFrequency")),
      status: Yup.string().required(t("selectYourStatus")),
      childrens: Yup.string()
        .required(t("enterNumberOfChildrens"))
        .min(0, t("enterValidAmount")),
      otherDependents: Yup.string()
        .required(t("enterNumberOfDependents"))
        .min(0, t("enterValidAmount")),
      otherIncome: Yup.string()
        .required(t("enterOtherIncome"))
        .min(0, t("enterValidAmount")),
      withheldDeductions: Yup.string()
        .required(t("enterDeductions"))
        .min(0, t("enterValidAmount")),
      withoutheldDeductions: Yup.string()
        .required(t("enterDeductions"))
        .min(0, t("enterValidAmount")),
      itemizedDeductions: Yup.string()
        .required(t("enterDeductions"))
        .min(0, t("enterValidAmount")),
      hasOtherIncome: Yup.bool().required(t("selectOne")),
      stateIncomeTax: Yup.string()
        .required(t("enterIncomeTax"))
        .min(0, t("enterValidAmount"))
        .max(100, t("stateTaxMustBe100")),
      cityIncomeTax: Yup.string()
        .required(t("enterIncomeTax"))
        .min(0, t("enterValidAmount"))
        .max(100, t("cityTaxMustBe100")),
      isSelfEmployed: Yup.bool().required(t("selectOne")),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const userHasOtherIncome = values.hasOtherIncome === "true" || false;
      const jobIncome = Number(values.income?.replace(/[^0-9.-]+/g, ""));
      const jobIncome1 = userHasOtherIncome
        ? Number(values.income1?.replace(/[^0-9.-]+/g, ""))
        : 0;
      const jobIncome2 = userHasOtherIncome
        ? Number(values.income2?.replace(/[^0-9.-]+/g, ""))
        : 0;
      const jobFrequency = parseInt(values.frequency);
      const userStatus = parseInt(values.status);
      const userChildrens = Number(values.childrens?.replace(/[^0-9.-]+/g, ""));
      const userOtherDependents = Number(
        values.otherDependents?.replace(/[^0-9.-]+/g, "")
      );
      const userOtherIncome = Number(
        values.otherIncome?.replace(/[^0-9.-]+/g, "")
      );
      const userWithDeductions = Number(
        values.withheldDeductions?.replace(/[^0-9.-]+/g, "")
      );
      const userWithoutDeductions = Number(
        values.withoutheldDeductions?.replace(/[^0-9.-]+/g, "")
      );
      const userItemizedDeductions = Number(
        values.itemizedDeductions?.replace(/[^0-9.-]+/g, "")
      );
      const userStateIT = Number(
        values.stateIncomeTax?.replace(/[^0-9.-]+/g, "")
      );
      const usercityIT = Number(
        values.cityIncomeTax?.replace(/[^0-9.-]+/g, "")
      );
      const userIsSelftEmployed = values.isSelfEmployed === "true" || false;
      let netPay = calculateNetPaycheck(
        jobIncome + jobIncome1 + jobIncome2 || 0,
        jobFrequency,
        userOtherIncome,
        userWithDeductions + userWithoutDeductions,
        userItemizedDeductions,
        userStateIT,
        usercityIT,
        userIsSelftEmployed
      );
      let temp = [
        {
          color: "#5C34D5",
          data: netPay,
          label: t("netPay"),
        },
      ];
      setChartData(temp);
      setShowResults(true);
      setSubmitting(false);
    },
  });
  const inputProps = (pre, post) => {
    return {
      InputProps: {
        endAdornment: <span style={{ opacity: 0.5 }}>{post}</span>,
      },
      className: "card-input",
      onBlur: formik.handleBlur,
      onChange: formik.handleChange,
      fullWidth: true,
      customInput: { TextField },
      prefix: pre,
      thousandSeparator: ",",
    };
  };

  const trials = [
    {
      label: t("daily"),
      value: 260,
    },
    {
      label: t("weekly"),
      value: 52,
    },
    {
      label: t("biWeekly"),
      value: 26,
    },
    {
      label: t("twiceAMonth"),
      value: 24,
    },
    {
      label: t("monthly"),
      value: 12,
    },
    {
      label: t("quarterly"),
      value: 4,
    },
    {
      label: t("semiAnnually"),
      value: 2,
    },
    {
      label: t("annually"),
      value: 1,
    },
  ];
  const statuses = [
    {
      label: t("single"),
      value: 1,
    },
    {
      label: t("fillingJointly"),
      value: 2,
    },
    {
      label: t("marriedFilling"),
      value: 1,
    },
    {
      label: t("headOfHouse"),
      value: 1,
    },
  ];

  const renderResultTabs = () => {
    return (

        <Box>
        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 1,
          }}
        >
          <PieChart data={chartData} />
        </Card>

        <Box>
          {chartData?.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: theme.palette.common.background,
                p: 1,
                mt: 1,
                borderRadius: 1,
              }}
            >
              <Typography>{item?.label}</Typography>
              <Typography>
                {renderFormatData(item.data)}
              </Typography>
            </Box>
          ))}
          <Box mt={2} textAlign="center">
          <RecalculateButton
          onClick={() => {
            setShowResults(false);
          }}
        />
          </Box>
        </Box>
      </Box>


    //   <CalTab setTabValue={setTabValue} tabValue={tabValue}>
    //     {tabValue === "Results" && (
    //       <Grid item xs={12} md={12} sx={{ mt: 3 }}>
    //         <List>
    //           {chartData?.map((item, i) => (
    //             <ListL_LG
    //               key={i}
    //               left={item?.label}
    //               right={renderFormatData(item.data)}
    //             />
    //           ))}
    //           <Divider />
    //         </List>
    //       </Grid>
    //     )}
    //     {tabValue === "Graph" && <PieChart data={chartData} />}
    //     <RecalculateButton
    //       onClick={() => {
    //         setShowResults(false);
    //       }}
    //     />
    //   </CalTab>
    );
  };

  const renderForm = () => (
    <form onSubmit={formik.handleSubmit}>
      <NumericFormat
        {...inputProps("$ ", `/${t("year")}`)}
        error={Boolean(formik.touched.income && formik.errors.income)}
        helperText={formik.touched.income && formik.errors.income}
        label="Your Job Income (Salary)"
        name="income"
        value={formik.values.income}
      />
      <TextField
        select
        error={Boolean(formik.touched.frequency && formik.errors.frequency)}
        helperText={formik.touched.frequency && formik.errors.frequency}
        label="Pay Frequency"
        name="frequency"
        fullWidth
        SelectProps={{
          native: true,
        }}
        className="card-input"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.frequency}
      >
        {trials.map((trial) => {
          return (
            <option key={trial.label} value={trial.value}>
              {trial.label}
            </option>
          );
        })}
      </TextField>

      <TextField
        select
        error={Boolean(formik.touched.status && formik.errors.status)}
        helperText={formik.touched.status && formik.errors.status}
        label="File Status"
        name="status"
        fullWidth
        SelectProps={{
          native: true,
        }}
        className="card-input"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.status}
      >
        {statuses.map((stat) => {
          return (
            <option key={stat.label} value={stat.value}>
              {stat.label}
            </option>
          );
        })}
      </TextField>

      <NumericFormat
        {...inputProps("", "")}
        error={Boolean(formik.touched.childrens && formik.errors.childrens)}
        helperText={formik.touched.childrens && formik.errors.childrens}
        label="Number of children under age 17"
        name="childrens"
        value={formik.values.childrens}
      />
      <NumericFormat
        {...inputProps("", "")}
        error={Boolean(
          formik.touched.otherDependents && formik.errors.otherDependents
        )}
        helperText={
          formik.touched.otherDependents && formik.errors.otherDependents
        }
        label="Number of other dependents"
        name="otherDependents"
        value={formik.values.otherDependents}
      />
      <NumericFormat
        {...inputProps("$ ", `/${t("year")}`)}
        error={Boolean(formik.touched.otherIncome && formik.errors.otherIncome)}
        helperText={
          formik.errors.otherIncome && formik.touched.otherIncome
            ? formik.errors.otherIncome
            : t("otherIncomeHelperText")
        }
        label="Other income (not from jobs)"
        name="otherIncome"
        value={formik.values.otherIncome}
      />
      <NumericFormat
        {...inputProps("$ ", `/${t("year")}`)}
        error={Boolean(
          formik.touched.withheldDeductions && formik.errors.withheldDeductions
        )}
        helperText={
          formik.errors.withheldDeductions && formik.touched.withheldDeductions
            ? formik.errors.withheldDeductions
            : t("preTaxHelperText")
        }
        label="Pretax deductions withheld"
        name="withheldDeductions"
        value={formik.values.withheldDeductions}
      />

      <NumericFormat
        {...inputProps("$ ", `/${t("year")}`)}
        error={Boolean(
          formik.touched.withoutheldDeductions &&
            formik.errors.withoutheldDeductions
        )}
        helperText={
          formik.errors.withoutheldDeductions &&
          formik.touched.withoutheldDeductions
            ? formik.errors.withoutheldDeductions
            : t("deductionNotHeldHelperText")
        }
        label="Deductions not withheld"
        name="withoutheldDeductions"
        value={formik.values.withoutheldDeductions}
      />
      <NumericFormat
        {...inputProps("$ ", `/${t("year")}`)}
        error={Boolean(
          formik.touched.itemizedDeductions && formik.errors.itemizedDeductions
        )}
        helperText={
          formik.errors.itemizedDeductions && formik.touched.itemizedDeductions
            ? formik.errors.itemizedDeductions
            : t("itemizedDeductionsHelperText")
        }
        label="Itemized deductions"
        name="itemizedDeductions"
        value={formik.values.itemizedDeductions}
      />

      <Grid item xs={12}>
        <FormControl
          sx={{ px: 2 }}
          error={Boolean(
            formik.touched.hasOtherIncome && formik.errors.hasOtherIncome
          )}
        >
          <FormLabel id="demo-row-radio-buttons-group-label">
            {formik.values.status != 2 ? t("has2ndIncome") : t("has3rdIncome")}
          </FormLabel>
          <RadioGroup
            name="hasOtherIncome"
            onChange={formik.handleChange}
            value={formik.values.hasOtherIncome}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label={t("yes")}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label={t("no")}
            />
          </RadioGroup>
          {formik.touched.hasOtherIncome && formik.errors.hasOtherIncome ? (
            <div style={{ color: "#d32f2f" }}>
              {formik.errors.hasOtherIncome}
            </div>
          ) : null}
        </FormControl>
      </Grid>

      {formik.values.hasOtherIncome === "true" && (
        <Grid sx={{ pl: 2 }}>
          <label style={{ margin: 4 }}>{t("listTheseIncomes")}</label>
          <NumericFormat
            {...inputProps("$ ", `/${t("year")}`)}
            sx={{ my: 2 }}
            error={Boolean(formik.touched.income1 && formik.errors.income1)}
            helperText={formik.touched.income1 && formik.errors.income1}
            label="State income tax rate"
            name="income1"
            value={formik.values.income1}
          />
          <NumericFormat
            {...inputProps("$ ", `/${t("year")}`)}
            error={Boolean(formik.touched.income2 && formik.errors.income2)}
            helperText={formik.touched.income2 && formik.errors.income2}
            label="City income tax rate"
            name="income2"
            value={formik.values.income2}
          />
        </Grid>
      )}
      <NumericFormat
        {...inputProps("", "%")}
        error={Boolean(
          formik.touched.stateIncomeTax && formik.errors.stateIncomeTax
        )}
        helperText={
          formik.touched.stateIncomeTax && formik.errors.stateIncomeTax
        }
        label={t("incomeTaxRate")}
        name="stateIncomeTax"
        value={formik.values.stateIncomeTax}
      />
      <NumericFormat
        {...inputProps("", "%")}
        error={Boolean(
          formik.touched.cityIncomeTax && formik.errors.cityIncomeTax
        )}
        helperText={formik.touched.cityIncomeTax && formik.errors.cityIncomeTax}
        label={t("cityIncomeTaxRate")}
        name="cityIncomeTax"
        value={formik.values.cityIncomeTax}
      />

      <FormControl
        sx={{ px: 2 }}
        error={Boolean(
          formik.touched.isSelfEmployed && formik.errors.isSelfEmployed
        )}
      >
        <FormLabel id="demo-row-radio-buttons-group-label">
          Are you self-employed or an independent contractor?
        </FormLabel>
        <RadioGroup
          name="isSelfEmployed"
          onChange={formik.handleChange}
          value={formik.values.isSelfEmployed}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
        >
          <FormControlLabel value={true} control={<Radio />} label={t("yes")} />
          <FormControlLabel value={false} control={<Radio />} label={t("no")} />
        </RadioGroup>
        {formik.touched.isSelfEmployed && formik.errors.isSelfEmployed ? (
          <div style={{ color: "#d32f2f" }}>{formik.errors.isSelfEmployed}</div>
        ) : null}
      </FormControl>
      <Box textAlign="center" mt={1}>
        <Button
          variant="contained"
          sx={{ width: "70%" }}
          type="submit"
          className="btn btn-calculate"
        >
          Calculate
        </Button>
      </Box>
    </form>
  );

  return showResults ? renderResultTabs() : renderForm();
}
