import React from 'react';

export default function FeedBackModal(props) {

    return (<>
        <div className="modal fade action-sheet" id={props.id} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"></h5>
                    </div>
                    <div className="modal-body p-5 text-center">
                        <p>{props.text}</p>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
